import { STUDIO_PERMISSION_ROLES } from '../../../../../shared/enums/studio-permission-roles.enum';
import { Division, Franchise } from '../models';
import { USER_ROLE_TITLE } from '../users/user.model';
import { BaseModel } from './../base/base.model';

export interface AdvancedPermissionsObject {
    can_edit: boolean;
    can_access_restricted: boolean;
    can_access_asset_details: boolean;
    can_upload_ledger: boolean;
    can_view_historical: boolean;
    can_unlink_transactions: boolean;
    can_access_asset_search: boolean;
    can_edit_groups: boolean;
}

export class Invite extends BaseModel {
    id: number;
    approver: boolean = null;
    studio_id: number = null;
    franchise_id: number = null;
    division_id: number = null;
    email: string = null;
    studio_manager: boolean = null;
    franchise_admin: boolean = null;
    division_admin: boolean = null;
    franchise_position = '';
    assethub_role = '';

    // advanced permissions with defaults
    can_edit = true;
    can_access_restricted = true;
    can_access_asset_details = true;
    can_upload_ledger = false;
    can_view_historical = true;
    can_unlink_transactions = false;
    can_access_asset_search = false;
    can_edit_groups = false;

    department_ids = '[]';
    franchise_ids = '[]';

    constructor(userObject?) {
        super();
        this.initializeData(userObject, this);
    }

    static buildFranchiseUserInvite(email: string, franchise: Franchise, position: string, ah_role: string, departmentIds: number[], advancedPerms: AdvancedPermissionsObject): Invite {
        const newInvite = new Invite();
        newInvite.studio_manager = false;
        newInvite.franchise_admin = false;
        newInvite.email = email;
        newInvite.studio_id = franchise.studio_id;
        newInvite.franchise_position = position;
        newInvite.assethub_role = ah_role;
        newInvite.franchise_id = franchise.id;
        newInvite.department_ids = JSON.stringify(departmentIds);

        newInvite.setAdvancedPerms(advancedPerms, false);

        return newInvite;
    }

    static buildFranchiseOwnerInvites(email: string, studioId: number, franchiseIds: number[], canViewHistorical: boolean, canAccessAssetSearch: boolean, assethub_role: string): Invite[] {
        return franchiseIds.map((franchiseId) => {
            const newInvite = new Invite({});
            newInvite.franchise_id = franchiseId;
            newInvite.franchise_admin = true;
            newInvite.studio_manager = false;
            newInvite.email = email;
            newInvite.studio_id = studioId;
            newInvite.assethub_role = assethub_role

            newInvite.setAdvancedPerms(null, false);
            newInvite.can_view_historical = canViewHistorical;
            newInvite.can_access_asset_search = canAccessAssetSearch;

            return newInvite;
        });
    }

    static buildStudioManagerInvites(email: string, studioId: number, isApprover: boolean, assethub_role: string): Invite[] {
        const newInvite = new Invite({});
        newInvite.studio_manager = true;
        newInvite.email = email;
        newInvite.studio_id = studioId;
        newInvite.approver = isApprover;
        newInvite.assethub_role = assethub_role;
        return [newInvite];
    }

    static buildDivisionUserInvite(email: string, division: Division, position: string, ah_role: string, advancedPerms: AdvancedPermissionsObject, canAccessAssetSearch: boolean, franchiseIds?: number[]): Invite {
        const newInvite = new Invite({});
        newInvite.studio_manager = false;
        newInvite.studio_id = division.studio_id;
        newInvite.franchise_position = position;
        newInvite.assethub_role = ah_role;
        newInvite.email = email;
        newInvite.division_id = division.id;
        newInvite.franchise_ids = franchiseIds ? JSON.stringify(franchiseIds) : '[]';

        newInvite.setAdvancedPerms(advancedPerms, true);
        newInvite.can_access_asset_search = canAccessAssetSearch;

        return newInvite;
    }

    static buildDivisionOwnerInvite(email: string, division: Division, position: string, ah_role: string, advancedPerms: AdvancedPermissionsObject, canAccessAssetSearch: boolean, canViewHistorical: boolean): Invite {
        const newInvite = new Invite({});
        newInvite.studio_manager = false;
        newInvite.studio_id = division.studio_id;
        newInvite.division_admin = true;
        newInvite.franchise_position = position;
        newInvite.assethub_role = ah_role;
        newInvite.email = email;
        newInvite.division_id = division.id;

        newInvite.setAdvancedPerms(advancedPerms, true);
        newInvite.can_access_asset_search = canAccessAssetSearch;
        newInvite.can_view_historical = canViewHistorical;

        return newInvite;
    }

    displayFranchiseName(franchises) {
        const franchise = franchises.find(f => f.id === this.franchise_id);
        return franchise ? franchise.name : '';
    }

    displayFranchiseRole() {
        return this.franchise_admin ? 'Manager' : 'User';
    }

    displayStudioRole() {
        const roleNum = this.studio_manager ? STUDIO_PERMISSION_ROLES.MANAGER : STUDIO_PERMISSION_ROLES.USER;
        if (roleNum === STUDIO_PERMISSION_ROLES.USER) {
            return USER_ROLE_TITLE;
        }
        return STUDIO_PERMISSION_ROLES[roleNum].toLowerCase();
    }

    getDepartments(departmentList) {
        if (!departmentList) {
            return [];
        }

        let deptIds;
        try {
            deptIds = JSON.parse(this.department_ids);
        } catch (err) {
            return [];
        }

        return departmentList.filter(d => deptIds?.indexOf(d.id) > -1);
    }

    setAdvancedPerms(advancedPermsObject: AdvancedPermissionsObject, isDivision: boolean) {
        if (this.studio_manager) {
            return;
        }

        if (this.franchise_admin) {
            this.can_edit = true;
            this.can_access_restricted = true;
            this.can_access_asset_details = true;
            this.can_upload_ledger = true;
            this.can_view_historical = true;
            this.can_edit_groups = true
        } else if (this.division_admin) {
            this.can_edit = true;
            this.can_access_restricted = true;
            this.can_access_asset_details = true;
            this.can_edit_groups = true;
        } else {
            this.can_edit = advancedPermsObject.can_edit;
            this.can_access_restricted = advancedPermsObject.can_access_restricted;
            this.can_access_asset_details = advancedPermsObject.can_access_asset_details;
            this.can_upload_ledger = isDivision ? null : advancedPermsObject.can_upload_ledger;
            this.can_view_historical = advancedPermsObject.can_view_historical;
            this.can_edit_groups = advancedPermsObject.can_edit_groups;
        }
    }

    getInviteTitleOrRole(): string {
        return this.franchise_admin ? this.assethub_role : this.franchise_position;
    }
}
