import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as projectActions from './project.actions';
import { Project } from './project.model';

@Injectable()
export class ProjectEffects {

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectActions.ADD),
            map((action: projectActions.AddAction) => {
                const proj: Project = action.payload.project;
                return {
                    name: proj.name,
                    franchise_id: action.payload.franchiseId
                };
            }),
            switchMap((projectData) => this.http.post(`/api/projects`, projectData).pipe(
                map(res => new Project(res)),
                map((newProject: Project) => new projectActions.AddCompleteAction({ project: newProject, franchiseId: projectData.franchise_id })),
                catchError((error) => observableOf(new projectActions.AddFailedAction({ error, franchiseId: projectData.franchise_id }))))
            )));

     addFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(projectActions.ADD_FAILED),
            map((action: projectActions.AddFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectActions.UPDATE),
            map((action: projectActions.UpdateAction) => action.payload),
            switchMap((projectData) => this.http.put(`/api/projects/${projectData.project.id}`, projectData.project).pipe(
                map(res => {
                    const response = res;
                    return new Project(response);
                }),
                map((p: Project) => new projectActions.UpdateCompleteAction(p)),
                catchError((error) => observableOf(new projectActions.UpdateFailedAction({error, franchiseId:projectData.project.franchise_id}))))
            )));

     updateFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(projectActions.UPDATE_FAILED),
            map((action: projectActions.UpdateFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
