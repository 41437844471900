import { Moment, sosMoment } from '../../../shared/services/moment.service';
import { Group } from '../groups/group.model';
import { SubGroup } from '../sub-groups/sub-group.model';
import { BaseModel } from './../base/base.model';

export const ATTACHMENT_OWNERS = {
    ASSET: 'asset',
    EVENT: 'event',
    GROUP: 'group',
    SUBGROUP: 'sub_group'
};

export class Attachment extends BaseModel {

    static ALLOWED_TYPES = 'image/*,application/pdf,text/plain,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    id: number;
    owner_id?: number = null;
    studio_id: number = null;
    type: string = null;
    file_name: string = null;
    original_name: string = null;
    link: string = null;
    created_at: string = null;
    user_name: string = null;

    moment: Moment;

    date: string;
    group?: Group = null;
    subGroup: SubGroup = null;

    constructor(departmentObj) {
        super();
        this.initializeData(departmentObj, this);
        this.moment = sosMoment;
        this.date = this.moment.utc(this.created_at).format('DD MMM. YYYY');
    }
}
