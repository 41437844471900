import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../core/store';
import * as sidenavFilterActions from '../../core/store/sidenav-filter/sidenav-filter.actions';
import { BaseModel } from './../../core/store/base/base.model';
import { Division, Franchise, Project, Warehouse } from './../../core/store/models';
import { FilterEntry, PageType, SelectType } from './sidenav-filter.component';

@Injectable()
export class SidenavFilterService {
    get visible() {
        return this._visible;
    }
    filters: FilterEntry[];
    width: number;

    private _visible: boolean;
    private subs: Subscription;

    constructor(private store: Store<fromRoot.State>) { }

    selectEntity(entity: BaseModel, selectType: SelectType, pageType: PageType, franchiseOrDivision: Franchise | Division) {
        if (entity instanceof Project) {
            this.selectProject(entity, pageType, selectType, franchiseOrDivision as Franchise);
        } else if (entity instanceof Warehouse) {
            this.selectWarehouse(entity, pageType, selectType, franchiseOrDivision as Division);
        }
    }

    selectMultipleEntity(entity: any[], selectType: SelectType, pageType: PageType, franchiseOrDivision: Franchise | Division, selectAll: boolean) {
        if (franchiseOrDivision instanceof Franchise) {
            this.selectMultipleProject(entity, pageType, selectType, franchiseOrDivision as Franchise, selectAll);
        } else if (franchiseOrDivision instanceof Division) {
            this.selectMultipleWarehouse(entity, pageType, selectType, franchiseOrDivision as Division, selectAll);
        }
    }


    showHide() {
        this.subs = this.store.select(fromRoot.getShowSidenavFilter).subscribe(showFilter => this._visible = showFilter);
        return this.subs;
    }

    toggleVisible() {
        if (this.visible) {
            this.store.dispatch(new sidenavFilterActions.CloseFilterAction());
        } else {
            this.store.dispatch(new sidenavFilterActions.OpenFilterAction());
        }
    }

    selectDefaultProject(projectId: number, franchiseId: number) {
        this.store.dispatch(new sidenavFilterActions.UpdateProjectAction({
            franchiseId,
            projectId,
            selectType: SelectType.Single,
            pageType: PageType.Transaction
        }));
    }

    unsubscribe() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

    private selectProject(project: Project, pageType: PageType, selectType: number, franchise: Franchise) {
        this.store.dispatch(new sidenavFilterActions.UpdateProjectAction({
            franchiseId: franchise.id,
            projectId: project.id,
            selectType,
            pageType
        }));
    }

    private selectMultipleProject(project: Project[], pageType: PageType, selectType: number, franchise: Franchise, selectAll: boolean) {
        this.store.dispatch(new sidenavFilterActions.UpdateMultipleProjectAction({
            franchiseId: franchise.id,
            projectId: project.map(val => val.id),
            selectType,
            pageType,
            selectAll
        }));
    }

    private selectWarehouse(warehouse: Warehouse, pageType: PageType, selectType: number, division: Division) {
        this.store.dispatch(new sidenavFilterActions.UpdateWarehouseAction({
            divisionId: division.id,
            warehouseId: warehouse.id,
            selectType,
            pageType
        }));
    }
    private selectMultipleWarehouse(warehouse: Warehouse[], pageType: PageType, selectType: number, division: Division, selectAll: boolean) {
        this.store.dispatch(new sidenavFilterActions.UpdateMultipleWarehouseAction({
            divisionId: division.id,
            warehouseId: warehouse.map(val => val.id),
            selectType,
            pageType,
            selectAll
        }));
    }
}

export {
    SelectType, PageType
};
