import { SubDepartment } from '../sub-departments/sub-department.model';
import { SOS_DEPARTMENTS } from './../../../../../shared/enums/sos-departments.enum';
import { BaseModel } from './../base/base.model';
import { DepartmentField } from './department-field.model';

export class Department extends BaseModel {
    id: number;
    studio_id: number = null;
    name: string = null;
    account_code: string = null;
    departmentFields?: DepartmentField[];
    subDepartments?: SubDepartment[];
    sos_department_id: number = null;

    constructor(departmentObj) {
        super();
        this.initializeData(departmentObj, this);
        if (departmentObj && departmentObj.departmentFields) {
            this.departmentFields = departmentObj.departmentFields.map(df => new DepartmentField(df));
        }
        if (departmentObj && departmentObj.subDepartments) {
            this.subDepartments = departmentObj.subDepartments.map(sd => new SubDepartment(sd)).sort((a, b) => +a.code - +b.code);
        }
    }

    getFieldIds(): number[] {
        if (!this.departmentFields) {
            return [];
        }
        return this.departmentFields.map(df => df.field_id);
    }

    accountCodeAsNumber() {
        return parseInt(this.account_code, 10);
    }

    isSosCostumeOrPropsDepartment() {
        return this.sos_department_id === SOS_DEPARTMENTS.CM || this.sos_department_id === SOS_DEPARTMENTS.PR;
    }

    isSosSetDepartment() {
        return this.sos_department_id === SOS_DEPARTMENTS.SET;
    }

    requiresCharOrSet() {
        return this.sos_department_id === SOS_DEPARTMENTS.CM || this.sos_department_id === SOS_DEPARTMENTS.SET || this.sos_department_id === SOS_DEPARTMENTS.PR;
    }
}
