import { SortObject } from '../../../../../shared/types';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import { TableFilterObject } from '../types';
import * as studioIndexActions from './studio-asset-index.actions';

export interface State {
    columnFiltersForStudioId: {
        [studioId: number]: ColumnFilterOptions;
    };
    studioAssetSort: {
        [studioId: number]: SortObject;
    };
    studioAssetFilter: {
        [studioId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    columnFiltersForStudioId: {},
    studioAssetSort: {},
    studioAssetFilter: {}
};

export function reducer(state = initialState, action: assetActions.Actions | studioIndexActions.Actions): State {
    switch (action.type) {
        case assetActions.LIST_BY_STUDIO_FILTERS_COMPLETE: {
            const studioId = action.payload.studioId;
            const columnFiltersForStudioId = { ...state.columnFiltersForStudioId, [studioId]: action.payload.columnFilters };

            return {
                ...state,
                columnFiltersForStudioId
            };
        }

        case studioIndexActions.SORT_STUDIO_LIST: {
            const studioId = action.payload.studioId;
            const assetSort = action.payload.sort;
            const currentSort = state.studioAssetSort[studioId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[studioId] = { ...action.payload.sort };

            return {
                ...state,
                studioAssetSort: { ...state.studioAssetSort, ...sortObj }
            };
        }

        case studioIndexActions.FILTER_STUDIO_LIST: {
            const studioId = action.payload.studioId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.studioAssetFilter, studioId);
                return {
                    ...state,
                    studioAssetFilter: {
                        ...state.studioAssetFilter,
                        [studioId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.studioAssetFilter[studioId],
                ...action.payload.filter
            };

            return {
                ...state,
                studioAssetFilter: {
                    ...state.studioAssetFilter,
                    [studioId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case studioIndexActions.LIST_TABLE_STATE_COMPLETE: {
            const studioId = action.payload.studioId;
            const { filter, sort } = listTableState(action.payload.tableState, state.studioAssetFilter, state.studioAssetSort, studioId);

            return {
                ...state,
                studioAssetFilter: filter,
                studioAssetSort: sort
            };
        }

        default: {
            return state;
        }
    }
}
