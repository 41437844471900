import { createSelector } from 'reselect';
import { ActionStatus, SortableIds } from '../types';
import { copyAndMultiSort, getEntitiesObject, SortOrder } from '../utils';
import * as userActions from './user.actions';
import { User } from './user.model';

export interface State {
    entities: {
        [userId: number]: User
    };
    finalApproverUserIds: number[];
    userId: number;
    complete: boolean;
    division: SortableIds;
    franchise: SortableIds;
    studio: SortableIds;
    updating: ActionStatus;
    deletingFranchisePermission: ActionStatus;
    deletingStudioPermission: ActionStatus;
    deletingDivisionPermission: ActionStatus;
}

export const initialState = {
    complete: true,
    entities: {},
    finalApproverUserIds: [],
    userId: null,
    division: {
        ids: [],
        sortKey: 'last_name',
        sortOrder: 1,
        sortArgs: []
    },
    franchise: {
        ids: [],
        sortKey: 'franchiseRole',
        sortOrder: 1,
        sortArgs: []
    },
    studio: {
        ids: [],
        sortKey: 'studioRole',
        sortOrder: 1,
        sortArgs: []
    },
    updating: ActionStatus.Complete,
    deletingFranchisePermission: ActionStatus.Complete,
    deletingStudioPermission: ActionStatus.Complete,
    deletingDivisionPermission: ActionStatus.Complete
};

const userSort: { [sortKey: string]: SortOrder[] } = {
    franchiseRole: [{ key: 'isFranchiseAdmin', invert: true }, { key: 'last_name', orderOverride: 1 }, { key: 'email', orderOverride: 1 }],
    studioRole: [{ key: 'studioRole', invert: true }, { key: 'last_name', orderOverride: 1 }]
};

export function reducer(state = initialState, action: userActions.Actions): State {
    switch (action.type) {
        case userActions.GET: {
            const alterStateWith = {
                userId: null,
                complete: false
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.GET_COMPLETE: {
            const user = action.payload;
            const entities = {};
            entities[user.id] = user;
            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...entities
                },
                userId: user.id,
                complete: true
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.GET_FAILED: {
            const alterStateWith = {
                userId: null,
                complete: true,
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.LIST_ALL_STUDIO_COMPLETE: {
            const users = action.payload.users;
            const studioId = action.payload.studioId;
            const usersObj = getEntitiesObject(users);

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...usersObj
                },
                studio: {
                    ...state.studio,
                    ids: users.map(user => user.id),
                    sortArgs: [studioId]
                }
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.LIST_BY_FRANCHISE_COMPLETE: {
            const users = action.payload.users;
            const franchise = action.payload.franchise;
            const usersObj = getEntitiesObject(users);
            const sorts = userSort[state.franchise.sortKey] ? userSort[state.franchise.sortKey] : [{ key: state.franchise.sortKey }];

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...usersObj
                },
                franchise: {
                    ...state.franchise,
                    ids: copyAndMultiSort(
                        users.map(user => user.id),
                        usersObj, sorts, state.franchise.sortOrder, [franchise]
                    ),
                    sortArgs: [franchise]
                }
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.SORT_FRANCHISE_LIST: {
            let sorted;
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;
            if (userSort[sortKey]) {
                sorted = copyAndMultiSort(state.franchise.ids, state.entities, userSort[sortKey], sortOrder, sortArgs);
            } else {
                sorted = copyAndMultiSort(state.franchise.ids, state.entities, [{ key: sortKey }], sortOrder, sortArgs);
            }

            return {
                ...state,
                franchise: {
                    ids: sorted,
                    sortKey,
                    sortOrder,
                    sortArgs
                }
            };
        }

        case userActions.LIST_BY_DIVISION_COMPLETE: {
            const users = action.payload.users;
            const division = action.payload.division;
            const usersObj = getEntitiesObject(users);
            const sorts = userSort[state.division.sortKey] ? userSort[state.division.sortKey] : [{ key: state.division.sortKey }];

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...usersObj
                },
                division: {
                    ...state.division,
                    ids: copyAndMultiSort(
                        users.map(user => user.id),
                        usersObj, sorts, state.division.sortOrder, [division]
                    ),
                    sortArgs: [division]
                }
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.SORT_DIVISION_LIST: {
            let sorted;
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;
            if (userSort[sortKey]) {
                sorted = copyAndMultiSort(state.division.ids, state.entities, userSort[sortKey], sortOrder, sortArgs);
            } else {
                sorted = copyAndMultiSort(state.division.ids, state.entities, [{ key: sortKey }], sortOrder, sortArgs);
            }

            return {
                ...state,
                division: {
                    ids: sorted,
                    sortKey,
                    sortOrder,
                    sortArgs
                }
            };
        }

        case userActions.LIST_BY_STUDIO_COMPLETE: {
            const users = action.payload.users;
            const studioId = action.payload.studioId;
            const usersObj = getEntitiesObject(users);
            const sorts = userSort[state.studio.sortKey] ? userSort[state.studio.sortKey] : [{ key: state.studio.sortKey }];

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...usersObj
                },
                studio: {
                    ...state.studio,
                    ids: copyAndMultiSort(
                        users.map(user => user.id),
                        usersObj, sorts, state.studio.sortOrder, [studioId]
                    ),
                    sortArgs: [studioId]
                }
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.SORT_STUDIO_LIST: {
            let sorted;
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;

            if (userSort[sortKey]) {
                sorted = copyAndMultiSort(state.studio.ids, state.entities, userSort[sortKey], sortOrder, sortArgs);
            } else {
                sorted = copyAndMultiSort(state.studio.ids, state.entities, [{ key: sortKey }], sortOrder, sortArgs);
            }

            return {
                ...state,
                studio: {
                    ids: sorted,
                    sortKey,
                    sortOrder,
                    sortArgs
                }
            };
        }

        case userActions.LIST_FINAL_APPROVERS: {
            return {
                ...state,
                finalApproverUserIds: []
            };
        }

        case userActions.LIST_FINAL_APPROVERS_COMPLETE: {
            const users = action.payload.users;
            const usersObj = getEntitiesObject(users);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...usersObj
                },
                finalApproverUserIds: users && users.length ? users.map(u => u.id) : []
            };
        }

        case userActions.CLEAR_FRANCHISE_LIST: {
            return {
                ...state,
                franchise: {
                    ...initialState.franchise
                }
            };
        }

        case userActions.CLEAR_DIVISION_LIST: {
            return {
                ...state,
                division: {
                    ...initialState.division
                }
            };
        }

        case userActions.UPDATE:
        case userActions.ENABLE_STUDIO_USERS_MFA:
        case userActions.DISABLE_STUDIO_USERS_MFA: {
            const alterStateWith = { updating: ActionStatus.Loading };
            return { ...state, ...alterStateWith };
        }

        case userActions.UPDATE_FAILED: {
            const alterStateWith = { updating: ActionStatus.Failed };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_FRANCHISE_PERMISSION: {
            const alterStateWith = { deletingFranchisePermission: ActionStatus.Loading };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_FRANCHISE_PERMISSION_FAILED: {
            const alterStateWith = { deletingFranchisePermission: ActionStatus.Failed };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_FRANCHISE_PERMISSION_COMPLETE: {
            const user = action.payload.user;
            const complete = action.payload.last;
            const entities = {};
            entities[user.id] = user;
            const franchiseUserIds = [...state.franchise.ids];
            const i = franchiseUserIds.indexOf(user.id);
            if (i > -1) {
                franchiseUserIds.splice(i, 1);
            }

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...entities
                },
                franchise: {
                    ...state.franchise,
                    ids: franchiseUserIds
                },
                deletingFranchisePermission: (complete) ? ActionStatus.Complete : ActionStatus.Loading
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_STUDIO_PERMISSION: {
            const alterStateWith = { deletingStudioPermission: ActionStatus.Loading };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_STUDIO_PERMISSION_FAILED: {
            const alterStateWith = { deletingStudioPermission: ActionStatus.Failed };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_STUDIO_PERMISSION_COMPLETE: {
            const user = action.payload;
            const entities = {};
            entities[user.id] = user;
            const studioUserIds = [...state.studio.ids];
            const i = studioUserIds.indexOf(user.id);
            if (i > -1) {
                studioUserIds.splice(i, 1);
            }

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...entities
                },
                studio: {
                    ...state.studio,
                    ids: studioUserIds
                },
                deletingStudioPermission: ActionStatus.Complete
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_DIVISION_PERMISSION: {
            const alterStateWith = { deletingDivisionPermission: ActionStatus.Loading };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_DIVISION_PERMISSION_FAILED: {
            const alterStateWith = { deletingDivisionPermission: ActionStatus.Failed };
            return { ...state, ...alterStateWith };
        }

        case userActions.DELETE_DIVISION_PERMISSION_COMPLETE: {
            const user = action.payload.user;
            const complete = action.payload.last;
            const entities = {};
            entities[user.id] = user;
            const divisionUserIds = [...state.division.ids];
            const i = divisionUserIds.indexOf(user.id);
            if (i > -1) {
                divisionUserIds.splice(i, 1);
            }

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...entities
                },
                division: {
                    ...state.franchise,
                    ids: divisionUserIds
                },
                deletingDivisionPermission: (complete) ? ActionStatus.Complete : ActionStatus.Loading
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.UPDATE_COMPLETE: {
            const user = action.payload;
            const entities = {};
            entities[user.id] = user;
            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...entities
                },
                updating: ActionStatus.Complete
            };

            return { ...state, ...alterStateWith };
        }

        case userActions.ENABLE_STUDIO_USERS_MFA_COMPLETE:
        case userActions.DISABLE_STUDIO_USERS_MFA_COMPLETE: {
            return { ...state, updating: ActionStatus.Complete };
        }

        case userActions.REMOVE_FROM_STORE: {
            const alterStateWith = {
                entities: [],
                userId: null,
            }

            return { ...state, ...alterStateWith };
        }

        default: {
            return state;
        }
    }
}

const userIdSelector = (state: State) => state.userId;
const divisionIdsSelector = (state: State) => state.division.ids;
const franchiseIdsSelector = (state: State) => state.franchise.ids;
const studioIdsSelector = (state: State) => state.studio.ids;

export const entitiesSelector = (state: State) => state.entities;
export const getFinalApproverUserIds = (state: State) => state.finalApproverUserIds;
export const getComplete = (state: State) => state.complete;
export const getUpdating = (state: State) => state.updating;
export const getDeletingFranchisePermission = (state: State) => state.deletingFranchisePermission;
export const getDeletingStudioPermission = (state: State) => state.deletingStudioPermission;
export const getDeletingDivisionPermission = (state: State) => state.deletingDivisionPermission;
export const getUser = createSelector(
    entitiesSelector,
    userIdSelector,
    (users, id) => {
        return users[id] ? users[id] : null;
    }
);
export const getUsersForFranchise = createSelector(
    entitiesSelector,
    franchiseIdsSelector,
    (users, ids) => ids.map(id => users[id])
);
export const getUsersForDivision = createSelector(
    entitiesSelector,
    divisionIdsSelector,
    (users, ids) => ids.map(id => users[id])
);
export const getUsersForStudio = createSelector(
    entitiesSelector,
    studioIdsSelector,
    (users, ids) => ids.map(id => users[id])
);
