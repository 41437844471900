import { createSelector } from 'reselect';
import * as root from '../';
import * as bulkMoveModalSelectors from '../bulk-move-modal/bulk-move-modal.selectors';

export const getSubLocationsForAssetModalLocation = createSelector(root.getSubLocationEntities, root.getLocationSubLocationIds, root.getAssetModalSelectedLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]).filter(sl => !!sl) : [];
});

export const getSubLocationsForBulkMoveModalLocation = createSelector(root.getSubLocationEntities, root.getLocationSubLocationIds, bulkMoveModalSelectors.getBulkMoveModalSelectedLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]).filter(sl => !!sl) : [];
});

export const getSubLocationsForExpandoRowLocation = createSelector(root.getSubLocationEntities, root.getLocationSubLocationIds, root.getTransactionExpandoRowSelectedLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]) : [];
});

export const getSubLocationsForSplitAssetsLocation = createSelector(root.getSubLocationEntities, root.getLocationSubLocationIds, root.getSplitAssetsSelectedLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]) : [];
});
