import { createSelector } from 'reselect';
import * as root from '../';

export const getWarehouseSubLocationsForAssetModalWarehouseLocation = createSelector(root.getWarehouseSubLocationEntities, root.getWarehouseLocationSubLocationIds, root.getAssetModalSelectedWarehouseLocationId, (warehouseSubLocationEntities, warehouseSubLocationIds, warehouseLocationId) => {
    const newWarehouseSubLocationEntities = [];
    if (!warehouseLocationId) {
        return [];
    }
    warehouseSubLocationIds[warehouseLocationId].forEach(id => {
        if (warehouseSubLocationEntities[id]) {
            newWarehouseSubLocationEntities.push(warehouseSubLocationEntities[id]);
        }
    });
    return newWarehouseSubLocationEntities;
});
