import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import Quagga from 'quagga';
import { take } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './barcode-modal.component.html',
    styleUrls: ['./barcode-modal.component.scss']
})
export class BarcodeModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('scannerContainer', { static: true }) scannerContainer: ElementRef;
    code: string = null;
    scanning = true;

    constructor(public deviceService: DeviceDetectorService, public dialogRef: MatDialogRef<BarcodeModalComponent>, private ref: ChangeDetectorRef) { }

    ngOnDestroy() {
        this.ref.detach();
    }

    ngAfterViewInit() {
        this.dialogRef.afterOpened().pipe(take(1)).subscribe((value) => {
            const facingMode = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? { exact: 'environment' } : 'environment';
            Quagga.init({
                inputStream: {
                    name: 'Live',
                    type: 'LiveStream',
                    target: document.querySelector('#scanner-container'),
                    constraints: {
                        width: { min: 640 },
                        height: { min: 480 },
                        facingMode
                    },
                    locate: true
                },
                decoder: {
                    readers: [
                        'code_128_reader',
                        'i2of5_reader'
                    ],
                }
            }, function(err) {
                if (err) {
                    console.error(err);
                    return;
                }
                Quagga.start();
            });
            Quagga.onProcessed((result) => this.onProcessed(result));
            Quagga.onDetected((result) => this.onDetected(result));
        });
    }

    onProcessed(result) {
        const drawingCtx = Quagga.canvas.ctx.overlay,
            drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
                result.boxes.filter(function(box) {
                    return box !== result.box;
                }).forEach(function(box) {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'green', lineWidth: 2 });
                });
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
            }

            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
            }
        }
    }

    onDetected(result) {
        this.scanning = false;
        const drawingCtx = Quagga.canvas.ctx.overlay,
            drawingCanvas = Quagga.canvas.dom.overlay;
        if (result.boxes) {
            drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
        }

        this.code = result.codeResult.code;
        if (!this.ref['destroyed']) {
            this.ref.detectChanges();
        }
        Quagga.stop();
    }

    cancelScanning() {
        this.code = null;
        Quagga.stop();
        this.dialogRef.close(this.code);
    }

    close() {
        Quagga.stop();
        this.dialogRef.close(this.code);
    }
}
