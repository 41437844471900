import { Action } from '@ngrx/store';

export const SELECT_PROJECT = '[Transaction Upload] Select Project';

export class SelectProjectAction implements Action {
    readonly type = SELECT_PROJECT;
    constructor(public payload: number) { }
}

export type Actions
    = SelectProjectAction;
