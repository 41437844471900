import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromRoot from '../../core/store';

@Injectable()
export class StudioGuard  {
    constructor(private router: Router, private store: Store<fromRoot.State>) { }

    canActivate(): Observable<boolean> {
        return this.store.select(fromRoot.getUser).pipe(
            filter(user => !!user),
            map((user) => {
                const studioId = user.current_studio_id;
                const franchiseId = user.current_franchise_id;
                const divisionId = user.current_division_id;

                if (Object.keys(user.permission.studioPermissions).length === 0) {
                    this.router.navigate(["/no-studio-perms"])
                }else if (divisionId && user.permission.isDivisionUser(divisionId, studioId)) {
                    this.router.navigate([`/division/${divisionId}`]);
                } else if (franchiseId && user.permission.isFranchiseUser(franchiseId, studioId)) {
                    this.router.navigate([`/franchise/${franchiseId}/assets`]);
                } else if (studioId && user.permission.isStudioUser(studioId)) {
                    this.routeToStudioIndex(user, studioId);
                } else {
                    for (const id in user.permission.studioPermissions) {
                        if (user.permission.studioPermissions.hasOwnProperty(id)) {
                            this.routeToStudioIndex(user, id);
                            return false;
                        }
                    }
                }
            }));
    }

    routeToStudioIndex(user, studioId) {
        if (user.hasDivisionPermission(parseInt(studioId, 10)) && !user.isStudioAdmin(studioId)) {
            window.location.href = `/studio/${studioId}/divisions`;
        } else {
            window.location.href = `/studio/${studioId}`;
        }
    }

    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }

    canLoad(): Observable<boolean> {
        return this.canActivate();
    }
}
