import { Action } from '@ngrx/store';
import { CustomStudioFieldName } from './custom-studio-field-name.model';

export const LIST = '[Custom Studio Field Name] List By Studio';
export const LIST_COMPLETE = '[Custom Studio Field Name] List Complete';
export const LIST_FAILED = '[Custom Studio Field Name] List Failed';

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: CustomStudioFieldName) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction;
