import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as assetHistoryActions from './asset-history.actions';
import { AssetHistory } from './asset-history.model';

@Injectable()
export class AssetHistoryEffects {
    static BASE_URL = '/api/asset-history';

    get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetHistoryActions.GET),
            map((action: assetHistoryActions.GetAction) => action.payload),
            switchMap((payload) => this.http.get(`${AssetHistoryEffects.BASE_URL}/${payload.asset_id}`).pipe(
                map((res: AssetHistory[]) => new assetHistoryActions.GetCompleteAction({ assetHistory: res.map(ah => new AssetHistory(ah)) })),
                catchError((error) => observableOf(new assetHistoryActions.UpdateFailedAction({ error })))
            ))
        ))

    update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetHistoryActions.UPDATE),
            map((action: assetHistoryActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`${AssetHistoryEffects.BASE_URL}/${data.assetHistoryId}/assets/${data.assetId}`, { note: data.note }).pipe(
                map((res: { assetHistory: AssetHistory }) => new assetHistoryActions.UpdateCompleteAction({ assetHistory: new AssetHistory(res.assetHistory) })),
                catchError((error) => observableOf(new assetHistoryActions.UpdateFailedAction({ error }))))
            )));

    failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetHistoryActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
