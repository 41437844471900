import { Action } from '@ngrx/store';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { SortObject, TableFilterObject } from '../types';

export const SORT_EVENT_ASSETS_LIST = '[Event Asset Index] Sort Event Asset List';
export const FILTER_EVENT_ASSETS_LIST = '[Event Asset Index] Filter Event Asset List';
export const LIST_TABLE_STATE = '[Event Asset Index] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Event Asset Index] List Table State Complete';

export class SortEventAssetsAction implements Action {
    readonly type = SORT_EVENT_ASSETS_LIST;

    constructor(public payload: { eventId: number, sort: SortObject }) { }
}

export class FilterEventAssetsListAction implements Action {
    readonly type = FILTER_EVENT_ASSETS_LIST;

    constructor(public payload: { eventId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { eventId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { eventId: number, tableState: TableState }) { }
}

export type Actions
    = SortEventAssetsAction
    | FilterEventAssetsListAction
    | ListTableStateAction
    | ListTableStateCompleteAction;
