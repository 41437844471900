import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Injectable } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FIELD_TYPES } from '../../../../shared/fields/field-types';
import { BaseField } from '../../core/store/models';
import { buildPDropdownOptions } from '../../core/store/utils';

@Injectable()
class AssetFieldErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return !!(control && control.invalid);
    }
}

@Component({
    selector: 'ah-asset-field-input',
    templateUrl: './asset-field-input.component.html',
    styleUrls: ['./asset-field-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: ErrorStateMatcher, useClass: AssetFieldErrorStateMatcher }
    ]
})
export class AssetFieldInputComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() autoFocus = false;
    @Input() autoSize = true;
    @Input() controlName?: string;
    @Input() disabled = false;
    @Input() field: BaseField;
    @Input() filter?= true; // Only for multi-select fields, to turn off filtering on mobile
    @Input() fullWidth?= false;
    @Input() group: UntypedFormGroup;
    @Input() icon = null;
    @Input() iconColor: string = null;
    @Input() isButton = false;
    @Input() placeholder: string;
    @Input() required = false;
    @Output() change = new EventEmitter();
    @Output() blur = new EventEmitter();
    @ViewChild('input', { static: false }) inputElement;

    fieldTypes = FIELD_TYPES;
    selectedValue = '';
    _options = [];
    optionsLookup = {};
    hasBeenFocused = false;
    needsSelect = false;

    @Input() set options(options) {
        // Temporary fix for picklist so that gender's value is displayed as the label
        if (options && options.length) {
            if (options[0].hasOwnProperty('value') && options[0].hasOwnProperty('label')) {
                this._options = options;
            } else {
                this._options = buildPDropdownOptions(options, { custom: true });
            }
        } else {
            this._options = options;
        }
        this.needsSelect = this.required;
        if (this.field.type === this.fieldTypes.PICKLIST.type && this._options !== null) {
            const sel = this._options.find(o => o.id === this.group.value[this.controlName]);
            if (sel) {
                this.selectedValue = sel.value;
                if (this.selectedValue !== null) {
                    this.needsSelect = false;
                }
            } else {
                this.selectedValue = this.field.name;
                if (this.required) {
                    this.selectedValue += ' *';
                }
            }
            for (const option of this._options) {
                this.optionsLookup[option.value] = option;
            }
        }
    }

    get options() {
        return this._options;
    }

    constructor(public deviceService: DeviceDetectorService) { }

    ngOnInit() {
        if (this.disabled) {
            this.group.controls[this.controlName].disable();
        }
        if (this.group.value[this.controlName] !== null) {
            this.needsSelect = false;
        }
    }

    removeDate() {
        if (this.field.type === this.fieldTypes.DATE.type && this.field.canonical_name === this.controlName) {
            this.group.controls[this.controlName].patchValue(null);
        }
    }

    ngAfterViewInit() {
        if (this.autoFocus && !this.hasBeenFocused && this.inputElement) {
            this.hasBeenFocused = true;
            setTimeout(() => {
                this.inputElement.nativeElement.focus();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.controlName) {
            this.controlName = this.field.canonical_name;
        }

        if (changes.group) {
            if (this.disabled) {
                this.group.controls[this.controlName].disable();
            }
        }
        if (changes.disabled) {
            const control = this.group.controls[this.controlName];

            if (changes.disabled.currentValue) {
                control.disable();
            } else if (control.disabled) {
                control.enable();
            }
        }
    }

    onBlur() {
        this.blur.emit();
    }

    onChange(event) {
        if (this.change) {
            this.change.emit(event.value);
        }
    }

    onMenuSelect(optionId: number) {
        const option = this.optionsLookup[optionId];
        this.group.patchValue({ [this.controlName]: option.value });
        this.selectedValue = option.label;
        this.needsSelect = option.value === null;
        if (this.change) {
            this.change.emit(option.value);
        }
    }

    onDateSelect() {
        if (this.change) {
            this.change.emit();
        }
    }
}
