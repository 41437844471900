import { Action } from '@ngrx/store';

export const SELECT_WAREHOUSE_LOCATION = '[Storage Box Modal] Select Warehouse Location';

export class SelectWarehouseLocationAction implements Action {
    readonly type = SELECT_WAREHOUSE_LOCATION;
    constructor(public payload: number) { }
}

export type Actions = SelectWarehouseLocationAction;
