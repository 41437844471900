import { BaseModel } from './../base/base.model';

export interface DepartmentPermissionObject {
    id: number;
    department_id: number;
    franchise_permission_id: number;
}

export class DepartmentPermission extends BaseModel {
    id: number;
    department_id: number = null;
    franchise_permission_id: number = null;

    constructor(departmentPermission: DepartmentPermissionObject) {
        super();
        this.initializeData(departmentPermission, this);
    }
}

export const departmentPermissionObjectMock: DepartmentPermissionObject = {
    id: 1,
    department_id: 1,
    franchise_permission_id: 1
};
