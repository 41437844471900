import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export const LIST = '[AssetSatellite] List';
export const LIST_COMPLETE = '[AssetSatellite] List Complete';
export const LIST_FAILED = '[AssetSatellite] List Failed';

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: { studioId: number, franchiseId: number, divisionId: number }) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: {}) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction;
