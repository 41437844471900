import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { getMoveModalFields, getUpdateFranchiseInfoModalFields } from '../../core/store/fields/field.service';
import {
    Asset,
    Division,
    Franchise,
    Location,
    Project,
    Studio,
    SubLocation,
    User,
    WarehouseLocation,
    WarehouseSubLocation
} from '../../core/store/models';
import { Field, StorageBox } from '../../core/store/models';
import {
    divisionSelectors,
    fieldSelectors,
    franchiseSelectors,
    locationSelectors,
    projectSelectors,
    rootSelectors as fromRoot,
    storageBoxSelectors,
    subLocationSelectors,
    warehouseLocationSelectors
} from '../../core/store/selectors';
import { TableTypes } from '../../core/store/types';
import { BulkMoveModalComponent, BulkMoveModalData } from '../components/bulk-move-modal/bulk-move-modal.component';
@Injectable()
export class BulkMoveModalDataService {
    divisions: Division[];
    fields: Field[];
    franchises: Franchise[];
    locations$: Observable<Location[]>;
    /** Franchises That Are Move Options */
    ownedFranchises: Franchise[];
    projects$: Observable<Project[]>;
    storageBoxes$: Observable<StorageBox[]>;
    studio: Studio;
    subLocationsForLocation$: Observable<SubLocation[]>;
    warehouseLocations$: Observable<WarehouseLocation[]>;
    warehouseSubLocationsForLocation$: Observable<WarehouseSubLocation[]>;

    private subs: Subscription;

    constructor(private store: Store<fromRoot.State>) { }

    openModal(dialog: MatDialog, assets: Asset[], user: User, checkAndSaveAssets, clearAllSelectedAssets: () => void, listAssetsAndFilters: () => void, tableType: TableTypes) {
        const data: BulkMoveModalData = {
            assets,
            fields: getMoveModalFields(this.fields),
            user,
            checkAndSaveAssets,
            clearAllSelectedAssets,
            divisions: this.divisions,
            franchises: this.franchises,
            locations$: this.locations$,
            ownedFranchises: this.ownedFranchises,
            projects$: this.projects$,
            storageBoxes$: this.storageBoxes$,
            studio: this.studio,
            subLocationsForLocation$: this.subLocationsForLocation$,
            tableType,
            warehouseLocations$: this.warehouseLocations$,
            warehouseSubLocationsForLocation$: this.warehouseSubLocationsForLocation$
        };
        dialog.open(BulkMoveModalComponent, { data }).afterClosed().pipe(take(1)).subscribe((movedAssets) => {
            this.unsubscribe();
            if (movedAssets && listAssetsAndFilters) {
                listAssetsAndFilters();
            }
        });
    }

    openFranchiseInfoModal(dialog: MatDialog, assets: Asset[], user: User, checkAndSaveAssets, clearAllSelectedAssets: () => void, listAssetsAndFilters: () => void, tableType: TableTypes, title: string) {
        const data: BulkMoveModalData = {
            assets,
            fields: getUpdateFranchiseInfoModalFields(this.fields),
            user,
            checkAndSaveAssets,
            clearAllSelectedAssets,
            divisions: this.divisions,
            franchises: this.franchises,
            locations$: this.locations$,
            ownedFranchises: this.ownedFranchises,
            projects$: this.projects$,
            storageBoxes$: this.storageBoxes$,
            studio: this.studio,
            subLocationsForLocation$: this.subLocationsForLocation$,
            tableType,
            title,
            verb: 'update',
            warehouseLocations$: this.warehouseLocations$,
            warehouseSubLocationsForLocation$: this.warehouseSubLocationsForLocation$
        };
        dialog.open(BulkMoveModalComponent, { data }).afterClosed().pipe(take(1)).subscribe((didUpdate) => {
            this.unsubscribe();
            if (didUpdate && listAssetsAndFilters) {
                listAssetsAndFilters();
            }
        });
    }

    dispatchData() {
        this.setupSubscriptions();
        this.getData();
    }

    unsubscribe() {
        if (this.subs) {
            this.subs.unsubscribe();
            this.subs = null;
        }
    }

    setupSubscriptions() {
        if (this.subs) {
            throw new Error('Subscriptions already setup');
        }
        this.subs = new Subscription();

        const studioSub = this.store.select(fromRoot.getSelectedStudio).subscribe(studio => this.studio = studio);

        const ownedFranchisesSub = this.store.select(franchiseSelectors.getOwnedFranchisesWithProjects).subscribe(franchises => this.ownedFranchises = franchises);
        const franchisesSub = this.store.select(franchiseSelectors.getAllFranchisesWithProjects).subscribe(franchises => this.franchises = franchises);

        const divisionsSub = this.store.select(divisionSelectors.getOwnedDivisionsWithWarehouses).subscribe(divs => {
            this.divisions = divs;
        });

        const allFieldsSub = this.store.select(fieldSelectors.getAllWithFakeColumns).subscribe(fields => {
            this.fields = fields;
        });

        this.subs
            .add(studioSub)
            .add(franchisesSub)
            .add(ownedFranchisesSub)
            .add(divisionsSub)
            .add(allFieldsSub);
    }

    getData() {
        this.locations$ = this.store.select(locationSelectors.getForBulkMoveModal);
        this.projects$ = this.store.select(projectSelectors.getForBulkMoveModalSelectedFranchise).pipe(filter(prj => !!prj));
        this.storageBoxes$ = this.store.select(storageBoxSelectors.getForBulkMoveModal);
        this.subLocationsForLocation$ = this.store.select(subLocationSelectors.getSubLocationsForBulkMoveModalLocation);
        this.warehouseLocations$ = this.store.select(warehouseLocationSelectors.getForBulkMoveModalSelectedWarehouse);
        this.warehouseSubLocationsForLocation$ = this.store.select(warehouseLocationSelectors.getSubLocationsForBulkMoveModal);
    }
}
