import { createSelector } from 'reselect';
import * as root from '../';

export const getFranchiseTrashState = (state: root.State) => state.franchiseTrash;

export const getFranchiseTrashAssetSort = createSelector(getFranchiseTrashState, (franchiseTrash) => franchiseTrash.trashedAssetSort);
export const getFranchiseTrashSortForSelectedFranchise = createSelector(getFranchiseTrashAssetSort, root.getSelectedFranchiseId, (sort, selectedFranchiseId) => {
    return (sort && sort[selectedFranchiseId]) ? sort[selectedFranchiseId] : { field: '', order: 1, args: [] };
});

export const getFranchiseTrashFilter = createSelector(getFranchiseTrashState, (franchiseTrash) => franchiseTrash.trashedAssetFilter);
export const getFranchiseAssetFilterForSelectedFranchise = createSelector(getFranchiseTrashFilter, root.getSelectedFranchiseId, (filter, selectedFranchiseId) => {
    return (filter && filter[selectedFranchiseId]) ? filter[selectedFranchiseId] : {};
});
