import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModel } from './../base/base.model';

export class Address extends BaseModel {
    id: number;
    line_1 = null;
    line_2 = null;
    line_3 = null;
    city = null;
    state_province_county = null;
    zip_postcode = null;
    country = null;

    constructor(projectObj) {
        super();
        this.initializeData(projectObj, this);
    }

    static getFormGroup(value) {
        const validators = [Validators.maxLength(255)];
        return new UntypedFormGroup({
            line_1: new UntypedFormControl(value ? value.line_1 : null, validators),
            line_2: new UntypedFormControl(value ? value.line_2 : null, validators),
            city: new UntypedFormControl(value ? value.city : null, validators),
            state_province_county: new UntypedFormControl(value ? value.state_province_county : null, validators),
            zip_postcode: new UntypedFormControl(value ? value.zip_postcode : null, validators),
            country: new UntypedFormControl(value ? value.country : null, validators)
        });
    }

    getDisplayLocation() {
        let location = '';
        if (this.city) {
            location = this.city;
        }
        if (this.state_province_county) {
            if (this.city) {
                location += ', ';
            }
            location += this.state_province_county;
        }
        if (this.country) {
            if (this.city || this.state_province_county) {
                location += ', ';
            }
            location += this.country;
        }
        return location;
    }
}
