import { Action } from '@ngrx/store';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { SortObject, TableFilterObject } from '../types';

export const SORT_GROUP_ASSET_LIST = '[Group View] Sort Group Asset List';
export const FILTER_GROUP_ASSET_LIST = '[Group View] Filter Group Asset List';
export const LIST_TABLE_STATE = '[Group View] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Group View] List Table State Complete';

export class SortGroupAssetsAction implements Action {
    readonly type = SORT_GROUP_ASSET_LIST;

    constructor(public payload: { groupId: number, sort: SortObject }) { }
}

export class FilterGroupAssetsAction implements Action {
    readonly type = FILTER_GROUP_ASSET_LIST;

    constructor(public payload: { groupId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { groupId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { groupId: number, tableState: TableState }) { }
}

export type Actions
    = SortGroupAssetsAction
    | FilterGroupAssetsAction
    | ListTableStateAction
    | ListTableStateCompleteAction;
