import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../core/store/users/user.model';

// https://developers.intercom.com/docs/intercom-javascript#section-intercomboot-intercomsettings
@Injectable()
export class IntercomService {
    constructor() { }

    get Intercom() {
        return (window as any).Intercom;
    }

    launch(user: User) {
        if (environment.production || environment.development || environment.staging) {
            this.shutdown();
            if (!!user) {
                this.Intercom('boot', {
                    app_id: environment.intercomAppId,
                    email: user.email,
                    user_id: user.id,
                    user_hash: user.intercom_hash,
                    created_at: user.created_at
                });
            } else {
                this.Intercom('boot', {
                    app_id: environment.intercomAppId,
                });
            }
        }
    }

    shutdown() {
        this.Intercom('shutdown');
    }
}
