import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as ledgerActions from './ledger.actions';
import { Ledger } from './ledger.model';

@Injectable()
export class LedgerEffects {
     createTransactions$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ledgerActions.CREATE_TRANSACTIONS),
            map((action: ledgerActions.CreateTransactionsAction) => {
                // Takes an entire ledger state, and extracts a single Ledger Model from it
                const ledgerObj = Object.assign({}, action.payload.ledgerState, { matches: action.payload.matches });
                const newLedger = new Ledger(ledgerObj);

                return newLedger.getObject();
            }),
            switchMap((ledger) => this.http.post(`/api/ledgers/${ledger.id}/transactions`, ledger).pipe(
                map(res => new ledgerActions.CreateTransactionsCompleteAction(res)),
                catchError((error) => observableOf(new ledgerActions.CreateTransactionsFailedAction({ error }))))
            )));

     getForTransaction$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ledgerActions.GET_FOR_TRANSACTION),
            map((action: ledgerActions.GetForTransactionAction) => action.payload),
            switchMap(transactionId => this.http.get<Ledger>(`/api/transactions/${transactionId}/ledger`).pipe(
                map(res => new Ledger(res)),
                map(ledger => new ledgerActions.GetForTransactionCompleteAction(ledger)),
                catchError(error => observableOf(new ledgerActions.GetForTransactionFailedAction({ error }))))
            )));

     listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ledgerActions.LIST_BY_FRANCHISE),
            map((action: ledgerActions.ListByFranchiseAction) => action.payload),
            switchMap((franchiseId) => this.http.get<Ledger[]>(`/api/franchises/${franchiseId}/ledgers`).pipe(
                map(res => res.map((l) => new Ledger(l))),
                map((ledgers: Ledger[]) => new ledgerActions.ListByFranchiseCompleteAction(ledgers)),
                catchError((error) => observableOf(new ledgerActions.ListByFranchiseFailedAction({ error, franchiseId }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ledgerActions.DELETE),
            map((action: ledgerActions.DeleteAction) => action.payload),
            switchMap((payload) => this.http.post(`/api/ledgers/${payload.ledger.id}`, payload).pipe(
                map(() => new ledgerActions.DeleteCompleteAction(payload.ledger)),
                catchError((error) => observableOf(new ledgerActions.DeleteFailedAction({ error }))))
            )));

     checkLedgerForPO$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ledgerActions.CHECK_LEDGER_FOR_PURCHASE_ORDER),
            switchMap((action: ledgerActions.CheckLedgerForPurchaseOrder) => this.http.post(`/api/ledgers/${action.payload.ledgerId}/check-purchase-order`, { type: action.payload.type, matches: action.payload.matches }).pipe(
                map((res: { hasPurchaseOrder: boolean, fallbackWarning?: boolean }) => new ledgerActions.CheckLedgerForPurchaseOrderComplete(res)),
                catchError((error) => observableOf(new ledgerActions.CheckLedgerForPurchaseOrderFailed({ error }))))
            )));

     requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(ledgerActions.CREATE_TRANSACTIONS_FAILED, ledgerActions.GET_FOR_TRANSACTION_FAILED, ledgerActions.LIST_BY_FRANCHISE_FAILED, ledgerActions.DELETE_FAILED, ledgerActions.CHECK_LEDGER_FOR_PURCHASE_ORDER_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
