import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import * as divisionActions from '../divisions/division.actions';
import { SortObject, TableFilterObject } from '../types';
import * as divisionAssetIndexActions from './division-asset-index.actions';

export interface State {
    activeTab: number;
    columnFiltersForDivisionId: {
        [divisionId: number]: ColumnFilterOptions;
    };
    columnFiltersForReviewForDivisionId: {
        [divisionId: number]: ColumnFilterOptions;
    }
    divisionAssetSort: {
        [divisionId: number]: SortObject;
    };
    divisionReviewAssetSort: {
        [divisionId: number]: SortObject;
    };
    divisionAssetFilter: {
        [divisionId: number]: TableFilterObject;
    };
    divisionAssetForReviewFilter: {
        [divisionId: number]: TableFilterObject;
    };
    validCache: {
        [divisionId: number]: boolean
    };
}

export const initialState: State = {
    activeTab: 0,
    columnFiltersForDivisionId: {},
    columnFiltersForReviewForDivisionId: {},
    divisionAssetSort: {},
    divisionReviewAssetSort: {},
    divisionAssetFilter: {},
    divisionAssetForReviewFilter: {},
    validCache: {}
};

export function reducer(state = initialState, action: divisionAssetIndexActions.Actions | divisionActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case divisionAssetIndexActions.SELECT_TAB: {
            return {
                ...state,
                activeTab: action.payload
            }
        }

        case divisionAssetIndexActions.SORT_DIVISION_LIST: {
            const divisionId = action.payload.divisionId;
            const assetSort = action.payload.sort;
            const currentSort = state.divisionAssetSort[divisionId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[divisionId] = { ...action.payload.sort };

            return {
                ...state,
                divisionAssetSort: { ...state.divisionAssetSort, ...sortObj }
            };
        }

        case divisionAssetIndexActions.SORT_DIVISION_LIST_FOR_REVIEW_LIST: {
            const { divisionId, sort } = action.payload;
            const currentSort = state.divisionReviewAssetSort[divisionId];

            if (currentSort && currentSort.field === sort.field && currentSort.order === sort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[divisionId] = { ...sort };

            return {
                ...state,
                divisionReviewAssetSort: { ...state.divisionReviewAssetSort, ...sortObj }
            }
        }

        case divisionAssetIndexActions.FILTER_DIVISION_LIST: {
            const divisionId = action.payload.divisionId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.divisionAssetFilter, divisionId);
                return {
                    ...state,
                    divisionAssetFilter: {
                        ...state.divisionAssetFilter,
                        [divisionId]: globalFilter
                    },
                    validCache: {
                        ...state.validCache,
                        [divisionId]: false
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.divisionAssetFilter[divisionId],
                ...action.payload.filter
            };

            return {
                ...state,
                divisionAssetFilter: {
                    ...state.divisionAssetFilter,
                    [divisionId]: deleteUnusedFilterKeys(updatedFilter)
                },
                validCache: {
                    ...state.validCache,
                    [divisionId]: false
                }
            };
        }

        case divisionAssetIndexActions.FILTER_DIVISION_FOR_REVIEW_LIST: {
            const { divisionId, reset, filter } = action.payload;
            if (reset) {
                const globalFilter = getExistingGlobalSearch(state.divisionAssetForReviewFilter, divisionId);
                return {
                    ...state,
                    divisionAssetForReviewFilter: {
                        ...state.divisionAssetForReviewFilter,
                        [divisionId]: globalFilter
                    }
                }
            }

            const updateFilter: TableFilterObject = {
                ...state.divisionAssetForReviewFilter[divisionId],
                ...filter
            }

            return {
                ...state,
                divisionAssetForReviewFilter: {
                    ...state.divisionAssetForReviewFilter,
                    [divisionId]: deleteUnusedFilterKeys(updateFilter)
                }
            }
        }

        case assetActions.LIST_BY_DIVISION_FILTERS_COMPLETE: {
            const columnFiltersForDivisionId = { ...state.columnFiltersForDivisionId };
            columnFiltersForDivisionId[action.payload.divisionId] = action.payload.columnFilters;

            return {
                ...state,
                columnFiltersForDivisionId
            };
        }

        case assetActions.LIST_BY_DIVISION: {
            return {
                ...state,
                validCache: {
                    ...state.validCache,
                    [action.payload.divisionId]: true
                }
            };
        }

        case divisionAssetIndexActions.INVALIDATE_DIVISION_CACHE: {
            return {
                ...state,
                validCache: {
                    ...state.validCache,
                    [action.payload.divisionId]: action.payload.validCache
                }
            };
        }

        case divisionAssetIndexActions.LIST_TABLE_STATE_COMPLETE: {
            const divisionId = action.payload.divisionId;
            const { filter, sort } = listTableState(action.payload.tableState, state.divisionAssetFilter, state.divisionAssetSort, divisionId);

            return {
                ...state,
                divisionAssetFilter: filter,
                divisionAssetSort: sort
            };
        }

        case divisionAssetIndexActions.LIST_REVIEW_TABLE_STATE_COMPLETE: {
            const divisionId = action.payload.divisionId;
            const { filter, sort } = listTableState(action.payload.tableState, state.divisionAssetForReviewFilter, state.divisionReviewAssetSort, divisionId);
            return {
                ...state,
                divisionAssetForReviewFilter: filter,
                divisionReviewAssetSort: sort
            }
        }

        default: {
            return state;
        }
    }
}
export const getActiveTab = (state: State) => state.activeTab;
export const getDefaultAssetSort = (state: State) => state.divisionAssetSort;
export const getDivisionAssetSort = (state: State) => state.divisionAssetSort;
export const getReviewAssetSort = (state: State) => state.divisionReviewAssetSort;
export const getDivisionAssetFilter = (state: State) => state.divisionAssetFilter;
