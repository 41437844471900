import { Action } from '@ngrx/store';
import { Field } from './field.model';

export const CREATE = '[Field] Create';
export const CREATE_COMPLETE = '[Field] Create Complete';
export const CREATE_FAILED = '[Field] Create Failed';

export const UPDATE = '[Field] Update';
export const UPDATE_COMPLETE = '[Field] Update Complete';
export const UPDATE_FAILED = '[Field] Update Failed';

export const LIST = '[Field] List By Studio';
export const LIST_COMPLETE = '[Field] List Complete';
export const LIST_FAILED = '[Field] List Failed';

export const DELETE = '[Field] Delete';
export const DELETE_COMPLETE = '[Field] Delete Complete';
export const DELETE_FAILED = '[Field] Delete Failed';

export const LIST_ASSET_SELECTED = '[Field] List Asset Selected';
export const LIST_ASSET_SELECTED_COMPLETE = '[Field] List Asset Selected Complete';
export const SAVE_ASSET_SELECTED = '[Field] Save Asset Selected';
export const SAVE_ASSET_SELECTED_COMPLETE = '[Field] Save Asset Selected Complete';
export const SAVE_ASSET_SELECTED_FAILED = '[Field] Save Asset Selected Failed';

export const LIST_TRANSACTION_SELECTED = '[Field] List Transaction Selected';
export const LIST_TRANSACTION_SELECTED_COMPLETE = '[Field] List Transaction Selected Complete';

export const SAVE_TRANSACTION_SELECTED = '[Field] Save Transaction Selected';
export const SAVE_TRANSACTION_SELECTED_COMPLETE = '[Field] Save Transaction Selected Complete';
export const SAVE_TRANSACTION_SELECTED_FAILED = '[Field] Save Transaction Selected Failed';

export const SAVE_EXCLUDED_TRANSACTION_SELECTED = '[Field] Save Excluded Fields Transaction Selected';
export const SAVE_EXCLUDED_TRANSACTION_SELECTED_COMPLETE = '[Field] Save Excluded Fields Transaction Selected Complete';
export const SAVE_EXCLUDED_TRANSACTION_SELECTED_FAILED = '[Field] Save Excluded Fields Transaction Selected Failed';

export const LIST_DIVISION_ASSET_SELECTED = '[Field] List Division Asset Selected';
export const LIST_DIVISION_ASSET_SELECTED_COMPLETE = '[Field] List Division Asset Selected Complete';
export const SAVE_DIVISION_ASSET_SELECTED = '[Field] Save Division Asset Selected';
export const SAVE_DIVISION_ASSET_SELECTED_COMPLETE = '[Field] Save Division Asset Selected Complete';
export const SAVE_DIVISION_ASSET_SELECTED_FAILED = '[Field] Save Division Asset Selected Failed';

export const LIST_EVENT_ASSET_SELECTED = '[Field] List Event Asset Selected';
export const LIST_EVENT_ASSET_SELECTED_COMPLETE = '[Field] List Event Asset Selected Complete';
export const SAVE_EVENT_ASSET_SELECTED = '[Field] Save Event Asset Selected';
export const SAVE_EVENT_ASSET_SELECTED_COMPLETE = '[Field] Save Event Asset Selected Complete';
export const SAVE_EVENT_ASSET_SELECTED_FAILED = '[Field] Save Event Asset Selected Failed';

export const LIST_FRANCHISE_GROUP_ASSET_SELECTED = '[Field] List Franchise Group Asset Selected';
export const LIST_FRANCHISE_GROUP_ASSET_SELECTED_COMPLETE = '[Field] List Franchise Group Asset Selected Complete';
export const SAVE_FRANCHISE_GROUP_ASSET_SELECTED = '[Field] Save Franchise Group Asset Selected';
export const SAVE_FRANCHISE_GROUP_ASSET_SELECTED_COMPLETE = '[Field] Save Franchise Group Asset Selected Complete';
export const SAVE_FRANCHISE_GROUP_ASSET_SELECTED_FAILED = '[Field] Save Franchise Group Asset Selected Failed';

export const LIST_DIVISION_GROUP_ASSET_SELECTED = '[Field] List Division Group Asset Selected';
export const LIST_DIVISION_GROUP_ASSET_SELECTED_COMPLETE = '[Field] List Division Group Asset Selected Complete';
export const SAVE_DIVISION_GROUP_ASSET_SELECTED = '[Field] Save Division Group Asset Selected';
export const SAVE_DIVISION_GROUP_ASSET_SELECTED_COMPLETE = '[Field] Save Division Group Asset Selected Complete';
export const SAVE_DIVISION_GROUP_ASSET_SELECTED_FAILED = '[Field] Save Division Group Asset Selected Failed';

export const LIST_STUDIO_GROUP_ASSET_SELECTED = '[Field] List Studio Group Asset Selected';
export const LIST_STUDIO_GROUP_ASSET_SELECTED_COMPLETE = '[Field] List Studio Group Asset Selected Complete';
export const SAVE_STUDIO_GROUP_ASSET_SELECTED = '[Field] Save Studio Group Asset Selected';
export const SAVE_STUDIO_GROUP_ASSET_SELECTED_COMPLETE = '[Field] Save Studio Group Asset Selected Complete';
export const SAVE_STUDIO_GROUP_ASSET_SELECTED_FAILED = '[Field] Save Studio Group Asset Selected Failed';

export const LIST_FRANCHISE_SUBGROUP_ASSET_SELECTED = '[Field] List Franchise SubGroup Asset Selected';
export const LIST_FRANCHISE_SUBGROUP_ASSET_SELECTED_COMPLETE = '[Field] List Franchise SubGroup Asset Selected Complete';
export const SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED = '[Field] Save Franchise SubGroup Asset Selected';
export const SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED_COMPLETE = '[Field] Save Franchise SubGroup Asset Selected Complete';
export const SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED_FAILED = '[Field] Save Franchise SubGroup Asset Selected Failed';

export const LIST_DIVISION_SUBGROUP_ASSET_SELECTED = '[Field] List Division SubGroup Asset Selected';
export const LIST_DIVISION_SUBGROUP_ASSET_SELECTED_COMPLETE = '[Field] List Division SubGroup Asset Selected Complete';
export const SAVE_DIVISION_SUBGROUP_ASSET_SELECTED = '[Field] Save Division SubGroup Asset Selected';
export const SAVE_DIVISION_SUBGROUP_ASSET_SELECTED_COMPLETE = '[Field] Save Division SubGroup Asset Selected Complete';
export const SAVE_DIVISION_SUBGROUP_ASSET_SELECTED_FAILED = '[Field] Save Division SubGroup Asset Selected Failed';

export const LIST_STUDIO_SUBGROUP_ASSET_SELECTED = '[Field] List Studio SubGroup Asset Selected';
export const LIST_STUDIO_SUBGROUP_ASSET_SELECTED_COMPLETE = '[Field] List Studio SubGroup Asset Selected Complete';
export const SAVE_STUDIO_SUBGROUP_ASSET_SELECTED = '[Field] Save Studio SubGroup Asset Selected';
export const SAVE_STUDIO_SUBGROUP_ASSET_SELECTED_COMPLETE = '[Field] Save Studio SubGroup Asset Selected Complete';
export const SAVE_STUDIO_SUBGROUP_ASSET_SELECTED_FAILED = '[Field] Save Studio SubGroup Asset Selected Failed';

export const LIST_REQUEST_ASSET_SELECTED = '[Field] List Request Asset Selected';
export const LIST_REQUEST_ASSET_SELECTED_COMPLETE = '[Field] List Request Asset Selected Complete';
export const SAVE_REQUEST_ASSET_SELECTED = '[Field] Save Request Asset Selected';
export const SAVE_REQUEST_ASSET_SELECTED_COMPLETE = '[Field] Save Request Asset Selected Complete';
export const SAVE_REQUEST_ASSET_SELECTED_FAILED = '[Field] Save Request Asset Selected Failed';

export const SAVE_MATCH_ASSET_SELECTED = '[Field] Save Match Asset Selected';
export const SAVE_MATCH_ASSET_SELECTED_COMPLETE = '[Field] Save Match Asset Selected Complete';
export const SAVE_MATCH_ASSET_SELECTED_FAILED = '[Field] Save Match Asset Selected Failed';

export const LIST_MATCH_ASSET_SELECTED = '[Field] List Match Asset Selected';
export const LIST_MATCH_ASSET_SELECTED_COMPLETE = '[Field] List Match Asset Selected Complete';

export const SAVE_MATCH_REVIEW_SELECTED = '[Field] Save Match Review Selected';
export const SAVE_MATCH_REVIEW_SELECTED_COMPLETE = '[Field] Save Match Review Selected Complete';
export const SAVE_MATCH_REVIEW_SELECTED_FAILED = '[Field] Save Match Review Selected Failed';

export const LIST_MATCH_REVIEW_SELECTED = '[Field] List Match Review Selected';
export const LIST_MATCH_REVIEW_SELECTED_COMPLETE = '[Field] List Match Review Selected Complete';

export const UPDATE_TRANSACTION_FIELDS = '[Field] Update Transaction Fields';
export const UPDATE_TRANSACTION_FIELDS_COMPLETE = '[Field] Update Transaction Fields Complete';

export const UPDATE_TRANSACTION_TAB_FIELDS = '[Field] Update Transaction Tab Fields';
export const UPDATE_TRANSACTION_TAB_FIELDS_COMPLETE = '[Field] Update Transaction Tab Fields Complete';

export const SAVE_STUDIO_SELECTED = '[Field] Save Studio Selected';
export const SAVE_STUDIO_SELECTED_COMPLETE = '[Field] Save Studio Selected Complete';
export const SAVE_STUDIO_SELECTED_FAILED = '[Field] Save Studio Selected Failed';

export const LIST_STUDIO_SELECTED = '[Field] List Studio Selected';
export const LIST_STUDIO_SELECTED_COMPLETE = '[Field] List Studio Selected Complete';

export class CreateAction implements Action {
    readonly type = CREATE;

    constructor(public payload: { studioId: number, field: Field, deptIds: number[], requiredInAllDepts: boolean }) { }
}

export class CreateCompleteAction implements Action {
    readonly type = CREATE_COMPLETE;

    constructor(public payload: { field: Field }) { }
}

export class CreateFailedAction implements Action {
    readonly type = CREATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { studioId: number, fieldId: number, field: Field, deptIds: number[], requiredInAllDepts: boolean }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { field: Field }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: Field) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Field) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: any }) { }
}


export interface ListCompletePayload {
    fields: Field[];
    studioId: number;
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: ListCompletePayload) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class ListAssetSelectedAction implements Action {
    readonly type = LIST_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListAssetSelectedCompleteAction implements Action {
    readonly type = LIST_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveAssetSelectedAction implements Action {
    readonly type = SAVE_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveAssetSelectedFailedAction implements Action {
    readonly type = SAVE_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListTransactionSelectedAction implements Action {
    readonly type = LIST_TRANSACTION_SELECTED;

    constructor(public payload?) { }
}

export class ListTransactionSelectedCompleteAction implements Action {
    readonly type = LIST_TRANSACTION_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveTransactionSelectedAction implements Action {
    readonly type = SAVE_TRANSACTION_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveTransactionSelectedCompleteAction implements Action {
    readonly type = SAVE_TRANSACTION_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveTransactionSelectedFailedAction implements Action {
    readonly type = SAVE_TRANSACTION_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class SaveExcludedTransactionSelectedAction implements Action {
    readonly type = SAVE_EXCLUDED_TRANSACTION_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveExcludedTransactionSelectedCompleteAction implements Action {
    readonly type = SAVE_EXCLUDED_TRANSACTION_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveExcludedTransactionSelectedFailedAction implements Action {
    readonly type = SAVE_EXCLUDED_TRANSACTION_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListDivisionAssetSelectedAction implements Action {
    readonly type = LIST_DIVISION_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListDivisionAssetSelectedCompleteAction implements Action {
    readonly type = LIST_DIVISION_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveDivisionAssetSelectedAction implements Action {
    readonly type = SAVE_DIVISION_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveDivisionAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_DIVISION_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveDivisionAssetSelectedFailedAction implements Action {
    readonly type = SAVE_DIVISION_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListEventAssetSelectedAction implements Action {
    readonly type = LIST_EVENT_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListEventAssetSelectedCompleteAction implements Action {
    readonly type = LIST_EVENT_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveEventAssetSelectedAction implements Action {
    readonly type = SAVE_EVENT_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveEventAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_EVENT_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveEventAssetSelectedFailedAction implements Action {
    readonly type = SAVE_EVENT_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListFranchiseGroupAssetSelectedAction implements Action {
    readonly type = LIST_FRANCHISE_GROUP_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListFranchiseGroupAssetSelectedCompleteAction implements Action {
    readonly type = LIST_FRANCHISE_GROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveFranchiseGroupAssetSelectedAction implements Action {
    readonly type = SAVE_FRANCHISE_GROUP_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveFranchiseGroupAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_FRANCHISE_GROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveFranchiseGroupAssetSelectedFailedAction implements Action {
    readonly type = SAVE_FRANCHISE_GROUP_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListDivisionGroupAssetSelectedAction implements Action {
    readonly type = LIST_DIVISION_GROUP_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListDivisionGroupAssetSelectedCompleteAction implements Action {
    readonly type = LIST_DIVISION_GROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveDivisionGroupAssetSelectedAction implements Action {
    readonly type = SAVE_DIVISION_GROUP_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveDivisionGroupAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_DIVISION_GROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveDivisionGroupAssetSelectedFailedAction implements Action {
    readonly type = SAVE_DIVISION_GROUP_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListStudioGroupAssetSelectedAction implements Action {
    readonly type = LIST_STUDIO_GROUP_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListStudioGroupAssetSelectedCompleteAction implements Action {
    readonly type = LIST_STUDIO_GROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveStudioGroupAssetSelectedAction implements Action {
    readonly type = SAVE_STUDIO_GROUP_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveStudioGroupAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_STUDIO_GROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveStudioGroupAssetSelectedFailedAction implements Action {
    readonly type = SAVE_STUDIO_GROUP_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}
export class ListFranchiseSubGroupAssetSelectedAction implements Action {
    readonly type = LIST_FRANCHISE_SUBGROUP_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListFranchiseSubGroupAssetSelectedCompleteAction implements Action {
    readonly type = LIST_FRANCHISE_SUBGROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveFranchiseSubGroupAssetSelectedAction implements Action {
    readonly type = SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveFranchiseSubGroupAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveFranchiseSubGroupAssetSelectedFailedAction implements Action {
    readonly type = SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListDivisionSubGroupAssetSelectedAction implements Action {
    readonly type = LIST_DIVISION_SUBGROUP_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListDivisionSubGroupAssetSelectedCompleteAction implements Action {
    readonly type = LIST_DIVISION_SUBGROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveDivisionSubGroupAssetSelectedAction implements Action {
    readonly type = SAVE_DIVISION_SUBGROUP_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveDivisionSubGroupAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_DIVISION_SUBGROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveDivisionSubGroupAssetSelectedFailedAction implements Action {
    readonly type = SAVE_DIVISION_SUBGROUP_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListStudioSubGroupAssetSelectedAction implements Action {
    readonly type = LIST_STUDIO_SUBGROUP_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListStudioSubGroupAssetSelectedCompleteAction implements Action {
    readonly type = LIST_STUDIO_SUBGROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveStudioSubGroupAssetSelectedAction implements Action {
    readonly type = SAVE_STUDIO_SUBGROUP_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveStudioSubGroupAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_STUDIO_SUBGROUP_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveStudioSubGroupAssetSelectedFailedAction implements Action {
    readonly type = SAVE_STUDIO_SUBGROUP_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListRequestAssetSelectedAction implements Action {
    readonly type = LIST_REQUEST_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListRequestAssetSelectedCompleteAction implements Action {
    readonly type = LIST_REQUEST_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveRequestAssetSelectedAction implements Action {
    readonly type = SAVE_REQUEST_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveRequestAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_REQUEST_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveRequestAssetSelectedFailedAction implements Action {
    readonly type = SAVE_REQUEST_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class UpdateTransactionFieldsAction implements Action {
    readonly type = UPDATE_TRANSACTION_FIELDS;

    constructor(public payload: number[]) { }
}

export class UpdateTransactionFieldsCompleteAction implements Action {
    readonly type = UPDATE_TRANSACTION_FIELDS_COMPLETE;

    constructor(public payload: number[]) { }
}

export class UpdateTransactionTabFieldsAction implements Action {
    readonly type = UPDATE_TRANSACTION_TAB_FIELDS;

    constructor(public payload: number[]) { }
}

export class UpdateTransactionTabFieldsCompleteAction implements Action {
    readonly type = UPDATE_TRANSACTION_TAB_FIELDS_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveMatchAssetSelectedAction implements Action {
    readonly type = SAVE_MATCH_ASSET_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveMatchAssetSelectedCompleteAction implements Action {
    readonly type = SAVE_MATCH_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveMatchAssetSelectedFailedAction implements Action {
    readonly type = SAVE_MATCH_ASSET_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListMatchAssetSelectedAction implements Action {
    readonly type = LIST_MATCH_ASSET_SELECTED;

    constructor(public payload?) { }
}

export class ListMatchAssetSelectedCompleteAction implements Action {
    readonly type = LIST_MATCH_ASSET_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveMatchReviewSelectedAction implements Action {
    readonly type = SAVE_MATCH_REVIEW_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveMatchReviewSelectedCompleteAction implements Action {
    readonly type = SAVE_MATCH_REVIEW_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveMatchReviewSelectedFailedAction implements Action {
    readonly type = SAVE_MATCH_REVIEW_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListMatchReviewSelectedAction implements Action {
    readonly type = LIST_MATCH_REVIEW_SELECTED;

    constructor(public payload?) { }
}

export class ListMatchReviewSelectedCompleteAction implements Action {
    readonly type = LIST_MATCH_REVIEW_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveStudioSelectedAction implements Action {
    readonly type = SAVE_STUDIO_SELECTED;

    constructor(public payload: number[]) { }
}

export class SaveStudioSelectedCompleteAction implements Action {
    readonly type = SAVE_STUDIO_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export class SaveStudioSelectedFailedAction implements Action {
    readonly type = SAVE_STUDIO_SELECTED_FAILED;

    constructor(public payload: Error) { }
}

export class ListStudioSelectedAction implements Action {
    readonly type = LIST_STUDIO_SELECTED;

    constructor(public payload?) { }
}

export class ListStudioSelectedCompleteAction implements Action {
    readonly type = LIST_STUDIO_SELECTED_COMPLETE;

    constructor(public payload: number[]) { }
}

export type Actions
    = CreateAction
    | CreateCompleteAction
    | CreateFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | ListAssetSelectedAction
    | ListAssetSelectedCompleteAction
    | SaveAssetSelectedAction
    | SaveAssetSelectedCompleteAction
    | SaveAssetSelectedFailedAction
    | ListTransactionSelectedAction
    | ListTransactionSelectedCompleteAction
    | SaveTransactionSelectedAction
    | SaveTransactionSelectedCompleteAction
    | SaveTransactionSelectedFailedAction
    | SaveExcludedTransactionSelectedAction
    | SaveExcludedTransactionSelectedCompleteAction
    | SaveExcludedTransactionSelectedFailedAction
    | ListDivisionAssetSelectedAction
    | ListDivisionAssetSelectedCompleteAction
    | SaveDivisionAssetSelectedAction
    | SaveDivisionAssetSelectedCompleteAction
    | SaveDivisionAssetSelectedFailedAction
    | ListEventAssetSelectedAction
    | ListEventAssetSelectedCompleteAction
    | SaveEventAssetSelectedAction
    | SaveEventAssetSelectedCompleteAction
    | SaveEventAssetSelectedFailedAction
    | ListFranchiseGroupAssetSelectedAction
    | ListFranchiseGroupAssetSelectedCompleteAction
    | SaveFranchiseGroupAssetSelectedAction
    | SaveFranchiseGroupAssetSelectedCompleteAction
    | SaveFranchiseGroupAssetSelectedFailedAction
    | ListDivisionGroupAssetSelectedAction
    | ListDivisionGroupAssetSelectedCompleteAction
    | SaveDivisionGroupAssetSelectedAction
    | SaveDivisionGroupAssetSelectedCompleteAction
    | SaveDivisionGroupAssetSelectedFailedAction
    | ListStudioGroupAssetSelectedAction
    | ListStudioGroupAssetSelectedCompleteAction
    | SaveStudioGroupAssetSelectedAction
    | SaveStudioGroupAssetSelectedCompleteAction
    | SaveStudioGroupAssetSelectedFailedAction
    | ListFranchiseSubGroupAssetSelectedAction
    | ListFranchiseSubGroupAssetSelectedCompleteAction
    | SaveFranchiseSubGroupAssetSelectedAction
    | SaveFranchiseSubGroupAssetSelectedCompleteAction
    | SaveFranchiseSubGroupAssetSelectedFailedAction
    | ListDivisionSubGroupAssetSelectedAction
    | ListDivisionSubGroupAssetSelectedCompleteAction
    | SaveDivisionSubGroupAssetSelectedAction
    | SaveDivisionSubGroupAssetSelectedCompleteAction
    | SaveDivisionSubGroupAssetSelectedFailedAction
    | ListStudioSubGroupAssetSelectedAction
    | ListStudioSubGroupAssetSelectedCompleteAction
    | SaveStudioSubGroupAssetSelectedAction
    | SaveStudioSubGroupAssetSelectedCompleteAction
    | SaveStudioSubGroupAssetSelectedFailedAction
    | ListRequestAssetSelectedAction
    | ListRequestAssetSelectedCompleteAction
    | SaveRequestAssetSelectedAction
    | SaveRequestAssetSelectedCompleteAction
    | SaveRequestAssetSelectedFailedAction
    | UpdateTransactionFieldsAction
    | UpdateTransactionFieldsCompleteAction
    | UpdateTransactionTabFieldsAction
    | UpdateTransactionTabFieldsCompleteAction
    | SaveMatchAssetSelectedAction
    | SaveMatchAssetSelectedCompleteAction
    | SaveMatchAssetSelectedFailedAction
    | ListMatchAssetSelectedAction
    | ListMatchAssetSelectedCompleteAction
    | SaveMatchReviewSelectedAction
    | SaveMatchReviewSelectedCompleteAction
    | SaveMatchReviewSelectedFailedAction
    | ListMatchReviewSelectedAction
    | ListMatchReviewSelectedCompleteAction
    | SaveStudioSelectedAction
    | SaveStudioSelectedCompleteAction
    | SaveStudioSelectedFailedAction
    | ListStudioSelectedAction
    | ListStudioSelectedCompleteAction;
