import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { Asset } from '../assets/asset.model';
import * as layoutActions from '../layout/layout.actions';
import { AssetEvent } from '../models';
import * as eventActions from './event.actions';
import { Event } from './event.model';

@Injectable()
export class EventEffects {
    static BASE_URL = '/api/events';

    add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.ADD),
            map((action: eventActions.AddAction) => action.payload),
            switchMap((data) => this.http.post(EventEffects.BASE_URL, data).pipe(
                map(res => new Event(res)),
                map((event: Event) => new eventActions.AddCompleteAction(event)),
                catchError((error) => observableOf(new eventActions.AddFailedAction({ error }))))
            )));

    get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.GET),
            map((action: eventActions.GetAction) => action.payload),
            switchMap((eventId) => this.http.get(`/api/events/${eventId}`).pipe(
                map((res: { event: Event, assetEvents: AssetEvent[] }) => new eventActions.GetCompleteAction({ event: res.event ? new Event(res.event) : null, assetEvents: res.assetEvents ? res.assetEvents.map(ae => new AssetEvent(ae)) : null })),
                catchError((error) => observableOf(new eventActions.GetFailedAction({ error }))))
            )));

    addAssets$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.ADD_ASSETS),
            map((action: eventActions.AddAssetsAction) => action.payload),
            switchMap((data) => this.http.post<Asset[]>(`${EventEffects.BASE_URL}/${data.eventId}/assets`, data.assetIds).pipe(
                map(res => res.map((a) => new Asset(a))),
                map((assets: Asset[]) => new eventActions.AddAssetsCompleteAction({ assets, eventId: data.eventId })),
                catchError((error) => observableOf(new eventActions.AddAssetsFailedAction({ error }))))
            )));

    delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.DELETE),
            map((action: eventActions.DeleteAction) => action.payload),
            switchMap((eventData) => this.http.delete(`/api/events/${eventData.id}`).pipe(
                map(() => new eventActions.DeleteCompleteAction(eventData)),
                catchError((error) => observableOf(new eventActions.DeleteFailedAction({ error }))))
            )));

    removeAssets$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.REMOVE_ASSETS),
            map((action: eventActions.RemoveAssetsAction) => action.payload),
            switchMap((data) => this.http.put(`${EventEffects.BASE_URL}/${data.eventId}/assets`, data.assetIds).pipe(
                map(res => res),
                mergeMap((assetIds: number[]) => {
                    return [new layoutActions.InfoAction({ message: assetIds.length === 1 ? 'Asset has been removed' : 'Assets have been removed' }), new eventActions.RemoveAssetsCompleteAction({ assetIds, eventId: data.eventId })];
                }),
                catchError((error) => observableOf(new eventActions.RemoveAssetsFailedAction({ error }))))
            )));

    update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.UPDATE),
            map((action: eventActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`${EventEffects.BASE_URL}/${data.id}`, data).pipe(
                map(res => new Event(res)),
                map((event: Event) => new eventActions.UpdateCompleteAction(event)),
                catchError((error) => observableOf(new eventActions.UpdateFailedAction({ error }))))
            )));

    getOverlapAssets$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.GET_OVERLAP_ASSETS),
            map((action: eventActions.GetOverlapAssetsAction) => action.payload),
            switchMap((data) => this.http.post(`${EventEffects.BASE_URL}/${data.event.id}/overlap-assets`, data).pipe(
                map(res => res),
                map((numAssets: number) => new eventActions.GetOverlapAssetsCompleteAction(numAssets)),
                catchError((error) => observableOf(new eventActions.GetOverlapAssetsFailedAction({ error }))))
            )));

    listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.LIST_BY_DIVISION),
            map((action: eventActions.ListByDivisionAction) => action.payload),
            switchMap((divisionId) => this.http.get<Event[]>(`/api/divisions/${divisionId}/events`).pipe(
                map(res => res.map((f) => new Event(f))),
                map((events: Event[]) => new eventActions.ListByDivisionCompleteAction(events)),
                catchError((error) => observableOf(new eventActions.ListByDivisionFailedAction({ error }))))
            )));

    clone$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.CLONE),
            map((action: eventActions.CloneAction) => action.payload),
            switchMap((data) => this.http.post(`/api/clone-events/${data.eventId}`, data).pipe(
                map(res => new Event(res)),
                map((event: Event) => new eventActions.CloneCompleteAction(event)),
                catchError((error) => observableOf(new eventActions.CloneFailedAction((error)))))
            )));

    failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(eventActions.ADD_FAILED, eventActions.ADD_ASSETS_FAILED, eventActions.DELETE_FAILED, eventActions.UPDATE_FAILED, eventActions.LIST_BY_DIVISION_FAILED, eventActions.GET_FAILED, eventActions.GET_OVERLAP_ASSETS_FAILED, eventActions.REMOVE_ASSETS_FAILED, eventActions.CLONE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
