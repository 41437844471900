export enum RequestStatus {
    Unsent = 0,
    Incomplete,
    Submitted,
    Confirmed,
    Complete,
    Closed
}

export const RequestStatusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Approved', value: 'true' },
    { label: 'Denied', value: 'false' },
    { label: 'No status', value: 'null' }
];
