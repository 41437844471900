import { createSelector } from 'reselect';
import { ActionStatus } from '../types';
import * as warehouseActions from '../warehouses/warehouse.actions';
import { Warehouse } from '../warehouses/warehouse.model';
import * as divisionActions from './division.actions';
import { Division } from './division.model';

export interface State {
    ids: number[];
    /** The divisions the current user can move Assets to */
    moveToDivisionIds: number[];
    entities: {
        [divisionId: number]: Division;
    };
    divisionWarehousesIds: {
        [divisionId: number]: number[];
    };
    getting: ActionStatus;
    listing: ActionStatus;
    selectedDivisionId: number;
}

export const initialState: State = {
    ids: [],
    moveToDivisionIds: [],
    entities: {},
    divisionWarehousesIds: {},
    getting: ActionStatus.Complete,
    listing: ActionStatus.Complete,
    selectedDivisionId: null
};

export function reducer(state = initialState, action: divisionActions.Actions | warehouseActions.Actions): State {
    switch (action.type) {
        case divisionActions.ADD_COMPLETE: {
            const newDivision = action.payload;
            let divisionWarehousesIds = state.divisionWarehousesIds;
            if (newDivision.warehouses) {
                divisionWarehousesIds = {
                    ...state.divisionWarehousesIds,
                    [newDivision.id]: newDivision.warehouses.map(w => w.id)
                };
            }
            return {
                ...state,
                ids: [...state.ids, newDivision.id],
                entities: {
                    ...state.entities,
                    [newDivision.id]: newDivision
                },
                divisionWarehousesIds
            };
        }

        case divisionActions.UPDATE_COMPLETE: {
            const division = action.payload;

            const alterStateWith = {
                entities: { ...state.entities, [division.id]: division },
            };
            return { ...state, ...alterStateWith };
        }

        case divisionActions.GET: {
            return { ...state, getting: ActionStatus.Loading };
        }

        case divisionActions.GET_COMPLETE: {
            const division = action.payload;

            const newEnts = { [division.id]: division };
            const divisionWarehouseMap = { [division.id]: division.warehouses.map(w => w.id) };

            const newIds = [];
            if (!state.entities[division.id]) {
                newIds.push(division.id);
            }

            return {
                ...state,
                ids: [...state.ids, ...newIds],
                entities: {
                    ...state.entities,
                    ...newEnts,
                },
                getting: ActionStatus.Complete,
                divisionWarehousesIds: {
                    ...state.divisionWarehousesIds,
                    ...divisionWarehouseMap
                }
            };
        }

        case divisionActions.GET_FAILED: {
            return { ...state, getting: ActionStatus.Failed };
        }

        case divisionActions.LIST_BY_STUDIO: {
            return { ...state, listing: ActionStatus.Loading };
        }

        case divisionActions.LIST_BY_STUDIO_FAILED: {
            return { ...state, listing: ActionStatus.Failed };
        }

        case divisionActions.LIST_BY_STUDIO_COMPLETE: {
            const divisionsForStudio = action.payload;

            const divisionIdMap = {};
            const divisionIds = [];
            const divisionWarehouseIdMap = {};

            divisionsForStudio.forEach(div => {
                divisionIdMap[div.id] = div;
                if (div.warehouses) {
                    divisionWarehouseIdMap[div.id] = div.warehouses.map(p => p.id);
                }
                divisionIds.push(div.id);
            });

            return {
                ...state,
                ids: divisionIds,
                entities: divisionIdMap,
                divisionWarehousesIds: divisionWarehouseIdMap,
                listing: ActionStatus.Complete
            };
        }

        case divisionActions.LIST_MOVE_OPTIONS_COMPLETE: {
            const divisionsForStudio = action.payload;

            const divisionIdMap = {};
            const divisionIds = [];
            const divisionWarehouseIdMap = {};

            divisionsForStudio.forEach(div => {
                divisionIdMap[div.id] = div;
                if (div.warehouses) {
                    divisionWarehouseIdMap[div.id] = div.warehouses.map(p => p.id);
                }
                divisionIds.push(div.id);
            });

            return {
                ...state,
                moveToDivisionIds: divisionIds,
                entities: { ...state.entities, ...divisionIdMap },
                divisionWarehousesIds: { ...state.divisionWarehousesIds, ...divisionWarehouseIdMap }
            };
        }

        case divisionActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedDivisionId) {
                newState = {
                    ...state,
                    selectedDivisionId: action.payload
                };
            }
            return newState;
        }

        case warehouseActions.UPDATE_COMPLETE: {
            const warehouse = action.payload;
            const divisionId = warehouse.division_id;
            const entities = { ...state.entities };

            const newWarehouseEnts = [];
            entities[divisionId].warehouses.forEach(w => {
                newWarehouseEnts.push(new Warehouse(w.id === warehouse.id ? warehouse : w));
            });
            entities[divisionId] = new Division({ ...entities[divisionId], warehouses: newWarehouseEnts });

            return {
                ...state,
                entities
            };
        }

        case warehouseActions.ADD_COMPLETE: {
            const warehouse: Warehouse = action.payload.warhouse;
            const divisionId = warehouse.division_id;
            const entities = { ...state.entities };
            const divisionWarehousesIds = state.divisionWarehousesIds[action.payload.divisionId];
            if (divisionWarehousesIds.indexOf(warehouse.id) > -1) {
                return state;
            }
            const newWarehouseEnts: Warehouse[] = [...entities[divisionId].warehouses];
            newWarehouseEnts.push(warehouse);
            entities[divisionId] = new Division({ ...entities[divisionId], warehouses: newWarehouseEnts })
            return {
                ...state,
                divisionWarehousesIds: {
                    ...state.divisionWarehousesIds,
                    [action.payload.divisionId]: [...divisionWarehousesIds, warehouse.id]
                },
                entities
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
const getMoveToIds = (state: State) => state.moveToDivisionIds;
export const getSelectedDivisionId = (state: State) => state.selectedDivisionId;
export const getListing = (state: State) => state.listing;
export const getGetting = (state: State) => state.getting;
export const getDivisionWarehousesIds = (state: State) => state.divisionWarehousesIds;
export const getSelected = createSelector(getEntities, getSelectedDivisionId, (entities, selectedId) => entities[selectedId]);
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getMoveOptions = createSelector(getEntities, getMoveToIds, (entities, ids) => ids.map(id => entities[id]));
export const getDivisionWarehouses = createSelector(getEntities, getSelectedDivisionId, (entities, selectedId) => {
    return entities[selectedId] ? entities[selectedId].warehouses : [];
});
