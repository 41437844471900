import { Action } from '@ngrx/store';
import { EventField } from './event-field.model';

export const LIST = '[EventField] List By Studio';
export const LIST_COMPLETE = '[EventField] List Complete';
export const LIST_FAILED = '[EventField] List Failed';

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: EventField[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction;
