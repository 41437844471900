import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as userActions from '../../core/store/users/user.actions';
import * as fromRoot from '../../core/store';
import { User } from '../../core/store/models';
import { take } from 'rxjs/operators';
import { TableFilterObject } from '../../core/store/types';
import { ColumnFilterOptions } from '../../../../shared/types/column-filter-options';
import { TableState } from 'primeng/api';
import { PDropdownOption } from '../interfaces/p-dropdown-options';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor(private store: Store) { }

    saveTableState() {
        this.store.select(fromRoot.getUser).pipe(take(1)).subscribe((user: User) => {
            if (user) {
                const userCopy = new User(user);
                const userSetting = { ...localStorage };
                userCopy.settings = userSetting;
                this.store.dispatch(new userActions.UpdateAction({ user: userCopy, selectedStudioId: null }));
            }
        })
    }

    updateSettings(key: string, value: string) {
        localStorage.setItem(key, value);
        this.saveTableState();
    }

    updateLocalStorage(user: User) {
        if (user.settings) {
            for (var key of Object.keys(user.settings)) {
                let value = user.settings[key];
                localStorage.setItem(key, value);
            }
        }
    }


    /**
     * Given filters from redux (a deeply frozen object) and selectOptions for a table, remove
     * any filter value(s) that don't have a matching selectOption and indicate we must
     * sync the new filters with redux. Also sync new filters with table state if it exists.
     */

    validateFiltersAndSelectOptions(frozenFilters: TableFilterObject, selectOptions: ColumnFilterOptions, stateKey: string): { filters: TableFilterObject, syncWithRedux: boolean } {

        if (Object.keys(selectOptions).length > 0) {
            Object.keys(selectOptions).map(key => {
                selectOptions[key] = selectOptions[key].slice().sort((a, b) => a.label.localeCompare(b.label))
            });
        }

        const filters: TableFilterObject = { ...frozenFilters };
        // if there's nothing in selectOptions it hasn't loaded yet, so wait to do anything
        if (Object.keys(selectOptions).length === 0) {
            return { filters, syncWithRedux: false };
        }

        let syncWithRedux = false;
        let tableState: TableState = JSON.parse(localStorage.getItem(stateKey));

        if (!tableState) {
            tableState = {};
        }

        Object.keys(filters).forEach(key => {
            if (Array.isArray(filters[key].value) && filters[key].matchMode === 'in') {
                filters[key] = { ...filters[key] };

                const selectOption = this.getSelectOptionForKey(selectOptions, key);
                if (selectOption) {
                    filters[key].value = filters[key].value.filter(id => {
                        const option = selectOption.find(opt => opt.value === id);

                        if (!option) {
                            syncWithRedux = true;
                        }

                        return option;
                    });
                } else {
                    filters[key].value = [];
                    syncWithRedux = true;
                }
            }
        });

        tableState.filters = filters;
        const tableStateStr = JSON.stringify(tableState);

        if (tableStateStr != '{"filters":{}}') {
            const ts = localStorage.getItem(stateKey);
            if (ts != JSON.stringify(tableState)) {
                localStorage.setItem(stateKey, JSON.stringify(tableState));
                this.saveTableState();
            }
        }

        return { filters, syncWithRedux };
    }

    /** Handles getting the select options since sometimes Field.c_name doesn't correspond to the ColumnFilterOptions key */
    getSelectOptionForKey(selectOptions: ColumnFilterOptions, key: string) {
        let selectOption: PDropdownOption[];
        if (key === 'origin_currency') {
            // options for the origin_currency Field are in project_currency_id
            selectOption = selectOptions['project_currency_id'];
        } else {
            selectOption = selectOptions[key];
        }

        return selectOption;
    }
}
