import { createSelector } from 'reselect';
import { RequestNoteTypes } from '../../../../../shared/enums/request-note-types.enum';
import { RequestTypes } from '../../../../../shared/enums/request-types.enum';
import * as finalApproverRequestActions from '../final-approver-requests/final-approver-request.actions';
import { RequestNote } from '../models';
import * as requestAssetActions from '../request-assets/request-asset.actions';
import { ActionState, ActionStatus, SortObject } from '../types';
import { copyAndMultiSort, getIdsAndEntities, uniqueArrayMerge } from '../utils';
import * as requestActions from './request.actions';
import { Request } from './request.model';

export interface Filter {
    [canonical_name: string]: { value: string | number[] | RequestTypes };
}

export interface RequestIndexType {
    requestIndexType: { value: RequestTypes };
}

export interface State {
    alertApproverSaving: ActionStatus;
    approvalRequestsLoading: ActionStatus;
    ids: number[];
    entities: { [requestId: number]: Request };
    faRequestsLoading: ActionStatus;
    filter: Filter & RequestIndexType;
    getting: ActionStatus;
    loading: ActionStatus;
    notesForRequestId: { [requestId: number]: RequestNote[] };
    saving: ActionState;
    selectedId: number;
    sort: SortObject;
    unsentRequestsLoading: ActionStatus;
}

export const initialState: State = {
    alertApproverSaving: ActionStatus.Complete,
    approvalRequestsLoading: ActionStatus.Complete,
    ids: [],
    entities: {},
    faRequestsLoading: ActionStatus.Complete,
    filter: null,
    getting: ActionStatus.Complete,
    loading: ActionStatus.Complete,
    notesForRequestId: {},
    saving: { status: ActionStatus.Inactive, error: null },
    selectedId: null,
    sort: null,
    unsentRequestsLoading: ActionStatus.Complete
};

export function reducer(state = initialState, action: requestActions.Actions | finalApproverRequestActions.Actions | requestAssetActions.Actions): State {
    const listRequestsComplete = (sharedAction: requestActions.ListApprovalRequestsCompleteAction | requestActions.ListFinalApproverRequestsCompleteAction | requestActions.ListUnsentRequestsCompleteAction): { ids: number[], entities: { [requestId: number]: Request }, notesForRequestId: { [requestId: number]: RequestNote[] } } => {
        let { ids, entities } = getIdsAndEntities(state, sharedAction.payload.requests);
        ids = uniqueArrayMerge(state.ids, ids);
        entities = { ...state.entities, ...entities };
        const sortedIds = copyAndMultiSort(ids, entities, [{ key: 'status' }, { key: 'id' }], 1, []);

        const requestNotes = sharedAction.payload.requestNotes;
        const notesForRequestId = { ...state.notesForRequestId };

        // Clear the notes out for requests that are being refreshed with new notes
        const requestIdsBeingUpdated = sharedAction.payload.requests.map(r => r.id);
        requestIdsBeingUpdated.forEach(id => {
            notesForRequestId[id] = [];
        });
        if (requestNotes) {
            requestNotes.forEach(requestNote => {
                notesForRequestId[requestNote.request_id] = notesForRequestId[requestNote.request_id] ? [...notesForRequestId[requestNote.request_id], requestNote] : [requestNote];
            });
        }

        return {
            ids: sortedIds,
            entities,
            notesForRequestId
        };
    };

    switch (action.type) {
        case requestActions.ADD:
        case requestActions.DELETE:
        case requestActions.UPDATE:
        case requestActions.CLOSE_REQUESTS:
        case requestActions.COMPLETE_REQUEST:
        case requestActions.SEND_REQUEST: {
            return { ...state, saving: { status: ActionStatus.Loading, error: null } };
        }

        case requestActions.ADD_FAILED:
        case requestActions.DELETE_FAILED:
        case requestActions.UPDATE_FAILED:
        case requestActions.CLOSE_REQUESTS_FAILED:
        case requestActions.COMPLETE_REQUEST_FAILED:
        case requestActions.SEND_REQUEST_FAILED: {
            return { ...state, saving: { status: ActionStatus.Failed, error: action.payload.error } };
        }

        case requestActions.LIST_APPROVAL_REQUESTS: {
            return { ...state, approvalRequestsLoading: ActionStatus.Loading };
        }

        case requestActions.LIST_FINAL_APPROVER_REQUESTS: {
            return { ...state, faRequestsLoading: ActionStatus.Loading };
        }

        case requestActions.LIST_UNSENT_REQUESTS: {
            return { ...state, unsentRequestsLoading: ActionStatus.Loading };
        }

        case requestActions.LIST_APPROVAL_REQUESTS_FAILED: {
            return { ...state, approvalRequestsLoading: ActionStatus.Failed };
        }

        case requestActions.LIST_FINAL_APPROVER_REQUESTS_FAILED: {
            return { ...state, faRequestsLoading: ActionStatus.Failed };
        }

        case requestActions.LIST_UNSENT_REQUESTS_FAILED: {
            return { ...state, loading: ActionStatus.Failed };
        }

        case requestActions.GET: {
            return { ...state, getting: ActionStatus.Loading };
        }

        case requestActions.GET_FAILED: {
            return { ...state, getting: ActionStatus.Failed };
        }

        case requestActions.ALERT_APPROVER: {
            return { ...state, alertApproverSaving: ActionStatus.Loading };
        }

        case requestActions.ALERT_APPROVER_COMPLETE: {
            return { ...state, alertApproverSaving: ActionStatus.Complete };
        }

        case requestActions.ALERT_APPROVER_FAILED: {
            return { ...state, alertApproverSaving: ActionStatus.Failed };
        }

        case requestAssetActions.LIST_CONFLICTS_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload.requests);

            return {
                ...state,
                ids: uniqueArrayMerge(state.ids, ids),
                entities: {
                    ...state.entities,
                    ...entities,
                }
            };
        }

        case finalApproverRequestActions.UPDATE_COMPLETE:
        case finalApproverRequestActions.CREATE_COMPLETE:
        case requestActions.ADD_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, [action.payload.request]);
            const notesForRequestId = { ...state.notesForRequestId };
            if (action.type === requestActions.ADD_COMPLETE) {
                notesForRequestId[action.payload.request.id] = [action.payload.requestNote];
            } else if (action.payload.requestNote) {
                notesForRequestId[action.payload.request.id] = notesForRequestId[action.payload.request.id] ? [...notesForRequestId[action.payload.request.id], action.payload.requestNote] : [action.payload.requestNote];
            }

            return {
                ...state,
                ids: uniqueArrayMerge(state.ids, ids),
                entities: {
                    ...state.entities,
                    ...entities,
                },
                notesForRequestId,
                saving: { status: ActionStatus.Complete, error: null }
            };
        }

        case requestActions.LIST_APPROVAL_REQUESTS_COMPLETE: {
            return {
                ...state,
                ...listRequestsComplete(action),
                approvalRequestsLoading: ActionStatus.Complete,
                loading: ActionStatus.Complete
            };
        }
        case requestActions.LIST_FINAL_APPROVER_REQUESTS_COMPLETE: {
            return {
                ...state,
                ...listRequestsComplete(action),
                faRequestsLoading: ActionStatus.Complete,
                loading: ActionStatus.Complete
            };
        }
        case requestActions.LIST_UNSENT_REQUESTS_COMPLETE: {
            return {
                ...state,
                ...listRequestsComplete(action),
                unsentRequestsLoading: ActionStatus.Complete,
                loading: ActionStatus.Complete
            };
        }

        case requestActions.UPDATE_COMPLETE: {
            const request = action.payload.request;
            const requestNote = action.payload.requestNote;
            const entitiesUpdate = { [request.id]: request };
            const notesForRequestId = { ...state.notesForRequestId };
            notesForRequestId[request.id] = [requestNote];

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entitiesUpdate
                },
                notesForRequestId,
                saving: { status: ActionStatus.Complete, error: null }
            };
        }

        case requestActions.CLOSE_REQUESTS_COMPLETE: {
            const requests = action.payload.requests;
            const entities = { ...state.entities };
            requests.forEach(request => {
                entities[request.id] = request;
            });

            return {
                ...state,
                entities,
                saving: { status: ActionStatus.Complete, error: null }
            };
        }

        case requestActions.DELETE_COMPLETE: {
            const requestId = action.payload;
            const entities = { ...state.entities };
            delete entities[requestId];
            const notesForRequestId = { ...state.notesForRequestId };
            delete notesForRequestId[requestId];

            return {
                ...state,
                ids: state.ids.filter(id => id !== requestId),
                entities,
                notesForRequestId,
                saving: { status: ActionStatus.Complete, error: null }
            };
        }

        case requestActions.COMPLETE_REQUEST_COMPLETE:
        case requestActions.SEND_REQUEST_COMPLETE: {
            const request = action.payload.request;
            const entities = { ...state.entities };
            entities[request.id] = request;
            const notesForRequestId = { ...state.notesForRequestId };

            if (action.type !== requestActions.SEND_REQUEST_COMPLETE) {
                const requestNote = action.payload.requestNote;
                notesForRequestId[request.id] = notesForRequestId[request.id] ? [...notesForRequestId[request.id], requestNote] : [requestNote];
            }

            return {
                ...state,
                entities,
                notesForRequestId,
                saving: { status: ActionStatus.Complete, error: null }
            };
        }

        case requestActions.GET_COMPLETE: {
            const request = action.payload.request;
            const requestNotes = action.payload.requestNotes;
            const entities = { ...state.entities };
            entities[request.id] = request;
            const notesForRequestId = { ...state.notesForRequestId };
            notesForRequestId[request.id] = requestNotes;

            return {
                ...state,
                ids: uniqueArrayMerge(state.ids, [request.id]),
                entities,
                notesForRequestId,
                getting: ActionStatus.Complete
            };
        }

        case requestActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }
            return newState;
        }

        case requestActions.SORT: {
            const sortData = { field: action.payload.field, order: action.payload.order, args: (action.payload.args ? action.payload.args : []) };
            let requestIds = state.ids;

            if (state.sort
                && state.sort.args === sortData.args
                && state.sort.field === sortData.field
                && state.sort.order === sortData.order) {
                // Cancel sorting
                return state;
            }

            if (sortData.field === 'note') {
                const requesterNotes = requestIds.map(requestId => state.notesForRequestId[requestId].find(note => note.note_type === RequestNoteTypes.Requester));
                let requesterNoteIds = requesterNotes.map(reqNote => reqNote.id);
                const requesterNoteEnts = {};
                requesterNotes.forEach(reqNote => {
                    requesterNoteEnts[reqNote.id] = reqNote;
                });
                requesterNoteIds = copyAndMultiSort(requesterNoteIds, requesterNoteEnts, [{ key: sortData.field }, { key: 'id' }], sortData.order);
                requestIds = requesterNoteIds.map(requestNoteId => requesterNoteEnts[requestNoteId].request_id);
            } else {
                const requestEnts = state.entities;
                requestIds = copyAndMultiSort(requestIds, requestEnts, [{ key: sortData.field }, { key: 'id' }], sortData.order);
            }

            return {
                ...state,
                sort: sortData,
                ids: requestIds
            };
        }

        case requestActions.FILTER: {
            if (action.payload === null) {
                return {
                    ...state,
                    filter: initialState.filter
                };
            }

            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.canonical_name]: { value: action.payload.value },
                    requestIndexType: { value: action.payload.requestIndexType }
                },
            };
        }

        default:
            return state;
    }
}

export const getAlertApproverSaving = (state: State) => state.alertApproverSaving;
export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getNotesForRequestId = (state: State) => state.notesForRequestId;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getGetting = (state: State) => state.getting;
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getSelectedId = (state: State) => state.selectedId;
export const getFilter = (state: State) => state.filter;
export const getApprovalRequestsLoading = (state: State) => state.approvalRequestsLoading;
export const getFaRequestsLoading = (state: State) => state.faRequestsLoading;
export const getUnsentRequestsLoading = (state: State) => state.unsentRequestsLoading;
