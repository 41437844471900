import { NAV_TYPES } from '../../../../../shared/enums/nav-types.enum';
import * as layoutActions from './layout.actions';

export interface State {
    navType: NAV_TYPES;
}

export const initialState: State = {
    navType: NAV_TYPES.STUDIO,
};

export function reducer(state = initialState, action: layoutActions.Actions): State {
    switch (action.type) {
        case layoutActions.SWITCH_NAV: {
            return { ...state, navType: action.payload };
        }

        default:
            return state;
    }
}

export const getNavType = (state: State) => state.navType;
