import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as locationActions from './location.actions';
import { Location } from './location.model';

@Injectable()
export class LocationEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(locationActions.ADD),
            map((action: locationActions.AddAction) => action.payload),
            switchMap((locationData) => this.http.post(`api/locations`, locationData).pipe(
                map(res => new Location(res)),
                map((newLocation: Location) => new locationActions.AddCompleteAction(newLocation)),
                catchError(error => observableOf(new locationActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(locationActions.DELETE),
            map((action: locationActions.DeleteAction) => action.payload),
            switchMap((locationData) => this.http.delete(`api/locations/${locationData.id}`).pipe(
                map(() => new locationActions.DeleteCompleteAction(locationData)),
                catchError((error) => observableOf(new locationActions.DeleteFailedAction({ error }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(locationActions.LIST),
            map((action: locationActions.ListAction) => action.payload),
            switchMap((franchiseId) => this.http.get<Location[]>(`api/franchises/${franchiseId}/locations`).pipe(
                map(res => res.map((l) => new Location(l))),
                map((locations: Location[]) => new locationActions.ListCompleteAction(locations)),
                catchError(error => observableOf(new locationActions.ListFailedAction({ error }))))
            )));

     listByStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(locationActions.LIST_BY_STUDIO),
            map((action: locationActions.ListByStudioAction) => action.payload),
            switchMap((studioId) => this.http.get<Location[]>(`api/studios/${studioId}/locations`).pipe(
                map(res => res.map((l) => new Location(l))),
                map((locations: Location[]) => new locationActions.ListByStudioCompleteAction(locations)),
                catchError(error => observableOf(new locationActions.ListByStudioFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(locationActions.UPDATE),
            map((action: locationActions.UpdateAction) => action.payload),
            switchMap(payload => this.http.put(`api/locations/${payload.id}`, payload).pipe(
                map(res => new Location(res)),
                map((location: Location) => new locationActions.UpdateCompleteAction(location)),
                catchError(error => observableOf(new locationActions.UpdateFailedAction({ error }))))
            )));

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(locationActions.ADD_FAILED, locationActions.LIST_FAILED, locationActions.LIST_BY_STUDIO_FAILED, locationActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
