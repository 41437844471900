<div class="exception-body notfound-page">
    <div class="exception-type">
        <img src="assets/layout/images/404.svg">
    </div>
    <mat-card appearance="outlined" class="ah-exception-panel">
        <h1>Oops, the page you're looking for does not exist</h1>
        <div class="description">
            <div>You may want to head back to the homepage.
            </div>
            <div class="divider"></div>
            <div>
                If you think something is broken, report a problem.
            </div>
        </div>
        <div>
            <a mat-raised-button routerLink="/" class="import-btn" style="width: 163px; margin-right: 18px;">HOME</a>
            <a mat-raised-button class="import-btn" style="margin-right: 0px;" href="mailto:support@synconset.com?Subject=Asset%20Hub%20Page%20Not%20Found"
                target="_top">REPORT A PROBLEM</a>
        </div>
    </mat-card>
</div>
