import { ActionStatus } from '../types';
import * as claimActions from './claim.actions';

export interface State {
    entities: {};
    updating: ActionStatus;
}

export const initialState = {
    entities: {},
    updating: ActionStatus.Complete
}

export function reducer(state = initialState, action: claimActions.Actions): State {
    switch (action.type) {
        case claimActions.CLAIM_USER_ACCOUNT: {
            return { ...state, updating: ActionStatus.Loading };
        }

        case claimActions.CLAIM_USER_ACCOUNT_COMPLETE: {
            const claimObj = action.payload;

            return {
                entities: {
                    ...claimObj,
                },
                updating: ActionStatus.Complete
            };
        }
    }
}
export const entitiesSelector = (state: State) => state.entities;
export const getUpdating = (state: State) => state.updating;