import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as subLocationActions from './sub-location.actions';
import { SubLocation } from './sub-location.model';

@Injectable()
export class SubLocationEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subLocationActions.ADD),
            map((action: subLocationActions.AddAction) => action.payload),
            switchMap(payload => this.http.post(`api/sub-locations`, payload).pipe(
                map(res => new SubLocation(res)),
                map((newSubLocation: SubLocation) => new subLocationActions.AddCompleteAction(newSubLocation)),
                catchError(error => observableOf(new subLocationActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subLocationActions.DELETE),
            map((action: subLocationActions.DeleteAction) => action.payload),
            switchMap(subLocationData => this.http.post(`api/sub-locations/${subLocationData.id}/delete`, subLocationData).pipe(
                map(() => new subLocationActions.DeleteCompleteAction(subLocationData)),
                catchError((error) => observableOf(new subLocationActions.DeleteFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subLocationActions.UPDATE),
            map((action: subLocationActions.UpdateAction) => action.payload),
            switchMap(payload => this.http.put(`api/sub-locations/${payload.id}`, payload).pipe(
                map(res => new SubLocation(res)),
                map((newSubLocation: SubLocation) => new subLocationActions.UpdateCompleteAction(newSubLocation)),
                catchError(error => observableOf(new subLocationActions.UpdateFailedAction({ error }))))
            )));

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subLocationActions.ADD_FAILED, subLocationActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
