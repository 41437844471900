import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import { ActionStatus } from '../../core/store/types';
import * as assetModalActions from './../../core/store/asset-modal/asset-modal.actions';
import * as divisionActions from './../../core/store/divisions/division.actions';
import { GuardHelperService } from './guard-helper.service';

@Injectable()
export class DivisionGuard  {
    public canActivateChild = this.canActivate;

    constructor(private router: Router, private store: Store<fromRoot.State>) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const paramDivisionId = GuardHelperService.getRouteParam('divisionId', route);

        const getUser = this.store.select(fromRoot.getUser);
        const getSelectedDivision = this.store.select(fromRoot.getSelectedDivision);
        const getAssetModalSelectedDivision = this.store.select(fromRoot.getAssetModalSelectedDivisionId);
        const getGetting = this.store.select(fromRoot.getDivisionGetting);

        this.store.dispatch(new divisionActions.SelectAction(paramDivisionId));

        let dispatched = false;
        return observableCombineLatest(getUser, getSelectedDivision, getAssetModalSelectedDivision, getGetting).pipe(
            filter(([user, division, assetModalDivisionId, gettingState]) => {
                if (!division && !dispatched) {
                    dispatched = true;
                    this.store.dispatch(new divisionActions.GetAction(paramDivisionId));
                    return false;
                } else if ((!division && gettingState === ActionStatus.Loading)) {
                    return false;
                }
                if (division.id !== assetModalDivisionId) {
                    this.clearAssetModalSelected();
                }
                return true;
            }),
            map(([user, division]) => {
                if (!division || !user.isDivisionUser(division)) {
                    this.router.navigate(['/404']);
                    return false;
                }
                return true;
            }));
    }

    private clearAssetModalSelected() {
        this.store.dispatch(new assetModalActions.SelectDivisionAction(null));
        this.store.dispatch(new assetModalActions.SelectFranchiseAction(null));
        this.store.dispatch(new assetModalActions.SelectLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectProjectAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectDepartmentAction(null));
        this.store.dispatch(new assetModalActions.SelectGroupsAction([]));
    }
}
