import { createSelector } from 'reselect';
import * as root from '../';
import { Asset, AssetTypes } from '../assets/asset.model';
import { SubGroup } from '../models';
import { Group } from './group.model';

/* Group View*/
export const getAssetCountForSelectedGroup = createSelector(root.getAssetCountForGroupId, root.getSelectedFranchiseGroupId, root.getSelectedDivisionGroupId, root.getSelectedStudioGroupId, (assetCounts, franchiseGroupId, divisionGroupId, studioGroupId) => {
    if (franchiseGroupId) {
        return assetCounts[franchiseGroupId];
    } else if (divisionGroupId) {
        return assetCounts[divisionGroupId];
    } else if (studioGroupId) {
        return assetCounts[studioGroupId]
    }
    return null;
});

export const getAttachmentsForSelectedGroup = createSelector(root.getAttachmentsEntities, root.getAttachmentIdsByGroup, root.getSelectedFranchiseGroupId, root.getSelectedDivisionGroupId, root.getSelectedStudioGroupId, (attEnts, attIdsByGroup, franchiseGroupId, divisionGroupId, studioGroupId) => {
    const groupId = franchiseGroupId ? franchiseGroupId : divisionGroupId ? divisionGroupId : studioGroupId;
    return groupId && attIdsByGroup[groupId] ? attIdsByGroup[groupId].map(id => attEnts[id]) : [];
});

/* Franchise Groups */
export const getGroupsForSelectedFranchise = createSelector(root.getSelectedFranchiseId, root.getGroupIdsForFranchiseId, root.getGroupEntities, (franchiseId, groupIdsForFranchiseId, entities) => {
    const franchiseGroupIds = groupIdsForFranchiseId[franchiseId];
    if (franchiseGroupIds && franchiseGroupIds.length) {
        const franchiseGroups = franchiseGroupIds.map(id => entities[id]).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
        const groups = [];
        for (const franchiseGroup of franchiseGroups) {
            let { subGroups } = franchiseGroup;
            if (subGroups && subGroups.length) {
                subGroups = subGroups.map(item => item).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
            } else {
                subGroups = [];
            }
            groups.push(new Group({ ...franchiseGroup, subGroupAttachmentCount: subGroupsAttachmentCount(subGroups), subGroups: subGroups }))
        }
        return groups;
    }
    return [];
});

export const getGroupsNotInSelectedFranchise = createSelector(root.getSelectedFranchiseId, root.getGroupIdsForFranchiseId, root.getGroupIds, root.getGroupEntities, (franchiseId, groupIdsForFranchiseId, ids, entities) => {
    const franchiseGroupIds = groupIdsForFranchiseId[franchiseId] ? groupIdsForFranchiseId[franchiseId] : [];
    const studioGroupIds = ids.filter(id => !franchiseGroupIds.includes(id));
    if (studioGroupIds && studioGroupIds.length) {
        return studioGroupIds.map(id => entities[id]).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
    }
    return [];
});

/* Division Groups */
export const getGroupsForSelectedDivision = createSelector(root.getSelectedDivisionId, root.getGroupIdsForDivisionId, root.getGroupEntities, (divisionId, groupIdsForDivisionId, entities) => {
    const divisionGroupIds = groupIdsForDivisionId[divisionId];
    if (divisionGroupIds && divisionGroupIds.length) {
        const divisionGroups = divisionGroupIds.map(id => entities[id]).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
        const groups = [];
        for (const divisionGroup of divisionGroups) {
            let { subGroups } = divisionGroup;
            if (subGroups && subGroups.length) {
                subGroups = subGroups.map(item => item).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
            } else {
                subGroups = [];
            }
            groups.push(new Group({ ...divisionGroup, subGroupAttachmentCount: subGroupsAttachmentCount(subGroups), subGroups: subGroups }))
        }
        return groups;
    }
    return [];
});

export const getGroupsNotInSelectedDivision = createSelector(root.getSelectedDivisionId, root.getGroupIdsForDivisionId, root.getGroupIds, root.getGroupEntities, (divisionId, groupIdsForDivisionId, ids, entities) => {
    const divisionGroupIds = groupIdsForDivisionId[divisionId] ? groupIdsForDivisionId[divisionId] : [];
    const studioGroupIds = ids.filter(id => !divisionGroupIds.includes(id));
    if (studioGroupIds && studioGroupIds.length) {
        return studioGroupIds.map(id => entities[id]).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
    }
    return [];
});

/* Asset Groups */

const getAllAssetsIdsForSelectedGroup = createSelector(root.getAssetIdsForGroups, root.getSelectedFranchiseGroupId, root.getSelectedDivisionGroupId, root.getSelectedStudioGroupId, (assetIds, franchiseGroupId, divisionGroupId, studioGroupId) => {
    if (franchiseGroupId) {
        return (assetIds[franchiseGroupId]) ? assetIds[franchiseGroupId] : [];
    } else if (divisionGroupId) {
        return (assetIds[divisionGroupId]) ? assetIds[divisionGroupId] : [];
    } else {
        return (assetIds[studioGroupId]) ? assetIds[studioGroupId] : [];
    }
});
const getAllAssetsForSelectedGroup = createSelector(root.getAssetEntities, getAllAssetsIdsForSelectedGroup, (assets, assetIdsForGroup) => {
    return assetIdsForGroup.map(id => assets[id]);
});

const getAssetIdsForSelectedFranchiseGroup = createSelector(root.getAssetIdsForGroups, root.getSelectedFranchiseGroupId, (assetIds, groupId) => {
    return (groupId && assetIds[groupId]) ? assetIds[groupId] : [];
});
const getAssetsForSelectedFranchiseGroup = createSelector(root.getAssetEntities, getAssetIdsForSelectedFranchiseGroup, root.getSelectedFranchiseId, (assets, assetIdsForGroup, franchiseId) => {
    assetIdsForGroup = [...new Set(assetIdsForGroup)];
    return assetIdsForGroup.map(id => assets[id]).filter(a => a && !a.division_id && a.franchise_id === franchiseId);
});

const getAssetIdsForSelectedDivisionGroup = createSelector(root.getAssetIdsForGroups, root.getSelectedDivisionGroupId, (assetIds, groupId) => {
    return (groupId && assetIds[groupId]) ? assetIds[groupId] : [];
});
const getAssetsForSelectedDivisionGroup = createSelector(root.getAssetEntities, getAssetIdsForSelectedDivisionGroup, root.getSelectedDivisionId, (assets, assetIdsForGroup, divisionId) => {
    assetIdsForGroup = [...new Set(assetIdsForGroup)];
    return assetIdsForGroup.map(id => assets[id]).filter(a => a.division_id && a.division_id === divisionId);
});

export const getAssetsToShowOnGroupView = createSelector(root.getAssetsShown, getAssetsForSelectedFranchiseGroup, getAssetsForSelectedDivisionGroup, getAllAssetsForSelectedGroup, (assetsToShow, franchiseAssets, divisionAssets, allAssets) => {
    let assets: Asset[];
    if (assetsToShow === AssetTypes.Franchise) {
        assets = franchiseAssets;
    } else if (assetsToShow === AssetTypes.Division) {
        assets = divisionAssets;
    } else {
        assets = allAssets;
    }
    const assetIds = assets.map(a => a.id);
    const assetEntities: { [assetId: number]: Asset } = {};
    assets.forEach(asset => {
        assetEntities[asset.id] = asset;
    });
    return assetIds.map(id => assetEntities[id]);
});

/* Studio Groups */

export const getAllGroups = createSelector(root.getGroupEntities, root.getGroupIds, (groupEnt, ids) => {
    const studioGroups = ids.map(id => groupEnt[id]).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
    if (studioGroups && studioGroups.length) {
        const groups = [];
        for (const studioGroup of studioGroups) {
            let { subGroups } = studioGroup;
            if (subGroups && subGroups.length) {
                subGroups = subGroups.map(item => item).sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0);
            } else {
                subGroups = [];
            }
            groups.push(new Group({ ...studioGroup, subGroupAttachmentCount: subGroupsAttachmentCount(subGroups), subGroups: subGroups }))
        }
        return groups;
    }
    return [];
});

function subGroupsAttachmentCount(subGroups: SubGroup[]) {
    return subGroups && subGroups.length ? subGroups.reduce((total, curr) => total += curr.attachmentCount, 0) : 0
}
