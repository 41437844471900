import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ah-no-studio-perms',
    template: `
    <div class="content">
        <span>It looks like you don't have access to any studios.</span>
        <span>Please reach out to your studio contact(s) and request to be added.</span>
    </div>
  `,
    styleUrls: ['./no-studio-perms.component.scss']
})
export class NoStudioPermsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
