import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Attachment } from '../models';

export const SORT_ASSET_ATTACHMENTS_LIST = '[Attachments] Sort Asset Attachments List';
export const SORT_EVENT_ATTACHMENTS_LIST = '[Attachments] Sort Event Attachments List';
export const SORT_GROUP_ATTACHMENTS_LIST = '[Attachments] Sort Group Attachments List';
export const SORT_SUB_GROUP_ATTACHMENTS_LIST = '[Attachments] Sort Sub Group Attachments List';

export const ADD_ATTACHMENT_COMPLETE = '[Asset] Add Attachment Complete';
export const ADD_ATTACHMENT_FAILED = '[Asset] Add Attachment Failed';
export const DELETE_ATTACHMENT = '[Asset] Delete Attachment';
export const DELETE_ATTACHMENT_COMPLETE = '[Asset] Delete Attachment Complete';
export const DELETE_ATTACHMENT_FAILED = '[Asset] Delete Attachment Failed';
export const GET_ATTACHMENT = '[Asset] Get Attachment';
export const GET_ATTACHMENT_COMPLETE = '[Asset] Get Attachment Complete';
export const GET_ATTACHMENT_FAILED = '[Asset] Get Attachment Failed';

export const ADD_EVENT_ATTACHMENT_COMPLETE = '[Event] Add Attachment Complete';
export const ADD_EVENT_ATTACHMENT_FAILED = '[Event] Add Attachment Failed';
export const DELETE_EVENT_ATTACHMENT = '[Event] Delete Attachment';
export const DELETE_EVENT_ATTACHMENT_COMPLETE = '[Event] Delete Attachment Complete';
export const DELETE_EVENT_ATTACHMENT_FAILED = '[Event] Delete Attachment Failed';

export const ADD_GROUP_ATTACHMENTS_COMPLETE = '[Group] Add Attachments Complete';
export const ADD_GROUP_ATTACHMENTS_FAILED = '[Group] Add Attachments Failed';
export const DELETE_GROUP_ATTACHMENT = '[Group] Delete Attachment';
export const DELETE_GROUP_ATTACHMENT_COMPLETE = '[Group] Delete Attachment Complete';
export const DELETE_GROUP_ATTACHMENT_FAILED = '[Group] Delete Attachment Failed';

export const ADD_SUB_GROUP_ATTACHMENTS_COMPLETE = '[Sub Group] Add Attachments Complete';
export const ADD_SUB_GROUP_ATTACHMENTS_FAILED = '[Sub Group] Add Attachments Failed';
export const DELETE_SUB_GROUP_ATTACHMENT = '[Sub Group] Delete Attachment';
export const DELETE_SUB_GROUP_ATTACHMENT_COMPLETE = '[Sub Group] Delete Attachment Complete';
export const DELETE_SUB_GROUP_ATTACHMENT_FAILED = '[Sub Group] Delete Attachment Failed';

export class AddAttachmentCompleteAction implements Action {
    readonly type = ADD_ATTACHMENT_COMPLETE;

    constructor(public payload: { asset_id: number, attachment: Attachment }) { }
}

export class AddAttachmentFailedAction implements Action {
    readonly type = ADD_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class DeleteAttachmentAction implements Action {
    readonly type = DELETE_ATTACHMENT;

    constructor(public payload: Attachment) { }
}

export class DeleteAttachmentCompleteAction implements Action {
    readonly type = DELETE_ATTACHMENT_COMPLETE;

    constructor(public payload: Attachment) { }
}

export class DeleteAttachmentFailedAction implements Action {
    readonly type = DELETE_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class GetAttachmentAction implements Action {
    readonly type = GET_ATTACHMENT;

    constructor(public payload: number) { }
}

export class GetAttachmentCompleteAction implements Action {
    readonly type = GET_ATTACHMENT_COMPLETE;

    constructor(public payload: { assetId: any, attachments: Attachment[] }) { }
}

export class GetAttachmentFailedAction implements Action {
    readonly type = GET_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class SortAssetAttachmentsAction implements Action {
    readonly type = SORT_ASSET_ATTACHMENTS_LIST;

    constructor(public payload: {
        field?: string,
        order?: number,
        args?: any[],
        assetId: number
    }) { }
}

export class AddEventAttachmentCompleteAction implements Action {
    readonly type = ADD_EVENT_ATTACHMENT_COMPLETE;

    constructor(public payload: { event_id: number, attachment: Attachment }) { }
}

export class AddEventAttachmentFailedAction implements Action {
    readonly type = ADD_EVENT_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class DeleteEventAttachmentAction implements Action {
    readonly type = DELETE_EVENT_ATTACHMENT;

    constructor(public payload: Attachment) { }
}

export class DeleteEventAttachmentCompleteAction implements Action {
    readonly type = DELETE_EVENT_ATTACHMENT_COMPLETE;

    constructor(public payload: Attachment) { }
}

export class DeleteEventAttachmentFailedAction implements Action {
    readonly type = DELETE_EVENT_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class SortEventAttachmentsAction implements Action {
    readonly type = SORT_EVENT_ATTACHMENTS_LIST;

    constructor(public payload: {
        field?: string,
        order?: number,
        args?: any[],
        eventId: number
    }) { }
}

export class AddGroupAttachmentsCompleteAction implements Action {
    readonly type = ADD_GROUP_ATTACHMENTS_COMPLETE;

    constructor(public payload: { group_id: number, attachments: Attachment[] }) { }
}

export class AddGroupAttachmentsFailedAction implements Action {
    readonly type = ADD_GROUP_ATTACHMENTS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteGroupAttachmentAction implements Action {
    readonly type = DELETE_GROUP_ATTACHMENT;

    constructor(public payload: Attachment) { }
}

export class DeleteGroupAttachmentCompleteAction implements Action {
    readonly type = DELETE_GROUP_ATTACHMENT_COMPLETE;

    constructor(public payload: Attachment) { }
}

export class DeleteGroupAttachmentFailedAction implements Action {
    readonly type = DELETE_GROUP_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class SortGroupAttachmentsAction implements Action {
    readonly type = SORT_GROUP_ATTACHMENTS_LIST;

    constructor(public payload: {
        field?: string,
        order?: number,
        args?: any[],
        groupId: number
    }) { }
}
export class AddSubGroupAttachmentsCompleteAction implements Action {
    readonly type = ADD_SUB_GROUP_ATTACHMENTS_COMPLETE;

    constructor(public payload: { sub_group_id: number, attachments: Attachment[] }) { }
}

export class AddSubGroupAttachmentsFailedAction implements Action {
    readonly type = ADD_SUB_GROUP_ATTACHMENTS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteSubGroupAttachmentAction implements Action {
    readonly type = DELETE_SUB_GROUP_ATTACHMENT;

    constructor(public payload: Attachment) { }
}

export class DeleteSubGroupAttachmentCompleteAction implements Action {
    readonly type = DELETE_SUB_GROUP_ATTACHMENT_COMPLETE;

    constructor(public payload: Attachment) { }
}

export class DeleteSubGroupAttachmentFailedAction implements Action {
    readonly type = DELETE_SUB_GROUP_ATTACHMENT_FAILED;

    constructor(public payload: any) { }
}

export class SortSubGroupAttachmentsAction implements Action {
    readonly type = SORT_SUB_GROUP_ATTACHMENTS_LIST;

    constructor(public payload: {
        field?: string,
        order?: number,
        args?: any[],
        subGroupId: number
    }) { }
}

export type Actions
    = AddAttachmentCompleteAction
    | AddAttachmentFailedAction
    | DeleteAttachmentAction
    | DeleteAttachmentCompleteAction
    | DeleteAttachmentFailedAction
    | SortAssetAttachmentsAction
    | AddEventAttachmentCompleteAction
    | AddEventAttachmentFailedAction
    | DeleteEventAttachmentAction
    | DeleteEventAttachmentCompleteAction
    | DeleteEventAttachmentFailedAction
    | SortEventAttachmentsAction
    | AddGroupAttachmentsCompleteAction
    | AddGroupAttachmentsFailedAction
    | DeleteGroupAttachmentAction
    | DeleteGroupAttachmentCompleteAction
    | DeleteGroupAttachmentFailedAction
    | SortGroupAttachmentsAction
    | AddSubGroupAttachmentsCompleteAction
    | AddSubGroupAttachmentsFailedAction
    | DeleteSubGroupAttachmentAction
    | DeleteSubGroupAttachmentCompleteAction
    | DeleteSubGroupAttachmentFailedAction
    | SortSubGroupAttachmentsAction
    | GetAttachmentAction
    | GetAttachmentCompleteAction
    | GetAttachmentFailedAction;
