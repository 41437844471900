import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as attachmentActions from '../../core/store/attachments/attachment.actions';
import { Attachment } from '../../core/store/models';
import { rootSelectors } from '../../core/store/selectors';
import { SortObject } from '../../core/store/types';

@Injectable()
export class EventAttachmentService {

    constructor(private store: Store<rootSelectors.State>) { }

    add(data: { id: number, attachment: Attachment }) {
        this.store.dispatch(new attachmentActions.AddEventAttachmentCompleteAction({ event_id: data.id, attachment: data.attachment }));
    }

    delete(attachment: Attachment) {
        this.store.dispatch(new attachmentActions.DeleteEventAttachmentAction(attachment));
    }

    sort(sortObject: SortObject, eventId: number) {
        this.store.dispatch(new attachmentActions.SortEventAttachmentsAction({
            ...sortObject,
            eventId
        }));
    }
}
