import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as customStudioFieldNameActions from './custom-studio-field-name.actions';
import { CustomStudioFieldName } from './custom-studio-field-name.model';

@Injectable()
export class CustomStudioFieldNameEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(customStudioFieldNameActions.LIST),
            map((action: customStudioFieldNameActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<CustomStudioFieldName>(`/api/studios/${studioId}/custom-studio-field-names`).pipe(
                map(res => new CustomStudioFieldName(res)),
                map((customStudioFieldName: CustomStudioFieldName) => new customStudioFieldNameActions.ListCompleteAction(customStudioFieldName)),
                catchError((error) => observableOf(new customStudioFieldNameActions.ListFailedAction({ error, studioId }))))
            )));

     listFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(customStudioFieldNameActions.LIST_FAILED),
            map((action: customStudioFieldNameActions.ListFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
