import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SavingMessage, Severity } from '../../../core/store/types';

export interface ConfirmModalData {
    cancelButton?: string;
    confirmButton?: string;
    confirmColor?: string;
    removeButton?: string;
    removeColor?: string;
    ledgerDeleted?: boolean;
    onConfirm?: (dialogRef) => void;
    message?: string;
    htmlMessage?: string;
    mode?: string;
    messageBulleted?: string[];
    textStyle?: { [styleName: string]: string };
    title: string;
    allowCancel?: boolean;
    transactionMessage?: string[];
    charSetMessage?: string[];
    warningMessage?: string[];
    isNonStudioUser?: boolean;
}

@Component({
    templateUrl: './confirm-modal.component.html',
    styles: [`
        .modal-text {
            margin-bottom: 25px;
            word-wrap : break-word;
            color:black;
        }
        .mat-mdc-button.mat-yellow {
                color: #F4C84B;
        }
        .mat-mdc-raised-button.mat-yellow, .mat-mdc-unelevated-button.mat-yellow{
            background-color: #F4C84B;
            color: white;
        }
    `]
})
export class ConfirmModalComponent implements OnInit {
    cancelButton: string;
    confirmButton: string;
    confirmColor: string;
    ledgerDeleted: boolean;
    onConfirm?: (dialogRef) => void;
    message?: string;
    htmlMessage?: string;
    mode?: string;
    removeButton: string;
    removeColor: string;
    messageBulleted?: string[] = [];
    warningMessage?: string[] = [];
    textStyle: { [styleName: string]: string };
    title: string;
    allowCancel: boolean;
    savingMessage?: SavingMessage[] = [];
    saveDisabled = false;
    transactionMessage?: string[] = [];
    charSetMessage?: string[] = [];
    isNonStudioUser: boolean;
    isShowRemoveButton: boolean;
    deleteLedger: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmModalData, public dialogRef: MatDialogRef<ConfirmModalComponent>) { }

    ngOnInit() {
        this.transactionMessage = this.data.transactionMessage ? this.data.transactionMessage : [];
        this.charSetMessage = this.data.charSetMessage ? this.data.charSetMessage : [];
        this.message = this.data.message ? this.data.message : null;
        this.htmlMessage = this.data.htmlMessage ? this.data.htmlMessage : null;
        this.mode = this.data.mode;
        this.messageBulleted = this.data.messageBulleted ? this.data.messageBulleted : [];
        this.warningMessage = this.data.warningMessage ? this.data.warningMessage : [];
        this.title = this.data.title;
        this.textStyle = this.data.textStyle;
        this.confirmButton = this.data.confirmButton ? this.data.confirmButton : 'Confirm';
        this.confirmColor = this.data.confirmColor ? this.data.confirmColor : 'primary';
        this.cancelButton = this.data.cancelButton ? this.data.cancelButton : 'Cancel';
        this.removeButton = this.data.removeButton ? this.data.removeButton : null;
        this.removeColor = this.data.removeColor ? this.data.removeColor : 'yellow';
        this.allowCancel = this.data.hasOwnProperty('allowCancel') ? this.data.allowCancel : true;
        this.ledgerDeleted = this.data.ledgerDeleted !== null ? this.data.ledgerDeleted : null;
        this.onConfirm = this.data.onConfirm;
        this.isNonStudioUser = this.data.isNonStudioUser ? this.data.isNonStudioUser : false;


        if (this.isNonStudioUser) {
            this.isShowRemoveButton = false;
            this.deleteLedger = false;
        } else {
            this.isShowRemoveButton = this.removeButton && !this.ledgerDeleted;
            this.deleteLedger = true;
        }

    }

    save(hard_deleted?: boolean) {
        if (this.onConfirm) {
            this.savingMessage = [{ severity: Severity.Info, summary: 'Saving...' }];
            this.onConfirm(this.dialogRef);
        } else {
            this.dialogRef.close(this.ledgerDeleted !== null && hard_deleted ? { hard_deleted } : true);
        }
    }
}
