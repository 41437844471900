import { Action } from '@ngrx/store';
import { Studio } from './studio.model';

export const GET = '[Studio] Get';
export const GET_COMPLETE = '[Studio] Get Complete';
export const GET_FAILED = '[Studio] Get Failed';
export const LIST_BY_USER = '[Studio] List By User';
export const LIST_BY_USER_COMPLETE = '[Studio] List By User Complete';
export const LIST_BY_USER_FAILED = '[Studio] List By User Failed';
export const SELECT = '[Studio] Select';
export const SELECT_COMPLETE = '[Studio] Select Complete';
export const UPDATE_STUDIO = '[Studio] Update Studio';
export const UPDATE_STUDIO_COMPLETE = '[Studio] Update Studio Complete';
export const UPDATE_STUDIO_FAILED = '[Studio] Update Studio Failed';

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: Studio) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: { error: any }) { }
}

export class ListByUserAction implements Action {
    readonly type = LIST_BY_USER;

    constructor(public payload: number) { }
}

export class ListByUserCompleteAction implements Action {
    readonly type = LIST_BY_USER_COMPLETE;

    constructor(public payload: Studio[]) { }
}

export class ListByUserFailedAction implements Action {
    readonly type = LIST_BY_USER_FAILED;

    constructor(public payload: { error: any, userId: number }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class SelectCompleteAction implements Action {
    readonly type = SELECT_COMPLETE;

    constructor() { }
}

export class UpdateStudioAction implements Action {
    readonly type = UPDATE_STUDIO;

    constructor(public payload: { name: string, studioId: number, isStudioReadOnly: boolean }) { }
}

export class UpadteStudioCompleteAction implements Action {
    readonly type = UPDATE_STUDIO_COMPLETE;

    constructor(public payload: Studio) { }
}

export class UpdateStudioFailedAction implements Action {
    readonly type = UPDATE_STUDIO_FAILED;

    constructor(public payload: { error: any }) { }
}
export type Actions
    = GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListByUserAction
    | ListByUserCompleteAction
    | ListByUserFailedAction
    | SelectAction
    | SelectCompleteAction
    | UpdateStudioAction
    | UpadteStudioCompleteAction
    | UpdateStudioFailedAction;
