import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as subDepartmentActions from './sub-department.actions';
import { SubDepartment } from './sub-department.model';

@Injectable()
export class SubDepartmentEffects {
    static BASE_URL = '/api/sub-departments';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subDepartmentActions.ADD),
            map((action: subDepartmentActions.AddAction) => action.payload),
            switchMap((data) => this.http.post(SubDepartmentEffects.BASE_URL, data).pipe(
                map(res => new SubDepartment(res)),
                map((character: SubDepartment) => new subDepartmentActions.AddCompleteAction(character)),
                catchError((error) => observableOf(new subDepartmentActions.AddFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subDepartmentActions.UPDATE),
            map((action: subDepartmentActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`${SubDepartmentEffects.BASE_URL}/${data.id}`, data).pipe(
                map(res => new SubDepartment(res)),
                map((character: SubDepartment) => new subDepartmentActions.UpdateCompleteAction(character)),
                catchError((error) => observableOf(new subDepartmentActions.UpdateFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(subDepartmentActions.ADD_FAILED, subDepartmentActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
