import { PageType } from '../../../layout/sidenav-filter/sidenav-filter.component';
import * as sidenavFilterActions from './sidenav-filter.actions';

export interface State {
    showFilter: boolean;
    assetProjectIds: {
        [franchiseId: number]: number[];
    };
    transactionProjectIds: {
        // Will only ever contain one ID, since only one project can be selected
        // Logic in sidenavFilter effects (updateLocalStorage)
        [franchiseId: number]: number[];
    };
    settingsProjectIds: {
        // Same as above
        [franchiseId: number]: number[];
    };
    assetWarehouseIds: {
        [divisionId: number]: number[];
    };
    storageWarehouseIds: {
        [divisionId: number]: number[];
    };
    optionsView: sidenavFilterActions.Options;
}

export const initialState: State = {
    showFilter: false,
    assetProjectIds: {},
    transactionProjectIds: {},
    settingsProjectIds: {},
    assetWarehouseIds: {},
    storageWarehouseIds: {},
    optionsView: sidenavFilterActions.Options.Characters
};

export function reducer(state = initialState, action: sidenavFilterActions.Actions): State {
    switch (action.type) {

        case sidenavFilterActions.CLOSE_FILTER: {
            return state.showFilter ? { ...state, showFilter: false } : state;
        }

        case sidenavFilterActions.OPEN_FILTER: {
            return !state.showFilter ? { ...state, showFilter: true } : state;
        }

        case sidenavFilterActions.UPDATE_OPTIONS: {
            return { ...state, optionsView: action.payload };
        }

        case sidenavFilterActions.UPDATE_PROJECT_COMPLETE:
        case sidenavFilterActions.UPDATE_MULTIPLE_PROJECT_COMPLETE:
        case sidenavFilterActions.LIST_PROJECTS_COMPLETE: {
            const updatedProjects = action.payload.entriesObject;
            const pageType = action.payload.pageType;

            if (pageType === PageType.Asset) {
                return {
                    ...state,
                    assetProjectIds: { ...updatedProjects }
                };
            } else if (pageType === PageType.Settings) {
                return {
                    ...state,
                    settingsProjectIds: { ...updatedProjects }
                };
            } else {
                return {
                    ...state,
                    transactionProjectIds: { ...updatedProjects }
                };
            }
        }

        case sidenavFilterActions.UPDATE_WAREHOUSE_COMPLETE:
        case sidenavFilterActions.UPDATE_MULTIPLE_WAREHOUSE_COMPLETE:
        case sidenavFilterActions.LIST_WAREHOUSES_COMPLETE: {
            const updatedWarehouses = action.payload.entriesObject;

            if (action.payload.pageType === PageType.Storage) {
                return {
                    ...state,
                    storageWarehouseIds: { ...updatedWarehouses }
                };
            } else {
                return {
                    ...state,
                    assetWarehouseIds: { ...updatedWarehouses }
                };
            }
        }

        default: {
            return state;
        }
    }
}

export const getShowSidenavFilter = (state: State) => state.showFilter;
export const getSidenavFilterSelectedAssetProjectIds = (state: State) => state.assetProjectIds;
export const getSidenavFilterSelectedTransactionProjectIds = (state: State) => state.transactionProjectIds;
export const getSidenavFilterSelectedSettingsProjectIds = (state: State) => state.settingsProjectIds;
export const getSettingsOptions = (state: State) => state.optionsView;
export const getSidenavFilterSelectedAssetWarehouseIds = (state: State) => state.assetWarehouseIds;
export const getSidenavFilterSelectedStorageWarehouseIds = (state: State) => state.storageWarehouseIds;
