import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModalData {
    confirmButton?: string;
    confirmColor?: string;
    duplicate?: any;
    linked?: any;
    title: string;
}

@Component({
    selector: 'ah-confirm-duplicate-modal',
    templateUrl: './confirm-duplicate-modal.component.html',
    styleUrls: ['./confirm-duplicate-modal.component.scss']
})
export class ConfirmDuplicateModalComponent implements OnInit {
    confirmButton: string;
    confirmColor: string;
    title: string;
    duplicate: any;
    linked: any;

    constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmModalData, public dialogRef: MatDialogRef<ConfirmDuplicateModalComponent>) { }

    ngOnInit(): void {
        this.title = this.data.title;
        this.confirmButton = this.data.confirmButton ? this.data.confirmButton : 'Close';
        this.confirmColor = this.data.confirmColor ? this.data.confirmColor : 'primary';
        this.duplicate = this.data.duplicate;
        this.linked = this.data.linked;
    }

}
