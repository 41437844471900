import { ActionStatus } from '../types';
import { getIdsAndEntities } from '../utils';
import { GroupTypes } from './group-types.model';
import * as groupTypesActions from './group-types.actions';
import { createSelector } from 'reselect';

export interface State {
    ids: number[];
    entities: {
        [groupTypeId: number]: GroupTypes
    };

    loading: ActionStatus;
    saving: ActionStatus;
    adding: ActionStatus;
}

export const initialState: State = {
    ids: [],
    entities: {},
    loading: ActionStatus.Inactive,
    saving: ActionStatus.Inactive,
    adding: ActionStatus.Inactive,
};

export function reducer(state = initialState, action: groupTypesActions.Actions): State {
    switch (action.type) {

        case groupTypesActions.LIST_COMPLETE: {
            const groupTypes = action.payload.groupTypes;
            const { ids, entities } = getIdsAndEntities(state, groupTypes);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities,
                loading: ActionStatus.Complete
            };
        }

        default: {
            return state;
        }
    }
}

export const getIds = (state: State) => state.ids;
export const getEntities = (state: State) => state.entities;
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getAdding = (state: State) => state.adding;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => {
    return entities[id];
}));
