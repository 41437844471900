import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../core/store';
import { Store } from '@ngrx/store';
import * as userActions from '../../core/store/users/user.actions';

@Component({
    templateUrl: './timeout-modal.component.html',
    styleUrls: ['./timeout-modal.component.scss'],
    providers: [Idle]
})
export class TimeoutModalComponent implements OnDestroy, OnInit {

    idle: Idle;
    minutesLeft: number;
    sub: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialogRef: MatDialogRef<TimeoutModalComponent>, private store: Store<fromRoot.State>) { }

    ngOnInit() {
        this.sub = new Subscription();
        this.idle = this.data.idleInstance;

        const timeoutSub = this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.minutesLeft = Math.ceil(countdown / 60);
        });
        const logoutSub = this.idle.onTimeout.subscribe(() => {
            this.store.dispatch(new userActions.RemoveFromStoreAction(this.data.userId));
            window.location.href = 'session-expired';
        });

        this.sub.add(timeoutSub).add(logoutSub);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
