import * as assetActions from '../assets/asset.actions';
import * as eventActions from '../events/event.actions';
import { ActionStatus } from '../types';
import * as assetEventActions from './asset-event.actions';
import { AssetEvent } from './asset-event.model';

export interface State {
    assetEventForAssetId: {
        [assetId: number]: AssetEvent;
    };
    assetIdsForEvent: {
        [eventId: number]: number[];
    };
    eventTotalCount: {
        [eventId: number]: number;
    };
    quantityOnHandForAssetId: {
        [assetId: number]: number;
    };
    noOfGroupAssetsAdded: {
        [groupId: number]: { count: number, event: string, existingAssetsCount: number },
    },
    noOfSubGroupAssetsAdded: {
        [subGroupId: number]: { count: number, event: string, existingAssetsCount: number },
    },
    nonTransferAsset: AssetEvent[];
    saving: ActionStatus;
}

export const initialState: State = {
    assetEventForAssetId: {},
    assetIdsForEvent: {},
    eventTotalCount: {},
    quantityOnHandForAssetId: {},
    nonTransferAsset: [],
    noOfGroupAssetsAdded: {},
    noOfSubGroupAssetsAdded: {},
    saving: ActionStatus.Inactive
};

export function reducer(state = initialState, action: assetEventActions.Actions | assetActions.Actions | eventActions.Actions): State {
    switch (action.type) {
        case assetEventActions.UPDATE_MULTIPLE: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case assetEventActions.UPDATE_MULTIPLE_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case assetEventActions.UPDATE_MULTIPLE_COMPLETE: {
            const assetEvents = action.payload;
            const assetEventsForAssetId = { ...state.assetEventForAssetId };
            assetEvents.forEach(assetEvent => {
                assetEventsForAssetId[assetEvent.asset_id] = assetEvent;
            });

            return {
                ...state,
                assetEventForAssetId: assetEventsForAssetId,
                saving: ActionStatus.Complete
            };
        }

        case assetEventActions.TRANSFER_ASSETS: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case assetEventActions.TRANSFER_ASSETS_COMPLETE: {
            const assetEvents = action.payload.assets;
            const assetEventsForAssetId = { ...state.assetEventForAssetId };
            assetEvents.forEach(assetEvent => {
                assetEventsForAssetId[assetEvent.asset_id] = assetEvent;
            });

            return {
                ...state,
                assetEventForAssetId: assetEventsForAssetId,
                nonTransferAsset: assetEvents,
                saving: ActionStatus.Complete
            };
        }

        case assetEventActions.TRANSFER_ASSETS_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };

        }
        case eventActions.GET_COMPLETE: {
            const assetEvents = action.payload.assetEvents;
            const assetEventsForAssetId = { ...state.assetEventForAssetId };
            assetEvents.forEach(assetEvent => {
                assetEventsForAssetId[assetEvent.asset_id] = assetEvent;
            });

            return {
                ...state,
                assetEventForAssetId: assetEventsForAssetId
            };
        }

        case assetEventActions.LIST_QUANTITY_ON_HAND_COMPLETE: {
            const quantityOnHandForAssetId = action.payload.quantityOnHandForAssetIds;

            return {
                ...state,
                quantityOnHandForAssetId
            };
        }

        case assetActions.LIST_BY_EVENT_COMPLETE: {
            const assets = action.payload.assets;
            const totalCount = action.payload.totalCount;
            const eventId = action.payload.eventId;
            const eventIdObj = {};
            const eventCountObj = {};

            eventCountObj[eventId] = totalCount;
            eventIdObj[eventId] = assets.map(a => a.id);

            return {
                ...state,
                assetIdsForEvent: {
                    ...state.assetIdsForEvent,
                    ...eventIdObj
                },
                eventTotalCount: {
                    ...state.eventTotalCount,
                    ...eventCountObj
                }
            };
        }

        case assetActions.ADD_FOR_EVENT_COMPLETE:
        case eventActions.ADD_ASSETS_COMPLETE: {
            const assets = action.payload.assets;
            const eventId = action.payload.eventId;

            const eventIdObj = {};
            const eventTotalCount = {};
            const assetEventForAssetIdObj = {};
            if (state.assetIdsForEvent[eventId]) {
                eventIdObj[eventId] = [...state.assetIdsForEvent[eventId]];
                eventTotalCount[eventId] = state.eventTotalCount[eventId];
            } else {
                eventIdObj[eventId] = [];
                eventTotalCount[eventId] = 0;
            }

            if (assets.length) {
                const newAssetIds = [];
                assets.forEach(a => {
                    if (!eventIdObj[eventId].includes(a.id)) {
                        newAssetIds.push(a.id);
                        eventTotalCount[eventId] += 1;
                    }

                    if (a.displayFields.assetEvents && a.displayFields.assetEvents.length === 1) {
                        assetEventForAssetIdObj[a.id] = a.displayFields.assetEvents[0];
                    }
                });
                eventIdObj[eventId] = [...newAssetIds, ...eventIdObj[eventId]];
            }

            return {
                ...state,
                assetIdsForEvent: {
                    ...state.assetIdsForEvent,
                    ...eventIdObj
                },
                eventTotalCount: {
                    ...state.eventTotalCount,
                    ...eventTotalCount
                },
                assetEventForAssetId: {
                    ...state.assetEventForAssetId,
                    ...assetEventForAssetIdObj
                }
            };
        }

        case eventActions.REMOVE_ASSETS_COMPLETE: {
            const assetIds = action.payload.assetIds;
            const eventId = action.payload.eventId;
            const eventIdObj = {};
            const eventCountObj = {};
            if (state.assetIdsForEvent[eventId]) {
                eventIdObj[eventId] = [...state.assetIdsForEvent[eventId].filter((id) => !assetIds.includes(id))];
            }
            eventCountObj[eventId] = eventIdObj[eventId].length;
            return {
                ...state,
                assetIdsForEvent: {
                    ...state.assetIdsForEvent,
                    ...eventIdObj
                },
                eventTotalCount: {
                    ...state.eventTotalCount,
                    ...eventCountObj
                },
            };
        }

        case assetEventActions.ADD_GROUP_ASSETS_TO_EVENT:
        case assetEventActions.ADD_SUBGROUP_ASSETS_TO_EVENT: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case assetEventActions.ADD_GROUP_ASSETS_TO_EVENT_COMPLETE: {
            const { groupId, count, event, existingAssetsCount } = action.payload;
            return {
                ...state,
                noOfGroupAssetsAdded: {
                    [groupId]: { count, event, existingAssetsCount }
                },
                saving: ActionStatus.Complete
            }
        }
        case assetEventActions.ADD_SUBGROUP_ASSETS_TO_EVENT_COMPLETE: {
            const { subGroupId, count, event, existingAssetsCount } = action.payload;
            return {
                ...state,
                noOfSubGroupAssetsAdded: {
                    [subGroupId]: { count, event, existingAssetsCount }
                },
                saving: ActionStatus.Complete
            }
        }


        default: {
            return state;
        }
    }
}

export const getAssetIdsForEvent = (state: State) => state.assetIdsForEvent;
export const getAssetCountsForEvent = (state: State) => state.eventTotalCount;
export const getAssetEventForAssetIds = (state: State) => state.assetEventForAssetId;
export const getQuantityOnHandForAssetId = (state: State) => state.quantityOnHandForAssetId;
export const getAssetEventSaving = (state: State) => state.saving;
export const getNonTransferAssets = (state: State) => state.nonTransferAsset;
export const getNoOfGroupAssetsAdded = (state: State) => state.noOfGroupAssetsAdded;
export const getNoOfSubGroupAssetsAdded = (state: State) => state.noOfSubGroupAssetsAdded;
