import { Action } from '@ngrx/store';
import { PageType, SelectType } from '../../../layout/sidenav-filter/sidenav-filter.component';

export const OPEN_FILTER = '[Sidenav-filter] Open Sidenav Filter';
export const CLOSE_FILTER = '[Sidenav-filter] Close Sidenav Filter';

export const UPDATE_OPTIONS = '[Sidenav-filter] Select Chars, Sets or Currencies';

export const UPDATE_PROJECT = '[Sidenav-filter] Update Project';
export const UPDATE_PROJECT_COMPLETE = '[Sidenav-filter] Update Project Complete';
export const UPDATE_PROJECT_FAILED = '[Sidenav-filter] Update Project Failed';

export const LIST_PROJECTS = '[Sidenav-filter] List Projects';
export const LIST_PROJECTS_COMPLETE = '[Sidenav-filter] List Projects Complete';
export const LIST_PROJECTS_FAILED = '[Sidenav-filter] List Projects Failed';

export const UPDATE_WAREHOUSE = '[Sidenav-filter] Update Warehouse';
export const UPDATE_WAREHOUSE_COMPLETE = '[Sidenav-filter] Update Warehouse Complete';
export const UPDATE_WAREHOUSE_FAILED = '[Sidenav-filter] Update Warehouse Failed';

export const LIST_WAREHOUSES = '[Sidenav-filter] List Warehouses';
export const LIST_WAREHOUSES_COMPLETE = '[Sidenav-filter] List Warehouses Complete';
export const LIST_WAREHOUSES_FAILED = '[Sidenav-filter] List Warehouses Failed';

export const UPDATE_MULTIPLE_PROJECT = '[Sidenav-filter] Update Multiple Project';
export const UPDATE_MULTIPLE_PROJECT_COMPLETE = '[Sidenav-filter] Update Multiple Project Complete';
export const UPDATE_MULTIPLE_PROJECT_FAILED = '[Sidenav-filter] Update Multiple Project Failed';

export const UPDATE_MULTIPLE_WAREHOUSE = '[Sidenav-filter] Update Multiple Warehouse';
export const UPDATE_MULTIPLE_WAREHOUSE_COMPLETE = '[Sidenav-filter] Update Multiple Warehouse Complete';
export const UPDATE_MULTIPLE_WAREHOUSE_FAILED = '[Sidenav-filter] Update Multiple Warehouse Failed';

export enum Options { Characters, Sets, Currencies }

export class OpenFilterAction implements Action {
    readonly type = OPEN_FILTER;
}

export class CloseFilterAction implements Action {
    readonly type = CLOSE_FILTER;
}

export class UpdateOptionsAction implements Action {
    readonly type = UPDATE_OPTIONS;

    constructor(public payload: Options) { }
}

export class UpdateProjectAction implements Action {
    readonly type = UPDATE_PROJECT;
    constructor(public payload: { franchiseId: number, projectId: number, selectType: SelectType, pageType: PageType }) { }
}

export class UpdateProjectCompleteAction implements Action {
    readonly type = UPDATE_PROJECT_COMPLETE;

    constructor(public payload: { entriesObject: { [franchiseId: number]: number[] }, pageType: PageType }) { }
}

export class UpdateProjectFailedAction implements Action {
    readonly type = UPDATE_PROJECT_FAILED;

    constructor(public payload: any) { }
}

export class ListProjectsAction implements Action {
    readonly type = LIST_PROJECTS;
    constructor(public payload: { franchiseId: number, pageType: PageType }) { }
}

export class ListProjectsCompleteAction implements Action {
    readonly type = LIST_PROJECTS_COMPLETE;

    constructor(public payload: { entriesObject: { [franchiseId: number]: number[] }, pageType: PageType }) { }
}

export class ListProjectsFailedAction implements Action {
    readonly type = LIST_PROJECTS_FAILED;

    constructor(public payload: any) { }
}

export class UpdateWarehouseAction implements Action {
    readonly type = UPDATE_WAREHOUSE;
    constructor(public payload: { divisionId: number, warehouseId: number, selectType: SelectType, pageType: PageType }) { }
}

export class UpdateWarehouseCompleteAction implements Action {
    readonly type = UPDATE_WAREHOUSE_COMPLETE;

    constructor(public payload: { entriesObject: { [divisionId: number]: number[] }, pageType: PageType }) { }
}

export class UpdateWarehouseFailedAction implements Action {
    readonly type = UPDATE_WAREHOUSE_FAILED;

    constructor(public payload: any) { }
}

export class ListWarehousesAction implements Action {
    readonly type = LIST_WAREHOUSES;
    constructor(public payload: { divisionId: number, pageType: PageType }) { }
}

export class ListWarehousesCompleteAction implements Action {
    readonly type = LIST_WAREHOUSES_COMPLETE;

    constructor(public payload: { entriesObject: { [divisionId: number]: number[] }, pageType: PageType }) { }
}

export class ListWarehousesFailedAction implements Action {
    readonly type = LIST_WAREHOUSES_FAILED;

    constructor(public payload: any) { }
}

export class UpdateMultipleProjectAction implements Action {
    readonly type = UPDATE_MULTIPLE_PROJECT;
    constructor(public payload: { franchiseId: number, projectId: number[], selectType: SelectType, pageType: PageType, selectAll: boolean }) { }
}

export class UpdateMultipleProjectCompleteAction implements Action {
    readonly type = UPDATE_MULTIPLE_PROJECT_COMPLETE;

    constructor(public payload: { entriesObject: { [franchiseId: number]: number[] }, pageType: PageType }) { }
}

export class UpdateMultipleProjectFailedAction implements Action {
    readonly type = UPDATE_MULTIPLE_PROJECT_FAILED;

    constructor(public payload: any) { }
}

export class UpdateMultipleWarehouseAction implements Action {
    readonly type = UPDATE_MULTIPLE_WAREHOUSE;
    constructor(public payload: { divisionId: number, warehouseId: number[], selectType: SelectType, pageType: PageType, selectAll: boolean }) { }
}

export class UpdateMultipleWarehouseCompleteAction implements Action {
    readonly type = UPDATE_MULTIPLE_WAREHOUSE_COMPLETE;

    constructor(public payload: { entriesObject: { [divisionId: number]: number[] }, pageType: PageType }) { }
}

export class UpdateMultipleWarehouseFailedAction implements Action {
    readonly type = UPDATE_MULTIPLE_WAREHOUSE_FAILED;

    constructor(public payload: any) { }
}
export type Actions
    = OpenFilterAction
    | CloseFilterAction
    | UpdateOptionsAction
    | UpdateProjectAction
    | UpdateProjectCompleteAction
    | UpdateProjectFailedAction
    | ListProjectsAction
    | ListProjectsCompleteAction
    | ListProjectsFailedAction
    | UpdateWarehouseAction
    | UpdateWarehouseCompleteAction
    | UpdateWarehouseFailedAction
    | ListWarehousesAction
    | ListWarehousesCompleteAction
    | ListWarehousesFailedAction
    | UpdateMultipleProjectAction
    | UpdateMultipleProjectCompleteAction
    | UpdateMultipleProjectFailedAction
    | UpdateMultipleWarehouseAction
    | UpdateMultipleWarehouseCompleteAction
    | UpdateMultipleWarehouseFailedAction;
