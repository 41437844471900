import { Action } from '@ngrx/store';

export const CLAIM_USER_ACCOUNT = '[User] Claim Account';
export const CLAIM_USER_ACCOUNT_COMPLETE = '[User] Claim Account Complete';
export const CLAIM_USER_ACCOUNT_FAILED = '[User] Claim Account Failed';


export class ClaimUserAccount implements Action {
    readonly type = CLAIM_USER_ACCOUNT;

    constructor(public payload: number) { }
}

export class ClaimUserAccountCompleteAction implements Action {
    readonly type = CLAIM_USER_ACCOUNT_COMPLETE;

    constructor(public payload: any) { }
}

export class ClaimUserAccountFailedAction implements Action {
    readonly type = CLAIM_USER_ACCOUNT_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = ClaimUserAccount
    | ClaimUserAccountCompleteAction
    | ClaimUserAccountFailedAction;