import { Project } from '../projects/project.model';
import { BaseModel } from '../base/base.model';


export class SosProjectDepartment extends BaseModel {
    id: number;
    project_id: number = null;
    department_id: number = null;
    sos_episodic_id: number = null;
    project: Project = null;
    departments: any[];
    franchise: string = null;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(sosProjectDepartmentObject) {
        super();
        this.initializeData(sosProjectDepartmentObject, this);
        if (sosProjectDepartmentObject.project) {
            this.project = new Project(sosProjectDepartmentObject.project);
        }
        if (sosProjectDepartmentObject.departments) {
            this.departments = [...sosProjectDepartmentObject.departments];
        }
    }
}
