import { createSelector } from 'reselect';
import * as root from './';
import * as characterSelectors from './characters/character.selectors';
import * as projectCurrencySelectors from './project-currencies/project-currency.selectors';
import * as setSelectors from './sets/set.selectors';
import { Options } from './sidenav-filter/sidenav-filter.actions';

function projectSearch(entity, search: string): boolean {
    if (search) {
        let match = entity.name.toLowerCase().includes(search);
        if (!match) {
            if (entity.projectCharacters && entity.projectCharacters.length && entity.projectCharacters[0].code) {
                match = entity.projectCharacters[0].code.includes(search);
            } else if (entity.projectSets && entity.projectSets.length && entity.projectSets[0].code) {
                match = entity.projectSets[0].code.includes(search);
            }
        }

        return match;
    } else {
        return true;
    }
}

const getOptionsForSelectedProject = createSelector(root.getSettingsOptions, characterSelectors.getCharactersForSelectedProject, setSelectors.getSetsForSelectedProject, projectCurrencySelectors.getForSelectedProjectOnSettings, root.getSettingsPageProjectSearch, (options, chars, sets, currencies, search) => {
    switch (options) {
        case Options.Characters: {
            return chars.filter(c => projectSearch(c, search));
        }
        case Options.Sets: {
            return sets.filter(s => projectSearch(s, search));
        }
        case Options.Currencies: {
            return currencies;
        }
    }
});

const getCharsOrSetsWithProjectCharacters = createSelector(root.getSettingsOptions, characterSelectors.getCharactersWithProjectCharacters, setSelectors.getSetsWithProjectSets, (charsOrSets, chars, sets) => {
    return charsOrSets === Options.Characters ? chars : sets;
});

const getOptions = createSelector(root.getSettingsOptions, root.getCharacters, root.getSets, root.getProjectCurrencies, (options, chars, sets, currencies) => {
    switch (options) {
        case Options.Characters: {
            return chars;
        }
        case Options.Sets: {
            return sets;
        }
        case Options.Currencies: {
            return currencies;
        }
    }
});

const optionsSelectors = { getOptionsForSelectedProject, getCharsOrSetsWithProjectCharacters, getOptions };
export { characterSelectors, optionsSelectors, setSelectors };
