import { Action } from '@ngrx/store';
import { SosField } from './sos-field.model';

export const LIST = '[SosField] List';
export const LIST_COMPLETE = '[SosField] List Complete';
export const LIST_FAILED = '[SosField] List Failed';

export class ListAction implements Action {
    readonly type = LIST;

    constructor() { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;
    constructor(public payload: SosField[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction;
