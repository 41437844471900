import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import * as fieldActions from './../../core/store/fields/field.actions';

@Injectable()
export class FieldDataService {
    constructor(private store: Store<fromRoot.State>) { }

    getFields() {
        const combined = observableCombineLatest(this.store.select(fromRoot.getSelectedStudio), this.store.select(fromRoot.getFields));
        return combined.pipe(
            map(([studio, fields]) => {
                if (!fields || !fields.length) {
                    this.store.dispatch(new fieldActions.ListAction(studio.id));
                }
                return fields;
            }),
            filter((fields) => !!fields.length),
            take(1));
    }
}
