import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as layoutActions from '../../../core/store/layout/layout.actions';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IntercomService } from '../../services/intercom.service';
import { User } from '../../../core/store/models';
import { redirectToLogout } from '../../services/user.service';


@Component({
    selector: 'ah-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    user: User;

    constructor(private route: ActivatedRoute, public store: Store, private intercomService: IntercomService) { }

    ngOnInit(): void {
        this.setupIntercom();
        if (this.route.snapshot.queryParamMap) {
            const loginError = this.route.snapshot.queryParamMap.get('loginError');
            if (loginError) {
                const msg = this.route.snapshot.queryParamMap.get('msg');
                if (msg.length > 0) {
                    this.store.dispatch(new layoutActions.ErrorAction({
                        error: new HttpErrorResponse({ error: new Error(msg) })
                    }));
                    setTimeout(() => {
                        redirectToLogout();
                    }, 5000);
                } else {
                    this.store.dispatch(new layoutActions.ErrorAction({
                        error: new HttpErrorResponse({ error: new Error('An error has occurred. If this error persists, please contact Support.') })
                    }));
                }
            }
        }
    }

    setupIntercom() {
        this.intercomService.launch(this.user);
    }
}
