<mat-menu class="studio-switcher" #studioListMenu="matMenu" x-position="before">
    <button mat-menu-item routerLink="/studio/{{currentStudio?.id}}">
        <b>{{currentStudio?.name}}</b>
    </button>
    <ng-container *ngIf="studioList && studioList.length > 1">
        <hr>
        <ng-container *ngFor="let studio of studioList; trackBy: studio?.id">
            <button mat-menu-item (click)="selectStudioFromMenu.emit(studio)" *ngIf="studio?.id !== currentStudio?.id">
                <span style="font-size: 14px;">Switch to: <b>{{getName(studio)}}</b></span>
            </button>
        </ng-container>
    </ng-container>
    <hr>
    <button *ngIf="currentStudio" mat-menu-item routerLink="{{'studio/'+currentStudio.id+'/edit-account'}}">Edit Account</button>
    <button *ngIf="!currentStudio" mat-menu-item routerLink="edit-account">Edit Account</button>
    <a mat-menu-item href="https://support.synconset.com/category/7e9j3qg4rq-asset-hub" target="_blank"
        alt="Help Desk">Help & Support</a>
    <button mat-menu-item (click)="lightSwitch.emit()">Lights
        <span *ngIf="dark">On</span>
        <span *ngIf="!dark">Out</span>
    </button>
    <button mat-menu-item (click)="logout()">Logout</button>
    <hr *ngIf="version">
    <span *ngIf="version" class="version-tag">
        v{{version}}
    </span>
</mat-menu>
<button mat-icon-button [matMenuTriggerFor]="studioListMenu">
    <mat-icon svgIcon="apps"></mat-icon>
</button>
