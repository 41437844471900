import { FIELD_TYPES } from '../../../../shared/fields/field-types';
import { Field } from '../../core/store/models';
import { TableFilterObject } from '../../core/store/types';
// import { FilterUtils } from 'primeng/utils';
import { Injectable } from "@angular/core";

@Injectable()
export class ColumnFilterService {
    savedColumnFilterValues: TableFilterObject = {};
    savedColumnFilterValuesForSecondTable: TableFilterObject = {};

    setCustomFilterConstraints() {
        // FilterUtils['between'] = this.numberBetweenFunction;
        // FilterUtils['betweenCurrency'] = this.getCurrencyBetweenFunction(this);
    }
    /**
     *  These are primeNG things- the string returned indicates how to compare the value with the filter selections
     */
    getFilterMatchMode(type: string) {
        if (type === FIELD_TYPES.NUMBER.type || type === FIELD_TYPES.DATE.type) {
            return 'between';
        } else if (type === FIELD_TYPES.CURRENCY.type) {
            return 'betweenCurrency';
        } else {
            return 'in';
        }
    }

    getSavedColumnFilterValues(storageName: string) {
        this.savedColumnFilterValues = JSON.parse(localStorage.getItem(storageName));
        return this.savedColumnFilterValues ? this.savedColumnFilterValues : {};
    }

    getSavedColumnFilterValuesForSecondTable(storageName: string) {
        this.savedColumnFilterValuesForSecondTable = JSON.parse(localStorage.getItem(storageName));
        return this.savedColumnFilterValuesForSecondTable ? this.savedColumnFilterValuesForSecondTable : {};
    }

    /**
     * Whether the dropdowns should filter based on the asset's character/set id or the displayfield string
     */
    returnFilterField(field: Field) {
        return field.canonical_name;
    }

    numberBetweenFunction(value: number, filter: number[]) {
        if (!value && filter[0] === 0) {
            return true;
        } else {
            return value !== null && value !== undefined && value >= filter[0] && value <= filter[1];
        }
    }

    // Returns a function so we can access ColumnFilterService when this is called from the datatable
    getCurrencyBetweenFunction(thisObject: ColumnFilterService) {
        return (value, filter) => {
            const strippedValue = value ? thisObject.removeCurrencySymbols(value) : null;

            if (filter[0] === '' && filter[1] !== '') {
                return strippedValue !== null && strippedValue <= filter[1];
            } else if (filter[1] === '' && filter[0] !== '') {
                return strippedValue !== null && strippedValue >= filter[0];
            } else if (filter[0] !== '' && filter[1] !== '') {
                return strippedValue !== null && strippedValue >= filter[0] && strippedValue <= filter[1];
            } else {
                return true;
            }
        };
    }

    private removeCurrencySymbols(value: string) {
        if (typeof value !== 'string') {
            return 0;
        }
        return Number(value.replace(/[^0-9\.-]+/g, ''));
    }
}
