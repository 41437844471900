import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import * as layoutActions from '../layout/layout.actions';
import { MatchField } from '../match-fields/match-field.model';
import * as actions from './asset-view.actions';

@Injectable()
export class AssetViewEffects {
    listSelectedFields$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(actions.LIST_SELECTED_TRANSACTION_TABLE_FIELDS),
                map((action: actions.ListSelectedTransactionTableFieldsAction) => action.payload),
                switchMap((payload) => {
                    const fields = JSON.parse(localStorage.getItem(`assetView.transactionTable.selectedFields.${payload.franchiseId}-${payload.projectId}`));
                    if (!fields || !fields.length) {
                        return this.http.get<MatchField[]>(`/api/franchises/${payload.franchiseId}/match-fields`).pipe(
                            map((results: Array<Partial<MatchField>>) => {
                                const fields: string[] = [];
                                results.forEach(r => {
                                    if (r.ledger_field_gv && !fields.includes(r.ledger_field_gv)) {
                                        fields.push(r.ledger_field_gv);
                                    }
                                    if (r.ledger_field_psl && !fields.includes(r.ledger_field_psl)) {
                                        fields.push(r.ledger_field_psl);
                                    }
                                    if (r.ledger_field_other && !fields.includes(r.ledger_field_other)) {
                                        fields.push(r.ledger_field_other);
                                    }
                                });
                                try {
                                    this.localStorageService.updateSettings(`assetView.transactionTable.selectedFields.${payload.franchiseId}-${payload.projectId}`, JSON.stringify(fields));
                                    return new actions.ListSelectedTransactionTableFieldsCompleteAction({
                                        franchiseId: payload.franchiseId,
                                        projectId: payload.projectId,
                                        fields
                                    });
                                } catch (error) {
                                    return new actions.ListSelectedTransactionTableFieldsFailedAction({ error, franchiseId: payload.franchiseId, projectId: payload.projectId });
                                }
                            }),
                            catchError(error => observableOf(new actions.ListSelectedTransactionTableFieldsFailedAction({ error, franchiseId: payload.franchiseId, projectId: payload.projectId })))
                        );
                    }
                    return observableOf(new actions.ListSelectedTransactionTableFieldsCompleteAction({
                        franchiseId: payload.franchiseId,
                        projectId: payload.projectId,
                        fields
                    }));
                })
            )
    );

     listSelectedFieldsFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.LIST_SELECTED_TRANSACTION_TABLE_FIELDS_FAILED),
            map((action: actions.ListSelectedTransactionTableFieldsFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     saveSelectedFields$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(actions.SAVE_SELECTED_TRANSACTION_TABLE_FIELDS),
            map((action: actions.SaveSelectedTransactionTableFieldsAction) => action.payload),
            switchMap(({ franchiseId, projectId, fields }): Observable<Action> => {
                try {
                    this.localStorageService.updateSettings(`assetView.transactionTable.selectedFields.${franchiseId}-${projectId}`, JSON.stringify(fields));
                    return observableOf(new actions.SaveSelectedTransactionTableFieldsCompleteAction({
                        franchiseId,
                        projectId,
                        fields
                    }));
                } catch (error) {
                    return observableOf(new actions.SaveSelectedTransactionTableFieldsFailedAction({ error, franchiseId }));
                }
            })
        ));

    constructor(private actions$: Actions, private http: HttpClient, private localStorageService: LocalStorageService) { }
}
