import { Project } from '../projects/project.model';
import { BaseModel } from './../base/base.model';

export enum FranchiseType {
    Franchise,
    Series
}

export class Franchise extends BaseModel {
    id: number;
    is_series = false;
    studio_id: number = null;
    name: string = null;
    projects?: Project[];

    constructor(franchiseObj: any = {}) {
        super();
        this.initializeData(franchiseObj, this);
        if (franchiseObj.projects) {
            this.projects = franchiseObj.projects.map((p) => new Project(p));
        }
    }
}

export const franchiseObjectMock = {
    id: 1,
    studio_id: 1,
    name: 'SyncOnSet'
};
