import { createSelector } from 'reselect';
import { RequestNoteTypes } from '../../../../../shared/enums/request-note-types.enum';
import { RequestNote } from '../request-notes/request-note.model';
import * as requestActions from '../requests/request.actions';
import { Request } from '../requests/request.model';
import { ActionStatus, SortObject } from '../types';
import { copyAndMultiSort, getIdsAndEntities, uniqueArrayMerge } from '../utils';
import * as finalApproverRequestActions from './final-approver-request.actions';
import { FinalApproverRequest } from './final-approver-request.model';

export interface State {
    ids: number[];
    entities: { [id: number]: FinalApproverRequest };
    saving: ActionStatus;
    sort: SortObject;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Complete,
    sort: null
};

export function reducer(state = initialState, action: finalApproverRequestActions.Actions | requestActions.Actions): State {
    switch (action.type) {
        case finalApproverRequestActions.UPDATE:
        case finalApproverRequestActions.CREATE: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case finalApproverRequestActions.UPDATE_COMPLETE:
        case requestActions.LIST_FINAL_APPROVER_REQUESTS_COMPLETE:
        case requestActions.GET_COMPLETE: {
            let payload: FinalApproverRequest[];
            if (action.type === finalApproverRequestActions.UPDATE_COMPLETE || action.type === requestActions.GET_COMPLETE) {
                payload = [action.payload.finalApproverRequest];
            } else {
                payload = action.payload.finalApproverRequests;
            }
            const { ids, entities } = getIdsAndEntities(state, payload);

            return {
                ...state,
                ids: uniqueArrayMerge(state.ids, ids),
                entities: {
                    ...state.entities,
                    ...entities
                },
                saving: ActionStatus.Complete
            };
        }

        case finalApproverRequestActions.CREATE_COMPLETE: {
            return {
                ...state,
                saving: ActionStatus.Complete
            };
        }

        case finalApproverRequestActions.UPDATE_FAILED:
        case finalApproverRequestActions.CREATE_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case finalApproverRequestActions.SORT: {
            const sortData = { field: action.payload.field, order: action.payload.order, args: (action.payload.args ? action.payload.args : []) };

            if (state.sort
                && state.sort.args === sortData.args
                && state.sort.field === sortData.field
                && state.sort.order === sortData.order) {
                // Cancel sorting
                return state;
            }

            return {
                ...state,
                sort: sortData
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getSaving = (state: State) => state.saving;
export const getSort = (state: State) => state.sort;
