import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import * as layoutActions from '../../core/store/layout/layout.actions';
import { ActionState, ActionStatus } from '../../core/store/types';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';

@Injectable()
export class ActionStateService {
    constructor(private store: Store<fromRoot.State>, private dialog: MatDialog) { }

    /**
     * Takes an ActionState. Toasts if successful, displays a modal with the error message if not
     */
    handle(actionState: ActionState, successMessage: string, errorTitle: string) {
        if (actionState.status === ActionStatus.Complete) {
            this.store.dispatch(new layoutActions.InfoAction({
                message: successMessage
            }));
        } else if (actionState.status === ActionStatus.Failed) {
            this.dialog.open(ConfirmModalComponent, {
                data: {
                    allowCancel: false,
                    confirmButton: 'Cancel',
                    htmlMessage: this.getErrorMessage(actionState.error),
                    title: errorTitle
                }
            });
        }
    }

    private getErrorMessage(err: HttpErrorResponse): string {
        if (err && err.error && err.error.message) {
            return err.error.message;
        } else {
            return 'Something went wrong, please refresh and try again.';
        }
    }
}
