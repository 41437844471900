import { createSelector } from 'reselect';
import { ActionState, ActionStatus } from '../types';
import { getIdsAndEntities } from '../utils';
import * as projectCurrencyActions from './project-currency.actions';
import { ProjectCurrency } from './project-currency.model';

export interface State {
    ids: number[];
    entities: { [projectCurrencyId: number]: ProjectCurrency };
    saving: ActionStatus;
    selectedId: number;
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Complete,
    selectedId: null,
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: projectCurrencyActions.Actions): State {
    switch (action.type) {
        case projectCurrencyActions.ADD_COMPLETE: {
            const updatedPCs = action.payload;
            const { ids, entities } = getIdsAndEntities(state, updatedPCs);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities },
                saving: ActionStatus.Complete
            };
        }

        case projectCurrencyActions.ADD: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case projectCurrencyActions.ADD_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case projectCurrencyActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case projectCurrencyActions.DELETE_COMPLETE: {
            const { deletedPC, usPC } = action.payload;
            const updatedPCs = usPC ? [usPC] : [];
            const { entities } = getIdsAndEntities(state, updatedPCs);
            const allEnts = { ...state.entities, ...entities };
            delete allEnts[deletedPC.id];

            return {
                ...state,
                ids: state.ids.filter(id => id !== deletedPC.id),
                entities: allEnts,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case projectCurrencyActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case projectCurrencyActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case projectCurrencyActions.LIST_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        case projectCurrencyActions.SELECT: {
            return { ...state, selectedId: action.payload };
        }

        case projectCurrencyActions.UPDATE_COMPLETE: {
            const updatedPCs = action.payload;
            const { entities } = getIdsAndEntities(state, updatedPCs);
            return {
                ...state,
                entities: { ...state.entities, ...entities }
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getSaving = (state: State) => state.saving;
export const getSelectedId = (state: State) => state.selectedId;
export const getDeleteState = (state: State) => state.deleteState;
