import { createSelector } from 'reselect';
import { ActionStatus } from '../types';
import * as fieldActions from './field.actions';
import { Field } from './field.model';

export interface State {
    ids: number[];
    entities: {
        [fieldId: number]: Field
    };
    selectedIds: number[];
    excludedTabSelectedIds: number[];
    deptFieldIdsForField: { [fieldId: number]: number[] };
    saving: ActionStatus;
}

export const initialState: State = {
    ids: [],
    entities: {},
    selectedIds: [],
    excludedTabSelectedIds: [],
    deptFieldIdsForField: {},
    saving: ActionStatus.Inactive
};

export function reducer(state = initialState, action: fieldActions.Actions): State {
    switch (action.type) {
        case fieldActions.CREATE_COMPLETE: {
            const field = action.payload.field;

            const deptFieldIdsForField = { ...state.deptFieldIdsForField };
            if (field.departmentFields) {
                deptFieldIdsForField[field.id] = field.departmentFields.map(df => df.id);
            }

            return {
                ...state,
                ids: [...state.ids, field.id],
                entities: { ...state.entities, [field.id]: field },
                deptFieldIdsForField,
                saving: ActionStatus.Complete
            };
        }

        case fieldActions.CREATE: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case fieldActions.CREATE_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case fieldActions.UPDATE_COMPLETE: {
            const field = action.payload.field;
            const deptFieldIdsForField = { ...state.deptFieldIdsForField };
            if (field.departmentFields) {
                deptFieldIdsForField[field.id] = field.departmentFields.map(df => df.id);
            }

            return {
                ...state,
                entities: { ...state.entities, [field.id]: field },
                deptFieldIdsForField,
                saving: ActionStatus.Complete
            };
        }

        case fieldActions.UPDATE: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case fieldActions.UPDATE_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case fieldActions.DELETE: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case fieldActions.DELETE_COMPLETE: {
            const field = action.payload;

            let idsCopy = [...state.ids];
            const entitiesCopy = { ...state.entities };
            idsCopy = idsCopy.filter(sId => sId != field.id);
            delete entitiesCopy[field.id];

            const deptFieldIdsForField = { ...state.deptFieldIdsForField };
            delete deptFieldIdsForField[field.id];

            return {
                ...state,
                ids: idsCopy,
                entities: entitiesCopy,
                deptFieldIdsForField,
                saving: ActionStatus.Complete
            };
        }

        case fieldActions.DELETE_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case fieldActions.LIST_COMPLETE: {
            const fields = action.payload.fields;
            const fieldIdMap = {};
            const fieldIds = [];
            const deptFieldIds = {};

            fields.forEach(f => {
                fieldIdMap[f.id] = f;
                fieldIds.push(f.id);
                if (f.departmentFields) {
                    deptFieldIds[f.id] = f.departmentFields.map(deptField => deptField.id);
                }
            });

            const newFieldIds = fieldIds.filter(id => !state.entities[id]);
            return {
                ...state,
                ids: [...state.ids, ...newFieldIds],
                entities: {
                    ...state.entities,
                    ...fieldIdMap
                },
                deptFieldIdsForField: {
                    ...state.deptFieldIdsForField,
                    ...deptFieldIds
                }
            };
        }

        case fieldActions.SAVE_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_TRANSACTION_SELECTED_COMPLETE:
        case fieldActions.LIST_TRANSACTION_SELECTED_COMPLETE:
        case fieldActions.SAVE_DIVISION_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_DIVISION_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_EVENT_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_EVENT_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_FRANCHISE_GROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_FRANCHISE_GROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_DIVISION_GROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_STUDIO_GROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_DIVISION_GROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_STUDIO_GROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_STUDIO_SUBGROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_STUDIO_SUBGROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_FRANCHISE_SUBGROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_DIVISION_SUBGROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_DIVISION_SUBGROUP_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_REQUEST_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_REQUEST_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_MATCH_ASSET_SELECTED_COMPLETE:
        case fieldActions.LIST_MATCH_ASSET_SELECTED_COMPLETE:
        case fieldActions.SAVE_MATCH_REVIEW_SELECTED_COMPLETE:
        case fieldActions.LIST_MATCH_REVIEW_SELECTED_COMPLETE:
        case fieldActions.UPDATE_TRANSACTION_FIELDS_COMPLETE:
        case fieldActions.SAVE_STUDIO_SELECTED_COMPLETE:
        case fieldActions.LIST_STUDIO_SELECTED_COMPLETE: {
            const ids = action.payload;
            const alterWith = { selectedIds: ids };
            return { ...state, ...alterWith };
        }

        case fieldActions.SAVE_EXCLUDED_TRANSACTION_SELECTED_COMPLETE: {
            const ids = action.payload;
            const alterWith = { excludedTabSelectedIds: ids };
            return { ...state, ...alterWith }
        }

        case fieldActions.UPDATE_TRANSACTION_TAB_FIELDS_COMPLETE: {
            const ids = action.payload;
            const alterWith = { selectedIds: ids, excludedTabSelectedIds: ids };
            return { ...state, ...alterWith };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getSelectedIds = (state: State) => state.selectedIds;
export const getExcludedTabSelectedIds = (state: State) => state.excludedTabSelectedIds;
export const getSaving = (state: State) => state.saving;
export const getDeptFieldIdsForField = (state: State) => state.deptFieldIdsForField;

export const getAllAndDeleted = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getAll = createSelector(getEntities, getIds, (entities, ids) => {
    const fields = ids.map(id => entities[id]);
    return fields.filter(f => f.deleted == false);
});
