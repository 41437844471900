<div class="asset-modal-content modal-flex" *ngIf="assetForm" ngClass.xs="flex-column" 
    ngClass.sm="mobile-asset-modal-content">
    <form novalidate class="create-form asset-form" [formGroup]="assetForm" ngClass.xs="flex-column"
        style="width: 100%">
        <!-- Top Icons Only on mobile -->
        <div ngClass.xs="display-block" class="display-none top-icons mobile-top-icons">
            <mat-chip-listbox class="trash-chip" *ngIf="asset.trashed">
                <mat-chip-option>This asset is in the trash</mat-chip-option>
            </mat-chip-listbox>
            <p-menu #menu appendTo="body" popup="popup" [model]="modalOptions"></p-menu>
            <mat-icon *ngIf="(action === ASSET_MODAL_TYPE.EDIT) && !asset.for_review"
                class="clickable mobile-trash-icon" (click)="menu.toggle($event)" svgIcon="settings"></mat-icon>
            <mat-icon class="clickable" (click)="close()" svgIcon="clear"></mat-icon>
        </div>
        <div class="field-div" style="order: 1;" ngStyle.xs="order: 1;">
            <div *ngFor="let field of otherFields; trackBy: field?.id">
                <ah-asset-field-input class="wide" [group]="assetForm" [field]="field.field"
                    [required]="field.departmentField.required" [options]="field.field.picklistOptions">
                </ah-asset-field-input>
            </div>
        </div>
        <div class="purchase-div" ngStyle.gt-xs="margin-left: 7px" style="order: 2" ngClass.xs="order: 2">
            <!-- purchase fields -->
            <div [ngClass]="{'inline': !(selectedOwner === OwnerType.Division) && (field.field.canonical_name === standardFields.cost.canonical_name || field.field.canonical_name === standardFields.origin_currency.canonical_name)}"
                *ngFor="let field of purchaseFields; trackBy: field?.id" [ngSwitch]="field.field.canonical_name">
                <ah-asset-field-input class="wide" *ngSwitchCase="standardFields.disposition.canonical_name" [group]="assetForm" [field]="field.field"
                    [required]="field.departmentField.required" [options]="dispositionOptions$ | async"></ah-asset-field-input>
                <ah-asset-field-input class="wide" *ngSwitchCase="standardFields.origin_currency.canonical_name" [group]="assetForm" [field]="field.field"
                    [required]="field.departmentField.required" [options]="projectCurrencyOptions$ | async"></ah-asset-field-input>
                <ah-asset-field-input class="wide" *ngSwitchCase="standardFields.cost.canonical_name" [group]="assetForm" [field]="field.field"
                    [required]="field.departmentField.required" [options]="field.field.picklistOptions"></ah-asset-field-input>
                <ah-asset-field-input class="wide" *ngSwitchCase="standardFields.status.canonical_name" [group]="assetForm" [field]="field.field"
                    [required]="field.departmentField.required" [options]="statusOptions$ | async"></ah-asset-field-input>
                <ah-asset-field-input class="wide" *ngSwitchDefault [group]="assetForm" [field]="field.field" [required]="field.departmentField.required"
                    [options]="field.field.picklistOptions"></ah-asset-field-input>
            </div>
        </div>
        <div class="owner-div" ngStyle.gt-xs="margin-left: 7px" style="order: 3;margin-left: 7%;" ngStyle.xs="order: 3"
            ngClass.xs="mobile-owner-div">
            <div class="top-icons" ngClass.xs="display-none">
                <mat-chip-listbox class="trash-chip" *ngIf="asset.trashed">
                    <mat-chip-option>This asset is in the trash</mat-chip-option>
                </mat-chip-listbox>
                <p-menu #menu appendTo="body" popup="popup" [model]="modalOptions"></p-menu>
                <mat-icon *ngIf="(action === ASSET_MODAL_TYPE.EDIT) && !asset.for_review" class="clickable"
                    (click)="menu.toggle($event)" svgIcon="settings"></mat-icon>
                <mat-icon class="clickable" (click)="close()" svgIcon="clear"></mat-icon>
            </div>
            <div class="owner-fields" ngClass.xs="mobile-owner-fields">
                <div *ngFor="let field of ownerFields; trackBy: field?.id" [ngSwitch]="field.field.canonical_name">
                    <ah-owner-fields [assetForm]="assetForm" [field]="field" [ownerOptions]="ownerOptions"
                        [disableOwnerChange]="disableOwnerChange" (selectOwner)="selectOwner($event)"
                        [divisionOptions]="divisionOptions" (selectDivision)="selectDivision($event)"
                        [selectedOwner]="selectedOwner" [currentFranchiseOption]="currentFranchiseOption"
                        [ownedFranchiseOptions]="ownedFranchiseOptions" (selectFranchise)="selectFranchise($event)"
                        [projectOptions$]="projectOptions$" (selectProject)="selectProject($event)"
                        [warehouseOptions]="warehouseOptions" (selectWarehouse)="selectWarehouse($event)"
                        [locationOptions$]="locationOptions$" (selectLocation)="selectLocation($event)"
                        [warehouseLocationOptions$]="warehouseLocationOptions$"
                        (selectWarehouseLocation)="selectWarehouseLocation($event)"
                        [subLocationOptions$]="subLocationOptions$" (selectSubLocation)="selectSubLocation($event)"
                        [warehouseSubLocationOptions$]="warehouseSubLocationOptions$"
                        (selectWarehouseSubLocation)="selectWarehouseSubLocation($event)"
                        [storageBoxOptions$]="storageBoxOptions$" (selectStorageBox)="selectStorageBox($event)"
                        [warehouseLocationSubLocationShelfDisabled]="warehouseLocationSubLocationShelfDisabled"
                        [placeholder]="placeholder">
                    </ah-owner-fields>
                    <!-- Department Field -->
                    <div>
                        <ah-asset-field-input *ngSwitchCase="standardFields.department.canonical_name" [isButton]="true" [group]="assetForm" [field]="field.field"
                            [required]="field.departmentField.required" [options]="departmentOptions$ | async" (change)="selectDepartment($event)">
                        </ah-asset-field-input>
                    </div>
                    <!-- Sub Department Field -->
                    <div class="bottom-margin no-top-border">
                        <ah-asset-field-input *ngSwitchCase="standardFields.sub_department.canonical_name" [isButton]="true" [group]="assetForm"
                            [field]="field.field" [required]="field.departmentField.required" [options]="assetForm?.value?.department_id?(subDepartmentOptions$ | async):[]"
                            [placeholder]="'Sub Department'" [disabled]="!(subDepartmentOptions$ | async).length" (change)="selectSubDepartment($event)">
                        </ah-asset-field-input>
                    </div>
                    <!-- Character Field -->
                    <div class="bottom-margin" *ngSwitchCase="standardFields.character.canonical_name">
                        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field"
                            [required]="field.departmentField.required" [options]="characterOptions$ | async"
                            [disabled]="!(characterOptions$ | async).length"
                            [icon]="'nature_people'" [iconColor]="'#CA001A'" (change)="selectCharacter($event)">
                        </ah-asset-field-input>
                    </div>
                    <!-- Set Field -->
                    <div class="bottom-margin" *ngSwitchCase="standardFields.set.canonical_name">
                        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required"
                            [options]="setOptions$ | async" [disabled]="!(setOptions$ | async).length"
                            [icon]="'nature_people'" [iconColor]="'#CA001A'" (change)="selectSet($event)">
                        </ah-asset-field-input>
                    </div>
                    <!-- Disposition Field -->
                    <div class="input-wrapper-p-dropdown bottom-margin"
                        *ngSwitchCase="standardFields.disposition.canonical_name">
                        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field"
                            [required]="field.departmentField.required" [options]="dispositionOptions$ | async"
                            [placeholder]="'Disposition'" (change)="selectDisposition($event)">
                        </ah-asset-field-input>
                    </div>
                    <!-- Status Field -->
                    <div class="input-wrapper-p-dropdown bottom-margin"
                        *ngSwitchCase="standardFields.status.canonical_name">
                        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field"
                            [required]="field.departmentField.required" [options]="statusOptions$ | async"
                            [placeholder]="'Status'" (change)="selectStatus($event)">
                        </ah-asset-field-input>
                    </div>
                </div>
                <ah-group-input [selectedGroups]="selectedGroups" [selectedSubGroups]="selectedSubGroups" [divisionGroups]="divisionGroups$ | async"
                    [franchiseGroups]="franchiseGroups$ | async" [notDivisionGroups]="notDivisionGroups$ | async"
                    [notFranchiseGroups]="notFranchiseGroups$ | async" [allGroups]="allGroups$ | async"
                    [studioId]="asset.studio_id" [franchiseId]="asset.franchise_id" [franchise]="franchise"
                    [franchiseTable]="isFranchiseTable" [divisionTable]="isDivisionTable"
                    [divisionId]="asset.division_id" [isAssetModal]="true" (selectGroups)="selectGroups($event)">
                </ah-group-input>
            </div>
            <div style="margin-bottom: 20px;">
                <button mat-raised-button color="primary" (click)="saveAsset($event, false)"
                    [disabled]="assetForm.invalid || asset.trashed || saveDisabled" class="wk-flex-fix">{{saveText}}
                </button>
            </div>
            <div *ngIf="this.action === ASSET_MODAL_TYPE.CREATE" style="margin-bottom: 20px;">
                <button mat-raised-button color="primary" (click)="saveAsset($event, true)"
                    [disabled]="assetForm.invalid || asset.trashed || saveDisabled" class="wk-flex-fix">{{saveAndCreateText}}
                </button>
            </div>
            <div style="margin-bottom: 20px;">
                <span style="color: #f44336">* - required field</span>
            </div>
        </div>
    </form>
</div>
