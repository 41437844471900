<div class="sidenav-filter ui-shadow-2" [ngClass]="{'sidenav-filter-visible': visible && !isMobileDevice(), 'sidenav-filter-visible-mobile': visible && isMobileDevice() }">
    <div class="sidenav-filter-list">
        <mat-icon class="clickable sidenav-toggle" svgIcon="chevron_left" (click)="toggleVisible.emit()"></mat-icon>
        <div *ngFor="let filter of filters; let i = index; let first = first" class="margin-top">
            <input *ngIf="filter.search" type="text" #searchFilter placeholder="Search" (keyup)="clear.emit()">
            <div *ngIf="assetShown !== historicalAsset">
                <div *ngIf="filter.title !== 'Departments'">
                    <h3 [ngStyle]="{ 'margin-top.px': first ? 5 : 40}" class="filter-header ellipsis">{{ filter.title }}</h3>
                    <span *ngIf="tableType !== 1  && settings && storage">Select All <mat-slide-toggle [(ngModel)]="allowProject" (change)="selectProject.emit({name: filter.title, allowProject:allowProject})"></mat-slide-toggle> </span>
                    <mat-nav-list *ngIf="filter.type === filterType.Multi; else single">
                        <mat-list-item class="filter-item" *ngFor="let entity of (filter.entities | async) | nameSearch:searchFilter?.value; trackBy: entity?.id">
                            <mat-checkbox class="filter-checkbox ellipsis" [matTooltip]="filterName(filter, entity)" matTooltipPosition="after" [matTooltipShowDelay]="tooltipDelay"
                                [checked]="isEntityChecked(entity, filter.selectedEntityIds)" (change)="select.emit({name: filter.name, entity: entity, selectType: filter.type})">{{filterName(filter, entity)}}</mat-checkbox>
                        </mat-list-item>
                    </mat-nav-list>
                </div>
                <div *ngIf="filter.title === 'Departments'">
                    <h3 [ngStyle]="{ 'margin-top.px': first ? 5 : 40}" class="filter-header ellipsis">{{ filter.title }}</h3>
                    <span>Select All <mat-slide-toggle [(ngModel)]="allowDept" (change)="selectProject.emit({name: filter.title, allowProject:allowDept})"></mat-slide-toggle> </span>
                    <mat-nav-list *ngIf="filter.type === filterType.Multi; else single">
                        <mat-list-item class="filter-item" *ngFor="let entity of (filter.entities | async) | nameSearch:searchFilter?.value; trackBy: entity?.id">
                            <mat-checkbox class="filter-checkbox ellipsis" [matTooltip]="filterName(filter, entity)" matTooltipPosition="after" [matTooltipShowDelay]="tooltipDelay"
                                [checked]="isEntityChecked(entity, filter.selectedEntityIds)" (change)="select.emit({name: filter.name, entity: entity, selectType: filter.type})">{{filterName(filter, entity)}}</mat-checkbox>
                        </mat-list-item>
                    </mat-nav-list>
                </div>
            </div>
            <div *ngIf="assetShown === historicalAsset">
                <mat-nav-list *ngIf="filter.type === filterType.Multi && filter.title === 'Departments'">
                    <h3 [ngStyle]="{ 'margin-top.px': 5}" class="filter-header ellipsis">{{ filter.title }}</h3>
                    <span>Select All <mat-slide-toggle [(ngModel)]="allowDept" (change)="selectProject.emit({name: filter.title, allowProject:allowDept})"></mat-slide-toggle> </span>
                    <mat-list-item class="filter-item" *ngFor="let entity of (filter.entities | async) | nameSearch:searchFilter?.value; trackBy: entity?.id">
                        <mat-checkbox class="filter-checkbox ellipsis" [matTooltip]="filterName(filter, entity)" matTooltipPosition="after" [matTooltipShowDelay]="tooltipDelay"
                            [checked]="isEntityChecked(entity, filter.selectedEntityIds)" (change)="select.emit({name: filter.name, entity: entity, selectType: filter.type})">{{filterName(filter, entity)}}</mat-checkbox>
                    </mat-list-item>
                </mat-nav-list>
            </div>
            <ng-template #single>
                <mat-radio-button name="filter{{i}}" [matTooltip]="filterName(filter, entity)" matTooltipPosition="after" [matTooltipShowDelay]="tooltipDelay"
                    class="flex-column filter-item filter-checkbox" *ngFor="let entity of (filter.entities | async) | nameSearch:searchFilter?.value; trackBy: entity?.id"
                    [checked]="isEntityChecked(entity, filter.selectedEntityIds)" (change)="select.emit({entity: entity, selectType: filter.type})">{{filterName(filter, entity)}}
                </mat-radio-button>
            </ng-template>
        </div>
    </div>
    <div *ngIf="button" style="text-align: center;">
        <button mat-raised-button style="margin: 15px;" (click)="buttonClick.emit($event)" ngClass.xs="mobile-flat-border">{{buttonText}}</button>
    </div>
</div>
