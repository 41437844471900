import { createSelector } from 'reselect';
import { ActionState, ActionStatus, SortObject } from '../types';
import { getEntitiesObject, getIdsAndEntities } from '../utils';
import * as storageBoxActions from './storage-box.actions';
import { StorageBox } from './storage-box.model';

export interface State {
    ids: number[];
    entities: {
        [storageBoxId: number]: StorageBox;
    };
    loading: ActionStatus;
    saving: ActionStatus;
    sort: SortObject;
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    loading: ActionStatus.Complete,
    saving: ActionStatus.Complete,
    sort: null,
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: storageBoxActions.Actions): State {
    switch (action.type) {
        case storageBoxActions.ADD_COMPLETE: {
            const storageBox: StorageBox = action.payload.storageBox;
            const ids: number[] = [];

            if (!state.entities[storageBox.id]) {
                ids.push(storageBox.id);
            }

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, [storageBox.id]: storageBox },
                saving: ActionStatus.Complete
            };
        }

        case storageBoxActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case storageBoxActions.DELETE_COMPLETE: {
            const storageBoxId = action.payload.storageBoxId;
            const entities = { ...state.entities };
            delete entities[storageBoxId];

            return {
                ...state,
                ids: state.ids.filter(id => id !== storageBoxId),
                entities,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case storageBoxActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case storageBoxActions.LIST_BY_WAREHOUSE_COMPLETE: {
            const storageBoxes = action.payload;
            const { ids, entities } = getIdsAndEntities(state, storageBoxes);

            return {
                ...state,
                ids,
                entities,
                loading: ActionStatus.Complete
            };
        }

        case storageBoxActions.UPDATE_COMPLETE: {
            const alterStateWith = {
                entities: { ...state.entities, ...getEntitiesObject([action.payload.storageBox]) },
            };

            return { ...state, ...alterStateWith, saving: ActionStatus.Complete };
        }

        case storageBoxActions.ADD:
        case storageBoxActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case storageBoxActions.ADD_FAILED:
        case storageBoxActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case storageBoxActions.LIST_BY_WAREHOUSE: {
            return {
                ...state,
                ids: [],
                entities: {},
                loading: ActionStatus.Loading
            };
        }

        case storageBoxActions.LIST_BY_WAREHOUSE_FAILED: {
            return { ...state, loading: ActionStatus.Failed };
        }

        case storageBoxActions.SORT: {
            const sort = { field: action.payload.field, order: action.payload.order, args: (action.payload.args ? action.payload.args : []) };
            return {
                ...state,
                sort
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getLoading = (state: State) => state.loading;
export const getSort = (state: State) => state.sort;
export const getSaving = (state: State) => state.saving;
export const getDeleteState = (state: State) => state.deleteState;
