import { AssetHistory } from '../asset-history/asset-history.model';
import { Attachment } from '../attachments/attachment.model';
import { AssetEvent, RequestAsset, Transaction } from '../models';
import { Photo } from '../photos/photo.model';
import { BaseJsonModel } from './../base/base-json.model';
export * from '../../../../../shared/enums/asset-types';

export const ASSET_ATTACHMENT_TYPE = [
    'photos',
    'attachments'
];

export class Asset extends BaseJsonModel {
    id: number;
    studio_id: number = null;
    franchise_id: number = null;
    project_id: number = null;
    department_id: number = null;
    disposition_id: number = null;
    division_id: number = null;
    location_id: number = null;
    warehouse_location_id: number = null;
    warehouse_id: number = null;
    trashed: boolean;
    origin_currency: number = null;
    status_id: number = null;
    sub_department_id: number = null;
    sub_location_id: number = null;
    warehouse_sub_location_id: number = null;
    shelf = '';
    storage_box_id: number = null;
    set_id?: number;
    character_id: number = null;
    is_split = false;
    name = '';
    cost: string = null;
    transactions?: Transaction[];
    history?;
    attachments?: Attachment[] = [];
    photos?: Photo[] = [];
    barcode: string = null;
    for_review = false;
    allCosts = {};
    displayFields;
    group_ids?: number[] = [];
    sub_group_ids?: number[] = [];
    requestAsset?: RequestAsset = null;
    assetEvents?: AssetEvent[] = null;
    created_at: Date = null;
    updated_at: Date = null;
    import_payload?: any = null;
    /**
     * Used for creating an AssetEvent along with an Asset. Only set in event-view.component
     */
    event_id: number;

    constructor(assetObj: any = {}) {
        super();
        this.initializeData.call(this, assetObj);

        if (assetObj && assetObj.photos) {
            this.photos = assetObj.photos.map(photo => new Photo(photo));
        }

        if (assetObj && assetObj.history) {
            this.history = assetObj.history.map(event => new AssetHistory(event));
        }

        if (assetObj && assetObj.attachments) {
            this.attachments = assetObj.attachments.map(attachment => {
                const newAttachment: Attachment = new Attachment(attachment);
                newAttachment.owner_id = this.id;
                return newAttachment;
            });
        }
    }
}
