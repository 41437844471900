import { Asset } from '../assets/asset.model';
import { Project } from '../projects/project.model';
import { Warehouse } from '../warehouses/warehouse.model';
import { BaseJsonModel } from '../base/base-json.model';

export class AssetImports extends BaseJsonModel {
    id: number;
    franchise_id: number = null;
    division_id: number = null;
    project_id: number = null;
    warehouse_id: number = null;
    project: Project = null;
    warehouse: Warehouse = null;
    origin_currency: number = null;
    import_date: Date = null;
    deleted: boolean = false;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;
    original_filename: string = null;
    unique_filename: string = null;
    assets: Asset[];
    link: string;

    constructor(assetImportsObject) {
        super();
        this.initializeData.call(this, assetImportsObject);
    }

    getObject() {
        return {
            franchise_id: this.franchise_id,
            division_id: this.division_id,
            project_id: this.project_id,
            warehouse_id: this.warehouse_id,
            project: this.project,
            warehouse: this.warehouse,
            import_date: this.import_date,
            deleted: this.deleted,
            created_by: this.created_by,
            updated_by: this.updated_by,
            created_at: this.created_at,
            updated_at: this.updated_at,
            original_filename: this.original_filename,
            unique_filename: this.unique_filename,
            assets: this.assets,
            link: this.link,
            origin_currency: this.origin_currency
        };
    }
}
