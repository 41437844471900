import { createSelector } from 'reselect';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as setActions from './../sets/set.actions';
import * as projectSetActions from './project-set.actions';
import { ProjectSet } from './project-set.model';

export interface State {
    ids: number[];
    entities: {
        [projectSetId: number]: ProjectSet
    };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: projectSetActions.Actions | setActions.Actions): State {
    switch (action.type) {

        case setActions.LIST_COMPLETE: {
            const sets = action.payload;
            const projectSets = sets.reduce((pcs, set) => [...pcs, ...set.projectSets], []);

            const { ids, entities } = getIdsAndEntities(state, projectSets);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'code' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case setActions.ADD_COMPLETE: {
            const projectSets = action.payload.projectSets;
            const { ids, entities } = getIdsAndEntities(state, projectSets);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'code' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case projectSetActions.UPDATE_COMPLETE:
        case projectSetActions.ADD_COMPLETE: {
            const projectSet = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [projectSet]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'code' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case projectSetActions.DELETE_COMPLETE: {
            const projectSetId = action.payload;
            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[projectSetId];
            return {
                ...state,
                ids: state.ids.filter(id => id !== projectSetId),
                entities: entitiesCopy
            };
        }

        case setActions.DELETE_COMPLETE: {
            const setId = action.payload.id;
            const idsToDelete = [];
            const entitiesCopy = { ...state.entities };
            Object.keys(state.entities).forEach(key => {
                const projSet = state.entities[key];
                if (projSet.set_id === setId) {
                    idsToDelete.push(projSet.id);
                    delete entitiesCopy[key];
                }
            });
            return {
                ...state,
                ids: state.ids.filter(id => idsToDelete.indexOf(id) === -1),
                entities: entitiesCopy
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
