import { BaseModel } from './../base/base.model';
import { ProjectCharacter } from './../project-characters/project-character.model';

export class Character extends BaseModel {
    static modelRelatedIdName = 'character_id';
    static relatedFieldString = 'projectCharacters';

    id: number;
    name: string = null;
    franchise_id: number = null;
    projectCharacters?: ProjectCharacter[];

    constructor(obj: any = {}) {
        super();
        this.initializeData(obj, this);
        if (obj.projectCharacters) {
            this.projectCharacters = obj.projectCharacters.map(pc => new ProjectCharacter(pc));
        }
    }

    getNameWithCode() {
        if (this.projectCharacters[0] && this.projectCharacters[0].code) {
            return `${this.projectCharacters[0].code} ${this.name}`;
        } else {
            return this.name;
        }
    }
}
