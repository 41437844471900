import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as characterActions from './character.actions';
import { Character } from './character.model';

@Injectable()
export class CharacterEffects {
    static BASE_URL = '/api/characters';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(characterActions.ADD),
            map((action: characterActions.AddAction) => action.payload),
            switchMap((data) => this.http.post(CharacterEffects.BASE_URL, data).pipe(
                map(res => new Character(res)),
                map((character: Character) => new characterActions.AddCompleteAction(character)),
                catchError((error) => observableOf(new characterActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(characterActions.DELETE),
            map((action: characterActions.DeleteAction) => action.payload),
            switchMap((charData) => this.http.delete(`/api/characters/${charData.id}`).pipe(
                map(() => new characterActions.DeleteCompleteAction(charData)),
                catchError((error) => observableOf(new characterActions.DeleteFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(characterActions.UPDATE),
            map((action: characterActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`${CharacterEffects.BASE_URL}/${data.id}`, data).pipe(
                map(res => new Character(res)),
                map((character: Character) => new characterActions.UpdateCompleteAction(character)),
                catchError((error) => observableOf(new characterActions.UpdateFailedAction({ error }))))
            )));

     listByProject$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(characterActions.LIST_BY_PROJECT),
            map((action: characterActions.ListByProjectAction) => action.payload),
            switchMap(({ projectId, divisionId }) => this.http.get<Character[]>(`/api/divisions/${divisionId}/characters/${projectId}`).pipe(
                map(res => res.map((f) => new Character(f))),
                map((characters: Character[]) => new characterActions.ListByFranchiseCompleteAction(characters)),
                catchError((error) => observableOf(new characterActions.ListByFranchiseFailedAction({ error }))))
            )));

     listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(characterActions.LIST_BY_FRANCHISE),
            map((action: characterActions.ListByFranchiseAction) => action.payload),
            switchMap((franchiseId) => this.http.get<Character[]>(`/api/franchises/${franchiseId}/characters`).pipe(
                map(res => res.map((f) => new Character(f))),
                map((characters: Character[]) => new characterActions.ListByFranchiseCompleteAction(characters)),
                catchError((error) => observableOf(new characterActions.ListByFranchiseFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(characterActions.ADD_FAILED, characterActions.UPDATE_FAILED, characterActions.LIST_BY_FRANCHISE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
