import { Action } from '@ngrx/store';
import { RequestTypes } from '../../../../../shared/enums/request-types.enum';
import { Asset, FinalApproverRequest, RequestAsset, RequestNote } from '../models';
import { Request } from './request.model';

export const ADD = '[Request] Add';
export const ADD_COMPLETE = '[Request] Add Complete';
export const ADD_FAILED = '[Request] Add Failed';

export const ALERT_APPROVER = '[Request] Alert Approver';
export const ALERT_APPROVER_COMPLETE = '[Request] Alert Approver Complete';
export const ALERT_APPROVER_FAILED = '[Request] Alert Approver Failed';

export const CLOSE_REQUESTS = '[Request] Close Request';
export const CLOSE_REQUESTS_COMPLETE = '[Request] Close Request Complete';
export const CLOSE_REQUESTS_FAILED = '[Request] Close Request Failed';

export const COMPLETE_REQUEST = '[Request] Complete Request';
export const COMPLETE_REQUEST_COMPLETE = '[Request] Complete Request Complete';
export const COMPLETE_REQUEST_FAILED = '[Request] Complete Request Failed';

export const DELETE = '[Request] Delete';
export const DELETE_COMPLETE = '[Request] Delete Complete';
export const DELETE_FAILED = '[Request] Delete Failed';

export const FILTER = '[Request] Filter';

export const GET = '[Request] Get';
export const GET_COMPLETE = '[Request] Get Complete';
export const GET_FAILED = '[Request] Get Failed';

export const LIST_APPROVAL_REQUESTS = '[Request] List Approval Requests';
export const LIST_APPROVAL_REQUESTS_COMPLETE = '[Request] List Approval Requests Complete';
export const LIST_APPROVAL_REQUESTS_FAILED = '[Request] List Approval Requests Failed';

export const LIST_FINAL_APPROVER_REQUESTS = '[Request] List Final Approver Requests';
export const LIST_FINAL_APPROVER_REQUESTS_COMPLETE = '[Request] List Final Approver Requests Complete';
export const LIST_FINAL_APPROVER_REQUESTS_FAILED = '[Request] List Final Approver Requests Failed';

export const LIST_UNSENT_REQUESTS = '[Request] List Unsent Requests';
export const LIST_UNSENT_REQUESTS_COMPLETE = '[Request] List Unsent Requests Complete';
export const LIST_UNSENT_REQUESTS_FAILED = '[Request] List Unsent Requests Failed';

export const UPDATE = '[Request] Update';
export const UPDATE_COMPLETE = '[Request] Update Complete';
export const UPDATE_FAILED = '[Request] Update Failed';

export const SEND_REQUEST = '[Request] Send Request';
export const SEND_REQUEST_COMPLETE = '[Request] Send Request Complete';
export const SEND_REQUEST_FAILED = '[Request] Send Request Failed';

export const SELECT = '[Request] Select Request';

export const SORT = '[Request] Sort Requests';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { request: Request, note: string, assetIds: number[], send: boolean }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: { request: Request, requestNote: RequestNote, assetIds: number[] }) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: any) { }
}

export class AlertApproverAction implements Action {
    readonly type = ALERT_APPROVER;

    constructor(public payload: { request_id: number }) { }
}

export class AlertApproverCompleteAction implements Action {
    readonly type = ALERT_APPROVER_COMPLETE;

    constructor() { }
}

export class AlertApproverFailedAction implements Action {
    readonly type = ALERT_APPROVER_FAILED;

    constructor(public payload: any) { }
}

export class CloseRequestsAction implements Action {
    readonly type = CLOSE_REQUESTS;

    constructor(public payload: { studio_id: number, requestIds: number[] }) { }
}

export class CloseRequestsCompleteAction implements Action {
    readonly type = CLOSE_REQUESTS_COMPLETE;

    constructor(public payload: { requests: Request[] }) { }
}

export class CloseRequestsFailedAction implements Action {
    readonly type = CLOSE_REQUESTS_FAILED;

    constructor(public payload: any) { }
}

export class CompleteRequestAction implements Action {
    readonly type = COMPLETE_REQUEST;

    constructor(public payload: { studio_id: number, request: Request, requestNote: RequestNote }) { }
}

export class CompleteRequestCompleteAction implements Action {
    readonly type = COMPLETE_REQUEST_COMPLETE;

    constructor(public payload: { request: Request, requestNote: RequestNote }) { }
}

export class CompleteRequestFailedAction implements Action {
    readonly type = COMPLETE_REQUEST_FAILED;

    constructor(public payload: any) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: { studio_id: number, request_id: number }) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: number) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: { studio_id: number, request_id: number, requestType: RequestTypes }) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: { request: Request, requestNotes: RequestNote[], assets: Asset[], requestAssets: RequestAsset[], finalApproverRequest: FinalApproverRequest }) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: any) { }
}

/** Gets all regular (not final approver) requests for approvers and non-approvers for the request-index */
export class ListApprovalRequestsAction implements Action {
    readonly type = LIST_APPROVAL_REQUESTS;

    constructor(public payload: number) { }
}

export class ListApprovalRequestsCompleteAction implements Action {
    readonly type = LIST_APPROVAL_REQUESTS_COMPLETE;

    constructor(public payload: { requests: Request[], requestNotes: RequestNote[] }) { }
}

export class ListApprovalRequestsFailedAction implements Action {
    readonly type = LIST_APPROVAL_REQUESTS_FAILED;

    constructor(public payload: any) { }
}

/** Gets all final approver requests for the request-index */
export class ListFinalApproverRequestsAction implements Action {
    readonly type = LIST_FINAL_APPROVER_REQUESTS;

    constructor(public payload: number) { }
}

export class ListFinalApproverRequestsCompleteAction implements Action {
    readonly type = LIST_FINAL_APPROVER_REQUESTS_COMPLETE;

    constructor(public payload: { finalApproverRequests: FinalApproverRequest[], requests: Request[], requestNotes: RequestNote[] }) { }
}

export class ListFinalApproverRequestsFailedAction implements Action {
    readonly type = LIST_FINAL_APPROVER_REQUESTS_FAILED;

    constructor(public payload: any) { }
}

export class ListUnsentRequestsAction implements Action {
    readonly type = LIST_UNSENT_REQUESTS;

    constructor(public payload: number) { }
}

export class ListUnsentRequestsCompleteAction implements Action {
    readonly type = LIST_UNSENT_REQUESTS_COMPLETE;

    constructor(public payload: { requests: Request[], requestNotes: RequestNote[] }) { }
}

export class ListUnsentRequestsFailedAction implements Action {
    readonly type = LIST_UNSENT_REQUESTS_FAILED;

    constructor(public payload: any) { }
}

/** Updates the request status and note */
export class SendRequestAction implements Action {
    readonly type = SEND_REQUEST;

    constructor(public payload: { studio_id: number, request_id: number }) { }
}

export class SendRequestCompleteAction implements Action {
    readonly type = SEND_REQUEST_COMPLETE;

    constructor(public payload: { request: Request }) { }
}

export class SendRequestFailedAction implements Action {
    readonly type = SEND_REQUEST_FAILED;

    constructor(public payload: any) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { request: Request, requestNote: RequestNote, assetIds: number[] }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { request: Request, requestNote: RequestNote, assetIds: number[] }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export class SelectRequestAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class SortAction implements Action {
    readonly type = SORT;

    constructor(public payload: {
        field?: string,
        order?: number,
        args?: any[],
        filteredRequestIds?: number[]
    }) { }
}

export class FilterAction implements Action {
    readonly type = FILTER;

    constructor(public payload: { requestIndexType: RequestTypes, value: string | number[], canonical_name: string }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | AlertApproverAction
    | AlertApproverCompleteAction
    | AlertApproverFailedAction
    | CloseRequestsAction
    | CloseRequestsCompleteAction
    | CloseRequestsFailedAction
    | CompleteRequestAction
    | CompleteRequestCompleteAction
    | CompleteRequestFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | FilterAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListApprovalRequestsAction
    | ListApprovalRequestsCompleteAction
    | ListApprovalRequestsFailedAction
    | ListFinalApproverRequestsAction
    | ListFinalApproverRequestsCompleteAction
    | ListFinalApproverRequestsFailedAction
    | ListUnsentRequestsAction
    | ListUnsentRequestsCompleteAction
    | ListUnsentRequestsFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SendRequestAction
    | SendRequestCompleteAction
    | SendRequestFailedAction
    | SelectRequestAction
    | SortAction;
