import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
    constructor() {
    }

    public static naturalSort = (data = [], key) => {
        if (!data.length) return [];
        data = data.slice().sort((a, b) => {
            return a[key].toString().localeCompare(b[key], 'en', { numeric: true });
        });
        return data
    }
}
