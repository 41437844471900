import { FieldVisibility } from '../enums/field-visibility.enum';
import { FAKE_EXCLUDED_FIELDS } from './fake-fields';
import { FIELD_TYPES } from './field-types';

export const DUPLICATE_MODAL_FIELDS = new Set(['name', 'quantity', 'cost']);

export interface StandardField {
    /** What displays to the user */
    name: string;
    /** What the data is stored under in the DB. Should never be changed */
    canonical_name: string;
    type: string;
    /** This field is added to all depts when a studio is created */
    default: boolean;
    /** If true, Field is created with required = true on all its DepartmentFields */
    initially_required: boolean;
    /** If true, user cannot change required state */
    required_locked: boolean;
    /** If true, user cannot remove field from any departments */
    delete_locked: boolean;
    restricted?: boolean;
    sort_order: number;
    visibility: FieldVisibility;
}

export const STANDARD_FIELDS: { [field: string]: StandardField } = {
    name: {
        name: 'Asset Name',
        canonical_name: 'name',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: true,
        required_locked: true,
        delete_locked: true,
        sort_order: 10,
        visibility: FieldVisibility.All
    },
    description: {
        name: 'Description',
        canonical_name: 'description',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 20,
        visibility: FieldVisibility.All
    },
    department: {
        name: 'Department',
        canonical_name: 'department_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: true,
        required_locked: true,
        delete_locked: true,
        sort_order: 30,
        visibility: FieldVisibility.All
    },
    sub_department: {
        name: 'Sub Department',
        canonical_name: 'sub_department_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 40,
        visibility: FieldVisibility.All
    },
    character: {
        name: 'Character',
        canonical_name: 'character_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: false, // kind of, default in CM/PR depts
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 50,
        visibility: FieldVisibility.All
    },
    set: {
        name: 'Set',
        canonical_name: 'set_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: false, // kind of, default in SET depts
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 60,
        visibility: FieldVisibility.All
    },
    episode: {
        name: 'Episode',
        canonical_name: 'episode',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: false,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 70,
        visibility: FieldVisibility.All
    },
    location: {
        name: 'Storage Location',
        canonical_name: 'location_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 80,
        visibility: FieldVisibility.Franchise
    },
    subLocation: {
        name: 'Sub Location',
        canonical_name: 'sub_location_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 90,
        visibility: FieldVisibility.Franchise
    },
    wrap_box: {
        name: 'Wrap Box',
        canonical_name: 'wrap_box',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 100,
        visibility: FieldVisibility.All
    },
    quantity: {
        name: 'Quantity',
        canonical_name: 'quantity',
        type: FIELD_TYPES.NUMBER.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 120,
        visibility: FieldVisibility.All
    },
    qty_on_hand: {
        name: 'Qty. On Hand',
        canonical_name: 'qty_on_hand',
        type: FIELD_TYPES.NUMBER.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 550,
        visibility: FieldVisibility.Division
    },
    cost: {
        name: 'Cost (USD)',
        canonical_name: 'cost',
        type: FIELD_TYPES.CURRENCY.type,
        default: true,
        initially_required: true,
        required_locked: true,
        delete_locked: true,
        restricted: true,
        sort_order: 130,
        visibility: FieldVisibility.All
    },
    origin_currency: {
        name: 'Origin Currency',
        canonical_name: 'origin_currency',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: true,
        required_locked: true,
        delete_locked: true,
        restricted: true,
        sort_order: 140,
        visibility: FieldVisibility.Franchise
    },
    sale_value: {
        name: 'Sale Value',
        canonical_name: 'sale_value',
        type: FIELD_TYPES.CURRENCY.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        restricted: true,
        sort_order: 150,
        visibility: FieldVisibility.All
    },
    estimated_value: {
        name: 'Estimated Value',
        canonical_name: 'estimated_value',
        type: FIELD_TYPES.CURRENCY.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        restricted: true,
        sort_order: 160,
        visibility: FieldVisibility.All
    },
    vendor: {
        name: 'Vendor',
        canonical_name: 'vendor',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 170,
        visibility: FieldVisibility.All
    },
    payment_method: {
        name: 'Payment Method',
        canonical_name: 'payment_method',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 180,
        visibility: FieldVisibility.All
    },
    payment_number: {
        name: 'Payment Number',
        canonical_name: 'payment_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 190,
        visibility: FieldVisibility.All
    },
    purchase_date: {
        name: 'Eff Date',
        canonical_name: 'purchase_date',
        type: FIELD_TYPES.DATE.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 200,
        visibility: FieldVisibility.Franchise
    },
    invoice_number: {
        name: 'Invoice #',
        canonical_name: 'invoice_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 210,
        visibility: FieldVisibility.Transaction
    },
    po_number: {
        name: 'PO #',
        canonical_name: 'po_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 220,
        visibility: FieldVisibility.Transaction
    },
    source_code: {
        name: 'Source Code',
        canonical_name: 'source_code',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 230,
        visibility: FieldVisibility.Franchise
    },
    transaction_number: {
        name: 'Trans #',
        canonical_name: 'transaction_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 240,
        visibility: FieldVisibility.Transaction
    },
    created_at: {
        name: 'Created At',
        canonical_name: 'created_at',
        type: FIELD_TYPES.DATE.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 250,
        visibility: FieldVisibility.All
    },
    updated_at: {
        name: 'Last Modified',
        canonical_name: 'updated_at',
        type: FIELD_TYPES.DATE.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 260,
        visibility: FieldVisibility.All
    },
    brand: {
        name: 'Brand',
        canonical_name: 'brand',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 270,
        visibility: FieldVisibility.All
    },
    color: {
        name: 'Color',
        canonical_name: 'color',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 280,
        visibility: FieldVisibility.All
    },
    gender: {
        name: 'Gender',
        canonical_name: 'gender',
        type: FIELD_TYPES.PICKLIST.type,
        default: false, // kind of, default in CM
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 290,
        visibility: FieldVisibility.All
    },
    hero: {
        name: 'Hero',
        canonical_name: 'hero',
        type: FIELD_TYPES.CHECKBOX.type,
        default: false, // kind of, default in CM/PR
        initially_required: false,
        required_locked: true,
        delete_locked: false,
        sort_order: 300,
        visibility: FieldVisibility.All
    },
    style: {
        name: 'Style',
        canonical_name: 'style',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: false, // kind of, default in CM
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 310,
        visibility: FieldVisibility.All
    },
    material: {
        name: 'Material',
        canonical_name: 'material',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: false, // kind of, default in CM
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 320,
        visibility: FieldVisibility.All
    },
    model: {
        name: 'Model',
        canonical_name: 'model',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: false,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 330,
        visibility: FieldVisibility.All
    },
    vin: {
        name: 'Vin',
        canonical_name: 'vin',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: false,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 340,
        visibility: FieldVisibility.All
    },
    year: {
        name: 'Year',
        canonical_name: 'year',
        type: FIELD_TYPES.NUMBER.type,
        default: false,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 350,
        visibility: FieldVisibility.All
    },
    serial_number: {
        name: 'Serial Number',
        canonical_name: 'serial_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: false,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 360,
        visibility: FieldVisibility.All
    },
    disposition: {
        name: 'Disposition',
        canonical_name: 'disposition_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: true,
        required_locked: true,
        delete_locked: true,
        sort_order: 370,
        visibility: FieldVisibility.Franchise
    },
    disposition_note: {
        name: 'Disposition Note',
        canonical_name: 'disposition_note',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 380,
        visibility: FieldVisibility.Franchise
    },
    status: {
        name: 'Status',
        canonical_name: 'status_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 390,
        visibility: FieldVisibility.Division
    },
    status_note: {
        name: 'Status Note',
        canonical_name: 'status_note',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 400,
        visibility: FieldVisibility.Division
    },
    franchise: {
        name: 'Feature',
        canonical_name: 'franchise_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 410,
        visibility: FieldVisibility.All
    },
    project: {
        name: 'Project',
        canonical_name: 'project_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: true,
        required_locked: true,
        delete_locked: true,
        sort_order: 420,
        visibility: FieldVisibility.All
    },
    division: {
        name: 'Division',
        canonical_name: 'division_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 430,
        visibility: FieldVisibility.All
    },
    warehouse: {
        name: 'Warehouse',
        canonical_name: 'warehouse_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 440,
        visibility: FieldVisibility.Division
    },
    warehouseLocation: {
        name: 'Warehouse Location',
        canonical_name: 'warehouse_location_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 450,
        visibility: FieldVisibility.Division
    },
    warehouseSubLocation: {
        name: 'Warehouse Sub Location',
        canonical_name: 'warehouse_sub_location_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 460,
        visibility: FieldVisibility.Division
    },
    shelf: {
        name: 'Shelf',
        canonical_name: 'shelf',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 470,
        visibility: FieldVisibility.Division
    },
    storageBox: {
        name: 'Storage Box',
        canonical_name: 'storage_box_id',
        type: FIELD_TYPES.PICKLIST.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 480,
        visibility: FieldVisibility.Division
    },
    photos: {
        name: 'Photos',
        canonical_name: 'photos',
        type: FIELD_TYPES.IMAGE.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 490,
        visibility: FieldVisibility.All
    },
    group_ids: {
        name: 'Groups',
        canonical_name: 'group_ids',
        type: 'MULTI_PICKLIST',
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 500,
        visibility: FieldVisibility.All
    },
    sync_id: {
        name: 'SyncID',
        canonical_name: 'sync_id',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 510,
        visibility: FieldVisibility.All
    },
    asset_number: {
        name: 'Asset Number',
        canonical_name: 'asset_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 520,
        visibility: FieldVisibility.All
    },
    je_number: {
        name: 'JE #',
        canonical_name: 'je_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 530,
        visibility: FieldVisibility.Transaction
    },
    reference_number: {
        name: 'Ref #',
        canonical_name: 'reference_number',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 540,
        visibility: FieldVisibility.Transaction
    },
    sub_code: {
        name: 'Sub Code',
        canonical_name: 'sub_code',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 550,
        visibility: FieldVisibility.All
    },
    pallet: {
        name: 'Pallet',
        canonical_name: 'pallet',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 560,
        visibility: FieldVisibility.All
    },
    size: {
        name: 'Size',
        canonical_name: 'size',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 570,
        visibility: FieldVisibility.All
    },
    barcode: {
        name: 'Barcode',
        canonical_name: 'barcode',
        type: FIELD_TYPES.BARCODE.type,
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 580,
        visibility: FieldVisibility.All
    },
    sub_group_ids: {
        name: 'Groups',
        canonical_name: 'sub_group_ids',
        type: 'MULTI_PICKLIST',
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 590,
        visibility: FieldVisibility.All
    },
    created_by_user: {
        name: 'Created By',
        canonical_name: 'created_by_user',
        type: FIELD_TYPES.SHORT_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 600,
        visibility: FieldVisibility.All
    },
    assetEvents: {
        name: 'Events',
        canonical_name: 'assetEvents',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 610,
        visibility: FieldVisibility.Division
    },
    free_field_1: {
        name: 'Asset Imports - Free Field 1',
        canonical_name: 'free_field_1',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 620,
        visibility: FieldVisibility.All
    },
    free_field_2: {
        name: 'Asset Imports - Free Field 2',
        canonical_name: 'free_field_2',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 630,
        visibility: FieldVisibility.All
    },
    free_field_3: {
        name: 'Asset Imports - Free Field 3',
        canonical_name: 'free_field_3',
        type: FIELD_TYPES.LONG_TEXT_AREA.type,
        default: true,
        initially_required: false,
        required_locked: true,
        delete_locked: true,
        sort_order: 640,
        visibility: FieldVisibility.All
    }

};

export const DEFAULT_SELECTED_FIELDS_FOR_FRANCHISE_INDEX = [
    STANDARD_FIELDS.photos.canonical_name,
    STANDARD_FIELDS.name.canonical_name,
    STANDARD_FIELDS.description.canonical_name,
    STANDARD_FIELDS.department.canonical_name,
    STANDARD_FIELDS.cost.canonical_name,
    STANDARD_FIELDS.disposition.canonical_name,
    STANDARD_FIELDS.location.canonical_name,
    STANDARD_FIELDS.updated_at.canonical_name,
    STANDARD_FIELDS.wrap_box.canonical_name
];

export const DEFAULT_SELECTED_FIELDS_FOR_DIVISION_INDEX = [
    STANDARD_FIELDS.photos.canonical_name,
    STANDARD_FIELDS.name.canonical_name,
    STANDARD_FIELDS.description.canonical_name,
    STANDARD_FIELDS.status.canonical_name,
    STANDARD_FIELDS.franchise.canonical_name,
    STANDARD_FIELDS.project.canonical_name,
    STANDARD_FIELDS.updated_at.canonical_name,
    STANDARD_FIELDS.wrap_box.canonical_name
];

export const STATIC_DEFAULT_SELECTED_FIELDS_FOR_DIVISION_INDEX = [
    STANDARD_FIELDS.photos.canonical_name,
    STANDARD_FIELDS.name.canonical_name,
    STANDARD_FIELDS.description.canonical_name,
    STANDARD_FIELDS.status.canonical_name,
    STANDARD_FIELDS.franchise.canonical_name,
    STANDARD_FIELDS.project.canonical_name,
    STANDARD_FIELDS.updated_at.canonical_name,
    STANDARD_FIELDS.qty_on_hand.canonical_name,
    STANDARD_FIELDS.wrap_box.canonical_name
];

export const DEFAULT_SELECTED_FIELDS_FOR_REQUEST_VIEW = [
    STANDARD_FIELDS.photos.canonical_name,
    STANDARD_FIELDS.name.canonical_name,
    STANDARD_FIELDS.description.canonical_name,
    STANDARD_FIELDS.franchise.canonical_name,
    STANDARD_FIELDS.updated_at.canonical_name
];

export const DEFAULT_SELECTED_FIELDS_FOR_STUDIO_INDEX = [
    STANDARD_FIELDS.photos.canonical_name,
    STANDARD_FIELDS.name.canonical_name,
    STANDARD_FIELDS.description.canonical_name,
    STANDARD_FIELDS.quantity.canonical_name,
    STANDARD_FIELDS.franchise.canonical_name,
    STANDARD_FIELDS.division.canonical_name,
    STANDARD_FIELDS.sync_id.canonical_name,
    STANDARD_FIELDS.created_at.canonical_name,
    STANDARD_FIELDS.updated_at.canonical_name
];

export const DEFAULT_SELECTED_FIELDS_FOR_TRANSACTION_TABLE = [
    STANDARD_FIELDS.name.canonical_name,
    STANDARD_FIELDS.department.canonical_name,
    STANDARD_FIELDS.cost.canonical_name,
    STANDARD_FIELDS.purchase_date.canonical_name,
    STANDARD_FIELDS.vendor.canonical_name,
    STANDARD_FIELDS.po_number.canonical_name,
    STANDARD_FIELDS.invoice_number.canonical_name,
    STANDARD_FIELDS.character.canonical_name,
    STANDARD_FIELDS.set.canonical_name,
    STANDARD_FIELDS.updated_at.canonical_name,
    FAKE_EXCLUDED_FIELDS.excluded_disposition.canonical_name
];

export const DEFAULT_PICKLIST_FIELDS = [
    STANDARD_FIELDS.character.canonical_name,
    STANDARD_FIELDS.set.canonical_name,
    STANDARD_FIELDS.department.canonical_name,
    STANDARD_FIELDS.sub_department.canonical_name,
    STANDARD_FIELDS.franchise.canonical_name,
    STANDARD_FIELDS.project.canonical_name,
    STANDARD_FIELDS.division.canonical_name,
    STANDARD_FIELDS.location.canonical_name,
    STANDARD_FIELDS.subLocation.canonical_name,
    STANDARD_FIELDS.disposition.canonical_name,
    STANDARD_FIELDS.warehouse.canonical_name,
    STANDARD_FIELDS.warehouseLocation.canonical_name,
    STANDARD_FIELDS.warehouseSubLocation.canonical_name,
    STANDARD_FIELDS.storageBox.canonical_name,
    STANDARD_FIELDS.origin_currency.canonical_name,
    STANDARD_FIELDS.status.canonical_name
]

export const DEFAULT_FREE_FIELDS = [
    STANDARD_FIELDS.free_field_1.canonical_name,
    STANDARD_FIELDS.free_field_2.canonical_name,
    STANDARD_FIELDS.free_field_3.canonical_name
]

export const DEFAULT_FREE_FIELDS_NAME = [
    STANDARD_FIELDS.free_field_1.name,
    STANDARD_FIELDS.free_field_2.name,
    STANDARD_FIELDS.free_field_3.name
]
