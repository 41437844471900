import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserDataService, UserInfo } from '../../data-services/user-data.service';
import { Observable, Subscription } from 'rxjs';
import { redirectToURL } from '../../services/user.service';

@Component({
    selector: 'ah-activate-user',
    templateUrl: './activate-user.component.html',
    styleUrls: ['./activate-user.component.scss']
})

export class ActivateUserComponent implements OnInit, OnDestroy {
    userInfo$: Observable<UserInfo>;
    private subs: Subscription;

    constructor(private route: ActivatedRoute, private userDataService: UserDataService) { }

    ngOnInit(): void {
        this.subs = new Subscription();

        const code = this.route.snapshot.queryParams['code'];
        this.userInfo$ = this.userDataService.getUserByToken(code);
    }

    proceed(event: any) {
        const pathSub = this.userDataService.activateUser(event.userInfo);

        this.subs.add(pathSub.subscribe((value: any) => {
            redirectToURL(value.path);
        }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}