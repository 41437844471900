import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserInfo } from '../../data-services/user-data.service';

@Component({
    selector: 'ah-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnChanges {
    @Input() userInfo: UserInfo;
    @Input() readonly?: boolean = false;
    @Output() onSubmitDetails = new EventEmitter();

    userForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {
        this.userForm = this.fb.group({
            first_name: '',
            last_name: '',
            phone: ['', [Validators.pattern("^[0-9+()\+\-]+$")]],
            email: ''
        });
    }

    ngOnChanges(): void {
        if (this.userInfo) {
            this.userForm.controls["first_name"].setValue(this.userInfo.firstName);
            this.userForm.controls["last_name"].setValue(this.userInfo.lastName);
            this.userForm.controls["phone"].setValue(this.userInfo.phone);
            this.userForm.controls["email"].setValue(this.userInfo.email);
        }
    }

    onSubmit(event) {
        event.stopPropagation();
        this.userInfo.firstName = this.userForm.controls["first_name"].value;
        this.userInfo.lastName = this.userForm.controls["last_name"].value;
        this.userInfo.phone = this.userForm.controls["phone"].value;

        let data = { userInfo: this.userInfo };
        this.onSubmitDetails.emit(data);
    }
}
