import { Action } from '@ngrx/store';
import { SosProjectDepartment } from './sos-project-department.model';

export const CREATE = '[SosLink] Create';
export const CREATE_COMPLETE = '[SosLink] Create Complete';
export const CREATE_FAILED = '[SosLink] Create Failed';

export const LIST = '[SosLink] List';
export const LIST_COMPLETE = '[SosLink] List Complete';
export const LIST_FAILED = '[SosLink] List Failed';

export const DELETE = '[SosLink] Delete';
export const DELETE_COMPLETE = '[SosLink] Delete Complete';
export const DELETE_FAILED = '[SosLink] Delete Failed';

export const DISPLAY_COMPLETE = '[SosLink] Display Complete';

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;
    constructor(public payload: SosProjectDepartment[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any }) { }
}

export class CreateAction implements Action {
    readonly type = CREATE;

    constructor(public payload: any) { }
}

export class CreateCompleteAction implements Action {
    readonly type = CREATE_COMPLETE;

    constructor(public payload: any) { }
}

export class DisplayWarningInfoCompleteAction implements Action {
    readonly type = DISPLAY_COMPLETE;

    constructor(public payload: any) { }
}

export class CreateFailedAction implements Action {
    readonly type = CREATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: number) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: number[]) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}
export type Actions
    = CreateAction
    | CreateCompleteAction
    | CreateFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | DisplayWarningInfoCompleteAction
