import * as divisionGroupActions from '../division-groups/division-group.actions';
import * as franchiseGroupActions from '../franchise-groups/franchise-group.actions';
import { ActionStatus } from '../types';
import * as assetActions from './asset.actions';

export interface State {
    assetIdsForGroup: {
        [groupId: number]: number[];
    };
    groupTotalCount: {
        [groupId: number]: number;
    };
    loadingGroupAssets: ActionStatus;
}

export const initialState: State = {
    assetIdsForGroup: {},
    groupTotalCount: {},
    loadingGroupAssets: ActionStatus.Complete,
};

export function reducer(state = initialState, action: assetActions.Actions | divisionGroupActions.Actions | franchiseGroupActions.Actions): State {
    switch (action.type) {
        case assetActions.LIST_BY_GROUP: {
            return {
                ...state,
                loadingGroupAssets: ActionStatus.Loading
            };
        }

        case assetActions.LIST_BY_GROUP_COMPLETE: {
            const assets = action.payload.assets;
            const totalCount = action.payload.totalCount;
            const groupId = action.payload.groupId;

            const groupIdObj = {};
            const groupCountObj = {};

            groupCountObj[groupId] = totalCount;
            groupIdObj[groupId] = assets.map(a => a.id);

            return {
                ...state,
                assetIdsForGroup: {
                    ...state.assetIdsForGroup,
                    ...groupIdObj
                },
                groupTotalCount: {
                    ...state.groupTotalCount,
                    ...groupCountObj
                },
                loadingGroupAssets: ActionStatus.Complete
            };
        }

        case divisionGroupActions.ADD_TO_DIVISION_GROUP_COMPLETE:
        case franchiseGroupActions.ADD_TO_FRANCHISE_GROUP_COMPLETE: {
            const assetIds = action.payload.assetIds;
            const groupId = action.payload.groupId;

            const groupIdObj = {};
            if (state.assetIdsForGroup[groupId]) {
                groupIdObj[groupId] = [...state.assetIdsForGroup[groupId], ...assetIds];
            } else {
                groupIdObj[groupId] = [...assetIds];
            }

            return {
                ...state,
                assetIdsForGroup: {
                    ...state.assetIdsForGroup,
                    ...groupIdObj
                }
            };
        }

        case divisionGroupActions.DELETE_FROM_DIVISION_COMPLETE:
        case franchiseGroupActions.DELETE_FROM_FRANCHISE_COMPLETE: {
            const groupId = action.payload.groupId;
            const assetIds = action.payload.assetIds;
            const assetIdsForGroup = { ...state.assetIdsForGroup };
            assetIdsForGroup[groupId] = assetIdsForGroup && assetIdsForGroup[groupId] ? assetIdsForGroup[groupId].filter(assetId => !assetIds.includes(assetId)) : null;

            return {
                ...state,
                assetIdsForGroup
            };
        }

        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const updatedAssets = action.payload.assets;

            const assetIdsForGroup = { ...state.assetIdsForGroup };
            const groupTotalCount = { ...state.groupTotalCount };

            // add updated group info for assets that were updated
            updatedAssets.forEach(a => {
                if (!a.trashed) {
                    a.group_ids.forEach(groupId => {
                        assetIdsForGroup[groupId] = assetIdsForGroup[groupId] ? [...assetIdsForGroup[groupId], a.id] : [a.id];
                        if (groupTotalCount[groupId]) {
                            groupTotalCount[groupId] += 1;
                        } else {
                            groupTotalCount[groupId] = 1;
                        }
                    });
                }
            });

            return {
                ...state,
                assetIdsForGroup,
                groupTotalCount
            };
        }

        default: {
            return state;
        }
    }
}

export const getAssetIdsForGroup = (state: State) => state.assetIdsForGroup;
export const getLoadingGroupAssets = (state: State) => state.loadingGroupAssets;
export const getAssetCountsForGroup = (state: State) => state.groupTotalCount;
