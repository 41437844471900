import { Action } from '@ngrx/store';
import { NAV_TYPES } from '../../../../../shared/enums/nav-types.enum';

export const SWITCH_NAV = '[Layout] Switch Nav';

export const ERROR = '[Layout] Display Error';
export const ERROR_DISPLAYED = '[Layout] Error Displayed';

export const INFO = '[Layout] Display Info';
export const INFO_DISPLAYED = '[Layout] Info Displayed';

export const CLEAR = '[Layout] Clear Info/Error';
export const CLEARED = '[Layout] Info Cleared';

export class SwitchNavAction implements Action {
    readonly type = SWITCH_NAV;

    constructor(public payload: NAV_TYPES) { }
}

export class ErrorAction implements Action {
    readonly type = ERROR;

    constructor(public payload: { error: any }) { }
}

export class ErrorDisplayedAction implements Action {
    readonly type = ERROR_DISPLAYED;

    constructor() { }
}

export class InfoAction implements Action {
    readonly type = INFO;

    constructor(public payload: { message: string }) { }
}

export class InfoDisplayedAction implements Action {
    readonly type = INFO_DISPLAYED;

    constructor() { }
}

export class ClearAction implements Action {
    readonly type = CLEAR;

    constructor() { }
}

export class ClearedAction implements Action {
    readonly type = CLEARED;

    constructor() { }
}

export type Actions
    = SwitchNavAction
    | ErrorAction
    | ErrorDisplayedAction
    | InfoAction
    | InfoDisplayedAction
    | ClearAction
    | ClearedAction;
