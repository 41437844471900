import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { SubLocation } from './sub-location.model';

export const ADD = '[SubLocation] Add';
export const ADD_COMPLETE = '[SubLocation] Add Complete';
export const ADD_FAILED = '[SubLocation] Add Failed';

export const DELETE = '[SubLocation] Delete';
export const DELETE_COMPLETE = '[SubLocation] Delete Complete';
export const DELETE_FAILED = '[SubLocation] Delete Failed';

export const UPDATE = '[SubLocation] Update';
export const UPDATE_COMPLETE = '[SubLocation] Update Complete';
export const UPDATE_FAILED = '[SubLocation] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: SubLocation) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;
    constructor(public payload: SubLocation) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: SubLocation) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: SubLocation) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: SubLocation) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;
    constructor(public payload: SubLocation) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
