import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { LEDGER_TYPES } from '../../../../../shared/enums/ledger-types.enum';
import { Department } from '../departments/department.model';
import { Ledger } from './ledger.model';

export const ADD_FIELDS = '[Ledger] Add Fields';

export const CREATE_TRANSACTIONS = '[Ledger] Create Transactions';
export const CREATE_TRANSACTIONS_COMPLETE = '[Ledger] Create Transactions Complete';
export const CREATE_TRANSACTIONS_FAILED = '[Ledger] Create Transactions Failed';

export const DELETE = '[Ledger] Delete';
export const DELETE_COMPLETE = '[Ledger] Delete Complete';
export const DELETE_FAILED = '[Ledger] Delete Failed';

export const GET_FOR_TRANSACTION = '[Ledger] Get for Transaction';
export const GET_FOR_TRANSACTION_COMPLETE = '[Ledger] Get for Transaction Complete';
export const GET_FOR_TRANSACTION_FAILED = '[Ledger] Get for Transaction Failed';

export const LIST_BY_FRANCHISE = '[Ledger] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[Ledger] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Ledger] List By Franchise Failed';

export const MATCH = '[Ledger] Match Field';
export const MATCHES_CLEAR = '[Ledger] Clear Matches';
export const SET_MATCHES = '[Ledger] Set Matches';
export const SET_TYPE = '[Ledger] Set Type';
export const UPDATE_UNMATCHED_DEPTS = '[Ledger] Update Unmatched Departments';

export const SET_SHOW_MATCHING_PAGE = '[Ledger] Set Show Matching Page';

export const CHECK_LEDGER_FOR_PURCHASE_ORDER = '[Ledger] Check Ledger For Purchase Order';
export const CHECK_LEDGER_FOR_PURCHASE_ORDER_COMPLETE = '[Ledger] Check Ledger For Purchase Order Complete';
export const CHECK_LEDGER_FOR_PURCHASE_ORDER_FAILED = '[Ledger] Check Ledger For Purchase Order Failed';

export const RESET_LEDGER_IMPORT = '[Ledger] Reset Ledger Import';

export class AddFieldsAction implements Action {
    readonly type = ADD_FIELDS;

    constructor(public payload: {
        id: number;
        franchise_id: number;
        hasMultipleCurrencies: boolean;
        project_id: number;
        fields: string[];
        numUnmatched: number;
        unmatchedDepartments: string[];
        unmatchedCurrencies: string[];
        valueExamples: { [ledgerHeader: string]: string };
    }) { }
}

export class CreateTransactionsAction implements Action {
    readonly type = CREATE_TRANSACTIONS;

    constructor(public payload: { ledgerState: any, matches: any }) { }
}

export class CreateTransactionsCompleteAction implements Action {
    readonly type = CREATE_TRANSACTIONS_COMPLETE;

    constructor(public payload: any) { }
}

export class CreateTransactionsFailedAction implements Action {
    readonly type = CREATE_TRANSACTIONS_FAILED;

    constructor(public payload: any) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: { ledger: Ledger, hard_deleted: boolean }) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Ledger) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}

export class GetForTransactionAction implements Action {
    readonly type = GET_FOR_TRANSACTION;

    constructor(public payload: number) { }
}

export class GetForTransactionCompleteAction implements Action {
    readonly type = GET_FOR_TRANSACTION_COMPLETE;

    constructor(public payload: Ledger) { }
}

export class GetForTransactionFailedAction implements Action {
    readonly type = GET_FOR_TRANSACTION_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: number) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: Ledger[]) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: any, franchiseId: number }) { }
}

export class MatchAction implements Action {
    readonly type = MATCH;

    constructor(public payload: { [ledgerHeader: string]: number }) { }
}

export class MatchesClearAction implements Action {
    readonly type = MATCHES_CLEAR;
}

export class SetMatchesAction implements Action {
    readonly type = SET_MATCHES;

    constructor(public payload: { [ledgerHeader: string]: number }) { }
}

export class SetTypeAction implements Action {
    readonly type = SET_TYPE;

    constructor(public payload: number) { }
}

export class UpdateUnmatchedDepts implements Action {
    readonly type = UPDATE_UNMATCHED_DEPTS;

    constructor(public payload: Department[]) { }
}

export class SetShowMatchingPageAction implements Action {
    readonly type = SET_SHOW_MATCHING_PAGE;

    constructor(public payload: boolean) { }
}

export class CheckLedgerForPurchaseOrder implements Action {
    readonly type = CHECK_LEDGER_FOR_PURCHASE_ORDER;

    constructor(public payload: { ledgerId: number, type: LEDGER_TYPES, matches: { [ledgerColName: string]: number } }) { }
}

export class CheckLedgerForPurchaseOrderComplete implements Action {
    readonly type = CHECK_LEDGER_FOR_PURCHASE_ORDER_COMPLETE;

    constructor(public payload: { hasPurchaseOrder: boolean, fallbackWarning?: boolean }) { }
}

export class CheckLedgerForPurchaseOrderFailed implements Action {
    readonly type = CHECK_LEDGER_FOR_PURCHASE_ORDER_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ResetLedgerImport implements Action {
    readonly type = RESET_LEDGER_IMPORT;

    constructor() { }
}

export type Actions
    = AddFieldsAction
    | CreateTransactionsAction
    | CreateTransactionsCompleteAction
    | CreateTransactionsFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | GetForTransactionAction
    | GetForTransactionCompleteAction
    | GetForTransactionFailedAction
    | ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | MatchAction
    | MatchesClearAction
    | SetTypeAction
    | UpdateUnmatchedDepts
    | SetMatchesAction
    | SetShowMatchingPageAction
    | CheckLedgerForPurchaseOrder
    | CheckLedgerForPurchaseOrderComplete
    | CheckLedgerForPurchaseOrderFailed
    | ResetLedgerImport;
