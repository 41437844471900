import * as transactionUploadActions from './transaction-upload.actions';

export interface State {
    selectedProjectId: number;
}

export const initialState: State = {
    selectedProjectId: null,
};

export function reducer(state = initialState, action: transactionUploadActions.Actions): State {
    switch (action.type) {

        case transactionUploadActions.SELECT_PROJECT: {
            let newState = state;
            if (action.payload !== state.selectedProjectId) {
                newState = {
                    ...state,
                    selectedProjectId: action.payload
                };
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}

export const getSelectedProjectId = (state: State) => state.selectedProjectId;
