<div class="row my-settings">
    <div class="col-xs-12">
        <h2> Update User Profile</h2>
        <p>Fields with <span class="required">*</span> are required.</p>
        <div class="well">
            <form class="create-form" [formGroup]="userForm" style="width: 100%">
                <div class="flex-50" sytle="order: 1;">
                    <div class="form-group">
                        <label> First Name <span class="required">*</span></label>
                        <br>
                        <input type="text" class="form-control" [ngClass]="{'readonly' : readonly}" formControlName="first_name" [readonly]="readonly" required>
                    </div>
                    <div class="form-group">
                        <label> Last Name <span class="required">*</span></label>
                        <br>
                        <input type="text" class="form-control" [ngClass]="{'readonly' : readonly}" formControlName="last_name" [readonly]="readonly" required>
                    </div>
                    <div class="form-group">
                        <label> Phone Number <span class="required">*</span></label>
                        <br>
                        <input type="text" class="form-control" formControlName="phone" required>
                    </div>
                </div>
                <!-- col 1 -->
                <div class="flex-50" sytle="order: 2;">
                    <div class="form-group">
                        <label> Email <span class="required">*</span></label>
                        <br>
                        <input type="text" class="form-control readonly" formControlName="email" readonly required>
                    </div>
                    <div class="form-group">
                        <span style="font-size: 1.5em;">Change Password</span>
                        <br>
                        <span style="vertical-align: 2px;">To update your EP password please logout and navigate to the sign in page. Click the "Forgot Password?" link that is under the "Sign In" button and follow the instructions.</span>
                    </div>
                    <div class="display-buttons">
                        <button mat-raised-button color="primary" [disabled]="userForm.invalid" (click)="onSubmit($event);"> Submit </button>
                        <button mat-raised-button mat-dialog-close style="margin-left:10px"> Cancel </button>
                    </div>
                </div>
                <!-- col 2 -->
            </form>
        </div>
        <!-- well -->
    </div>
</div>