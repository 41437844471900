<div class="exception-body error-page">
    <div class="exception-type">
        <img src="assets/layout/images/error.svg">
    </div>
    <mat-card appearance="outlined" class="ah-exception-panel">
        <h1>Looks like we're having some server issues.</h1>
        <div class="description">
            <div>Go back to the previous page and try again.
            </div>
            <div class="divider"></div>
            <div>
                If you think something is broken, report a problem.
            </div>
        </div>
        <div>
            <a mat-raised-button routerLink="/" class="import-btn" style="width: 163px; margin-right: 18px;">HOME</a>
            <a mat-raised-button class="import-btn" style="margin-right: 0px;" href="mailto:support@synconset.com?Subject=Asset%20Hub%20Page%20Error"
                target="_top">REPORT A PROBLEM</a>
        </div>
    </mat-card>
</div>
