import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as setActions from './set.actions';
import { Set } from './set.model';

@Injectable()
export class SetEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(setActions.ADD),
            map((action: setActions.AddAction) => action.payload),
            switchMap((data) => this.http.post(`/api/sets`, data).pipe(
                map(res => new Set(res)),
                map((set: Set) => new setActions.AddCompleteAction(set)),
                catchError((error) => observableOf(new setActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(setActions.DELETE),
            map((action: setActions.DeleteAction) => action.payload),
            switchMap((setData) => this.http.delete(`/api/sets/${setData.id}`).pipe(
                map(() => new setActions.DeleteCompleteAction(setData)),
                catchError((error) => observableOf(new setActions.DeleteFailedAction({ error }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(setActions.LIST),
            map((action: setActions.ListAction) => action.payload),
            switchMap(franchiseId => this.http.get<Set[]>(`/api/franchises/${franchiseId}/sets`).pipe(
                map(res => res.map(s => new Set(s))),
                map((sets) => new setActions.ListCompleteAction(sets)),
                catchError(error => observableOf(new setActions.ListFailedAction({ error, franchiseId }))))
            )));

     listProjectSet$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(setActions.LIST_SET),
            map((action: setActions.ListSetAction) => action.payload),
            switchMap(({ projectId, divisionId }) => this.http.get<Set[]>(`/api/divisions/${divisionId}/sets/${projectId}`).pipe(
                map(res => res.map(s => new Set(s))),
                map((sets) => new setActions.ListCompleteAction(sets)),
                catchError(error => observableOf(new setActions.ListSetFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(setActions.UPDATE),
            map((action: setActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`/api/sets/${data.id}`, data).pipe(
                map(res => new Set(res)),
                map((set: Set) => new setActions.UpdateCompleteAction(set)),
                catchError((error) => observableOf(new setActions.UpdateFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(setActions.ADD_FAILED, setActions.LIST_FAILED, setActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
