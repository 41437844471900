import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as attachmentActions from './attachment.actions';
import { Attachment } from './attachment.model';

@Injectable()
export class AttachmentEffects {

    requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.ADD_ATTACHMENT_FAILED, attachmentActions.DELETE_ATTACHMENT_FAILED, attachmentActions.DELETE_GROUP_ATTACHMENT_FAILED, attachmentActions.GET_ATTACHMENT_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    getAttachment$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.GET_ATTACHMENT),
            map((action: attachmentActions.GetAttachmentAction) => action.payload),
            switchMap((assetId) => this.http.get<Attachment[]>(`/api/list-asset-attachments/${assetId}`).pipe(
                map(res => res.map((attachment) => {
                    attachment.owner_id = assetId;
                    return new Attachment(attachment)
                })),
                map((attachments) => new attachmentActions.GetAttachmentCompleteAction({ assetId, attachments })),
                catchError((error) => observableOf(new attachmentActions.GetAttachmentFailedAction({ error })))))
        ));

    deleteAttachment$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.DELETE_ATTACHMENT),
            map((action: attachmentActions.DeleteAttachmentAction) => action.payload),
            switchMap((attachmentData) => this.http.delete(`/api/attachments/${attachmentData.id}`).pipe(
                map(() => new attachmentActions.DeleteAttachmentCompleteAction(attachmentData)),
                catchError((error) => observableOf(new attachmentActions.DeleteAttachmentFailedAction({ error }))))
            )));

    deleteEventAttachment$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.DELETE_EVENT_ATTACHMENT),
            map((action: attachmentActions.DeleteEventAttachmentAction) => action.payload),
            switchMap((attachmentData) => this.http.delete(`/api/event-attachments/${attachmentData.id}`).pipe(
                map(() => new attachmentActions.DeleteEventAttachmentCompleteAction(attachmentData)),
                catchError((error) => observableOf(new attachmentActions.DeleteAttachmentFailedAction({ error }))))
            )));

    deleteGroupAttachment$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.DELETE_GROUP_ATTACHMENT),
            map((action: attachmentActions.DeleteGroupAttachmentAction) => action.payload),
            switchMap((attachmentData) => this.http.delete(`/api/group-attachments/${attachmentData.id}`).pipe(
                map(() => new attachmentActions.DeleteGroupAttachmentCompleteAction(attachmentData)),
                catchError((error) => observableOf(new attachmentActions.DeleteGroupAttachmentFailedAction({ error }))))
            )));

    deleteSubGroupAttachment$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.DELETE_SUB_GROUP_ATTACHMENT),
            map((action: attachmentActions.DeleteSubGroupAttachmentAction) => action.payload),
            switchMap((attachmentData) => this.http.delete(`/api/sub-group-attachments/${attachmentData.id}`).pipe(
                map(() => new attachmentActions.DeleteSubGroupAttachmentCompleteAction(attachmentData)),
                catchError((error) => observableOf(new attachmentActions.DeleteSubGroupAttachmentFailedAction({ error }))))
            )));

    failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(attachmentActions.DELETE_SUB_GROUP_ATTACHMENT_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));


    constructor(private actions$: Actions, private http: HttpClient) { }
}
