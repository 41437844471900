import * as transactionExpandRowActions from './transaction-expando-row.actions';

export interface State {
    selectedLocationId: number;
}

export const initialState: State = {
    selectedLocationId: null,
};

export function reducer(state = initialState, action: transactionExpandRowActions.Actions): State {
    switch (action.type) {

        case transactionExpandRowActions.SELECT_LOCATION: {
            let newState = state;
            if (action.payload !== state.selectedLocationId) {
                newState = {
                    ...state,
                    selectedLocationId: action.payload
                };
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}

export const getSelectedLocationId = (state: State) => state.selectedLocationId;
