export class BaseModel {
    constructor() { }

    initializeData(modelObject, childThis) {
        for (const key in modelObject) {
            if (modelObject.hasOwnProperty(key)) {
                if (childThis.hasOwnProperty(key) || key === 'id') {
                    childThis[key] = modelObject[key];
                }
            }
        }
    }
}
