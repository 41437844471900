import { RequestStatus } from '../../../../../shared/enums/request-status.enum';
import { RequestTypes } from '../../../../../shared/enums/request-types.enum';
import { Moment } from '../../../shared/services/moment.service';
import { getFormattedDate } from '../utils';
import { BaseModel } from './../base/base.model';

export class Request extends BaseModel {
    id: number;
    studio_id: number = null;
    studio_generated_id: number = null;
    name: string = null;
    deadline: Moment | Date = null;
    date_sent: Moment | Date = null;
    status: RequestStatus = null;
    created_by: number = null;
    created_at: Moment | Date = null;
    commonFranchise: string = null;
    creatorName: string = null;
    requestTypeForUser: RequestTypes = null;

    constructor(requestObject) {
        super();
        this.initializeData(requestObject, this);
    }

    getFormattedDate(date) {
        return getFormattedDate(date);
    }

    getIdAndName() {
        return `#${this.studio_generated_id}${this.name ? ` - ${this.name}` : ''}`;
    }

    getStatusDisplayBasedOnView(requestIndexType: RequestTypes) {
        if (requestIndexType === RequestTypes.Requester && (this.status === RequestStatus.Incomplete || this.status === RequestStatus.Submitted || this.status === RequestStatus.Confirmed)) {
            return RequestStatus.Incomplete;
        }
        return this.status;
    }
}
