import { createSelector } from 'reselect';
import * as root from '../';

export const getFranchisesWithProjects = createSelector(root.getActualFranchises, root.getFranchiseProjectIds, root.getProjectEntities, (franchises, franProjIds, projectMap) => {
    return franchises.map(franchise => {
        return Object.assign({}, franchise, { projects: franProjIds[franchise.id] ? franProjIds[franchise.id].map(id => projectMap[id]) : [] });
    });
});

export const getSeriesWithProjects = createSelector(root.getSeries, root.getFranchiseProjectIds, root.getProjectEntities, (series, franProjIds, projectMap) => {
    return series.map(s => {
        return Object.assign({}, s, { projects: franProjIds[s.id] ? franProjIds[s.id].map(id => projectMap[id]) : [] });
    });
});

export const getAllFranchisesWithProjects = createSelector(root.getAllFranchises, root.getFranchiseProjectIds, root.getProjectEntities, (franchises, franProjIds, projectMap) => {
    return franchises.map(franchise => {
        return Object.assign({}, franchise, { projects: franProjIds[franchise.id] ? franProjIds[franchise.id].map(id => projectMap[id]) : [] });
    });
});

export const getOwnedFranchisesWithProjects = createSelector(root.getOwnedFranchises, root.getOwnedFranchiseProjectIds, root.getProjectEntities, (franchises, franProjIds, projectMap) => {
    return franchises.map(franchise => {
        return Object.assign({}, franchise, { projects: franProjIds[franchise.id] ? franProjIds[franchise.id].map(id => projectMap[id]) : [] });
    });
});
