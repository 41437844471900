import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromRoot from '../../core/store';
import { Division, Franchise, Project, Studio, User } from '../../core/store/models';

@Injectable()
export class PendoService {
    currentData: string = null;
    sub: Subscription = null;

    constructor(private store: Store<fromRoot.State>) { }

    get Pendo() {
        return (window as any).pendo;
    }

    launch(user: User) {
        const fullName = user.fullName()
        this.Pendo.initialize({
            visitor: {
                id: `${environment.envName}_${user.email}`,
                email: user.email,
                full_name: fullName,
                date_created: user.created_at,
                current_studio: user.current_studio_id,
                current_franchise: user.current_franchise_id,
                current_division: user.current_division_id,
                studio_admin: user.permission.isStudioAdmin(user.current_studio_id),
                franchise_admin: user.permission.isFranchiseAdmin(user.current_franchise_id, user.current_studio_id),
                user_hash: user.intercom_hash,
                user_id: user.id,
                application: 'AssetHub',
                environment: environment.envName,
            },
            account: {
                franchise_id: `STAH_${user.current_franchise_id}`,
                division_id: `STAH_${user.current_division_id}`,
                studio_id: user.current_studio_id,
            }
        });
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
        this.sub = combineLatest<any>([
            this.store.select(fromRoot.getSelectedStudio),
            this.store.select(fromRoot.getSelectedFranchise),
            this.store.select(fromRoot.getSelectedDivision),
            this.store.select(fromRoot.getUser),
            this.store.select(fromRoot.getSelectedFranchiseProjects)]
        ).pipe(filter(([studio, franchise, division, user, projects]: [Studio, Franchise, Division, User, Project[]]) => {
            return !!studio && !!user
                && ((user.current_franchise_id && franchise && user.current_franchise_id === franchise.id)
                    || (user.current_division_id && division && user.current_division_id === division.id));
        })).subscribe(([studio, franchise, division, user, projects]: [Studio, Franchise, Division, User, Project[]]) => {
            const data = {
                visitor: {
                    id: user.email,
                    email: user.email,
                    full_name: user.fullName,
                    date_created: user.created_at,
                    current_studio: user.current_studio_id,
                    current_franchise: user.current_franchise_id,
                    current_division: user.current_division_id,
                    studio_admin: user.permission.isStudioAdmin(user.current_studio_id),
                    franchise_admin: user.permission.isFranchiseAdmin(user.current_franchise_id, user.current_studio_id),
                    user_hash: user.intercom_hash,
                    user_id: user.id,
                    application: 'AssetHub',
                    environment: environment.envName
                },
                account: {
                    studio_id: user.current_studio_id,
                    studio_name: studio.name
                }
            };
            if (user.current_franchise_id && franchise) {
                data.account['id'] = `STAH_${franchise.id}`;
                data.account['franchise_name'] = franchise.name;
                data.account['current_projects'] = projects ? projects.map(p => p.id).toString() : '';
            } else if (user.current_division_id && division) {
                data.account['id'] = `STAH_D${division.id}`;
                data.account['division_name'] = division.name;
            }

            const newData = JSON.stringify(data);
            if (newData !== this.currentData) {
                this.Pendo.identify(data);
                this.currentData = newData;
            }
        });
    }
}
