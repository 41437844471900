import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as inviteActions from './invite.actions';
import { Invite } from './invite.model';

@Injectable()
export class InviteEffects {

     listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.LIST_BY_FRANCHISE),
            map((action: inviteActions.ListByFranchiseAction) => action.payload),
            switchMap((franchiseId) => this.http.get<Invite[]>(`/api/franchises/${franchiseId}/invites`).pipe(
                map(res => res.map((u) => new Invite(u))),
                map((invites: Invite[]) => new inviteActions.ListByFranchiseCompleteAction(invites)),
                catchError((error) => observableOf(new inviteActions.ListByFranchiseFailedAction({ error, franchiseId }))))
            )));

     listByStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.LIST_BY_STUDIO),
            map((action: inviteActions.ListByStudioAction) => action.payload),
            switchMap((studioId) => this.http.get<Invite[]>(`/api/studios/${studioId}/invites`).pipe(
                map(res => res.map((u) => new Invite(u))),
                map((invites: Invite[]) => new inviteActions.ListByStudioCompleteAction(invites)),
                catchError((error) => observableOf(new inviteActions.ListByStudioFailedAction({ error, studioId }))))
            )));

     listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.LIST_BY_DIVISION),
            map((action: inviteActions.ListByDivisionAction) => action.payload),
            switchMap((divisionId) => this.http.get<Invite[]>(`/api/divisions/${divisionId}/invites`).pipe(
                map(res => res.map((u) => new Invite(u))),
                map((invites: Invite[]) => new inviteActions.ListByDivisionCompleteAction(invites)),
                catchError((error) => observableOf(new inviteActions.ListByDivisionFailedAction({ error, divisionId }))))
            )));

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.ADD),
            map((action: inviteActions.AddAction) => action.payload),
            switchMap((inviteData) => this.http.post<Invite[]>(`/api/invites`, inviteData).pipe(
                map(res => res.map((u) => new Invite(u))),
                map((invites: Invite[]) => new inviteActions.AddCompleteAction(invites)),
                catchError((error) => observableOf(new inviteActions.AddFailedAction({ error, studioId: inviteData[0].studio_id }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.DELETE),
            map((action: inviteActions.DeleteAction) => action.payload),
            switchMap((inviteData) => this.http.post(`/api/invites/delete`, inviteData).pipe(
                map((deletedIds: number[]) => new inviteActions.DeleteCompleteAction(deletedIds)),
                catchError((error) => observableOf(new inviteActions.DeleteFailedAction({ error }))))
            )));

     resend$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.RESEND),
            map((action: inviteActions.ResendAction) => action.payload),
            switchMap((data) => this.http.post<Invite[]>(`/api/invites/resend`, data).pipe(
                map(res => res.map((u) => new Invite(u))),
                map((invites: Invite[]) => new inviteActions.ResendCompleteAction({ invites, studioId: data.studioId, franchiseId: data.franchiseId, divisionId: data.divisionId })),
                catchError((error) => observableOf(new inviteActions.ResendFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(inviteActions.ADD_FAILED, inviteActions.DELETE_FAILED, inviteActions.LIST_BY_STUDIO_FAILED, inviteActions.LIST_BY_FRANCHISE_FAILED, inviteActions.LIST_BY_DIVISION_FAILED, inviteActions.RESEND_FAILED),
            map((action: any) => new layoutActions.ErrorAction(action.payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
