import { Action } from '@ngrx/store';

export const SELECT_LOCATION = '[Transaction Expando Row] Select Location';

export class SelectLocationAction implements Action {
    readonly type = SELECT_LOCATION;
    constructor(public payload: number) { }
}

export type Actions
    = SelectLocationAction;
