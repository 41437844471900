<div class="container">
    <div class="headerContainer">
        <div class="header">
           <img class="edit-icon clickable"  style="width: 330px;" src="../../../../assets/images/ep-assethub.png">
        </div>
    </div>
    <div class="page">
        <div class="content">
            <h1>Welcome back to AssetHub!</h1>
            <h5>
                As a part of the Entertainment Partners family, we now require an EP
                account to log in to our apps
            </h5>
            <div class="boxes">
                <div class="box">
                    <div>
                        <p>Already have a SyncOnSet or AssetHub account?</p>
                    </div>
                    <div>
                        <a routerLink="/claim-account" class="button">
                            Claim EP account
                        </a>
                    </div>
                </div>
                <div class="box blueLight">
                    <div>
                        <p>Already claimed your account? Log in with EP.</p>
                    </div>
                    <div>
                        <a href="/api/auth/login" class="button">
                            Log in
                        </a>
                    </div>
                </div>
            </div>
            <h4>
                Looking for something else? Product resources, help articles, FAQs
                and more can be found
                <a href="https://support.synconset.com">here</a>.
            </h4>
            <a href="https://www.synconset.com/core?r=site/login">Looking for SyncOnSet?</a>
        </div>
    </div>
</div>
