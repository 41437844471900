import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { take } from 'rxjs/operators';
// import * as layout from '../../../core/store/layout/layout.actions';

@Component({
    selector: 'ah-field-remove-modal',
    templateUrl: './field-remove-modal.component.html',
    styleUrls: ['./field-remove-modal.component.scss']
})
export class FieldRemoveModalComponent implements OnInit {
    messageList = [];
    constructor(@Inject(MAT_DIALOG_DATA) private data: any) { }

    ngOnInit(): void {
        for (let asset of this.data.assets) {
            let message = [];
            if (asset.franchise) {
                message.push(`Features / Series: ${asset.franchise}`);
            }
            if (asset.division) {
                if (message.length > 0)
                    message.push(', ');
                message.push(`Division: ${asset.division}`);
            }
            this.messageList.push(message.join(""));
        }
    }

}
