<div [formGroup]="group" [ngSwitch]="field?.type">
    <!-- checkbox -->
    <div *ngSwitchCase="fieldTypes.CHECKBOX.type" class="input-wrapper">
        <mat-checkbox formControlName="{{controlName}}">{{field.name}}</mat-checkbox>
    </div>

    <!-- date -->
     <div *ngSwitchCase="fieldTypes.DATE.type">
        <mat-form-field [ngClass]="{'full-width': fullWidth}">
           <input [required]="required" matInput [matDatepicker]="picker" readonly (click)="picker.open()" (onSelect)="onDateSelect()" formControlName="{{controlName}}" placeholder="{{field.name}}" >
           <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
           <mat-datepicker-toggle matSuffix *ngIf="group.value[controlName]" (click)="removeDate()">
            <mat-icon matDatepickerToggleIcon>cancel</mat-icon>
          </mat-datepicker-toggle>
            <mat-datepicker [touchUi]="deviceService.isMobile() || deviceService.isTablet()" #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <!-- picklist -->
    <div *ngSwitchCase="fieldTypes.PICKLIST.type" class="p-dropdown-items-wrapper" [ngClass]="{'input-wrapper-p-dropdown': true, 'full-width': fullWidth}">
        <div class="owner-icon" [style.color]="iconColor" *ngIf="icon">
            <mat-icon>{{icon}}</mat-icon>
        </div>
        <p-dropdown appendTo="body" [ngClass]="{'blank-option': !group.controls[controlName].value}"
            [styleClass]="isButton ? 'mat-raised-button' : ''"
            (onChange)="onMenuSelect($event.value)" [options]="options"
            [filter]="!(deviceService.isMobile() || deviceService.isTablet())"
            formControlName="{{controlName}}"
            placeholder="{{placeholder ? placeholder : field.name}} {{required ? ' *' : ''}}"
            [virtualScroll]="true" [virtualScrollItemSize]="40"
        >
            <ng-template let-item pTemplate="selectedItem">
                <span [ngClass]="{'required': needsSelect, 'default': !needsSelect}">{{ item.label || 'empty' }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
                <span [ngClass]="option.styleClass">{{option.label||'empty'}}</span>
            </ng-template>
        </p-dropdown>
    </div>

    <!-- barcode -->
    <div *ngSwitchCase="fieldTypes.BARCODE.type">
        <ah-barcode-input placeholder="{{field.name}}" [disable]="disabled" formControlName="{{controlName}}" [controlName]="controlName" [group]="group" #input>
        </ah-barcode-input>
    </div>

    <!-- input -->
    <mat-form-field *ngSwitchDefault [ngClass]="{'full-width': fullWidth}" [ngStyle]="{'display': (field.canonical_name !== 'created_by_user') ? 'block' : 'none'}">
        <mat-label>{{field.name}}</mat-label>
        <textarea *ngIf="(field.type === fieldTypes.LONG_TEXT_AREA.type || field.type === fieldTypes.HUGE_TEXT_AREA.type) && autoSize" matInput matAutosizeMaxRows="5"
            matTextareaAutosize formControlName="{{controlName}}"
            [required]="required" #input>
        </textarea>
        <!-- please don't remove the next line- matTextareaAutosize breaks tables on firefox when adding or removing rows -->
        <textarea *ngIf="(field.type === fieldTypes.LONG_TEXT_AREA.type || field.type === fieldTypes.HUGE_TEXT_AREA.type) && !autoSize" matInput
             formControlName="{{controlName}}" [required]="required" #input>
        </textarea>
        <input
            *ngIf="field.type !== fieldTypes.LONG_TEXT_AREA.type && field.type !== fieldTypes.HUGE_TEXT_AREA.type && field.type !== fieldTypes.NUMBER.type"
            matInput
            type="{{field.input_type}}"
            pattern="{{field.pattern ? field.pattern.pattern : ''}}"
            maxlength="{{field.max_length ? field.max_length : ''}}"
            formControlName="{{controlName}}"
            [required]="required"
            #input
            (blur)="onBlur()"
        >
        <input
            *ngIf="field.type === fieldTypes.NUMBER.type"
            matInput
            type="number"
            pattern="{{field.pattern ? field.pattern.pattern : ''}}"
            formControlName="{{controlName}}"
            [required]="required"
            #input
            max="{{field.max ? field.max : ''}}"
            min="{{field.min ? field.min : ''}}"
        >
        <mat-error *ngIf="field.max_length && group.get(controlName).hasError('maxLength')">This is too long
        </mat-error>
        <mat-error *ngIf="field.pattern && group.get(controlName).hasError('pattern')">
            {{field.pattern.message}}</mat-error>
    </mat-form-field>
</div>
