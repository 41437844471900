import { Action } from '@ngrx/store';
import { DivisionGroup, FranchiseGroup, Group } from '../models';
import { SubGroup } from '../sub-groups/sub-group.model';

export const ADD_IN_FRANCHISE = '[Group] Add Group in Franchise';
export const ADD_IN_FRANCHISE_COMPLETE = '[Group] Add Group in Franchise Complete';
export const ADD_IN_FRANCHISE_FAILED = '[Group] Add Group in Franchise Failed';

export const ADD_IN_DIVISION = '[Group] Add Group in Division';
export const ADD_IN_DIVISION_COMPLETE = '[Group] Add Group in Division Complete';
export const ADD_IN_DIVISION_FAILED = '[Group] Add Group in Division Failed';

export const ADD_IN_STUDIO = '[Group] Add Group in Studio';
export const ADD_IN_STUDIO_COMPLETE = '[Group] Add Group in Studio Complete';
export const ADD_IN_STUDIO_FAILED = '[Group] Add Group in Studio Failed';

export const GET = '[Group] Get Group';
export const GET_COMPLETE = '[Group] Get Group Complete';
export const GET_FAILED = '[Group] Get Group Failed';

export const GET_ASSET_COUNT = '[Group] Get Studio Asset Count';
export const GET_ASSET_COUNT_COMPLETE = '[Group] Get Studio Asset Count Complete';
export const GET_ASSET_COUNT_FAILED = '[Group] Get Studio Asset Count Failed';

export const LIST = '[Group] List By Studio';
export const LIST_COMPLETE = '[Group] List Complete';
export const LIST_FAILED = '[Group] List Failed';

export const LIST_BY_STUDIO_GROUPS = '[Group] List By Studio Group';
export const LIST_BY_STUDIO_GROUP_COMPLETE = '[Group] List By Studio Group Complete';
export const LIST_BY_STUDIO_GROUP_FAILED = '[Group] List By Studio Group Failed';

export const DELETE_FROM_STUDIO = '[Group] Delete Group from Studio';
export const DELETE_FROM_STUDIO_COMPLETE = '[Group] Delete Group from Studio Complete';
export const DELETE_FROM_STUDIO_FAILED = '[Group] Delete Group from Studio Failed';

export const LIST_BY_FRANCHISE = '[Group] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[Group] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Group] List By Franchise Failed';

export const LIST_BY_DIVISION = '[Group] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[Group] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[Group] List By Division Failed';

export const SELECT = '[Group] Select';

export const UPDATE = '[Group] Update';
export const UPDATE_COMPLETE = '[Group] Update Complete';
export const UPDATE_FAILED = '[Group] Update Failed';

export const LIST_BY_STUDIO_GROUP_TYPE = '[Group] List By Studio and Group Type';
export const LIST_BY_STUDIO_GROUP_TYPE_COMPLETE = '[Group] List By Studio and Group Type Complete';
export const LIST_BY_STUDIO_GROUP_TYPE_FAILED = '[Group] List By Studio and Group Type Failed';

export const CONVERT_GROUP_TO_SUBGROUP = '[Group] Convert group to Subgroup';
export const CONVERT_GROUP_TO_SUBGROUP_COMPLETE = '[Group] Convert Group to Subgroup Complete';
export const CONVERT_GROUP_TO_SUBGROUP_FAILED = '[Group] Convert Group to Subgroup Failed';

export const CONVERT_SUBGROUP_TO_GROUP = '[Group] Convert Subgroup to Group';
export const CONVERT_SUBGROUP_TO_GROUP_COMPLETE = '[Group] Convert Subgroup to Group Complete';
export const CONVERT_SUBGROUP_TO_GROUP_FAILED = '[Group] Convert Subgroup to Group Failed';

export const RESET_ADDING = '[Group] Reset Adding to Inactive';

export class AddInFranchiseAction implements Action {
    readonly type = ADD_IN_FRANCHISE;

    constructor(public payload: { group: Group, attachments?: File[], types?: string[], franchiseId: number }) { }
}

export class AddInFranchiseCompleteAction implements Action {
    readonly type = ADD_IN_FRANCHISE_COMPLETE;

    constructor(public payload: { group: Group, franchiseGroup: FranchiseGroup }) { }
}

export class AddInFranchiseFailedAction implements Action {
    readonly type = ADD_IN_FRANCHISE_FAILED;

    constructor(public payload: any) { }
}

export class AddInDivisionAction implements Action {
    readonly type = ADD_IN_DIVISION;

    constructor(public payload: { group: Group, attachments?: File[], types?: string[], divisionId: number }) { }
}

export class AddInDivisionCompleteAction implements Action {
    readonly type = ADD_IN_DIVISION_COMPLETE;

    constructor(public payload: { group: Group, divisionGroup: DivisionGroup }) { }
}

export class AddInDivisionFailedAction implements Action {
    readonly type = ADD_IN_DIVISION_FAILED;

    constructor(public payload: any) { }
}

export class AddInStudioAction implements Action {
    readonly type = ADD_IN_STUDIO;

    constructor(public payload: { group: Group, studioId: number }) { }
}

export class AddInStudioCompleteAction implements Action {
    readonly type = ADD_IN_STUDIO_COMPLETE;

    constructor(public payload: { group: Group }) { }
}

export class AddInStudioFailedAction implements Action {
    readonly type = ADD_IN_STUDIO_FAILED;

    constructor(public payload: any) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: { groupId: number, status: boolean }) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: { group: Group }) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: any) { }
}

export class GetAssetCountAction implements Action {
    readonly type = GET_ASSET_COUNT;

    constructor(public payload: { studioId: number, groupId: number }) { }
}

export class GetAssetCountCompleteAction implements Action {
    readonly type = GET_ASSET_COUNT_COMPLETE;

    constructor(public payload: { groupId: number, assetCount: number }) { }
}

export class GetAssetCountFailedAction implements Action {
    readonly type = GET_ASSET_COUNT_FAILED;

    constructor(public payload: any) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: { studioId: number, groups: Group[] }) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: any) { }
}

export class DeleteFromStudioAction implements Action {
    readonly type = DELETE_FROM_STUDIO;

    constructor(public payload: number) { }
}

export class DeleteFromStudioCompleteAction implements Action {
    readonly type = DELETE_FROM_STUDIO_COMPLETE;

    constructor(public payload: number) { }
}

export class DeleteFromStudioFailedAction implements Action {
    readonly type = DELETE_FROM_STUDIO_FAILED;

    constructor(public payload: any) { }
}

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: number) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: { franchiseId: number, groups: Group[] }) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: any) { }
}

export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: number) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: { divisionId: number, groups: Group[] }) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: any) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Group) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Group) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class ListByStudioGroupTypeAction implements Action {
    readonly type = LIST_BY_STUDIO_GROUP_TYPE;

    constructor(public payload: { studioId: number, groupTypeId: number }) { }
}

export class ListByStudioGroupTypeCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_GROUP_TYPE_COMPLETE;

    constructor(public payload: { studioId: number, groups: Group[] }) { }
}

export class ListByStudioGroupTypeFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_GROUP_TYPE_FAILED;

    constructor(public payload: any) { }
}

export class ConvertGroupToSubgroupAction implements Action {
    readonly type = CONVERT_GROUP_TO_SUBGROUP;

    constructor(public payload: { group_id: number, group: { name: string, description: string, group_id: number } }) { }
}

export class ConvertGroupToSubgroupCompleteAction implements Action {
    readonly type = CONVERT_GROUP_TO_SUBGROUP_COMPLETE;

    constructor(public payload: { subGroup: SubGroup, groupId: number }) { }
}

export class ConvertGroupToSubgroupFailedAction implements Action {
    readonly type = CONVERT_GROUP_TO_SUBGROUP_FAILED;

    constructor(public payload: any) { }
}

export class ConvertSubgroupToGroupAction implements Action {
    readonly type = CONVERT_SUBGROUP_TO_GROUP;

    constructor(public payload: { subgroup_id: number, subGroup: { name: string, description: string, group_id: number } }) { }
}

export class ConvertSubgroupToGroupCompleteAction implements Action {
    readonly type = CONVERT_SUBGROUP_TO_GROUP_COMPLETE;

    constructor(public payload: { group: Group, subgroup_id: number, group_id: number }) { }
}

export class ConvertSubgroupToGroupFailedAction implements Action {
    readonly type = CONVERT_SUBGROUP_TO_GROUP_FAILED;

    constructor(public payload: any) { }
}

export class ResetAction implements Action {
    readonly type = RESET_ADDING;

    constructor() { }
}

export type Actions
    = AddInFranchiseAction
    | AddInFranchiseCompleteAction
    | AddInFranchiseFailedAction
    | AddInDivisionAction
    | AddInDivisionCompleteAction
    | AddInDivisionFailedAction
    | AddInStudioAction
    | AddInStudioCompleteAction
    | AddInStudioFailedAction
    | GetAssetCountAction
    | GetAssetCountCompleteAction
    | GetAssetCountFailedAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | DeleteFromStudioAction
    | DeleteFromStudioCompleteAction
    | DeleteFromStudioFailedAction
    | ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SelectAction
    | ListByStudioGroupTypeAction
    | ListByStudioGroupTypeCompleteAction
    | ListByStudioGroupTypeFailedAction
    | ConvertGroupToSubgroupAction
    | ConvertGroupToSubgroupCompleteAction
    | ConvertGroupToSubgroupFailedAction
    | ConvertSubgroupToGroupAction
    | ConvertSubgroupToGroupCompleteAction
    | ConvertSubgroupToGroupFailedAction
    | ResetAction;
