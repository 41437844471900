import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf } from 'rxjs';
import { Action } from '@ngrx/store';
import * as divisionSubGroupActions from './division-subgroup.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DivisionSubGroupEffects {
     getAssetCount$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(divisionSubGroupActions.GET_ASSET_COUNT),
            map((action: divisionSubGroupActions.GetAssetCountAction) => action.payload),
            switchMap(({ divisionId, subgroupId }) => this.http.get<number>(`/api/divisions/${divisionId}/subgroups/${subgroupId}/asset-count`).pipe(
                map((res) => new divisionSubGroupActions.GetAssetCountCompleteAction(res)),
                catchError((error) => observableOf(new divisionSubGroupActions.GetAssetCountFailedAction({ error }))))
            )));

    constructor(private actions$: Actions, private http: HttpClient) { }
}