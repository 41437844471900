import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import * as groupActions from '../../../core/store/groups/group.actions';
import { Franchise, Group, GroupTypes } from '../../../core/store/models';
import { rootSelectors } from '../../../core/store/selectors';
import { ActionStatus } from '../../../core/store/types';
import * as fromRoot from '../../../core/store';

@Component({
    selector: 'ah-group-input',
    templateUrl: './group-input.component.html',
    styleUrls: ['./group-input.component.scss']
})
export class GroupInputComponent implements OnInit, OnChanges {
    @Input() allGroups: Group[];
    @Input() disabled = false;
    @Input() divisionId?: number;
    @Input() divisionGroups: Group[] = [];
    @Input() notDivisionGroups: Group[] = [];
    @Input() franchiseGroups: Group[] = [];
    @Input() notFranchiseGroups: Group[] = [];
    @Input() franchiseId?: number;
    @Input() franchise?: Franchise;
    @Input() selectedGroups: string[] = [];
    @Input() selectedSubGroups: string[] = [];
    @Input() studioId: number;
    @Input() isAssetModal?: boolean;
    @Input() franchiseTable?: any;
    @Input() divisionTable?: any;
    @Input() franchiseGroupTable?: any;
    @Input() divisionGroupTable?: any;
    @Input() franchiseSubGroupTable?: any;
    @Input() divisionSubGroupTable?: any;
    @Input() eventTable?: any;
    @Input() convertSubGroup?: Group;
    @Input() isConvertSubGroup?: boolean = false;
    @Output() selectGroups = new EventEmitter();

    @ViewChild('groupInput', { static: true }) groupInput: ElementRef;
    @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;

    separatorKeysCodes: number[] = [ENTER, COMMA];

    groupForm: UntypedFormGroup = this.fb.group({
        groupControl: '',
    });

    filteredGroups$: Observable<GroupCollection[]>;
    filteredStudioGroups$: Observable<GroupCollection[]>;
    groups: GroupCollection[];
    studioGroups: GroupCollection[];
    isDivisionTable: boolean;
    isFranchisetable: boolean;
    isListOpen: boolean = false;
    groupType: GroupTypes;

    constructor(private store: Store<rootSelectors.State>, private fb: UntypedFormBuilder) { }

    ngOnInit() {
        this.isDivisionTable = this.divisionTable || this.divisionGroupTable || this.eventTable || this.divisionSubGroupTable;
        this.isFranchisetable = this.franchiseTable || this.franchiseGroupTable || this.franchiseSubGroupTable;
        this.setGroups();
        this.filteredGroups$ = this.groupForm.get('groupControl')!.valueChanges
            .pipe(
                startWith<string | Group>(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this.filterGroups(name) : this.groups.slice())
            );
        this.filteredStudioGroups$ = this.groupForm.get('groupControl')!.valueChanges
            .pipe(
                startWith<string | Group>(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this.filterGroups(name, true) : this.studioGroups.slice())
            );
        this.store.select(fromRoot.getGroupTypes)
            .subscribe(groupTypes => {
                this.groupType = groupTypes.find(gt => gt.name === 'Standard');
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.allGroups || changes.franchiseGroups || changes.notFranchiseGroups || changes.divisionGroups || changes.notDivisionGroups) {
            this.setGroups();
        }
        if (changes.disabled) {
            const control = this.groupForm.controls['groupControl'];
            if (changes.disabled.currentValue) {
                control.disable();
            } else if (control.disabled) {
                control.enable();
            }
        }
    }

    setGroups() {
        if (this.isDivisionTable) {
            this.groups = this.setGroupsAndSubGroups(this.divisionGroups);
            this.studioGroups = this.setGroupsAndSubGroups(this.notDivisionGroups);
        } else if (this.isFranchisetable) {
            this.groups = this.setGroupsAndSubGroups(this.franchiseGroups);
            this.studioGroups = this.setGroupsAndSubGroups(this.notFranchiseGroups);
        } else {
            // For Request Table
            this.groups = [];
            this.studioGroups = this.setGroupsAndSubGroups(this.allGroups);
        }
    }

    setGroupsAndSubGroups(groups: Group[]) {
        let groupsAndSubGroups: GroupCollection[] = [];
        if (groups && groups.length) {
            groups.forEach((group) => {
                groupsAndSubGroups.push({ id: group.id, name: group.name, isSubgroup: false });
                // subgroup name is not required with group name if group converting to sub group
                if (!this.isConvertSubGroup) {
                    group.subGroups.forEach((subGroup) => {
                        groupsAndSubGroups.push({ id: subGroup.id, name: `${group.name} - ${subGroup.name}`, isSubgroup: true });
                    })
                }
            });
        }

        if (this.isConvertSubGroup) {
            return groupsAndSubGroups.filter(group => group.id !== this.convertSubGroup.id)
        }
        return groupsAndSubGroups;
    }

    filterGroups(name: string, isStudioGroups?: boolean): GroupCollection[] {
        const filterValue = name.toLowerCase();
        if (isStudioGroups) {
            return this.studioGroups.filter(group => group.name.toLowerCase().includes(filterValue));
        }
        return this.groups.filter(group => group.name.toLowerCase().includes(filterValue));
    }

    addGroup(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value.trim();
        const selectedGroup = this.groups.find(group => group.name === value);
        const selectedStudioGroup = this.studioGroups.find(group => group.name === value);
        if (selectedGroup || selectedStudioGroup) {
            if (selectedGroup)
                this.addGroupToSelected(value);
            if (selectedStudioGroup)
                this.addSubGroupToSelected(value);
        } else if (value !== '') {
            const newGroup = new Group({ name: value, group_type_id: this.groupType.id, studio_id: this.studioId });
            if (this.divisionId) {
                this.store.dispatch(new groupActions.AddInDivisionAction({ group: newGroup, divisionId: this.divisionId }));
            } else if (this.franchiseId) {
                this.store.dispatch(new groupActions.AddInFranchiseAction({ group: newGroup, franchiseId: this.franchiseId }));
            } else {
                this.store.dispatch(new groupActions.AddInStudioAction({ group: newGroup, studioId: this.studioId }));
            }
            combineLatest(this.store.select(rootSelectors.getGroupsAdding), this.store.select(rootSelectors.getGroups)).pipe(
                filter(([saving, groups]) => this.doneSavingAndAllGroupsIsUpdated(saving, groups, value)),
                take(1),
                map(() => this.addGroupToSelected(value))
            ).subscribe();
        }
        if (input) {
            input.value = '';
        }
        this.clearGroupFilters();
    }

    removeGroup(group: string) {
        const index = this.selectedGroups.indexOf(group);
        if (index >= 0) {
            this.selectedGroups.splice(index, 1);
        }
        if (this.isAssetModal) {
            this.selectGroups.emit();
        }
    }

    removeSubGroup(subGroup: string) {
        const index = this.selectedSubGroups.indexOf(subGroup);
        if (index >= 0) {
            this.selectedSubGroups.splice(index, 1);
        }
        if (this.isAssetModal) {
            this.selectGroups.emit();
        }
    }

    selectGroup(event: MatAutocompleteSelectedEvent) {
        let groupName = event.option.viewValue;
        // events opttion returns label in safari. It should be removed from groupName to make Safari work
        groupName = groupName
            .replace(" (Division Groups)", "")
            .replace(" (Series Groups)", "")
            .replace(" (Feature Groups)", "")
            .replace(" (Studio Groups)", "");

        const group = [...this.groups, ...this.studioGroups].find(group => group.name === groupName);
        if (this.isConvertSubGroup) {
            this.selectedGroups = [];
            this.selectedGroups.push(groupName)
            this.selectGroups.emit(group);
        } else {
            if (group.isSubgroup) {
                this.addSubGroupToSelected(groupName);
            }
            else {
                this.addGroupToSelected(groupName);
            }
        }
        this.groupInput.nativeElement.value = '';
        this.clearGroupFilters();
    }

    showAutoComplete() {
        if (this.isDivisionTable) {
            return !(this.divisionGroups.length === 0 && this.notDivisionGroups.length === 0);
        } else if (this.isFranchisetable) {
            return !(this.franchiseGroups.length === 0 && this.notFranchiseGroups.length === 0);
        } else {
            return !(this.allGroups.length === 0);
        }
    }

    addGroupToSelected(name: string) {
        if (this.selectedGroups.indexOf(name) < 0) {
            this.selectedGroups.push(name);
            if (this.isAssetModal) {
                this.selectGroups.emit();
            }
        }
    }

    addSubGroupToSelected(name: string) {
        if (this.selectedSubGroups.indexOf(name) < 0) {
            this.selectedSubGroups.push(name);
            if (this.isAssetModal) {
                this.selectGroups.emit();
            }
        }
    }

    clearGroupFilters() {
        this.groupForm.patchValue({ groupControl: '' });
        setTimeout(() => {
            this.isConvertSubGroup ? this.trigger.closePanel() : this.trigger.openPanel();
        });
    }

    private doneSavingAndAllGroupsIsUpdated(saving: ActionStatus, groups: Group[], newGroupName: string) {
        return saving !== ActionStatus.Loading && groups.some(grp => grp.name.toLocaleLowerCase() === newGroupName.trim().toLocaleLowerCase());
    }

    openList() {
        if (this.isConvertSubGroup) {
            this.isListOpen = !this.isListOpen;
            this.isListOpen ? this.trigger.closePanel() : this.trigger.openPanel();
        }
    }
}

interface GroupCollection {
    id: number;
    name: string;
    isSubgroup: boolean;
}
