import * as navActions from './nav.actions';

export interface NavShowFilterOptions {
    showAssetFilterOption: boolean;
    showTransactionFilterOption: boolean;
    showSettingsFilterOption: boolean;
    showStorageFilterOption: boolean;
}

export interface State {
    showAssetFilterOption: boolean;
    showTransactionFilterOption: boolean;
    showSettingsFilterOption: boolean;
    showStorageFilterOption: boolean;
    numOfForReviewAssets: number;
    numOfForReviewDivisionAssets: number;
    numOfOpenRequests: number;
    numOfUnreconciledTransactions: number;
}

export const initialState: State = {
    showAssetFilterOption: false,
    showTransactionFilterOption: false,
    showSettingsFilterOption: false,
    showStorageFilterOption: false,
    numOfForReviewAssets: null,
    numOfForReviewDivisionAssets: null,
    numOfOpenRequests: null,
    numOfUnreconciledTransactions: null
};

export function reducer(state = initialState, action: navActions.Actions): State {
    switch (action.type) {
        case navActions.TOGGLE_ASSET_FILTER_OPTION: {
            return { ...state, showAssetFilterOption: !state.showAssetFilterOption };
        }

        case navActions.TOGGLE_TRANSACTION_FILTER_OPTION: {
            return { ...state, showTransactionFilterOption: !state.showTransactionFilterOption };
        }

        case navActions.TOGGLE_SETTINGS_FILTER_OPTION: {
            return { ...state, showSettingsFilterOption: !state.showSettingsFilterOption };
        }

        case navActions.TOGGLE_STORAGE_FILTER_OPTION: {
            return { ...state, showStorageFilterOption: !state.showStorageFilterOption };
        }

        case navActions.LIST_STATUS_COMPLETE: {
            const numOfForReviewAssets = action.payload.numOfForReviewAssets;
            const numOfOpenRequests = action.payload.numOfOpenRequests;
            const numOfUnreconciledTransactions = action.payload.numOfUnreconciledTransactions;
            const numOfForReviewDivisionAssets = action.payload.numOfForReviewDivisionAssets;
            return {
                ...state,
                numOfForReviewAssets: numOfForReviewAssets ? numOfForReviewAssets : null,
                numOfForReviewDivisionAssets: numOfForReviewDivisionAssets ? numOfForReviewDivisionAssets : null,
                numOfOpenRequests: numOfOpenRequests ? numOfOpenRequests : null,
                numOfUnreconciledTransactions: numOfUnreconciledTransactions ? numOfUnreconciledTransactions : null
            };
        }

        default: {
            return state;
        }
    }
}

export const getShowFilterOptions = (state: State) => ({
    showAssetFilterOption: state.showAssetFilterOption,
    showTransactionFilterOption: state.showTransactionFilterOption,
    showSettingsFilterOption: state.showSettingsFilterOption,
    showStorageFilterOption: state.showStorageFilterOption
});

export const getShowAssetFilterOptions = (state: State) => state.showAssetFilterOption;
