import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import * as customStudioFieldNameActions from './../../core/store/custom-studio-field-names/custom-studio-field-name.actions';

@Injectable()
export class CustomStudioFieldNameDataService {
    constructor(private store: Store<fromRoot.State>) { }

    getCustomFields(route: ActivatedRouteSnapshot) {
        const combined = observableCombineLatest(this.store.select(fromRoot.getUser), this.store.select(fromRoot.getCustomStudioFieldName));
        return combined.pipe(
            map(([user, customStudioFieldName]) => {
                if (!user) {
                    return null;
                }
                if (!(customStudioFieldName && customStudioFieldName.studio_id)) {
                    const paramStudioId = parseInt(route.params.studioId, 10);
                    let studioId = user.current_studio_id;
                    if (paramStudioId && paramStudioId !== user.current_studio_id) {
                        studioId = paramStudioId;
                    }
                    this.store.dispatch(new customStudioFieldNameActions.ListAction(studioId));

                }
                return customStudioFieldName;
            }),
            filter((customStudioFieldName) => !!(customStudioFieldName && customStudioFieldName.studio_id)),
            take(1));
    }
}
