import { Action } from '@ngrx/store';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { SortObject, TableFilterObject } from '../types';

export const SELECT_TAB = '[Franchise Asset Index] Select Tab';

export const SORT_FRANCHISE_LIST = '[Franchise Asset Index] Sort Franchise Asset List';
export const SORT_FRANCHISE_FOR_REVIEW_LIST = '[Franchise Asset Index] Sort Franchise For Review Asset List';

export const FILTER_FRANCHISE_LIST = '[Franchise Asset Index] Filter Franchise Asset List';
export const FILTER_FRANCHISE_FOR_REVIEW_LIST = '[Franchise Asset Index] Filter Franchise For Review List';
export const FILTER_FRANCHISE_TRASHED_LIST = '[Franchise Asset Index] Filter Franchise Trashed List';

export const LIST_TABLE_STATE = '[Franchise Asset Index] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Franchise Asset Index] List Table State Complete';
export const LIST_REVIEW_TABLE_STATE = '[Franchise Asset Index] List Review Table State';
export const LIST_REVIEW_TABLE_STATE_COMPLETE = '[Franchise Asset Index] List Review Table State Complete';

export class SelectTabAction implements Action {
    readonly type = SELECT_TAB;

    constructor(public payload: number) { }
}

export class SortFranchiseAssetsAction implements Action {
    readonly type = SORT_FRANCHISE_LIST;

    constructor(public payload: { franchiseId: number, sort: SortObject }) { }
}

export class SortFranchiseForReviewAssetsAction implements Action {
    readonly type = SORT_FRANCHISE_FOR_REVIEW_LIST;

    constructor(public payload: { franchiseId: number, sort: SortObject }) { }
}

export class FilterFranchiseListAction implements Action {
    readonly type = FILTER_FRANCHISE_LIST;

    constructor(public payload: { franchiseId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class FilterFranchiseForReviewListAction implements Action {
    readonly type = FILTER_FRANCHISE_FOR_REVIEW_LIST;

    constructor(public payload: { franchiseId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { franchiseId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { franchiseId: number, tableState: TableState }) { }
}

export class ListReviewTableStateAction implements Action {
    readonly type = LIST_REVIEW_TABLE_STATE;

    constructor(public payload: { franchiseId: number, stateKey: string }) { }
}

export class ListReviewTableStateCompleteAction implements Action {
    readonly type = LIST_REVIEW_TABLE_STATE_COMPLETE;

    constructor(public payload: { franchiseId: number, tableState: TableState }) { }
}

export type Actions
    = SelectTabAction
    | SortFranchiseAssetsAction
    | SortFranchiseForReviewAssetsAction
    | FilterFranchiseListAction
    | FilterFranchiseForReviewListAction
    | ListTableStateAction
    | ListTableStateCompleteAction
    | ListReviewTableStateAction
    | ListReviewTableStateCompleteAction;
