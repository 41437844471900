import { Action } from '@ngrx/store';

export const SELECT_DEPARTMENT = '[Asset Modal] Select Department';
export const SELECT_DIVISION = '[Asset Modal] Select Division';
export const SELECT_EVENT = '[Asset Modal] Select Event';
export const SELECT_LOCATION = '[Asset Modal] Select Location';
export const SELECT_FRANCHISE = '[Asset Modal] Select Franchise';
export const SELECT_PROJECT = '[Asset Modal] Select Project';
export const SELECT_WAREHOUSE = '[Asset Modal] Select Warehouse';
export const SELECT_WAREHOUSE_LOCATION = '[Asset Modal] Select Warehouse Location';
export const SELECT_WAREHOUSE_SUB_LOCATION = '[Asset Modal] Select Warehouse Sub Location';
export const SELECT_STATUS = '[Asset Modal] Select Status';
export const SELECT_SUB_LOCATION = '[Asset Modal] Select Sub Location';
export const SELECT_SUB_DEPARTMENT = '[Asset Modal] Select Sub Department';
export const SELECT_DISPOSITION = '[Asset Modal] Select Disposition';
export const SELECT_CHARACTER = '[Asset Modal] Select Character';
export const SELECT_SET = '[Asset Modal] Select Set';
export const SELECT_GROUPS = '[Asset Modal] Select Groups';

export class SelectDepartmentAction implements Action {
    readonly type = SELECT_DEPARTMENT;
    constructor(public payload: number) { }
}

export class SelectDivisionAction implements Action {
    readonly type = SELECT_DIVISION;
    constructor(public payload: number) { }
}

export class SelectEventAction implements Action {
    readonly type = SELECT_EVENT;
    constructor(public payload: number) { }
}

export class SelectLocationAction implements Action {
    readonly type = SELECT_LOCATION;
    constructor(public payload: number) { }
}

export class SelectFranchiseAction implements Action {
    readonly type = SELECT_FRANCHISE;
    constructor(public payload: number) { }
}

export class SelectProjectAction implements Action {
    readonly type = SELECT_PROJECT;
    constructor(public payload: number) { }
}

export class SelectWarehouseAction implements Action {
    readonly type = SELECT_WAREHOUSE;
    constructor(public payload: number) { }
}

export class SelectWarehouseLocationAction implements Action {
    readonly type = SELECT_WAREHOUSE_LOCATION;
    constructor(public payload: number) { }
}

export class SelectWarehouseSubLocationAction implements Action {
    readonly type = SELECT_WAREHOUSE_SUB_LOCATION;
    constructor(public payload: number) { }
}

export class SelectStatusAction implements Action {
    readonly type = SELECT_STATUS;
    constructor(public payload: number) { }
}

export class SelectSubLocationAction implements Action {
    readonly type = SELECT_SUB_LOCATION;
    constructor(public payload: number) { }
}

export class SelectSubDepartmentAction implements Action {
    readonly type = SELECT_SUB_DEPARTMENT;
    constructor(public payload: number) { }
}

export class SelectDispositionAction implements Action {
    readonly type = SELECT_DISPOSITION;
    constructor(public payload: number) { }
}

export class SelectCharacterAction implements Action {
    readonly type = SELECT_CHARACTER;
    constructor(public payload: number) { }
}

export class SelectSetAction implements Action {
    readonly type = SELECT_SET;
    constructor(public payload: number) { }
}

export class SelectGroupsAction implements Action {
    readonly type = SELECT_GROUPS;
    constructor(public payload: number[]) { }
}

export type Actions
    = SelectDepartmentAction
    | SelectDivisionAction
    | SelectEventAction
    | SelectLocationAction
    | SelectFranchiseAction
    | SelectProjectAction
    | SelectWarehouseAction
    | SelectWarehouseLocationAction
    | SelectWarehouseSubLocationAction
    | SelectStatusAction
    | SelectSubLocationAction
    | SelectSubDepartmentAction
    | SelectDispositionAction
    | SelectCharacterAction
    | SelectSetAction
    | SelectGroupsAction;
