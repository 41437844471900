import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as characterActions from '../characters/character.actions';
import * as departmentActions from '../departments/department.actions';
import * as dispositionActions from '../dispositions/disposition.actions';
import * as divisionActions from '../divisions/division.actions';
import * as fieldActions from '../fields/field.actions';
import * as franchiseActions from '../franchises/franchise.actions';
import * as groupActions from '../groups/group.actions';
import * as layoutActions from '../layout/layout.actions';
import * as locationActions from '../locations/location.actions';
import { Character, Department, Disposition, Division, Field, Franchise, Group, Location, ProjectCurrency, Set, Status, WarehouseLocation } from '../models';
import * as projectCurrencyActions from '../project-currencies/project-currency.actions';
import * as setActions from '../sets/set.actions';
import * as statusActions from '../statuses/status.actions';
import { AssetSatelliteList } from '../types';
import * as warehouseLocationActions from '../warehouse-locations/warehouse-location.actions';
import * as assetSatelliteActions from './asset-satellite.actions';
import * as groupTypesActions from '../group-types/group-types.actions';

@Injectable()
export class AssetSatelliteEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetSatelliteActions.LIST),
            map((action: assetSatelliteActions.ListAction) => action.payload),
            switchMap((data: { studioId: number, franchiseId: number, divisionId: number }) => this.http.post<AssetSatelliteList>(`/api/studios/asset-satellites`, {
                studio_id: data.studioId,
                franchise_id: data.franchiseId,
                division_id: data.divisionId
            }).pipe(
                mergeMap((res: AssetSatelliteList) => {
                    const actionsToDispatch = [];
                    if (res.characters && data.franchiseId) {
                        actionsToDispatch.push(new characterActions.ListByFranchiseCompleteAction(res.characters.map(c => new Character(c))));
                    }
                    if (res.departments) {
                        if (data.franchiseId) {
                            actionsToDispatch.push(new departmentActions.ListByFranchiseCompleteAction(res.departments.map(d => new Department(d))));
                        } else {
                            actionsToDispatch.push(new departmentActions.ListCompleteAction({ studioId: data.studioId, departments: res.departments.map(d => new Department(d)) }));
                        }
                    }
                    if (res.dispositions) {
                        actionsToDispatch.push(new dispositionActions.ListCompleteAction(res.dispositions.map(d => new Disposition(d))));
                    }
                    if (res.division && data.divisionId) {
                        actionsToDispatch.push(new divisionActions.GetCompleteAction(new Division(res.division)));
                    }
                    if (res.divisions) {
                        actionsToDispatch.push(new divisionActions.ListMoveOptionsCompleteAction(res.divisions.map(d => new Division(d))));
                    }
                    if (res.fields) {
                        actionsToDispatch.push(new fieldActions.ListCompleteAction({ fields: res.fields.map(f => new Field(f)), studioId: data.studioId }));
                    }
                    if (res.franchises) {
                        if (res.franchises.onlyOwned) {
                            actionsToDispatch.push(new franchiseActions.ListCompleteAction({ franchises: res.franchises.onlyOwned.map(f => new Franchise(f)), studioId: data.studioId, onlyOwnerOptions: true }));
                        }
                        if (res.franchises.notOnlyOwned) {
                            actionsToDispatch.push(new franchiseActions.ListCompleteAction({ franchises: res.franchises.notOnlyOwned.map(f => new Franchise(f)), studioId: data.studioId, onlyOwnerOptions: false }));
                        }
                    }
                    if (res.franchise && data.franchiseId) {
                        actionsToDispatch.push(new franchiseActions.GetCompleteAction(new Franchise(res.franchise)));
                    }
                    if (res.groups) {
                        if (data.franchiseId && res.groups.franchiseGroups) {
                            actionsToDispatch.push(new groupActions.ListByFranchiseCompleteAction({ franchiseId: data.franchiseId, groups: res.groups.franchiseGroups.map(g => new Group(g)) }));
                        }
                        if (data.divisionId && res.groups.divisionGroups) {
                            actionsToDispatch.push(new groupActions.ListByDivisionCompleteAction({ divisionId: data.divisionId, groups: res.groups.divisionGroups.map(g => new Group(g)) }));
                        }
                        if (res.groups.studioGroups) {
                            actionsToDispatch.push(new groupActions.ListCompleteAction({ studioId: data.studioId, groups: res.groups.studioGroups.map(g => new Group(g)) }));
                        }
                    }
                    if (res.groupTypes) {
                        actionsToDispatch.push(new groupTypesActions.ListCompleteAction({ studioId: data.studioId, groupTypes: res.groupTypes }));
                    }
                    if (res.locations) {
                        actionsToDispatch.push(new locationActions.ListByStudioCompleteAction(res.locations.map(l => new Location(l))));
                    }
                    if (res.projectCurrencies && data.franchiseId) {
                        actionsToDispatch.push(new projectCurrencyActions.ListCompleteAction(res.projectCurrencies.map(c => new ProjectCurrency(c))));
                    }
                    if (res.sets && data.franchiseId) {
                        actionsToDispatch.push(new setActions.ListCompleteAction(res.sets.map(s => new Set(s))));
                    }
                    if (res.statuses) {
                        actionsToDispatch.push(new statusActions.ListCompleteAction(res.statuses.map(s => new Status(s))));
                    }
                    if (res.warehouseLocations) {
                        actionsToDispatch.push(new warehouseLocationActions.ListByStudioCompleteAction(res.warehouseLocations.map(l => new WarehouseLocation(l))));
                    }
                    actionsToDispatch.push(new assetSatelliteActions.ListCompleteAction({}));
                    return actionsToDispatch;
                }),
                catchError((error) => observableOf(new assetSatelliteActions.ListFailedAction({ error }))))
            )));

     listFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetSatelliteActions.LIST_FAILED),
            map((action: assetSatelliteActions.ListFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
