import { createSelector } from 'reselect';
import * as root from '../';
import { sortArray } from '../utils';

export const getSubDeptsForSelectedDept = createSelector(root.getSubDepartmentEntities, root.getSubDeptIdsForSelectedDept, (subDeptEntities, subDeptIds) => {
    return subDeptIds.map(id => subDeptEntities[id]).sort((a, b) => +a.code - +b.code);
});

export const getSubDeptsForSelectedInlineEditDept = createSelector(root.getDepartmentEntities, root.getSelectedInlineEditAsset, (deptEntities, asset) => {
    const dept = deptEntities[asset.department_id];
    return dept.subDepartments ? sortArray(dept.subDepartments, 'code') : [];
});

export const getSubDeptsForAssetModalDept = createSelector(root.getSubDepartmentEntities, root.getDeptSubDeptIds, root.getAssetModalSelectedDepartmentId, (subDeptEntities, subDeptIds, deptId) => {
    return subDeptIds[deptId].map(id => subDeptEntities[id]);
});

function getAssetLikeSubDepts(assetLike, deptToSubDeptMap, subDepartments) {
    if (!assetLike || !deptToSubDeptMap[assetLike.department_id]) {
        return [];
    }
    return deptToSubDeptMap[assetLike.department_id].map(id => subDepartments[id]);
}

export const forSelectedAssetDepartment = createSelector(root.getSelectedAsset, root.getDeptSubDeptIds, root.getSubDepartmentEntities, getAssetLikeSubDepts);
export const forSelectedTransactionDepartment = createSelector(root.getSelectedTransaction, root.getDeptSubDeptIds, root.getSubDepartmentEntities, getAssetLikeSubDepts);

// Do not copy this code. It is bad and we should feel bad (Peter)
export function forDepartmentFactory(departmentId) {
    return createSelector(root.getDeptSubDeptIds, root.getSubDepartmentEntities, (deptToSubDeptMap, subDepartments) => {
        const subDeptIds = deptToSubDeptMap[departmentId] || [];
        return subDeptIds.map(id => subDepartments[id]);
    });
}
