import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as attachmentActions from '../../core/store/attachments/attachment.actions';
import { Attachment } from '../../core/store/models';
import { rootSelectors } from '../../core/store/selectors';
import { SortObject } from '../../core/store/types';

@Injectable()
export class SubGroupAttachmentService {
    constructor(private store: Store<rootSelectors.State>) { }

    add(data: { id: number, attachment: Attachment[] }) {
        this.store.dispatch(new attachmentActions.AddSubGroupAttachmentsCompleteAction({ sub_group_id: data.id, attachments: data.attachment }));
    }

    delete(attachment: Attachment) {
        this.store.dispatch(new attachmentActions.DeleteSubGroupAttachmentAction(attachment));
    }

    sort(sortObject: SortObject, subGroupId: number) {
        this.store.dispatch(new attachmentActions.SortSubGroupAttachmentsAction({
            ...sortObject,
            subGroupId
        }));
    }
}
