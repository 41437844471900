export const FIELD_TYPES = {
    SHORT_TEXT_AREA: {
        type: 'SHORT_TEXT_AREA',
        input_type: 'text',
        max_length: 255,
        db_type: 'string',
        display: 'Short Text Area (255)'
    },
    LONG_TEXT_AREA: {
        type: 'LONG_TEXT_AREA',
        input_type: 'text',
        max_length: 2000,
        db_type: 'string',
        display: 'Long Text Area (2000)'
    },
    HUGE_TEXT_AREA: {
        type: 'HUGE_TEXT_AREA',
        input_type: 'text',
        max_length: 100000,
        db_type: 'string',
        display: 'Huge Text Area (100000)'
    },
    CHECKBOX: {
        type: 'CHECKBOX',
        input_type: 'checkbox',
        db_type: 'boolean',
        display: 'Checkbox'
    },
    EMAIL: {
        type: 'EMAIL',
        input_type: 'email',
        max_length: 255,
        db_type: 'string',
        display: 'Email'
    },
    PHONE: {
        type: 'PHONE',
        input_type: 'tel',
        max_length: 255,
        db_type: 'string',
        display: 'Phone'
    },
    PICKLIST: {
        type: 'PICKLIST',
        input_type: 'select',
        db_type: 'integer',
        display: 'Picklist'
    },
    MULTI_PICKLIST: {
        type: 'MULTI_PICKLIST',
        input_type: 'multi-select',
        db_type: 'array',
        display: 'Multi Picklist'
    },
    DATE: {
        type: 'DATE',
        input_type: 'date',
        db_type: 'date',
        display: 'Date'
    },
    NUMBER: {
        type: 'NUMBER',
        input_type: 'number',
        pattern: {
            pattern: '^-?(?:\\d+(?:\\.\\d*)?|\\.\\d+)$',
            message: 'This field must be a valid number. ex 10.62'
        },
        db_type: 'integer',
        display: 'Number'
    },
    URL: {
        type: 'URL',
        input_type: 'url',
        max_length: 255,
        db_type: 'url',
        display: 'Url'
    },
    CURRENCY: {
        type: 'CURRENCY',
        input_type: 'text',
        pattern: {
            pattern: '^-?(?:\\d{1,15}(?:\\.\\d{0,3})?|\\.\\d{1,3})$',
            message: 'This field must be a valid number. ex 10.62'
        },
        db_type: 'currency',
        max_length: 30,
        display: 'Currency'
    },
    IMAGE: {
        type: 'IMAGE',
        input_type: 'image',
        db_type: 'json',
        display: 'Image'
    },
    ADDRESS: {
        type: 'ADDRESS',
        input_type: 'address',
        db_type: 'address',
        display: 'Address'
    },
    SHIPMENT: {
        type: 'SHIPMENT',
        input_type: 'shipment',
        db_type: 'shipment',
        display: 'Shipment'
    },
    BARCODE: {
        type: 'BARCODE',
        input_type: 'text',
        max_length: 100,
        db_type: 'string',
        display: 'Short Text Area (100)'
    },
};
