import { createSelector } from 'reselect';
import * as root from '../';

export const canAccessRestrictedFieldsInCurrentDivision = createSelector(root.getUser, root.getSelectedDivision, (user, division) => {
    return user && division ? user.permission.canAccessRestrictedInDivision(division.id, division.studio_id) : false;
});

export const canAccessRestrictedFieldsInCurrentFranchise = createSelector(root.getUser, root.getSelectedFranchise, (user, franchise) => {
    return user && franchise ? user.permission.canAccessRestrictedInFranchise(franchise.id, franchise.studio_id) : false;
});

export const getFinalApprovers = createSelector(root.getUserEntities, root.getFinalApproverUserIds, (userEnts, userIds) => {
    return userIds && userIds.length ? userIds.map(id => userEnts[id]) : [];
});
export const getFinalApproverOptions = createSelector(getFinalApprovers, (finalApprovers) => {
    return finalApprovers.map(finalApprover => {
        return {
            value: finalApprover.id,
            label: finalApprover.fullName()
        };
    });
});
