import * as cookie from 'cookie';
import { Injectable } from "@angular/core";

@Injectable()
export class CookieService {
    private get cookie() {
        if (document && document.cookie) {
            return document.cookie;
        }
        return '';
    }

    get(key: string) {
        const cookies = cookie.parse(this.cookie);
        if (cookies && cookies[key]) {
            return cookies[key];
        }
        return null;
    }
}
