// This file is the base angular-cli environment for all other environments

let env_config = {
    production: false,
    development: false,
    staging: false,
    local: false,
    local80: false,
    sosUrl: '',
    intercomAppId: '',
    envName: 'prod',
    sentryDebug: false,
    test: false
};


/* For deployed environments, set config based on our URL
 *
 * Local development and test environment config is augmented by environment.[env].ts
 * where [env] is determined by ng --configuration flag at compile time.
 * These configurations are defined in angular.js
 */

if (window.location.href.indexOf('hound.sosv2.net') > -1) {
    env_config.envName = 'dev';
}
else if (window.location.href.indexOf('assets-qa.sosv2.net') > -1) {
    env_config.envName = 'qa';
}
else if (window.location.href.indexOf('assets.sosv2.net') > -1) {
    env_config.envName = 'staging';
}
else if (window.location.href.indexOf('assets.synconset.com') > -1) {
    env_config.envName = 'prod';
}
else if (window.location.href.indexOf('https://assethub-dev.ep.com') > -1) {
    env_config.envName = 'dev_ct';
}
else if (window.location.href.indexOf('https://assethub-qa.ep.com') > -1) {
    env_config.envName = 'dev_ct';
}
else if (window.location.href.indexOf('https://assethub-uat.ep.com') > -1) {
    env_config.envName = 'uat_ct';
}
else if (window.location.href.indexOf('localhost') > -1) {
    env_config.envName = 'local';
}

export default env_config;
