import { createSelector } from 'reselect';
import { ActionStatus } from '../types';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as assetActions from './../assets/asset.actions';
import { Asset } from './../assets/asset.model';
import * as assetHistoryActions from './asset-history.actions';
import { AssetHistory } from './asset-history.model';

export interface State {
    ids: number[];
    entities: {
        [assetHistoryId: number]: AssetHistory
    };
    assetIds: number[];
    loading: boolean;
    saving: ActionStatus;
    sort: { field: string, order: number, args: any[] };
}

export const initialState: State = {
    ids: [],
    entities: {},
    assetIds: [],
    loading: false,
    saving: ActionStatus.Inactive,
    sort: { field: 'updated_at', order: -1, args: [] }
};

export function reducer(state = initialState, action: assetHistoryActions.Actions | assetActions.Actions): State {
    switch (action.type) {

        case assetActions.GET_COMPLETE: {
            const asset: Asset = action.payload;
            if (asset.history) {
                const entries = asset.history;

                const { ids, entities } = getIdsAndEntities(state, entries);

                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: {
                        ...state.entities,
                        ...entities,
                    }
                };
            }
            return state;
        }

        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const assets: Asset[] = action.payload.assets;
            let newEntities = {};
            const newIds = [];
            assets.forEach(ass => {
                if (ass.history) {
                    const entries = ass.history;
                    const { ids, entities } = getIdsAndEntities(state, entries);
                    newEntities = { ...newEntities, ...entities };
                    newIds.push(...ids);
                }
            });

            return {
                ...state,
                ids: [...state.ids, ...newIds],
                entities: {
                    ...state.entities,
                    ...newEntities,
                }
            };
        }

        case assetHistoryActions.GET: {
            return { ...state, saving: ActionStatus.Loading }
        }

        case assetHistoryActions.GET_COMPLETE: {
            const { assetHistory } = action.payload
            const asset_ids = new Set(assetHistory.map(ah => ah.asset_id))
            const { allIds, entities } = getIdsAndEntities(state, assetHistory);
            return {
                ...state,
                ids: allIds,
                entities,
                assetIds: Array.from(asset_ids),
                saving: ActionStatus.Complete
            }
        }

        case assetHistoryActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case assetHistoryActions.UPDATE_FAILED || assetHistoryActions.GET_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case assetHistoryActions.SORT_EVENTS_LIST: {
            let sorted;
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;
            sorted = copyAndMultiSort(state.ids, state.entities, [{ key: sortKey }], sortOrder, sortArgs);

            return {
                ...state,
                ids: sorted,
                sort: {
                    field: sortKey,
                    order: sortOrder,
                    args: sortArgs
                }
            };
        }

        case assetHistoryActions.UPDATE_COMPLETE: {
            const assetHistory = action.payload.assetHistory;
            const updatedHistory = new AssetHistory(state.entities[assetHistory.id]);
            updatedHistory.note = assetHistory.note;

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [assetHistory.id]: updatedHistory
                },
                saving: ActionStatus.Complete
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getAll = createSelector(getIds, getEntities, (ids, entities) => ids.map(id => entities[id]));
