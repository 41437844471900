import { Action } from '@ngrx/store';

export const LIST_SELECTED_TRANSACTION_TABLE_FIELDS = '[Asset View] List Selected Fields';
export const LIST_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE = '[Asset View] List Selected Fields Complete';
export const LIST_SELECTED_TRANSACTION_TABLE_FIELDS_FAILED = '[Asset View] List Selected Fields Failed';

export const SAVE_SELECTED_TRANSACTION_TABLE_FIELDS = '[Asset View] Save Selected Fields';
export const SAVE_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE = '[Asset View] Save Selected Fields Complete';
export const SAVE_SELECTED_TRANSACTION_TABLE_FIELDS_FAILED = '[Asset View] Save Selected Fields Failed';

export interface ListSelectedFieldsCompletePayload {
    fields: string[];
    franchiseId: number;
    projectId: number;
}

export class ListSelectedTransactionTableFieldsAction implements Action {
    readonly type = LIST_SELECTED_TRANSACTION_TABLE_FIELDS;

    constructor(public payload: { franchiseId: number, projectId: number }) { }
}

export class ListSelectedTransactionTableFieldsCompleteAction implements Action {
    readonly type = LIST_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE;

    constructor(public payload: ListSelectedFieldsCompletePayload) { }
}

export class ListSelectedTransactionTableFieldsFailedAction implements Action {
    readonly type = LIST_SELECTED_TRANSACTION_TABLE_FIELDS_FAILED;

    constructor(public payload: { error: any, franchiseId: number, projectId: number }) { }
}

export class SaveSelectedTransactionTableFieldsAction implements Action {
    readonly type = SAVE_SELECTED_TRANSACTION_TABLE_FIELDS;

    constructor(public payload: ListSelectedFieldsCompletePayload) { }
}

export class SaveSelectedTransactionTableFieldsCompleteAction implements Action {
    readonly type = SAVE_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE;

    constructor(public payload: ListSelectedFieldsCompletePayload) { }
}

export class SaveSelectedTransactionTableFieldsFailedAction implements Action {
    readonly type = SAVE_SELECTED_TRANSACTION_TABLE_FIELDS_FAILED;

    constructor(public payload: { error: any, franchiseId: number }) { }
}

export type Actions
    = ListSelectedTransactionTableFieldsAction
    | ListSelectedTransactionTableFieldsCompleteAction
    | ListSelectedTransactionTableFieldsFailedAction
    | SaveSelectedTransactionTableFieldsAction
    | SaveSelectedTransactionTableFieldsCompleteAction
    | SaveSelectedTransactionTableFieldsFailedAction;
