import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule as ngrxEffectsModule } from '@ngrx/effects';
import { AssetEventEffects } from '../core/store/asset-events/asset-event.effects';
import { AssetHistoryEffects } from '../core/store/asset-history/asset-history.effects';
import { AssetTransactionEffects } from '../core/store/asset-transactions/asset-transaction.effects';
import { AssetSatelliteEffects } from '../core/store/asset-satellites/asset-satellite.effects';
import { AssetTableEffects } from '../core/store/asset-table/asset-table.effects';
import { AssetViewEffects } from '../core/store/asset-view/asset-view.effects';
import { AssetEffects } from '../core/store/assets/asset.effects';
import { AttachmentEffects } from '../core/store/attachments/attachment.effects';
import { CharacterEffects } from '../core/store/characters/character.effects';
import { CurrencyEffects } from '../core/store/currencies/currency.effects';
import { CustomStudioFieldNameEffects } from '../core/store/custom-studio-field-names/custom-studio-field-name.effects';
import { CustomerEffects } from '../core/store/customers/customer.effects';
import { DepartmentEffects } from '../core/store/departments/department.effects';
import { DispositionEffects } from '../core/store/dispositions/disposition.effects';
import { DivisionGroupEffects } from '../core/store/division-groups/division-group.effects';
import { DivisionEffects } from '../core/store/divisions/division.effects';
import { EventFieldEffects } from '../core/store/event-fields/event-field.effects';
import { EventEffects } from '../core/store/events/event.effects';
import { ExcludedDispositionEffects } from '../core/store/excluded-dispositions/excluded-disposition.effects';
import { FieldEffects } from '../core/store/fields/field.effects';
import { FinalApproverRequestEffects } from '../core/store/final-approver-requests/final-approver-request.effects';
import { FranchiseGroupEffects } from '../core/store/franchise-groups/franchise-group.effects';
import { FranchiseEffects } from '../core/store/franchises/franchise.effects';
import { GroupEffects } from '../core/store/groups/group.effects';
import { InviteEffects } from '../core/store/invites/invite.effects';
import { LayoutEffects } from '../core/store/layout/layout.effects';
import { LedgerEffects } from '../core/store/ledger/ledger.effects';
import { LocationEffects } from '../core/store/locations/location.effects';
import { MatchFieldEffects } from '../core/store/match-fields/match-field.effects';
import { NavEffects } from '../core/store/nav/nav.effects';
import { PicklistOptionEffects } from '../core/store/picklist-options/picklist-option.effects';
import { ProjectCharacterEffects } from '../core/store/project-characters/project-character.effects';
import { ProjectCurrencyEffects } from '../core/store/project-currencies/project-currency.effects';
import { ProjectSetEffects } from '../core/store/project-sets/project-set.effects';
import { ProjectEffects } from '../core/store/projects/project.effects';
import { ReportEffects } from '../core/store/reports/report.effects';
import { RequestAssetEffects } from '../core/store/request-assets/request-asset.effects';
import { RequestEffects } from '../core/store/requests/request.effects';
import { SetEffects } from '../core/store/sets/set.effects';
import { SidenavFilterEffects } from '../core/store/sidenav-filter/sidenav-filter.effects';
import { SosFieldEffects } from '../core/store/sos-fields/sos-field.effects';
import { SosLinkEffects } from '../core/store/sos-links/sos-link.effects';
import { StatusEffects } from '../core/store/statuses/status.effects';
import { StorageBoxEffects } from '../core/store/storage-boxes/storage-box.effects';
import { StudioEffects } from '../core/store/studios/studio.effects';
import { SubDepartmentEffects } from '../core/store/sub-departments/sub-department.effects';
import { SubLocationEffects } from '../core/store/sub-locations/sub-location.effects';
import { TransactionEffects } from '../core/store/transactions/transaction.effects';
import { UserEffects } from '../core/store/users/user.effects';
import { WarehouseLocationEffects } from '../core/store/warehouse-locations/warehouse-location.effects';
import { WarehouseSubLocationEffects } from '../core/store/warehouse-sub-locations/warehouse-sub-location.effects';
import { WarehouseEffects } from '../core/store/warehouses/warehouse.effects';
import { GroupTypesEffects } from '../core/store/group-types/group-types.effects';
import { SubGroupEffects } from '../core/store/sub-groups/sub-group.effects';
import { FranchiseSubGroupEffects } from '../core/store/franchise-subgroup/franchise-subgroup.effects';
import { DivisionSubGroupEffects } from '../core/store/division-subgroups/division-subgroup.effects';
import { AssetImportEffects } from '../core/store/asset-imports/asset-imports.effects';
import { ClaimEffects } from '../core/store/claim-account/claim.effects'

@NgModule({
    imports: [
        CommonModule,
        ngrxEffectsModule.forRoot([
            AssetEffects,
            AssetEventEffects,
            AssetHistoryEffects,
            AssetTransactionEffects,
            AssetSatelliteEffects,
            AssetTableEffects,
            AssetViewEffects,
            AssetEventEffects,
            AttachmentEffects,
            CharacterEffects,
            CurrencyEffects,
            CustomerEffects,
            CustomStudioFieldNameEffects,
            DepartmentEffects,
            DispositionEffects,
            DivisionEffects,
            DivisionGroupEffects,
            EventEffects,
            EventFieldEffects,
            ExcludedDispositionEffects,
            FieldEffects,
            FinalApproverRequestEffects,
            FranchiseEffects,
            FranchiseGroupEffects,
            GroupEffects,
            InviteEffects,
            LayoutEffects,
            LedgerEffects,
            LocationEffects,
            MatchFieldEffects,
            NavEffects,
            PicklistOptionEffects,
            ProjectCharacterEffects,
            ProjectCurrencyEffects,
            ProjectEffects,
            ProjectSetEffects,
            ReportEffects,
            RequestEffects,
            RequestAssetEffects,
            SetEffects,
            SidenavFilterEffects,
            SosFieldEffects,
            SosLinkEffects,
            StatusEffects,
            StorageBoxEffects,
            StudioEffects,
            SubDepartmentEffects,
            SubLocationEffects,
            TransactionEffects,
            UserEffects,
            WarehouseEffects,
            WarehouseLocationEffects,
            WarehouseSubLocationEffects,
            GroupTypesEffects,
            SubGroupEffects,
            FranchiseSubGroupEffects,
            DivisionSubGroupEffects,
            AssetImportEffects,
            ClaimEffects
        ])
    ],
    declarations: []
})
export class EffectsModule { }
