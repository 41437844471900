import { LEDGER_TYPES } from '../../../../../shared/enums/ledger-types.enum';
import { BaseModel } from '../base/base.model';
import { FakeField, Field } from '../fields/field.model';

export class MatchField extends BaseModel {

    static DEFAULT_MATCH_FIELDS = {
        [LEDGER_TYPES.GV]: {
            department_id: 'DE',
            sub_department_id: 'TL',
            name: 'Description',
            code: 'SET',
            vendor: 'Vendor Name',
            purchase_date: 'YEAR PERIOD',
            cost: 'Amount',
            origin_currency: 'Curr',
        },
        [LEDGER_TYPES.PSL]: {
            department_id: 'Department',
            sub_department_id: 'SubDept',
            name: 'Distribution Description',
            code: 'SetCode',
            vendor: 'Vendor/Employee',
            purchase_date: 'Eff Date',
            cost: 'Amount',
            origin_currency: 'Org Cur',
        },
        [LEDGER_TYPES.OTHER]: {
            department_id: 'Department',
            sub_department_id: 'SubDept',
            name: 'Distribution Description',
            code: 'SetCode',
            vendor: 'Vendor/Employee',
            purchase_date: 'Eff Date',
            cost: 'Amount',
            origin_currency: 'Org Cur',
        }
    };

    id: number;
    franchise_id: number = null;
    field_id: number = null;
    required: boolean = null;
    required_locked: boolean = null;
    ledger_field_gv: string = null;
    ledger_field_psl: string = null;
    ledger_field_other: string = null;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;
    field?: Field | FakeField;

    constructor(obj) {
        super();
        this.initializeData(obj, this);
        if (obj.field) {
            if (obj.field.mapTo) {
                this.field = new FakeField(obj.field);
            } else {
                this.field = new Field(obj.field);
            }
        }
    }

    static getLedgerField(type: LEDGER_TYPES) {
        if (type === LEDGER_TYPES.GV) {
            return 'ledger_field_gv';
        } if (type === LEDGER_TYPES.OTHER) {
            return 'ledger_field_other';
        } else {
            return 'ledger_field_psl';
        }
    }

    getLedgerField(type: LEDGER_TYPES) {
        return MatchField.getLedgerField(type);
    }

    setLedgerValue(type: LEDGER_TYPES, val) {
        this[MatchField.getLedgerField(type)] = val;
    }

    setDefault(type) {
        if (this.field) {
            if (MatchField.DEFAULT_MATCH_FIELDS[type] && MatchField.DEFAULT_MATCH_FIELDS[type][this.field.canonical_name]) {
                const ledgerFieldName = MatchField.DEFAULT_MATCH_FIELDS[type][this.field.canonical_name];
                this[this.getLedgerField(type)] = ledgerFieldName;
            }
        }
    }

    getLedgerFieldValue(type: LEDGER_TYPES) {
        return this[this.getLedgerField(type)];
    }
}
