import { createSelector } from 'reselect';
import * as root from '../';
import { STANDARD_FIELDS } from '../../../../../shared/fields/standard-fields';
import * as bulkMoveModalSelectors from '../../../core/store/bulk-move-modal/bulk-move-modal.selectors';
import { StorageBox } from '../models';
import { SortObject } from '../types';
import { copyAndMultiSort } from '../utils';
import * as fromStorageBox from './storage-box.reducer';

export const getStorageBoxState = (state: root.State) => state.storageBox;

export const getStorageBoxEntities = createSelector(getStorageBoxState, fromStorageBox.getEntities);
const getStorageBoxIds = createSelector(getStorageBoxState, fromStorageBox.getIds);
export const getAllStorageBoxes = createSelector(getStorageBoxState, fromStorageBox.getAll);
export const getStorageBoxLoading = createSelector(getStorageBoxState, fromStorageBox.getLoading);
export const getStorageBoxSort = createSelector(getStorageBoxState, fromStorageBox.getSort);
export const getStorageBoxSaving = createSelector(getStorageBoxState, fromStorageBox.getSaving);
export const getStorageBoxDeleteState = createSelector(getStorageBoxState, fromStorageBox.getDeleteState);

export const getStorageBoxesWithSort = createSelector(getStorageBoxEntities, getStorageBoxIds, getStorageBoxSort, root.getWarehouseLocationEntities, root.getWarehouseSubLocationEntities, (storageBoxEnts, ids, sort, warehouseLocEnts, warehouseSubLocEnts) => {
    let sortedIds = ids;
    if (sort && sort.field) {
        const storageBoxes = ids.map(id => storageBoxEnts[id]);
        if (sort.field === STANDARD_FIELDS.warehouseLocation.canonical_name) {
            return sortByWarehouseLocations(storageBoxes, sort.field, warehouseLocEnts, sort);
        } else if (sort.field === STANDARD_FIELDS.warehouseSubLocation.canonical_name) {
            return sortByWarehouseLocations(storageBoxes, sort.field, warehouseSubLocEnts, sort);
        } else {
            sortedIds = copyAndMultiSort(ids, storageBoxEnts, [{ key: sort.field }, { key: 'id' }], sort.order);
        }
    }
    return sortedIds.map(id => storageBoxEnts[id]);
});

export const getForAssetModal = createSelector(getAllStorageBoxes, root.getAssetModalSelectedWarehouseId, (storageBoxes, warehouseId) => {
    return warehouseId ? storageBoxes.filter(storageBox => storageBox.warehouse_id === warehouseId) : [];
});

export const getForBulkMoveModal = createSelector(getAllStorageBoxes, bulkMoveModalSelectors.getBulkMoveModalSelectedWarehouseId, (storageBoxes, warehouseId) => {
    return warehouseId ? storageBoxes.filter(storageBox => storageBox.warehouse_id === warehouseId) : [];
});

function sortByWarehouseLocations(storageBoxes: StorageBox[], field: string, ents: {}, sort: SortObject) {
    return storageBoxes.sort((a, b) => {
        const aName = ents[a[field]] ? ents[a[field]].name.toLowerCase() : '';
        const bName = ents[b[field]] ? ents[b[field]].name.toLowerCase() : '';
        if (sort.order > 0) {
            return aName.localeCompare(bName);
        } else {
            return bName.localeCompare(aName);
        }
    });
}
