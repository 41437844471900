import { Action } from '@ngrx/store';

export const SELECT_DIVISION = '[Bulk Move Modal] Select Division';
export const SELECT_LOCATION = '[Bulk Move Modal] Select Location';
export const SELECT_FRANCHISE = '[Bulk Move Modal] Select Franchise';
export const SELECT_PROJECT = '[Bulk Move Modal] Select Project';
export const SELECT_WAREHOUSE = '[Bulk Move Modal] Select Warehouse';
export const SELECT_WAREHOUSE_LOCATION = '[Bulk Move Modal] Select Warehouse Location';
export const SELECT_WAREHOUSE_SUB_LOCATION = '[Bulk Move Modal] Select Warehouse Sub Location';
export const SELECT_SUB_LOCATION = '[Bulk Move Modal] Select Sub Location';

export class SelectDivisionAction implements Action {
    readonly type = SELECT_DIVISION;
    constructor(public payload: number) { }
}

export class SelectLocationAction implements Action {
    readonly type = SELECT_LOCATION;
    constructor(public payload: number) { }
}

export class SelectFranchiseAction implements Action {
    readonly type = SELECT_FRANCHISE;
    constructor(public payload: number) { }
}

export class SelectProjectAction implements Action {
    readonly type = SELECT_PROJECT;
    constructor(public payload: number) { }
}

export class SelectWarehouseAction implements Action {
    readonly type = SELECT_WAREHOUSE;
    constructor(public payload: number) { }
}

export class SelectWarehouseLocationAction implements Action {
    readonly type = SELECT_WAREHOUSE_LOCATION;
    constructor(public payload: number) { }
}

export class SelectWarehouseSubLocationAction implements Action {
    readonly type = SELECT_WAREHOUSE_SUB_LOCATION;
    constructor(public payload: number) { }
}

export class SelectSubLocationAction implements Action {
    readonly type = SELECT_SUB_LOCATION;
    constructor(public payload: number) { }
}

export type Actions
    = SelectDivisionAction
    | SelectLocationAction
    | SelectFranchiseAction
    | SelectProjectAction
    | SelectWarehouseAction
    | SelectWarehouseLocationAction
    | SelectWarehouseSubLocationAction
    | SelectSubLocationAction;
