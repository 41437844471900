import { createSelector } from 'reselect';
import * as departmentActions from '../departments/department.actions';
import { SubDepartment } from '../models';
import { getIdsAndEntities, sortArray } from '../utils';
import * as subDepartmentActions from './sub-department.actions';

export interface State {
    ids: number[];
    entities: { [subDepartmentId: number]: SubDepartment };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: departmentActions.Actions | subDepartmentActions.Actions): State {
    switch (action.type) {
        case departmentActions.LIST_COMPLETE: {
            const departments = action.payload.departments;
            const subDepartments: SubDepartment[] = departments.reduce((subDepts, dept) => subDepts.concat(dept.subDepartments), []);
            const { ids, entities } = getIdsAndEntities(state, subDepartments);

            return { ...state, ids: [...state.ids, ...ids], entities: { ...state.entities, ...entities } };
        }

        case departmentActions.LIST_BY_FRANCHISE_COMPLETE: {
            const departments = action.payload;
            const subDepartments: SubDepartment[] = departments.reduce((subDepts, dept) => subDepts.concat(dept.subDepartments), []);
            const { ids, entities } = getIdsAndEntities(state, subDepartments);

            return { ...state, ids: [...state.ids, ...ids], entities: { ...state.entities, ...entities } };
        }

        case subDepartmentActions.ADD_COMPLETE: {
            const subDepartment = action.payload;

            return {
                ...state,
                ids: [...state.ids, subDepartment.id],
                entities: { ...state.entities, ...{ [subDepartment.id]: subDepartment } }
            };
        }

        case subDepartmentActions.UPDATE_COMPLETE: {
            const subDepartment = action.payload;

            return {
                ...state,
                entities: { ...state.entities, ...{ [subDepartment.id]: subDepartment } }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => sortArray(ids.map(id => entities[id]), 'code'));
