import { createSelector } from 'reselect';
import * as root from '../';
import { Set } from '../models';

function selectedAssetLikeSets(assetLike, sets) {
    return sets.filter(s =>
        s.projectSets.find(ps =>
            ps.project_id === assetLike.project_id
        )
    );
}

export const getSetsForSelectedProject = createSelector(root.getSetEntities, root.getProjectSets, root.getSidenavSettingsProjectIds, (setEntities, projectSets, projectIds) => {
    const projectId = projectIds[0];
    const pSetsForProject = projectSets.filter((projectSet) => projectSet.project_id === projectId);

    return pSetsForProject.map((projectSet) => Object.assign({}, setEntities[projectSet.set_id], { projectSets: [projectSet] }));
});

export const getSetsWithProjectSets = createSelector(root.getSets, root.getProjectSets, (sets, projectSets) => {
    return sets.map((set => {
        return Object.assign({}, set, {
            projectSets: projectSets.filter((pSet) => pSet.set_id === set.id)
        });
    }));
});

export const forSelectedAssetProject = createSelector(root.getSelectedAsset, root.getSets, selectedAssetLikeSets);

export const forSelectedInlineEditAssetProject = createSelector(root.getSelectedInlineEditAsset, root.getSets, selectedAssetLikeSets);

export const getSetsForSelectedTransactionProject = createSelector(root.getSetEntities, root.getProjectSets, root.getSelectedTransaction, (setEntities, projectSets, trans) => {
    const pSetsForProject = projectSets.filter((projectSet) => projectSet.project_id === trans.project_id);

    return pSetsForProject.map((projectSet) => Object.assign({}, setEntities[projectSet.set_id], { projectSets: [projectSet] }));
});

export const getForAssetModalSelectedProject = createSelector(root.getSetEntities, root.getProjectSets, root.getAssetModalSelectedProjectId, (setEntities, projectSets, projectId) => {
    const pSetsForProject = projectSets.filter((projectSet) => projectSet.project_id === projectId);

    return pSetsForProject.map((projectSet) => Object.assign({}, setEntities[projectSet.set_id], { projectSets: [projectSet] }));
});

export const getAssetBulkModalSetsForSelectedProject = createSelector(root.getSetEntities, root.getProjectSets, root.getSidenavAssetProjectIds, root.getSelectedFranchiseProjects, (setEntities, projectSets, projectIds, franchiseProjects) => {
    let pSetsForProject;
    if (franchiseProjects.length === 1) {
        pSetsForProject = projectSets.filter((projectSet) => franchiseProjects[0].id === projectSet.project_id);
    } else {
        pSetsForProject = projectSets.filter((projectSet) => projectIds.includes(projectSet.project_id));
    }

    return pSetsForProject.map(projectSet => new Set({ ...setEntities[projectSet.set_id], projectSets: [projectSet] }));
});

export const getTransactionBulkModalSetsForSelectedProject = createSelector(root.getSetEntities, root.getProjectSets, root.getSidenavTransactionProjectIds, (setEntities, projectSets, projectIds) => {
    const pSetsForProject = projectSets.filter((projectSet) => projectIds.includes(projectSet.project_id));

    return pSetsForProject.map(projectSet => new Set({ ...setEntities[projectSet.set_id], projectSets: [projectSet] }));
});
