import { Action } from '@ngrx/store';
import { SortObject, TableFilterObject } from '../types';

export const SORT_FRANCHISE_TRASHED_LIST = '[Franchise Asset Index] Sort Franchise Trashed Asset List';
export const FILTER_FRANCHISE_TRASHED_LIST = '[Franchise Asset Index] Filter Franchise Trashed List';

export class SortFranchiseTrashedAssetsAction implements Action {
    readonly type = SORT_FRANCHISE_TRASHED_LIST;

    constructor(public payload: { franchiseId: number, sort: SortObject }) { }
}

export class FilterFranchiseTrashedAssetsAction implements Action {
    readonly type = FILTER_FRANCHISE_TRASHED_LIST;

    constructor(public payload: { franchiseId: number, filter: TableFilterObject }) { }
}

export type Actions
    = SortFranchiseTrashedAssetsAction
    | FilterFranchiseTrashedAssetsAction;
