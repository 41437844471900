import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import * as assetModalActions from '../../core/store/asset-modal/asset-modal.actions';
import * as studioActions from '../../core/store/studios/studio.actions';
import { ActionStatus } from '../../core/store/types';
import { User } from '../../core/store/users/user.model';
import * as franchiseActions from './../../core/store/franchises/franchise.actions';

@Injectable()
export class FranchiseDataService {
    constructor(private store: Store<fromRoot.State>) { }

    getAllFranchises() {
        let dispatched = false;
        const combined = combineLatest([this.store.select(fromRoot.getSelectedStudio), this.store.select(fromRoot.getAllFranchises), this.store.select(fromRoot.getFranchiseListing)]);
        return combined.pipe(filter(([studio, allFranchises, listing]) => {
            if (!dispatched) {
                dispatched = true;
                this.store.dispatch(new franchiseActions.ListAction({ studioId: studio.id, onlyOwnerOptions: false }));
                return false;
            }
            if (listing === ActionStatus.Loading) {
                return false;
            }
            return true;
        }), map(([_, allFranchises]) => {
            return allFranchises;
        }), take(1));
    }

    getSelectedFranchise(route: ActivatedRouteSnapshot) {
        const paramFranchiseId = parseInt(route.params.franchiseId, 10);

        return combineLatest([this.store.select(fromRoot.getUser), this.store.select(fromRoot.getSelectedStudio), this.store.select(fromRoot.getSelectedFranchise)]).pipe(filter(([user, studio, franchise]) => {
            if (!franchise || franchise.id !== paramFranchiseId) {
                this.store.dispatch(new franchiseActions.SelectAction(paramFranchiseId));
                return false;
            }

            if (!studio || studio.id !== franchise.studio_id) {
                this.store.dispatch(new studioActions.SelectAction(franchise.studio_id));
                return false;
            }

            return true;
        }),
            map(([user, _, franchise]) => {
                if (franchise.id !== user.current_franchise_id || franchise.studio_id !== user.current_studio_id) {
                    const userCopy = new User(user);
                    userCopy.current_studio_id = franchise.studio_id;
                    userCopy.current_franchise_id = franchise.id;
                    userCopy.current_division_id = null;
                    this.store.dispatch(new assetModalActions.SelectDivisionAction(null));
                    this.store.dispatch(new assetModalActions.SelectWarehouseAction(null));
                    this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(null));
                    this.store.dispatch(new assetModalActions.SelectWarehouseSubLocationAction(null));
                }

                return franchise;
            }),
            take(1));
    }

    waitForFranchises() {
        return this.store.select(fromRoot.getAllFranchises).pipe(
            filter((franchises) => franchises !== null),
            take(1));
    }
}
