import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as matchFieldActions from './match-field.actions';
import { MatchField } from './match-field.model';

@Injectable()
export class MatchFieldEffects {
    list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(matchFieldActions.LIST),
            map((action: matchFieldActions.ListAction) => action.payload),
            switchMap(franchiseId => this.http.get<MatchField[]>(`/api/franchises/${franchiseId}/match-fields`).pipe(
                map(res => {
                    const response = res;
                    return response.map(s => new MatchField(s));
                }),
                map((matchFields: MatchField[]) => new matchFieldActions.ListCompleteAction(matchFields)),
                catchError(error => observableOf(new matchFieldActions.ListFailedAction({ error }))))
            )));

    updateMultiple$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(matchFieldActions.UPDATE_MULTIPLE),
            map((action: matchFieldActions.UpdateMultipleAction) => action.payload),
            mergeMap((data) => this.http.put<MatchField[]>(`/api/franchises/${data.franchiseId}/match-fields`, data.matchFields).pipe(
                map(res => res.map((mf) => new MatchField(mf))),
                map((matchFields: MatchField[]) => new matchFieldActions.UpdateMultipleCompleteAction(matchFields)),
                catchError((error) => observableOf(new matchFieldActions.UpdateMultipleFailedAction({ error }))))
            )));

    update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(matchFieldActions.UPDATE),
            map((action: matchFieldActions.UpdateAction) => action.payload),
            mergeMap((data) => this.http.put(`/api/match-fields/${data.id}`, data).pipe(
                map(res => new MatchField(res)),
                map((matchField: MatchField) => new matchFieldActions.UpdateCompleteAction(matchField)),
                catchError((error) => observableOf(new matchFieldActions.UpdateFailedAction({ error }))))
            )));

    failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(matchFieldActions.LIST_FAILED, matchFieldActions.UPDATE_FAILED, matchFieldActions.UPDATE_MULTIPLE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
