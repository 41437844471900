import { Pipe, PipeTransform } from '@angular/core';
import { Asset } from '../../core/store/assets/asset.model';

@Pipe({ name: 'assetFilter' })
export class SidenavFilterAssetPipe implements PipeTransform {
    transform(assets: Asset[], departmentIdList?: number[], projectIdList?: number[], warehouseIdList?: number[], modelIdList?: number[], modelRelatedIdName?: string) {
        if (assets) {
            const filteredAssets = assets.slice();
            const filterByProj = projectIdList && projectIdList.length;
            const filterByDept = departmentIdList && departmentIdList.length;
            const filterByWarehouse = warehouseIdList && warehouseIdList.length;
            const filterByRelated = modelRelatedIdName && modelIdList && modelIdList.length;

            if (filterByProj || filterByDept || filterByWarehouse || filterByRelated) {
                return filteredAssets.filter(asset => {
                    if (filterByProj) {
                        if (!projectIdList.includes(asset.project_id)) {
                            return false;
                        }
                    }
                    if (filterByDept) {
                        if (!departmentIdList.includes(asset.department_id)) {
                            return false;
                        }
                    }
                    if (filterByWarehouse) {
                        if (!warehouseIdList.includes(asset.warehouse_id)) {
                            return false;
                        }
                    }
                    if (filterByRelated) {
                        if (!modelIdList.includes(asset[modelRelatedIdName])) {
                            return false;
                        }
                    }
                    return true;
                });
            } else {
                return filteredAssets;
            }

        }
    }
}
