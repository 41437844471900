import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as statusActions from './status.actions';
import { Status } from './status.model';

@Injectable()
export class StatusEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(statusActions.LIST),
            map((action: statusActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<Status[]>(`/api/studios/${studioId}/statuses`).pipe(
                map(res => res.map((u) => new Status(u))),
                map((statuses: Status[]) => new statusActions.ListCompleteAction(statuses)),
                catchError((error) => observableOf(new statusActions.ListFailedAction({ error }))))
            )));

     listFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(statusActions.LIST_FAILED),
            map((action: statusActions.ListFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
