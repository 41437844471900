import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import { Request, RequestAsset } from '../models';
import * as requestAssetActions from './request-asset.actions';

@Injectable()
export class RequestAssetEffects {

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestAssetActions.DELETE),
            map((action: requestAssetActions.DeleteAction) =>
                action.payload),
            switchMap((data) => this.http.put(`/api/request/${data.request_id}/delete-request-assets`, { assetIds: data.assetIds }).pipe(
                map(() => new requestAssetActions.DeleteCompleteAction({ requestId: data.request_id, assetIds: data.assetIds })),
                catchError((error) => observableOf(new requestAssetActions.DeleteFailedAction({ error }))))
            )));

     listConflicts$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestAssetActions.LIST_CONFLICTS),
            map((action: requestAssetActions.ListConflictsAction) => action.payload),
            switchMap((data) => this.http.get(`/api/request/${data.request_id}/request-asset/${data.request_asset_id}/conflicts`).pipe(
                map((res: { requests: Request[] }) => new requestAssetActions.ListConflictsCompleteAction({ requestAssetId: data.request_asset_id, requests: res.requests ? res.requests.map(r => new Request(r)) : [] })),
                catchError((error) => observableOf(new requestAssetActions.ListConflictsFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestAssetActions.UPDATE),
            map((action: requestAssetActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`/api/request/${data.request_id}/approve/${data.approve}`, { request_asset_ids: data.request_asset_ids }).pipe(
                map((res: RequestAsset[]) => new requestAssetActions.UpdateCompleteAction({ requestId: data.request_id, requestAssets: res ? res.map(a => new RequestAsset(a)) : [] })),
                catchError((error) => observableOf(new requestAssetActions.UpdateFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(requestAssetActions.DELETE_FAILED, requestAssetActions.LIST_CONFLICTS_FAILED, requestAssetActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
