import { Moment, sosMoment } from '../../../shared/services/moment.service';
import { BaseModel } from './../base/base.model';

export class AssetHistory extends BaseModel {
    id: number;
    asset_id: number = null;
    user_id: number = null;
    activity_type: number = null;
    prev_value?: any = null;
    new_value: any = null;
    note: string = null;
    user_name: string = null;
    updated_at: string = null;

    moment: Moment;

    date: string;

    constructor(assetHistoryObj: any = {}) {
        super();
        this.initializeData(assetHistoryObj, this);
        this.moment = sosMoment;
        this.date = this.moment.utc(this.updated_at).format('DD MMM. YYYY');
    }
}

export const AssetHistoryObjectMock = {
    id: 1,
    asset_id: 1,
};
