import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as assetEventActions from './asset-event.actions';
import { AssetEvent } from './asset-event.model';

@Injectable()
export class AssetEventEffects {
    updateMultiple$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetEventActions.UPDATE_MULTIPLE),
            map((action: assetEventActions.UpdateMultipleAction) => action.payload),
            mergeMap((data) => this.http.put<AssetEvent[]>(`/api/asset-events/${data.event_id}`, data.assetEvents).pipe(
                map(res => res.map((ae) => new AssetEvent(ae))),
                map((assetEvents: AssetEvent[]) => new assetEventActions.UpdateMultipleCompleteAction(assetEvents)),
                catchError((error) => observableOf(new assetEventActions.UpdateMultipleFailedAction({ error }))))
            )));

    listQuantityOnHand$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetEventActions.LIST_QUANTITY_ON_HAND),
            map((action: assetEventActions.ListQuantityOnHandAction) => action.payload),
            switchMap((data) => this.http.post(`/api/asset-events/divisions/${data.division_id}/quantity-on-hand`, data.asset_ids).pipe(
                map((quantityOnHandForAssetIds: { [assetId: number]: number }) => new assetEventActions.ListQuantityOnHandCompleteAction({ quantityOnHandForAssetIds })),
                catchError((error) => observableOf(new assetEventActions.ListQuantityOnHandFailedAction({ error }))))
            )));

    failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetEventActions.UPDATE_MULTIPLE_FAILED, assetEventActions.LIST_QUANTITY_ON_HAND_FAILED, assetEventActions.ADD_GROUP_ASSETS_TO_EVENT_FAILED, assetEventActions.ADD_SUBGROUP_ASSETS_TO_EVENT_FAILED, assetEventActions.TRANSFER_ASSETS_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    transferMultiple$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetEventActions.TRANSFER_ASSETS),
            map((action: assetEventActions.TransferAssetsAction) => action.payload),
            mergeMap((data) => this.http.put<AssetEvent[]>(`/api/asset-events/${data.event_id}/transfer`, data.assetEvents).pipe(
                map(res => res.map((ae) => new AssetEvent(ae))),
                map((assetEvents: AssetEvent[]) => new assetEventActions.TransferAssetsCompleteAction({ assets: assetEvents })),
                catchError((error) => observableOf(new assetEventActions.TransferAssetsFailedAction({ error }))))
            )));

    addGroupAssetsToEvent$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetEventActions.ADD_GROUP_ASSETS_TO_EVENT),
            map((action: assetEventActions.AddGroupAssetsToEvent) => action.payload),
            mergeMap((data) => this.http.get(`/api/asset-events/${data.eventId}/add-group-assets/${data.groupId}`).pipe(
                map((res) => new assetEventActions.AddGroupAssetsToEventComplete({ groupId: res["groupId"], count: res["count"], event: res["event"], ids: res["ids"], existingAssetsCount: res["existingAssetsCount"] })),
                catchError((error) => observableOf(new assetEventActions.AddGroupAssetsToEventFailed({ error }))))
            )));
    addSubGroupAssetsToEvent$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetEventActions.ADD_SUBGROUP_ASSETS_TO_EVENT),
            map((action: assetEventActions.AddSubGroupAssetsToEvent) => action.payload),
            mergeMap((data) => this.http.get(`/api/asset-events/${data.eventId}/add-subgroup-assets/${data.subGroupId}`).pipe(
                map((res) => new assetEventActions.AddSubGroupAssetsToEventComplete({ subGroupId: res["subGroupId"], count: res["count"], event: res["event"], ids: res["ids"], existingAssetsCount: res["existingAssetsCount"] })),
                catchError((error) => observableOf(new assetEventActions.AddSubGroupAssetsToEventFailed({ error }))))
            )));


    constructor(private actions$: Actions, private http: HttpClient) { }
}
