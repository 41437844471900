import { Action } from '@ngrx/store';
import { SortObject, TableFilterObject } from '../types';

export const SORT_DIVISION_TRASHED_LIST = '[Division Asset Index] Sort Division Trashed Asset List';
export const FILTER_DIVISION_TRASHED_LIST = '[Division Asset Index] Filter Division Trashed List';

export class SortDivisionTrashedAssetsAction implements Action {
    readonly type = SORT_DIVISION_TRASHED_LIST;

    constructor(public payload: { divisionId: number, sort: SortObject }) { }
}

export class FilterDivisionTrashedAssetsAction implements Action {
    readonly type = FILTER_DIVISION_TRASHED_LIST;

    constructor(public payload: { divisionId: number, filter: TableFilterObject }) { }
}

export type Actions
    = SortDivisionTrashedAssetsAction
    | FilterDivisionTrashedAssetsAction;
