import { createSelector } from 'reselect';
import * as subLocationActions from '../sub-locations/sub-location.actions';
import { ActionState, ActionStatus } from '../types';
import { addValueToObjectArray, copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as locationActions from './location.actions';
import { Location } from './location.model';

export interface State {
    ids: number[];
    idsByFranchise: { [franchiseId: number]: number[] };
    entities: {
        [locationId: number]: Location
    };
    saving: ActionStatus;
    selectedId: number;
    subLocationIds: {
        [locationId: number]: number[]
    };
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    idsByFranchise: {},
    entities: {},
    saving: ActionStatus.Complete,
    selectedId: null,
    subLocationIds: {},
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: locationActions.Actions | subLocationActions.Actions): State {
    switch (action.type) {
        case locationActions.LIST:
        case locationActions.LIST_BY_STUDIO: {
            return { ...state, ids: [], idsByFranchise: {}, entities: {} };
        }

        case locationActions.ADD_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, [action.payload]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            const idsByFranchise = { ...state.idsByFranchise };
            addValueToObjectArray(idsByFranchise, action.payload.franchise_id, action.payload.id);

            return {
                ...state,
                ids: sortedIds,
                idsByFranchise,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case locationActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case locationActions.DELETE_COMPLETE: {
            const locationId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            const subLocationIdsCopy = { ...state.subLocationIds };
            delete entitiesCopy[locationId];
            delete subLocationIdsCopy[locationId];

            const idsByFranchise = { ...state.idsByFranchise };
            idsByFranchise[action.payload.franchise_id] = idsByFranchise[action.payload.franchise_id].filter(id => id !== locationId);

            let selectedId = state.selectedId;
            if (selectedId === locationId) {
                selectedId = null;
            }
            return {
                ...state,
                ids: state.ids.filter(id => id !== locationId),
                idsByFranchise,
                entities: entitiesCopy,
                selectedId,
                subLocationIds: subLocationIdsCopy,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case locationActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case locationActions.LIST_COMPLETE:
        case locationActions.LIST_BY_STUDIO_COMPLETE: {
            const locations = action.payload;
            const { ids, entities } = getIdsAndEntities(state, locations);
            const idsByFranchise = { ...state.idsByFranchise };

            const subLocationIds = {};
            locations.forEach(loc => {
                addValueToObjectArray(idsByFranchise, loc.franchise_id, loc.id);

                if (loc.subLocations) {
                    const sls = {};
                    const slIds = loc.subLocations.map(subLoc => {
                        sls[subLoc.id] = subLoc;
                        return subLoc.id;
                    });
                    subLocationIds[loc.id] = copyAndMultiSort(slIds, sls, [{ key: 'name' }], 1);
                }
            });

            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            for (const franchiseId in idsByFranchise) {
                if (idsByFranchise.hasOwnProperty(franchiseId)) {
                    const locIds = idsByFranchise[franchiseId];
                    idsByFranchise[franchiseId] = copyAndMultiSort(locIds, allEnts, [{ key: 'name' }], 1);
                }
            }

            return {
                ...state,
                ids: sortedIds,
                idsByFranchise,
                entities: allEnts,
                subLocationIds: {
                    ...state.subLocationIds,
                    ...subLocationIds
                }
            };
        }

        case locationActions.ADD:
        case locationActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case locationActions.ADD_FAILED:
        case locationActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case locationActions.UPDATE_COMPLETE: {
            const location = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [location]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case locationActions.SELECT: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case subLocationActions.ADD_COMPLETE: {
            const subLocation = action.payload;

            const ids = state.subLocationIds[subLocation.location_id] ? state.subLocationIds[subLocation.location_id] : [];
            if (ids.indexOf(subLocation.id) > -1) {
                return state;
            }

            return {
                ...state,
                subLocationIds: {
                    ...state.subLocationIds,
                    [subLocation.location_id]: [...ids, subLocation.id]
                }
            };
        }

        case subLocationActions.DELETE_COMPLETE: {
            const subLocation = action.payload;

            const ids = [...state.subLocationIds[subLocation.location_id]];
            ids.splice(ids.indexOf(action.payload.id), 1);

            return {
                ...state,
                subLocationIds: {
                    ...state.subLocationIds,
                    [subLocation.location_id]: ids
                }
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getIdsByFranchise = (state: State) => state.idsByFranchise;
export const getLocationSubLocationIds = (state: State) => state.subLocationIds;
const getSelectedId = (state: State) => state.selectedId;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getSaving = (state: State) => state.saving;
export const getSelected = createSelector(getEntities, getSelectedId, (entities, id) => entities[id]);
export const getDeleteState = (state: State) => state.deleteState;
