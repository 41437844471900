import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as franchiseGroupActions from './franchise-group.actions';

@Injectable()
export class FranchiseGroupEffects {
    static BASE_URL = '/api/franchise-groups';

     addToFranchiseGroup$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseGroupActions.ADD_TO_FRANCHISE_GROUP),
            map((action: franchiseGroupActions.AddToFranchiseGroupAction) => action.payload),
            mergeMap(({ groupId, franchiseId, assetIds }) => this.http.put(`/api/groups/${groupId}/assets`, { franchise_id: franchiseId, assetIds }).pipe(
                map((res: number[]) => new franchiseGroupActions.AddToFranchiseGroupCompleteAction({ groupId, assetIds: res })),
                catchError((error) => observableOf(new franchiseGroupActions.AddToFranchiseGroupFailedAction({ error }))))
            )));

     deleteFromFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseGroupActions.DELETE_FROM_FRANCHISE),
            map((action: franchiseGroupActions.DeleteFromFranchiseAction) => action.payload),
            mergeMap(({ groupId, franchiseId }) => this.http.delete(`/api/groups/${groupId}?franchise_id=${franchiseId}`).pipe(
                map((res: { groupId: number, assetIds: number[] }) => new franchiseGroupActions.DeleteFromFranchiseCompleteAction({ groupId: res.groupId, franchiseId, assetIds: res.assetIds })),
                catchError((error) => observableOf(new franchiseGroupActions.DeleteFromFranchiseFailedAction({ error }))))
            )));

     getAssetCount$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseGroupActions.GET_ASSET_COUNT),
            map((action: franchiseGroupActions.GetAssetCountAction) => action.payload),
            switchMap(({ franchiseId, groupId }) => this.http.get<number>(`/api/franchises/${franchiseId}/groups/${groupId}/asset-count`).pipe(
                map((res) => new franchiseGroupActions.GetAssetCountCompleteAction(res)),
                catchError((error) => observableOf(new franchiseGroupActions.GetAssetCountFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseGroupActions.ADD_TO_FRANCHISE_GROUP_FAILED, franchiseGroupActions.GET_ASSET_COUNT_FAILED, franchiseGroupActions.DELETE_FROM_FRANCHISE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
