import { createSelector } from 'reselect';

import * as root from '../';

export const getUnusedInSelectedDepartment = createSelector(root.getSelectedDepartment, root.getSosFields, (dept, fields) => {
    return fields.filter(field => {
        const used = !!dept.departmentFields.find(df => df.sos_field_id === field.id);
        return field.sos_department_id === dept.sos_department_id && !used;
    });
});
