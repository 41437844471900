import { Moment } from '../../../shared/services/moment.service';
import { Address } from '../addresses/address.model';
import { BaseModel } from './../base/base.model';

// To display Customer info separately on Event Edit Details and Event View
export const CustomerFields = [
    { name: 'Customer Name', canonical_name: 'customer_name' },
    { name: 'Customer #', canonical_name: 'customer_number' }
];
export const ContactFields = [
    { name: 'Contact Name', canonical_name: 'contact_name' },
    { name: 'Contact Phone', canonical_name: 'contact_phone' }
];

export class Customer extends BaseModel {
    id: number;
    division_id: number = null;
    address_id: number = null;
    customer_name: string = null;
    customer_number: string = null;
    contact_name: string = null;
    contact_phone: string = null;
    contact_email: string = null;
    note: string = null;
    address?: Address = null;
    updated_at: Moment | Date = null;

    constructor(customerObject) {
        super();
        this.initializeData(customerObject, this);

        if (customerObject.address) {
            this.address = new Address(customerObject.address);
        } else {
            this.address = new Address({ customer_id: this.id });
        }
    }

    getDisplayName() {
        if (this.customer_name && this.contact_name) {
            return `${this.customer_name} - ${this.contact_name}`;
        }
        if (this.customer_name && !this.contact_name) {
            return `${this.customer_name}`;
        }
        if (!this.customer_name && this.contact_name) {
            return this.contact_name;
        }
        return '';
    }
}
