import { User } from '../users/user.model';
import { BaseModel } from './../base/base.model';

export class FinalApproverRequest extends BaseModel {
    id: number;
    request_id: number = null;
    user_id: number = null;
    confirmed: boolean = null;
    created_by: number = null;
    userName: string = null;
    createdByName: string = null;

    constructor(finalApproverRequestObject) {
        super();
        this.initializeData(finalApproverRequestObject, this);
    }

    displayCreatedByName(userEnts: { [id: number]: User }) {
        return `${userEnts[this.created_by].first_name} ${userEnts[this.created_by].last_name}`;
    }
}
