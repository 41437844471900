import { BaseModel } from './../base/base.model';

export class Studio extends BaseModel {
    id: number;
    name: string = null;
    banner_url: string = null;
    franchise_hidden = false;
    series_hidden = false;
    transactions_hidden = false;
    approvals_enabled = false;
    final_reviewers_enabled = false;
    advanced_permissions_enabled = false;
    read_only = false;
    smart_id_mfa_enabled: boolean = null;

    constructor(studioObject) {
        super();
        this.initializeData(studioObject, this);
    }
}

export const studioObjectMock = {
    id: 1,
    name: 'Warner Sisters',
};
