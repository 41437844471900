import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EVENT_FIELD_DB_LOCATIONS } from '../../../../../shared/enums/event-field-db-locations.enum';
import { EVENT_FIELD_DISPLAY_TYPES } from '../../../../../shared/enums/event-field-display-types.enum';
import { FIELD_TYPES } from '../../../../../shared/fields/field-types';
import { Address, DivisionEventField } from '../models';
import { BaseField } from './../base/base-field.model';

export interface EventPicklistOption {
    id: number;
    value: string;
}

export interface EventFieldWithDivisionEventField {
    divisionEventField: DivisionEventField;
    eventField: EventField;
}

export class EventField extends BaseField {
    id: number;
    studio_id: number = null;
    canonical_name = '';
    name: string = null;
    required_locked: boolean = null;
    delete_locked: boolean = null;
    sort_order: number = null;
    display_type: EVENT_FIELD_DISPLAY_TYPES = null;
    db_location: EVENT_FIELD_DB_LOCATIONS = null;
    eventPicklistOptions: EventPicklistOption[] = null;

    constructor(fieldObj) {
        super();
        this.initializeData(fieldObj, this);
    }

    getFormControl(required: boolean, value?: any): UntypedFormControl | UntypedFormGroup {
        if (this.type === FIELD_TYPES.ADDRESS.type) {
            return Address.getFormGroup(value);
        } else if (this.type === FIELD_TYPES.SHIPMENT.type) {
            const validators = [Validators.maxLength(255)];
            return new UntypedFormGroup({
                shipping_date: new UntypedFormControl(value ? value.shipping_date : null),
                expected_date: new UntypedFormControl(value ? value.expected_date : null),
                actual_date: new UntypedFormControl(value ? value.actual_date : null),
                shipping_company: new UntypedFormControl(value ? value.shipping_company : null, validators),
                tracking_number: new UntypedFormControl(value ? value.tracking_number : null, validators),
                receiver_name: new UntypedFormControl(value ? value.receiver_name : null, validators),
                receiver_phone: new UntypedFormControl(value ? value.receiver_phone : null, validators)
            });
        }
        return super.getFormControl(required, value);
    }

    getValueFromEvent(event) {
        if (this.type === FIELD_TYPES.DATE.type) {
            if (event[this.canonical_name]) {
                return new Date(event[this.canonical_name]);
            } else if (event.attributes && event.attributes[this.canonical_name]) {
                return new Date(event.attributes[this.canonical_name]);
            }
            return null;
        } else if (this.type === FIELD_TYPES.CHECKBOX.type) {
            // never let a checkbox be null, that's not a thing a checkbox should be
            if (event[this.canonical_name]) {
                return !!event[this.canonical_name];
            } else if (event.attributes && event.attributes[this.canonical_name]) {
                return !!event.attributes[this.canonical_name];
            } else {
                return false;
            }
        }
        if (event[this.canonical_name]) {
            return event[this.canonical_name];
        }
        if (event.attributes && event.attributes[this.canonical_name]) {
            return event.attributes[this.canonical_name];
        }
        return null;
    }
}
