import { Action } from '@ngrx/store';
import { SubGroup } from '../models';

export const SELECT_SUB_GROUP = '[SubGroup] Select Sub Group';

export const ADD = '[SubGroup] Add Sub Group';
export const ADD_COMPLETE = '[SubGroup] Add Sub Group Complete';
export const ADD_FAILED = '[SubGroup] Add Sub Group Failed';

export const GET = '[SubGroup] Get Group';
export const GET_COMPLETE = '[SubGroup] Get Group Complete';
export const GET_FAILED = '[SubGroup] Get Group Failed';

export const DELETE = '[SubGroup] Delete Sub Group from Studio';
export const DELETE_COMPLETE = '[SubGroup] Delete Sub Group from Studio Complete';
export const DELETE_FAILED = '[SubGroup] Delete Sub Group from Studio Failed';

export const UPDATE = '[SubGroup] Update';
export const UPDATE_COMPLETE = '[SubGroup] Update Complete';
export const UPDATE_FAILED = '[SubGroup] Update Failed';

export const LIST = '[SubGroup] List By Studio and Group Type';
export const LIST_COMPLETE = '[SubGroup] List By Studio and Group Type Complete';
export const LIST_FAILED = '[SubGroup] List By Studio and Group Type Failed';

export const GET_ASSET_COUNT = '[SubGroup] Get Studio Asset Count';
export const GET_ASSET_COUNT_COMPLETE = '[SubGroup] Get Studio Asset Count Complete';
export const GET_ASSET_COUNT_FAILED = '[SubGroup] Get Studio Asset Count Failed';

export const RESET_ADDING = '[SubGroup] Reset Adding to Inactive';

export const GET_NAMES = '[SubGroup] Get Names';
export const GET_NAMES_COMPLETE = '[SubGroup] Get Names Complete';
export const GET_NAMES_FAILED = '[SubGroup] Get Names Failed';

export class SelectSubGroupAction implements Action {
    readonly type = SELECT_SUB_GROUP;
    constructor(public payload: number) { }
}
export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { subGroup: SubGroup, attachments?: File[], types?: string[] }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: SubGroup) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: any) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: SubGroup) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: SubGroup) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: { subGroup: SubGroup }) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: any) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: SubGroup[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: any) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: { id: number, franchiseId?: number, divisionId?: number }) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: number) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}
export class GetAssetCountAction implements Action {
    readonly type = GET_ASSET_COUNT;

    constructor(public payload: { studioId: number, subGroupId: number }) { }
}
export class GetAssetCountCompleteAction implements Action {
    readonly type = GET_ASSET_COUNT_COMPLETE;

    constructor(public payload: { subGroupId: number, assetCount: number }) { }
}
export class GetAssetCountFailedAction implements Action {
    readonly type = GET_ASSET_COUNT_FAILED;

    constructor(public payload: any) { }
}

export class ResetAction implements Action {
    readonly type = RESET_ADDING;

    constructor() { }
}

export class GetAssetSubGroupsNamesAction implements Action {
    readonly type = GET_NAMES;

    constructor(public payload: { subgroupIds: number[] }) { }
}
export class GetAssetSubGroupsNamesCompleteAction implements Action {
    readonly type = GET_NAMES_COMPLETE;

    constructor(public payload: any) { }
}
export class GetAssetSubGroupsNamesFailedAction implements Action {
    readonly type = GET_NAMES_FAILED;

    constructor(public payload: any) { }
}



export type Actions
    = GetAction
    | GetCompleteAction
    | GetFailedAction
    | AddAction
    | AddCompleteAction
    | AddFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | SelectSubGroupAction
    | GetAssetCountAction
    | GetAssetCountCompleteAction
    | GetAssetCountFailedAction
    | ResetAction
    | GetAssetSubGroupsNamesAction
    | GetAssetSubGroupsNamesCompleteAction
    | GetAssetSubGroupsNamesFailedAction;
