import { createSelector } from 'reselect';
import { SortObject } from '../types';
import { addValuesToObjectArray, addValueToObjectArray, copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as assetActions from './../assets/asset.actions';
import { Asset } from './../assets/asset.model';
import * as eventActions from './../events/event.actions';
import * as groupActions from './../groups/group.actions';
import * as subGroupActions from './../sub-groups/sub-group.actions';
import * as attachmentActions from './attachment.actions';
import { Attachment } from './attachment.model';

export interface State {
    ids: number[];
    entities: { [attachmentId: number]: Attachment };
    idsForAsset: { [assetId: number]: number[] };
    idsForEvent: { [eventId: number]: number[] };
    idsForGroup: { [groupId: number]: number[] };
    idsForSubGroup: { [subGroupId: number]: number[] };
    loading: boolean;
    assetSort: { [assetId: number]: SortObject; };
    eventSort: { [eventId: number]: SortObject; };
    groupSort: { [groupId: number]: SortObject; };
    subGroupSort: { [subGroupId: number]: SortObject; };
}

export const initialState: State = {
    ids: [],
    entities: {},
    idsForAsset: {},
    idsForEvent: {},
    idsForGroup: {},
    idsForSubGroup: {},
    loading: false,
    eventSort: {},
    assetSort: {},
    groupSort: {},
    subGroupSort: {}
};

const defaultSort = {
    field: 'id',
    order: 1,
    args: []
};

export function reducer(state = initialState, action: attachmentActions.Actions | assetActions.Actions | eventActions.Actions | groupActions.Actions | subGroupActions.Actions): State {
    switch (action.type) {


        case assetActions.MERGE_ASSETS_COMPLETE: {
            const asset: Asset = action.payload.asset;
            if (asset.attachments) {
                const idsForAsset = state.idsForAsset[asset.id] ? state.idsForAsset[asset.id] : [];
                const newIds: number[] = [];
                const entries = asset.attachments;
                entries.forEach(entry => {
                    if (!idsForAsset.includes(entry.id)) {
                        newIds.push(entry.id);
                    }
                });
                const { entities } = getIdsAndEntities(state, entries);
                const sortedIds = copyAndMultiSort([...idsForAsset, ...newIds], entities, [{ key: defaultSort.field }], defaultSort.order, defaultSort.args);

                return {
                    ...state,
                    idsForAsset: { ...state.idsForAsset, [asset.id]: sortedIds },
                    assetSort: { ...state.assetSort, [asset.id]: defaultSort }
                };
            }
            return state;
        }

        case eventActions.GET_COMPLETE: {
            const event = action.payload.event;
            if (event.attachments) {
                const idsForEvent = state.idsForEvent[event.id] ? state.idsForEvent[event.id] : [];
                const entries = event.attachments;
                const { ids, entities } = getIdsAndEntities(state, entries);
                const sortedIds = copyAndMultiSort([...idsForEvent, ...ids], entities, [{ key: defaultSort.field }], defaultSort.order, defaultSort.args);
                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: {
                        ...state.entities,
                        ...entities,
                    },
                    idsForEvent: { ...state.idsForEvent, [event.id]: sortedIds },
                    eventSort: { ...state.eventSort, [event.id]: defaultSort }
                };
            }
            return state;
        }

        case groupActions.GET_COMPLETE: {
            const group = action.payload.group;
            if (group.attachments) {
                const idsForGroup = state.idsForGroup[group.id] ? state.idsForGroup[group.id] : [];
                const entries = group.attachments;
                const { ids, entities } = getIdsAndEntities(state, entries);
                const sortedIds = copyAndMultiSort([...idsForGroup, ...ids], entities, [{ key: defaultSort.field }], defaultSort.order, defaultSort.args);
                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: {
                        ...state.entities,
                        ...entities,
                    },
                    idsForGroup: { ...state.idsForGroup, [group.id]: sortedIds },
                    groupSort: { ...state.groupSort, [group.id]: defaultSort }
                };
            }
            return state;
        }

        case subGroupActions.GET_COMPLETE: {
            const subGroup = action.payload.subGroup;
            if (subGroup.attachments) {
                const idsForSubGroup = state.idsForSubGroup[subGroup.id] ? state.idsForSubGroup[subGroup.id] : [];
                const entries = subGroup.attachments;
                const { ids, entities } = getIdsAndEntities(state, entries);
                const sortedIds = copyAndMultiSort([...idsForSubGroup, ...ids], entities, [{ key: defaultSort.field }], defaultSort.order, defaultSort.args);
                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: {
                        ...state.entities,
                        ...entities,
                    },
                    idsForSubGroup: { ...state.idsForSubGroup, [subGroup.id]: sortedIds },
                    subGroupSort: { ...state.subGroupSort, [subGroup.id]: defaultSort }
                };
            }
            return state;
        }

        case attachmentActions.DELETE_ATTACHMENT_COMPLETE: {
            const attachmentId = action.payload.id;

            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[attachmentId];

            const idsForAsset = { ...state.idsForAsset };
            idsForAsset[action.payload.owner_id] = idsForAsset[action.payload.owner_id].filter(id => id !== attachmentId);

            return {
                ...state,
                entities: entitiesCopy,
                ids: state.ids.filter(id => id !== attachmentId),
                idsForAsset
            };
        }

        case attachmentActions.DELETE_EVENT_ATTACHMENT_COMPLETE: {
            const attachmentId = action.payload.id;

            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[attachmentId];

            const idsForEvent = { ...state.idsForEvent };
            idsForEvent[action.payload.owner_id] = idsForEvent[action.payload.owner_id].filter(id => id !== attachmentId);

            return {
                ...state,
                entities: entitiesCopy,
                ids: state.ids.filter(id => id !== attachmentId),
                idsForEvent
            };
        }

        case attachmentActions.ADD_ATTACHMENT_COMPLETE: {
            const newAttachment = new Attachment(action.payload.attachment);
            newAttachment.owner_id = action.payload.asset_id;
            const newIds = state.ids.slice();
            newIds.push(newAttachment.id);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...{ [newAttachment.id]: newAttachment }
                },
                ids: newIds,
                idsForAsset: addValueToObjectArray({ ...state.idsForAsset }, action.payload.asset_id, newAttachment.id)
            };
        }

        case attachmentActions.ADD_EVENT_ATTACHMENT_COMPLETE: {
            const newAttachment = new Attachment(action.payload.attachment);
            newAttachment.owner_id = action.payload.event_id;
            const newIds = state.ids.slice();
            newIds.push(newAttachment.id);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...{ [newAttachment.id]: newAttachment }
                },
                ids: newIds,
                idsForEvent: addValueToObjectArray({ ...state.idsForEvent }, action.payload.event_id, newAttachment.id)
            };
        }

        case attachmentActions.ADD_GROUP_ATTACHMENTS_COMPLETE: {
            if (!Array.isArray(action.payload.attachments)) {
                return state;
            }
            const attachments = action.payload.attachments.map(attachment => {
                return new Attachment({ ...attachment, owner_id: action.payload.group_id });
            });
            const { ids, entities } = getIdsAndEntities(state, attachments);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                ids: [...state.ids, ...ids],
                idsForGroup: addValuesToObjectArray({ ...state.idsForGroup }, action.payload.group_id, ids)
            };
        }
        case attachmentActions.ADD_SUB_GROUP_ATTACHMENTS_COMPLETE: {
            if (!Array.isArray(action.payload.attachments)) {
                return state;
            }
            const attachments = action.payload.attachments.map(attachment => {
                return new Attachment({ ...attachment, owner_id: action.payload.sub_group_id });
            });
            const { ids, entities } = getIdsAndEntities(state, attachments);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                ids: [...state.ids, ...ids],
                idsForSubGroup: addValuesToObjectArray({ ...state.idsForSubGroup }, action.payload.sub_group_id, ids)
            };
        }

        case attachmentActions.DELETE_GROUP_ATTACHMENT_COMPLETE: {
            const attachmentId = action.payload.id;

            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[attachmentId];

            const idsForGroup = { ...state.idsForGroup };
            idsForGroup[action.payload.owner_id] = idsForGroup[action.payload.owner_id].filter(id => id !== attachmentId);

            return {
                ...state,
                entities: entitiesCopy,
                ids: state.ids.filter(id => id !== attachmentId),
                idsForGroup
            };
        }
        case attachmentActions.DELETE_SUB_GROUP_ATTACHMENT_COMPLETE: {
            const attachmentId = action.payload.id;

            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[attachmentId];

            const idsForSubGroup = { ...state.idsForSubGroup };
            idsForSubGroup[action.payload.subGroup.id] = idsForSubGroup[action.payload.subGroup.id].filter(id => id !== attachmentId);

            return {
                ...state,
                entities: entitiesCopy,
                ids: state.ids.filter(id => id !== attachmentId),
                idsForSubGroup
            };
        }

        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const assets: Asset[] = action.payload.assets;
            let newEntities = {};
            const newIds = [];
            const idsForAsset = { ...state.idsForAsset };
            assets.forEach(asset => {
                if (asset.attachments) {
                    const entries = asset.attachments;
                    const { ids, entities } = getIdsAndEntities(state, entries);

                    idsForAsset[asset.id] = idsForAsset[asset.id] ? [...idsForAsset[asset.id], ...ids] : ids;

                    newEntities = { ...newEntities, ...entities };
                    newIds.push(...ids);
                }
            });

            return {
                ...state,
                ids: [...state.ids, ...newIds],
                entities: {
                    ...state.entities,
                    ...newEntities,
                },
                idsForAsset
            };
        }

        case attachmentActions.SORT_ASSET_ATTACHMENTS_LIST: {
            const field = action.payload.field;
            const order = action.payload.order;
            const args = action.payload.args;
            const assetId = action.payload.assetId;
            const sorted = copyAndMultiSort(state.idsForAsset[assetId], state.entities, [{ key: field }], order, args);

            return {
                ...state,
                ids: sorted,
                idsForAsset: { ...state.idsForAsset, [assetId]: sorted },
                assetSort: {
                    ...state.assetSort, [assetId]: { field, order, args }
                }
            };
        }

        case attachmentActions.SORT_EVENT_ATTACHMENTS_LIST: {
            const field = action.payload.field;
            const order = action.payload.order;
            const args = action.payload.args;
            const eventId = action.payload.eventId;
            const sorted = copyAndMultiSort(state.idsForEvent[eventId], state.entities, [{ key: field }], order, args);

            return {
                ...state,
                ids: sorted,
                idsForEvent: { ...state.idsForEvent, [eventId]: sorted },
                eventSort: {
                    ...state.assetSort, [eventId]: { field, order, args }
                }
            };
        }

        case attachmentActions.SORT_GROUP_ATTACHMENTS_LIST: {
            const field = action.payload.field;
            const order = action.payload.order;
            const args = action.payload.args;
            const groupId = action.payload.groupId;
            const sorted = copyAndMultiSort(state.idsForGroup[groupId], state.entities, [{ key: field }], order, args);

            return {
                ...state,
                ids: sorted,
                idsForGroup: { ...state.idsForGroup, [groupId]: sorted },
                groupSort: {
                    ...state.assetSort, [groupId]: { field, order, args }
                }
            };
        }
        case attachmentActions.SORT_SUB_GROUP_ATTACHMENTS_LIST: {
            const field = action.payload.field;
            const order = action.payload.order;
            const args = action.payload.args;
            const subGroupId = action.payload.subGroupId;
            const sorted = copyAndMultiSort(state.idsForSubGroup[subGroupId], state.entities, [{ key: field }], order, args);

            return {
                ...state,
                ids: sorted,
                idsForSubGroup: { ...state.idsForSubGroup, [subGroupId]: sorted },
                subGroupSort: {
                    ...state.assetSort, [subGroupId]: { field, order, args }
                }
            };
        }

        case attachmentActions.GET_ATTACHMENT_COMPLETE: {
            const attachments = action.payload.attachments;
            const assetId = action.payload.assetId;
            if (attachments) {
                const idsForAsset = state.idsForAsset[assetId] ? state.idsForAsset[assetId] : [];
                const { ids, entities } = getIdsAndEntities(state, attachments);
                const sortedIds = copyAndMultiSort([...idsForAsset, ...ids], entities, [{ key: defaultSort.field }], defaultSort.order, defaultSort.args);
                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: {
                        ...state.entities,
                        ...entities
                    },
                    idsForAsset: { ...state.idsForAsset, [assetId]: sortedIds },
                    assetSort: { ...state.assetSort, [assetId]: defaultSort }
                }
            }
            return state;
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getIdsForAsset = (state: State) => state.idsForAsset;
export const getIdsForEvent = (state: State) => state.idsForEvent;
export const getIdsForGroup = (state: State) => state.idsForGroup;
export const getIdsForSubGroup = (state: State) => state.idsForSubGroup;
export const getLoading = (state: State) => state.loading;
export const getAll = createSelector(getIds, getEntities, (ids, entities) => ids.map(id => entities[id]));
