import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '../../core/store';

@Injectable()
export class LoginGuard  {
    constructor(private router: Router, private store: Store<fromRoot.State>) { }

    canActivate(): Observable<boolean> {
        return this.store.select(fromRoot.getUser).pipe(map(user => {
            if (!!user) {
                //create guard rather than navigating
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }))
    }

    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
