import { BaseModel } from './../base/base.model';

export class ProjectSet extends BaseModel {
    static relatedIdField = 'set_id';

    id: number;
    code: string = null;
    set_id: number = null;
    project_id: number = null;

    constructor(obj: any = {}) {
        super();
        this.initializeData(obj, this);
    }
}
