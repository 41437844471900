import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import { SortObject, TableFilterObject } from '../types';
import * as requestViewActions from './request-view.actions';

export interface State {
    columnFiltersForRequestId: {
        [requestId: number]: ColumnFilterOptions;
    };
    requestViewSort: {
        [requestId: number]: SortObject;
    };
    requestViewFilter: {
        [requestId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    columnFiltersForRequestId: {},
    requestViewSort: {},
    requestViewFilter: {}
};

export function reducer(state = initialState, action: requestViewActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case requestViewActions.SORT_REQUEST_ASSETS_LIST: {
            const requestId = action.payload.requestId;
            const assetSort = action.payload.sort;
            const currentSort = state.requestViewSort[requestId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[requestId] = { ...action.payload.sort };

            return {
                ...state,
                requestViewSort: { ...state.requestViewSort, ...sortObj }
            };
        }

        case requestViewActions.FILTER_REQUEST_ASSETS_LIST: {
            const requestId = action.payload.requestId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.requestViewFilter, requestId);
                return {
                    ...state,
                    requestViewFilter: {
                        ...state.requestViewFilter,
                        [requestId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.requestViewFilter[requestId],
                ...action.payload.filter
            };

            return {
                ...state,
                requestViewFilter: {
                    ...state.requestViewFilter,
                    [requestId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case assetActions.LIST_BY_FINAL_APPROVER_REQUEST_FILTERS_COMPLETE:
        case assetActions.LIST_BY_REQUEST_FILTERS_COMPLETE: {
            const columnFiltersForRequestId = { ...state.columnFiltersForRequestId };
            columnFiltersForRequestId[action.payload.requestId] = action.payload.columnFilters;

            return {
                ...state,
                columnFiltersForRequestId
            };
        }

        case requestViewActions.LIST_TABLE_STATE_COMPLETE: {
            const requestId = action.payload.requestId;
            const { filter, sort } = listTableState(action.payload.tableState, state.requestViewFilter, state.requestViewSort, requestId);

            return {
                ...state,
                requestViewFilter: filter,
                requestViewSort: sort
            };
        }

        default: {
            return state;
        }
    }
}
