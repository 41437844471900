import { createSelector } from 'reselect';
import * as root from '../';
import * as fromEvent from './event.reducer';
import { copyAndMultiSort } from '../utils';

export const getEventState = (state: root.State) => state.event;

export const getEventEntities = createSelector(getEventState, fromEvent.getEntities);
export const getEventIds = createSelector(getEventState, fromEvent.getIds);
export const getEventSort = createSelector(getEventState, fromEvent.getSort);

export const getAllEventsSorted = createSelector(getEventEntities, getEventIds, getEventSort, (entities, ids, sort) => {
    let sortedIds = ids;
    if (sort) {
        if (sort.field === 'assetEvents' || sort.field === 'attachments') {
            const events: any = ids.map(id => entities[id]);
            if (sort.order === 1) {
                if (sort.field === 'attachments') {
                    return events.sort((a, b) => a.attachments.length - b.attachments.length);
                } else {
                    return events.sort((a, b) => a.assetEvents.length - b.assetEvents.length);
                }

            } else {
                if (sort.field === 'attachments') {
                    return events.sort((a, b) => b.attachments.length - a.attachments.length);
                } else {
                    return events.sort((a, b) => b.assetEvents.length - a.assetEvents.length);
                }

            }
        } else {
            sortedIds = copyAndMultiSort(ids, entities, [{ key: sort.field }, { key: 'id' }], sort.order);
        }
    } else {
        sortedIds = copyAndMultiSort(ids, entities, [{ key: 'start_date' }, { key: 'id' }], -1, []);
    }
    return sortedIds.map(id => entities[id]);
});

export const getEventsForDivision = createSelector(root.getAllEvents, root.getSelectedDivisionId, (events, divisionId) => {
    return events.filter((event => event.division_id === divisionId));
});

export const getAttachmentsForSelectedEvent = createSelector(root.getAttachmentsEntities, root.getAttachmentIdsByEvent, root.getSelectedEventId, (attEnts, attIdsByEvent, eventId) => {
    return eventId && attIdsByEvent[eventId] ? attIdsByEvent[eventId].map(id => attEnts[id]) : [];
});
