<div matDialogTitle>{{ title }}</div>
<p class="orange" style="max-width: 480px">WARNING: If selected assets do not have identical fields (i.e. {{ projectName }}), those fields will be cleared after clicking Save.</p>
<mat-dialog-content>
    <div>
        <p-messages [(value)]="savingMessage"></p-messages>
    </div>
    <form novalidate (keyup.enter)="ownerForm.valid && save()" class="form-container create-form" [formGroup]="ownerForm">
        <div *ngIf="assets.length" style="width: 100%; text-align: center;">
            <div *ngFor="let field of fields; trackBy: field?.id" [ngSwitch]="field.field.canonical_name" [class]="'bulk-move-modal-' + field.field.canonical_name">
                <ah-owner-fields [field]="field" [assetForm]="ownerForm" [ownerOptions]="ownerOptions" [disableOwnerChange]="disableOwnerChange" (selectOwner)="selectOwner($event)" [divisionOptions]="divisionOptions" (selectDivision)="selectDivision($event)" [selectedOwner]="selectedOwner"
                    [currentFranchiseOption]="currentFranchiseOption" [ownedFranchiseOptions]="franchiseOptions" (selectFranchise)="selectFranchise($event)" [projectOptions$]="projectOptions$" (selectProject)="selectProject($event)" [warehouseOptions]="warehouseOptions"
                    (selectWarehouse)="selectWarehouse($event)" [locationOptions$]="locationOptions$" (selectLocation)="selectLocation($event)" [warehouseLocationOptions$]="warehouseLocationOptions$" (selectWarehouseLocation)="selectWarehouseLocation($event)"
                    [subLocationOptions$]="subLocationOptions$" (selectSubLocation)="selectSubLocation($event)" [warehouseSubLocationOptions$]="warehouseSubLocationOptions$" (selectWarehouseSubLocation)="selectWarehouseSubLocation($event)" [storageBoxOptions$]="storageBoxOptions$"
                    (selectStorageBox)="selectStorageBox($event)" [warehouseLocationSubLocationShelfDisabled]="warehouseLocationSubLocationShelfDisabled" [disabled]="saveDisabled"></ah-owner-fields>
            </div>
        </div>
    </form>
    <div *ngIf="assets.length" class="feedback-text flex-column">
        <p class="no-permission" *ngIf="assetsWithoutPermsTo.length">You do not have permission to {{ verb }} {{assetsWithoutPermsTo.length}} of the selected Assets.</p>
        <p class="permission">{{assets.length}} selected {{pluralize('Asset', assets.length)}} will be {{ verb }}d.</p>
    </div>
    <div *ngIf="!assets.length" class="feedback-text flex-column">
        <p class="permission">You do not have permission to {{ verb }} any of the selected Assets.</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center gap-20">
    <button mat-raised-button mat-dialog-close [disabled]="saveDisabled">Cancel</button>
    <button *ngIf="assets.length" mat-raised-button color="primary" (click)="save()" [disabled]="ownerForm?.invalid || saveDisabled">Save</button>
</mat-dialog-actions>