import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as attachmentActions from '../../core/store/attachments/attachment.actions';
import * as subGroupActions from '../../core/store/sub-groups/sub-group.actions';
import { Attachment } from '../../core/store/models';
import { rootSelectors } from '../../core/store/selectors';
import { SortObject } from '../../core/store/types';

@Injectable()
export class GroupAttachmentService {
    constructor(private store: Store<rootSelectors.State>) { }

    add(data: { id: number, attachment: Attachment[] }) {
        this.store.dispatch(new attachmentActions.AddGroupAttachmentsCompleteAction({ group_id: data.id, attachments: data.attachment }));
    }

    delete(attachment: Attachment) {
        if (attachment.group) {
            this.store.dispatch(new attachmentActions.DeleteGroupAttachmentAction(attachment));
        }
        if (attachment.subGroup) {
            this.store.dispatch(new subGroupActions.SelectSubGroupAction(attachment.subGroup.id));
            this.store.dispatch(new subGroupActions.GetAction(attachment.subGroup.id));
            this.store.dispatch(new attachmentActions.DeleteSubGroupAttachmentAction(attachment));
        }
    }

    sort(sortObject: SortObject, groupId: number) {
        this.store.dispatch(new attachmentActions.SortGroupAttachmentsAction({
            ...sortObject,
            groupId
        }));
    }
}
