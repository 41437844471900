import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import { ActionStatus } from '../../core/store/types';
import * as assetModalActions from './../../core/store/asset-modal/asset-modal.actions';
import * as franchiseActions from './../../core/store/franchises/franchise.actions';
import { GuardHelperService } from './guard-helper.service';

@Injectable()
export class FranchiseGuard  {
    public canActivateChild = this.canActivate;

    constructor(private router: Router, private store: Store<fromRoot.State>) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const paramFranchiseId = GuardHelperService.getRouteParam('franchiseId', route);

        const getUser = this.store.select(fromRoot.getUser);
        const getSelectedFranchise = this.store.select(fromRoot.getSelectedFranchise);
        const getAssetModalSelectedFranchise = this.store.select(fromRoot.getAssetModalSelectedFranchiseId);
        const getGetting = this.store.select(fromRoot.getFranchiseGetting);
        const getStudioList = this.store.select(fromRoot.getStudioList);

        let dispatched = false;
        return observableCombineLatest(getUser, getSelectedFranchise, getAssetModalSelectedFranchise, getGetting, getStudioList).pipe(
            filter(([user, franchise, assetModalFranchiseId, gettingState, studios]) => {
                const studio = studios && studios.find(s => s.id === user.current_studio_id);
                if (studio && franchise && !franchise.is_series && studio.franchise_hidden) {
                    this.router.navigate(['/']);
                    return null;
                } else {
                    if (!franchise) {
                        if (!user.permission.isFranchiseUser(paramFranchiseId, user.current_studio_id)) {
                            this.router.navigate(['/']);
                        } else if (!dispatched) {
                            // Only dispatch if the user has perms to the franchise
                            this.store.dispatch(new franchiseActions.SelectAction(paramFranchiseId));
                            this.store.dispatch(new franchiseActions.GetAction(paramFranchiseId));
                            dispatched = true;
                        }
                        return false;
                    } else if ((!franchise && gettingState === ActionStatus.Loading)) {
                        return false;
                    }
                    if (franchise.id !== assetModalFranchiseId) {
                        this.clearAssetModalSelected();
                    }
                    return true;
                }
            }),
            map(([user, franchise]) => {
                if (!franchise || !user.permission.isFranchiseUser(franchise.id, franchise.studio_id)) {
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            }));
    }

    private clearAssetModalSelected() {
        this.store.dispatch(new assetModalActions.SelectDivisionAction(null));
        this.store.dispatch(new assetModalActions.SelectFranchiseAction(null));
        this.store.dispatch(new assetModalActions.SelectLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectProjectAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectDepartmentAction(null));
        this.store.dispatch(new assetModalActions.SelectGroupsAction([]));
    }
}
