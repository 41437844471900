import { BaseModel } from '../base/base.model';

export class DivisionEventField extends BaseModel {
    id: number;
    division_id: number = null;
    event_field_id: number = null;
    required: boolean = null;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(obj) {
        super();
        this.initializeData(obj, this);
    }
}
