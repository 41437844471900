import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class PingInterceptor implements HttpInterceptor {

    constructor(private httpClient: HttpClient) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.indexOf("/api/auth/extendSession") < 0) {
            const last_checked = (sessionStorage.ping_session_last_checked != null && sessionStorage.ping_session_last_checked != 'null') ? sessionStorage.ping_session_last_checked : 0;
            const now = new Date().getTime();


            if (last_checked < now - 60 * 5 * 1000) {
                this.httpClient.get("/api/auth/extendSession").subscribe((sessionExtendTime) => {
                    if (sessionExtendTime != null) {
                        sessionStorage.ping_session_last_checked = sessionExtendTime;
                    }
                })
            }
        }
        return next.handle(req);
    }
}