import { Action } from '@ngrx/store';
import { Currency } from './currency.model';

export const LIST = '[Currency] List';
export const LIST_COMPLETE = '[Currency] List Complete';
export const LIST_FAILED = '[Currency] List Failed';

export class ListAction implements Action {
    readonly type = LIST;

    constructor() { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: Currency[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction;
