import { Action } from '@ngrx/store';
import { MatchField } from './match-field.model';

export const ADD = '[Match Field] Add';
export const ADD_COMPLETE = '[Match Field] Add Complete';
export const ADD_FAILED = '[Match Field] Add Failed';

export const LIST = '[Match Field] List by Franchise';
export const LIST_COMPLETE = '[Match Field] List by Franchise Complete';
export const LIST_FAILED = '[Match Field] List by Franchise Failed';

export const UPDATE = '[Match Field] Update';
export const UPDATE_COMPLETE = '[Match Field] Update Complete';
export const UPDATE_FAILED = '[Match Field] Update Failed';

export const UPDATE_MULTIPLE = '[Match Field] Update Multiple';
export const UPDATE_MULTIPLE_COMPLETE = '[Match Field] Update Multiple Complete';
export const UPDATE_MULTIPLE_FAILED = '[Match Field] Update Multiple Failed';

export const SET_MULTIPLE = '[Match Field] Set Multiple';

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: MatchField[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: MatchField) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: MatchField) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateMultipleAction implements Action {
    readonly type = UPDATE_MULTIPLE;

    constructor(public payload: { franchiseId: number, matchFields: MatchField[] }) { }
}

export class UpdateMultipleCompleteAction implements Action {
    readonly type = UPDATE_MULTIPLE_COMPLETE;

    constructor(public payload: MatchField[]) { }
}

export class UpdateMultipleFailedAction implements Action {
    readonly type = UPDATE_MULTIPLE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class SetMultipleAction implements Action {
    readonly type = SET_MULTIPLE;

    constructor(public payload: MatchField[]) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | UpdateMultipleAction
    | UpdateMultipleCompleteAction
    | UpdateMultipleFailedAction
    | SetMultipleAction;
