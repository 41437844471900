import { createSelector } from 'reselect';
import * as root from '../';
import * as fromBulkMoveModal from './bulk-move-modal.reducer';

/**
 * Bulk Move Modal Selectors
 */
export const getBulkMoveModalState = (state: root.State) => state.bulkMoveModal;
export const getBulkMoveModalSelectedDivisionId = createSelector(getBulkMoveModalState, fromBulkMoveModal.getSelectedDivisionId);
export const getBulkMoveModalSelectedFranchiseId = createSelector(getBulkMoveModalState, fromBulkMoveModal.getSelectedFranchiseId);
export const getBulkMoveModalSelectedProjectId = createSelector(getBulkMoveModalState, fromBulkMoveModal.getSelectedProjectId);
export const getBulkMoveModalSelectedLocationId = createSelector(getBulkMoveModalState, fromBulkMoveModal.getSelectedLocationId);
export const getBulkMoveModalSelectedWarehouseId = createSelector(getBulkMoveModalState, fromBulkMoveModal.getSelectedWarehouseId);
export const getBulkMoveModalSelectedWarehouseLocationId = createSelector(getBulkMoveModalState, fromBulkMoveModal.getSelectedWarehouseLocationId);
