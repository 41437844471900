import { createSelector } from 'reselect';
import * as root from '..';

export const getAssetEventState = (state: root.State) => state.assetEvent;
export const getAssetEventSaving = createSelector(getAssetEventState, (assetEvent) => assetEvent.saving);

export const getQtyOnHand = createSelector(
    getAssetEventState,
    (assetEvent) => assetEvent.quantityOnHandForAssetId
);

export const getQtyOnHandForSelectedAsset = createSelector(
    getQtyOnHand,
    root.getSelectedAsset,
    (qtyOnHand, asset) => {
        if (asset && qtyOnHand[asset.id] !== undefined) {
            return qtyOnHand[asset.id];
        }
        return null;
    }
);

export const getNoOfGroupAssetsAddedToEvent = createSelector(getAssetEventState, root.getSelectedDivisionGroup, (assetEvent, group) => assetEvent.noOfGroupAssetsAdded[group.id]);
export const getNoOfSubGroupAssetsAddedToEvent = createSelector(getAssetEventState, root.getSelectedDivisionSubGroup, (assetEvent, subGroup) => assetEvent.noOfSubGroupAssetsAdded[subGroup.id]);
