<div matDialogTitle *ngIf="title">{{title}}</div>
<div>
    <p-messages [(value)]="savingMessage"></p-messages>
</div>
<mat-dialog-content>
    <div *ngIf="mode == 'confirmDeleteAssetImports'" class="modal-text" [ngStyle]="textStyle">
        Are you sure you want to delete this Asset Import?<br><br>This will <span style="color: red;">permanently delete</span> all associated Assets which have yet to be reviewed/accepted.<br><br> All accepted Assets will remain on the Asset Index.
    </div>

    <div *ngIf="message" class="modal-text" [ngStyle]="textStyle">{{message}}</div>

    <div *ngIf="htmlMessage" class="modal-text" [ngStyle]="textStyle"><p [innerHTML]="htmlMessage"></p></div>
    <ul class="modal-text" *ngFor="let msgData of transactionMessage">
        <li>{{msgData}}</li>
    </ul>
    <div class="modal-text" *ngFor="let msgData of charSetMessage">
        {{msgData}}
    </div>
    <ul class="modal-text" *ngFor="let msg of warningMessage">
        <li style="color: red;">{{msg}}</li>
    </ul>
    <ul class="modal-text" *ngFor="let msg of messageBulleted">
        <li>{{msg}}</li>
    </ul>
</mat-dialog-content>
<div class="layout-row-end-none gap-20">
    <button *ngIf="allowCancel" [disabled]="saveDisabled" mat-raised-button (click)="dialogRef.close(false)">{{cancelButton}}</button>
    <button *ngIf="isShowRemoveButton" mat-raised-button [color]="removeColor" (click)="save()" [disabled]="saveDisabled">{{removeButton}}</button>
    <button mat-raised-button [color]="confirmColor" (click)="save(deleteLedger)" [disabled]="saveDisabled">{{confirmButton}}</button>
</div>
