import { BaseModel } from './../base/base.model';

export class RequestAsset extends BaseModel {
    id: number;
    request_id: number = null;
    asset_id: number = null;
    approved: boolean = null;
    hasConflict: boolean = null;
    finalApproversSentTo: string = null;

    constructor(requestAssetObject) {
        super();
        this.initializeData(requestAssetObject, this);
    }
}
