<mat-toolbar color="white" class="shadow-box ui-shadow-3 ah-toolbar" [ngClass]="{'ah-toolbar-dark': dark}" ngClass.xs="mobile-toolbar">
    <!-- Top Nav -->
    <mat-toolbar-row>
        <div *ngIf="isMobile">
            <p-menubar [model]="items"></p-menubar>
        </div>
        <div class="toolbar-sos-logo" *ngIf="!isMobile">
            <img src="/assets/images/sos-logo.png">
        </div>
        <div class="flex justify-content-center center">
            <span *ngIf="currentStudio; else elseBlock" class="toolbar-link text-headline" (click)="selectStudioFromMenu(currentStudio, false)"
                ngClass.xs="mobile-text-studio-name">{{currentStudio.name}}</span>
            <ng-template #elseBlock>
                <span class="text-headline">AssetHub</span>
            </ng-template>
            <span *ngIf="navType === NAV_TYPES.DIVISION" class="toolbar-separating-arrow" ngClass.xs="mobile-toolbar-separating-arrow">
                <mat-icon svgIcon="chevron_right"></mat-icon>
            </span>
            <span *ngIf="navType === NAV_TYPES.DIVISION" routerLink="/division/{{currentDivision?.id}}" class="toolbar-link text-headline franchise-name"
                ngClass.xs="mobile-text-headline">{{currentDivision?.name}}</span>
            <span *ngIf="navType === NAV_TYPES.FRANCHISE" class="toolbar-separating-arrow" ngClass.xs="mobile-toolbar-separating-arrow">
                <mat-icon svgIcon="chevron_right"></mat-icon>
            </span>
            <span *ngIf="navType === NAV_TYPES.FRANCHISE" routerLink="/franchise/{{currentFranchise?.id}}/assets" class="toolbar-link text-headline franchise-name"
                ngClass.xs="mobile-text-headline">{{currentFranchise?.name}}</span>
        </div>
        <span class="toolbar-spacer"></span>
        <button mat-raised-button *ngIf="!user" (click)="loginFromSynconset()">Login</button>
        <ah-toolbar-menu (lightSwitch)="lightSwitch()" [dark]="dark" (selectStudioFromMenu)="selectStudioFromMenu($event, true)"
            [currentStudio]="currentStudio" [studioList]="studioList"></ah-toolbar-menu>
    </mat-toolbar-row>
    <!-- Sub Nav -->
    <mat-toolbar-row *ngIf="!isMobile">
        <p-menubar [model]="navSelectOptions"></p-menubar>
        <p-menubar [model]="items"></p-menubar>
    </mat-toolbar-row>
</mat-toolbar>
