import { BaseModel } from './../base/base.model';

export class Currency extends BaseModel {
    id: number;
    iso_code: string = null;
    name: string = null;

    constructor(obj) {
        super();
        this.initializeData(obj, this);
    }
}
