import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { UserDataService } from '../data-services/user-data.service';

@Injectable()
export class UserResolver  {

    constructor(private store: Store<fromRoot.State>, private userDataService: UserDataService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userDataService.getUser();
    }
}
