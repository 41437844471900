import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import * as layoutActions from '../layout/layout.actions';
import * as transactionActions from './transaction.actions';
import { Transaction } from './transaction.model';

interface FilterResults {
    columnFilters: ColumnFilterOptions;
    columnFiltersForExcluded: ColumnFilterOptions;
}

interface ListResults {
    transactions: Transaction[];
    totalCount: number;
}

@Injectable()
export class TransactionEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(transactionActions.LIST),
            map((action: transactionActions.ListAction) => action.payload),
            mergeMap((payload) => this.http.post<ListResults>(`/api/franchises/transactions`, payload).pipe(
                map((result) => {
                    const transactions = result.transactions.map((t) => new Transaction(t));
                    return new transactionActions.ListCompleteAction({ franchiseId: payload.franchiseId, totalCount: result.totalCount, transactions, options: payload.options });
                }),
                catchError((error) => observableOf(new transactionActions.ListFailedAction({ error }))))
            )));

     listFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(transactionActions.LIST_FILTERS),
            map((action: transactionActions.ListFiltersAction) => action.payload),
            mergeMap((payload) => this.http.post<FilterResults>(`/api/franchises/transactions/filters`, payload).pipe(
                map((result) => {
                    return new transactionActions.ListFiltersCompleteAction({ franchiseId: payload.franchiseId, columnFilters: result.columnFilters, columnFiltersForExcluded: result.columnFiltersForExcluded });
                }),
                catchError((error) => observableOf(new transactionActions.ListFiltersFailedAction({ error }))))
            )));

     sendReconcileEmails$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(transactionActions.SEND_RECONCILE_EMAILS),
            map((action: transactionActions.SendReconcileEmailsAction) => action.payload),
            mergeMap((payload) => this.http.post(`/api/projects/${payload.projectId}/transactions/reconcile`, { deptIds: payload.deptIds, includeFranchiseOwners: payload.incldueFranchiseOwners }).pipe(
                map(() => {
                    return new transactionActions.SendReconcileEmailsCompleteAction(payload);
                }),
                catchError((error) => observableOf(new transactionActions.SendReconcileEmailsFailedAction({ error }))))
            )));

     updateMultiple$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(transactionActions.UPDATE_MULTIPLE),
            map((action: transactionActions.UpdateMultipleAction) => action.payload),
            switchMap((payload) => this.http.put<Transaction[]>(`/api/franchises/${payload.franchiseId}/transactions`, payload.transactions).pipe(
                map(res => res.map((t) => new Transaction(t))),
                map((transactions: Transaction[]) => new transactionActions.UpdateMultipleCompleteAction(transactions)),
                catchError((error) => observableOf(new transactionActions.UpdateMultipleFailedAction({ error }))))
            )));

     requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(transactionActions.LIST_FAILED, transactionActions.LIST_FILTERS_FAILED, transactionActions.GET_FAILED, transactionActions.SEND_RECONCILE_EMAILS_FAILED, transactionActions.UPDATE_MULTIPLE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(transactionActions.GET),
            map((action: transactionActions.GetAction) => action.payload),
            switchMap((transactionIds) => this.http.post<Transaction[]>(`/api/transactions/get`, transactionIds).pipe(
                map(res => res.map((t) => new Transaction(t))),
                map((trs: Transaction[]) => new transactionActions.GetCompleteAction(trs)),
                catchError((error) => observableOf(new transactionActions.GetFailedAction({ error }))))
            )));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
