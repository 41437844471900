import { createSelector } from 'reselect';
import { ActionState, ActionStatus } from '../types';
import { getIdsAndEntities, sortStrings } from '../utils';
import * as setActions from './set.actions';
import { Set } from './set.model';

export interface State {
    ids: number[];
    entities: { [setId: number]: Set };
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: setActions.Actions): State {
    switch (action.type) {
        case setActions.ADD_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, [action.payload]);
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities,
                }
            };
        }

        case setActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case setActions.DELETE_COMPLETE: {
            const setId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[setId];

            return {
                ...state,
                ids: state.ids.filter(id => id !== setId),
                entities: entitiesCopy,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case setActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case setActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case setActions.LIST_SET: {
            return { ...state, ids: [], entities: {} };
        }

        case setActions.LIST_COMPLETE: {
            const sets = action.payload;
            const newSetIds = sets.map(set => set.id);
            const uniqueSetIds = newSetIds.filter(id => !state.entities[id]);

            const setMap = {};
            sets.forEach(set => {
                setMap[set.id] = set;
            });
            const ids = [...state.ids, ...uniqueSetIds];
            const entities = { ...state.entities, ...setMap };
            return {
                ...state,
                ids,
                entities
            };
        }

        case setActions.UPDATE_COMPLETE: {
            const set = action.payload;
            const entitiesUpdate = {};
            entitiesUpdate[set.id] = set;
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entitiesUpdate
                }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]).sort((a, b) => sortStrings(a.name, b.name)));
export const getDeleteState = (state: State) => state.deleteState;
