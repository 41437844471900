import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { STANDARD_FIELDS } from '../../../../../shared/fields/standard-fields';
import { FieldWithDepartmentField } from '../../../core/store/models';
import { OwnerType } from '../../../core/store/types';
import { PDropdownOption } from '../../interfaces/p-dropdown-options';

@Component({
    selector: 'ah-owner-fields',
    templateUrl: './owner-fields.component.html',
    styleUrls: ['./owner-fields.component.scss'],
})
export class OwnerFieldsComponent {
    @Input() assetForm: UntypedFormGroup;
    @Input() currentFranchiseOption: PDropdownOption[] = [];
    @Input() disabled = false;
    @Input() disableOwnerChange: boolean;
    @Input() divisionOptions: PDropdownOption[] = [];
    @Input() field: FieldWithDepartmentField;
    @Input() isMobile?= false;
    @Input() locationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    @Input() ownedFranchiseOptions: PDropdownOption[] = [];
    @Input() ownerOptions: PDropdownOption[] = [];
    @Input() projectOptions$: Observable<PDropdownOption[]> = observableOf([]);
    @Input() placeholder: string;
    @Input() selectedOwner: number;
    @Input() storageBoxOptions$: Observable<PDropdownOption[]> = observableOf([]);
    @Input() subLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    @Input() warehouseLocationSubLocationShelfDisabled = false;
    @Input() warehouseLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    @Input() warehouseOptions: PDropdownOption[] = [];
    @Input() warehouseSubLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);

    @Output() selectOwner = new EventEmitter();
    @Output() selectDivision = new EventEmitter();
    @Output() selectFranchise = new EventEmitter();
    @Output() selectProject = new EventEmitter();
    @Output() selectWarehouse = new EventEmitter();
    @Output() selectLocation = new EventEmitter();
    @Output() selectWarehouseLocation = new EventEmitter();
    @Output() selectSubLocation = new EventEmitter();
    @Output() selectWarehouseSubLocation = new EventEmitter();
    @Output() selectStorageBox = new EventEmitter();

    OwnerType = OwnerType;
    standardFields = STANDARD_FIELDS;

    constructor() { }

    selectOwnerEmit(event) {
        this.selectOwner.emit(event);
    }

    selectDivisionEmit(event) {
        this.selectDivision.emit(event);
    }

    selectFranchiseEmit(event) {
        this.selectFranchise.emit(event);
    }

    selectProjectEmit(event) {
        this.selectProject.emit(event);
    }

    selectWarehouseEmit(event) {
        this.selectWarehouse.emit(event);
    }

    selectLocationEmit(event) {
        this.selectLocation.emit(event);
    }

    selectWarehouseLocationEmit(event) {
        this.selectWarehouseLocation.emit(event);
    }

    selectSubLocationEmit(event) {
        this.selectSubLocation.emit(event);
    }

    selectWarehouseSubLocationEmit(event) {
        this.selectWarehouseSubLocation.emit(event);
    }

    selectStorageBoxEmit(event) {
        this.selectStorageBox.emit(event);
    }
}
