import { BaseModel } from './../base/base.model';

export class SubDepartment extends BaseModel {
    id: number;
    department_id: number = null;
    code: string = null;
    name: string = null;
    sos_match_entity: boolean = null;

    constructor(departmentObj) {
        super();
        this.initializeData(departmentObj, this);
    }

    displayName() {
        return `${this.code} ${this.name}`;
    }
}
