import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { createSelector } from 'reselect';
import { environment } from '../../../environments/environment';
import * as fromAssetEvent from './asset-events/asset-event.reducer';
import * as fromAssetHistory from './asset-history/asset-history.reducer';
import * as fromAssetModal from './asset-modal/asset-modal.reducer';
import * as fromAssetView from './asset-view/asset-view.reducer';
import * as fromAssetGroup from './assets/asset-group.reducer';
import * as fromAssetRequest from './assets/asset-request.reducer';
import * as fromAssetTransaction from './asset-transactions/asset-transaction.reducer';
import * as fromAsset from './assets/asset.reducer';
import * as fromAttachments from './attachments/attachment.reducer';
import * as fromBulkMoveModal from './bulk-move-modal/bulk-move-modal.reducer';
import * as fromCharacter from './characters/character.reducer';
import * as fromCurrency from './currencies/currency.reducer';
import * as fromCustomStudioFieldName from './custom-studio-field-names/custom-studio-field-name.reducer';
import * as fromCustomer from './customers/customer.reducer';
import * as fromDepartmentField from './department-fields/department-field.reducer';
import * as fromDepartment from './departments/department.reducer';
import * as fromDisposition from './dispositions/disposition.reducer';
import * as fromDivisionAssetIndex from './division-asset-index/division-asset-index.reducer';
import * as fromDivisionGroups from './division-groups/division-group.reducer';
import * as fromDivisionSubGroups from './division-subgroups/division-subgroup.reducer';
import * as fromDivisionTrash from './division-trash/division-trash.reducer';
import * as fromDivision from './divisions/division.reducer';
import * as fromDuplicateModal from './duplicate-modal/duplicate-modal.reducer';
import * as fromEventField from './event-fields/event-field.reducer';
import * as fromEventView from './event-view/event-view.reducer';
import * as fromEvent from './events/event.reducer';
import * as fromExcludedDispositions from './excluded-dispositions/excluded-disposition.reducer';
import * as fromField from './fields/field.reducer';
import * as fromFinalApproverRequest from './final-approver-requests/final-approver-request.reducer';
import * as fromFranchiseAssetIndex from './franchise-asset-index/franchise-asset-index.reducer';
import * as fromFranchiseGroups from './franchise-groups/franchise-group.reducer';
import * as fromFranchiseSubGroups from './franchise-subgroup/franchise-subgroup.reducer';
import * as fromFranchiseTrash from './franchise-trash/franchise-trash.reducer';
import * as fromFranchise from './franchises/franchise.reducer';
import * as fromGroupView from './group-view/group-view.reducer';
import * as fromGroups from './groups/group.reducer';
import * as fromInvite from './invites/invite.reducer';
import * as fromLayout from './layout/layout.reducers';
import { Ledger } from './ledger/ledger.model';
import * as fromLedger from './ledger/ledger.reducer';
import * as fromLocation from './locations/location.reducer';
import * as fromMatchAssets from './match-assets/match-assets.reducer';
import * as fromMatchField from './match-fields/match-field.reducer';
import * as fromMatchReview from './match-review/match-review.reducer';
import * as fromPicklistOption from './picklist-options/picklist-option.reducer';
import * as fromProjectCharacter from './project-characters/project-character.reducer';
import * as fromProjectCurrency from './project-currencies/project-currency.reducer';
import * as fromProjectSet from './project-sets/project-set.reducer';
import * as fromProject from './projects/project.reducer';
import * as fromRequestAsset from './request-assets/request-asset.reducer';
import * as fromRequestView from './request-view/request-view.reducer';
import * as fromRequest from './requests/request.reducer';
import * as fromSet from './sets/set.reducer';
import * as fromSettingsPage from './settings-page/settings-page.reducer';
import * as fromSidenavFilter from './sidenav-filter/sidenav-filter.reducer';
import * as fromNav from './nav/nav.reducer';
import * as fromSosField from './sos-fields/sos-field.reducer';
import * as fromSplitAssets from './split-assets/split-assets.reducer';
import * as fromStatus from './statuses/status.reducer';
import * as fromStorageBoxModal from './storage-box-modal/storage-box-modal.reducer';
import * as fromStorageBox from './storage-boxes/storage-box.reducer';
import * as fromStudioAssetIndex from './studio-asset-index/studio-asset-index.reducer';
import * as fromStudio from './studios/studio.reducer';
import * as fromSubDepartment from './sub-departments/sub-department.reducer';
import * as fromSubLocation from './sub-locations/sub-location.reducer';
import * as fromTransactionExpandoRow from './transaction-expando-row/transaction-expando-row.reducer';
import * as fromTransactionIndex from './transaction-index/transaction-index.reducer';
import * as fromTransactionUpload from './transaction-upload/transaction-upload.reducer';
import * as fromTransaction from './transactions/transaction.reducer';
import * as fromUser from './users/user.reducer';
import * as fromWarehouseLocation from './warehouse-locations/warehouse-location.reducer';
import * as fromWarehouseSubLocation from './warehouse-sub-locations/warehouse-sub-location.reducer';
import * as fromWarehouse from './warehouses/warehouse.reducer';
import * as fromSosLink from './sos-links/sos-link.reducer';
import * as fromGroupTypes from './group-types/group-types.reducer';
import * as fromSubGroups from './sub-groups/sub-group.reducer';
import * as fromSubGroupView from './subgroup-view/subgroup-view.reducer';
import * as fromAssetSubGroup from './assets/asset-sub-group.reducer';
import * as fromAssetImports from './asset-imports/asset-imports.reducer';
import { AssetImports } from './asset-imports/asset-imports.model';
import * as fromClaim from './claim-account/claim.reducer';
import { ActionStatus } from './types';

export interface State {
    asset: fromAsset.State;
    assetEvent: fromAssetEvent.State;
    assetGroup: fromAssetGroup.State;
    assetHistory: fromAssetHistory.State;
    assetTransaction: fromAssetTransaction.State;
    assetModal: fromAssetModal.State;
    assetRequest: fromAssetRequest.State;
    assetView: fromAssetView.State;
    attachments: fromAttachments.State;
    bulkMoveModal: fromBulkMoveModal.State;
    character: fromCharacter.State;
    currency: fromCurrency.State;
    customer: fromCustomer.State;
    customStudioFieldName: fromCustomStudioFieldName.State;
    department: fromDepartment.State;
    departmentField: fromDepartmentField.State;
    disposition: fromDisposition.State;
    division: fromDivision.State;
    divisionAssetIndex: fromDivisionAssetIndex.State;
    divisionGroups: fromDivisionGroups.State;
    divisionSubGroups: fromDivisionSubGroups.State;
    divisionTrash: fromDivisionTrash.State;
    duplicateModal: fromDuplicateModal.State;
    event: fromEvent.State;
    eventField: fromEventField.State;
    eventView: fromEventView.State;
    excludedDispositions: fromExcludedDispositions.State;
    field: fromField.State;
    finalApproverRequest: fromFinalApproverRequest.State;
    franchise: fromFranchise.State;
    franchiseAssetIndex: fromFranchiseAssetIndex.State;
    franchiseGroups: fromFranchiseGroups.State;
    franchiseSubGroups: fromFranchiseSubGroups.State;
    groups: fromGroups.State;
    groupView: fromGroupView.State;
    invite: fromInvite.State;
    layout: fromLayout.State;
    ledger: fromLedger.State;
    location: fromLocation.State;
    matchAssets: fromMatchAssets.State;
    matchField: fromMatchField.State;
    matchReview: fromMatchReview.State;
    nav: fromNav.State;
    picklistOption: fromPicklistOption.State;
    project: fromProject.State;
    projectCharacter: fromProjectCharacter.State;
    projectCurrency: fromProjectCurrency.State;
    projectSet: fromProjectSet.State;
    requestAsset: fromRequestAsset.State;
    requestView: fromRequestView.State;
    request: fromRequest.State;
    sidenavFilter: fromSidenavFilter.State;
    set: fromSet.State;
    settingsPage: fromSettingsPage.State;
    sosField: fromSosField.State;
    splitAssets: fromSplitAssets.State;
    status: fromStatus.State;
    storageBox: fromStorageBox.State;
    storageBoxModal: fromStorageBoxModal.State;
    studio: fromStudio.State;
    studioAssetIndex: fromStudioAssetIndex.State;
    subDepartment: fromSubDepartment.State;
    subLocation: fromSubLocation.State;
    transaction: fromTransaction.State;
    transactionExpandoRow: fromTransactionExpandoRow.State;
    transactionIndex: fromTransactionIndex.State;
    transactionUpload: fromTransactionUpload.State;
    franchiseTrash: fromFranchiseTrash.State;
    user: fromUser.State;
    warehouse: fromWarehouse.State;
    warehouseLocation: fromWarehouseLocation.State;
    warehouseSubLocation: fromWarehouseSubLocation.State;
    sosLink: fromSosLink.State;
    groupTypes: fromGroupTypes.State;
    subGroups: fromSubGroups.State;
    subGroupView: fromSubGroupView.State;
    assetSubGroup: fromAssetSubGroup.State;
    assetImports: fromAssetImports.State;
    claimAccount: fromClaim.State;
}

export const reducers: ActionReducerMap<State> = {
    asset: fromAsset.reducer,
    assetEvent: fromAssetEvent.reducer,
    assetGroup: fromAssetGroup.reducer,
    assetHistory: fromAssetHistory.reducer,
    assetModal: fromAssetModal.reducer,
    assetRequest: fromAssetRequest.reducer,
    assetTransaction: fromAssetTransaction.reducer,
    assetView: fromAssetView.reducer,
    attachments: fromAttachments.reducer,
    bulkMoveModal: fromBulkMoveModal.reducer,
    character: fromCharacter.reducer,
    currency: fromCurrency.reducer,
    customer: fromCustomer.reducer,
    customStudioFieldName: fromCustomStudioFieldName.reducer,
    department: fromDepartment.reducer,
    departmentField: fromDepartmentField.reducer,
    disposition: fromDisposition.reducer,
    division: fromDivision.reducer,
    divisionAssetIndex: fromDivisionAssetIndex.reducer,
    divisionGroups: fromDivisionGroups.reducer,
    divisionSubGroups: fromDivisionSubGroups.reducer,
    divisionTrash: fromDivisionTrash.reducer,
    duplicateModal: fromDuplicateModal.reducer,
    event: fromEvent.reducer,
    eventField: fromEventField.reducer,
    eventView: fromEventView.reducer,
    excludedDispositions: fromExcludedDispositions.reducer,
    field: fromField.reducer,
    finalApproverRequest: fromFinalApproverRequest.reducer,
    franchise: fromFranchise.reducer,
    franchiseAssetIndex: fromFranchiseAssetIndex.reducer,
    franchiseGroups: fromFranchiseGroups.reducer,
    franchiseSubGroups: fromFranchiseSubGroups.reducer,
    groups: fromGroups.reducer,
    groupView: fromGroupView.reducer,
    invite: fromInvite.reducer,
    layout: fromLayout.reducer,
    ledger: fromLedger.reducer,
    location: fromLocation.reducer,
    matchAssets: fromMatchAssets.reducer,
    matchField: fromMatchField.reducer,
    matchReview: fromMatchReview.reducer,
    nav: fromNav.reducer,
    picklistOption: fromPicklistOption.reducer,
    project: fromProject.reducer,
    projectCharacter: fromProjectCharacter.reducer,
    projectCurrency: fromProjectCurrency.reducer,
    projectSet: fromProjectSet.reducer,
    requestAsset: fromRequestAsset.reducer,
    requestView: fromRequestView.reducer,
    request: fromRequest.reducer,
    sidenavFilter: fromSidenavFilter.reducer,
    set: fromSet.reducer,
    settingsPage: fromSettingsPage.reducer,
    sosField: fromSosField.reducer,
    splitAssets: fromSplitAssets.reducer,
    status: fromStatus.reducer,
    storageBox: fromStorageBox.reducer,
    storageBoxModal: fromStorageBoxModal.reducer,
    studio: fromStudio.reducer,
    studioAssetIndex: fromStudioAssetIndex.reducer,
    subDepartment: fromSubDepartment.reducer,
    subLocation: fromSubLocation.reducer,
    transaction: fromTransaction.reducer,
    transactionExpandoRow: fromTransactionExpandoRow.reducer,
    transactionIndex: fromTransactionIndex.reducer,
    transactionUpload: fromTransactionUpload.reducer,
    franchiseTrash: fromFranchiseTrash.reducer,
    user: fromUser.reducer,
    warehouse: fromWarehouse.reducer,
    warehouseLocation: fromWarehouseLocation.reducer,
    warehouseSubLocation: fromWarehouseSubLocation.reducer,
    sosLink: fromSosLink.reducer,
    groupTypes: fromGroupTypes.reducer,
    subGroups: fromSubGroups.reducer,
    subGroupView: fromSubGroupView.reducer,
    assetSubGroup: fromAssetSubGroup.reducer,
    assetImports: fromAssetImports.reducer,
    claimAccount: fromClaim.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [storeFreeze] : [];

/**
 * Asset Selectors
 */
const getAssetState = (state: State) => state.asset;
export const getAssetEntities = createSelector(getAssetState, fromAsset.getEntities);
export const getDivisionAssetIds = createSelector(getAssetState, fromAsset.getDivisionIds);
export const getFranchiseAssetIds = createSelector(getAssetState, fromAsset.getFranchiseIds);
export const getReviewAssetIds = createSelector(getAssetState, fromAsset.getReviewIds);
export const getDivisionReviewAssetIds = createSelector(getAssetState, fromAsset.getDivisionReviewIds);
export const getSelectedAsset = createSelector(getAssetState, fromAsset.getSelectedAsset);
export const getSelectedInlineEditAsset = createSelector(getAssetState, fromAsset.getSelectedInlineEditAsset);
export const getAssetAdding = createSelector(getAssetState, fromAsset.getAdding);
export const getAssetSaving = createSelector(getAssetState, fromAsset.getSaving);
export const getAssetLoading = createSelector(getAssetState, fromAsset.getLoading);
export const getAssetGetting = createSelector(getAssetState, fromAsset.getGetting);
export const getAssetsShown = createSelector(getAssetState, fromAsset.getAssetsShown);
export const getAssetCountForFranchise = createSelector(getAssetState, fromAsset.getAssetCountsForFranchise);
export const getReviewAssetCountForFranchise = createSelector(getAssetState, fromAsset.getReviewAssetCountsForFranchise);
export const getAssetCountForDivision = createSelector(getAssetState, fromAsset.getAssetCountsForDivision);
export const getReviewAssetCountForDivision = createSelector(getAssetState, fromAsset.getReviewAssetCountForDivisoin);
export const getCacheLastValidated = createSelector(getAssetState, fromAsset.getCacheLastValidated);
export const getAssetLimit = createSelector(getAssetState, fromAsset.getAssetLimit);
export const getUpdatedFieldAssetCount = createSelector(getAssetState, fromAsset.getUpdatedFieldAssetCount);
export const getErrorMovingAsset = createSelector(getAssetState, fromAsset.getErrorMovingAsset)
export const getErrorSavingAsset = createSelector(getAssetState, fromAsset.getErrorSavingAsset)
/**
 * Asset Event
 */
const getAssetEventState = (state: State) => state.assetEvent;
export const getAssetIdsForEvents = createSelector(getAssetEventState, fromAssetEvent.getAssetIdsForEvent);
export const getAssetCountsForEvent = createSelector(getAssetEventState, fromAssetEvent.getAssetCountsForEvent);
export const getAssetEventForAssetIds = createSelector(getAssetEventState, fromAssetEvent.getAssetEventForAssetIds);
export const getQuantityOnHandForAssetId = createSelector(getAssetEventState, fromAssetEvent.getQuantityOnHandForAssetId);
export const getAssetEventSaving = createSelector(getAssetEventState, fromAssetEvent.getAssetEventSaving);
export const getNonTransferAssets = createSelector(getAssetEventState, fromAssetEvent.getNonTransferAssets);

/**
 * Asset Transaction
 */
export const getAssetTransactionState = (state: State) => state.assetTransaction;
export const getAssetTransactions = createSelector(getAssetTransactionState, fromAssetTransaction.getAssetTransactions);
export const getAssetListTransactions = createSelector(getAssetTransactionState, fromAssetTransaction.getListAssetTransactions);

/**
 * Asset Group
 */
const getAssetGroupState = (state: State) => state.assetGroup;
export const getAssetIdsForGroups = createSelector(getAssetGroupState, fromAssetGroup.getAssetIdsForGroup);
export const getGroupAssetLoading = createSelector(getAssetGroupState, fromAssetGroup.getLoadingGroupAssets);
export const getAssetCountsForGroups = createSelector(getAssetGroupState, fromAssetGroup.getAssetCountsForGroup);

/**
 * Asset Sub Group
 */
const getAssetSubGroupState = (state: State) => state.assetSubGroup;
export const getAssetIdsForSubGroups = createSelector(getAssetSubGroupState, fromAssetSubGroup.getAssetIdsForSubGroup);
export const getSubGroupAssetLoading = createSelector(getAssetSubGroupState, fromAssetSubGroup.getLoadingSubGroupAssets);
export const getAssetCountsForSubGroups = createSelector(getAssetSubGroupState, fromAssetSubGroup.getAssetCountsForSubGroup);

/**
 * Asset Request
 */
const getAssetRequestState = (state: State) => state.assetRequest;
export const getAssetIdsForRequests = createSelector(getAssetRequestState, fromAssetRequest.getAssetIdsForRequest);
export const getAssetCountsForRequests = createSelector(getAssetRequestState, fromAssetRequest.getAssetCountsForRequest);

/**
 * Asset History
 */
export const getAssetHistoryState = (state: State) => state.assetHistory;
export const getAssetHistorySaving = createSelector(getAssetHistoryState, fromAssetHistory.getSaving);
export const getAssetHistoryEntities = createSelector(getAssetHistoryState, fromAssetHistory.getEntities);
export const getAssetHistory = createSelector(getAssetHistoryState, fromAssetHistory.getAll);

/**
 * Asset Modal Selectors
 */
export const getAssetModalState = (state: State) => state.assetModal;
export const getAssetModalSelectedDepartmentId = createSelector(getAssetModalState, fromAssetModal.getSelectedDepartmentId);
export const getAssetModalSelectedDivisionId = createSelector(getAssetModalState, fromAssetModal.getSelectedDivisionId);
export const getAssetModalSelectedEventId = createSelector(getAssetModalState, fromAssetModal.getSelectedEventId);
export const getAssetModalSelectedFranchiseId = createSelector(getAssetModalState, fromAssetModal.getSelectedFranchiseId);
export const getAssetModalSelectedProjectId = createSelector(getAssetModalState, fromAssetModal.getSelectedProjectId);
export const getAssetModalSelectedLocationId = createSelector(getAssetModalState, fromAssetModal.getSelectedLocationId);
export const getAssetModalSelectedWarehouseId = createSelector(getAssetModalState, fromAssetModal.getSelectedWarehouseId);
export const getAssetModalSelectedWarehouseLocationId = createSelector(getAssetModalState, fromAssetModal.getSelectedWarehouseLocationId);

/**
 * Attachments
 */
export const getAttachmentsState = (state: State) => state.attachments;
export const getAttachmentsEntities = createSelector(getAttachmentsState, fromAttachments.getEntities);
export const getAttachments = createSelector(getAttachmentsState, fromAttachments.getAll);
export const getAttachmentIdsByAsset = createSelector(getAttachmentsState, fromAttachments.getIdsForAsset);
export const getAttachmentIdsByEvent = createSelector(getAttachmentsState, fromAttachments.getIdsForEvent);
export const getAttachmentIdsByGroup = createSelector(getAttachmentsState, fromAttachments.getIdsForGroup);
export const getAttachmentIdsBySubGroup = createSelector(getAttachmentsState, fromAttachments.getIdsForSubGroup);

/**
 * Character Selectors
 */
const getCharacterState = (state: State) => state.character;
export const getCharacterEntities = createSelector(getCharacterState, fromCharacter.getEntities);
export const getCharacters = createSelector(getCharacterState, fromCharacter.getAll);
export const getCharacterDeleteState = createSelector(getCharacterState, fromCharacter.getDeleteState);

/**
 * Currency Selectors
 */
const getCurrencyState = (state: State) => state.currency;
export const getCurrencyEntities = createSelector(getCurrencyState, fromCurrency.getEntities);
export const getCurrencies = createSelector(getCurrencyState, fromCurrency.getAll);

/**
 * Custom Studio Field Name Selectors
 */
const getCustomStudioFieldNameState = (state: State) => state.customStudioFieldName;
export const getCustomStudioFieldName = createSelector(getCustomStudioFieldNameState, fromCustomStudioFieldName.getCustomStudioFieldName);

/**
 * Department Selectors
 */
const getDepartmentState = (state: State) => state.department;
export const getDepartmentEntities = createSelector(getDepartmentState, fromDepartment.getEntities);
export const getAllDepartments = createSelector(getDepartmentState, fromDepartment.getAll);
export const getDepartmentsLoading = createSelector(getDepartmentState, fromDepartment.getLoading);
// Most places this is used need to be replaced with a byFranchise version, which isn't a thing yet so...
export const getDepartmentsForSelectedStudio = createSelector(
    getAllDepartments,
    getDepartmentsLoading,
    (depts, loading) => {
        if (depts && !loading) {
            return depts;
        } else {
            return [];
        }
    }
);
export const getDepartmentsForSelectedFranchise = createSelector(getDepartmentState, fromDepartment.getByFranchise);
export const getSosDepartmentsForSelectedFranchise = createSelector(getDepartmentState, fromDepartment.getSosByFranchise);
export const getSelectedDepartment = createSelector(getDepartmentState, fromDepartment.getSelected);
export const getSubDeptIdsForSelectedDept = createSelector(getDepartmentState, fromDepartment.getSelectedSubDeptIds);
export const getDeptSubDeptIds = createSelector(getDepartmentState, fromDepartment.getDeptSubDeptIds);

/**
 * Disposition Selectors
 */
const getDispositionState = (state: State) => state.disposition;
export const getDispositionMap = createSelector(getDispositionState, fromDisposition.getEntities);
export const getDispositions = createSelector(getDispositionState, fromDisposition.getDispositions);
export const getDispositionSaving = createSelector(getDispositionState, fromDisposition.getSaving);

const getDuplicateModalState = (state: State) => state.duplicateModal;
const getDuplicateAssetIds = createSelector(getDuplicateModalState, fromDuplicateModal.getDuplicateAssetIds);
export const getDuplicateAssets = createSelector(getDuplicateAssetIds, getAssetEntities, (ids, assets) => {
    return ids ? ids.map(id => assets[id]) : [];
});

/**
 * Event Field Selectors
 */
const getEventFieldState = (state: State) => state.eventField;
export const getEventFieldEntities = createSelector(getEventFieldState, fromEventField.getEntities);
export const getEventFields = createSelector(getEventFieldState, fromEventField.getAll);

/**
 * Excluded Disposition Selectors
 */
const getExcludedDispositionState = (state: State) => state.excludedDispositions;
export const getExcludedDispositionMap = createSelector(getExcludedDispositionState, fromExcludedDispositions.getEntities);
export const getExcludedDispositions = createSelector(getExcludedDispositionState, fromExcludedDispositions.getExcludedDispositions);
export const getExcludedDispositionSaving = createSelector(getExcludedDispositionState, fromExcludedDispositions.getSaving);

/**
 * Field Selectors
 */
const getFieldState = (state: State) => state.field;
export const getFieldEntities = createSelector(getFieldState, fromField.getEntities);
export const getFields = createSelector(getFieldState, fromField.getAll);
export const getAllFields = createSelector(getFieldState, fromField.getAllAndDeleted);
export const getSelectedFieldIds = createSelector(getFieldState, fromField.getSelectedIds);
export const getExcludedTabSelectedIds = createSelector(getFieldState, fromField.getExcludedTabSelectedIds);
export const getDeptFieldIdsForField = createSelector(getFieldState, fromField.getDeptFieldIdsForField);
export const getFieldSaving = createSelector(getFieldState, fromField.getSaving);

/**
 * MatchField Selectors
 */
const getMatchFieldState = (state: State) => state.matchField;
export const getMatchFieldEntities = createSelector(getMatchFieldState, fromMatchField.getEntities);
export const getMatchFields = createSelector(getMatchFieldState, fromMatchField.getAll);
export const getMatchFieldsSaving = createSelector(getMatchFieldState, fromMatchField.getSaving);

/**
 * Invite Selectors
 */
export const getInviteState = (state: State) => state.invite;

export const getInvitesForStudio = createSelector(getInviteState, fromInvite.getInvitesForStudio);
export const getInvitesForFranchise = createSelector(getInviteState, fromInvite.getInvitesForFranchise);
export const getInvitesForDivision = createSelector(getInviteState, fromInvite.getInvitesForDivision);
export const getInviteSaving = createSelector(getInviteState, fromInvite.getSaving);

/**
 * Layout Selectors
 */
export const getLayoutState = (state: State) => state.layout;
export const getNavType = createSelector(getLayoutState, fromLayout.getNavType);

/**
 * Ledger Selectors
 */
export const getLedgerState = (state: State) => state.ledger;
export const getCurrentLedger = createSelector(getLedgerState, (ledgerState) => {
    return new Ledger({ ...ledgerState });
});
export const getLedgerType = createSelector(getLedgerState, fromLedger.getType);
export const getLedgerUploaded = createSelector(getLedgerState, fromLedger.getUploaded);
export const getLedgerFields = createSelector(getLedgerState, fromLedger.getFields);
export const getLedgerValueExamples = createSelector(getLedgerState, fromLedger.getValueExamples);
export const getLedgerUnmatchedDepartments = createSelector(getLedgerState, fromLedger.getUnmatchedDepartments);
export const getLedgerNumUnmatched = createSelector(getLedgerState, fromLedger.getNumUnmatched);
export const getLedgerUnmatchedCurrencies = createSelector(getLedgerState, fromLedger.getUnmatchedCurrencies);
export const getLedgerComplete = createSelector(getLedgerState, fromLedger.getComplete);
export const getLedgerDeleteComplete = createSelector(getLedgerState, fromLedger.getDeleteComplete);
export const getLedgerCreateError = createSelector(getLedgerState, fromLedger.getCreateError);
export const getLedgerIdsForFranchises = createSelector(getLedgerState, fromLedger.getLedgerIdsForFranchise);
export const getLedgerEntities = createSelector(getLedgerState, fromLedger.getEntities);
export const getLedgerShowMatchingPage = createSelector(getLedgerState, fromLedger.getShowMatchingPage);

/**
 * Location Selectors
 */
export const getLocationState = (state: State) => state.location;
export const getLocationIdsByFranchise = createSelector(getLocationState, fromLocation.getIdsByFranchise);
export const getLocationEntities = createSelector(getLocationState, fromLocation.getEntities);
export const getLocations = createSelector(getLocationState, fromLocation.getAll);
export const getLocationSubLocationIds = createSelector(getLocationState, fromLocation.getLocationSubLocationIds);
export const getLocationsSaving = createSelector(getLocationState, fromLocation.getSaving);
export const getSelectedLocation = createSelector(getLocationState, fromLocation.getSelected);
export const getLocationDeleteState = createSelector(getLocationState, fromLocation.getDeleteState);

/**
 * Sub Location Selectors
 */
export const getSubLocationState = (state: State) => state.subLocation;
export const getSubLocationEntities = createSelector(getSubLocationState, fromSubLocation.getEntities);
export const getSubLocations = createSelector(getSubLocationState, fromSubLocation.getAll);
export const getSubLocationsSaving = createSelector(getSubLocationState, fromSubLocation.getSaving);
export const getSubLocationDeleteState = createSelector(getSubLocationState, fromSubLocation.getDeleteState);

/**
 * Match Review Selectors
 */
export const getMatchReviewState = (state: State) => state.matchReview;
export const getMatchReviewSelectedProjectId = createSelector(getMatchReviewState, fromMatchReview.getSelectedProjectId);
/**
 * Match Review Selectors
 */
export const getMatchAssetState = (state: State) => state.matchAssets;
export const getMatchAssetSelectedProjectId = createSelector(getMatchAssetState, fromMatchAssets.getSelectedProjectId);

/**
 * Picklist Option Selectors
 */
export const getPicklistOptionState = (state: State) => state.picklistOption;
export const getPicklistOptions = createSelector(getPicklistOptionState, fromPicklistOption.getPicklistOptions);
export const getPicklistOptionSaving = createSelector(getPicklistOptionState, fromPicklistOption.getSaving);
/**
 * Project Characters
 */
const getProjectCharacterState = (state: State) => state.projectCharacter;
export const getProjectCharacterEntities = createSelector(getProjectCharacterState, fromProjectCharacter.getEntities);
export const getProjectCharacters = createSelector(getProjectCharacterState, fromProjectCharacter.getAll);

/**
 * Project Currencies
 */
const getProjectCurrencyState = (state: State) => state.projectCurrency;
export const getProjectCurrencyEntities = createSelector(getProjectCurrencyState, fromProjectCurrency.getEntities);
export const getProjectCurrencies = createSelector(getProjectCurrencyState, fromProjectCurrency.getAll);
export const getProjectCurrencySaving = createSelector(getProjectCurrencyState, fromProjectCurrency.getSaving);
export const getProjectCurrencySelectedId = createSelector(getProjectCurrencyState, fromProjectCurrency.getSelectedId);
export const getProjectCurrencyDeleteState = createSelector(getProjectCurrencyState, fromProjectCurrency.getDeleteState);

/**
 * Project Sets
 */
const getProjectSetState = (state: State) => state.projectSet;
export const getProjectSetEntities = createSelector(getProjectSetState, fromProjectSet.getEntities);
export const getProjectSets = createSelector(getProjectSetState, fromProjectSet.getAll);

/**
 * Set Selectors
 */
export const getSetState = (state: State) => state.set;
export const getSetEntities = createSelector(getSetState, fromSet.getEntities);
export const getSets = createSelector(getSetState, fromSet.getAll);
export const getSetDeleteState = createSelector(getSetState, fromSet.getDeleteState);

/**
 * Status Selectors
 */
const getStatusState = (state: State) => state.status;
export const getStatusMap = createSelector(getStatusState, fromStatus.getEntities);
export const getStatuses = createSelector(getStatusState, fromStatus.getStatuses);

/**
 * Studio Selectors
 */
export const getStudioState = (state: State) => state.studio;

export const getStudio = createSelector(getStudioState, fromStudio.getStudio);
export const getStudioList = createSelector(getStudioState, fromStudio.getStudioList);
export const getSelectedStudio = createSelector(getStudioState, fromStudio.getSelectedStudio);
export const getSelectedStudioId = createSelector(getStudioState, fromStudio.getSelectedStudioId);
export const getStudioFinalApproversEnabled = createSelector(getSelectedStudio, studio => studio ? studio.final_reviewers_enabled : false);
export const getStudioApprovalsEnabled = createSelector(getSelectedStudio, studio => studio ? studio.approvals_enabled : false);

/**
 * Settings Page Selectors
 */
const getSettingsPageState = (state: State) => state.settingsPage;
export const getSettingsPageProjectSearch = createSelector(getSettingsPageState, fromSettingsPage.getProjectSearch);

/**
 * SOS Field Selectors
 */
export const getSosFieldState = (state: State) => state.sosField;
export const getSosFields = createSelector(getSosFieldState, fromSosField.getSosFields);

/**
 * Split Assets Page Selectors
 */
export const getSplitAssetsState = (state: State) => state.splitAssets;
export const getSplitAssetsSelectedLocationId = createSelector(getSplitAssetsState, fromSplitAssets.getSelectedLocationId);

/**
 * SubDepartment Selectors
 */
export const getSubDepartmentState = (state: State) => state.subDepartment;
export const getSubDepartmentEntities = createSelector(getSubDepartmentState, fromSubDepartment.getEntities);
export const getSubDepartments = createSelector(getSubDepartmentState, fromSubDepartment.getAll);

/**
 * Transaction Selectors
 */
const getTransactionState = (state: State) => state.transaction;
export const getSelectedTransaction = createSelector(getTransactionState, fromTransaction.getSelectedTransaction);
export const getSelectedTransactionId = createSelector(getTransactionState, fromTransaction.getSelectedTransactionId);
export const getSelectedTransactionsToMatch = createSelector(getTransactionState, fromTransaction.getSelectedTransactionsToMatch);
export const getSelectedTransactionIdsToMatch = createSelector(getTransactionState, fromTransaction.getSelectedTransactionIdsToMatch);
export const getTransactionIdsForFranchise = createSelector(getTransactionState, fromTransaction.getTransactionIdsForFranchise);
const getExcludedTransactionIdsForFranchise = createSelector(getTransactionState, fromTransaction.getExcludedTransactionIdsForFranchise);
const getTransactionEntities = createSelector(getTransactionState, fromTransaction.getEntities);
export const getSelectedTransactionDepartment = createSelector(getSelectedTransaction, getDepartmentsForSelectedFranchise, (transaction, departments) =>
    departments.find(dept => dept.id === transaction.department_id));
export const getTransactionEmailSending = createSelector(getTransactionState, fromTransaction.getEmailSending);
export const getTransactionSaving = createSelector(getTransactionState, fromTransaction.getSaving);
export const getTransactionLoading = createSelector(getTransactionState, fromTransaction.getLoading);
export const getTransactionCounts = createSelector(getTransactionState, fromTransaction.getTransactionTotalCount);
export const getTransactionExcludedCounts = createSelector(getTransactionState, fromTransaction.getTransactionExcludedTotalCount);
export const getTransactionsShown = createSelector(getTransactionState, fromTransaction.getTransactionsShown);

/**
 * Transaction Expando Row Selectors
 */
export const getTransactionExpandoRowState = (state: State) => state.transactionExpandoRow;
export const getTransactionExpandoRowSelectedLocationId = createSelector(getTransactionExpandoRowState, fromTransactionExpandoRow.getSelectedLocationId);

/**
 * Transaction Upload Selectors
 */
export const getTransactionUploadState = (state: State) => state.transactionUpload;
export const getTransactionUploadSelectedProjectId = createSelector(getTransactionUploadState, fromTransactionUpload.getSelectedProjectId);
export const getTransactionUploadCurrenciesForSelectedProjectId = createSelector(getTransactionUploadSelectedProjectId, getProjectCurrencies, (projectId, currencies) => {
    return currencies ? currencies.filter(curr => curr.project_id === projectId) : [];
});

/**
 * User Selectors
 */
export const getUserState = (state: State) => state.user;

export const getUser = createSelector(getUserState, fromUser.getUser);
export const getUserEntities = createSelector(getUserState, fromUser.entitiesSelector);
export const getFinalApproverUserIds = createSelector(getUserState, fromUser.getFinalApproverUserIds);
export const getAllUsers = createSelector(getUserState, fromUser.entitiesSelector);
export const getUsersForFranchise = createSelector(getUserState, fromUser.getUsersForFranchise);
export const getUsersForStudio = createSelector(getUserState, fromUser.getUsersForStudio);
export const getUsersForDivision = createSelector(getUserState, fromUser.getUsersForDivision);
export const getUserUpdating = createSelector(getUserState, fromUser.getUpdating);
export const getDeletingFranchisePermission = createSelector(getUserState, fromUser.getDeletingFranchisePermission);
export const getDeletingStudioPermission = createSelector(getUserState, fromUser.getDeletingStudioPermission);
export const getDeletingDivisionPermission = createSelector(getUserState, fromUser.getDeletingDivisionPermission);

/**
 * 
 * @param state 
 * @returns Claim Account Details
 */
export const getClaimState = (state: State) => state.claimAccount;
export const getClaimEntities = createSelector(getClaimState, fromUser.entitiesSelector)
export const getClaimUserUpdating = createSelector(getClaimState, fromUser.getUpdating);
export const getClaimUserDetailsPath = createSelector(getClaimUserUpdating, getClaimEntities, (loading, path) => {
    return loading === ActionStatus.Complete ? path : undefined
});

/**
 * Warehouse Selectors
 */
const getWarehouseState = (state: State) => state.warehouse;

export const getAllWarehouses = createSelector(getWarehouseState, fromWarehouse.getAll);
export const getWarehouseEntities = createSelector(getWarehouseState, fromWarehouse.getEntities);

/**
 * Warehouse Location Selectors
 */
const getWarehouseLocationState = (state: State) => state.warehouseLocation;
export const getWarehouseLocations = createSelector(getWarehouseLocationState, fromWarehouseLocation.getAll);
export const getSelectedWarehouseLocation = createSelector(getWarehouseLocationState, fromWarehouseLocation.getSelected);
export const getWarehouseLocationEntities = createSelector(getWarehouseLocationState, fromWarehouseLocation.getEntities);
export const getWarehouseLocationsSaving = createSelector(getWarehouseLocationState, fromWarehouseLocation.getSaving);
export const getWarehouseLocationSubLocationIds = createSelector(getWarehouseLocationState, fromWarehouseLocation.getWarehouseLocationSubLocationIds);
export const getWarehouseLocationDeleteState = createSelector(getWarehouseLocationState, fromWarehouseLocation.getDeleteState);

/**
 * Warehouse Sub Location Selectors
 */
const getWarehouseSubLocationState = (state: State) => state.warehouseSubLocation;

export const getWarehouseSubLocations = createSelector(getWarehouseSubLocationState, fromWarehouseSubLocation.getAll);
export const getAllWarehouseSubLocations = createSelector(getWarehouseSubLocationState, fromWarehouseSubLocation.getAll);
export const getWarehouseSubLocationEntities = createSelector(getWarehouseSubLocationState, fromWarehouseSubLocation.getEntities);
export const getWarehouseSubLocationsSaving = createSelector(getWarehouseSubLocationState, fromWarehouseSubLocation.getSaving);
export const getWarehouseSubLocationDeleteState = createSelector(getWarehouseSubLocationState, fromWarehouseSubLocation.getDeleteState);

/**
 * Division Selectors
 */
const getDivisionState = (state: State) => state.division;

export const getSelectedDivision = createSelector(getDivisionState, fromDivision.getSelected);
export const getSelectedDivisionId = createSelector(getDivisionState, fromDivision.getSelectedDivisionId);
export const getAllDivisions = createSelector(getDivisionState, fromDivision.getAll);
export const getMoveToDivisions = createSelector(getDivisionState, fromDivision.getMoveOptions);
export const getDivisionWarehousesIds = createSelector(getDivisionState, fromDivision.getDivisionWarehousesIds);
export const getDivisionWarehouses = createSelector(getDivisionState, fromDivision.getDivisionWarehouses);
export const getDivisionListing = createSelector(getDivisionState, fromDivision.getListing);
export const getDivisionGetting = createSelector(getDivisionState, fromDivision.getGetting);
export const getDivisionEntities = createSelector(getDivisionState, fromDivision.getEntities);

/**
 * Event Selectors
 */
export const getEventState = (state: State) => state.event;

export const getAllEvents = createSelector(getEventState, fromEvent.getAll);
export const getEventsSaving = createSelector(getEventState, fromEvent.getSaving);
export const getEventsOverlapAssets = createSelector(getEventState, fromEvent.getNumOverlapAssets);
export const getEventsGettingOverlap = createSelector(getEventState, fromEvent.getGettingOverlap);
export const getSelectedEventId = createSelector(getEventState, fromEvent.getSelectedEventId);
export const getSelectedEvent = createSelector(getEventState, fromEvent.getSelected);
export const getEventLoading = createSelector(getEventState, fromEvent.getLoading);
export const getEventSaving = createSelector(getEventState, fromEvent.getSaving);

/**
 * Project Selectors
 */
export const getProjectState = (state: State) => state.project;
export const getProjectEntities = createSelector(getProjectState, fromProject.getEntities);
export const getAllProjects = createSelector(getProjectState, fromProject.getAll);

/**
 * Franchise Selectors
 */
const getFranchiseState = (state: State) => state.franchise;
const getSelectedFranchiseProjectIds = createSelector(getFranchiseState, fromFranchise.getSelectedFranchiseProjectIds);

export const getFranchiseEntities = createSelector(getFranchiseState, fromFranchise.getEntities);
export const getFranchiseProjectIds = createSelector(getFranchiseState, fromFranchise.getFranchiseProjectsIds);
export const getOwnedFranchiseProjectIds = createSelector(getFranchiseState, fromFranchise.getOwnedFranchiseProjectsIds);
export const getSelectedFranchise = createSelector(getFranchiseState, fromFranchise.getSelected);
export const getSelectedFranchiseId = createSelector(getFranchiseState, fromFranchise.getSelectedFranchiseId);
export const getSelectedFranchiseProjects = createSelector(getSelectedFranchiseProjectIds, getProjectEntities, (ids, projectEntities) => ids.map(id => projectEntities[id]));
export const getAllFranchises = createSelector(getFranchiseState, fromFranchise.getAll);
export const getOwnedFranchises = createSelector(getFranchiseState, fromFranchise.getOwned);
export const getSeries = createSelector(getAllFranchises, (franchises) => {
    return franchises ? franchises.filter(franchise => franchise.is_series) : [];
});
export const getActualFranchises = createSelector(getAllFranchises, (franchises) => {
    return franchises ? franchises.filter(franchise => !franchise.is_series) : [];
});
export const getFranchiseMap = createSelector(getFranchiseState, fromFranchise.getEntities);
export const getFranchiseGetting = createSelector(getFranchiseState, fromFranchise.getGetting);
export const getFranchiseListing = createSelector(getFranchiseState, fromFranchise.getListing);

/**
 * Asset Join Selectors
 */
const getAssetIdsForSelectedEvent = createSelector(getAssetIdsForEvents, getSelectedEvent, (assetIds, event) => {
    return (event && assetIds[event.id]) ? assetIds[event.id] : [];
});
export const getAssetsForSelectedEvent = createSelector(getAssetEntities, getAssetIdsForSelectedEvent, (assets, assetIdsForEvent) => {
    return assetIdsForEvent.map(id => assets[id]);
});
export const getAssetCountForEvent = createSelector(getAssetCountsForEvent, getSelectedEvent, (counts, event) => {
    return event && counts[event.id] ? counts[event.id] : null;
});

/**
 * Transaction Join Selectors
 */
const getTransactionIdsForSelectedFranchise = createSelector(getTransactionIdsForFranchise, getSelectedFranchise, (transactionIds, franchise) =>
    (franchise && transactionIds[franchise.id]) ? transactionIds[franchise.id] : []);

const getExcludedTransactionIdsForSelectedFranchise = createSelector(getExcludedTransactionIdsForFranchise, getSelectedFranchise, (transactionIds, franchise) =>
    (franchise && transactionIds[franchise.id]) ? transactionIds[franchise.id] : []);

export const getAllTransactionsForSelectedFranchise = createSelector(getTransactionIdsForSelectedFranchise, getTransactionEntities,
    (ids, entities) => ids.map(id => entities[id]) || []);

export const getExcludedTransactionsForSelectedFranchise = createSelector(getExcludedTransactionIdsForSelectedFranchise, getTransactionEntities, (ids, entities) => {
    return (ids.map(id => entities[id]) || []);
});

export const getTransactionCountForSelectedFranchise = createSelector(getTransactionCounts, getSelectedFranchise, (counts, franchise) => {
    return franchise ? counts[franchise.id] : null;
});

export const getTransactionExcludedCountForSelectedFranchise = createSelector(getTransactionExcludedCounts, getSelectedFranchise, (counts, franchise) => {
    return franchise ? counts[franchise.id] : null;
});

/**
 * Sidenav Filter Selectors
 */
export const getSidenavFilterState = (state: State) => state.sidenavFilter;
export const getSettingsOptions = createSelector(getSidenavFilterState, fromSidenavFilter.getSettingsOptions);
export const getShowSidenavFilter = createSelector(getSidenavFilterState, fromSidenavFilter.getShowSidenavFilter);

const getSidenavFilterSelectedAssetProjects = createSelector(getSidenavFilterState, fromSidenavFilter.getSidenavFilterSelectedAssetProjectIds);
const getSidenavFilterSelectedTransactionProjects = createSelector(getSidenavFilterState, fromSidenavFilter.getSidenavFilterSelectedTransactionProjectIds);
const getSidenavFilterSelectedSettingsProjects = createSelector(getSidenavFilterState, fromSidenavFilter.getSidenavFilterSelectedSettingsProjectIds);

export const getSidenavAssetProjectIds = createSelector(getSidenavFilterSelectedAssetProjects, getSelectedFranchiseId, (projects, franchiseId) => {
    return projects && projects[franchiseId] ? projects[franchiseId] : [];
});
export const getSidenavTransactionProjectIds = createSelector(getSidenavFilterSelectedTransactionProjects, getSelectedFranchiseId, (projects, franchiseId) => {
    return projects && projects[franchiseId] ? projects[franchiseId] : [];
});
export const getSidenavSettingsProjectIds = createSelector(getSidenavFilterSelectedSettingsProjects, getSelectedFranchiseId, getSelectedFranchiseProjects, (selectedProjects, franchiseId, projects): number[] => {
    const projs = selectedProjects && selectedProjects[franchiseId] ? selectedProjects[franchiseId] : [];
    if (!projs.length && projects[0]) {
        projs.push(projects[0].id);
    }
    return projs;
});

/**
 * Nav Selectors
 */
export const getNavState = (state: State) => state.nav;
export const getSidenavShowFilterOptions = createSelector(getNavState, fromNav.getShowFilterOptions);
export const getSidenavShowAssetFilterOptions = createSelector(getNavState, fromNav.getShowAssetFilterOptions);

/**
 * Group Types Selectors
 */
export const getGroupTypesState = (state: State) => state.groupTypes;
export const getGroupTypesEntities = createSelector(getGroupTypesState, fromGroupTypes.getEntities);
export const getGroupTypesIds = createSelector(getGroupTypesState, fromGroupTypes.getIds);
export const getGroupTypes = createSelector(getGroupTypesState, fromGroupTypes.getAll);
/**
 * Group Selectors
 */
export const getGroupState = (state: State) => state.groups;
export const getGroupEntities = createSelector(getGroupState, fromGroups.getEntities);
export const getGroupIds = createSelector(getGroupState, fromGroups.getIds);
export const getGroups = createSelector(getGroupState, fromGroups.getAll);
export const getGroupIdsForFranchiseId = createSelector(getGroupState, fromGroups.getGroupIdsForFranchiseId);
export const getGroupIdsForDivisionId = createSelector(getGroupState, fromGroups.getGroupIdsForDivisionId);
export const getGroupsLoading = createSelector(getGroupState, fromGroups.getLoading);
export const getGroupsSaving = createSelector(getGroupState, fromGroups.getSaving);
export const getGroupsAdding = createSelector(getGroupState, fromGroups.getAdding);
export const getAssetCountForGroupId = createSelector(getGroupState, fromGroups.getAssetCountForGroupId);

export const getSelectedStudioGroupId = createSelector(getGroupState, fromGroups.getSelectedId);
export const getSelectedStudioGroup = createSelector(getGroupEntities, getSelectedStudioGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});
/**
 * Franchise Group Selectors
 */
export const getFranchiseGroupState = (state: State) => state.franchiseGroups;
export const getSelectedFranchiseGroupId = createSelector(getFranchiseGroupState, fromFranchiseGroups.getSelectedId);
export const getSelectedFranchiseGroup = createSelector(getGroupEntities, getSelectedFranchiseGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});
export const getSelectedFranchiseGroupAssetCount = createSelector(getFranchiseGroupState, fromFranchiseGroups.getSelectedAssetCount);

/*
 * Division Group Selectors
 */
export const getDivisionGroupState = (state: State) => state.divisionGroups;
export const getSelectedDivisionGroupId = createSelector(getDivisionGroupState, fromDivisionGroups.getSelectedId);
export const getSelectedDivisionGroup = createSelector(getGroupEntities, getSelectedDivisionGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});
export const getSelectedDivisionGroupAssetCount = createSelector(getDivisionGroupState, fromDivisionGroups.getSelectedAssetCount);
export const getAssetTotalCountForSelectedGroup = createSelector(getAssetCountsForGroups, getSelectedFranchiseGroup, getSelectedDivisionGroup, getSelectedStudioGroup, (counts, franchiseGroup, divisionGroup, studioGroup) => {
    if (franchiseGroup) {
        return (counts && counts[franchiseGroup.id]) ? counts[franchiseGroup.id] : 0;
    } else if (divisionGroup) {
        return (counts && counts[divisionGroup.id]) ? counts[divisionGroup.id] : 0;
    } else if (studioGroup) {
        return (counts && counts[studioGroup.id]) ? counts[studioGroup.id] : 0;
    }
});
/**
 * SubGroup Selectors
 */
export const getSubGroupState = (state: State) => state.subGroups;
export const getSubGroupEntities = createSelector(getSubGroupState, fromSubGroups.getEntities);
export const getSubGroupIds = createSelector(getSubGroupState, fromSubGroups.getIds);
export const getSubGroupNames = createSelector(getSubGroupState, fromSubGroups.getSubGroupNames);
export const getSubGroups = createSelector(getSubGroupState, fromSubGroups.getAll);
export const getSubGroupsLoading = createSelector(getSubGroupState, fromSubGroups.getLoading);
export const getSubGroupsSaving = createSelector(getSubGroupState, fromSubGroups.getSaving);
export const getSelectedStudioSubGroupId = createSelector(getSubGroupState, fromSubGroups.getSelectedId);
export const getSelectedSubGroup = createSelector(getSubGroupEntities, getSelectedStudioSubGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});

// export const getSelectedStudioSubGroupId = createSelector(getSubGroupState, fromGroups.getSelectedId);
export const getSelectedStudioSubGroup = createSelector(getSubGroupEntities, getSelectedStudioSubGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});
export const getSubGroupsAdding = createSelector(getSubGroupState, fromSubGroups.getAdding);
export const getAssetCountForSubGroupId = createSelector(getSubGroupState, fromSubGroups.getAssetCountForSubGroupId);

/**
 * Franchise Sub Group Selectors
 */
export const getFranchiseSubGroupState = (state: State) => state.franchiseSubGroups;
export const getSelectedFranchiseSubGroupId = createSelector(getFranchiseSubGroupState, fromFranchiseSubGroups.getSelectedId);
export const getSelectedFranchiseSubGroup = createSelector(getSubGroupEntities, getSelectedFranchiseSubGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});
export const getSelectedFranchiseSubGroupAssetCount = createSelector(getFranchiseSubGroupState, fromFranchiseSubGroups.getSelectedAssetCount);

/**
 * Division Sub Group Selectors
 */
export const getDivisionSubGroupState = (state: State) => state.divisionSubGroups;
export const getSelectedDivisionSubGroupId = createSelector(getDivisionSubGroupState, fromDivisionSubGroups.getSelectedId);
export const getSelectedDivisionSubGroup = createSelector(getSubGroupEntities, getSelectedDivisionSubGroupId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});
export const getSelectedDivisionSubGroupAssetCount = createSelector(getDivisionSubGroupState, fromDivisionSubGroups.getSelectedAssetCount);

/*
 *  Final Approver Request Selectors
 */
export const getFinalApproverRequestState = (state: State) => state.finalApproverRequest;
export const getFinalApproverRequestSort = createSelector(getFinalApproverRequestState, fromFinalApproverRequest.getSort);

/*
*   SOS Link Selector
*/
export const getSosLinkState = (state: State) => state.sosLink;
export const getAllSosLinks = createSelector(getSosLinkState, fromSosLink.getSosLinksForStudio);

/**
 * Asset Imports Selectors
 */
export const getAssetImportsState = (state: State) => state.assetImports;
export const getAssetImportsUploaded = createSelector(getAssetImportsState, fromAssetImports.getUploaded);
export const getAssetImportsExamples = createSelector(getAssetImportsState, fromAssetImports.getValueExamples);
export const getCurrentAssetsImport = createSelector(getAssetImportsState, (assetImportState) => {
    return new AssetImports({ ...assetImportState });
});
export const getAssetImportsCreateComplete = createSelector(getAssetImportsState, fromAssetImports.getCreateComplete);
export const getAssetImportsCreateError = createSelector(getAssetImportsState, fromAssetImports.getCreateFailed);
export const getAssetImportsIdsForFranchises = createSelector(getAssetImportsState, fromAssetImports.getIdsForFranchise);
export const getAssetImportsIdsForDivisions = createSelector(getAssetImportsState, fromAssetImports.getIdsForDivision);
export const getAssetImportsEntities = createSelector(getAssetImportsState, fromAssetImports.getEntities);
export const getAssetImportDeleteComplete = createSelector(getAssetImportsState, fromAssetImports.getDeleteComplete);
export const getAssetImportDeleteFailed = createSelector(getAssetImportsState, fromAssetImports.getDeleteFailed);
export const getAssetImportsSelectedProjectId = createSelector(getAssetImportsState, fromAssetImports.getSelectedProjectId);
