<ng-template [ngIf]="!authenticated">
        <router-outlet></router-outlet>
</ng-template>
<ng-template [ngIf]="authenticated" >
    <!-- Navigation -->
    <ah-toolbar
    (selectStudio)="selectStudio($event)"
    [customFieldNames]="customFieldNames | async"
    [isMobile]="isMobile"
    [navType]="navType"
    [studioList]="studioList | async"
    [user]="user | async"
    ></ah-toolbar>
    <!-- Main Content -->
    <mat-sidenav-container class="layout-fill">
        <mat-sidenav-content class="content">
            <div class="main-content" ngClass.xs="mobile-main-content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>
