import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Asset } from '../models';
import { AssetImports } from './asset-imports.model';

export const UPLOAD_DONE = '[Asset-Imports] Uploaded';

export const CREATE_ASSETS = '[Asset-Imports] Create Assets';
export const CREATE_ASSETS_COMPLETE = '[Asset-Imports] Create Assets Complete';
export const CREATE_ASSETS_FAILED = '[Asset-Imports] Create Assets Failed';

export const ADD_ASSETS_IMPORTS = '[Asset-Imports] Add Assets Imports';
export const ADD_ASSETS_IMPORTS_COMPLETE = '[Asset-Imports] Add Assets Imports Complete';
export const ADD_ASSETS_IMPORTS_FAILED = '[Asset-Imports] Add Assets Imports Failed';

export const LIST_BY_FRANCHISE = '[Asset-Imports] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[Asset-Imports] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Asset-Imports] List By Franchise Failed';

export const LIST_BY_DIVISION = '[Asset-Imports] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[Asset-Imports] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[Asset-Imports] List By Division Failed';

export const DELETE = '[Asset-Imports] Delete';
export const DELETE_COMPLETE = '[Asset-Imports] Delete Complete';
export const DELETE_FAILED = '[Asset-Imports] Delete Failed';

export const SELECT_PROJECT = '[Asset-Imports] Select Project';

export class UploadDoneAction implements Action {
    readonly type = UPLOAD_DONE;

    constructor(public payload: {
        importId: number;
        studioId: number
        divisionId: number;
        franchiseId: number;
        warehouseId: number;
        projectId: number;
        originCurrency: number;
        valueExamples: { [assetHeader: string]: string };
        assetsCount: number;
    }) { }
}

export class AddAssetsImportsAction implements Action {
    readonly type = ADD_ASSETS_IMPORTS;

    constructor(public payload: { studioId: number, importId: number, divisionId: number, warehouseId: number, franchiseId: number, projectId: number, originCurrency: number }) { }
}

export class AddAssetsImportsCompletedAction implements Action {
    readonly type = ADD_ASSETS_IMPORTS_COMPLETE;

    constructor(public payload: { assets: Asset[], franchiseId?: number, divisionId?: number }) { }
}

export class AddAssetsImportsFailedAction implements Action {
    readonly type = ADD_ASSETS_IMPORTS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: number) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: AssetImports[]) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: any, franchiseId: number }) { }
}

export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: number) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: AssetImports[]) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: { error: any, divisionId: number }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: AssetImports) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: AssetImports) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}

export class SelectProjectAction implements Action {
    readonly type = SELECT_PROJECT;

    constructor(public payload: number) { }
}
export type Actions
    = UploadDoneAction
    | AddAssetsImportsAction
    | AddAssetsImportsCompletedAction
    | AddAssetsImportsFailedAction
    | ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | SelectProjectAction;
