import { createSelector } from 'reselect';
import { DEFAULT_SELECTED_FIELDS_FOR_FRANCHISE_INDEX, STANDARD_FIELDS } from '../../../../shared/fields/standard-fields';
import { AssetTypes } from '../../core/store/assets/asset.model';
import { Field, TRANSACTION_MATCH_ASSETS_FIELDS } from '../../core/store/fields/field.model';
import * as fieldSelectors from '../../core/store/fields/field.selectors';
import * as root from '../../core/store/index';
import { filterSubDeptFiltersBySelectedDepartments, filterWarehouseSubLocFiltersBySelectedWarehouseLocs, filterSubLocFiltersBySelectedLocs } from '../../core/store/utils';

const getFranchiseAssetIndexState = (state: root.State) => state.franchiseAssetIndex;

export const getAllFieldsForTransactionAssetMatch = createSelector(root.getFields, (fields) => {
    return fields.filter(f => TRANSACTION_MATCH_ASSETS_FIELDS.indexOf(f.canonical_name) > -1);
});

const getColumnFiltersForFranchiseId = createSelector(getFranchiseAssetIndexState, (franchiseAssetIndex) => franchiseAssetIndex.columnFiltersForFranchiseId);

export const getFranchiseAssetSort = createSelector(getFranchiseAssetIndexState, (franchiseAssetIndex) => franchiseAssetIndex.franchiseAssetSort);
export const getFranchiseAssetSortForSelectedFranchise = createSelector(getFranchiseAssetSort, root.getSelectedFranchiseId, (sort, franchiseId) => {
    return (sort && sort[franchiseId]) ? sort[franchiseId] : {};
});
const getFranchiseReviewAssetSort = createSelector(getFranchiseAssetIndexState, (franchiseAssetIndex) => franchiseAssetIndex.franchiseReviewAssetSort);
export const getFranchiseForReviewAssetSortForSelectedFranchise = createSelector(getFranchiseReviewAssetSort, root.getSelectedFranchiseId, (sort, franchiseId) => {
    return (sort && sort[franchiseId]) ? sort[franchiseId] : {};
});

const getFranchiseAssetFilter = createSelector(getFranchiseAssetIndexState, (franchiseAssetIndex) => franchiseAssetIndex.franchiseAssetFilter);
export const getFranchiseAssetFilterForSelectedFranchise = createSelector(getFranchiseAssetFilter, root.getSelectedFranchiseId, (filter, franchiseId) => {
    return (filter && filter[franchiseId]) ? filter[franchiseId] : {};
});

export const getFranchiseAssetFilterDeptIds = createSelector(getFranchiseAssetFilterForSelectedFranchise, (filter): number[] => {
    const deptFilters = filter ? filter[STANDARD_FIELDS.department.canonical_name] : null;
    if (deptFilters) {
        return deptFilters.value;
    }
    return [];
});

export const getFranchiseAssetFilterLocationIds = createSelector(getFranchiseAssetFilterForSelectedFranchise, (filter): number[] => {
    const locFilters = filter ? filter.location_id : null;
    if (locFilters) {
        return locFilters.value;
    }
    return [];
});

export const getFranchiseAssetFilterWarehouseLocIds = createSelector(getFranchiseAssetFilterForSelectedFranchise, (filter): number[] => {
    const warehouseLocFilters = filter ? filter.warehouse_location_id : null;
    if (warehouseLocFilters) {
        return warehouseLocFilters.value;
    }
    return [];
});

const getFranchiseForReviewAssetFilter = createSelector(getFranchiseAssetIndexState, (franchiseAssetIndex) => franchiseAssetIndex.franchiseAssetForReviewFilter);
export const getFranchiseForReviewAssetFilterForSelectedFranchise = createSelector(getFranchiseForReviewAssetFilter, root.getSelectedFranchiseId, (filter, franchiseId) => {
    return (filter && filter[franchiseId]) ? filter[franchiseId] : {};
});

export const getFranchiseForReviewAssetFilterDeptIds = createSelector(getFranchiseForReviewAssetFilterForSelectedFranchise, (filter): number[] => {
    const deptFilters = filter ? filter[STANDARD_FIELDS.department.canonical_name] : null;
    if (deptFilters) {
        return deptFilters.value;
    }
    return [];
});

export const getFranchiseForReviewAssetFilterLocationIds = createSelector(getFranchiseForReviewAssetFilterForSelectedFranchise, (filter): number[] => {
    const locFilters = filter ? filter.location_id : null;
    if (locFilters) {
        return locFilters.value;
    }
    return [];
});

export const getFranchiseForReviewAssetFilterWarehouseLocIds = createSelector(getFranchiseForReviewAssetFilterForSelectedFranchise, (filter): number[] => {
    const warehouseLocFilters = filter ? filter.warehouse_location_id : null;
    if (warehouseLocFilters) {
        return warehouseLocFilters.value;
    }
    return [];
});

export const getReviewAssetCountForFranchise = createSelector(root.getReviewAssetCountForFranchise, root.getSelectedFranchiseId, (reviewAssetCountForFranchiseId, franchiseId) => {
    return franchiseId && reviewAssetCountForFranchiseId[franchiseId] ? reviewAssetCountForFranchiseId[franchiseId] : 0;
});

export const getActiveTab = createSelector(getFranchiseAssetIndexState, (state) => state.activeTab);

export const getFilteredDeptIds = createSelector(
    getActiveTab,
    getFranchiseAssetFilterDeptIds,
    getFranchiseForReviewAssetFilterDeptIds,
    (activeTab, ids, forReviewIds) => activeTab === 0 ? ids : forReviewIds
);

export const getFields = createSelector(fieldSelectors.getAllWithFakeColumnsAndFranchiseRestricted, root.getAssetsShown, (fields, assetsShown) => {
    let results: Field[];
    if (assetsShown === AssetTypes.All || assetsShown === AssetTypes.Historical || assetsShown === AssetTypes.Division) {
        results = fields.filter(field => !field.isTransactionOnly());
    } else if (assetsShown === AssetTypes.Franchise) {
        results = fields.filter(field => !field.isDivisionOnly() && !field.isTransactionOnly());
    }
    return results.map(f => new Field(f));
});

export const getSelectedFields = createSelector(getFields, root.getSelectedFieldIds, (fields, selectedIds) => fieldSelectors.getSelectedOrDefaultFields(fields, selectedIds, DEFAULT_SELECTED_FIELDS_FOR_FRANCHISE_INDEX));

export const getColumnFilters = createSelector(getColumnFiltersForFranchiseId, root.getSelectedFranchiseId, getFranchiseAssetFilterDeptIds, root.getSubDepartmentEntities, getFranchiseAssetFilterLocationIds, root.getSubLocationEntities, getFranchiseAssetFilterWarehouseLocIds, root.getWarehouseSubLocationEntities, (columnFilters, franchiseId, selectedDeptIds, subDeptEnts, selectedLocIds, subLocEnts, selectedWarehouseLocIds, warehouseSubLocEnts) => {
    const filters = (columnFilters && columnFilters[franchiseId]) ? { ...columnFilters[franchiseId] } : {};
    filterSubDeptFiltersBySelectedDepartments(filters, selectedDeptIds, subDeptEnts);
    filterSubLocFiltersBySelectedLocs(filters, selectedLocIds, subLocEnts);
    filterWarehouseSubLocFiltersBySelectedWarehouseLocs(filters, selectedWarehouseLocIds, warehouseSubLocEnts);
    return filters;
});

const getColumnFiltersForReviewForFranchiseId = createSelector(getFranchiseAssetIndexState, (franchiseAssetIndex) => franchiseAssetIndex.columnFiltersForReviewForFranchiseId);
export const getColumnFiltersForReview = createSelector(getColumnFiltersForReviewForFranchiseId, root.getSelectedFranchiseId, getFranchiseForReviewAssetFilterDeptIds, root.getSubDepartmentEntities, getFranchiseForReviewAssetFilterLocationIds, root.getSubLocationEntities, getFranchiseForReviewAssetFilterWarehouseLocIds, root.getWarehouseSubLocationEntities, (columnFilters, franchiseId, selectedDeptIds, subDeptEnts, selectedLocIds, subLocEnts, selectedWarehouseLocIds, warehouseSubLocEnts) => {
    const filters = (columnFilters && columnFilters[franchiseId]) ? { ...columnFilters[franchiseId] } : {};
    filterSubDeptFiltersBySelectedDepartments(filters, selectedDeptIds, subDeptEnts);
    filterSubLocFiltersBySelectedLocs(filters, selectedLocIds, subLocEnts);
    filterWarehouseSubLocFiltersBySelectedWarehouseLocs(filters, selectedWarehouseLocIds, warehouseSubLocEnts);
    return filters;
});
