import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Asset } from '../assets/asset.model';
import { SortObject } from '../types';
import { StorageBox } from './storage-box.model';

export const ADD = '[StorageBox] Add';
export const ADD_COMPLETE = '[StorageBox] Add Complete';
export const ADD_FAILED = '[StorageBox] Add Failed';

export const DELETE = '[StorageBox] Delete';
export const DELETE_COMPLETE = '[StorageBox] Delete Complete';
export const DELETE_FAILED = '[StorageBox] Delete Failed';

export const LIST_BY_WAREHOUSE = '[StorageBox] List By Warehouse';
export const LIST_BY_WAREHOUSE_COMPLETE = '[StorageBox] List By Warehouse Complete';
export const LIST_BY_WAREHOUSE_FAILED = '[StorageBox] List By Warehouse Failed';

export const UPDATE = '[StorageBox] Update';
export const UPDATE_COMPLETE = '[StorageBox] Update Complete';
export const UPDATE_FAILED = '[StorageBox] Update Failed';

export const SORT = '[StorageBox] Sort Requests';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { storageBox: StorageBox }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: { storageBox: StorageBox }) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: { storageBoxId: number }) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: { storageBoxId: number }) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByWarehouseAction implements Action {
    readonly type = LIST_BY_WAREHOUSE;

    constructor(public payload: number) { }
}

export class ListByWarehouseCompleteAction implements Action {
    readonly type = LIST_BY_WAREHOUSE_COMPLETE;

    constructor(public payload: StorageBox[]) { }
}

export class ListByWarehouseFailedAction implements Action {
    readonly type = LIST_BY_WAREHOUSE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { storageBox: StorageBox }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { storageBox: StorageBox, assets: Asset[] }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class SortAction implements Action {
    readonly type = SORT;

    constructor(public payload: SortObject) { }
}

export type Actions = AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListByWarehouseAction
    | ListByWarehouseCompleteAction
    | ListByWarehouseFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SortAction;
