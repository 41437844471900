import { createSelector } from 'reselect';
import { ActionState, ActionStatus } from '../types';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as warehouseLocationActions from '../warehouse-locations/warehouse-location.actions';
import * as warehouseSubLocationActions from './warehouse-sub-location.actions';
import { WarehouseSubLocation } from './warehouse-sub-location.model';

export interface State {
    ids: number[];
    entities: {
        [subLocationId: number]: WarehouseSubLocation
    };
    saving: ActionStatus;
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Complete,
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: warehouseSubLocationActions.Actions | warehouseLocationActions.Actions): State {
    switch (action.type) {
        case warehouseLocationActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case warehouseSubLocationActions.ADD:
        case warehouseSubLocationActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case warehouseSubLocationActions.ADD_FAILED:
        case warehouseSubLocationActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case warehouseSubLocationActions.ADD_COMPLETE: {
            const warehouseSubLocation = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [warehouseSubLocation]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case warehouseSubLocationActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case warehouseSubLocationActions.DELETE_COMPLETE: {
            const warehouseSubLocationId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            const idsCopy = [...state.ids];

            delete entitiesCopy[warehouseSubLocationId];
            idsCopy.splice(idsCopy.indexOf(warehouseSubLocationId), 1);
            return {
                ...state,
                ids: idsCopy,
                entities: entitiesCopy,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case warehouseSubLocationActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case warehouseSubLocationActions.UPDATE_COMPLETE: {
            const warehouseSubLocation = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [warehouseSubLocation]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case warehouseLocationActions.LIST_COMPLETE:
        case warehouseLocationActions.LIST_BY_STUDIO_COMPLETE: {
            const warehouseLocations = action.payload;
            const wareSubLocsArr = warehouseLocations.map(wareLoc => wareLoc.warehouseSubLocations);

            const ids = [];
            const entities = {};
            wareSubLocsArr.forEach(wareSubLocs => {
                wareSubLocs.forEach(wareSubLoc => {
                    if (!state.entities[wareSubLoc.id]) {
                        ids.push(wareSubLoc.id);
                    }
                    entities[wareSubLoc.id] = wareSubLoc;
                });
            });
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);

            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case warehouseLocationActions.DELETE_COMPLETE: {
            const warehouseLocationId = action.payload.id;
            const idsToDelete = [];
            const entitiesCopy = { ...state.entities };
            Object.keys(state.entities).forEach(key => {
                const wareSubLoc = state.entities[key];
                if (wareSubLoc.location_id === warehouseLocationId) {
                    idsToDelete.push(wareSubLoc.id);
                    delete entitiesCopy[key];
                }
            });
            return {
                ...state,
                ids: state.ids.filter(id => idsToDelete.indexOf(id) === -1),
                entities: entitiesCopy
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getSaving = (state: State) => state.saving;
export const getDeleteState = (state: State) => state.deleteState;
