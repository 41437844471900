import { Moment } from '../../../shared/services/moment.service';
import { BaseModel } from './../base/base.model';

export class Shipment extends BaseModel {
    id: number;
    shipping_date: Moment | Date = null;
    expected_date: Moment | Date = null;
    actual_date: Moment | Date = null;
    tracking_number: string = null;
    shipping_company: string = null;
    receiver_name: string = null;
    receiver_phone: string = null;

    moment: Moment;

    constructor(eventObject) {
        super();
        this.initializeData(eventObject, this);
    }
}
