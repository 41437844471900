import { Action } from '@ngrx/store';
import { Project } from '../projects/project.model';
import { Franchise } from './franchise.model';

export const ADD = '[Franchise] Add';
export const ADD_COMPLETE = '[Franchise] Add Complete';
export const ADD_FAILED = '[Franchise] Add Failed';

export const GET = '[Franchise] Get';
export const GET_COMPLETE = '[Franchise] Get Complete';
export const GET_FAILED = '[Franchise] Get Failed';

export const LIST = '[Franchise] List By Studio';
export const LIST_COMPLETE = '[Franchise] List Complete';
export const LIST_FAILED = '[Franchise] List Failed';

export const LIST_FRANCHISE_DIVISION_USER = '[Franchise] List By Studio based on Division User';
export const LIST_FRANCHISE_DIVISION_USER_COMPLETE = '[Franchise] Division User List Complete';
export const LIST_FRANCHISE_DIVISION_USER_FAILED = '[Franchise] Division User List Failed';

export const UPDATE = '[Franchise] Update';
export const UPDATE_COMPLETE = '[Franchise] Update Complete';
export const UPDATE_FAILED = '[Franchise] Update Failed';

export const SELECT = '[Franchise] Select';

export interface ListCompletePayload {
    franchises: Franchise[];
    studioId: number;
    onlyOwnerOptions: boolean;
}

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { franchise: Franchise, project?: Project }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;
    constructor(public payload: Franchise) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { studioId: number, error: any }) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: Franchise) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: { error: any }) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: { studioId: number, onlyOwnerOptions: boolean }) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: ListCompletePayload) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Franchise) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Franchise) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { studioId: number, error: any }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;
    constructor(public payload: number) { }
}

export class ListFranchiseDivisionUserAction implements Action {
    readonly type = LIST_FRANCHISE_DIVISION_USER;

    constructor(public payload: { studioId: number, onlyOwnerOptions: boolean }) { }
}

export class ListFranchiseDivisionUserCompleteAction implements Action {
    readonly type = LIST_FRANCHISE_DIVISION_USER_COMPLETE;

    constructor(public payload: ListCompletePayload) { }
}

export class ListFranchiseDivisionUserFailedAction implements Action {
    readonly type = LIST_FRANCHISE_DIVISION_USER_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SelectAction
    | ListFranchiseDivisionUserAction
    | ListFranchiseDivisionUserCompleteAction
    | ListFranchiseDivisionUserFailedAction;
