import { createSelector } from 'reselect';
import * as root from '../';
import { copyAndMultiSort } from '../utils';
import * as fromCustomer from './customer.reducer';

export const getCustomerState = (state: root.State) => state.customer;

export const getCustomerEntities = createSelector(getCustomerState, fromCustomer.getEntities);
export const getCustomerIds = createSelector(getCustomerState, fromCustomer.getIds);
export const getCustomerLoading = createSelector(getCustomerState, fromCustomer.getLoading);
export const getCustomerSaving = createSelector(getCustomerState, fromCustomer.getSaving);
export const getCustomerSort = createSelector(getCustomerState, fromCustomer.getSort);

export const getAllCustomers = createSelector(getCustomerState, fromCustomer.getAll);

export const getCustomerOptions = createSelector(getAllCustomers, (customers) => {
    return customers.map(c => ({
        value: c.id,
        label: c.getDisplayName()
    }));
});

export const getAllCustomersForCustomerTable = createSelector(getCustomerEntities, getCustomerIds, getCustomerSort, (entities, ids, sort) => {
    let sortedIds = ids;
    if (sort) {
        if (sort.field === 'address') {
            const customers = ids.map(id => entities[id]);
            return customers.sort((a, b) => {
                const address1 = a.address.getDisplayLocation().toLowerCase();
                const address2 = b.address.getDisplayLocation().toLowerCase();
                if (sort.order === 1) {
                    return address1.localeCompare(address2);
                } else {
                    return address2.localeCompare(address1);
                }
            });
        } else {
            sortedIds = copyAndMultiSort(ids, entities, [{ key: sort.field }, { key: 'id' }], sort.order);
        }
    } else {
        sortedIds = copyAndMultiSort(ids, entities, [{ key: 'customer_name' }, { key: 'id' }], 1, []);
    }
    return sortedIds.map(id => entities[id]);
});
