<form [formGroup]="groupForm">
    <mat-form-field style="width: 100%;">
        <mat-label>{{isConvertSubGroup?'Group *':'Groups'}}</mat-label>
        <mat-chip-grid #chipList>
            <mat-chip-row *ngFor="let group of selectedGroups" [removable] (removed)="removeGroup(group)">
                {{group}}
                <mat-icon matChipRemove *ngIf="!disabled" svgIcon="cancel"></mat-icon>
            </mat-chip-row>
            <mat-chip-row *ngFor="let subGroup of selectedSubGroups" [removable] (removed)="removeSubGroup(subGroup)">
                {{subGroup}}
                <mat-icon matChipRemove *ngIf="!disabled" svgIcon="cancel"></mat-icon>
            </mat-chip-row>
            <input #groupInput formControlName="groupControl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="addGroup($event)" (click)="openList()">
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectGroup($event)" panelWidth="auto">
            <div *ngIf="showAutoComplete()">
                <div *ngIf="(isDivisionTable || isFranchisetable) && (divisionId ? !(divisionGroups.length === 0 && notDivisionGroups.length === 0) : !(franchiseGroups.length === 0 && notFranchiseGroups.length
                === 0))">
                    <mat-optgroup [label]="divisionId ? 'Division Groups' : (franchise && franchise.is_series ? 'Series Groups' : 'Feature Groups')">
                        <mat-option [ngStyle]="{'font-weight': group.isSubgroup || isConvertSubGroup?'normal':'bold'}" *ngFor="let group of filteredGroups$ | async; trackBy: group?.id" [value]="group.name">
                            {{group.name}}
                        </mat-option>
                    </mat-optgroup>
                </div>
                <mat-optgroup [label]="'Studio Groups'">
                    <mat-option [ngStyle]="{'font-weight': group.isSubgroup || isConvertSubGroup?'normal':'bold'}" *ngFor="let group of filteredStudioGroups$ | async; trackBy: group?.id" [value]="group.name">
                        {{group.name}}
                    </mat-option>
                </mat-optgroup>
            </div>
        </mat-autocomplete>
    </mat-form-field>
</form>
