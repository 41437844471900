import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as navActions from './nav.actions';

@Injectable()
export class NavEffects {
     listStatus$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(navActions.LIST_STATUS),
            map((action: navActions.ListStatusAction) => action.payload),
            switchMap((data) => this.http.post<{ numOfForReviewAssets: number, numOfOpenRequests: number, numOfUnreconciledTransactions: number, numOfForReviewDivisionAssets: number }>(`/api/studio/${data.studioId}/nav-status`, { franchiseId: data.franchiseId, divisionId: data.divisionId }).pipe(
                map(res => new navActions.ListStatusCompleteAction(res)),
                catchError((error) => observableOf(new navActions.ListStatusFailedAction({ error })))
            ))));

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(navActions.LIST_STATUS_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
