import * as fieldActions from './custom-studio-field-name.actions';
import { CustomStudioFieldName } from './custom-studio-field-name.model';

export interface State {
    customStudioFieldName: CustomStudioFieldName;
}

export const initialState: State = {
    customStudioFieldName: new CustomStudioFieldName({})
};

export function reducer(state = initialState, action: fieldActions.Actions): State {
    switch (action.type) {
        case fieldActions.LIST_COMPLETE: {
            const customStudioFieldName = action.payload;
            return {
                ...state,
                customStudioFieldName
            };
        }

        default: {
            return state;
        }
    }
}

export const getCustomStudioFieldName = (state: State) => state.customStudioFieldName;
