import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { AssetTypes } from '../../core/store/assets/asset.model';
import { TableTypes } from '../../core/store/types';
import { BaseModel } from './../../core/store/base/base.model';

export enum SelectType {
    Single,
    Multi
}

export enum PageType {
    Asset,
    Transaction,
    Settings,
    Storage
}

export interface FilterOption {
    id: number;
    name: string;
}

export interface FilterEntry {
    name: string;
    entities: Observable<BaseModel[]> | Observable<FilterOption[]>;
    entityPrefix?: string; // string that goes in front of the entity.name
    selectedEntityIds: number[];
    title: string;
    type: SelectType;
    search?: boolean;
}

@Component({
    selector: 'ah-sidenav-filter',
    templateUrl: './sidenav-filter.component.html',
    styleUrls: ['./sidenav-filter.component.scss'],
    // We should rebuild the sidenav filter to be a 2 layered component
    // SidenavComponent and SidenavFilterComponent, where SidenavComponent
    // is the wrapper around all of the filters and each filter is its own component.
    // This would allow us to have OnPush change detection for each filter
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavFilterComponent {
    @Input() filters: FilterEntry[];
    @Input() visible: boolean = false;
    @Input() width = 0;
    @Input() button = false;
    @Input() buttonText: string;
    @Input() assetShown?: AssetTypes;
    @Output() select = new EventEmitter<{ entity: BaseModel, selectType: SelectType }>();
    @Output() clear = new EventEmitter();
    @Output() toggleVisible = new EventEmitter();
    @Output() buttonClick = new EventEmitter();
    @Input() allowDept: boolean;
    @Input() allowProject: boolean;
    @Input() tableType: TableTypes;
    @Output() selectProject = new EventEmitter<any>();
    @Input() settings: boolean = true;
    @Input() storage: boolean = true;

    filterType = SelectType;
    showDeptsText = 'Show all';
    toggleShowDepts = false;
    tooltipDelay = 1000;
    toggleIcon: string;
    historicalAsset: number = AssetTypes.Historical;

    constructor(public deviceService: DeviceDetectorService) { }

    isEntityChecked(entity: any, selectedEntityIds: number[]) {
        return selectedEntityIds && selectedEntityIds.indexOf(entity.id) !== -1;
    }

    isMobileDevice() {
        return this.deviceService.isMobile() || this.deviceService.isTablet();
    }

    filterName(filter: FilterEntry, entity) {
        let name = '';
        if (filter.entityPrefix) {
            name += `${filter.entityPrefix} `;
        }
        name += entity.name;
        return name;
    }

    toggleDepts() {
        this.toggleShowDepts = this.toggleShowDepts ? false : true;
        this.showDeptsText = this.toggleShowDepts ? 'Hide' : 'Show all';
    }
}
