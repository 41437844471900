import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomStudioFieldName } from '../../core/store/models';
import { CustomStudioFieldNameDataService } from '../data-services/custom-studio-field-name-data.service';
import { UserDataService } from '../data-services/user-data.service';

@Injectable()
export class CustomStudioFieldNameResolver  {

    constructor(private customStudioFieldNameDataService: CustomStudioFieldNameDataService, private userDataService: UserDataService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.userDataService.getUser().pipe(
            switchMap(() => this.customStudioFieldNameDataService.getCustomFields(route))
        );
    }
}
