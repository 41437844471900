import { RequestNoteTypes } from '../../../../../shared/enums/request-note-types.enum';
import { Moment } from '../../../shared/services/moment.service';
import { getFormattedDate } from '../utils';
import { BaseModel } from './../base/base.model';

export class RequestNote extends BaseModel {
    id: number;
    request_id: number = null;
    user_id: number = null;
    note_type: RequestNoteTypes = null;
    note: string = null;
    created_at: Moment | Date = null;
    userName: string = null;
    finalApprovers: string = null;

    constructor(requestNoteObject) {
        super();
        this.initializeData(requestNoteObject, this);
    }

    getFormattedDate(date) {
        return getFormattedDate(date);
    }
}
