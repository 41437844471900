import { createSelector } from 'reselect';
import * as root from '..';

export const getAssetViewState = (state: root.State) => state.assetView;

export const getListStatus = createSelector(
    getAssetViewState,
    (assetView) => assetView.listStatus
);

export const getSelectedFields = createSelector(
    getAssetViewState,
    (assetView) => assetView.selectedFields
);

export const getSelectedFieldsForFranchise = createSelector(
    getSelectedFields,
    root.getSelectedAsset,
    (selectedFields, asset) => selectedFields[asset.franchise_id] ? selectedFields[asset.franchise_id] : []
);
