import { createSelector } from 'reselect';
import { getIdsAndEntities } from '../utils';
import * as eventFieldActions from './event-field.actions';
import { EventField } from './event-field.model';

export interface State {
    ids: number[];
    entities: {
        [eventFieldId: number]: EventField
    };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: eventFieldActions.Actions): State {
    switch (action.type) {
        case eventFieldActions.LIST_COMPLETE: {
            const eventFields = action.payload;
            const { ids, entities } = getIdsAndEntities(state, eventFields);
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities
                }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;

export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
