import * as storageBoxModalActions from './storage-box-modal.actions';

export interface State {
    selectedWarehouseLocationId: number;
}

export const initialState: State = {
    selectedWarehouseLocationId: null
};

export function reducer(state = initialState, action: storageBoxModalActions.Actions): State {
    switch (action.type) {
        case storageBoxModalActions.SELECT_WAREHOUSE_LOCATION: {
            return {
                ...state,
                selectedWarehouseLocationId: action.payload
            };
        }

        default: {
            return state;
        }
    }
}

export const getSelectedWarehouseLocationId = (state: State) => state.selectedWarehouseLocationId;
