import { BaseModel } from './../base/base.model';
import { DivisionEventField } from './../divisions/division-event-field.model';
import { Warehouse } from './../warehouses/warehouse.model';

export class Division extends BaseModel {
    id: number;
    studio_id: number = null;
    name: string = null;
    events_enabled = false;
    warehouses?: Warehouse[];
    divisionEventFields?: DivisionEventField[];

    constructor(divisionObject) {
        super();
        this.initializeData(divisionObject, this);
        if (divisionObject.warehouses) {
            this.warehouses = divisionObject.warehouses.map((w) => new Warehouse(w));
        }
        if (divisionObject.divisionEventFields) {
            this.divisionEventFields = divisionObject.divisionEventFields.map((f) => new DivisionEventField(f));
        }
    }
}
