import { BaseModel } from './../base/base.model';

export class ProjectCharacter extends BaseModel {
    static relatedIdField = 'character_id';

    id: number;
    code: string = null;
    character_id: number = null;
    project_id: number = null;

    constructor(obj: any = {}) {
        super();
        this.initializeData(obj, this);
    }
}
