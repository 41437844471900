import { STANDARD_FIELDS } from '../../../../../shared/fields/standard-fields';
import { Entities } from '../../../../../shared/types';
import { Department } from '../departments/department.model';
import { Transaction } from '../transactions/transaction.model';
import { BULK_MOVE_FIELDS, BULK_UPDATE_FRANCHISE_INFO_FIELDS, Field, TRANSACTION_MATCH_ASSETS_FIELDS, TRANSACTION_MATCH_REVIEW_FIELDS } from './field.model';

/**
 * If selections are present in entities,
 * returns them in an array
 */
export const getSelectedEntities = (fieldEntities: Entities<Field>, selectedIds: number[]) => {
    let selectedFields: Field[];
    selectedFields = selectedIds.reduce((result, id) => {
        if (fieldEntities[id]) {
            return [...result, fieldEntities[id]];
        }
        return result;
    }, []);
    return selectedFields;
};

export const getBulkFields = (fields: Field[], bulkFieldArray: string[]) => {
    return fields.filter(f => bulkFieldArray.includes(f.canonical_name)).sort(function(a, b) {
        return a.sort_order - b.sort_order;
    });
};

/**
 * Gets the owner fields sorted for the bulk move modal
 */
export const getMoveModalFields = (fields: Field[]) => {
    const moveModalFields = BULK_MOVE_FIELDS.map(f => fields.find(field => field.canonical_name === f));
    const ownerField = new Field(fields.find(field => field.canonical_name === STANDARD_FIELDS.franchise.canonical_name));
    ownerField.canonical_name = 'owner';
    moveModalFields.splice(0, 0, ownerField);
    return moveModalFields;
};

export const getUpdateFranchiseInfoModalFields = (fields: Field[]) => {
    return BULK_UPDATE_FRANCHISE_INFO_FIELDS.map(f => fields.find(field => field.canonical_name === f));
};

export const getDefaultSelectedFieldsForTransactionMatch = (fields: Field[], departments: Entities<Department>, transactions: Transaction[]) => {
    let selectedFields: Field[] = [];

    TRANSACTION_MATCH_ASSETS_FIELDS.forEach(name => {
        const field: Field = fields.find(f => f.canonical_name === name);

        if (field) {
            selectedFields.push(field);
        }
    });

    selectedFields = addTransactionMatchSpecificFields(selectedFields, fields, departments, transactions);

    return selectedFields;
};

/**
 * Adds Department and Character or Set field to input `selectedFields` depending on the transaction. These fields are not optional so they don't go into Redux.
 * @param {Field[]} selectedFields currently selected fields
 * @param {Field[]} fields all fields for the selected franchise
 * @param {Entities<Department>} departments available department entities
 * @param {Transaction} transaction currently selected transaction
 */
export const addTransactionMatchSpecificFields = (selectedFields: Field[], fields: Field[], departments: Entities<Department>, transactions: Transaction[]) => {
    const departmentField: Field = fields.find(f => f.canonical_name === STANDARD_FIELDS.department.canonical_name);

    if (departmentField && !selectedFields.find(f => f.canonical_name === departmentField.canonical_name)) {
        selectedFields.push(departmentField);
    }

    if (transactions[0].department_id && departmentField) {
        const department: Department = departments[transactions[0].department_id];

        if (department) {
            if (department.isSosCostumeOrPropsDepartment()) {
                const characterField: Field = fields.find(f => f.canonical_name === STANDARD_FIELDS.character.canonical_name);

                if (characterField && !selectedFields.find(f => f.canonical_name === characterField.canonical_name)) {
                    selectedFields.push(characterField);
                }
            } else if (department.isSosSetDepartment()) {
                const setField: Field = fields.find(f => f.canonical_name === STANDARD_FIELDS.set.canonical_name);

                if (setField && !selectedFields.find(f => f.canonical_name === setField.canonical_name)) {
                    selectedFields.push(setField);
                }
            }
        }
    }

    return selectedFields;
};

export const getDefaultSelectedFieldsForTransactionMatchReview = (fields: Field[], departments: Entities<Department>, transactions: Transaction[]) => {
    let selectedFields: Field[] = [];

    TRANSACTION_MATCH_REVIEW_FIELDS.forEach(name => {
        const field: Field = fields.find(f => f.canonical_name === name);

        if (field) {
            selectedFields.push(field);
        }
    });

    selectedFields = addTransactionMatchReviewSpecificFields(selectedFields, fields, departments, transactions);

    return selectedFields;
};

export const addTransactionMatchReviewSpecificFields = (selectedFields: Field[], fields: Field[], departments: Entities<Department>, transactions: Transaction[]) => {
    if (transactions && transactions[0] && transactions[0].department_id) {
        const department: Department = departments[transactions[0].department_id];

        if (department) {
            if (department.isSosCostumeOrPropsDepartment()) {
                const characterField: Field = fields.find(f => f.canonical_name === STANDARD_FIELDS.character.canonical_name);

                if (characterField && !selectedFields.find(f => f.canonical_name === characterField.canonical_name)) {
                    selectedFields.push(characterField);
                }
            } else if (department.isSosSetDepartment()) {
                const setField: Field = fields.find(f => f.canonical_name === STANDARD_FIELDS.set.canonical_name);

                if (setField && !selectedFields.find(f => f.canonical_name === setField.canonical_name)) {
                    selectedFields.push(setField);
                }
            }
        }
    }

    return selectedFields;
};
