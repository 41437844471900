import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Location } from './location.model';

export const ADD = '[Location] Add';
export const ADD_COMPLETE = '[Location] Add Complete';
export const ADD_FAILED = '[Location] Add Failed';

export const DELETE = '[Location] Delete';
export const DELETE_COMPLETE = '[Location] Delete Complete';
export const DELETE_FAILED = '[Location] Delete Failed';

export const LIST = '[Location] List By Franchise';
export const LIST_COMPLETE = '[Location] List Complete';
export const LIST_FAILED = '[Location] List Failed';

export const LIST_BY_STUDIO = '[Location] List By Studio';
export const LIST_BY_STUDIO_COMPLETE = '[Location] List By Studio Complete';
export const LIST_BY_STUDIO_FAILED = '[Location] List By Studio Failed';

export const SELECT = '[Location] Select';

export const UPDATE = '[Location] Update';
export const UPDATE_COMPLETE = '[Location] Update Complete';
export const UPDATE_FAILED = '[Location] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: Location) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Location) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: Location) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Location) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: Location[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any }) { }
}

export class ListByStudioAction implements Action {
    readonly type = LIST_BY_STUDIO;

    constructor(public payload: number) { }
}

export class ListByStudioCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_COMPLETE;

    constructor(public payload: Location[]) { }
}

export class ListByStudioFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_FAILED;

    constructor(public payload: { error: any }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Location) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Location) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | ListByStudioAction
    | ListByStudioCompleteAction
    | ListByStudioFailedAction
    | SelectAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
