import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { LEDGER_TYPES } from '../../../../shared/enums/ledger-types.enum';
import * as fieldActions from '../../core/store/fields/field.actions';
import { fakeFields, unmatchableFields } from '../../core/store/fields/field.model';
import * as matchFieldActions from '../../core/store/match-fields/match-field.actions';
import { MatchField } from '../../core/store/match-fields/match-field.model';
import { matchFieldSelectors, rootSelectors as fromRoot } from '../../core/store/selectors';
import { ActionStatus } from '../../core/store/types';

@Injectable()
export class MatchFieldsDataService {
    matchFields: MatchField[] = [];
    ledgerType: LEDGER_TYPES;
    franchiseId: number;
    realFieldMap = {};

    constructor(private store: Store<fromRoot.State>) { }

    dispatchListByFranchise(franchiseId) {
        this.franchiseId = franchiseId;
        this.store.dispatch(new matchFieldActions.ListAction(franchiseId));
    }

    renameFieldsForFrontEnd() {
        const costFieldIndex = this.matchFields.findIndex(mf => mf.field.canonical_name === 'cost');
        if (costFieldIndex >= 0) {
            this.matchFields[costFieldIndex].field.name = 'Cost';
        }
    }

    // For each fake field, replace however many corresponding matchFields with just one matchField with the fake field attached
    handleFakeFieldsForFrontEnd() {
        const toRemove = [];
        const toAdd = fakeFields.reduce((prev, ff) => Object.assign(prev, { [ff.id]: null }), {});
        this.matchFields.forEach(mf => {
            fakeFields.forEach(ff => {
                if (ff.mapTo.includes(mf.field.canonical_name)) {
                    if (this.realFieldMap[ff.id]) {
                        this.realFieldMap[ff.id].push(mf);
                    } else {
                        this.realFieldMap[ff.id] = [mf];
                    }
                    toRemove.push(mf);
                    toAdd[ff.id] = new MatchField({ ...mf, field_id: ff.id, field: ff });
                }
            });
        });
        toRemove.forEach(mfRem => {
            const i = this.matchFields.findIndex(mf => mf.id === mfRem.id);
            if (i !== -1) {
                this.matchFields.splice(i, 1);
            }
        });
        for (const ffId in toAdd) {
            if (toAdd.hasOwnProperty(ffId)) {
                const matchFieldWithFakeField = toAdd[ffId];
                if (matchFieldWithFakeField) {
                    this.matchFields.push(matchFieldWithFakeField);
                }
            }
        }
    }

    // For each matchField with a fakeField attached, split it into however many real fields it came from
    handleFakeFieldsForBackEndOrState() {
        const toAdd = {};
        const toRemove = {};
        this.matchFields.forEach(mf => {
            fakeFields.forEach(ff => {
                if (ff.id === mf.field_id) {
                    const realMatchFields = this.realFieldMap[ff.id];
                    realMatchFields.forEach(rmf => {
                        // return the original matchFields to the array, but with the new matches
                        if (toAdd[rmf.id]) {
                            toAdd[rmf.id] = new MatchField({ ...rmf, [mf.getLedgerField(this.ledgerType)]: mf.getLedgerFieldValue(this.ledgerType) });
                        } else {
                            toAdd[rmf.id] = new MatchField({ ...rmf, [mf.getLedgerField(this.ledgerType)]: mf.getLedgerFieldValue(this.ledgerType) });
                        }
                        toRemove[mf.id] = mf;
                    });
                }
            });
        });
        for (const mfId in toRemove) {
            if (toRemove.hasOwnProperty(mfId)) {
                const mfRem = toRemove[mfId];
                const i = this.matchFields.findIndex(mf => mf.id === mfRem.id);
                if (i !== -1) {
                    this.matchFields.splice(i, 1);
                }
            }
        }

        for (const rmfId in toAdd) {
            if (toAdd.hasOwnProperty(rmfId)) {
                const realMatchField = toAdd[rmfId];
                if (realMatchField) {
                    this.matchFields.push(realMatchField);
                }
            }
        }
    }

    applyMatches(matches) {
        this.matchFields.forEach(mf => mf.setLedgerValue(this.ledgerType, null));

        for (const ledgerField in matches) {
            if (matches.hasOwnProperty(ledgerField)) {
                const matchFieldId = matches[ledgerField];

                if (matchFieldId) {
                    const matchField = this.matchFields.find(mf => mf.id === matchFieldId);
                    matchField.setLedgerValue(this.ledgerType, ledgerField);
                }
            }
        }
    }

    selectMatchFields() {
        return this.store.select(matchFieldSelectors.getWithField).pipe(filter(mf => !!mf && !!mf.length), map(matchFieldsWithFields => {
            this.matchFields = [...matchFieldsWithFields];
            this.realFieldMap = {};
            this.renameFieldsForFrontEnd();
            this.handleFakeFieldsForFrontEnd();
            this.matchFields = this.matchFields.filter(f => !unmatchableFields.includes(f.field.canonical_name));
            return this.matchFields;
        }));
    }

    setAllDefault() {
        this.matchFields.forEach(mf => mf.setDefault(this.ledgerType));
    }

    setLedgerType(type) {
        this.ledgerType = type;
    }

    updateAll() {
        this.handleFakeFieldsForBackEndOrState();
        const selectedFields = JSON.parse(localStorage.getItem('selectedTransactionFields'));
        if (!selectedFields) {
            const selectedMatchFields = this.matchFields.filter(mf => mf.getLedgerFieldValue(this.ledgerType)).map(field => field.field_id);
            this.store.dispatch(new fieldActions.UpdateTransactionFieldsAction(selectedMatchFields));
        }
        const matchFields = this.matchFields.map((mf => (new MatchField({ ...mf, field: null }))))
        this.store.dispatch(new matchFieldActions.UpdateMultipleAction({ franchiseId: this.franchiseId, matchFields }));
        return this.store.select(fromRoot.getMatchFieldsSaving).pipe(filter(saving => saving !== ActionStatus.Loading), take(1)).toPromise();
    }

    setAll() {
        this.handleFakeFieldsForBackEndOrState();
        this.store.dispatch(new matchFieldActions.SetMultipleAction(this.matchFields));
    }

    vetMatchFields() {
        let countMatches = 0;
        let countRequiredUnmatched = 0;
        let countNameDescription = 0;
        this.matchFields.forEach(mf => {
            if (mf.getLedgerFieldValue(this.ledgerType)) {
                countMatches++;
            } else if (mf.required) {
                if (mf.field.canonical_name === 'name' || mf.field.canonical_name === 'description') {
                    countNameDescription++;
                    if (countNameDescription === 2) {
                        countRequiredUnmatched = countRequiredUnmatched + 2;
                    }
                } else {
                    countRequiredUnmatched++;
                }
            }
        });
        return { countMatches, countRequiredUnmatched };
    }

    getMatchObjForTransactions(): { [ledgerFieldName: string]: number } {
        this.matchFields = [...this.matchFields];
        this.handleFakeFieldsForFrontEnd();

        return this.matchFields.reduce((matchObj, mf) => {
            if (mf && mf.getLedgerFieldValue(this.ledgerType)) {
                return Object.assign(matchObj, { [mf.getLedgerFieldValue(this.ledgerType)]: mf.field_id });
            } else {
                return matchObj;
            }
        }, {});
    }
}
