import { ActionStatus } from '../types';
import { PicklistOption } from './picklist-option.model';
import * as picklistOptionActions from './picklist-option.actions';
import { getIdsAndEntities, sortStrings } from '../utils';
import { createSelector } from 'reselect';

export interface State {
    ids: number[];
    entities: {
        [id: number]: PicklistOption
    };
    saving: ActionStatus
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Inactive
};

export function reducer(state = initialState, action: picklistOptionActions.Actions): State {
    switch (action.type) {
        case picklistOptionActions.LIST_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload);
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities },
                saving: ActionStatus.Complete
            }
        };

        case picklistOptionActions.ADD_COMPLETE: {
            const picklistOption = action.payload;

            let sortIds = [...state.ids];

            let picklistOptionObj = {};
            picklistOptionObj[picklistOption.id] = picklistOption;
            sortIds.push(picklistOption.id);

            const alterStateWith = {
                ids: sortIds,
                entities: {
                    ...state.entities,
                    picklistOption
                }
            };
            return {
                ...state,
                ...alterStateWith,
                saving: ActionStatus.Complete
            }
        };

        case picklistOptionActions.UPDATE_COMPLETE: {
            const picklistOption = action.payload;
            let sortIds = [...state.ids];

            let picklistOptionObj = {};
            picklistOptionObj[picklistOption.id] = picklistOption;

            const alterStateWith = {
                ids: sortIds,
                entities: {
                    ...state.entities,
                    ...picklistOption
                }
            }

            return {
                ...state,
                ...alterStateWith,
                saving: ActionStatus.Complete
            }

        };

        case picklistOptionActions.DELETE_COMPLETE: {
            const id = action.payload;

            let sortIds = [...state.ids];

            const entitiesCopy = { ...state.entities };
            sortIds = sortIds.filter(sId => sId !== id);
            delete entitiesCopy[id];

            return {
                ...state,
                entities: entitiesCopy,
                ids: sortIds,
                saving: ActionStatus.Complete
            }
        };

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;

export const getPicklistOptions = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]).sort((a, b) => sortStrings(a.value, b.value)));
export const getSaving = (state: State) => state.saving;