import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as warehouseSubLocationActions from './warehouse-sub-location.actions';
import { WarehouseSubLocation } from './warehouse-sub-location.model';

@Injectable()
export class WarehouseSubLocationEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseSubLocationActions.ADD),
            map((action: warehouseSubLocationActions.AddAction) => action.payload),
            switchMap(payload => this.http.post(`api/warehouse-sub-locations`, payload).pipe(
                map(res => new WarehouseSubLocation(res)),
                map((newWarehouseSubLoc: WarehouseSubLocation) => new warehouseSubLocationActions.AddCompleteAction(newWarehouseSubLoc)),
                catchError(error => observableOf(new warehouseSubLocationActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseSubLocationActions.DELETE),
            map((action: warehouseSubLocationActions.DeleteAction) => action.payload),
            switchMap(warehouseSubLocData => this.http.delete(`api/warehouse-sub-locations/${warehouseSubLocData.id}`).pipe(
                map(() => new warehouseSubLocationActions.DeleteCompleteAction(warehouseSubLocData)),
                catchError((error) => observableOf(new warehouseSubLocationActions.DeleteFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseSubLocationActions.UPDATE),
            map((action: warehouseSubLocationActions.UpdateAction) => action.payload),
            switchMap(payload => this.http.put(`api/warehouse-sub-locations/${payload.id}`, payload).pipe(
                map(res => new WarehouseSubLocation(res)),
                map((warehouseSubLoc: WarehouseSubLocation) => new warehouseSubLocationActions.UpdateCompleteAction(warehouseSubLoc)),
                catchError(error => observableOf(new warehouseSubLocationActions.UpdateFailedAction({ error }))))
            )));

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseSubLocationActions.ADD_FAILED, warehouseSubLocationActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
