import { Action } from '@ngrx/store';
import { Customer, Event } from '../models';

export const ADD = '[Customer] Add';
export const ADD_COMPLETE = '[Customer] Add Complete';
export const ADD_FAILED = '[Customer] Add Failed';

export const ADD_TO_EVENT = '[Customer] Add To Event';
export const ADD_TO_EVENT_COMPLETE = '[Customer] Add To Event Complete';
export const ADD_TO_EVENT_FAILED = '[Customer] Add To Event Failed';

export const DELETE = '[Customer] Delete';
export const DELETE_COMPLETE = '[Customer] Delete Complete';
export const DELETE_FAILED = '[Customer] Delete Failed';

export const LIST_BY_DIVISION = '[Customers] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[Customers] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[Customers] List By Division Failed';

export const UPDATE = '[Customers] Update';
export const UPDATE_COMPLETE = '[Customers] Update Complete';
export const UPDATE_FAILED = '[Customers] Update Failed';

export const SORT = '[Customers] Sort Customers';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: Customer) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Customer) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: any) { }
}

export class AddToEventAction implements Action {
    readonly type = ADD_TO_EVENT;

    constructor(public payload: { customer: Customer, eventId: number }) { }
}

export class AddToEventCompleteAction implements Action {
    readonly type = ADD_TO_EVENT_COMPLETE;

    constructor(public payload: { customer: Customer, event: Event }) { }
}

export class AddToEventFailedAction implements Action {
    readonly type = ADD_TO_EVENT_FAILED;

    constructor(public payload: any) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: Customer) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Customer) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: any }) { }
}
export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: number) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: Customer[]) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Customer) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Customer) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export class SortAction implements Action {
    readonly type = SORT;

    constructor(public payload: {
        divisionId: number,
        field: string,
        order: number,
        args: any[]
    }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | AddToEventAction
    | AddToEventCompleteAction
    | AddToEventFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SortAction;
