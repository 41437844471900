import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import * as assetModalActions from '../../core/store/asset-modal/asset-modal.actions';
import * as studioActions from '../../core/store/studios/studio.actions';
import { ActionStatus } from '../../core/store/types';
import { User } from '../../core/store/users/user.model';
import * as divisionActions from './../../core/store/divisions/division.actions';

@Injectable()
export class DivisionDataService {
    constructor(private store: Store<fromRoot.State>) { }

    getAllDivisions() {
        let dispatched = false;
        const combined = combineLatest([this.store.select(fromRoot.getSelectedStudio), this.store.select(fromRoot.getAllDivisions), this.store.select(fromRoot.getDivisionListing)]);
        return combined.pipe(filter(([studio, allDivisions, listing]) => {
            if (!dispatched) {
                dispatched = true;
                this.store.dispatch(new divisionActions.ListByStudioAction(studio.id));
                return false;
            }
            if (listing === ActionStatus.Loading) {
                return false;
            }
            return true;
        }), map(([_, allDivisions]) => {
            return allDivisions;
        }), take(1));
    }

    getSelectedDivision(route: ActivatedRouteSnapshot) {
        const paramDivisionId = parseInt(route.params.divisionId, 10);

        return combineLatest([
            this.store.select(fromRoot.getUser),
            this.store.select(fromRoot.getSelectedStudio),
            this.store.select(fromRoot.getSelectedDivision)
        ]).pipe(filter(([_, studio, division]) => {
            if (!division || division.id !== paramDivisionId) {
                this.store.dispatch(new divisionActions.SelectAction(paramDivisionId));
                return false;
            }

            if (!studio || studio.id !== division.studio_id) {
                this.store.dispatch(new studioActions.SelectAction(division.studio_id));
                return false;
            }

            return true;
        }), map(([user, _, division]) => {
            if (division.id !== user.current_division_id || division.studio_id !== user.current_studio_id) {
                const userCopy = new User(user);
                userCopy.current_studio_id = division.studio_id;
                userCopy.current_franchise_id = null;
                userCopy.current_division_id = division.id;
                this.store.dispatch(new assetModalActions.SelectFranchiseAction(null));
                this.store.dispatch(new assetModalActions.SelectProjectAction(null));
                this.store.dispatch(new assetModalActions.SelectLocationAction(null));
                this.store.dispatch(new assetModalActions.SelectSubLocationAction(null));
            }

            return division;
        }), take(1));
    }
}
