import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as assetImportsActions from './asset-imports.actions';
import { Asset } from '../models';
import { AssetImports } from './asset-imports.model';
import * as layoutActions from '../layout/layout.actions';

@Injectable()
export class AssetImportEffects {

    createFromImports$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetImportsActions.ADD_ASSETS_IMPORTS),
            map((action: assetImportsActions.AddAssetsImportsAction) => action.payload),
            switchMap((payload) => this.http.post<Asset[]>(`/api/studio/${payload.studioId}/asset-imports/${payload.importId}/assets`, {
                division_id: payload.divisionId, warehouse_id: payload.warehouseId, franchise_id: payload.franchiseId, project_id: payload.projectId, origin_currency: payload.originCurrency
            }).pipe(
                map((result) => {
                    return new assetImportsActions.AddAssetsImportsCompletedAction({ assets: result, franchiseId: payload.franchiseId, divisionId: payload.divisionId });
                }),
                catchError((error) => observableOf(new assetImportsActions.AddAssetsImportsFailedAction({ error }))))
            )));

    listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetImportsActions.LIST_BY_FRANCHISE),
            map((action: assetImportsActions.ListByFranchiseAction) => action.payload),
            switchMap((franchiseId) => this.http.get<AssetImports[]>(`/api/franchises/${franchiseId}/asset-imports`).pipe(
                map(res => res.map((ai) => new AssetImports(ai))),
                map((assetImports: AssetImports[]) => new assetImportsActions.ListByFranchiseCompleteAction(assetImports)),
                catchError((error) => observableOf(new assetImportsActions.ListByFranchiseFailedAction({ error, franchiseId }))))
            )));

    listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetImportsActions.LIST_BY_DIVISION),
            map((action: assetImportsActions.ListByDivisionAction) => action.payload),
            switchMap((divisionId) => this.http.get<AssetImports[]>(`/api/divisions/${divisionId}/asset-imports`).pipe(
                map(res => res.map((ai) => new AssetImports(ai))),
                map((assetImports: AssetImports[]) => new assetImportsActions.ListByDivisionCompleteAction(assetImports)),
                catchError((error) => observableOf(new assetImportsActions.ListByDivisionFailedAction({ error, divisionId }))))
            )));

    delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetImportsActions.DELETE),
            map((action: assetImportsActions.DeleteAction) => action.payload),
            switchMap((ai) => this.http.delete(`/api/asset-imports/${ai.id}`).pipe(
                map((result: AssetImports) => new assetImportsActions.DeleteCompleteAction(result)),
                catchError((error) => observableOf(new assetImportsActions.DeleteFailedAction({ error }))))
            )));

    deleteFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetImportsActions.DELETE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))
        ));


    constructor(private actions$: Actions, private http: HttpClient) { }
}
