import { createSelector } from 'reselect';
import * as studioActions from './studio.actions';
import { Studio } from './studio.model';

export interface State {
    studio: Studio;
    studioList: Studio[];
    selectedStudioId: number | null;
}

export const initialState = {
    studio: null,
    studioList: null,
    selectedStudioId: null
};

export function reducer(state = initialState, action: studioActions.Actions): State {
    switch (action.type) {
        case studioActions.GET_COMPLETE: {
            const studio = action.payload;
            return {
                ...state,
                studio
            };
        }

        case studioActions.LIST_BY_USER_COMPLETE: {
            const studioList = action.payload;
            return {
                ...state,
                studioList
            };
        }

        case studioActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedStudioId) {
                newState = {
                    ...state,
                    selectedStudioId: action.payload
                };
            }
            return newState;
        }

        case studioActions.UPDATE_STUDIO_COMPLETE: {
            const studioList = [...state.studioList];
            const index = studioList.findIndex(s => s.id === action.payload.id);
            studioList[index] = action.payload;
            return {
                ...state,
                studioList
            }
        }

        default: {
            return state;
        }
    }
}

export const getStudio = (state: State) => state.studio;
export const getStudioList = (state: State) => state.studioList;
export const getSelectedStudioId = (state: State) => state.selectedStudioId;

export const getSelectedStudio = createSelector(getStudioList, getSelectedStudioId, (studios, selectedId) => {
    return studios && selectedId ? studios.find(studio => studio.id === selectedId) : null;
});
