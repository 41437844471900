import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import * as layout from '../../../core/store/layout/layout.actions';

@Component({
  selector: 'ah-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss']
})
export class RemoveModalComponent implements OnInit {

  messageList = [];
  title: string;
  modalMessage: string;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<RemoveModalComponent>) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.modalMessage = this.data.message;

    for (let asset of this.data.assets) {
      let message = [];
      if (asset.franchise) {
        message.push(`Features / Series: ${asset.franchise}`);
      }
      if (asset.division) {
        if (message.length > 0)
          message.push(', ');
        message.push(`Division: ${asset.division}`);
      }
      this.messageList.push(message.join(""));
    }

    this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      return new layout.InfoAction({ message: "Disposition has been deleted." });
    });
  }

}
