import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import * as layoutActions from '../layout/layout.actions';
import * as userActions from './user.actions';
import { User } from './user.model';

@Injectable()
export class UserEffects {
     get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.GET),
            switchMap(() => this.http.get(`/api/users`).pipe(
                map(res => new User(res)),
                map(userModel => {
                    this.localStorageService.updateLocalStorage(userModel);
                    return new userActions.GetCompleteAction(userModel);
                }),
                catchError((error) => observableOf(new userActions.GetFailedAction({ error }))))
            )));

     listAllStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.LIST_ALL_STUDIO),
            map((action: userActions.ListAllStudioAction) => action.payload),
            switchMap((studioId) => this.http.get<User[]>(`/api/studios/${studioId}/users/all`).pipe(
                map(res => res.map((u) => new User(u))),
                map((users: User[]) => new userActions.ListAllStudioCompleteAction({ users, studioId })),
                catchError((error) => observableOf(new userActions.ListAllStudioFailedAction({ error, studioId }))))
            )));

     listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.LIST_BY_FRANCHISE),
            map((action: userActions.ListByFranchiseAction) => action.payload),
            switchMap((franchise) => this.http.get<User[]>(`/api/franchises/${franchise.id}/users`).pipe(
                map(res => res.map((u) => new User(u))),
                map((users: User[]) => new userActions.ListByFranchiseCompleteAction({ users, franchise })),
                catchError((error) => observableOf(new userActions.ListByFranchiseFailedAction({ error, franchise }))))
            )));

     listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.LIST_BY_DIVISION),
            map((action: userActions.ListByDivisionAction) => action.payload),
            switchMap((division) => this.http.get<User[]>(`/api/divisions/${division.id}/users`).pipe(
                map(res => res.map((u) => new User(u))),
                map((users: User[]) => new userActions.ListByDivisionCompleteAction({ users, division })),
                catchError((error) => observableOf(new userActions.ListByDivisionFailedAction({ error, division }))))
            )));

     listByStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.LIST_BY_STUDIO),
            map((action: userActions.ListByStudioAction) => action.payload),
            switchMap((studioId) => this.http.get<User[]>(`/api/studios/${studioId}/users`).pipe(
                map(res => res.map((u) => new User(u))),
                map((users: User[]) => new userActions.ListByStudioCompleteAction({ users, studioId })),
                catchError((error) => observableOf(new userActions.ListByStudioFailedAction({ error, studioId }))))
            )));

     listFinalApprovers$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.LIST_FINAL_APPROVERS),
            map((action: userActions.ListFinalApproversAction) => action.payload),
            switchMap((data) => this.http.post<User[]>(`/api/studios/${data.studio_id}/final-approvers`, { request_id: data.request_id, assetIds: data.assetIds }).pipe(
                map(res => res.map((u) => new User(u))),
                map((users: User[]) => new userActions.ListFinalApproversCompleteAction({ users })),
                catchError((error) => observableOf(new userActions.ListFinalApproversFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.UPDATE),
            map((action: userActions.UpdateAction) => action.payload),
            mergeMap(payload => {
                const { user, selectedStudioId } = payload;
                return this.http.put(`/api/users/${user.id}`, { user: user.getObject(), selectedStudioId }).pipe(
                    map(res => new userActions.UpdateCompleteAction(new User(res))),
                    catchError(error => observableOf(new userActions.UpdateFailedAction({ error }))));
            })));

     updateDivisionPermission$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.UPDATE_DIVISION_PERMISSION),
            map((action: userActions.UpdateDivisionPermissionAction) => action.payload),
            mergeMap(divisionPermission => {
                return this.http.put(`/api/division-permissions/${divisionPermission.id}`, divisionPermission).pipe(
                    map(res => new userActions.UpdateCompleteAction(new User(res))),
                    catchError(error => observableOf(new userActions.UpdateDivisionPermissionFailedAction({ error }))));
            })));

     updateFranchisePermission$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.UPDATE_FRANCHISE_PERMISSION),
            map((action: userActions.UpdateFranchisePermissionAction) => action.payload),
            mergeMap(franchisePermission => {
                return this.http.put(`/api/franchise-permissions/${franchisePermission.id}`, franchisePermission).pipe(
                    map(res => new userActions.UpdateCompleteAction(new User(res))),
                    catchError(error => observableOf(new userActions.UpdateFranchisePermissionFailedAction({ error }))));
            })));

     updateStudioPermission$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.UPDATE_STUDIO_PERMISSION),
            map((action: userActions.UpdateStudioPermissionAction) => action.payload),
            mergeMap(studioPermission => {
                return this.http.put(`/api/studio-permissions/${studioPermission.id}`, studioPermission).pipe(
                    map(res => new userActions.UpdateCompleteAction(new User(res))),
                    catchError(error => observableOf(new userActions.UpdateStudioPermissionFailedAction({ error }))));
            })));

     deleteDivisionPermission$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.DELETE_DIVISION_PERMISSION),
            map((action: userActions.DeleteDivisionPermissionAction) => action.payload),
            mergeMap(divisionPermission => {
                return this.http.delete(`/api/division-permissions/${divisionPermission.permission.id}`).pipe(
                    map(res => new userActions.DeleteDivisionPermissionCompleteAction({ user: new User(res), last: divisionPermission.last })),
                    catchError(error => observableOf(new userActions.DeleteDivisionPermissionFailedAction({ error }))));
            })));

     deleteFranchisePermission$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.DELETE_FRANCHISE_PERMISSION),
            map((action: userActions.DeleteFranchisePermissionAction) => action.payload),
            mergeMap(p => {
                return this.http.delete(`/api/franchise-permissions/${p.permission.id}`).pipe(
                    map(res => new userActions.DeleteFranchisePermissionCompleteAction({ user: new User(res), last: p.last })),
                    catchError(error => observableOf(new userActions.DeleteFranchisePermissionFailedAction({ error }))));
            })));

     deleteStudioPermission$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.DELETE_STUDIO_PERMISSION),
            map((action: userActions.DeleteStudioPermissionAction) => action.payload),
            mergeMap(studioPermission => {
                return this.http.delete(`/api/studio-permissions/${studioPermission.permission.id}`).pipe(
                    map(res => new userActions.DeleteStudioPermissionCompleteAction(new User(res))),
                    catchError(error => observableOf(new userActions.DeleteStudioPermissionFailedAction({ error }))));
            })));

     enableStudioUsersMFA$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.ENABLE_STUDIO_USERS_MFA),
            map((action: userActions.EnableStudioUsersMFAAction) => action.payload),
            mergeMap(payload => {
                return this.http.post(`/api/studio/${payload.studio_id}/enable-mfa`, {}).pipe(
                    map(res => new userActions.EnableStudioUsersMFACompleteAction({ status: res["status"] })),
                    catchError(error => observableOf(new userActions.EnableStudioUsersMFAFailedAction({ error })))
                )
            })
        ))

     disableStudioUsersMFA$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.DISABLE_STUDIO_USERS_MFA),
            map((action: userActions.DisableStudioUsersMFAAction) => action.payload),
            mergeMap(payload => {
                return this.http.post(`/api/studio/${payload.studio_id}/disable-mfa`, {}).pipe(
                    map(res => new userActions.DisableStudioUsersMFACompleteAction({ status: res["status"] })),
                    catchError(error => observableOf(new userActions.DisableStudioUsersMFAFailedAction({ error })))
                )
            })
        ))

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(userActions.GET_FAILED, userActions.LIST_ALL_STUDIO_FAILED, userActions.LIST_BY_FRANCHISE_FAILED, userActions.LIST_BY_DIVISION_FAILED, userActions.LIST_BY_STUDIO_FAILED, userActions.LIST_FINAL_APPROVERS_FAILED, userActions.UPDATE_FAILED, userActions.UPDATE_STUDIO_PERMISSION_FAILED, userActions.UPDATE_FRANCHISE_PERMISSION_FAILED, userActions.UPDATE_DIVISION_PERMISSION_FAILED, userActions.DELETE_STUDIO_PERMISSION_FAILED, userActions.DELETE_FRANCHISE_PERMISSION_FAILED, userActions.DELETE_DIVISION_PERMISSION_FAILED, userActions.ENABLE_STUDIO_USERS_MFA_FAILED, userActions.DISABLE_STUDIO_USERS_MFA_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient, private localStorageService: LocalStorageService) { }
}
