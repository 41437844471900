import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class GuardHelperService {
    static getRouteParam(paramName: string, route: ActivatedRouteSnapshot) {
        let paramValue = null;
        let currentRoute = route;
        while (currentRoute) {
            if (currentRoute.params[paramName] && currentRoute.params[paramName] !== 'null') {
                paramValue = parseInt(currentRoute.params[paramName], 10);
                break;
            } else {
                currentRoute = currentRoute.parent;
            }
        }
        return paramValue;
    }
}
