import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as assetTransactionActions from './asset-transaction.action';
import { AssetTransaction } from './asset-transaction.model';
import { Observable, of as observableOf } from 'rxjs';
import * as layoutActions from '../layout/layout.actions';
import { Transaction } from '../models';

@Injectable()
export class AssetTransactionEffects {
     listAssetTransactions$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetTransactionActions.LIST_ASSET_TRANSACTION),
            map((action: assetTransactionActions.ListAssetTransactionAction) => action.payload),
            switchMap((data) => this.http.post<AssetTransaction[]>('/api/asset-transactions/assets', data.assetIds).pipe(
                map(res => {
                    const assetTransaction = res.map((at) => new AssetTransaction(at));
                    return new assetTransactionActions.ListAssetTransactionCompleteAction({ assetTransaction: assetTransaction })
                }),
                catchError((error) => observableOf(new assetTransactionActions.ListAssetTransactionFailedAction({ error }))))
            )
        ))

     getAssetTransactions$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetTransactionActions.GET_ASSET_TRANSACTION),
            map((action: assetTransactionActions.GetAssetTransactionAction) => action.payload),
            switchMap((assetId) => this.http.get<Transaction[]>(`/api/list-asset-transactions/${assetId}`).pipe(
                map(res => {
                    const transactions = res.map((transactions) => new Transaction(transactions));
                    return new assetTransactionActions.GetAssetTransactionCompleteAction({ transactions })
                }),
                catchError((error) => observableOf(new assetTransactionActions.GetAssetTransactionfailedAction({ error })))
            ))
        ))

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetTransactionActions.LIST_ASSET_TRANSACTION_FAILED, assetTransactionActions.GET_ASSET_TRANSACTION_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));


    constructor(private actions$: Actions, private http: HttpClient) { }
}