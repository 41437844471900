import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import { ActionStatus, Severity } from '../../core/store/types';
import * as layoutActions from './../../core/store/layout/layout.actions';
import * as transactionActions from './../../core/store/transactions/transaction.actions';
import { Transaction } from './../../core/store/transactions/transaction.model';

@Injectable()
export class TransactionDataService {
    constructor(private store: Store<fromRoot.State>) { }

    /** For the split page */
    getTransaction(id: number) {
        this.store.dispatch(new transactionActions.SelectAction(id));
        return this.store.select(fromRoot.getSelectedTransaction).pipe(filter(selectedTransaction => {
            if (!selectedTransaction) {
                this.store.dispatch(new transactionActions.GetAction([id]));
                return false;
            }
            return true;
        }), take(1));
    }

    /** For the match pages */
    getTransactions(ids: number[]) {
        this.store.dispatch(new transactionActions.SelectToMatchAction(ids));
        return this.store.select(fromRoot.getSelectedTransactionsToMatch).pipe(filter(selectedTransactions => {
            if (!selectedTransactions) {
                this.store.dispatch(new transactionActions.GetAction(ids));
                return false;
            }
            return true;
        }), take(1));
    }

    bulkSaveTransactions(transactions: Transaction[], dialogRef, infoAction: layoutActions.InfoAction) {
        const store = this.store;
        dialogRef.componentInstance.saveDisabled = true;
        if (transactions.length) {
            this.store.dispatch(new transactionActions.UpdateMultipleAction({ franchiseId: transactions[0].franchise_id, transactions }));
        }
        this.store.select(fromRoot.getTransactionSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                    store.dispatch(infoAction);
                } else {
                    dialogRef.componentInstance.saveDisabled = false;
                    dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Save Failed' }];
                }
            });
    }
}
