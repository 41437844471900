import { createSelector } from 'reselect';
import * as characterActions from '../characters/character.actions';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as projectCharacterActions from './project-character.actions';
import { ProjectCharacter } from './project-character.model';

export interface State {
    ids: number[];
    entities: { [projectCharacterId: number]: ProjectCharacter };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: projectCharacterActions.Actions | characterActions.Actions): State {
    switch (action.type) {
        case characterActions.LIST_BY_FRANCHISE_COMPLETE: {
            const characters = action.payload;
            const projectChars = characters.reduce((pcs, char) => char.projectCharacters ? [...pcs, ...char.projectCharacters] : pcs, []);
            if (projectChars.length) {
                const { ids, entities } = getIdsAndEntities(state, projectChars);
                const allEnts = { ...state.entities, ...entities };
                const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'code' }], 1);
                return {
                    ...state,
                    ids: sortedIds,
                    entities: allEnts
                };
            }
            return state;
        }

        case characterActions.ADD_COMPLETE: {
            const projectChars = action.payload.projectCharacters;
            const { ids, entities } = getIdsAndEntities(state, projectChars);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'code' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case projectCharacterActions.UPDATE_COMPLETE:
        case projectCharacterActions.ADD_COMPLETE: {
            const projectChar = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [projectChar]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'code' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case projectCharacterActions.DELETE_COMPLETE: {
            const projectCharId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[projectCharId];
            return {
                ...state,
                ids: state.ids.filter(id => id !== projectCharId),
                entities: entitiesCopy
            };
        }

        case characterActions.DELETE_COMPLETE: {
            const charId = action.payload.id;
            const idsToDelete = [];
            const entitiesCopy = { ...state.entities };
            Object.keys(state.entities).forEach(key => {
                const projChar = state.entities[key];
                if (projChar.character_id === charId) {
                    idsToDelete.push(projChar.id);
                    delete entitiesCopy[key];
                }
            });
            return {
                ...state,
                ids: state.ids.filter(id => idsToDelete.indexOf(id) === -1),
                entities: entitiesCopy
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
