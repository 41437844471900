import { BaseModel } from '../base/base.model';

export class CustomStudioFieldName extends BaseModel {
    id: number;
    studio_id: number = null;
    franchise_id = 'Feature';
    project_id = 'Project';
    series_id = 'Series';
    season_id = 'Season';
    division_id = 'Division';
    warehouse_id = 'Warehouse';
    franchises = 'Features';
    projects = 'Projects';
    series = 'Series';
    seasons = 'Seasons';
    divisions = 'Divisions';
    warehouses = 'Warehouses';
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(fieldObj) {
        super();
        this.initializeData(fieldObj, this);
    }
}
