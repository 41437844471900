import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as franchiseActions from './franchise.actions';
import { Franchise } from './franchise.model';

@Injectable()
export class FranchiseEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.ADD),
            map((action: franchiseActions.AddAction) => {
                const franchiseData: any = {
                    ...action.payload.franchise,
                    project: null
                };
                if (action.payload.project) {
                    franchiseData.project = { ...action.payload.project };
                }
                return franchiseData;
            }),
            switchMap((franchiseData) => this.http.post(`/api/franchises`, franchiseData).pipe(
                map(res => new Franchise(res)),
                map((newFranchise: Franchise) => new franchiseActions.AddCompleteAction(newFranchise)),
                catchError((error) => observableOf(new franchiseActions.AddFailedAction({ error, studioId: franchiseData.studio_id }))))
            )));

     addFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.ADD_FAILED),
            map((action: franchiseActions.AddFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.GET),
            map((action: franchiseActions.GetAction) => action.payload),
            switchMap((franId) => this.http.get(`/api/franchises/${franId}`).pipe(
                map(res => {
                    const response = res;
                    return new Franchise(response);
                }),
                map((franchise: Franchise) => new franchiseActions.GetCompleteAction(franchise)),
                catchError((error) => observableOf(new franchiseActions.GetFailedAction({ error }))))
            )));

     getFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.GET_FAILED),
            map((action: franchiseActions.GetFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.LIST),
            map((action: franchiseActions.ListAction) => action.payload),
            mergeMap(({ studioId, onlyOwnerOptions }) => this.http.get<Franchise[]>(`/api/studios/${studioId}/franchises?only_owned=${onlyOwnerOptions ? 1 : 0}`).pipe(
                map(res => {
                    const response = res;
                    return response.map((f) => new Franchise(f));
                }),
                map((franchises: Franchise[]) => new franchiseActions.ListCompleteAction({ franchises, studioId, onlyOwnerOptions })),
                catchError((error) => observableOf(new franchiseActions.ListFailedAction({ error, studioId }))))
            )));

     listFranchiseDivisionUser$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.LIST_FRANCHISE_DIVISION_USER),
            map((action: franchiseActions.ListAction) => action.payload),
            mergeMap(({ studioId, onlyOwnerOptions }) => this.http.get<Franchise[]>(`/api/studio/${studioId}/list_franchises`).pipe(
                map(res => {
                    const response = res;
                    return response.map((f) => new Franchise(f));
                }),
                map((franchises: Franchise[]) => new franchiseActions.ListFranchiseDivisionUserCompleteAction({ franchises, studioId, onlyOwnerOptions })),
                catchError((error) => observableOf(new franchiseActions.ListFranchiseDivisionUserFailedAction({ error, studioId }))))
            )));

     listFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.LIST_FAILED || franchiseActions.LIST_FRANCHISE_DIVISION_USER_FAILED),
            map((action: franchiseActions.ListFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.UPDATE),
            map((action: franchiseActions.UpdateAction) => action.payload),
            switchMap((franchise) => this.http.put(`/api/franchises/${franchise.id}`, franchise).pipe(
                map(res => {
                    const response = res;
                    return new Franchise(response);
                }),
                map((f: Franchise) => new franchiseActions.UpdateCompleteAction(f)),
                catchError((error) => observableOf(new franchiseActions.UpdateFailedAction({ error, studioId: franchise.studio_id }))))
            )));

     updateFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseActions.UPDATE_FAILED),
            map((action: franchiseActions.UpdateFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
