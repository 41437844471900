import { BaseModel } from './../base/base.model';

export class DivisionGroup extends BaseModel {
    id: number;
    division_id: number = null;
    group_id: number = null;

    constructor(divisionGroupObject) {
        super();
        this.initializeData(divisionGroupObject, this);
    }
}
