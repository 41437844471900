import { Action } from '@ngrx/store';
import { Request, RequestAsset } from '../models';

export const DELETE = '[Request Asset] Delete';
export const DELETE_COMPLETE = '[Request Asset] Delete Complete';
export const DELETE_FAILED = '[Request Asset] Delete Failed';

export const LIST_CONFLICTS = '[Request Asset] List Conflicts';
export const LIST_CONFLICTS_COMPLETE = '[Request Asset] List Conflicts Complete';
export const LIST_CONFLICTS_FAILED = '[Request Asset] List Conflicts Failed';

export const UPDATE = '[Request Asset] Update';
export const UPDATE_COMPLETE = '[Request Asset] Update Complete';
export const UPDATE_FAILED = '[Request Asset] Update Failed';

export const SELECT = '[Request Asset] Select Request Asset';

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: { request_id: number, assetIds: number[] }) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: { requestId: number, assetIds: number[] }) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}

export class ListConflictsAction implements Action {
    readonly type = LIST_CONFLICTS;

    constructor(public payload: { request_id: number, request_asset_id: number }) { }
}

export class ListConflictsCompleteAction implements Action {
    readonly type = LIST_CONFLICTS_COMPLETE;

    constructor(public payload: { requestAssetId: number, requests: Request[] }) { }
}

export class ListConflictsFailedAction implements Action {
    readonly type = LIST_CONFLICTS_FAILED;

    constructor(public payload: any) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { request_id: number, request_asset_ids: number[], approve: boolean }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { requestId: number, requestAssets: RequestAsset[] }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export class SelectRequestAssetAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export type Actions
    = DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListConflictsAction
    | ListConflictsCompleteAction
    | ListConflictsFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SelectRequestAssetAction;
