import { BaseModel } from './../base/base.model';

export class GroupTypes extends BaseModel {
    id: number;
    studio_id: number = null;
    name: string = null;
    canonical_name: string = null;

    constructor(groupTypesObject) {
        super();
        this.initializeData(groupTypesObject, this);
    }
}