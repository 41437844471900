import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as divisionGroupActions from './division-group.actions';

@Injectable()
export class DivisionGroupEffects {
    static BASE_URL = '/api/division-groups';

     addToDivisionGroup$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(divisionGroupActions.ADD_TO_DIVISION_GROUP),
            map((action: divisionGroupActions.AddToDivisionGroupAction) => action.payload),
            mergeMap(({ groupId, divisionId, assetIds }) => this.http.put(`/api/groups/${groupId}/assets`, { division_id: divisionId, assetIds }).pipe(
                map((res: number[]) => new divisionGroupActions.AddToDivisionGroupCompleteAction({ groupId, assetIds: res })),
                catchError((error) => observableOf(new divisionGroupActions.AddToDivisionGroupFailedAction({ error }))))
            )));

     deleteFromDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(divisionGroupActions.DELETE_FROM_DIVISION),
            map((action: divisionGroupActions.DeleteFromDivisionAction) => action.payload),
            mergeMap(({ groupId, divisionId }) => this.http.delete(`/api/groups/${groupId}?division_id=${divisionId}`).pipe(
                map((res: { groupId: number, assetIds: number[] }) => new divisionGroupActions.DeleteFromDivisionCompleteAction({ groupId: res.groupId, divisionId, assetIds: res.assetIds })),
                catchError((error) => observableOf(new divisionGroupActions.DeleteFromDivisionFailedAction({ error }))))
            )));

     getAssetCount$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(divisionGroupActions.GET_ASSET_COUNT),
            map((action: divisionGroupActions.GetAssetCountAction) => action.payload),
            switchMap(({ divisionId, groupId }) => this.http.get<number>(`/api/divisions/${divisionId}/groups/${groupId}/asset-count`).pipe(
                map((res) => new divisionGroupActions.GetAssetCountCompleteAction(res)),
                catchError((error) => observableOf(new divisionGroupActions.GetAssetCountFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionGroupActions.ADD_TO_DIVISION_GROUP_FAILED, divisionGroupActions.DELETE_FROM_DIVISION_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
