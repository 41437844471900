import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Set } from './set.model';

export const ADD = '[Set] Add';
export const ADD_COMPLETE = '[Set] Add Complete';
export const ADD_FAILED = '[Set] Add Failed';

export const DELETE = '[Set] Delete';
export const DELETE_COMPLETE = '[Set] Delete Complete';
export const DELETE_FAILED = '[Set] Delete Failed';

export const LIST = '[Set] List by Franchise';
export const LIST_SET = '[Set] List by Project';
export const LIST_COMPLETE = '[Set] List by Franchise Complete';
export const LIST_FAILED = '[Set] List by Franchise Failed';

export const UPDATE = '[Set] Update';
export const UPDATE_COMPLETE = '[Set] Update Complete';
export const UPDATE_FAILED = '[Set] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: Set) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Set) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: Set) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Set) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListSetAction implements Action {
    readonly type = LIST_SET;

    constructor(public payload: any) { }
}

export class ListSetFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}
export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: Set[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: HttpErrorResponse, franchiseId: number }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Set) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Set) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListSetAction
    | ListSetFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
