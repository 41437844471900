import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserDataService, UserInfo } from '../../data-services/user-data.service';
import { User } from '../../../core/store/models';
import { Store } from '@ngrx/store';
import * as userActions from '../../../core/store/users/user.actions';
import * as fromRoot from '../../../core/store';
import * as layoutActions from '../../../core/store/layout/layout.actions';
import { ActionStatus } from '../../../core/store/types';
@Component({
    selector: 'ah-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

    userInfo: UserInfo;
    userInfoObject: User;
    private subs: Subscription;

    constructor(private userDataService: UserDataService, private store: Store<fromRoot.State>) { }

    ngOnInit(): void {
        this.subs = new Subscription();

        this.userDataService.getUser().subscribe(user => {
            this.userInfo = {
                firstName: user.first_name,
                lastName: user.last_name,
                phone: user.phone,
                email: user.email
            }
            this.userInfoObject = user
        });
    }

    proceed(event: any) {
        const userObj = { ...this.userInfoObject };
        userObj.first_name = event.userInfo.firstName;
        userObj.last_name = event.userInfo.lastName;
        userObj.phone = event.userInfo.phone;
        delete userObj.permission;
        delete userObj.position;
        delete userObj.smart_id_mfa_federated;

        this.store.dispatch(new userActions.UpdateAction({ user: new User({ ...this.userInfoObject, ...userObj }), selectedStudioId: this.userInfoObject.current_studio_id }))
        const updateSub = this.store.select(fromRoot.getUserUpdating).subscribe(updating => {
            if (updating === ActionStatus.Complete) {
                this.store.dispatch(new layoutActions.InfoAction({ message: 'Your account information has been successfully updated.' }))
            }
        })
        this.subs.add(updateSub);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
