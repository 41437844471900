import * as franchiseActions from '../franchises/franchise.actions';
import { SortObject, TableFilterObject } from '../types';
import * as franchiseTrashedActions from './franchise-trash.actions';

export interface State {
    trashedAssetSort: {
        [franchiseId: number]: SortObject;
    };
    trashedAssetFilter: {
        [franchiseId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    trashedAssetSort: {},
    trashedAssetFilter: {}
};

export function reducer(state = initialState, action: franchiseTrashedActions.Actions | franchiseActions.Actions): State {
    switch (action.type) {
        case franchiseTrashedActions.SORT_FRANCHISE_TRASHED_LIST: {
            const franchiseId = action.payload.franchiseId;
            const assetSort = action.payload.sort;
            const currentSort = state.trashedAssetSort[franchiseId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[franchiseId] = { ...action.payload.sort };

            return {
                ...state,
                trashedAssetSort: { ...state.trashedAssetSort, ...sortObj }
            };
        }

        case franchiseTrashedActions.FILTER_FRANCHISE_TRASHED_LIST: {
            const franchiseId = action.payload.franchiseId;
            const assetFilter = action.payload.filter;
            const assetFilterObj = { ...state.trashedAssetFilter };
            const newFilter: TableFilterObject = {};

            if (!assetFilterObj[franchiseId]) {
                assetFilterObj[franchiseId] = {};
            }
            Object.keys(assetFilter).forEach(filter => {
                const value = assetFilter[filter].value;
                if (!newFilter[filter] && value && !(Array.isArray(value) && !value.length)) {
                    newFilter[filter] = assetFilter[filter];
                } else if (newFilter[filter]) {
                    if (value && !(Array.isArray(value) && !value.length)) {
                        newFilter[filter] = assetFilter[filter];
                    } else {
                        delete newFilter[filter];
                    }
                }
            });
            assetFilterObj[franchiseId] = { ...newFilter };

            return {
                ...state,
                trashedAssetFilter: { ...assetFilterObj }
            };
        }

        default: {
            return state;
        }
    }
}
