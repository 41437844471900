import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, ObservableInput, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as divisionIndexActions from '../division-asset-index/division-asset-index.actions';
import * as eventViewActions from '../event-view/event-view.actions';
import * as franchiseIndexActions from '../franchise-asset-index/franchise-asset-index.actions';
import * as groupViewActions from '../group-view/group-view.actions';
import * as matchAssetsActions from '../match-assets/match-assets.actions';
import * as matchReviewActions from '../match-review/match-review.actions';
import * as requestViewActions from '../request-view/request-view.actions';
import * as studioIndexActions from '../studio-asset-index/studio-asset-index.actions';
import * as transactionIndexActions from '../transaction-index/transaction-index.actions';
import * as subGroupViewActions from '../subgroup-view/subgroup-view.actions';

@Injectable()
export class AssetTableEffects {
     listTableState$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(
                divisionIndexActions.LIST_TABLE_STATE,
                divisionIndexActions.LIST_REVIEW_TABLE_STATE,
                eventViewActions.LIST_TABLE_STATE,
                franchiseIndexActions.LIST_TABLE_STATE,
                franchiseIndexActions.LIST_REVIEW_TABLE_STATE,
                groupViewActions.LIST_TABLE_STATE,
                matchAssetsActions.LIST_TABLE_STATE,
                matchReviewActions.LIST_TABLE_STATE,
                requestViewActions.LIST_TABLE_STATE,
                transactionIndexActions.LIST_TABLE_STATE,
                transactionIndexActions.LIST_EXCLUDED_TABLE_STATE,
                studioIndexActions.LIST_TABLE_STATE,
                subGroupViewActions.LIST_TABLE_STATE
            ),
            switchMap((action: divisionIndexActions.ListTableStateAction
                | divisionIndexActions.ListReviewTableStateAction
                | eventViewActions.ListTableStateAction
                | franchiseIndexActions.ListTableStateAction
                | franchiseIndexActions.ListReviewTableStateAction
                | groupViewActions.ListTableStateAction
                | matchAssetsActions.ListTableStateAction
                | matchReviewActions.ListTableStateAction
                | requestViewActions.ListTableStateAction
                | transactionIndexActions.ListTableStateAction
                | transactionIndexActions.ListExcludedTableStateAction
                | studioIndexActions.ListTableStateAction
                | subGroupViewActions.ListTableStateAction
            ): ObservableInput<Action> => {
                const tableState: TableState = JSON.parse(localStorage.getItem(action.payload.stateKey));

                switch (action.type) {
                    case divisionIndexActions.LIST_TABLE_STATE:
                        return observableOf(new divisionIndexActions.ListTableStateCompleteAction({ divisionId: action.payload.divisionId, tableState }));
                    case divisionIndexActions.LIST_REVIEW_TABLE_STATE:
                        return observableOf(new divisionIndexActions.ListReviewTableStateCompleteAction({ divisionId: action.payload.divisionId, tableState }))
                    case eventViewActions.LIST_TABLE_STATE:
                        return observableOf(new eventViewActions.ListTableStateCompleteAction({ eventId: action.payload.eventId, tableState }));
                    case franchiseIndexActions.LIST_TABLE_STATE:
                        return observableOf(new franchiseIndexActions.ListTableStateCompleteAction({ franchiseId: action.payload.franchiseId, tableState }));
                    case franchiseIndexActions.LIST_REVIEW_TABLE_STATE:
                        return observableOf(new franchiseIndexActions.ListReviewTableStateCompleteAction({ franchiseId: action.payload.franchiseId, tableState }));
                    case groupViewActions.LIST_TABLE_STATE:
                        return observableOf(new groupViewActions.ListTableStateCompleteAction({ groupId: action.payload.groupId, tableState }));
                    case matchAssetsActions.LIST_TABLE_STATE:
                        return observableOf(new matchAssetsActions.ListTableStateCompleteAction({ transactionId: action.payload.transactionId, tableState }));
                    case matchReviewActions.LIST_TABLE_STATE:
                        return observableOf(new matchReviewActions.ListTableStateCompleteAction({ transactionId: action.payload.transactionId, tableState }));
                    case requestViewActions.LIST_TABLE_STATE:
                        return observableOf(new requestViewActions.ListTableStateCompleteAction({ requestId: action.payload.requestId, tableState }));
                    case transactionIndexActions.LIST_TABLE_STATE:
                        return observableOf(new transactionIndexActions.ListTableStateCompleteAction({ franchiseId: action.payload.franchiseId, tableState }));
                    case transactionIndexActions.LIST_EXCLUDED_TABLE_STATE:
                        return observableOf(new transactionIndexActions.ListExcludedTableStateCompleteAction({ franchiseId: action.payload.franchiseId, tableState }));
                    case studioIndexActions.LIST_TABLE_STATE:
                        return observableOf(new studioIndexActions.ListTableStateCompleteAction({ studioId: action.payload.studioId, tableState }));
                    case subGroupViewActions.LIST_TABLE_STATE:
                        return observableOf(new subGroupViewActions.ListTableStateCompleteAction({ subGroupId: action.payload.subGroupId, tableState }))
                }
            })));

    constructor(private actions$: Actions) { }
}
