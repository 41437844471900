import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export const ADD_TO_FRANCHISE_GROUP = '[FranchiseGroup] Add To Franchise Group';
export const ADD_TO_FRANCHISE_GROUP_COMPLETE = '[FranchiseGroup] Add Franchise Group Complete';
export const ADD_TO_FRANCHISE_GROUP_FAILED = '[FranchiseGroup] Add Franchise Group Failed';

export const DELETE_FROM_FRANCHISE = '[FranchiseGroup] Delete Group from Franchise';
export const DELETE_FROM_FRANCHISE_COMPLETE = '[FranchiseGroup] Delete Group from Franchise Complete';
export const DELETE_FROM_FRANCHISE_FAILED = '[FranchiseGroup] Delete Group from Franchise Failed';

export const SELECT = '[FranchiseGroup] Select Franchise Group';

export const GET_ASSET_COUNT = '[FranchiseGroup] Get Asset Count';
export const GET_ASSET_COUNT_COMPLETE = '[FranchiseGroup] Get Asset Count Complete';
export const GET_ASSET_COUNT_FAILED = '[FranchiseGroup] Get Asset Count Failed';

export class AddToFranchiseGroupAction implements Action {
    readonly type = ADD_TO_FRANCHISE_GROUP;

    constructor(public payload: { groupId: number, franchiseId: number, assetIds: number[] }) { }
}

export class AddToFranchiseGroupCompleteAction implements Action {
    readonly type = ADD_TO_FRANCHISE_GROUP_COMPLETE;

    constructor(public payload: { groupId: number, assetIds: number[] }) { }
}

export class AddToFranchiseGroupFailedAction implements Action {
    readonly type = ADD_TO_FRANCHISE_GROUP_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteFromFranchiseAction implements Action {
    readonly type = DELETE_FROM_FRANCHISE;

    constructor(public payload: { groupId: number, franchiseId: number }) { }
}

export class DeleteFromFranchiseCompleteAction implements Action {
    readonly type = DELETE_FROM_FRANCHISE_COMPLETE;

    constructor(public payload: { groupId: number, franchiseId: number, assetIds: number[] }) { }
}

export class DeleteFromFranchiseFailedAction implements Action {
    readonly type = DELETE_FROM_FRANCHISE_FAILED;

    constructor(public payload: any) { }
}

export class SelectFranchiseGroupAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class GetAssetCountAction implements Action {
    readonly type = GET_ASSET_COUNT;

    constructor(public payload: { groupId: number, franchiseId: number }) { }
}

export class GetAssetCountCompleteAction implements Action {
    readonly type = GET_ASSET_COUNT_COMPLETE;

    constructor(public payload: number) { }
}

export class GetAssetCountFailedAction implements Action {
    readonly type = GET_ASSET_COUNT_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = AddToFranchiseGroupAction
    | AddToFranchiseGroupCompleteAction
    | AddToFranchiseGroupFailedAction
    | DeleteFromFranchiseAction
    | DeleteFromFranchiseCompleteAction
    | DeleteFromFranchiseFailedAction
    | SelectFranchiseGroupAction
    | GetAssetCountAction
    | GetAssetCountCompleteAction
    | GetAssetCountFailedAction;
