import { HttpErrorResponse } from '@angular/common/http';
import { without } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetIndexTypes } from '../../../../shared/enums/asset-index-types.enum';
import { StandardField } from '../../../../shared/fields/standard-fields';
import { Entities } from '../../../../shared/types';
import { ColumnFilterOptions } from '../../../../shared/types/column-filter-options';
import { PDropdownOption } from '../../shared/interfaces/p-dropdown-options';
import { sosMoment } from '../../shared/services/moment.service';
import { Field, ProjectCurrency, SubDepartment, WarehouseSubLocation, SubLocation } from './../../core/store/models';
import { BaseModel } from './base/base.model';
import { ActionStatus } from './types';

export interface SortOrder {
    key: string;
    // overrides sort order from table, mainly for secondary sorts
    orderOverride?: number;
    // due to the data, sort needs to be the opposite of normal for a given sort order
    invert?: boolean;
}

export function addValueToObjectArray(arr: { [key: number]: any[] }, key: number, value) {
    if (arr[key]) {
        arr[key] = uniqueArrayMerge(arr[key], [value]);
    } else {
        arr[key] = [value];
    }

    return arr;
}

export function addValuesToObjectArray(arr: { [key: number]: any[] }, key: number, values: any[]) {
    if (arr[key]) {
        arr[key] = uniqueArrayMerge(arr[key], values);
    } else {
        arr[key] = values;
    }

    return arr;
}

export function copyAndMultiSort(ids: any[], entities: { [id: number]: BaseModel }, sorts: SortOrder[], order: number, args?: any[]): any[] {
    if (!ids) {
        return [];
    }
    return ids.slice().sort((a, b) => {
        let result, invert = null;
        sorts.some(sort => {
            const attrOrder = sort.orderOverride ? sort.orderOverride : order;
            invert = sort.invert;
            let aProp, bProp;
            [aProp, bProp] = getSortArgs(a, b, entities, sort.key, args);
            if (sort.key === 'displayFields.group_ids') {
                result = sortProps(aProp, bProp, true) * attrOrder;
            } else {
                result = sortProps(aProp, bProp) * attrOrder;
            }

            return result;
        });
        return invert ? -result : result;
    });
}

export function getSortArgs(a, b, entities: { [id: number]: BaseModel }, key: string, args: any[]) {
    let aProp, bProp;
    const aEnt = entities[a];
    const bEnt = entities[b];
    if (typeof aEnt[key] === 'function') {
        aProp = aEnt[key].apply(aEnt, args);
        bProp = bEnt[key].apply(bEnt, args);
    } else {
        const splits = key.split('.');
        aProp = aEnt;
        bProp = bEnt;
        for (const split of splits) {
            aProp = aProp[split] === '' ? null : aProp[split];
            bProp = bProp[split] === '' ? null : bProp[split];
        }
        if (key === 'displayFields.purchase_date') {
            aProp = typeof aProp === 'undefined' ? null : new Date(aProp);
            bProp = typeof bProp === 'undefined' ? null : new Date(bProp);
        }
    }
    if (key === 'cost') {
        aProp = aProp ? aProp : 0;
        bProp = bProp ? bProp : 0;
    }
    return [aProp, bProp];
}

function isNumeric(val) {
    return !Number.isNaN(val) && Number.isFinite(val);
}

function sortProps(aProp, bProp, isMultiSelect?: boolean) {
    let result = 0;
    const aPropDNE = typeof aProp === 'undefined' || aProp === null;
    const bPropDNE = typeof bProp === 'undefined' || bProp === null;

    if (aPropDNE && bPropDNE) {
        result = 0;
    } else if (aPropDNE) {
        result = 1;
    } else if (bPropDNE) {
        result = -1;
    } else {
        if (typeof aProp === 'string') {
            aProp = aProp.toLowerCase();
        }
        if (typeof bProp === 'string') {
            bProp = bProp.toLowerCase();
        }
        if (isMultiSelect) {
            aProp = aProp.join(', ');
            bProp = bProp.join(', ');
        }
        if (typeof aProp === 'object') {
            result = new Date(aProp).getTime() > new Date(bProp).getTime() ? 1 : -1;
        } else {
            if (isNaN(aProp) && isNaN(bProp)) {
                return aProp.localeCompare(bProp);
            }
            if (isNumeric(parseFloat(aProp)) && isNumeric(parseFloat(bProp))) {
                aProp = Number.parseFloat(aProp);
                bProp = Number.parseFloat(bProp);
            }

            if (aProp === bProp) {
                result = 0;
            } else {
                result = (aProp < bProp ? -1 : 1);
            }
        }
    }

    return result;
}

export function sortStrings(a: string, b: string, order = 1) {
    a = a && a.toLowerCase();
    b = b && b.toLowerCase();

    let res = 0;
    if (a > b) {
        res = 1;
    } else if (a < b) {
        res = -1;
    }
    return res * order;
}

export function getEntitiesObject(models): { [id: number]: typeof models } {
    const obj = {};
    models.forEach(model => {
        obj[model.id] = model;
    });
    return obj;
}

export function getIdsAndEntities(state, models: any[]) {
    const allIds: number[] = [];
    const ids: number[] = [];
    const entities = {};
    models.forEach(model => {
        entities[model.id] = model;
        allIds.push(model.id);
        if (!state.entities[model.id]) {
            ids.push(model.id);
        }
    });

    return {
        allIds,
        ids,
        entities
    };
}

export function copyWithoutIndex(arr: any[], i: number) {
    return [...arr.slice(0, i), ...arr.slice(i + 1)];
}

export function buildPDropdownOptions(models, { nullOption, nullLabel, sortLabels, field, custom = false }: {
    nullOption?: boolean,
    nullLabel?: string,
    sortLabels?: number,
    field?: Field,
    custom?: boolean
} = {}): PDropdownOption[] {
    const opts = models.map(model => {
        return {
            value: model.id,
            label: typeof model.name !== 'undefined' ? model.name : model.value
        };
    });
    if (opts.length > 0) {
        if (sortLabels) {
            opts.sort((a, b) => {
                return sortLabels * a.label.localeCompare(b.label);
            });
        }
        else if (custom) {
            opts.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        }
    }
    if (opts.length > 0 && nullOption) {
        opts.unshift({
            value: null,
            label: field ? field.name : nullLabel,
            styleClass: 'default'
        });
    }
    return opts;
}

/**
 * Gets studio's field name based on standard field passed in
 */
export function getStudioFieldName(standardField: StandardField, fields: Field[]): string {
    const fieldFound = fields.find(field => field.canonical_name === standardField.canonical_name);
    return fieldFound ? fieldFound.name : standardField.name;
}

export function makeCharOrSetDropdown(options: Observable<any[]>, isChar: boolean) {
    return options.pipe(
        map(opts => {
            if (opts.length > 0) {
                opts.sort((a, b) => {
                    const aCode = isChar ? a.projectCharacters[0].code : a.projectSets[0].code;
                    const bCode = isChar ? b.projectCharacters[0].code : b.projectSets[0].code;
                    if (aCode === null && bCode === null) {
                        return a.name && b.name ? a.name.localeCompare(b.name) : 0;
                    } else if (aCode === null) {
                        return 1;
                    } else if (bCode === null) {
                        return -1;
                    } else {
                        return aCode - bCode;
                    }
                });
            }
            const dropdownOptions = opts.map(opt => {
                const code = isChar ? opt.projectCharacters[0].code : opt.projectSets[0].code;
                const value = code ? `${code} ${opt.name}` : opt.name;
                return {
                    value: opt.id,
                    label: value
                };
            });
            const label = isChar ? 'Character' : 'Set';
            if (dropdownOptions.length > 0) {
                dropdownOptions.unshift({
                    value: null,
                    label
                });
            }
            return dropdownOptions;
        }));
}

export function makeProjectCurrencyDropdown(projectCurrencies$: Observable<ProjectCurrency[]>) {
    return projectCurrencies$.pipe(map(projectCurrencies => {
        if (projectCurrencies && projectCurrencies.length) {
            return projectCurrencies.map(pc => {
                return {
                    value: pc.id,
                    label: pc.accounting_code
                };
            });
        }
        return [];
    }));
}

export function uniqueArrayMerge(existing: number[], newEntries: number[]) {
    if (existing) {
        return [
            ...existing,
            ...newEntries.filter(id => !existing.includes(id))
        ];
    }
    return newEntries;
}

export function uniqueStringArrayMerge(existing: string[], newEntries: string[]) {
    if (existing) {
        return [
            ...existing,
            ...newEntries.filter(val => !existing.includes(val))
        ];
    }
    return newEntries;
}

export function getDateToSave(date: Date) {
    if (!date) {
        return null;
    }
    return sosMoment(date).format('YYYY-MM-DD');
}

export function getFormattedDate(date) {
    if (date) {
        return sosMoment.utc(date).format('DD, MMM. YYYY');
    }
    return '';
}

/**
 * Generates a session storage key string for a specific page of the application
 * @param {Object} options object specifying the page
 * @returns {string} Returns the key that session storage data will be found under
 */
export function buildStateKey({ divisionId, eventId, forReview, franchiseId, groupId, indexType, isTransactionTable, requestId, studioId, transactionId, subGroupId }: {
    divisionId?: number,
    eventId?: number,
    forReview?: boolean,
    franchiseId?: number,
    groupId?: number,
    indexType?: AssetIndexTypes,
    isTransactionTable?: boolean,
    requestId?: number,
    studioId?: number,
    transactionId?: number,
    subGroupId?: number,
} = {}): string {
    let stateKey = '';

    if (franchiseId) {
        stateKey += `franchise${franchiseId}`;
    } else if (divisionId) {
        stateKey += `division${divisionId}`;
    } else if (eventId) {
        stateKey += `event${eventId}`;
    } else if (groupId) {
        stateKey += `group${groupId}`;
    } else if (requestId) {
        stateKey += `request${requestId}`;
    } else if (transactionId) {
        stateKey += `transaction${transactionId}`;
    } else if (studioId) {
        stateKey += `studio${studioId}`;
    } else if (subGroupId) {
        stateKey += `subgroup${subGroupId}`;
    }

    if (forReview) {
        stateKey += `ForReview`;
    }

    if (typeof indexType !== 'undefined' && franchiseId) {
        stateKey += `View${indexType}`;
    }

    if (typeof indexType !== 'undefined' && divisionId) {
        stateKey += `View${indexType}`
    }

    if (stateKey && isTransactionTable) {
        stateKey += 'TransactionTableState';
    } else if (stateKey) {
        stateKey += 'AssetTableState';
    }

    return stateKey;
}

export function getArrayFromIdsAndEntities<T>(ids: number[], entities: { [id: number]: T }): T[] {
    const things: T[] = [];
    ids.forEach(id => {
        if (entities[id]) {
            things.push(entities[id]);
        }
    });
    return things;
}

/**
 * Update table state when a sidenav item is selected
 * @param {string} stateKey for the table
 * @param {string} canonicalName of the entity type being selected
 * @param {number} value id of the entity being selected
 */
export function updateTableStateFiltersWithSidenav(stateKey: string, canonicalName: string, value: number): {
    [prop: string]: any;
} {
    let tableState: { [prop: string]: any } = JSON.parse(sessionStorage.getItem(stateKey));
    let propValue: any;

    if (tableState) {
        if (tableState.filters) {
            propValue = tableState.filters[canonicalName];
            if (propValue) {
                propValue = propValue.value;
                if (Array.isArray(propValue)) {
                    if (propValue.includes(value)) {
                        tableState.filters[canonicalName].value = without(propValue, value);
                        if (tableState.filters[canonicalName].value.length === 0) {
                            delete tableState.filters;
                        }
                    } else {
                        tableState.filters[canonicalName].value = [...propValue, value];
                    }
                }
            } else {
                tableState.filters[canonicalName] = {
                    value: [value],
                    matchMode: 'contains'
                };
            }
        } else {
            tableState.filters = {
                [canonicalName]: {
                    value: [value],
                    matchMode: 'contains'
                }
            };
        }
        tableState.first = 0;
        if (!tableState.rows) {
            tableState.rows = 50;
        }
    } else {
        tableState = {
            filters: {
                [canonicalName]: {
                    value: [value],
                    matchMode: 'contains'
                }
            },
            first: 0,
            rows: 50
        };
    }

    sessionStorage.setItem(stateKey, JSON.stringify(tableState));
    return tableState;
}

export const parseHttpErrorResponse = (error: HttpErrorResponse): ActionStatus => {
    return error.status === 504 ? ActionStatus.Timeout : ActionStatus.Failed;
};

export function filterSubDeptFiltersBySelectedDepartments(filters: ColumnFilterOptions, selectedDeptIds: number[], subDeptEnts: Entities<SubDepartment>) {
    if (filters && filters.department_id && filters.sub_department_id && selectedDeptIds.length) {
        filters.sub_department_id = filters.sub_department_id.filter(filter => {
            const subDept = subDeptEnts[filter.value];
            if (subDept && selectedDeptIds.includes(subDept.department_id)) {
                return true;
            } else {
                return false;
            }
        });
    }
}

export function filterSubLocFiltersBySelectedLocs(filters: ColumnFilterOptions, selectedLocationIds: number[], subLocEnts: Entities<SubLocation>) {
    if (filters && filters.location_id && filters.sub_location_id && selectedLocationIds.length) {
        filters.sub_location_id = filters.sub_location_id.filter(filter => {
            const subLocation = subLocEnts[filter.value];
            if (subLocation && selectedLocationIds.includes(subLocation.location_id)) {
                return true;
            } else {
                return false;
            }
        });
    }
}

export function filterWarehouseSubLocFiltersBySelectedWarehouseLocs(filters: ColumnFilterOptions, selectedWarehouseLocationIds: number[], warehouseSubLocEnts: Entities<WarehouseSubLocation>) {
    if (filters && filters.warehouse_location_id && filters.warehouse_sub_location_id && selectedWarehouseLocationIds.length) {
        filters.warehouse_sub_location_id = filters.warehouse_sub_location_id.filter(filter => {
            const warehouseSubLocation = warehouseSubLocEnts[filter.value];
            if (warehouseSubLocation && selectedWarehouseLocationIds.includes(warehouseSubLocation.warehouse_location_id)) {
                return true;
            } else {
                return false;
            }
        });
    }
}

export function sortArray(array: any, key: string) {
    return array.sort((a: any, b: any) => {
        return a[key].toString().localeCompare(b[key], 'en', { numeric: true });
    })
}

export function downloadCSV(data: any): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}
