import { LEDGER_TYPES } from '../../../../../shared/enums/ledger-types.enum';
import { Transaction } from '../transactions/transaction.model';
import { BaseJsonModel } from './../base/base-json.model';

export class Ledger extends BaseJsonModel {
    id: number;
    created_at: number = null;
    created_by: number = null;
    fields: string[] = null;
    franchise_id: number = null;
    project_id: number = null;
    matches: any = null;
    displayFields: { [fieldName: string]: string };
    project_currency_id: number = null;
    type: LEDGER_TYPES;
    deleted: boolean = false;
    account_code: number = null;
    department_code_configuration: number = null;
    department_code_location: string = null;
    showMatchingPage: boolean = false;

    transactions?: Transaction[];
    link?: string;

    constructor(ledgerObj = {}) {
        super();
        this.initializeData.call(this, ledgerObj);
    }

    getObject() {
        return {
            id: this.id,
            fields: this.fields,
            franchise_id: this.franchise_id,
            project_id: this.project_id,
            matches: this.matches,
            type: this.type,
            deleted: this.deleted,
            account_code: this.account_code,
            department_code_configuration: this.department_code_configuration,
            department_code_location: this.department_code_location
        };
    }
}
