import { createSelector } from 'reselect';
import * as root from '../';
import { Asset, AssetTypes } from '../assets/asset.model';

export const getAssetCountForSelectedSubGroup = createSelector(root.getAssetCountForSubGroupId, root.getSelectedFranchiseSubGroupId, root.getSelectedDivisionSubGroupId, root.getSelectedStudioSubGroupId, (assetCounts, franchiseSubGroupId, divisionSubGroupId, studioSubGroupId) => {
    if (franchiseSubGroupId) {
        return assetCounts[franchiseSubGroupId];
    } else if (divisionSubGroupId) {
        return assetCounts[divisionSubGroupId];
    } else if (studioSubGroupId) {
        return assetCounts[studioSubGroupId]
    }
    return null;
});

export const getAttachmentsForSelectedSubGroup = createSelector(root.getAttachmentsEntities, root.getAttachmentIdsBySubGroup, root.getSelectedFranchiseSubGroupId, root.getSelectedDivisionSubGroupId, root.getSelectedStudioSubGroupId, (attEnts, attIdsBySubGroup, franchiseSubGroupId, divisionSubGroupId, studioSubGroupId) => {
    const subGroupId = franchiseSubGroupId ? franchiseSubGroupId : divisionSubGroupId ? divisionSubGroupId : studioSubGroupId;
    return subGroupId && attIdsBySubGroup[subGroupId] ? attIdsBySubGroup[subGroupId].map(id => attEnts[id]) : [];
});

const getAssetIdsForSelectedFranchiseSubGroup = createSelector(root.getAssetIdsForSubGroups, root.getSelectedFranchiseSubGroupId, (assetIds, subGroupId) => {
    return (subGroupId && assetIds[subGroupId]) ? assetIds[subGroupId] : [];
});

const getAssetsForSelectedFranchiseSubGroup = createSelector(root.getAssetEntities, getAssetIdsForSelectedFranchiseSubGroup, root.getSelectedFranchiseId, (assets, assetIdsForSubGroup, franchiseId) => {
    return assetIdsForSubGroup.map(id => assets[id]).filter(a => a && !a.division_id && a.franchise_id === franchiseId);
});

const getAssetIdsForSelectedDivisionSubGroup = createSelector(root.getAssetIdsForSubGroups, root.getSelectedDivisionSubGroupId, (assetIds, subGroupId) => {
    return (subGroupId && assetIds[subGroupId]) ? assetIds[subGroupId] : [];
});
const getAssetsForSelectedDivisionSubGroup = createSelector(root.getAssetEntities, getAssetIdsForSelectedDivisionSubGroup, root.getSelectedDivisionId, (assets, assetIdsForSubGroup, divisionId) => {
    return assetIdsForSubGroup.map(id => assets[id]).filter(a => a && a.division_id && a.division_id === divisionId);
});

const getAllAssetsIdsForSelectedSubGroup = createSelector(root.getAssetIdsForSubGroups, root.getSelectedFranchiseSubGroupId, root.getSelectedDivisionSubGroupId, root.getSelectedStudioSubGroupId, (assetIds, franchiseSubGroupId, divisionSubGroupId, studioSubGroupId) => {
    if (franchiseSubGroupId) {
        return (assetIds[franchiseSubGroupId]) ? assetIds[franchiseSubGroupId] : [];
    } else if (divisionSubGroupId) {
        return (assetIds[divisionSubGroupId]) ? assetIds[divisionSubGroupId] : [];
    } else {
        return (assetIds[studioSubGroupId]) ? assetIds[studioSubGroupId] : [];
    }
});
const getAllAssetsForSelectedSubGroup = createSelector(root.getAssetEntities, getAllAssetsIdsForSelectedSubGroup, (assets, assetIdsForSubGroup) => {
    return assetIdsForSubGroup.map(id => assets[id]);
});

export const getAssetsToShowOnSubGroupView = createSelector(root.getAssetsShown, getAssetsForSelectedFranchiseSubGroup, getAssetsForSelectedDivisionSubGroup, getAllAssetsForSelectedSubGroup, (assetsToShow, franchiseAssets, divisionAssets, allAssets) => {
    let assets: Asset[];
    if (assetsToShow === AssetTypes.Franchise) {
        assets = franchiseAssets;
    } else if (assetsToShow === AssetTypes.Division) {
        assets = divisionAssets;
    } else {
        assets = allAssets;
    }
    const assetIds = assets.map(a => a.id);
    const assetEntities: { [assetId: number]: Asset } = {};
    assets.forEach(asset => {
        assetEntities[asset.id] = asset;
    });
    return assetIds.map(id => assetEntities[id]);
});
