<div matDialogTitle>{{title}}</div>

<mat-dialog-content>
    <ng-container *ngIf="duplicate">
        <ul class="modal-text" *ngFor="let department of duplicate.departments">
            <li>Project id={{duplicate.project_id}} has already been linked to SOS Episodic id={{duplicate.sos_episodic_id}} in department <strong>{{department.name}}</strong>, so it cannot be linked again</li>
        </ul>
    </ng-container>
    <ng-container *ngIf="linked">
        <ul class="modal-text" *ngFor="let department of linked.departments">
            <li>Project id={{linked.project_id}} linked to SOS Episodic id={{linked.sos_episodic_id}} in department <strong>{{department.name}}</strong></li>
        </ul>
    </ng-container>
</mat-dialog-content>
<div class="flex justify-flex-end gap-20">
    <button mat-raised-button [color]="confirmColor" [mat-dialog-close]="true" >{{confirmButton}}</button>
</div>
