import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import * as franchiseActions from '../franchises/franchise.actions';
import { SortObject, TableFilterObject } from '../types';
import * as franchiseAssetIndexActions from './franchise-asset-index.actions';

export interface State {
    activeTab: number;
    columnFiltersForFranchiseId: {
        [franchiseId: number]: ColumnFilterOptions;
    };
    columnFiltersForReviewForFranchiseId: {
        [franchiseId: number]: ColumnFilterOptions;
    };
    franchiseAssetSort: {
        [franchiseId: number]: SortObject;
    };
    franchiseReviewAssetSort: {
        [franchiseId: number]: SortObject;
    };
    franchiseAssetFilter: {
        [franchiseId: number]: TableFilterObject;
    };
    franchiseAssetForReviewFilter: {
        [franchiseId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    activeTab: 0,
    columnFiltersForFranchiseId: {},
    columnFiltersForReviewForFranchiseId: {},
    franchiseAssetSort: {},
    franchiseReviewAssetSort: {},
    franchiseAssetFilter: {},
    franchiseAssetForReviewFilter: {}
};

export function reducer(state = initialState, action: franchiseAssetIndexActions.Actions | franchiseActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case franchiseAssetIndexActions.SELECT_TAB: {
            return {
                ...state,
                activeTab: action.payload
            };
        }

        case franchiseAssetIndexActions.SORT_FRANCHISE_LIST: {
            const franchiseId = action.payload.franchiseId;
            const assetSort = action.payload.sort;
            const currentSort = state.franchiseAssetSort[franchiseId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[franchiseId] = { ...action.payload.sort };

            return {
                ...state,
                franchiseAssetSort: { ...state.franchiseAssetSort, ...sortObj }
            };
        }

        case franchiseAssetIndexActions.SORT_FRANCHISE_FOR_REVIEW_LIST: {
            const franchiseId = action.payload.franchiseId;
            const assetSort = action.payload.sort;
            const currentSort = state.franchiseReviewAssetSort[franchiseId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[franchiseId] = { ...assetSort };

            return {
                ...state,
                franchiseReviewAssetSort: { ...state.franchiseReviewAssetSort, ...sortObj }
            };
        }

        case franchiseAssetIndexActions.FILTER_FRANCHISE_LIST: {
            const franchiseId = action.payload.franchiseId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.franchiseAssetFilter, franchiseId);
                return {
                    ...state,
                    franchiseAssetFilter: {
                        ...state.franchiseAssetFilter,
                        [franchiseId]: globalFilter
                    }
                };
            }

            const updatedFilter: TableFilterObject = {
                ...state.franchiseAssetFilter[franchiseId],
                ...action.payload.filter
            };

            return {
                ...state,
                franchiseAssetFilter: {
                    ...state.franchiseAssetFilter,
                    [franchiseId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case franchiseAssetIndexActions.FILTER_FRANCHISE_FOR_REVIEW_LIST: {
            const franchiseId = action.payload.franchiseId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.franchiseAssetForReviewFilter, franchiseId);
                return {
                    ...state,
                    franchiseAssetForReviewFilter: {
                        ...state.franchiseAssetForReviewFilter,
                        [franchiseId]: globalFilter
                    }
                };
            }

            const updatedFilter: TableFilterObject = {
                ...state.franchiseAssetForReviewFilter[franchiseId],
                ...action.payload.filter
            };

            return {
                ...state,
                franchiseAssetForReviewFilter: {
                    ...state.franchiseAssetForReviewFilter,
                    [franchiseId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case assetActions.LIST_BY_FRANCHISE_FILTERS_COMPLETE: {
            const columnFiltersForFranchiseId = { ...state.columnFiltersForFranchiseId };
            const columnFiltersForReviewForFranchiseId = { ...state.columnFiltersForReviewForFranchiseId };
            const franchiseId = action.payload.franchiseId;
            columnFiltersForFranchiseId[franchiseId] = action.payload.columnFilters;
            columnFiltersForReviewForFranchiseId[franchiseId] = action.payload.columnFiltersForReview;

            return {
                ...state,
                columnFiltersForFranchiseId,
                columnFiltersForReviewForFranchiseId
            };
        }

        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const columnFiltersForFranchiseId = { ...state.columnFiltersForFranchiseId };
            const columnFiltersForReviewForFranchiseId = { ...state.columnFiltersForReviewForFranchiseId };
            const franchiseId = action.payload.assets[0]?.franchise_id;
            if (action.payload.columnFilters) {
                columnFiltersForFranchiseId[franchiseId] = action.payload.columnFilters;
                columnFiltersForReviewForFranchiseId[franchiseId] = action.payload.columnFilters;
            }

            return {
                ...state,
                columnFiltersForFranchiseId,
                columnFiltersForReviewForFranchiseId
            };
        }

        case franchiseAssetIndexActions.LIST_TABLE_STATE_COMPLETE: {
            const franchiseId = action.payload.franchiseId;
            const { filter, sort } = listTableState(action.payload.tableState, state.franchiseAssetFilter, state.franchiseAssetSort, franchiseId);

            return {
                ...state,
                franchiseAssetFilter: filter,
                franchiseAssetSort: sort
            };
        }

        case franchiseAssetIndexActions.LIST_REVIEW_TABLE_STATE_COMPLETE: {
            const franchiseId = action.payload.franchiseId;
            const { filter, sort } = listTableState(action.payload.tableState, state.franchiseAssetForReviewFilter, state.franchiseReviewAssetSort, franchiseId);

            return {
                ...state,
                franchiseAssetForReviewFilter: filter,
                franchiseReviewAssetSort: sort
            };
        }

        default: {
            return state;
        }
    }
}

export const getActiveTab = (state: State) => state.activeTab;
export const getDefaultAssetSort = (state: State) => state.franchiseAssetSort;
export const getFranchiseAssetSort = (state: State) => state.franchiseAssetSort;
export const getReviewAssetSort = (state: State) => state.franchiseReviewAssetSort;
export const getFranchiseAssetFilter = (state: State) => state.franchiseAssetFilter;
