import { Action } from '@ngrx/store';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { SortObject, TableFilterObject } from '../types';

export const SELECT_TAB = '[Transaction Asset Index] Select Tab';

export const SORT_TRANSACTION_LIST = '[Transaction Asset Index] Sort Transaction Asset List';
export const SORT_TRANSACTION_EXCLUDED_LIST = '[Transaction Asset Index] Sort Transaction For Review Asset List';

export const FILTER_TRANSACTION_LIST = '[Transaction Asset Index] Filter Transaction Asset List';
export const FILTER_TRANSACTION_EXCLUDED_LIST = '[Transaction Asset Index] Filter Transaction ExcludedList';

export const LIST_TABLE_STATE = '[Transaction Asset Index] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Transaction Asset Index] List Table State Complete';

export const LIST_EXCLUDED_TABLE_STATE = '[Transaction Asset Index] List Excluded Table State';
export const LIST_EXCLUDED_TABLE_STATE_COMPLETE = '[Transaction Asset Index] List Excluded Table State Complete';

export class SelectTabAction implements Action {
    readonly type = SELECT_TAB;

    constructor(public payload: number) { }
}

export class SortTransactionAssetsAction implements Action {
    readonly type = SORT_TRANSACTION_LIST;

    constructor(public payload: { franchiseId: number, sort: SortObject }) { }
}

export class SortTransactionExcludedAssetsAction implements Action {
    readonly type = SORT_TRANSACTION_EXCLUDED_LIST;

    constructor(public payload: { franchiseId: number, sort: SortObject }) { }
}

export class FilterTransactionListAction implements Action {
    readonly type = FILTER_TRANSACTION_LIST;

    constructor(public payload: { franchiseId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class FilterTransactionExcludedListAction implements Action {
    readonly type = FILTER_TRANSACTION_EXCLUDED_LIST;

    constructor(public payload: { franchiseId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { franchiseId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { franchiseId: number, tableState: TableState }) { }
}

export class ListExcludedTableStateAction implements Action {
    readonly type = LIST_EXCLUDED_TABLE_STATE;

    constructor(public payload: { franchiseId: number, stateKey: string }) { }
}

export class ListExcludedTableStateCompleteAction implements Action {
    readonly type = LIST_EXCLUDED_TABLE_STATE_COMPLETE;

    constructor(public payload: { franchiseId: number, tableState: TableState }) { }
}

export type Actions
    = SelectTabAction
    | SortTransactionAssetsAction
    | SortTransactionExcludedAssetsAction
    | FilterTransactionListAction
    | FilterTransactionExcludedListAction
    | ListTableStateAction
    | ListTableStateCompleteAction
    | ListExcludedTableStateAction
    | ListExcludedTableStateCompleteAction;
