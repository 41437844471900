import { BaseModel } from './../base/base.model';

export class FranchiseGroup extends BaseModel {
    id: number;
    franchise_id: number = null;
    group_id: number = null;
    group_type: number = null;
    created_at: number = null;

    constructor(franchiseGroupObject) {
        super();
        this.initializeData(franchiseGroupObject, this);
    }
}
