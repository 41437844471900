import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as transactionActions from '../transactions/transaction.actions';
import { SortObject, TableFilterObject } from '../types';
import * as transactionIndexActions from './transaction-index.actions';

export interface State {
    activeTab: number;
    columnFiltersForFranchiseId: {
        [franchiseId: number]: ColumnFilterOptions;
    };
    columnFiltersForExcludedForFranchiseId: {
        [franchiseId: number]: ColumnFilterOptions;
    };
    transactionSort: {
        [franchiseId: number]: SortObject;
    };
    transactionExcludedSort: {
        [franchiseId: number]: SortObject;
    };
    transactionFilter: {
        [franchiseId: number]: TableFilterObject;
    };
    transactionExcludedFilter: {
        [franchiseId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    activeTab: 0,
    columnFiltersForFranchiseId: {},
    columnFiltersForExcludedForFranchiseId: {},
    transactionSort: {},
    transactionExcludedSort: {},
    transactionFilter: {},
    transactionExcludedFilter: {}
};

export function reducer(state = initialState, action: transactionIndexActions.Actions | transactionActions.Actions): State {
    switch (action.type) {
        case transactionIndexActions.SELECT_TAB: {
            return {
                ...state,
                activeTab: action.payload
            };
        }

        case transactionIndexActions.SORT_TRANSACTION_LIST: {
            const franchiseId = action.payload.franchiseId;
            const transactionSort = action.payload.sort;
            const currentSort = state.transactionSort[franchiseId];

            if (currentSort && currentSort.field === transactionSort.field && currentSort.order === transactionSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[franchiseId] = { ...action.payload.sort };

            return {
                ...state,
                transactionSort: { ...state.transactionSort, ...sortObj }
            };
        }

        case transactionIndexActions.SORT_TRANSACTION_EXCLUDED_LIST: {
            const franchiseId = action.payload.franchiseId;
            const transactionSort = action.payload.sort;
            const currentSort = state.transactionExcludedSort[franchiseId];

            if (currentSort && currentSort.field === transactionSort.field && currentSort.order === transactionSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[franchiseId] = { ...transactionSort };

            return {
                ...state,
                transactionExcludedSort: { ...state.transactionExcludedSort, ...sortObj }
            };
        }

        case transactionIndexActions.FILTER_TRANSACTION_LIST: {
            const franchiseId = action.payload.franchiseId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.transactionFilter, franchiseId);
                return {
                    ...state,
                    transactionFilter: {
                        ...state.transactionFilter,
                        [franchiseId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.transactionFilter[franchiseId],
                ...action.payload.filter
            };

            return {
                ...state,
                transactionFilter: {
                    ...state.transactionFilter,
                    [franchiseId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case transactionIndexActions.FILTER_TRANSACTION_EXCLUDED_LIST: {
            const franchiseId = action.payload.franchiseId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.transactionExcludedFilter, franchiseId);
                return {
                    ...state,
                    transactionExcludedFilter: {
                        ...state.transactionExcludedFilter,
                        [franchiseId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.transactionExcludedFilter[franchiseId],
                ...action.payload.filter
            };

            return {
                ...state,
                transactionExcludedFilter: {
                    ...state.transactionExcludedFilter,
                    [franchiseId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case transactionActions.LIST_FILTERS_COMPLETE: {
            const columnFiltersForFranchiseId = { ...state.columnFiltersForFranchiseId };
            const columnFiltersForExcludedForFranchiseId = { ...state.columnFiltersForExcludedForFranchiseId };
            const franchiseId = action.payload.franchiseId;
            columnFiltersForFranchiseId[franchiseId] = action.payload.columnFilters;
            columnFiltersForExcludedForFranchiseId[franchiseId] = action.payload.columnFiltersForExcluded;

            return {
                ...state,
                columnFiltersForFranchiseId,
                columnFiltersForExcludedForFranchiseId
            };
        }

        case transactionIndexActions.LIST_TABLE_STATE_COMPLETE: {
            const franchiseId = action.payload.franchiseId;
            const { filter, sort } = listTableState(action.payload.tableState, state.transactionFilter, state.transactionSort, franchiseId);

            return {
                ...state,
                transactionFilter: filter,
                transactionSort: sort
            };
        }

        case transactionIndexActions.LIST_EXCLUDED_TABLE_STATE_COMPLETE: {
            const franchiseId = action.payload.franchiseId;
            const { filter, sort } = listTableState(action.payload.tableState, state.transactionExcludedFilter, state.transactionExcludedSort, franchiseId);

            return {
                ...state,
                transactionExcludedFilter: filter,
                transactionExcludedSort: sort
            };
        }

        default: {
            return state;
        }
    }
}

export const getActiveTab = (state: State) => state.activeTab;
export const getDefaultAssetSort = (state: State) => state.transactionSort;
export const getTransactionAssetSort = (state: State) => state.transactionSort;
export const getExcludedSort = (state: State) => state.transactionExcludedSort;
export const getTransactionAssetFilter = (state: State) => state.transactionFilter;
