<div matDialogTitle>Session Timeout</div>
<mat-dialog-content>
    <div class="timeout-text">
        Due to inactivity, your session is about to expire.<br> Your session will be ended and you will be logged out in
        {{minutesLeft}} minutes.<br>Click “Keep Working” to extend your session.
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-flex-end gap-20">
    <button mat-raised-button (click)="dialogRef.close(true)" class="flex-20">Log Out</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(false)">Keep Working</button>
</mat-dialog-actions>
