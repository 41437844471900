import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as warehouseLocationActions from './warehouse-location.actions';
import { WarehouseLocation } from './warehouse-location.model';

@Injectable()
export class WarehouseLocationEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseLocationActions.ADD),
            map((action: warehouseLocationActions.AddAction) => action.payload),
            switchMap((warehouseLocData) => this.http.post(`api/warehouse-locations`, warehouseLocData).pipe(
                map(res => new WarehouseLocation(res)),
                map((newWarehouseLoc: WarehouseLocation) => new warehouseLocationActions.AddCompleteAction(newWarehouseLoc)),
                catchError(error => observableOf(new warehouseLocationActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseLocationActions.DELETE),
            map((action: warehouseLocationActions.DeleteAction) => action.payload),
            switchMap((warehouseLocData) => this.http.delete(`api/warehouse-locations/${warehouseLocData.id}`).pipe(
                map(() => new warehouseLocationActions.DeleteCompleteAction(warehouseLocData)),
                catchError((error) => observableOf(new warehouseLocationActions.DeleteFailedAction({ error }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseLocationActions.LIST),
            map((action: warehouseLocationActions.ListAction) => action.payload),
            switchMap((divisionId) => this.http.get<WarehouseLocation[]>(`api/divisions/${divisionId}/warehouse-locations`).pipe(
                map(res => res.map((l) => new WarehouseLocation(l))),
                map((warehouseLocs: WarehouseLocation[]) => new warehouseLocationActions.ListCompleteAction(warehouseLocs)),
                catchError(error => observableOf(new warehouseLocationActions.ListFailedAction({ error }))))
            )));

     listByStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseLocationActions.LIST_BY_STUDIO),
            map((action: warehouseLocationActions.ListByStudioAction) => action.payload),
            switchMap((studioId) => this.http.get<WarehouseLocation[]>(`api/studios/${studioId}/warehouse-locations`).pipe(
                map(res => res.map((l) => new WarehouseLocation(l))),
                map((warehouseLocs: WarehouseLocation[]) => new warehouseLocationActions.ListByStudioCompleteAction(warehouseLocs)),
                catchError(error => observableOf(new warehouseLocationActions.ListByStudioFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseLocationActions.UPDATE),
            map((action: warehouseLocationActions.UpdateAction) => action.payload),
            switchMap(payload => this.http.put(`api/warehouse-locations/${payload.id}`, payload).pipe(
                map(res => new WarehouseLocation(res)),
                map((warehouseLoc: WarehouseLocation) => new warehouseLocationActions.UpdateCompleteAction(warehouseLoc)),
                catchError(error => observableOf(new warehouseLocationActions.UpdateFailedAction({ error }))))
            )));

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseLocationActions.ADD_FAILED, warehouseLocationActions.LIST_FAILED, warehouseLocationActions.LIST_BY_STUDIO_FAILED, warehouseLocationActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
