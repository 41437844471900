import { Action } from '@ngrx/store';

export const TOGGLE_ASSET_FILTER_OPTION = '[Nav] Toggle Asset Filters';
export const TOGGLE_TRANSACTION_FILTER_OPTION = '[Nav] Toggle Transaction Filters';
export const TOGGLE_SETTINGS_FILTER_OPTION = '[Nav] Toggle Settings Filters';
export const TOGGLE_STORAGE_FILTER_OPTION = '[Nav] Toggle Storage Filters';

export const LIST_STATUS = '[Nav] List Status';
export const LIST_STATUS_COMPLETE = '[Nav] List Status Complete';
export const LIST_STATUS_FAILED = '[Nav] List Status Failed';

export class ToggleAssetFiltersOptionAction implements Action {
    readonly type = TOGGLE_ASSET_FILTER_OPTION;
}

export class ToggleTransactionFiltersOptionAction implements Action {
    readonly type = TOGGLE_TRANSACTION_FILTER_OPTION;
}

export class ToggleSettingsFiltersOptionAction implements Action {
    readonly type = TOGGLE_SETTINGS_FILTER_OPTION;
}

export class ToggleStorageFiltersOptionAction implements Action {
    readonly type = TOGGLE_STORAGE_FILTER_OPTION;
}

export class ListStatusAction implements Action {
    readonly type = LIST_STATUS;

    constructor(public payload: { studioId: number, franchiseId?: number, divisionId?: number }) { }
}

export class ListStatusCompleteAction implements Action {
    readonly type = LIST_STATUS_COMPLETE;

    constructor(public payload: { numOfForReviewAssets: number, numOfOpenRequests: number, numOfUnreconciledTransactions: number, numOfForReviewDivisionAssets?: number }) { }
}

export class ListStatusFailedAction implements Action {
    readonly type = LIST_STATUS_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = ToggleAssetFiltersOptionAction
    | ToggleTransactionFiltersOptionAction
    | ToggleSettingsFiltersOptionAction
    | ToggleStorageFiltersOptionAction
    | ListStatusAction
    | ListStatusCompleteAction
    | ListStatusFailedAction;
