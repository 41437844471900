import { BaseModel } from '../base/base.model';
import { SubLocation } from '../sub-locations/sub-location.model';

export class Location extends BaseModel {
    id: number;
    franchise_id: number = null;
    name: string = null;
    subLocations?: SubLocation[];

    constructor(locationObj) {
        super();
        this.initializeData(locationObj, this);
        if (locationObj.subLocations) {
            this.subLocations = locationObj.subLocations.map(sl => new SubLocation(sl));
        }
    }
}
