import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { BarcodeModalComponent } from './barcode-modal.component';

@Component({
    selector: 'ah-barcode-input',
    templateUrl: './barcode-input.component.html',
    styleUrls: ['barcode-modal.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BarcodeInputComponent),
        multi: true
    }]
})
export class BarcodeInputComponent implements ControlValueAccessor {
    @Input() controlName: string;
    @Input() disable = false;
    @Input() name: string = null;
    @Input() placeholder: string = null;
    @Input() value: number;
    @Input() group: UntypedFormGroup;
    @ViewChild('input', { static: true }) inputElement;

    onValueChange: any;
    scanDialogRef: MatDialogRef<BarcodeModalComponent>;
    @Output() private valueChange = new EventEmitter();

    constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<BarcodeInputComponent>) { }

    scanBarcode($event) {
        this.scanDialogRef = this.dialog.open(BarcodeModalComponent);
        this.scanDialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            if (result) {
                this.inputElement.nativeElement.focus();
                this.value = result;
                this.valueChange.emit();
                this.onValueChange(this.value);
            }
        });
    }

    onUserInput(event) {
        this.value = event.target.value;
        this.valueChange.emit();
        this.onValueChange(this.value);
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: () => any) {
        this.onValueChange = fn;
    }

    registerOnTouched(fn: () => any) {
    }
}
