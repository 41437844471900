import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FieldRemoveModalComponent } from '../../../shared/components/field-remove-modal/field-remove-modal.component';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { DeleteCompleteAction } from '../events/event.actions';
import * as layoutActions from '../layout/layout.actions';
import * as fieldActions from './field.actions';
import { Field } from './field.model';

@Injectable()
export class FieldEffects {
     create$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.CREATE),
            map((action: fieldActions.CreateAction) => action.payload),
            switchMap((data) => this.http.post<Field>(`/api/studios/${data.studioId}/fields`, { field: data.field, deptIds: data.deptIds, requiredInAllDepts: data.requiredInAllDepts }).pipe(
                map((field: Field) => new Field(field)),
                map((field: Field) => new fieldActions.CreateCompleteAction({ field })),
                catchError((error) => observableOf(new fieldActions.CreateFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.UPDATE),
            map((action: fieldActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.post<Field>(`/api/studios/${data.studioId}/fields/${data.fieldId}`, { field: data.field, deptIds: data.deptIds, requiredInAllDepts: data.requiredInAllDepts }).pipe(
                map((field: Field) => new Field(field)),
                map((field: Field) => new fieldActions.UpdateCompleteAction({ field })),
                catchError((error) => observableOf(new fieldActions.UpdateFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.DELETE),
            map((action: fieldActions.DeleteAction) => action.payload),
            switchMap((field) => this.http.delete(`/api/studios/${field.studio_id}/fields/${field.id}`).pipe(
                map((assets: any[]) => {
                    if (assets.length > 0) {
                        // dispostion is being used
                        this.dialog.open(FieldRemoveModalComponent, { data: { assets: assets } });
                        return new layoutActions.InfoDisplayedAction();
                    }
                    else {
                        return new fieldActions.DeleteCompleteAction(field);
                    }
                }),

                catchError((error) => observableOf(new fieldActions.DeleteFailedAction({ error }))))
            )));

     deleteComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.DELETE_COMPLETE),
            map((data: DeleteCompleteAction) => new layoutActions.InfoAction({ message: `Field '${data.payload.name}' has been deleted.` }))));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST),
            map((action: fieldActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<Field[]>(`/api/studios/${studioId}/fields`).pipe(
                map(res => res.map(f => new Field(f))),
                map((fields: Field[]) => new fieldActions.ListCompleteAction({ fields, studioId })),
                catchError((error) => observableOf(new fieldActions.ListFailedAction({ error, studioId }))))
            )));

     listFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_FAILED),
            map((action: fieldActions.ListFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     listAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListAssetSelectedCompleteAction([]));
                }
            })));

     saveAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_ASSET_SELECTED),
            map((action: fieldActions.SaveAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedAssetFields', selectedFields, fieldActions.SaveAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveAssetSelectedFailedAction(err));
                }
            })));

     listTransactionSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_TRANSACTION_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedTransactionFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListTransactionSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListTransactionSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListTransactionSelectedCompleteAction([]));
                }
            })));

     saveTransactionSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_TRANSACTION_SELECTED),
            map((action: fieldActions.SaveTransactionSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedTransactionFields', selectedFields, fieldActions.SaveTransactionSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveTransactionSelectedFailedAction(err));
                }
            })));

     saveExcludedTransactionSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_EXCLUDED_TRANSACTION_SELECTED),
            map((action: fieldActions.SaveExcludedTransactionSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedTransactionFields', selectedFields, fieldActions.SaveExcludedTransactionSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveExcludedTransactionSelectedFailedAction(err));
                }
            })));

     listDivisionAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_DIVISION_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedDivisionAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListDivisionAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListDivisionAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListDivisionAssetSelectedCompleteAction([]));
                }
            })));

     saveDivisionAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_DIVISION_ASSET_SELECTED),
            map((action: fieldActions.SaveDivisionAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedDivisionAssetFields', selectedFields, fieldActions.SaveDivisionAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveDivisionAssetSelectedFailedAction(err));
                }
            })));

     listEventAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_EVENT_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedEventAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListEventAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListEventAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListEventAssetSelectedCompleteAction([]));
                }
            })));

     saveEventAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_EVENT_ASSET_SELECTED),
            map((action: fieldActions.SaveEventAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedEventAssetFields', selectedFields, fieldActions.SaveEventAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveEventAssetSelectedFailedAction(err));
                }
            })));

     listFranchiseGroupAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_FRANCHISE_GROUP_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedFranchiseGroupAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListFranchiseGroupAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListFranchiseGroupAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListFranchiseGroupAssetSelectedCompleteAction([]));
                }
            })));

     saveFranchiseGroupAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_FRANCHISE_GROUP_ASSET_SELECTED),
            map((action: fieldActions.SaveFranchiseGroupAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedFranchiseGroupAssetFields', selectedFields, fieldActions.SaveFranchiseGroupAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveFranchiseGroupAssetSelectedFailedAction(err));
                }
            })));

     listDivisionGroupAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_DIVISION_GROUP_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedDivisionGroupAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListDivisionGroupAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListDivisionGroupAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListDivisionGroupAssetSelectedCompleteAction([]));
                }
            })));

     listStudioGroupAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_STUDIO_GROUP_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedStudioGroupAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListStudioGroupAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListStudioGroupAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListStudioGroupAssetSelectedCompleteAction([]));
                }
            })));

     saveDivisionGroupAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_DIVISION_GROUP_ASSET_SELECTED),
            map((action: fieldActions.SaveDivisionGroupAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedDivisionGroupAssetFields', selectedFields, fieldActions.SaveDivisionGroupAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveDivisionGroupAssetSelectedFailedAction(err));
                }
            })));

     saveStudioGroupAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_STUDIO_GROUP_ASSET_SELECTED),
            map((action: fieldActions.SaveStudioGroupAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedStudioGroupAssetFields', selectedFields, fieldActions.SaveStudioGroupAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveStudioGroupAssetSelectedFailedAction(err));
                }
            })));
     listFranchiseSubGroupAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_FRANCHISE_SUBGROUP_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedFranchiseSubGroupAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListFranchiseSubGroupAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListFranchiseSubGroupAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListFranchiseSubGroupAssetSelectedCompleteAction([]));
                }
            })));

     saveFranchiseSubGroupAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_FRANCHISE_SUBGROUP_ASSET_SELECTED),
            map((action: fieldActions.SaveFranchiseSubGroupAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedFranchiseSubGroupAssetFields', selectedFields, fieldActions.SaveFranchiseSubGroupAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveFranchiseSubGroupAssetSelectedFailedAction(err));
                }
            })));

     listDivisionSubGroupAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_DIVISION_SUBGROUP_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedDivisionSubGroupAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListDivisionSubGroupAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListDivisionSubGroupAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListDivisionSubGroupAssetSelectedCompleteAction([]));
                }
            })));

     saveDivisionSubGroupAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_DIVISION_SUBGROUP_ASSET_SELECTED),
            map((action: fieldActions.SaveDivisionSubGroupAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedDivisionSubGroupAssetFields', selectedFields, fieldActions.SaveDivisionSubGroupAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveDivisionSubGroupAssetSelectedFailedAction(err));
                }
            })));

     listStudioSubGroupAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_STUDIO_SUBGROUP_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedStudioSubGroupAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListStudioSubGroupAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListStudioSubGroupAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListStudioSubGroupAssetSelectedCompleteAction([]));
                }
            })));


     saveStudioSubGroupAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_STUDIO_SUBGROUP_ASSET_SELECTED),
            map((action: fieldActions.SaveStudioSubGroupAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedStudioSubGroupAssetFields', selectedFields, fieldActions.SaveStudioSubGroupAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveStudioSubGroupAssetSelectedFailedAction(err));
                }
            })));

     listRequestAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_REQUEST_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedRequestAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListRequestAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListRequestAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListRequestAssetSelectedCompleteAction([]));
                }
            })));

     saveRequestAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_REQUEST_ASSET_SELECTED),
            map((action: fieldActions.SaveRequestAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedRequestAssetFields', selectedFields, fieldActions.SaveRequestAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveRequestAssetSelectedFailedAction(err));
                }
            })));

     updateSelectedTransactionFields$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.UPDATE_TRANSACTION_FIELDS),
            map((action: fieldActions.UpdateTransactionFieldsAction) => action.payload),
            switchMap((selectedMatchFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedTransactionFields', selectedMatchFields, fieldActions.UpdateTransactionFieldsCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.UpdateTransactionFieldsCompleteAction([]));
                }
            })));

     updateSelectedTransactionTabFields$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.UPDATE_TRANSACTION_TAB_FIELDS),
            map((action: fieldActions.UpdateTransactionTabFieldsAction) => action.payload),
            switchMap((selectedFeilds): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedTransactionFields', selectedFeilds, fieldActions.UpdateTransactionTabFieldsCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.UpdateTransactionFieldsCompleteAction([]));
                }
            })));

     saveMatchAssetSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_MATCH_ASSET_SELECTED),
            map((action: fieldActions.SaveMatchAssetSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedMatchAssetFields', selectedFields, fieldActions.SaveMatchAssetSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveMatchAssetSelectedFailedAction(err));
                }
            })));

     listMatchAssetSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_MATCH_ASSET_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedMatchAssetFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListMatchAssetSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListMatchAssetSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListMatchAssetSelectedCompleteAction([]));
                }
            })));

     saveMatchReviewSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_MATCH_REVIEW_SELECTED),
            map((action: fieldActions.SaveMatchReviewSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedMatchReviewFields', selectedFields, fieldActions.SaveMatchReviewSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveMatchReviewSelectedFailedAction(err));
                }
            })));

     listMatchReviewSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_MATCH_REVIEW_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedMatchReviewFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListMatchReviewSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListMatchReviewSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListMatchReviewSelectedCompleteAction([]));
                }
            })));

     listStudioSelected$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.LIST_STUDIO_SELECTED),
            switchMap(() => {
                try {
                    const selectedFields = JSON.parse(localStorage.getItem('selectedStudioFields'));
                    if (!selectedFields) {
                        return observableOf(new fieldActions.ListStudioSelectedCompleteAction([]));
                    }
                    return observableOf(new fieldActions.ListStudioSelectedCompleteAction(selectedFields));
                } catch (err) {
                    return observableOf(new fieldActions.ListStudioSelectedCompleteAction([]));
                }
            })));

     saveStudioSelected$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.SAVE_STUDIO_SELECTED),
            map((action: fieldActions.SaveStudioSelectedAction) => action.payload),
            switchMap((selectedFields): Observable<Action> => {
                try {
                    return this.saveSelectedFields('selectedStudioFields', selectedFields, fieldActions.SaveStudioSelectedCompleteAction);
                } catch (err) {
                    return observableOf(new fieldActions.SaveStudioSelectedFailedAction(err));
                }
            })));

     requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(fieldActions.CREATE_FAILED, fieldActions.DELETE_FAILED, fieldActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient, private dialog: MatDialog, private localStorageService: LocalStorageService) { }

    private saveSelectedFields(key: string, selectedFields: number[], completeAction) {
        selectedFields = selectedFields.filter(field => field);
        this.localStorageService.updateSettings(key, JSON.stringify(selectedFields));
        return observableOf(new completeAction(selectedFields));
    }
}
