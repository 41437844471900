import { Action } from '@ngrx/store';
import { SortObject } from '../types';
import { DepartmentField } from './department-field.model';
import { Department } from './department.model';

export const ADD = '[Department] Add';
export const ADD_COMPLETE = '[Department] Add Complete';
export const ADD_FAILED = '[Department] Add Failed';

export const ADD_DEPARTMENT_FIELD = '[Department] Add Department Field';
export const ADD_DEPARTMENT_FIELD_COMPLETE = '[Department] Add Department Field Complete';
export const ADD_DEPARTMENT_FIELD_FAILED = '[Department] Add Department Field Failed';

export const DELETE_DEPARTMENT_FIELD = '[Department] Delete Department Field';
export const DELETE_DEPARTMENT_FIELD_COMPLETE = '[Department] Delete Department Field Complete';
export const DELETE_DEPARTMENT_FIELD_FAILED = '[Department] Delete Department Field Failed';

export const LIST = '[Department] List By Studio';
export const LIST_COMPLETE = '[Department] List Complete';
export const LIST_FAILED = '[Department] List Failed';

export const LIST_BY_FRANCHISE = '[Department] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[Department] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Department] List By Franchise Failed';

export const SELECT = '[Department] Select';

export const SORT = '[Department] Sort';

export const UPDATE = '[Department] Update';
export const UPDATE_COMPLETE = '[Department] Update Complete';
export const UPDATE_FAILED = '[Department] Update Failed';

export const UPDATE_DEPARTMENT_FIELD = '[Department] Update Department Field';
export const UPDATE_DEPARTMENT_FIELD_COMPLETE = '[Department] Update Department Field Complete';
export const UPDATE_DEPARTMENT_FIELD_FAILED = '[Department] Update Department Field Failed';

export interface ListCompletePayload {
    departments: Department[];
    studioId: number;
}

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { departments: Department[], studioId: number }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Department[]) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { studioId: number, error: any }) { }
}

export class AddDepartmentFieldAction implements Action {
    readonly type = ADD_DEPARTMENT_FIELD;

    constructor(public payload: DepartmentField) { }
}

export class AddDepartmentFieldCompleteAction implements Action {
    readonly type = ADD_DEPARTMENT_FIELD_COMPLETE;

    constructor(public payload: DepartmentField) { }
}

export class AddDepartmentFieldFailedAction implements Action {
    readonly type = ADD_DEPARTMENT_FIELD_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteDepartmentFieldAction implements Action {
    readonly type = DELETE_DEPARTMENT_FIELD;

    constructor(public payload: DepartmentField) { }
}

export class DeleteDepartmentFieldCompleteAction implements Action {
    readonly type = DELETE_DEPARTMENT_FIELD_COMPLETE;

    constructor(public payload: DepartmentField) { }
}

export class DeleteDepartmentFieldFailedAction implements Action {
    readonly type = DELETE_DEPARTMENT_FIELD_FAILED;

    constructor(public payload: { error: any }) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: ListCompletePayload) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: number) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: Department[]) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class SortAction implements Action {
    readonly type = SORT;

    constructor(public payload: SortObject) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Department) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Department) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { studioId: number, error: any }) { }
}

export class UpdateDepartmentFieldAction implements Action {
    readonly type = UPDATE_DEPARTMENT_FIELD;

    constructor(public payload: DepartmentField) { }
}

export class UpdateDepartmentFieldCompleteAction implements Action {
    readonly type = UPDATE_DEPARTMENT_FIELD_COMPLETE;

    constructor(public payload: DepartmentField) { }
}

export class UpdateDepartmentFieldFailedAction implements Action {
    readonly type = UPDATE_DEPARTMENT_FIELD_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddDepartmentFieldAction
    | AddDepartmentFieldCompleteAction
    | AddDepartmentFieldFailedAction
    | AddFailedAction
    | DeleteDepartmentFieldAction
    | DeleteDepartmentFieldCompleteAction
    | DeleteDepartmentFieldFailedAction
    | ListAction
    | ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | ListCompleteAction
    | ListFailedAction
    | SelectAction
    | SortAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateDepartmentFieldAction
    | UpdateDepartmentFieldCompleteAction
    | UpdateDepartmentFieldFailedAction
    | UpdateFailedAction;
