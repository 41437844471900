import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as assetSatelliteActions from '../../core/store/asset-satellites/asset-satellite.actions';
import { CustomStudioFieldName, Field, StorageBox } from '../../core/store/models';
import {
    Asset,
    Character,
    Department,
    Disposition,
    Division,
    Franchise,
    Group,
    Location,
    Project,
    ProjectCurrency,
    Set,
    Status,
    Studio,
    SubDepartment,
    SubLocation,
    User,
    WarehouseLocation,
    WarehouseSubLocation
} from '../../core/store/models';
import {
    characterSelectors,
    departmentSelectors,
    divisionSelectors,
    fieldSelectors,
    franchiseSelectors,
    groupSelectors,
    locationSelectors,
    projectCurrencySelectors,
    projectSelectors,
    rootSelectors as fromRoot,
    selectedAssetSelectors,
    setSelectors,
    storageBoxSelectors,
    subDepartmentSelectors,
    subLocationSelectors,
    userSelectors,
    warehouseLocationSelectors
} from '../../core/store/selectors';
import { TableTypes } from '../../core/store/types';
import { AssetModalComponent, AssetModalData } from './../../assets/asset-modal/asset-modal.component';
import { AssetDataService } from './asset-data.service';

@Injectable()
export class AssetModalDataService {
    allGroups$: Observable<Group[]>;
    asset: Asset;
    assetModalSelected: { [fieldName: string]: number | number[] };
    assetSaving$: Observable<number>;
    canAccessRestrictedFieldsInDivision: boolean;
    canAccessRestrictedFieldsInFranchise: boolean;
    division: Division;
    divisionGroups$: Observable<Group[]>;
    notDivisionGroups$: Observable<Group[]>;
    modalDepartments$: Observable<Department[]>;
    franchise: Franchise;
    franchiseGroups$: Observable<Group[]>;
    notFranchiseGroups$: Observable<Group[]>;
    franchiseId: number;
    fieldsForModal: Field[] = [];
    usedFranchises: Franchise[];
    ownedFranchises: Franchise[];
    usedDivisions: Division[];
    ownedDivisions: Division[];
    modalDepartment$: Observable<Department>;
    modalDispositions$: Observable<Disposition[]>;
    modalProjectCurrencies$: Observable<ProjectCurrency[]>;
    modalCharactersForProject$: Observable<Character[]>;
    modalLocations$: Observable<Location[]>;
    modalProjects$: Observable<Project[]>;
    modalSetsForProject$: Observable<Set[]>;
    modalStatuses$: Observable<Status[]>;
    modalStorageBoxes$: Observable<StorageBox[]>;
    studio$: Observable<Studio>;
    modalSubDeptsForDept$: Observable<SubDepartment[]>;
    modalSubLocationsForLocation$: Observable<SubLocation[]>;
    modalWarehouseLocations$: Observable<WarehouseLocation[]>;
    modalWarehouseSubLocationsForLocation$: Observable<WarehouseSubLocation[]>;

    private subs: Subscription;

    constructor(private store: Store<fromRoot.State>, private assetDataService: AssetDataService) { }

    openModal(dialog: MatDialog, action: string, user: User, tableType?: TableTypes, asset?: Asset, disableOwnerChange?: boolean, customFieldNames?: CustomStudioFieldName) {
        this.asset = asset ? asset : null;
        const data: AssetModalData = {
            action,
            allGroups$: this.allGroups$,
            asset: this.asset,
            assetFields: this.fieldsForModal,
            assetModalSelected: this.assetModalSelected,
            canAccessRestrictedFieldsInDivision: this.canAccessRestrictedFieldsInDivision,
            canAccessRestrictedFieldsInFranchise: this.canAccessRestrictedFieldsInFranchise,
            characters$: this.modalCharactersForProject$,
            checkAndSaveAssets: this.assetDataService.checkAndSaveAssets.bind(this),
            customFieldNames,
            departments$: this.modalDepartments$,
            department$: this.modalDepartment$,
            disableOwnerChange: disableOwnerChange ? disableOwnerChange : false,
            dispositions$: this.modalDispositions$,
            divisions: disableOwnerChange ? this.usedDivisions : this.ownedDivisions,
            divisionGroups$: this.divisionGroups$,
            notDivisionGroups$: this.notDivisionGroups$,
            franchise: this.franchise ? this.franchise : null,
            franchiseGroups$: this.franchiseGroups$,
            notFranchiseGroups$: this.notFranchiseGroups$,
            locations$: this.modalLocations$,
            ownedFranchises: disableOwnerChange ? this.usedFranchises : this.ownedFranchises,
            projectCurrencies$: this.modalProjectCurrencies$,
            projects$: this.modalProjects$,
            saving$: this.assetSaving$,
            sets$: this.modalSetsForProject$,
            statuses$: this.modalStatuses$,
            storageBoxes$: this.modalStorageBoxes$,
            studio$: this.studio$,
            subDepartments$: this.modalSubDeptsForDept$,
            subLocations$: this.modalSubLocationsForLocation$,
            tableType,
            user,
            warehouseLocations$: this.modalWarehouseLocations$,
            warehouseSubLocations$: this.modalWarehouseSubLocationsForLocation$
        };
        dialog.open(AssetModalComponent, { data, width: '900px', panelClass: ['scrollable-modal', 'mobile-modal'] }).afterClosed().pipe(take(1)).subscribe(() => {
            this.unsubscribe();
        });
    }

    dispatchData(asset?: Asset, franchise?: Franchise, division?: Division, user?: User, studioId?: number) {
        this.asset = asset ? asset : null;
        this.franchise = franchise ? franchise : null;
        this.division = division ? division : null;
        if (!studioId) {
            if (this.asset) {
                studioId = this.asset.studio_id;
            } else if (this.franchise) {
                studioId = this.franchise.studio_id;
            } else if (this.division) {
                studioId = this.division.studio_id;
            } else if (!this.franchise && !this.division && !this.asset) {
                studioId = user.current_studio_id;
            }
        }
        const hasFranchisePermission = user && this.asset ? user.hasFranchisePermission(this.asset.franchise_id, this.asset.studio_id) : false;
        if (((this.asset && this.asset.franchise_id) || this.franchise) && (!user || hasFranchisePermission)) {
            this.franchiseId = this.asset ? this.asset.franchise_id : this.franchise.id;
        }
        let divisionId;
        if ((this.asset && this.asset.division_id) || this.division) {
            divisionId = this.asset ? this.asset.division_id : this.division.id;
        }
        this.store.dispatch(new assetSatelliteActions.ListAction({ studioId, franchiseId: this.franchiseId, divisionId }));

        this.setupSubscriptions();
        this.getData();
    }

    unsubscribe() {
        if (this.subs) {
            this.subs.unsubscribe();
            this.subs = null;
        }
    }

    setupSubscriptions() {
        if (this.subs) {
            throw new Error('Subscriptions already setup');
        }
        this.subs = new Subscription();

        if (this.asset) {
            const assetFranchiseSub = this.store.select(selectedAssetSelectors.franchise).subscribe(franchise => this.franchise = franchise);
            this.subs.add(assetFranchiseSub);
        }

        const ownedFranchisesSub = this.store.select(franchiseSelectors.getOwnedFranchisesWithProjects).subscribe(franchises => this.ownedFranchises = franchises);
        const usedFranchisesSub = this.store.select(franchiseSelectors.getFranchisesWithProjects).subscribe(franchises => this.usedFranchises = franchises);

        const usedDivisionsSub = this.store.select(divisionSelectors.getDivisionsWithWarehouses).subscribe(divs => this.usedDivisions = divs);
        const ownedDivisionsSub = this.store.select(divisionSelectors.getOwnedDivisionsWithWarehouses).subscribe(divs => this.ownedDivisions = divs);

        const assetSelectedSub = this.store.select(fromRoot.getAssetModalState).subscribe(state => {
            this.assetModalSelected = {
                division_id: state.selectedDivisionId,
                warehouse_id: state.selectedWarehouseId,
                warehouse_location_id: state.selectedWarehouseLocationId,
                location_id: state.selectedLocationId,
                department_id: state.selectedDepartmentId,
                warehouse_sub_location_id: state.selectedWarehouseSubLocationId,
                status_id: state.selectedStatusId,
                sub_location_id: state.selectedSubLocationId,
                sub_department_id: state.selectedSubDepartmentId,
                disposition_id: state.selectedDispositionId,
                character_id: state.selectedCharacterId,
                set_id: state.selectedSetId,
                group_ids: state.selectedGroupIds
            };
        });

        const userFranchiseSub = this.store.select(userSelectors.canAccessRestrictedFieldsInCurrentFranchise).subscribe(val => this.canAccessRestrictedFieldsInFranchise = val);
        const userDivisionSub = this.store.select(userSelectors.canAccessRestrictedFieldsInCurrentDivision).subscribe(val => this.canAccessRestrictedFieldsInDivision = val);

        this.subs
            .add(ownedFranchisesSub)
            .add(usedFranchisesSub)
            .add(ownedDivisionsSub)
            .add(usedDivisionsSub)
            .add(assetSelectedSub)
            .add(userFranchiseSub)
            .add(userDivisionSub);
    }

    getData() {
        this.studio$ = this.store.select(fromRoot.getSelectedStudio);
        if (this.franchiseId) {
            this.modalDepartments$ = this.store.select(fromRoot.getDepartmentsForSelectedFranchise);
        } else {
            this.modalDepartments$ = this.store.select(fromRoot.getDepartmentsForSelectedStudio);
        }
        this.assetSaving$ = this.store.select(fromRoot.getAssetSaving);
        this.modalCharactersForProject$ = this.store.select(characterSelectors.getForAssetModalSelectedProject);
        this.modalDepartment$ = this.store.select(departmentSelectors.getForAssetModal);
        this.modalDispositions$ = this.store.select(fromRoot.getDispositions).pipe(filter(disps => !!disps));
        this.modalLocations$ = this.store.select(locationSelectors.getForDispositionModal);
        this.modalProjectCurrencies$ = this.store.select(projectCurrencySelectors.getForAssetModalSelectedProject);
        this.modalProjects$ = this.store.select(projectSelectors.getForAssetModalSelectedFranchise).pipe(filter(prj => !!prj));
        this.modalSetsForProject$ = this.store.select(setSelectors.getForAssetModalSelectedProject);
        this.modalStatuses$ = this.store.select(fromRoot.getStatuses).pipe(filter(statuses => !!statuses));
        this.modalSubDeptsForDept$ = this.store.select(subDepartmentSelectors.getSubDeptsForAssetModalDept);
        this.modalSubLocationsForLocation$ = this.store.select(subLocationSelectors.getSubLocationsForAssetModalLocation);
        this.modalWarehouseLocations$ = this.store.select(warehouseLocationSelectors.getForAssetModalSelectedWarehouse);
        this.modalWarehouseSubLocationsForLocation$ = this.store.select(warehouseLocationSelectors.getSubLocationsForAssetModal);
        this.modalStorageBoxes$ = this.store.select(storageBoxSelectors.getForAssetModal);
        this.store.select(fieldSelectors.getAllWithFakeColumns).pipe(filter(f => !!f.length), take(1)).subscribe(f => {
            this.fieldsForModal = f;
        });
        this.allGroups$ = this.store.select(groupSelectors.getAllGroups);
        this.franchiseGroups$ = this.store.select(groupSelectors.getGroupsForSelectedFranchise);
        this.notFranchiseGroups$ = this.store.select(groupSelectors.getGroupsNotInSelectedFranchise);
        this.divisionGroups$ = this.store.select(groupSelectors.getGroupsForSelectedDivision);
        this.notDivisionGroups$ = this.store.select(groupSelectors.getGroupsNotInSelectedDivision);
    }
}
