import { Action } from '@ngrx/store';
import { Warehouse } from './warehouse.model';

export const ADD = '[Warehouse] Add';
export const ADD_COMPLETE = '[Warehouse] Add Complete';
export const ADD_FAILED = '[warehouse] Add Failed';
export const UPDATE = '[Warehouse] Update';
export const UPDATE_COMPLETE = '[Warehouse] Update Complete';
export const UPDATE_FAILED = '[Warehouse] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { warehouse: Warehouse, divisionId: number }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: { warhouse: Warehouse, divisionId: number }) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: any, divisionId: number }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { warehouse: Warehouse, divisionId: number }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;
    constructor(public payload: Warehouse) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any, divisionId: number }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
