import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AssetFieldInputComponent } from '../assets/asset-field-input/asset-field-input.component';
import { SidenavFilterComponent } from '../layout/sidenav-filter/sidenav-filter.component';
import { MaterialModule } from '../material/material.module';
import { AddToRequestModalComponent } from '../shared/components/add-to-request-modal/add-to-request-modal.component';
import { BarcodeInputComponent } from '../shared/components/barcode-input/barcode-input.component';
import { BarcodeModalComponent } from '../shared/components/barcode-input/barcode-modal.component';
import { FieldModalComponent } from './components/field-modal/field-modal.component';
import { OwnerFieldsComponent } from '../shared/components/owner-fields/owner-fields.component';
import { PlaceholderComponent } from '../shared/components/placeholder/placeholder.component';
import { StatusPillComponent } from '../shared/components/status-pill/status-pill.component';
import { StorageBoxTableComponent } from '../shared/components/storage-box-table/storage-box-table.component';
import { TransactionExpandoRowComponent } from '../shared/components/transaction-expando-row/transaction-expando-row.component';
import { CustomStudioFieldNameDataService } from '../shared/data-services/custom-studio-field-name-data.service';
import { DivisionDataService } from '../shared/data-services/division-data.service';
import { FieldDataService } from '../shared/data-services/field-data.service';
import { FranchiseDataService } from '../shared/data-services/franchise-data.service';
import { StudioDataService } from '../shared/data-services/studio-data.service';
import { UserDataService } from '../shared/data-services/user-data.service';
import { SidenavFilterAssetPipe } from '../shared/pipes/sidenav-filter-asset.pipe';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { AssetDataTableComponent } from './components/asset-data-table/asset-data-table.component';
import {
    CanAccessAssetDetailsPipe, GetColumnFitlerTooltipPipe, GetPaginatorDisplayPipe, GetPluralizedAssetOrTransactionPipe,
    IsBulkOptionsDisabledPipe, IsCaptionVisiblePipe, IsClearSelectionVisiblePipe, IsRequestConflictColumnVisiblePipe, IsRequestSentToColumnVisiblePipe, IsRequestStatusColumnVisiblePipe, IsRowCheckboxVisiblePipe,
    IsSelectAllCaptionVisiblePipe, IsSelectAllCheckboxVisiblePipe, TruncateAssetFieldPipe
} from './components/asset-data-table/asset-data-table.pipes';
import { AttachmentUploadModalComponent } from './components/attachment-upload-modal/attachment-upload-modal.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { CheckInModalComponent } from './components/check-in-modal/check-in-modal.component';
import { CheckOutModalComponent } from './components/check-out-modal/check-out-modal.component';
import { ColumnSelectorComponent } from './components/column-selector/column-selector.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CreateAssetFromTrxModalComponent } from './components/create-asset-from-trx-modal/create-asset-from-trx-modal.component';
import { CreatedUpdatedComponent } from './components/created-updated/created-updated.component';
import { DualInputComponent } from './components/dual-input/dual-input.component';
import { GroupInputComponent } from './components/group-input/group-input.component';
import { LedgerWarningModalComponent } from './components/ledger-warning-modal/ledger-warning-modal.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { MobileColumnModalComponent } from './components/mobile-column-modal/mobile-column-modal.component';
import { PhotoModalComponent } from './components/photo-modal/photo-modal.component';
import { ProjectCurrencySelectComponent } from './components/project-currency-select/project-currency-select.component';
import { ShipmentInputComponent } from './components/shipment-input/shipment-input.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TransactionTableComponent } from './components/transaction-table/transaction-table.component';
import { UpdateFieldModalComponent } from './components/update-field-modal/update-field-modal.component';
import { UpdateTrxFormComponent } from './components/update-trx-form/update-trx-form.component';
import { AssetAttachmentService } from './data-services/asset-attachment.service';
import { AssetDataService } from './data-services/asset-data.service';
import { AssetModalDataService } from './data-services/asset-modal-data.service';
import { BulkMoveModalDataService } from './data-services/bulk-move-modal-data.service';
import { EventAttachmentService } from './data-services/event-attachment.service';
import { GroupAttachmentService } from './data-services/group-attachment.service';
import { MatchFieldsDataService } from './data-services/match-fields-data.service';
import { TransactionDataService } from './data-services/transaction-data.service';
import { CompareDirective } from './directives/compare.directive';
import { EmailValidatorDirective } from './directives/email-validator.directive';
import { IsMobilePipe } from './pipes/is-mobile.pipe';
import { KeyValuePipe } from './pipes/key-value.pipe';
import { NameSearchPipe } from './pipes/name-search.pipe';
import { ActionStateService } from './services/action-state.service';
import { ColumnFilterService } from './services/column-filter.service';
import { CookieService } from './services/cookie.service';
import { GalleryService } from './services/gallery.service';
import { IntercomService } from './services/intercom.service';
import { PendoService } from './services/pendo.service';
import { AssetLimitModalComponent } from './components/asset-limit-modal/asset-limit-modal.component';
import { ConfirmDuplicateModalComponent } from './components/confirm-duplicate-modal/confirm-duplicate-modal.component';
import { DispositionModalComponent } from './components/disposition-modal/disposition-modal.component';
import { FieldRemoveModalComponent } from './components/field-remove-modal/field-remove-modal.component';
import { RemoveModalComponent } from './components/remove-modal/remove-modal.component';
import { SubGroupModalComponent } from './components/sub-groups/sub-group-modal/sub-group-modal.component';
import { SubGroupAttachmentService } from './data-services/sub-group-attachment.service';
import { ConvertGroupSubgroupModalComponent } from './components/convert-group-subgroup-modal/convert-group-subgroup-modal.component';
import { AssetsImportComponent } from './components/assets-import/assets-import.component';
import { AssetsUploadComponent } from './components/assets-upload/assets-upload.component';
import { ConfirmImportComponent } from './components/confirm-import/confirm-import.component';
import { InvalidSubDepartmentModalComponent } from './components/invalid-sub-department-modal/invalid-sub-department-modal.component';
import { AssetsImportSummaryModalComponent } from './components/assets-import-summary-modal/assets-import-summary-modal.component';
import { AssetImportHistoryComponent } from './components/asset-import-history/asset-import-history.component';
import { GetAcceptedAssetsCountPipe, GetAssetCountPipe, GetForReviewAssetsCountPipe, GetAcceptedAssetsTotalPipe, GetAssetsTotalPipe, GetForReviewAssetsTotalPipe } from './components/asset-import-history/asset-import-history.pipes';
import { LocalStorageService } from './services/local-storage.service';
import { AssetEventComponent } from './components/asset-event/asset-event.component';
import { LoginComponent } from './components/login/login.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { ClaimAccountComponent } from './components/claim-account/claim-account.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';


@NgModule({
    imports: [
        CalendarModule,
        CommonModule,
        DataViewModule,
        DropdownModule,
        FileUploadModule,
        FormsModule,
        MaterialModule,
        MenuModule,
        MenubarModule,
        MultiSelectModule,
        PaginatorModule,
        ReactiveFormsModule,
        ScrollingModule,
        SliderModule,
        TableModule,
        TabMenuModule,
        TieredMenuModule,
        GalleriaModule,
        ImageModule
    ],
    declarations: [
        AddressInputComponent,
        AssetDataTableComponent,
        AssetFieldInputComponent,
        AssetsImportComponent,
        AssetsUploadComponent,
        AssetImportHistoryComponent,
        AttachmentsComponent,
        AttachmentUploadModalComponent,
        BarcodeInputComponent,
        BarcodeModalComponent,
        CanAccessAssetDetailsPipe,
        CheckInModalComponent,
        CheckOutModalComponent,
        CompareDirective,
        ConfirmModalComponent,
        ColumnSelectorComponent,
        UpdateTrxFormComponent,
        CreateAssetFromTrxModalComponent,
        CreatedUpdatedComponent,
        DualInputComponent,
        EmailValidatorDirective,
        FieldModalComponent,
        GetColumnFitlerTooltipPipe,
        GetPaginatorDisplayPipe,
        GetPluralizedAssetOrTransactionPipe,
        GroupInputComponent,
        IsBulkOptionsDisabledPipe,
        IsCaptionVisiblePipe,
        IsClearSelectionVisiblePipe,
        IsMobilePipe,
        IsRequestConflictColumnVisiblePipe,
        IsRequestSentToColumnVisiblePipe,
        IsRequestStatusColumnVisiblePipe,
        IsRowCheckboxVisiblePipe,
        IsSelectAllCaptionVisiblePipe,
        IsSelectAllCheckboxVisiblePipe,
        KeyValuePipe,
        LedgerWarningModalComponent,
        LoadingOverlayComponent,
        MobileColumnModalComponent,
        NameSearchPipe,
        OwnerFieldsComponent,
        PhotoModalComponent,
        PlaceholderComponent,
        ProjectCurrencySelectComponent,
        AddToRequestModalComponent,
        ShipmentInputComponent,
        SidenavFilterAssetPipe,
        SidenavFilterComponent,
        StatusPillComponent,
        StorageBoxTableComponent,
        TabBarComponent,
        TransactionExpandoRowComponent,
        TransactionTableComponent,
        TruncateAssetFieldPipe,
        TooltipComponent,
        UpdateFieldModalComponent,
        AssetLimitModalComponent,
        ConfirmDuplicateModalComponent,
        DispositionModalComponent,
        FieldRemoveModalComponent,
        RemoveModalComponent,
        SubGroupModalComponent,
        ConvertGroupSubgroupModalComponent,
        ConfirmImportComponent,
        InvalidSubDepartmentModalComponent,
        AssetsImportSummaryModalComponent,
        AssetImportHistoryComponent,
        GetAssetCountPipe,
        GetAcceptedAssetsCountPipe,
        GetAssetsTotalPipe,
        GetAcceptedAssetsTotalPipe,
        GetForReviewAssetsCountPipe,
        GetForReviewAssetsTotalPipe,
        AssetEventComponent,
        LoginComponent,
        UserDetailsComponent,
        ActivateUserComponent,
        ClaimAccountComponent,
        UserSettingsComponent,
    ],
    exports: [
        AddressInputComponent,
        AssetDataTableComponent,
        AssetFieldInputComponent,
        AssetsImportComponent,
        AssetsUploadComponent,
        AssetImportHistoryComponent,
        AttachmentsComponent,
        AttachmentUploadModalComponent,
        BarcodeInputComponent,
        BarcodeModalComponent,
        CalendarModule,
        CommonModule,
        CompareDirective,
        CheckInModalComponent,
        CheckOutModalComponent,
        ConfirmModalComponent,
        ColumnSelectorComponent,
        UpdateTrxFormComponent,
        CreateAssetFromTrxModalComponent,
        CreatedUpdatedComponent,
        DataViewModule,
        DropdownModule,
        DualInputComponent,
        EmailValidatorDirective,
        FieldModalComponent,
        FileUploadModule,
        FormsModule,
        GalleriaModule,
        ImageModule,
        GroupInputComponent,
        KeyValuePipe,
        LedgerWarningModalComponent,
        LoadingOverlayComponent,
        MaterialModule,
        MobileColumnModalComponent,
        MenuModule,
        MenubarModule,
        MultiSelectModule,
        NameSearchPipe,
        OwnerFieldsComponent,
        PanelMenuModule,
        PhotoModalComponent,
        PlaceholderComponent,
        ProgressSpinnerModule,
        ProjectCurrencySelectComponent,
        ReactiveFormsModule,
        AddToRequestModalComponent,
        ScrollingModule,
        ShipmentInputComponent,
        SidenavFilterAssetPipe,
        SidenavFilterComponent,
        SliderModule,
        StatusPillComponent,
        StorageBoxTableComponent,
        TableModule,
        TabBarComponent,
        TabMenuModule,
        TieredMenuModule,
        TransactionTableComponent,
        TooltipComponent,
        UpdateFieldModalComponent,
        AssetLimitModalComponent,
        SubGroupModalComponent,
        AssetEventComponent,
        LoginComponent,
        UserDetailsComponent,
        ActivateUserComponent,
    ],
    providers: [
        ActionStateService,
        AssetAttachmentService,
        AssetDataService,
        AssetModalDataService,
        BulkMoveModalDataService,
        ColumnFilterService,
        CookieService,
        CustomStudioFieldNameDataService,
        DivisionDataService,
        EventAttachmentService,
        FieldDataService,
        FranchiseDataService,
        GalleryService,
        GroupAttachmentService,
        IntercomService,
        MatchFieldsDataService,
        StudioDataService,
        TransactionDataService,
        UserDataService,
        SidenavFilterAssetPipe,
        PendoService,
        SubGroupAttachmentService,
        LocalStorageService
    ]
})
export class SharedModule { }
