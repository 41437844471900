import { Component, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output } from '@angular/core';
import { NAV_TYPES } from '../../../../shared/enums/nav-types.enum';
import { redirectToLogin } from '../../shared/services/user.service';
import { CustomStudioFieldName, Studio, Franchise, Division, User } from '../../core/store/models';
import { MenuItem } from 'primeng/api/menuitem';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import * as navSelectors from '../../core/store/nav/nav.selectors';
import { filter } from 'rxjs/operators';
import * as navActions from '../../core/store/nav/nav.actions';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Component({
    selector: 'ah-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() customFieldNames: CustomStudioFieldName;
    @Input() isMobile: boolean;
    @Input() navType: NAV_TYPES;
    @Input() studioList: Studio[];
    @Input() user: User;

    @Output() selectStudio = new EventEmitter();

    NAV_TYPES = NAV_TYPES;
    currentDivision: Division;
    currentFranchise: Franchise;
    currentStudio: Studio;
    dark = false;
    loginFromSynconset = redirectToLogin;
    numOfOpenRequests: number;
    numOfUnreconciledTransactions: number;
    items: MenuItem[] = [];
    navSelectOptions: MenuItem[] = [];

    private subs: Subscription;
    private routerEventSub: Subscription;
    constructor(private store: Store<fromRoot.State>, private router: Router, private localStorageService: LocalStorageService) { }

    ngOnInit() {
        if (localStorage.getItem('toolbar.dark') === 'true') {
            this.dark = true;
        }

        this.subs = new Subscription();
        const navStatusSub = observableCombineLatest(
            this.store.select(fromRoot.getSelectedStudio),
            this.store.select(fromRoot.getSelectedFranchise),
            this.store.select(fromRoot.getSelectedDivision),
            this.store.select(navSelectors.getNumOfOpenRequests),
            this.store.select(navSelectors.getNumOfUnreconciledTransactions)
        ).pipe(filter(([studio]) => !!studio)).subscribe(([studio, franchise, division, numOfOpenRequests, numOfUnReconciledTransactions]: [Studio, Franchise, Division, number, number]) => {
            this.numOfOpenRequests = numOfOpenRequests;
            this.numOfUnreconciledTransactions = numOfUnReconciledTransactions;

            if (!this.currentStudio || !this.currentFranchise || !this.currentDivision || studio.id !== this.currentStudio.id || (this.currentFranchise && franchise && franchise.id !== this.currentFranchise.id) || (this.currentDivision && division && division.id !== this.currentDivision.id)) {
                this.currentStudio = studio;
                this.currentFranchise = franchise;
                this.currentDivision = division;

            }
            this.updateSidenav();
        });

        this.routerEventSub = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.updateSidenav());

        this.subs
            .add(navStatusSub)
    }

    ngOnChanges(changes) {
        if ((changes.navType || changes.filterOptionsVisiblity || changes.filtersSelected) && this.currentStudio) {
            this.updateSidenav();
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.routerEventSub.unsubscribe();
    }

    selectStudioFromMenu(studioToSelect, refresh) {
        this.selectStudio.emit({
            studio: studioToSelect,
            refresh
        });
    }

    lightSwitch() {
        this.dark = !this.dark;
        this.localStorageService.updateSettings('toolbar.dark', JSON.stringify(this.dark));
    }

    private studioSidenavItems(studio: Studio) {
        const customNames = this.customFieldNames && this.customFieldNames.franchise_id ? this.customFieldNames : new CustomStudioFieldName({});
        const showDivisions = this.user.hasDivisionPermission(this.currentStudio.id);
        const isAdmin = this.user.permission.isStudioAdmin(this.currentStudio.id);
        const canAccessAssetSearch = this.user.permission.canAccessAssetSearch(this.currentStudio.id);

        const baseUrl = `/studio/${studio.id}`;
        const items: MenuItem[] = [];
        const requestsEnabled = this.currentStudio.approvals_enabled;
        if (!this.currentStudio.franchise_hidden) {
            items.push({
                label: customNames.franchises,
                id: 'sidenav_studio_franchise_button',
                icon: 'p-icon-movie',
                routerLink: `${baseUrl}/franchises`,
                styleClass: window.location.pathname.indexOf('franchises') > -1 ? 'active-tab' : ''
            });
        }
        if (!this.currentStudio.series_hidden) {
            items.push({
                label: customNames.series,
                id: 'sidenav_studio_series_button',
                icon: 'p-icon-tv',
                routerLink: `${baseUrl}/series`,
                styleClass: window.location.pathname.indexOf('series') > -1 ? 'active-tab' : ''
            });
        }

        if (showDivisions) {
            items.push({
                label: customNames.divisions,
                id: 'sidenav_studio_divisions_button',
                icon: 'p-icon-business',
                routerLink: `${baseUrl}/divisions`,
                styleClass: window.location.pathname.indexOf('divisions') > -1 ? 'active-tab' : ''
            });
        }

        if (isAdmin || canAccessAssetSearch) {
            items.push({
                label: 'Asset Search',
                id: 'sidenav_studio_asset_search_button',
                icon: 'p-icon-search',
                routerLink: `${baseUrl}/assets`,
                styleClass: window.location.pathname.indexOf('assets') > -1 ? 'active-tab' : ''
            });
        }

        if (isAdmin) {
            items.push({
                label: 'Groups',
                id: 'sidenav_studio_groups_button',
                icon: 'p-icon-label',
                routerLink: `${baseUrl}/groups`,
                styleClass: window.location.pathname.indexOf('groups') > -1 ? 'active-tab' : ''
            });
        }

        if (requestsEnabled) {
            const hasOpenRequests = this.numOfOpenRequests > 0 ? true : false;
            let styleClass = '';
            if (hasOpenRequests) {
                styleClass += 'notification-highlight ';
            }
            if (window.location.pathname.indexOf('requests') > -1) {
                styleClass += 'active-tab';
            }
            items.push({
                label: `Requests ${hasOpenRequests ? `(${this.numOfOpenRequests})` : ``}`,
                id: 'sidenav_studio_requests_button',
                icon: 'p-icon-flag',
                routerLink: `${baseUrl}/requests`,
                styleClass
            });
        }

        if (isAdmin) {
            items.push(
                {
                    label: 'Admin',
                    id: 'sidenav_studio_admin_button',
                    icon: 'p-icon-people',
                    routerLink: `${baseUrl}/team`,
                    styleClass: window.location.pathname.indexOf('team') > -1 ? 'active-tab' : ''
                }, {
                label: 'Studio Settings',
                id: 'sidenav_studio_settings_button',
                icon: 'p-icon-settings',
                routerLink: `${baseUrl}/settings`,
                styleClass: window.location.pathname.indexOf('settings') > -1 ? 'active-tab' : ''
            }
            );
        }

        return items;
    }

    private divisionSidenavItems() {
        if (!this.currentDivision) {
            return [];
        }

        const currentDivisionId = this.currentDivision ? this.currentDivision.id : -1;
        const baseUrl = `/division/${currentDivisionId}`;

        const items = [
            {
                label: 'Assets',
                id: 'sidenav_division_assets_button',
                icon: 'p-icon-extension',
                routerLink: `${baseUrl}/assets`,
                styleClass: window.location.pathname.indexOf('assets') > -1 ? 'active-tab' : ''
            }
        ];

        if (this.currentDivision && this.currentDivision.events_enabled) {
            items.push({
                label: 'Events',
                id: 'sidenav_division_events_button',
                icon: 'p-icon-today',
                routerLink: `${baseUrl}/events`,
                styleClass: window.location.pathname.indexOf('events') > -1 ? 'active-tab' : ''
            });
        }

        items.push(
            {
                label: 'Team',
                id: 'sidenav_division_team_button',
                icon: 'p-icon-person-add',
                routerLink: `${baseUrl}/team`,
                styleClass: window.location.pathname.indexOf('team') > -1 ? 'active-tab' : ''
            }, {
            label: 'Storage',
            id: 'sidenav_division_storage_button',
            icon: 'p-icon-pin-drop',
            routerLink: `${baseUrl}/storage`,
            styleClass: window.location.pathname.indexOf('storage') > -1 ? 'active-tab' : ''
        }
        );

        items.push(
            {
                label: 'Groups',
                id: 'sidenav_division_groups_button',
                icon: 'p-icon-label',
                routerLink: `${baseUrl}/groups`,
                styleClass: window.location.pathname.indexOf('groups') > -1 ? 'active-tab' : ''
            }
        );

        return items;
    }

    private franchiseSidenavItems() {
        if (!this.currentFranchise) {
            return [];
        }

        const currentFranchiseId = this.currentFranchise ? this.currentFranchise.id : -1;
        const baseUrl = `/franchise/${currentFranchiseId}`;
        const canEdit = this.user.permission.canEditInFranchise(this.currentFranchise.id, this.currentFranchise.studio_id);
        const items: MenuItem[] = [
            {
                label: 'Assets',
                id: 'sidenav_franchise_assets_button',
                icon: 'p-icon-extension',
                routerLink: `${baseUrl}/assets`,
                styleClass: window.location.pathname.indexOf('assets') > -1 ? 'active-tab' : ''
            }
        ];

        if (!this.currentStudio.transactions_hidden && canEdit) {
            const hasUnreconciledTrx = this.numOfUnreconciledTransactions > 0 ? true : false;
            let styleClass = '';
            if (hasUnreconciledTrx) {
                styleClass += 'notification-highlight ';
            }
            if (window.location.pathname.indexOf('transactions') > -1) {
                styleClass += 'active-tab';
            }
            items.push({
                label: `Transactions ${hasUnreconciledTrx ? `(${this.numOfUnreconciledTransactions})` : ``}`,
                id: 'sidenav_franchise_transactions_button',
                icon: 'p-icon-move-to-inbox',
                routerLink: `${baseUrl}/transactions`,
                styleClass
            });
        }

        items.push(
            {
                label: 'Team',
                id: 'sidenav_franchise_team_button',
                icon: 'p-icon-person-add',
                routerLink: `${baseUrl}/team`,
                styleClass: window.location.pathname.indexOf('team') > -1 ? 'active-tab' : ''
            }
        );

        items.push(
            {
                label: 'Storage',
                id: 'sidenav_franchise_storage_button',
                icon: 'p-icon-pin-drop',
                routerLink: `${baseUrl}/storage`,
                styleClass: window.location.pathname.indexOf('storage') > -1 ? 'active-tab' : ''
            }
        );

        items.push(
            {
                label: 'Groups',
                id: 'sidenav_franchise_groups_button',
                icon: 'p-icon-label',
                routerLink: `${baseUrl}/groups`,
                styleClass: window.location.pathname.indexOf('groups') > -1 ? 'active-tab' : ''
            }
        );
        let franchiseSeriesName = '';
        if (this.currentFranchise.is_series) {
            franchiseSeriesName = this.customFieldNames.series_id ? this.customFieldNames.series_id : 'Series';
        } else {
            franchiseSeriesName = this.customFieldNames.franchise_id ? this.customFieldNames.franchise_id : 'Feature';
        }
        items.push(
            {
                label: `${franchiseSeriesName} Settings`,
                id: 'sidenav_franchise_settings_button',
                icon: 'p-icon-settings',
                routerLink: `${baseUrl}/settings`,
                styleClass: window.location.pathname.indexOf('settings') > -1 ? 'active-tab' : ''
            }
        );

        return items;
    }

    private setupNavSelectItems() {
        this.navSelectOptions = [];
        if (this.currentStudio) {
            const baseUrl = `studio/${this.currentStudio.id}`;
            this.navSelectOptions.push({
                label: 'Studio',
                routerLink: baseUrl
            });
            if (!this.currentStudio.franchise_hidden) {
                this.navSelectOptions.push({
                    label: this.customFieldNames.franchises,
                    routerLink: `${baseUrl}/franchises`
                });
            }
            if (!this.currentStudio.series_hidden) {
                this.navSelectOptions.push({
                    label: this.customFieldNames.series,
                    routerLink: `${baseUrl}/series`
                });
            }
            if (this.user.hasDivisionPermission(this.currentStudio.id)) {
                this.navSelectOptions.push({
                    label: this.customFieldNames.divisions,
                    routerLink: `${baseUrl}/divisions`
                });
            }
        }
        let label = 'Studio';
        if (this.currentFranchise && this.navType === NAV_TYPES.FRANCHISE) {
            if (this.currentFranchise.is_series) {
                label = this.customFieldNames.series_id;
            } else {
                label = this.customFieldNames.franchise_id;
            }
        } else if (this.navType === NAV_TYPES.DIVISION) {
            label = this.customFieldNames.division_id;
        }
        this.navSelectOptions = [{
            label,
            items: this.navSelectOptions
        }];
    }

    private updateSidenav() {
        switch (this.navType) {
            case NAV_TYPES.STUDIO: {
                this.store.dispatch(new navActions.ListStatusAction({ studioId: this.currentStudio.id }));
                this.items = this.currentStudio ? this.studioSidenavItems(this.currentStudio) : [];
                break;
            }
            case NAV_TYPES.FRANCHISE: {
                this.items = this.franchiseSidenavItems();
                break;
            }
            case NAV_TYPES.DIVISION: {
                this.items = this.divisionSidenavItems();
                break;
            }
        }
        this.setupNavSelectItems();

        if (this.isMobile) {
            this.items = [
                {
                    label: '',
                    icon: 'ui-icon-menu',
                    items: this.items
                }
            ];
        }
    }
}
