import { SortObject } from '../../../../../shared/types';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import { TableFilterObject } from '../types';
import * as matchReviewActions from './match-review.actions';

export interface State {
    selectedProjectId: number;
    sort: {
        [transactionId: number]: SortObject;
    };
    filter: {
        [transactionId: number]: TableFilterObject;
    };
    columnFiltersForFranchiseId: {
        [franchiseId: number]: ColumnFilterOptions;
    };
}

export const initialState: State = {
    selectedProjectId: null,
    sort: {},
    filter: {},
    columnFiltersForFranchiseId: {}
};

export function reducer(state = initialState, action: matchReviewActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case matchReviewActions.SELECT_PROJECT: {
            let newState = state;
            if (action.payload !== state.selectedProjectId) {
                newState = {
                    ...state,
                    selectedProjectId: action.payload
                };
            }

            return newState;
        }

        case matchReviewActions.SORT_ASSETS_LIST: {
            const transactionId = action.payload.transactionId;
            const sort = action.payload.sort;
            const currentSort = state.sort[transactionId];

            if (currentSort && currentSort.field === sort.field && currentSort.order === sort.order) {
                return state;
            }

            return {
                ...state,
                sort: {
                    ...state.sort,
                    [transactionId]: {
                        ...sort
                    }
                }
            };
        }

        case matchReviewActions.FILTER_ASSETS_LIST: {
            const transactionId = action.payload.transactionId;
            const filter = action.payload.filter;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    [transactionId]: { ...filter }
                }
            };
        }

        case assetActions.LIST_BY_FRANCHISE_FILTERS_COMPLETE: {
            const columnFiltersForFranchiseId = { ...state.columnFiltersForFranchiseId };
            const franchiseId = action.payload.franchiseId;
            columnFiltersForFranchiseId[franchiseId] = action.payload.columnFiltersForReview;

            return {
                ...state,
                columnFiltersForFranchiseId
            };
        }

        case matchReviewActions.LIST_TABLE_STATE_COMPLETE: {
            const transactionId = action.payload.transactionId;
            const { filter, sort } = listTableState(action.payload.tableState, state.filter, state.sort, transactionId);

            return {
                ...state,
                filter,
                sort
            };
        }

        default: {
            return state;
        }
    }
}

export const getSelectedProjectId = (state: State) => state.selectedProjectId;
