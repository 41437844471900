import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as currencyActions from './currency.actions';
import { Currency } from './currency.model';

@Injectable()
export class CurrencyEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(currencyActions.LIST),
            switchMap(() => this.http.get<Currency[]>(`/api/currencies`).pipe(
                map(res => {
                    const response = res;
                    return response.map(s => new Currency(s));
                }),
                map((currencies: Currency[]) => new currencyActions.ListCompleteAction(currencies)),
                catchError(error => observableOf(new currencyActions.ListFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(currencyActions.LIST_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
