import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ProjectCurrency } from './project-currency.model';

export const ADD = '[Project Currency] Add';
export const ADD_COMPLETE = '[Project Currency] Add Complete';
export const ADD_FAILED = '[Project Currency] Add Failed';

export const DELETE = '[Project Currency] Delete';
export const DELETE_COMPLETE = '[Project Currency] Delete Complete';
export const DELETE_FAILED = '[Project Currency] Delete Failed';

export const LIST = '[Project Currency] List By Franchise';
export const LIST_COMPLETE = '[Project Currency] List By Franchise Complete';
export const LIST_FAILED = '[Project Currency] List By Franchise Failed';

export const SELECT = '[Project Currency] Select';

export const UPDATE = '[Project Currency] Update';
export const UPDATE_COMPLETE = '[Project Currency] Update Complete';
export const UPDATE_FAILED = '[Project Currency] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: ProjectCurrency) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: ProjectCurrency[]) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: ProjectCurrency) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: {
        deletedPC: ProjectCurrency,
        usPC: ProjectCurrency
    }) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: ProjectCurrency[]) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: { error: HttpErrorResponse, franchiseId: number }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: ProjectCurrency) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: ProjectCurrency[]) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | SelectAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
