import { createSelector } from 'reselect';
import * as root from '../';
import * as fromLedger from './ledger.reducer';

const getLedgerIdsForFranchise = createSelector(root.getLedgerIdsForFranchises, root.getSelectedFranchise, (ledgerIds, franchise) => {
    return (franchise && ledgerIds[franchise.id]) ? ledgerIds[franchise.id] : [];
});
export const getLedgersForSelectedFranchise = createSelector(root.getLedgerEntities, getLedgerIdsForFranchise, (ledgers, ledgerIdsForFranchise) => {
    return ledgerIdsForFranchise.map(id => ledgers[id]);
});
export const getLedgerForSelectedTransaction = createSelector(root.getSelectedTransaction, getLedgersForSelectedFranchise, (transaction, ledger) => {
    return ledger.find(l => l.id === transaction.ledger_id);
});
export const getLedgerForSelectedTransactions = createSelector(root.getSelectedTransactionsToMatch, getLedgersForSelectedFranchise, (transactions, ledger) => {
    return transactions && transactions.length ? ledger.find(l => l.id === transactions[0].ledger_id) : null;
});
export const getCheckLedgerForPOStatus = createSelector(root.getLedgerState, fromLedger.getCheckForPOStatus);
export const getLedgerHasPurchaseOrder = createSelector(root.getLedgerState, fromLedger.getHasPurchaseOrder);
export const getLedgerSourceCodeColNameWarning = createSelector(root.getLedgerState, fromLedger.getSourceCodeColNameWarning);
export const getLedgerHasMultipleCurrencies = createSelector(root.getLedgerState, fromLedger.getHasMultipleCurrencies);
