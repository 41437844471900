import { Action } from '@ngrx/store';
import { AssetTransaction } from './asset-transaction.model'
import { Transaction } from '../models';

export const LIST_ASSET_TRANSACTION = '[Asset Transaction] List Asset Transactions';
export const LIST_ASSET_TRANSACTION_COMPLETE = '[Asset Transaction] List Asset Transactions Complete';
export const LIST_ASSET_TRANSACTION_FAILED = '[Asset Transaction] List Asset Transaction Failed';

export const GET_ASSET_TRANSACTION = '[Asset] Get Asset Transactions Data';
export const GET_ASSET_TRANSACTION_COMPLETE = '[Asset] Get Asset Transactions Data Complete';
export const GET_ASSET_TRANSACTION_FAILED = '[Asset] Get Asset Transaction Data Failed';

export class ListAssetTransactionAction implements Action {
    readonly type = LIST_ASSET_TRANSACTION;

    constructor(public payload: { assetIds: number[] }) { }
}

export class ListAssetTransactionCompleteAction implements Action {
    readonly type = LIST_ASSET_TRANSACTION_COMPLETE;

    constructor(public payload: { assetTransaction: AssetTransaction[] }) { }
}

export class ListAssetTransactionFailedAction implements Action {
    readonly type = LIST_ASSET_TRANSACTION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class GetAssetTransactionAction implements Action {
    readonly type = GET_ASSET_TRANSACTION;

    constructor(public payload: number) { }
}

export class GetAssetTransactionCompleteAction implements Action {
    readonly type = GET_ASSET_TRANSACTION_COMPLETE;

    constructor(public payload: { transactions: Transaction[] }) { }
}

export class GetAssetTransactionfailedAction implements Action {
    readonly type = GET_ASSET_TRANSACTION_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = ListAssetTransactionAction
    | ListAssetTransactionCompleteAction
    | ListAssetTransactionFailedAction
    | GetAssetTransactionAction
    | GetAssetTransactionCompleteAction
    | GetAssetTransactionfailedAction