import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export const ADD_TO_DIVISION_GROUP = '[DivisionGroup] Add To Division Group';
export const ADD_TO_DIVISION_GROUP_COMPLETE = '[DivisionGroup] Add Division Group Complete';
export const ADD_TO_DIVISION_GROUP_FAILED = '[DivisionGroup] Add Division Group Failed';

export const DELETE_FROM_DIVISION = '[DivisionGroup] Delete Group from Division';
export const DELETE_FROM_DIVISION_COMPLETE = '[DivisionGroup] Delete Group from Division Complete';
export const DELETE_FROM_DIVISION_FAILED = '[DivisionGroup] Delete Group from Division Failed';

export const SELECT = '[DivisionGroup] Select Division Group';

export const GET_ASSET_COUNT = '[DivisionGroup] Get Asset Count';
export const GET_ASSET_COUNT_COMPLETE = '[DivisionGroup] Get Asset Count Complete';
export const GET_ASSET_COUNT_FAILED = '[DivisionGroup] Get Asset Count Failed';

export class AddToDivisionGroupAction implements Action {
    readonly type = ADD_TO_DIVISION_GROUP;

    constructor(public payload: { groupId: number, divisionId: number, assetIds: number[] }) { }
}

export class AddToDivisionGroupCompleteAction implements Action {
    readonly type = ADD_TO_DIVISION_GROUP_COMPLETE;

    constructor(public payload: { groupId: number, assetIds: number[] }) { }
}

export class AddToDivisionGroupFailedAction implements Action {
    readonly type = ADD_TO_DIVISION_GROUP_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteFromDivisionAction implements Action {
    readonly type = DELETE_FROM_DIVISION;

    constructor(public payload: { groupId: number, divisionId: number }) { }
}

export class DeleteFromDivisionCompleteAction implements Action {
    readonly type = DELETE_FROM_DIVISION_COMPLETE;

    constructor(public payload: { groupId: number, divisionId: number, assetIds: number[] }) { }
}

export class DeleteFromDivisionFailedAction implements Action {
    readonly type = DELETE_FROM_DIVISION_FAILED;

    constructor(public payload: any) { }
}

export class SelectDivisionGroupAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class GetAssetCountAction implements Action {
    readonly type = GET_ASSET_COUNT;

    constructor(public payload: { groupId: number, divisionId: number }) { }
}

export class GetAssetCountCompleteAction implements Action {
    readonly type = GET_ASSET_COUNT_COMPLETE;

    constructor(public payload: number) { }
}

export class GetAssetCountFailedAction implements Action {
    readonly type = GET_ASSET_COUNT_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = AddToDivisionGroupAction
    | AddToDivisionGroupCompleteAction
    | AddToDivisionGroupFailedAction
    | DeleteFromDivisionAction
    | DeleteFromDivisionCompleteAction
    | DeleteFromDivisionFailedAction
    | SelectDivisionGroupAction
    | GetAssetCountAction
    | GetAssetCountCompleteAction
    | GetAssetCountFailedAction;
