import { BaseModel } from './../base/base.model';

export interface DivisionPermissionObject {
    id: number;
    division_id: number;
    studio_permission_id: number;
    admin: boolean;
    position: string;
    can_edit: boolean;
    can_access_restricted: boolean;
    can_access_asset_details: boolean;
    can_access_customers: boolean;
    can_access_asset_search: boolean;
    can_edit_groups: boolean;
    franchise_ids: string;
    can_view_historical: boolean;
}

export class DivisionPermission extends BaseModel {
    id: number;
    division_id: number = null;
    studio_permission_id: number = null;
    admin: boolean = false;
    position: string = null;
    can_edit: boolean = null;
    can_access_restricted: boolean = null;
    can_access_asset_details: boolean = null;
    can_access_customers: boolean = null;
    can_access_asset_search: boolean = null;
    can_edit_groups: boolean = null;
    franchise_ids: string = null;
    can_view_historical: boolean = false;

    constructor(divisionPermission: DivisionPermissionObject) {
        super();
        this.initializeData(divisionPermission, this);
    }
}
