import * as assetActions from './asset.actions';

export interface State {
    assetIdsForRequest: {
        [requestId: number]: number[];
    };
    requestTotalCount: {
        [requestId: number]: number;
    };
}

export const initialState: State = {
    assetIdsForRequest: {},
    requestTotalCount: {},
};

export function reducer(state = initialState, action: assetActions.Actions): State {
    switch (action.type) {
        case assetActions.LIST_BY_FINAL_APPROVER_REQUEST_COMPLETE:
        case assetActions.LIST_BY_REQUEST_COMPLETE: {
            const assets = action.payload.assets;
            const totalCount = action.payload.totalCount;
            const requestId = action.payload.requestId;
            const requestIdObj = {};
            const requestCountObj = {};

            requestCountObj[requestId] = totalCount;
            requestIdObj[requestId] = assets.map(a => a.id);
            return {
                ...state,
                assetIdsForRequest: {
                    ...state.assetIdsForRequest,
                    ...requestIdObj
                },
                requestTotalCount: {
                    ...state.requestTotalCount,
                    ...requestCountObj
                }
            };
        }

        default: {
            return state;
        }
    }
}

export const getAssetIdsForRequest = (state: State) => state.assetIdsForRequest;
export const getAssetCountsForRequest = (state: State) => state.requestTotalCount;
