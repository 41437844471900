import { createSelector } from 'reselect';
import * as subDepartmentActions from '../sub-departments/sub-department.actions';
import { copyAndMultiSort, getEntitiesObject, sortArray } from '../utils';
import * as departmentActions from './department.actions';
import { Department } from './department.model';

const defaultSort = { key: 'name', order: 1, args: [] };

export interface State {
    ids: number[];
    entities: { [departmentId: number]: Department };
    deptSubDeptIds: { [departmentId: number]: number[] };
    franchiseIds: number[];
    loading: boolean;
    selectedId: number;
    sort: { key: string, order: number, args: any[] };
}

export const initialState: State = {
    ids: [],
    entities: {},
    deptSubDeptIds: {},
    franchiseIds: [],
    loading: false,
    selectedId: null,
    sort: defaultSort
};

export function getSubDeptIds(state: State, dept: Department) {
    let subDeptIds = dept.subDepartments.map(subDept => subDept.id);
    if (state.deptSubDeptIds[dept.id] && subDeptIds.length) {
        const newSubDeptIds = subDeptIds.filter(id => !state.deptSubDeptIds[dept.id].includes(id));
        subDeptIds = state.deptSubDeptIds[dept.id].concat(newSubDeptIds);
    }

    return subDeptIds;
}

export function reducer(state = initialState, action: departmentActions.Actions | subDepartmentActions.Actions): State {
    switch (action.type) {
        case departmentActions.ADD:
        case departmentActions.LIST:
        case departmentActions.UPDATE: {
            return { ...state, loading: true };
        }

        case departmentActions.ADD_COMPLETE: {
            const departments = action.payload;
            const departmentsObj = getEntitiesObject(departments);

            const alterStateWith = {
                ids: [
                    ...state.ids,
                    ...departments.map(dept => dept.id)
                ],
                entities: { ...state.entities, ...departmentsObj },
                loading: false
            };
            return { ...state, ...alterStateWith };
        }

        case subDepartmentActions.ADD_COMPLETE: {
            const subDept = action.payload;
            let deptSubDeptIds = [subDept.id];
            if (state.deptSubDeptIds[subDept.department_id]) {
                deptSubDeptIds = [...state.deptSubDeptIds[subDept.department_id], subDept.id];
            }

            return {
                ...state,
                deptSubDeptIds: { ...state.deptSubDeptIds, [subDept.department_id]: deptSubDeptIds }
            };
        }

        case departmentActions.ADD_FAILED:
        case departmentActions.LIST_FAILED:
        case departmentActions.UPDATE_FAILED: {
            return { ...state, loading: false };
        }

        case departmentActions.LIST_COMPLETE: {
            const departments = action.payload.departments;
            const newDepartmentIds = departments.map(dept => dept.id);
            const uniqueDepartmentIds = newDepartmentIds.filter(id => !state.entities[id]);

            const departmentMap = {};
            const subDeptMap = { ...state.deptSubDeptIds };
            departments.forEach(dept => {
                departmentMap[dept.id] = dept;
                subDeptMap[dept.id] = getSubDeptIds(state, dept);
            });

            const ids = [...state.ids, ...uniqueDepartmentIds];
            const entities = { ...state.entities, ...departmentMap };
            return {
                ...state,
                ids: copyAndMultiSort(ids, entities, [{ key: defaultSort.key }], defaultSort.order),
                entities,
                deptSubDeptIds: subDeptMap,
                loading: false,
                sort: defaultSort
            };
        }

        case departmentActions.LIST_BY_FRANCHISE_COMPLETE: {
            const departments = action.payload;
            const newDepartmentIds = departments.map(dept => dept.id);
            const uniqueDepartmentIds = newDepartmentIds.filter(id => !state.entities[id]);

            const departmentMap = {};
            const subDeptMap = { ...state.deptSubDeptIds };
            departments.forEach(dept => {
                departmentMap[dept.id] = dept;
                subDeptMap[dept.id] = getSubDeptIds(state, dept);
            });

            const ids = [...state.ids, ...uniqueDepartmentIds];
            const entities = { ...state.entities, ...departmentMap };

            return {
                ...state,
                ids: copyAndMultiSort(ids, entities, [{ key: defaultSort.key }], defaultSort.order),
                entities,
                deptSubDeptIds: subDeptMap,
                franchiseIds: copyAndMultiSort(newDepartmentIds, entities, [{ key: defaultSort.key }], defaultSort.order),
                sort: defaultSort
            };
        }

        case departmentActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }

            return newState;
        }

        case departmentActions.SORT: {
            const key = action.payload.field;
            const order = action.payload.order;
            const args = action.payload.args;

            const sorted = copyAndMultiSort(state.ids, state.entities, [{ key }], order, args);
            return { ...state, ids: sorted, sort: { key, order, args } };
        }

        case departmentActions.UPDATE_COMPLETE: {
            const department = action.payload;

            const alterStateWith = {
                entities: { ...state.entities, [department.id]: department },
                loading: false
            };
            return { ...state, ...alterStateWith };
        }

        case departmentActions.DELETE_DEPARTMENT_FIELD_COMPLETE: {
            const deptField = action.payload;

            const entities = { ...state.entities };
            const dept = new Department(state.entities[deptField.department_id]);
            dept.departmentFields = dept.departmentFields.filter(df => df.id !== deptField.id);
            entities[dept.id] = dept;
            return { ...state, entities };
        }

        case departmentActions.ADD_DEPARTMENT_FIELD_COMPLETE: {
            const deptField = action.payload;

            const entities = { ...state.entities };
            const dept = new Department(state.entities[deptField.department_id]);
            dept.departmentFields.push(deptField);
            entities[dept.id] = dept;
            return { ...state, entities };
        }

        case departmentActions.UPDATE_DEPARTMENT_FIELD_COMPLETE: {
            const deptField = action.payload;

            const entities = { ...state.entities };
            const dept = new Department(state.entities[deptField.department_id]);
            const index = dept.departmentFields.findIndex(df => df.id === deptField.id);
            dept.departmentFields[index] = deptField;
            entities[dept.id] = dept;
            return { ...state, entities };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
const getFranchiseIds = (state: State) => state.franchiseIds;
const getSelectedId = (state: State) => state.selectedId;
export const getDeptSubDeptIds = (state: State) => state.deptSubDeptIds;
export const getLoading = (state: State) => state.loading;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => sortArray(ids.map(id => entities[id]), 'account_code'));
export const getByFranchise = createSelector(getEntities, getFranchiseIds, (entities, ids) => sortArray(ids.map(id => entities[id]), 'account_code'));
export const getSosByFranchise = createSelector(getEntities, getFranchiseIds, (entities, ids) => ids.map(id => entities[id]).filter(dept => !!dept.sos_department_id));
export const getSelected = createSelector(getEntities, getSelectedId, (entities, id) => entities[id]);
export const getSelectedSubDeptIds = createSelector(getSelectedId, getDeptSubDeptIds, (selectedId, deptSubDeptIds) => deptSubDeptIds[selectedId] ? deptSubDeptIds[selectedId] : []);
