import { createSelector } from 'reselect';
import * as root from '../';
import { Character } from '../characters/character.model';

export const getCharactersWithProjectCharacters = createSelector(root.getCharacters, root.getProjectCharacters, (chars, projectChars) => {
    return chars.map((char => {
        return Object.assign({}, char, {
            projectCharacters: projectChars.filter((pChar) => pChar.character_id === char.id)
        });
    }));
});

export const forSelectedAssetProject = createSelector(root.getSelectedAsset, getCharactersWithProjectCharacters, (asset, characters) =>
    characters.filter(c =>
        c.projectCharacters.find(pc =>
            pc.project_id === asset.project_id
        )
    )
);

export const forSelectedInlineEditAssetProject = createSelector(root.getSelectedInlineEditAsset, getCharactersWithProjectCharacters, (asset, characters) =>
    characters.filter(c =>
        c.projectCharacters.find(pc =>
            pc.project_id === asset.project_id
        )
    )
);

export const getCharactersForSelectedTransactionProject = createSelector(root.getCharacterEntities, root.getProjectCharacters, root.getSelectedTransaction, (charEntities, projectChars, trans) => {
    const pCharsForProject = projectChars.filter((projectChar) => projectChar.project_id === trans.project_id);

    return pCharsForProject.map((projectChar) => Object.assign({}, charEntities[projectChar.character_id], { projectCharacters: [projectChar] }));
});

export const getCharactersForSelectedProject = createSelector(root.getCharacterEntities, root.getProjectCharacters, root.getSidenavSettingsProjectIds, (charEntities, projectChars, projectIds) => {
    const projectId = projectIds[0];
    const pCharsForProject = projectChars.filter((projectChar) => projectChar.project_id === projectId);

    return pCharsForProject.map((projectChar) => new Character({ ...charEntities[projectChar.character_id], projectCharacters: [projectChar] }));
});

export const getForAssetModalSelectedProject = createSelector(root.getCharacterEntities, root.getProjectCharacters, root.getAssetModalSelectedProjectId, (charEntities, projectChars, projectId) => {
    const pCharsForProject = projectChars.filter((projectChar) => projectChar.project_id === projectId);

    return pCharsForProject.map((projectChar) => Object.assign({}, charEntities[projectChar.character_id], { projectCharacters: [projectChar] }));
});

export const getAssetBulkModalCharactersForSelectedProject = createSelector(root.getCharacterEntities, root.getProjectCharacters, root.getSidenavAssetProjectIds, root.getSelectedFranchiseProjects, (charEntities, projectChars, projectIds, franchiseProjects) => {
    let pCharsForProject;
    if (franchiseProjects.length === 1) {
        pCharsForProject = projectChars.filter((projectChar) => franchiseProjects[0].id === projectChar.project_id);
    } else {
        pCharsForProject = projectChars.filter((projectChar) => projectIds.includes(projectChar.project_id));
    }

    return pCharsForProject.map((projectChar) => Object.assign({}, charEntities[projectChar.character_id], { projectCharacters: [projectChar] }));
});

export const getTransactionBulkModalCharactersForSelectedProject = createSelector(root.getCharacterEntities, root.getProjectCharacters, root.getSidenavTransactionProjectIds, (charEntities, projectChars, projectIds) => {
    const pCharsForProject = projectChars.filter((projectChar) => projectIds.includes(projectChar.project_id));

    return pCharsForProject.map((projectChar) => Object.assign({}, charEntities[projectChar.character_id], { projectCharacters: [projectChar] }));
});
