import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layout from '../layout/layout.actions';
import * as sosFieldActions from './sos-field.actions';
import { SosField } from './sos-field.model';

@Injectable()
export class SosFieldEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosFieldActions.LIST),
            switchMap(() => this.http.get<SosField[]>(`/api/sos-fields`).pipe(
                map(res => res.map((u) => new SosField(u))),
                map((sosFields: SosField[]) => new sosFieldActions.ListCompleteAction(sosFields)),
                catchError(error => observableOf(new sosFieldActions.ListFailedAction({ error }))))
            )));

     listFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosFieldActions.LIST_FAILED),
            map((action: sosFieldActions.ListFailedAction) => action.payload),
            map(payload => new layout.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
