import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, timeout } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import { DivisionGroup, FranchiseGroup, Group } from '../models';
import { SubGroup } from '../sub-groups/sub-group.model';
import * as groupActions from './group.actions';

@Injectable()
export class GroupEffects {
    static BASE_URL = '/api/groups';

     addInFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.ADD_IN_FRANCHISE),
            map((action: groupActions.AddInFranchiseAction) => this.buildFormData(action)),
            mergeMap((data) => this.http.post(GroupEffects.BASE_URL, data).pipe(
                map((res: { group: Group, franchiseGroup: FranchiseGroup }) => new groupActions.AddInFranchiseCompleteAction({ group: new Group(res.group), franchiseGroup: new FranchiseGroup(res.franchiseGroup) })),
                catchError((error) => observableOf(new groupActions.AddInFranchiseFailedAction({ error }))))
            )));

     addInDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.ADD_IN_DIVISION),
            map((action: groupActions.AddInDivisionAction) => this.buildFormData(action)),
            mergeMap((data) => this.http.post(GroupEffects.BASE_URL, data).pipe(
                map((res: { group: Group, divisionGroup: DivisionGroup }) => new groupActions.AddInDivisionCompleteAction({ group: new Group(res.group), divisionGroup: new DivisionGroup(res.divisionGroup) })),
                catchError((error) => observableOf(new groupActions.AddInDivisionFailedAction({ error }))))
            )));

     addInStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.ADD_IN_STUDIO),
            map((action: groupActions.AddInStudioAction) => action.payload),
            mergeMap((data) => this.http.post(GroupEffects.BASE_URL, data).pipe(
                map((res: { group: Group }) => new groupActions.AddInStudioCompleteAction({ group: new Group(res.group) })),
                catchError((error) => observableOf(new groupActions.AddInStudioFailedAction({ error }))))
            )));

     get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.GET),
            map((action: groupActions.GetAction) => action.payload),
            switchMap(({ groupId, status }) => this.http.get(`/api/group/${groupId}?includeSubGroup=${status}`).pipe(
                map((group: Group) => new groupActions.GetCompleteAction({ group: group ? new Group(group) : null })),
                catchError((error) => observableOf(new groupActions.GetFailedAction({ error }))))
            )));

     getAssetCount$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.GET_ASSET_COUNT),
            map((action: groupActions.GetAssetCountAction) => action.payload),
            switchMap(({ studioId, groupId }) => this.http.get<number>(`/api/groups/${groupId}/studios/${studioId}/assets`).pipe(
                map((assetCount: number) => new groupActions.GetAssetCountCompleteAction({ groupId, assetCount })),
                catchError((error) => observableOf(new groupActions.GetAssetCountFailedAction({ error }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.LIST),
            map((action: groupActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<Group[]>(`/api/groups/${studioId}`).pipe(
                map(res => res.map((group) => new Group(group))),
                map((groups: Group[]) => new groupActions.ListCompleteAction({ studioId, groups })),
                catchError((error) => observableOf(new groupActions.ListFailedAction({ error }))))
            )));

     listByType$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.LIST_BY_STUDIO_GROUP_TYPE),
            map((action: groupActions.ListByStudioGroupTypeAction) => action.payload),
            switchMap(({ studioId, groupTypeId }) => ((groupTypeId)
                ? this.http.get<Group[]>(`/api/studio-groups/${studioId}?groupTypeId=${groupTypeId}`)
                : this.http.get<Group[]>(`/api/studio-groups/${studioId}`)).pipe(
                    map(res => res.map((group) => new Group(group))),
                    map((groups: Group[]) => new groupActions.ListByStudioGroupTypeCompleteAction({ studioId, groups })),
                    catchError((error) => observableOf(new groupActions.ListByStudioGroupTypeFailedAction({ error }))))
            )));


     deleteStudioGroups$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.DELETE_FROM_STUDIO),
            map((action: groupActions.DeleteFromStudioAction) => action.payload),
            switchMap((groupId) => this.http.delete(`/api/studio-groups/${groupId}`).pipe(
                map((assetIds: any[]) => new groupActions.DeleteFromStudioCompleteAction(groupId)),
                catchError((error) => observableOf(new groupActions.DeleteFromStudioFailedAction({ error }))))
            )));

     deleteComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.DELETE_FROM_STUDIO_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Group has been deleted.' }))));

     listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.LIST_BY_FRANCHISE),
            map((action: groupActions.ListByFranchiseAction) => action.payload),
            switchMap((franchiseId) => this.http.get<Group[]>(`/api/franchises/${franchiseId}/groups`).pipe(
                map(res => res.map((group) => new Group(group))),
                map((groups: Group[]) => new groupActions.ListByFranchiseCompleteAction({ franchiseId, groups })),
                catchError((error) => observableOf(new groupActions.ListByFranchiseFailedAction({ error }))))
            )));

     listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.LIST_BY_DIVISION),
            map((action: groupActions.ListByDivisionAction) => action.payload),
            switchMap((divisionId) => this.http.get<Group[]>(`/api/divisions/${divisionId}/groups`).pipe(
                map(res => res.map((group) => new Group(group))),
                map((groups: Group[]) => new groupActions.ListByDivisionCompleteAction({ divisionId, groups })),
                catchError((error) => observableOf(new groupActions.ListByDivisionFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.UPDATE),
            map((action: groupActions.UpdateAction) => action.payload),
            switchMap(group => this.http.put(`api/groups/${group.id}`, group).pipe(
                map(res => new Group(res)),
                map((res: Group) => new groupActions.UpdateCompleteAction(res)),
                catchError(error => observableOf(new groupActions.UpdateFailedAction({ error }))))
            )));

     convertGroupToGroup$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.CONVERT_GROUP_TO_SUBGROUP),
            map((action: groupActions.ConvertGroupToSubgroupAction) => action.payload),
            mergeMap(payload => this.http.put(`${GroupEffects.BASE_URL}/${payload.group_id}/convert`, payload.group).pipe(
                timeout(180001),
                map(res => new SubGroup(res)),
                map((subGroup: SubGroup) => new groupActions.ConvertGroupToSubgroupCompleteAction({ subGroup, groupId: payload.group_id })),
                catchError(error => observableOf(new groupActions.ConvertGroupToSubgroupFailedAction({ error }))))
            )
        ));


     convertSubgorupComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.CONVERT_GROUP_TO_SUBGROUP_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Group successfully converted into a Subgroup.' }))));

     convertSubgroupGroupToGroup$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.CONVERT_SUBGROUP_TO_GROUP),
            map((action: groupActions.ConvertSubgroupToGroupAction) => action.payload),
            mergeMap(payload => this.http.put(`/api/subgroups/${payload.subgroup_id}/convert`, payload.subGroup).pipe(
                timeout(180001),
                map(res => new Group(res)),
                map((group: Group) => new groupActions.ConvertSubgroupToGroupCompleteAction({ group, subgroup_id: payload.subgroup_id, group_id: payload.subGroup.group_id })),
                catchError(error => observableOf(new groupActions.ConvertSubgroupToGroupFailedAction({ error }))))
            )
        ));

     convertGroupComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.CONVERT_SUBGROUP_TO_GROUP_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Subgroup successfully converted into a Group' }))));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(groupActions.ADD_IN_FRANCHISE_FAILED, groupActions.ADD_IN_DIVISION_FAILED, groupActions.ADD_IN_STUDIO_FAILED, groupActions.LIST_BY_FRANCHISE_FAILED, groupActions.LIST_BY_DIVISION_FAILED, groupActions.UPDATE_FAILED, groupActions.GET_ASSET_COUNT_FAILED, groupActions.CONVERT_GROUP_TO_SUBGROUP_FAILED, groupActions.CONVERT_SUBGROUP_TO_GROUP_FAILED, groupActions.DELETE_FROM_STUDIO_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }

    buildFormData(action: groupActions.AddInFranchiseAction | groupActions.AddInDivisionAction) {
        const formData = new FormData();
        formData.append('group', JSON.stringify(action.payload.group));
        if (action instanceof groupActions.AddInFranchiseAction) {
            formData.append('franchiseId', JSON.stringify(action.payload.franchiseId));
        } else {
            formData.append('divisionId', JSON.stringify(action.payload.divisionId));
        }

        if (action.payload.attachments && action.payload.attachments.length) {
            formData.append('types', JSON.stringify(action.payload.types));
            action.payload.attachments.forEach(attachment => {
                formData.append('attachments', attachment, attachment.name);
            });
        }

        return formData;
    }
}
