import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import { Request, RequestAsset, RequestNote } from '../models';
import * as finalApproverRequestActions from './final-approver-request.actions';
import { FinalApproverRequest } from './final-approver-request.model';

@Injectable()
export class FinalApproverRequestEffects {
    static BASE_URL = '/api/final-approver-requests';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(finalApproverRequestActions.CREATE),
            map((action: finalApproverRequestActions.CreateAction) => action.payload),
            switchMap((data) => this.http.post(FinalApproverRequestEffects.BASE_URL, data).pipe(
                map((res: { finalApproverRequests: FinalApproverRequest[], request: Request, requestNote: RequestNote, requestAssets: RequestAsset[] }) => new finalApproverRequestActions.CreateCompleteAction({ request: new Request(res.request), requestNote: new RequestNote(res.requestNote), requestAssets: res.requestAssets ? res.requestAssets.map(r => new RequestAsset(r)) : [] })),
                catchError((error) => observableOf(new finalApproverRequestActions.CreateFailedAction({ error }))))
            )));

     alertFinalApprover$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(finalApproverRequestActions.ALERT_FINAL_APPROVER),
            map((action: finalApproverRequestActions.AlertFinalApproverAction) => action.payload),
            switchMap((data) => this.http.post(`/api/final-approver-request/${data.final_approver_request_id}/alert-final-approver`, {}).pipe(
                map(() => new finalApproverRequestActions.AlertFinalApproverCompleteAction()),
                catchError((error) => observableOf(new finalApproverRequestActions.AlertFinalApproverFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(finalApproverRequestActions.UPDATE),
            map((action: finalApproverRequestActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`/api/studio/${data.studio_id}/final-approver-request/${data.final_approver_request_id}`, { note: data.note }).pipe(
                map((res: { finalApproverRequest: FinalApproverRequest, request: Request, requestNote: RequestNote }) => new finalApproverRequestActions.UpdateCompleteAction({ finalApproverRequest: new FinalApproverRequest(res.finalApproverRequest), request: new Request(res.request), requestNote: new RequestNote(res.requestNote) })),
                catchError((error) => observableOf(new finalApproverRequestActions.UpdateFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(finalApproverRequestActions.ALERT_FINAL_APPROVER_FAILED, finalApproverRequestActions.CREATE_FAILED, finalApproverRequestActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
