import { createSelector } from 'reselect';
import * as assetActions from '../assets/asset.actions';
import { ActionStatus } from '../types';
import * as subGroupActions from './sub-group.actions';
import { getEntitiesObject, getIdsAndEntities, uniqueArrayMerge } from '../utils';
import { SubGroup } from './sub-group.model';

export interface State {
    ids: number[];
    entities: {
        [subGroupId: number]: SubGroup
    };
    subGroupIdsForAsset: {
        [assetId: number]: number[];
    };
    assetCountForSubGroupId: {
        [subGroupId: number]: number;
    };
    subGroupNames: {
        [subGroupId: number]: string;
    }
    loading: ActionStatus;
    saving: ActionStatus;
    adding: ActionStatus;
    selectedId: number;
}

export const initialState: State = {
    ids: [],
    entities: {},
    subGroupIdsForAsset: {},
    assetCountForSubGroupId: {},
    subGroupNames: {},
    loading: ActionStatus.Inactive,
    saving: ActionStatus.Inactive,
    adding: ActionStatus.Inactive,
    selectedId: null
};

export function reducer(state = initialState, action: subGroupActions.Actions | assetActions.Actions): State {
    switch (action.type) {

        case subGroupActions.SELECT_SUB_GROUP: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }

            return newState;
        }
        case subGroupActions.ADD_COMPLETE: {
            const subGroup = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [subGroup]);
            const allEnts = { ...state.entities, ...entities };
            const allIds = [...state.ids, ...ids];

            return {
                ...state,
                entities: allEnts,
                ids: allIds,
                adding: ActionStatus.Complete
            };
        }

        case subGroupActions.GET_COMPLETE: {
            const newGroup = action.payload.subGroup;
            const groupObj = getEntitiesObject([newGroup]);

            return {
                ...state,
                ids: uniqueArrayMerge(state.ids, [newGroup.id]),
                entities: {
                    ...state.entities,
                    ...groupObj,
                }
            };
        }

        case subGroupActions.GET_ASSET_COUNT_COMPLETE: {
            const assetCount = action.payload.assetCount;
            const subGroupId = action.payload.subGroupId;
            const assetCountForSubGroupId = { ...state.assetCountForSubGroupId };
            assetCountForSubGroupId[subGroupId] = assetCount;

            return {
                ...state,
                assetCountForSubGroupId
            };
        }

        case subGroupActions.UPDATE_COMPLETE: {
            const subGroup = action.payload;
            const entities = getEntitiesObject([subGroup]);
            const allEnts = { ...state.entities, ...entities };

            return {
                ...state,
                entities: allEnts,
                adding: ActionStatus.Complete
            };
        }

        case subGroupActions.ADD_FAILED: {
            return { ...state, adding: ActionStatus.Failed };
        }

        case subGroupActions.RESET_ADDING: {
            return { ...state, adding: ActionStatus.Inactive, saving: ActionStatus.Inactive };
        }

        case subGroupActions.DELETE_COMPLETE: {
            return { ...state, saving: ActionStatus.Complete }
        }

        case subGroupActions.GET_NAMES_COMPLETE: {
            const subGroupNames = action.payload;

            return {
                ...state,
                subGroupNames: subGroupNames
            };
        }

        default: {
            return state;
        }
    }
}

export const getIds = (state: State) => state.ids;
export const getEntities = (state: State) => state.entities;
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getAdding = (state: State) => state.adding;
export const getGroupIdsForAsset = (state: State) => state.subGroupIdsForAsset;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => {
    return entities[id];
}));
export const getAssetCountForSubGroupId = (state: State) => state.assetCountForSubGroupId;
export const getSelectedId = (state: State) => state.selectedId;
export const getSubGroupNames = (state: State) => state.subGroupNames;
