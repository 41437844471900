import { Action } from '@ngrx/store';
import { Warehouse } from '../warehouses/warehouse.model';
import { Division } from './division.model';

export const LIST_BY_STUDIO = '[Division] List By Studio';
export const LIST_BY_STUDIO_COMPLETE = '[Division] List By Studio Complete';
export const LIST_BY_STUDIO_FAILED = '[Division] List By Studio Failed';
export const LIST_MOVE_OPTIONS = '[Division] List Move Options';
export const LIST_MOVE_OPTIONS_COMPLETE = '[Division] List Move Options Complete';
export const LIST_MOVE_OPTIONS_FAILED = '[Division] List Move Options Failed';
export const GET = '[Division] Get';
export const GET_COMPLETE = '[Division] Get Complete';
export const GET_FAILED = '[Division] Get Failed';
export const SELECT = '[Division] Select';
export const SELECT_COMPLETE = '[Division] Select Complete';
export const ADD = '[Division] Add';
export const ADD_COMPLETE = '[Division] Add Complete';
export const ADD_FAILED = '[Division] Add Failed';
export const UPDATE = '[Division] Update';
export const UPDATE_COMPLETE = '[Division] Update Complete';
export const UPDATE_FAILED = '[Division] Update Failed'
export class ListByStudioAction implements Action {
    readonly type = LIST_BY_STUDIO;

    constructor(public payload: number) { }
}

export class ListByStudioCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_COMPLETE;

    constructor(public payload: Division[]) { }
}

export class ListByStudioFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { division: Division, warehouse?: Warehouse }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Division) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { studioId: number, error: any }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { division: Division, warehouse?: Warehouse }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Division) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { studioId: number, error: any }) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: Division) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: { error: any }) { }
}

export class ListMoveOptionsAction implements Action {
    readonly type = LIST_MOVE_OPTIONS;

    constructor(public payload: number) { }
}

export class ListMoveOptionsCompleteAction implements Action {
    readonly type = LIST_MOVE_OPTIONS_COMPLETE;

    constructor(public payload: Division[]) { }
}

export class ListMoveOptionsFailedAction implements Action {
    readonly type = LIST_MOVE_OPTIONS_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class SelectCompleteAction implements Action {
    readonly type = SELECT_COMPLETE;

    constructor() { }
}

export class SelectAction implements Action {
    readonly type = SELECT;
    constructor(public payload: number) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListByStudioAction
    | ListByStudioCompleteAction
    | ListByStudioFailedAction
    | ListMoveOptionsAction
    | ListMoveOptionsCompleteAction
    | ListMoveOptionsFailedAction
    | SelectAction
    | SelectCompleteAction;
