import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as assetActions from '../../core/store/assets/asset.actions';
import { Photo } from '../../core/store/models';
import { rootSelectors } from '../../core/store/selectors';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';

@Injectable()
export class GalleryService {
    index: number;
    icons = {
        arrowNext: 'material-icons keyboard_arrow_right',
        arrowPrev: 'material-icons keyboard_arrow_left',
        close: 'material-icons close',
        delete: 'material-icons delete',
        download: 'material-icons file_download',
        hollowStar: 'material-icons star_border',
        rotate: 'material-icons rotate_right',
        spinner: 'material-icons auto_renew',
        star: 'material-icons star',
        zoomIn: 'material-icons zoom_in',
        zoomOut: 'material-icons zoom_out',
    };

    private subs: Subscription;

    constructor(public dialog: MatDialog, private store: Store<rootSelectors.State>) { }

    deletePhoto(photos: Photo[]) {
        this.dialog.open(ConfirmModalComponent, {
            data: {
                title: 'Delete Photo?',
                message: `Are you sure you want to delete this photo?`,
                confirmButton: 'Delete',
                confirmColor: 'warn'
            }
        }).afterClosed().pipe(take(1)).subscribe(del => {
            if (del) {
                const photo = photos[this.index];
                this.store.dispatch(new assetActions.DeletePhotoAction({ photo }));
            }
        });
    }

    downloadPhoto(photos: Photo[]) {
        const photo = photos[this.index];
        this.store.dispatch(new assetActions.DownloadPhotoAction({ photo }));
    }

    rotatePhoto(photos: Photo[]) {
        const photo = photos[this.index];
        this.store.dispatch(new assetActions.RotatePhotoAction({ photo, rotation: 90 }));
    }

    setMainPhoto(photos: Photo[]) {
        const currentPhoto = photos[this.index];
        this.store.dispatch(new assetActions.SetMainPhotoAction({ asset_id: currentPhoto.asset_id, photo_id: currentPhoto.id }));
    }

    unsubscribe() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

    updateIcon(icon: string, action: any) {
        action.icon = icon;
    }

    updateIndex(i: number) {
        this.index = i;
    }

    updateMainPhotoStar(currentPhoto: Photo, action: any) {
        if (currentPhoto.main_photo) {
            this.updateIcon(this.icons.star, action);
            action.disabled = true;
        } else {
            this.updateIcon(this.icons.hollowStar, action);
            action.disabled = false;
        }
    }
}
