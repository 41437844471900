import { BaseModel } from '../base/base.model';

export class PicklistOption extends BaseModel {
    id: number;
    field_id: number = null;
    value: string = null;
    deleted: boolean = false;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(obj) {
        super();
        this.initializeData(obj, this);
    }

}