<div matDialogTitle>{{title}}</div>

<mat-dialog-content>
    <div class="modal-text">{{modalMessage}}</div>
    <ul class="modal-text" *ngFor="let message of messageList">
        <li>{{message}}</li>
    </ul>
</mat-dialog-content>
<div class="flex justify-flex-end gap-20">
    <button mat-raised-button [mat-dialog-close]="true" >Close</button>
</div>
