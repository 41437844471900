import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as groupTypesActions from './group-types.actions';
import { GroupTypes } from './group-types.model';

@Injectable()
export class GroupTypesEffects {
    static BASE_URL = '/api/groupTypes';

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(groupTypesActions.LIST),
            map((action: groupTypesActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<GroupTypes[]>(`${GroupTypesEffects.BASE_URL}/${studioId}`).pipe(
                map(res => res.map((groupType) => new GroupTypes(groupType))),
                map((groupTypes: GroupTypes[]) => new groupTypesActions.ListCompleteAction({ studioId, groupTypes })),
                catchError((error) => observableOf(new groupTypesActions.ListFailedAction({ error }))))
            )));

    constructor(private actions$: Actions, private http: HttpClient) { }
}