import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import { Customer, Event } from '../models';
import * as customerActions from './customer.actions';

@Injectable()
export class CustomerEffects {

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(customerActions.ADD),
            map((action: customerActions.AddAction) => action.payload),
            switchMap((customerData) => this.http.post(`/api/customers`, customerData).pipe(
                map(res => new Customer(res)),
                map((newCustomer: Customer) => new customerActions.AddCompleteAction(newCustomer)),
                catchError((error) => observableOf(new customerActions.AddFailedAction({ error }))))
            )));

     addToEvent$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(customerActions.ADD_TO_EVENT),
            map((action: customerActions.AddToEventAction) => action.payload),
            switchMap(({ customer, eventId }) => this.http.post(`/api/events/${eventId}/customers`, customer).pipe(
                map((res: { customer: Partial<Customer>, event: Partial<Event> }) => ({
                    newCustomer: new Customer(res.customer),
                    event: new Event(res.event)
                })),
                map(({ newCustomer, event }) => new customerActions.AddToEventCompleteAction({
                    event,
                    customer: newCustomer
                })),
                catchError((error) => observableOf(new customerActions.AddToEventFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(customerActions.DELETE),
            map((action: customerActions.DeleteAction) => action.payload),
            switchMap((customerData) => this.http.delete(`/api/customers/${customerData.id}`).pipe(
                map(() => new customerActions.DeleteCompleteAction(customerData)),
                catchError((error) => observableOf(new customerActions.DeleteFailedAction({ error }))))
            )));

     listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(customerActions.LIST_BY_DIVISION),
            map((action: customerActions.ListByDivisionAction) => action.payload),
            switchMap((divisionId) => this.http.get<Customer[]>(`/api/divisions/${divisionId}/customers`).pipe(
                map(res => res.map((c) => new Customer(c))),
                map((customers: Customer[]) => new customerActions.ListByDivisionCompleteAction(customers)),
                catchError((error) => observableOf(new customerActions.ListByDivisionFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(customerActions.UPDATE),
            map((action: customerActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`/api/customers/${data.id}`, data).pipe(
                map(res => new Customer(res)),
                map((customer: Customer) => new customerActions.UpdateCompleteAction(customer)),
                catchError((error) => observableOf(new customerActions.UpdateFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(customerActions.ADD_FAILED, customerActions.DELETE_FAILED, customerActions.LIST_BY_DIVISION_FAILED, customerActions.UPDATE_FAILED, customerActions.ADD_TO_EVENT_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
