import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Studio } from '../../../core/store/studios/studio.model';
import { redirectToLogout } from '../../../shared/services/user.service';

const config = require('../../../../../package.json');

@Component({
    selector: 'ah-toolbar-menu',
    templateUrl: './toolbar-menu.component.html',
    styleUrls: ['./toolbar-menu.component.scss']
})
export class ToolbarMenuComponent {
    version = config.version;
    @Input() currentStudio: Studio;
    @Input() dark: boolean;
    @Input() studioList: Studio[];

    @Output() lightSwitch = new EventEmitter();
    @Output() selectStudioFromMenu = new EventEmitter();

    logout() {
        redirectToLogout();
    }
    getName({ name }) {
        const studioName = name?.length <= 16 ? name : name.substring(0, 18).concat("...");
        return studioName;
    }
}
