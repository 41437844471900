import { createSelector } from 'reselect';
import * as root from '../';
import { DataService } from '../../../shared/utils';
import * as bulkMoveModalSelectors from '../bulk-move-modal/bulk-move-modal.selectors';
import { WarehouseLocation, WarehouseSubLocation } from '../models';
import * as sidenavFilterSelectors from '../sidenav-filter/sidenav-filter.selectors';

function createWarehouseLocationWithSubLocations(warehouseLocation: WarehouseLocation, warehouseSubLocationEntities: { [subLocId: number]: WarehouseSubLocation }, warehouseLocToSubLocIds: { [warehouseLocationId: number]: number[] }) {
    return new WarehouseLocation({
        ...warehouseLocation,
        warehouseSubLocations: warehouseLocToSubLocIds[warehouseLocation.id] ? warehouseLocToSubLocIds[warehouseLocation.id].map(id => warehouseSubLocationEntities[id]) : []
    });
}

function createWarehouseLocationsWithSubLocations(warehouseLocations: WarehouseLocation[], warehouseSubLocationEntities: { [subLocId: number]: WarehouseSubLocation }, warehouseLocationSubLocationIds: { [warehouseLocationId: number]: number[] }) {
    return warehouseLocations.map(warehouseLocation => createWarehouseLocationWithSubLocations(warehouseLocation, warehouseSubLocationEntities, warehouseLocationSubLocationIds));
}

export const getWithSubLocations = createSelector(root.getWarehouseLocations, root.getWarehouseSubLocationEntities, root.getWarehouseLocationSubLocationIds, (warehouseLocations, subLocEntities, locSubLocIds) => {
    return createWarehouseLocationsWithSubLocations(warehouseLocations, subLocEntities, locSubLocIds);
});

export const getSelectedWithSubLocations = createSelector(root.getSelectedWarehouseLocation, root.getWarehouseSubLocationEntities, root.getWarehouseLocationSubLocationIds, (warehouseLocation, subLocEntities, locSubLocIds) => {
    if (warehouseLocation) {
        return createWarehouseLocationWithSubLocations(warehouseLocation, subLocEntities, locSubLocIds);
    }
    return warehouseLocation;
});

export const getForSelectedWarehouse = createSelector(sidenavFilterSelectors.getSidenavAssetWarehouseIds, getWithSubLocations, (selectedWarehouseIds, warehouseLocations) => {
    return warehouseLocations.filter(warehouseLocation => {
        return selectedWarehouseIds.includes(warehouseLocation.warehouse_id);
    });
});
export const getForSelectedWarehouseOnStoragePage = createSelector(sidenavFilterSelectors.getSidenavStorageWarehouseIds, getWithSubLocations, (selectedWarehouseIds, warehouseLocations) => {
    const unSortedWarehouseLocations = warehouseLocations.filter(warehouseLocation => {
        return selectedWarehouseIds.includes(warehouseLocation.warehouse_id);
    });
    return DataService.naturalSort(unSortedWarehouseLocations, 'name');
});

export const getForAssetModalSelectedWarehouse = createSelector(root.getAssetModalSelectedWarehouseId, getWithSubLocations, (selectedWarehouseId, warehouseLocations) => {
    return warehouseLocations.filter(warehouseLocation => {
        return warehouseLocation.warehouse_id === selectedWarehouseId;
    });
});

export const getSubLocationsForAssetModal = createSelector(root.getWarehouseSubLocationEntities, root.getWarehouseLocationSubLocationIds, root.getAssetModalSelectedWarehouseLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]) : [];
});

export const getForBulkMoveModalSelectedWarehouse = createSelector(bulkMoveModalSelectors.getBulkMoveModalSelectedWarehouseId, getWithSubLocations, (selectedWarehouseId, warehouseLocations) => {
    return warehouseLocations.filter(warehouseLocation => {
        return warehouseLocation.warehouse_id === selectedWarehouseId;
    });
});

export const getSubLocationsForBulkMoveModal = createSelector(root.getWarehouseSubLocationEntities, root.getWarehouseLocationSubLocationIds, bulkMoveModalSelectors.getBulkMoveModalSelectedWarehouseLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]) : [];
});
