import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SubGroup } from '../models';
import * as subGroupActions from './sub-group.actions';
import * as layoutActions from '../layout/layout.actions';

@Injectable()
export class SubGroupEffects {
    static BASE_URL = '/api/subgroups';
    constructor(private actions$: Actions, private http: HttpClient) { }

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.ADD),
            map((action: subGroupActions.AddAction) => this.buildFormData(action)),
            mergeMap((data) => this.http.post(SubGroupEffects.BASE_URL, data).pipe(
                map(res => new SubGroup(res)),
                map((newSubGroup: SubGroup) => new subGroupActions.AddCompleteAction(newSubGroup)),
                catchError((error) => observableOf(new subGroupActions.AddFailedAction({ error }))))
            )));

     addCompleted$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.ADD_COMPLETE),
            map((action: subGroupActions.AddCompleteAction) => action.payload),
            map(payload => new layoutActions.InfoAction({
                message: `${payload.name} saved successfully!`
            }))));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.UPDATE),
            map((action: subGroupActions.UpdateAction) => action.payload),
            mergeMap((subGroup) => this.http.put(`${SubGroupEffects.BASE_URL}/${subGroup.id}`, subGroup).pipe(
                map(res => new SubGroup(res)),
                map((newSubGroup: SubGroup) => new subGroupActions.UpdateCompleteAction(newSubGroup)),
                catchError((error) => observableOf(new subGroupActions.UpdateFailedAction({ error }))))
            )));

     updateCompleted$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.UPDATE_COMPLETE),
            map((action: subGroupActions.UpdateCompleteAction) => action.payload),
            map(payload => new layoutActions.InfoAction({
                message: `${payload.name} saved successfully!`
            }))));

     get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.GET),
            map((action: subGroupActions.GetAction) => action.payload),
            switchMap((subGroupId) => this.http.get(`api/subgroups/${subGroupId}`).pipe(
                map((subGroup: SubGroup) => new subGroupActions.GetCompleteAction({ subGroup: subGroup ? new SubGroup(subGroup) : null })),
                catchError((error) => observableOf(new subGroupActions.GetFailedAction({ error }))))
            )));

     getAssetCount$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.GET_ASSET_COUNT),
            map((action: subGroupActions.GetAssetCountAction) => action.payload),
            switchMap(({ studioId, subGroupId }) => this.http.get<number>(`/api/subgroups/${subGroupId}/studios/${studioId}/assets`).pipe(
                map((assetCount: number) => new subGroupActions.GetAssetCountCompleteAction({ subGroupId, assetCount })),
                catchError((error) => observableOf(new subGroupActions.GetAssetCountFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.DELETE),
            map((action: subGroupActions.DeleteAction) => action.payload),
            switchMap(({ id, franchiseId, divisionId }) => ((franchiseId)
                ? this.http.delete<{ subGroupId: number, assetIds: [] }>(`${SubGroupEffects.BASE_URL}/${id}?franchise_id=${franchiseId}`)
                : (divisionId)
                    ? this.http.delete<{ subGroupId: number, assetIds: [] }>(`${SubGroupEffects.BASE_URL}/${id}?division_id=${divisionId}`)
                    : this.http.delete<{ subGroupId: number, assetIds: [] }>(`/api/studio-sub-groups/${id}`)
            ).pipe(
                map(({ subGroupId }) => new subGroupActions.DeleteCompleteAction(subGroupId)),
                catchError((error) => observableOf(new subGroupActions.DeleteFailedAction({ error }))))
            )));

     deleteComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.DELETE_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Subgroup has been deleted.' }))
        ))

     getNames$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.GET_NAMES),
            map((action: subGroupActions.GetAssetSubGroupsNamesAction) => action.payload),
            mergeMap((data) => this.http.post(`${SubGroupEffects.BASE_URL}/names`, data).pipe(
                map(res => res),
                map((names: any) => new subGroupActions.GetAssetSubGroupsNamesCompleteAction(names)),
                catchError((error) => observableOf(new subGroupActions.GetAssetSubGroupsNamesFailedAction({ error }))))
            )));


     failed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(subGroupActions.ADD_FAILED, subGroupActions.LIST_FAILED, subGroupActions.GET_FAILED, subGroupActions.GET_ASSET_COUNT_FAILED, subGroupActions.UPDATE_FAILED, subGroupActions.DELETE_FAILED, subGroupActions.GET_NAMES_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));


    buildFormData(action: subGroupActions.AddAction) {
        const formData = new FormData();
        formData.append('subGroup', JSON.stringify(action.payload.subGroup));

        if (action.payload.attachments && action.payload.attachments.length) {
            formData.append('attachmentTypes', JSON.stringify(action.payload.types));
            action.payload.attachments.forEach(attachment => {
                formData.append('attachments', attachment, attachment.name);
            });
        }

        return formData;
    }
}
