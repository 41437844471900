import { BaseModel } from '../base/base.model';

export class SubLocation extends BaseModel {
    id: number;
    location_id: number = null;
    name: string = null;

    constructor(subLocationObj) {
        super();
        this.initializeData(subLocationObj, this);
    }
}
