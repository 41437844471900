import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';

@Injectable()
export class LayoutEffects {
     error$ = createEffect(() => this.actions$
        .pipe(
            ofType(layoutActions.ERROR),
            map((action: layoutActions.ErrorAction) => action.payload.error),
            map((err: HttpErrorResponse) => {
                let message;
                if (typeof err.error === 'string') {
                    message = err.error;
                } else if (err.error && typeof err.error.message !== 'undefined') {
                    message = err.error.message;
                }

                if (err.status === 504) {
                    message = 'Your request is taking a long time to process. It is still updating, please wait a few minutes and refresh. Contact customer support if the changes are not there.';
                    this.snackBar.open(message, 'Ok', {
                        panelClass: ['customize', 'info']
                    });
                } else {
                    if (err.status === 401) {
                        //this.router.navigate(['login']);
                        return new layoutActions.ErrorDisplayedAction();
                    } else if (err.status === 502) {
                        message = `ERROR: 502 Bad Gateway. Try the following: Refresh/reload the page`;
                    } else if (message) {
                        message = this.handleSpecialErrors(message);
                    } else if (err.status === 0) {
                        message = 'No internet connection. You will not be able to save changes until your connection is restored.';
                    } else {
                        message = 'An error has occurred. If this error persists, please contact support@synconset.com';
                    }
                    this.snackBar.open(message, 'Ok', {
                        panelClass: ['customize']
                    });
                }
                return new layoutActions.ErrorDisplayedAction();
            })));

     info$ = createEffect(() => this.actions$
        .pipe(
            ofType(layoutActions.INFO),
            map((action: layoutActions.InfoAction) => action.payload.message),
            map(message => {
                this.snackBar.open(message, 'Ok', {
                    panelClass: ['customize', 'success']
                });
                return new layoutActions.InfoDisplayedAction();
            })));

     clear$ = createEffect(() => this.actions$
        .pipe(
            ofType(layoutActions.CLEAR),
            map(() => {
                this.snackBar.dismiss();
                return new layoutActions.ClearedAction();
            })));

    constructor(private actions$: Actions, private snackBar: MatSnackBar) { }

    handleSpecialErrors(error) {
        if (error === '"location_id" must be a number') {
            error = 'Location is a required field. Please close this modal and change the location on this asset before editing it.';
        }
        return error;
    }
}
