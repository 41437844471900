import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RequestStatus } from '../../../../../shared/enums/request-status.enum';
import * as layoutActions from '../layout/layout.actions';
import { Asset, FinalApproverRequest, RequestAsset, RequestNote } from '../models';
import * as requestActions from './request.actions';
import { Request } from './request.model';

@Injectable()
export class RequestEffects {
    static BASE_URL = '/api/requests';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.ADD),
            map((action: requestActions.AddAction) => action.payload),
            switchMap((data) => this.http.post(RequestEffects.BASE_URL, data).pipe(
                map((res: { request: Request, requestNote: RequestNote }) => new requestActions.AddCompleteAction({ request: new Request(res.request), requestNote: new RequestNote(res.requestNote), assetIds: data.assetIds })),
                catchError((error) => observableOf(new requestActions.AddFailedAction({ error }))))
            )));

     alertApprover$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.ALERT_APPROVER),
            map((action: requestActions.AlertApproverAction) => action.payload),
            switchMap((data) => this.http.post(`/api/request/${data.request_id}/alert-approver`, {}).pipe(
                map(() => new requestActions.AlertApproverCompleteAction()),
                catchError((error) => observableOf(new requestActions.AlertApproverFailedAction({ error }))))
            )));

     closeRequests$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.CLOSE_REQUESTS),
            map((action: requestActions.CloseRequestsAction) =>
                action.payload),
            switchMap((data) => this.http.put(`${RequestEffects.BASE_URL}/close`, data).pipe(
                map((res: { requests: Request[] }) => new requestActions.CloseRequestsCompleteAction({ requests: res.requests ? res.requests.map(req => new Request(req)) : [] })),
                catchError((error) => observableOf(new requestActions.CloseRequestsFailedAction({ error }))))
            )));

     completeRequest$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.COMPLETE_REQUEST),
            map((action: requestActions.CompleteRequestAction) => action.payload),
            switchMap((data) => this.http.put(`${RequestEffects.BASE_URL}/${data.request.id}/status/${RequestStatus.Complete}`, { studio_id: data.studio_id, requestNote: data.requestNote }).pipe(
                map((res: { request: Request, requestNote: RequestNote }) => new requestActions.CompleteRequestCompleteAction({ request: res.request ? new Request(res.request) : null, requestNote: res.requestNote ? new RequestNote(res.requestNote) : null })),
                catchError((error) => observableOf(new requestActions.CompleteRequestFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.DELETE),
            map((action: requestActions.DeleteAction) =>
                action.payload),
            switchMap((data) => this.http.delete(`/api/request/${data.request_id}/studio/${data.studio_id}`).pipe(
                map(() => new requestActions.DeleteCompleteAction(data.request_id)),
                catchError((error) => observableOf(new requestActions.DeleteFailedAction({ error }))))
            )));

     get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.GET),
            map((action: requestActions.GetAction) =>
                action.payload),
            switchMap((data) => this.http.get(`/api/request/${data.request_id}/studio/${data.studio_id}/type/${data.requestType}`).pipe(map((res: { request: Request, requestNotes: RequestNote[], assets: Asset[], requestAssets: RequestAsset[], finalApproverRequest: FinalApproverRequest }) => new requestActions.GetCompleteAction({ request: new Request(res.request), requestNotes: res.requestNotes ? res.requestNotes.map(reqNote => new RequestNote(reqNote)) : [], assets: res.assets ? res.assets.map(asset => new Asset(asset)) : [], requestAssets: res.requestAssets ? res.requestAssets.map(rAsset => new RequestAsset(rAsset)) : [], finalApproverRequest: new FinalApproverRequest(res.finalApproverRequest) })),
                catchError((error) => observableOf(new requestActions.GetFailedAction({ error }))))
            )));

     listApprovalRequests$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.LIST_APPROVAL_REQUESTS),
            map((action: requestActions.ListApprovalRequestsAction) => action.payload),
            switchMap((studioId) => this.http.get(`/api/requests/${studioId}`).pipe(
                map((res: { requests: Request[], requestNotes: RequestNote[] }) => new requestActions.ListApprovalRequestsCompleteAction({ requests: res.requests ? res.requests.map(req => new Request(req)) : [], requestNotes: res.requestNotes ? res.requestNotes.map(reqNote => new RequestNote(reqNote)) : [] })),
                catchError((error) => observableOf(new requestActions.ListApprovalRequestsFailedAction({ error }))))
            )));

     listFinalApproverRequests$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.LIST_FINAL_APPROVER_REQUESTS),
            map((action: requestActions.ListFinalApproverRequestsAction) => action.payload),
            switchMap((studioId) => this.http.get(`/api/requests/${studioId}/final-approver`).pipe(
                map((res: { finalApproverRequests: FinalApproverRequest[], requests: Request[], requestNotes: RequestNote[] }) => new requestActions.ListFinalApproverRequestsCompleteAction({ finalApproverRequests: res.finalApproverRequests ? res.finalApproverRequests.map(req => new FinalApproverRequest(req)) : [], requests: res.requests ? res.requests.map(req => new Request(req)) : [], requestNotes: res.requestNotes ? res.requestNotes.map(reqNote => new RequestNote(reqNote)) : [] })),
                catchError((error) => observableOf(new requestActions.ListFinalApproverRequestsFailedAction({ error }))))
            )));

     listUnsentRequests$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.LIST_UNSENT_REQUESTS),
            map((action: requestActions.ListUnsentRequestsAction) => action.payload),
            switchMap((studioId) => this.http.get(`/api/requests/${studioId}/status/${RequestStatus.Unsent}`).pipe(
                map((res: { requests: Request[], requestNotes: RequestNote[] }) => new requestActions.ListUnsentRequestsCompleteAction({ requests: res.requests ? res.requests.map(req => new Request(req)) : [], requestNotes: res.requestNotes ? res.requestNotes.map(reqNote => new RequestNote(reqNote)) : [] })),
                catchError((error) => observableOf(new requestActions.ListUnsentRequestsFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.UPDATE),
            map((action: requestActions.UpdateAction) => action.payload),
            switchMap((data) => this.http.put(`${RequestEffects.BASE_URL}/${data.request.id}`, data).pipe(
                map((res: { request: Request, requestNote: RequestNote }) => new requestActions.UpdateCompleteAction({ request: new Request(res.request), requestNote: new RequestNote(res.requestNote), assetIds: data.assetIds })),
                catchError((error) => observableOf(new requestActions.UpdateFailedAction({ error }))))
            )));

     sendRequest$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.SEND_REQUEST),
            map((action: requestActions.SendRequestAction) => action.payload),
            switchMap((data) => this.http.put(`${RequestEffects.BASE_URL}/${data.request_id}/status/${RequestStatus.Incomplete}`, { studio_id: data.studio_id }).pipe(
                map((res: { request: Request }) => new requestActions.SendRequestCompleteAction({ request: new Request(res.request) })),
                catchError((error) => observableOf(new requestActions.SendRequestFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(requestActions.ADD_FAILED, requestActions.ALERT_APPROVER_FAILED, requestActions.CLOSE_REQUESTS_FAILED, requestActions.COMPLETE_REQUEST_FAILED, requestActions.DELETE_FAILED, requestActions.GET_FAILED, requestActions.LIST_APPROVAL_REQUESTS_FAILED, requestActions.LIST_FINAL_APPROVER_REQUESTS_FAILED, requestActions.LIST_UNSENT_REQUESTS_FAILED, requestActions.UPDATE_FAILED, requestActions.SEND_REQUEST_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
