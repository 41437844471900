import { createSelector } from 'reselect';
import { sortStrings } from '../utils';
import * as statusActions from './status.actions';
import { Status } from './status.model';

export interface State {
    ids: number[];
    entities: {
        [statusId: number]: Status;
    };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: statusActions.Actions): State {
    switch (action.type) {
        case statusActions.LIST_COMPLETE: {
            const statuses = action.payload;
            const { entities, ids } = statuses.reduce((acc, d) => {
                if (!state.entities[d.id]) {
                    acc.ids.push(d.id);
                }
                acc.entities[d.id] = d;
                return acc;
            }, { ids: [], entities: {} });

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;

export const getStatuses = createSelector(getEntities, getIds, (ents, ids) =>
    ids.map(id => ents[id]).sort((a, b) => sortStrings(a.name, b.name))
);
