import { Action } from '@ngrx/store';
import { Project } from './project.model';

export const ADD = '[Project] Add';
export const ADD_COMPLETE = '[Project] Add Complete';
export const ADD_FAILED = '[Project] Add Failed';

export const UPDATE = '[Project] Update';
export const UPDATE_COMPLETE = '[Project] Update Complete';
export const UPDATE_FAILED = '[Project] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { project: Project, franchiseId: number }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;
    constructor(public payload: { project: Project, franchiseId: number }) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: any, franchiseId: number }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { project: Project, franchiseId: number }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;
    constructor(public payload: Project) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any, franchiseId: number }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
