import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as divisionActions from './division.actions';
import { Division } from './division.model';

@Injectable()
export class DivisionEffects {
     listByStudio$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.LIST_BY_STUDIO),
            map((action: divisionActions.ListByStudioAction) => action.payload),
            switchMap((studioId) => this.http.get<Division[]>(`/api/studios/${studioId}/divisions`).pipe(
                map(res => res.map(divisionObj => new Division(divisionObj))),
                map(divisionModels => new divisionActions.ListByStudioCompleteAction(divisionModels)),
                catchError((error) => observableOf(new divisionActions.ListByStudioFailedAction({ error, studioId }))))
            )));

     listMoveOptions$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.LIST_MOVE_OPTIONS),
            map((action: divisionActions.ListMoveOptionsAction) => action.payload),
            switchMap((studioId) => this.http.get<Division[]>(`/api/studios/${studioId}/divisions/move-list`).pipe(
                map(res => res.map(divisionObj => new Division(divisionObj))),
                map(divisionModels => new divisionActions.ListMoveOptionsCompleteAction(divisionModels)),
                catchError((error) => observableOf(new divisionActions.ListMoveOptionsFailedAction({ error, studioId }))))
            )));

     get$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.GET),
            map((action: divisionActions.GetAction) => action.payload),
            switchMap((divisionId) => this.http.get(`/api/divisions/${divisionId}`).pipe(
                map(res => {
                    const response = res;
                    return new Division(response);
                }),
                map((division: Division) => new divisionActions.GetCompleteAction(division)),
                catchError((error) => observableOf(new divisionActions.GetFailedAction({ error }))))
            )));

     requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.GET_FAILED, divisionActions.LIST_BY_STUDIO_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

     add$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.ADD),
            map((action: divisionActions.AddAction) => {
                const divisionData: any = {
                    ...action.payload.division,
                    warehouse: null
                }
                if (action.payload.warehouse) {
                    divisionData.warehouse = { ...action.payload.warehouse }
                }
                return divisionData
            }),
            switchMap((divisionData) => this.http.post(`/api/divisions`, divisionData).pipe(
                map(res => new Division(res)),
                map((newDivision: Division) => new divisionActions.AddCompleteAction(newDivision)),
                catchError(error => observableOf(new divisionActions.AddFailedAction({ error, studioId: divisionData.studio_id })))
            ))
        ))

     addFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.ADD_FAILED),
            map((action: divisionActions.AddFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))
        ));

     addComplete$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.ADD_COMPLETE),
            map((action: divisionActions.AddCompleteAction) => action.payload),
            map((payload) => new layoutActions.InfoAction({ message: `Division "${payload.name}" created successfully.` }))
        ));

     update$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.UPDATE),
            map((action: divisionActions.UpdateAction) => {
                const divisionData: any = {
                    ...action.payload.division
                }
                return divisionData
            }),
            switchMap((divisionData) => this.http.put(`/api/divisions/${divisionData.id}`, divisionData).pipe(
                map(res => new Division(res)),
                map((newDivision: Division) => new divisionActions.UpdateCompleteAction(newDivision)),
                catchError(error => observableOf(new divisionActions.UpdateFailedAction({ error, studioId: divisionData.studio_id })))
            ))
        ))

     updateFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.UPDATE_FAILED),
            map((action: divisionActions.UpdateFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))
        ));

     updateComplete$ = createEffect(() => this.actions$
        .pipe(
            ofType(divisionActions.UPDATE_COMPLETE),
            map((action: divisionActions.UpdateCompleteAction) => action.payload),
            map((payload) => new layoutActions.InfoAction({ message: `Division "${payload.name}" updated successfully.` }))
        ));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
