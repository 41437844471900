import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as pluralize from 'pluralize';
import { Observable, of as observableOf } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { STANDARD_FIELDS } from '../../../../../shared/fields/standard-fields';
import { ASSET_MODAL_TYPE } from '../../../assets/asset-modal/asset-modal.component';
import * as bulkMoveModalActions from '../../../core/store/bulk-move-modal/bulk-move-modal.actions';
import * as divisionIndexActions from '../../../core/store/division-asset-index/division-asset-index.actions';
import { DIVISION_EXCLUDED_FIELDS, FRANCHISE_EXCLUDED_FIELDS } from '../../../core/store/fields/field.model';
import * as layoutActions from '../../../core/store/layout/layout.actions';
import {
    Asset,
    DepartmentField,
    Division,
    Field,
    FieldWithDepartmentField,
    Franchise,
    Location,
    Project,
    StorageBox,
    Studio,
    SubLocation,
    User,
    WarehouseLocation,
    WarehouseSubLocation
} from '../../../core/store/models';
import { rootSelectors } from '../../../core/store/selectors';
import * as storageBoxActions from '../../../core/store/storage-boxes/storage-box.actions';
import { OwnerType, SavingMessage, Severity, TableTypes } from '../../../core/store/types';
import { buildPDropdownOptions, getStudioFieldName } from '../../../core/store/utils';
import { SaveAssetsOptions } from '../../data-services/asset-data.service';
import { PDropdownOption } from '../../interfaces/p-dropdown-options';

export interface BulkMoveModalData {
    assets: Asset[];
    checkAndSaveAssets?: (newAssets: Asset[], action: string, dialogRef: MatDialogRef<any>, tableType: TableTypes, options?: SaveAssetsOptions) => void;
    clearAllSelectedAssets: () => void;
    divisions: Division[];
    fields: Field[];
    /** all franchises the user has access to */
    franchises: Franchise[];
    locations$: Observable<Location[]>;
    /** franchises that a user can move an asset to based on perms */
    ownedFranchises: Franchise[];
    projects$: Observable<Project[]>;
    storageBoxes$: Observable<StorageBox[]>;
    studio: Studio;
    subLocationsForLocation$: Observable<SubLocation[]>;
    tableType: TableTypes;
    title?: string;
    user: User;
    verb?: string;
    warehouseLocations$: Observable<WarehouseLocation[]>;
    warehouseSubLocationsForLocation$: Observable<WarehouseSubLocation[]>;
}

@Component({
    templateUrl: './bulk-move-modal.component.html',
    styleUrls: ['./bulk-move-modal.component.scss']
})
export class BulkMoveModalComponent implements OnInit {
    allAssets: Asset[];
    allFields: FieldWithDepartmentField[];
    /** Assets that the user has perms to edit */
    assets: Asset[] = [];
    assetsWithoutPermsTo: Asset[] = [];
    checkAndSaveAssets?: (newAssets: Asset[], action: string, dialogRef: MatDialogRef<any>, tableType: TableTypes,mutation?: any, options?: SaveAssetsOptions) => void;
    clearAllSelectedAssets: () => void;
    currentFranchiseOption: PDropdownOption[] = [];
    disableOwnerChange: boolean;
    division: Division;
    divisionOptions: PDropdownOption[] = [];
    divisions: Division[];
    fields: FieldWithDepartmentField[];
    franchise: Franchise;
    franchiseOptions: PDropdownOption[] = [];
    franchises: Franchise[] = [];
    locationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    movingToDivision = false;
    ownedFranchises: Franchise[] = [];
    ownerOptions: PDropdownOption[] = [];
    ownerForm: UntypedFormGroup;
    OwnerType = OwnerType;
    pluralize = pluralize;
    projectName = 'Project';
    projectOptions$: Observable<PDropdownOption[]> = observableOf([]);
    saveDisabled = false; /** set in AssetDataService */
    savingMessage: SavingMessage[] = [];
    /** all assets in this modal MUST have the same owner initially */
    selectedOwner: number;
    standardFields = STANDARD_FIELDS;
    storageBoxOptions$: Observable<PDropdownOption[]> = observableOf([]);
    studio: Studio;
    subLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    title = 'Move Assets';
    tableType: TableTypes;
    user: User;
    verb = 'move';
    warehouseLocationSubLocationShelfDisabled = false;
    warehouseLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    warehouseOptions: PDropdownOption[] = [];
    warehouseSubLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);

    divisionOption = { value: OwnerType.Division, label: 'Owner: Division', styleClass: 'owner-option-division' };
    franchiseOption = { value: OwnerType.Franchise, label: 'Owner: Feature', styleClass: 'owner-option-franchise' };
    seriesOption = { value: OwnerType.Series, label: 'Owner: Series', styleClass: 'owner-option-series' };

    constructor(@Inject(MAT_DIALOG_DATA) private data: BulkMoveModalData, public dialog: MatDialog, public dialogRef: MatDialogRef<BulkMoveModalComponent>, private fb: UntypedFormBuilder, private store: Store<rootSelectors.State>) { }

    ngOnInit() {
        this.allAssets = this.data.assets;
        this.fields = this.data.fields.map(field => {
            return { field, departmentField: new DepartmentField({ required: false }) };
        });
        this.allFields = this.fields;
        this.user = this.data.user;
        this.franchises = this.data.franchises;
        this.ownedFranchises = this.data.ownedFranchises;
        this.divisions = this.data.divisions;
        this.studio = this.data.studio;
        this.checkAndSaveAssets = this.data.checkAndSaveAssets;
        this.clearAllSelectedAssets = this.data.clearAllSelectedAssets;
        this.tableType = this.data.tableType;
        if (this.data.title) {
            this.title = this.data.title;
        }
        if (this.data.verb) {
            this.verb = this.data.verb;
        }
        this.setupModal();

        this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
            this.store.dispatch(new bulkMoveModalActions.SelectDivisionAction(null));
            this.store.dispatch(new bulkMoveModalActions.SelectFranchiseAction(null));
            this.store.dispatch(new bulkMoveModalActions.SelectLocationAction(null));
            this.store.dispatch(new bulkMoveModalActions.SelectProjectAction(null));
            this.store.dispatch(new bulkMoveModalActions.SelectWarehouseAction(null));
            this.store.dispatch(new bulkMoveModalActions.SelectWarehouseLocationAction(null));
        });
    }

    assetsValuesInit() {
        // Check perms for which assets they can update
        this.allAssets.forEach(asset => {
            let canEdit = false;
            if (asset.division_id) {
                const division = this.divisions.find(div => div.id === asset.division_id);
                canEdit = this.user.permission.canEditInDivision(division.id, division.studio_id);
            } else {
                const franchise = this.franchises.find(f => f.id === asset.franchise_id);
                canEdit = this.user.permission.canEditInFranchise(franchise.id, franchise.studio_id);
            }
            if (canEdit) {
                this.assets.push(asset);
            } else {
                this.assetsWithoutPermsTo.push(asset);
            }
        });
        const franchiseId = this.getCommonValueFromAssets(this.standardFields.franchise.canonical_name);
        if (franchiseId) {
            this.franchise = this.franchises.find(f => f.id === franchiseId);
            if (this.franchise) {
                this.selectedOwner = this.franchise.is_series ? OwnerType.Series : OwnerType.Franchise;
                this.store.dispatch(new bulkMoveModalActions.SelectFranchiseAction(franchiseId));
            }
        }
        const divisionId = this.getCommonValueFromAssets(this.standardFields.division.canonical_name);
        if (divisionId) {
            this.division = this.divisions.find(f => f.id === divisionId);
            this.selectedOwner = OwnerType.Division;
            this.store.dispatch(new bulkMoveModalActions.SelectDivisionAction(divisionId));
        }
        const projectId = this.getCommonValueFromAssets(this.standardFields.project.canonical_name);
        if (projectId) {
            this.store.dispatch(new bulkMoveModalActions.SelectProjectAction(projectId));
        }
        const locationId = this.getCommonValueFromAssets(this.standardFields.location.canonical_name);
        if (locationId) {
            this.store.dispatch(new bulkMoveModalActions.SelectLocationAction(locationId));
        }
        const subLocationId = this.getCommonValueFromAssets(this.standardFields.subLocation.canonical_name);
        if (subLocationId) {
            this.store.dispatch(new bulkMoveModalActions.SelectSubLocationAction(subLocationId));
        }
        const warehouseId = this.getCommonValueFromAssets(this.standardFields.warehouse.canonical_name);
        if (warehouseId) {
            this.store.dispatch(new bulkMoveModalActions.SelectWarehouseAction(warehouseId));
            this.store.dispatch(new storageBoxActions.ListByWarehouseAction(warehouseId));
        }
        const warehouseLocationId = this.getCommonValueFromAssets(this.standardFields.warehouseLocation.canonical_name);
        if (warehouseLocationId) {
            this.store.dispatch(new bulkMoveModalActions.SelectWarehouseLocationAction(warehouseLocationId));
        }
        const warehouseSubLocationId = this.getCommonValueFromAssets(this.standardFields.warehouseSubLocation.canonical_name);
        if (warehouseSubLocationId) {
            this.store.dispatch(new bulkMoveModalActions.SelectWarehouseSubLocationAction(warehouseSubLocationId));
        }
        const storageBoxId = this.getCommonValueFromAssets(this.standardFields.storageBox.canonical_name);
        if (storageBoxId) {
            this.warehouseLocationSubLocationShelfDisabled = true;
        }
    }

    getCommonValueFromAssets(fieldName: string) {
        if (this.assets.length) {
            const sameForAllAssets = this.assets.every(a => a[fieldName] && a[fieldName] === this.assets[0][fieldName]);
            if (sameForAllAssets) {
                return this.assets[0][fieldName];
            }
        }
        return null;
    }

    setupModal() {
        this.assetsValuesInit();
        this.filterOwnerFields();
        this.buildForm();

        const allFieldsWithoutDeptField: Field[] = this.allFields.map(a => a.field);
        const franchiseName = getStudioFieldName(this.standardFields.franchise, allFieldsWithoutDeptField);
        const divisionName = getStudioFieldName(this.standardFields.division, allFieldsWithoutDeptField);
        this.projectName = getStudioFieldName(this.standardFields.project, allFieldsWithoutDeptField);

        if (this.user.permission.hasDivisionPermissionForStudio(this.studio.id)) {
            this.divisionOption.label = `Owner: ${divisionName}`;
            this.ownerOptions.push(this.divisionOption);
        }
        if (!this.studio.franchise_hidden) {
            this.franchiseOption.label = `Owner: ${franchiseName}`;
            this.ownerOptions.push(this.franchiseOption);
        }
        if (!this.studio.series_hidden) {
            this.ownerOptions.push(this.seriesOption);
        }

        this.divisionOptions = buildPDropdownOptions(this.divisions);

        this.currentFranchiseOption = this.franchise ? buildPDropdownOptions([this.franchise], { nullOption: true, nullLabel: `${franchiseName}/Series` }) : [];
        if (this.selectedOwner === OwnerType.Franchise) {
            this.franchiseOptions = buildPDropdownOptions(this.ownedFranchises.filter(f => !f.is_series), { nullOption: true, nullLabel: `${franchiseName} *` });
        } else if (this.selectedOwner === OwnerType.Series) {
            this.franchiseOptions = buildPDropdownOptions(this.ownedFranchises.filter(f => f.is_series), { nullOption: true, nullLabel: 'Series *' });
        } else {
            this.franchiseOptions = buildPDropdownOptions(this.ownedFranchises, { nullOption: true, nullLabel: `${franchiseName}/Series` });
        }

        this.projectOptions$ = this.data.projects$.pipe(map(projects => buildPDropdownOptions(projects)));

        const division = this.division ? this.data.divisions.find(div => div.id === this.division.id) : null;
        const warehouseName = getStudioFieldName(this.standardFields.warehouse, allFieldsWithoutDeptField);
        this.warehouseOptions = division && division.warehouses ? buildPDropdownOptions(division.warehouses, { nullOption: true, nullLabel: `${warehouseName} *` }) : [];

        const locationName = getStudioFieldName(this.standardFields.location, allFieldsWithoutDeptField);
        this.locationOptions$ = this.data.locations$.pipe(map(locs => buildPDropdownOptions(locs, { nullOption: true, nullLabel: locationName })));

        const warehouseLocationName = getStudioFieldName(this.standardFields.warehouseLocation, allFieldsWithoutDeptField);
        this.warehouseLocationOptions$ = this.data.warehouseLocations$.pipe(map(locs => buildPDropdownOptions(locs, { nullOption: true, nullLabel: warehouseLocationName })));

        const subLocationName = getStudioFieldName(this.standardFields.subLocation, allFieldsWithoutDeptField);
        this.subLocationOptions$ = this.data.subLocationsForLocation$.pipe(map(sublocs => buildPDropdownOptions(sublocs, { nullOption: true, nullLabel: subLocationName })));

        const warehouseSubLocationName = getStudioFieldName(this.standardFields.warehouseSubLocation, allFieldsWithoutDeptField);
        this.warehouseSubLocationOptions$ = this.data.warehouseSubLocationsForLocation$.pipe(map(sublocs => buildPDropdownOptions(sublocs, { nullOption: true, nullLabel: warehouseSubLocationName })));

        const storageBoxName = getStudioFieldName(this.standardFields.storageBox, allFieldsWithoutDeptField);
        this.storageBoxOptions$ = this.data.storageBoxes$.pipe(map(storageBoxes => storageBoxes ? buildPDropdownOptions(storageBoxes, { nullOption: true, nullLabel: storageBoxName }) : []));

        this.disableOwnerChange = !this.divisionOptions.length && !this.franchiseOptions.length;
    }

    buildForm() {
        this.selectedOwner = this.ownerForm ? this.ownerForm.getRawValue().owner : this.selectedOwner;
        this.setRequiredFields();
        const formObj = {};
        this.fields.forEach(field => {
            if (field.field.canonical_name === 'owner') {
                formObj['owner'] = [
                    { value: (this.ownerForm && this.ownerForm.value['owner']) ? this.ownerForm.value['owner'] : this.selectedOwner, disabled: false },
                    []
                ];
            } else {
                const initial = {
                    value: (this.ownerForm && this.ownerForm.value[field.field.canonical_name]) ? this.ownerForm.value[field.field.canonical_name] : this.getCommonValueFromAssets(field.field.canonical_name),
                    disabled: false
                };
                const validators = field.field.getValidators(field.departmentField.required);
                formObj[field.field.canonical_name] = [initial, validators];
            }
        });
        this.ownerForm = this.fb.group(formObj);
    }

    selectOwner() {
        this.selectedOwner = this.ownerForm.getRawValue().owner;
        this.filterOwnerFields();
        let switchFranchiseType;
        const currentFranchiseId = this.ownerForm.getRawValue().franchise_id;
        const currentFranchise = this.franchises.find(f => f.id === currentFranchiseId);
        const currentProjectId = this.ownerForm.getRawValue().project_id;
        const franchiseName = getStudioFieldName(this.standardFields.franchise, this.allFields.map(a => a.field));
        if (this.selectedOwner === OwnerType.Division) {
            this.franchiseOptions = buildPDropdownOptions(this.franchises, { nullOption: true, nullLabel: `${franchiseName}/Series` });
            this.movingToDivision = true;
            if (currentFranchiseId) {
                this.selectFranchise(currentFranchiseId);
                if (currentProjectId) {
                    this.selectProject(currentProjectId);
                    this.ownerForm.patchValue({ project_id: currentProjectId });
                }
            }
        } else {
            if (this.selectedOwner === OwnerType.Franchise) {
                this.franchiseOptions = buildPDropdownOptions(this.franchises.filter(f => !f.is_series), { nullOption: true, nullLabel: `${franchiseName} *` });
                switchFranchiseType = currentFranchise && currentFranchise.is_series ? true : false;
            } else {
                this.franchiseOptions = buildPDropdownOptions(this.franchises.filter(f => f.is_series), { nullOption: true, nullLabel: 'Series *' });
                switchFranchiseType = currentFranchise && !currentFranchise.is_series ? true : false;
            }
            if (switchFranchiseType) {
                this.clearFranchise();
            } else {
                this.selectFranchise(currentFranchiseId);
            }
        }
    }

    clearFranchise() {
        this.store.dispatch(new bulkMoveModalActions.SelectFranchiseAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectProjectAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectLocationAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectSubLocationAction(null));
        this.ownerForm.patchValue({ franchise_id: null, project_id: null, location_id: null, sub_location_id: null });
    }

    selectDivision(divId: number) {
        const division = this.data.divisions.find(div => div.id === divId);
        const warehouseName = getStudioFieldName(this.standardFields.warehouse, this.allFields.map(a => a.field));
        this.warehouseOptions = division && division.warehouses ? buildPDropdownOptions(division.warehouses, { nullOption: true, nullLabel: `${warehouseName} *` }) : [];
        this.store.dispatch(new bulkMoveModalActions.SelectDivisionAction(division.id));
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseLocationAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseSubLocationAction(null));
        this.ownerForm.patchValue({ warehouse_id: null, warehouse_location_id: null, warehouse_sub_location_id: null, shelf: null, storage_box_id: null });
    }

    selectFranchise(franchiseId: number) {
        this.franchise = this.franchises.find(f => f.id === franchiseId);
        this.store.dispatch(new bulkMoveModalActions.SelectFranchiseAction(franchiseId));
        this.store.dispatch(new bulkMoveModalActions.SelectLocationAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectSubLocationAction(null));
        let projectId = null;
        if (this.franchise && this.franchise.projects && this.franchise.projects.length > 0) {
            projectId = this.franchise.projects[0].id;
        }
        this.ownerForm.patchValue({ project_id: projectId });
        this.selectProject(projectId);
        this.ownerForm.patchValue({ location_id: null, sub_location_id: null });
    }

    selectProject(projectId: number) {
        this.store.dispatch(new bulkMoveModalActions.SelectProjectAction(projectId));
    }

    selectLocation(locationId: number) {
        this.store.dispatch(new bulkMoveModalActions.SelectLocationAction(locationId));
        this.store.dispatch(new bulkMoveModalActions.SelectSubLocationAction(null));
        this.ownerForm.patchValue({ sub_location_id: null });
    }

    selectWarehouseLocation(warehouseLocationId: number) {
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseLocationAction(warehouseLocationId));
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseSubLocationAction(null));
        this.ownerForm.patchValue({ warehouse_sub_location_id: null });
    }

    selectWarehouse(warehouseId: number) {
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseAction(warehouseId));
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseLocationAction(null));
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseSubLocationAction(null));
        if (warehouseId) {
            this.store.dispatch(new storageBoxActions.ListByWarehouseAction(warehouseId));
        }
        this.ownerForm.patchValue({ warehouse_location_id: null, warehouse_sub_location_id: null, shelf: null, storage_box_id: null });
        this.warehouseLocationSubLocationShelfDisabled = false;
    }

    selectWarehouseSubLocation(warehouseSubLocationId: number) {
        this.store.dispatch(new bulkMoveModalActions.SelectWarehouseSubLocationAction(warehouseSubLocationId));
    }

    selectSubLocation(subLocationId: number) {
        this.store.dispatch(new bulkMoveModalActions.SelectSubLocationAction(subLocationId));
    }

    selectStorageBox(storageBoxId: number) {
        if (storageBoxId) {
            // Set Warehouse Location, Warehouse Sub Location and Shelf to storage box's and disable those inputs
            this.data.storageBoxes$.pipe(take(1)).subscribe(storageBoxes => {
                const storageBox = storageBoxes.find(sB => sB.id === storageBoxId);
                const warehouseLocationId = storageBox.warehouse_location_id;
                const warehouseSubLocationId = storageBox.warehouse_sub_location_id;
                this.store.dispatch(new bulkMoveModalActions.SelectWarehouseLocationAction(warehouseLocationId));
                this.store.dispatch(new bulkMoveModalActions.SelectWarehouseSubLocationAction(warehouseSubLocationId));
                this.ownerForm.patchValue({ warehouse_location_id: warehouseLocationId, warehouse_sub_location_id: warehouseSubLocationId, shelf: storageBox.shelf });
                this.warehouseLocationSubLocationShelfDisabled = true;
            });
        } else {
            this.warehouseLocationSubLocationShelfDisabled = false;
        }
    }

    save() {
        if (this.ownerForm.invalid) {
            return;
        }
        this.savingMessage = [{ severity: Severity.Info, summary: 'Saving...' }];
        const values = this.ownerForm.getRawValue();
        const newAssets: Asset[] = [];

        if (this.movingToDivision) {
            this.store.dispatch(new divisionIndexActions.InvalidateDivisionCacheAction({ divisionId: values.division_id, validCache: false }));
        }
        const fieldsToUpdate = this.allFields.map(field => field.field.canonical_name);
        this.assets.forEach(asset => {
            const newAsset = new Asset({ ...asset });
            fieldsToUpdate.forEach(field => {
                newAsset[field] = values.hasOwnProperty(field) ? values[field] : null;
            });
            newAssets.push(newAsset);
        });

        const updateMessage = (this.tableType === TableTypes.DivisionTable && this.selectedOwner !== OwnerType.Division) ? 'successfully updated. Please check the For Review tab to find the Asset(s) you moved.' : 'successfully updated.';
        const infoAction = new layoutActions.InfoAction({ message: `${this.assets.length} ${pluralize('asset', this.assets.length)} ${updateMessage}` });
        this.checkAndSaveAssets(newAssets, ASSET_MODAL_TYPE.EDIT, this.dialogRef, this.tableType, values, { infoAction });
    }

    private filterOwnerFields() {
        const franchiseView = this.selectedOwner !== OwnerType.Division;
        this.fields = this.allFields;
        this.fields = this.fields.filter(f => {
            if (franchiseView) {
                if (FRANCHISE_EXCLUDED_FIELDS.includes(f.field.canonical_name)) {
                    return false;
                }
            } else {
                if (DIVISION_EXCLUDED_FIELDS.includes(f.field.canonical_name)) {
                    return false;
                }
            }
            return true;
        });
        if (this.ownerForm) {
            this.buildForm();
        }
    }

    /** Since the bulk move modal doesn't require a common department, we need to specify what department fields are required */
    private setRequiredFields() {
        const divisionRequiredFields = [this.standardFields.division.canonical_name, this.standardFields.warehouse.canonical_name];
        const franchiseRequiredFields = [this.standardFields.franchise.canonical_name, this.standardFields.project.canonical_name];
        const isDivisionOwner = this.selectedOwner === OwnerType.Division;
        this.fields.forEach(f => {
            if (divisionRequiredFields.includes(f.field.canonical_name)) {
                f.departmentField.required = isDivisionOwner ? true : false;
            } else if (franchiseRequiredFields.includes(f.field.canonical_name)) {
                f.departmentField.required = isDivisionOwner ? false : true;
            }
        });
    }
}
