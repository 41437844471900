import { ActionStatus } from '../types';
import * as actions from './asset-view.actions';

export interface State {
    listStatus: ActionStatus;
    selectedFields: {
        [franchiseId: number]: string[];
    };
}

export const initialState: State = {
    listStatus: ActionStatus.Inactive,
    selectedFields: {}
};

export function reducer(state = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.LIST_SELECTED_TRANSACTION_TABLE_FIELDS: {
            return {
                ...state,
                listStatus: ActionStatus.Loading
            };
        }

        case actions.SAVE_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE:
        case actions.LIST_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE: {
            const franchiseId = action.payload.franchiseId;
            const fields = action.payload.fields;
            const alterWith = {
                [franchiseId]: fields
            };
            let listStatus = state.listStatus;
            if (action.type === actions.LIST_SELECTED_TRANSACTION_TABLE_FIELDS_COMPLETE) {
                listStatus = ActionStatus.Complete;
            }
            const newState = {
                ...state,
                listStatus,
                selectedFields: {
                    ...state.selectedFields,
                    ...alterWith
                }
            };
            return newState;
        }

        case actions.LIST_SELECTED_TRANSACTION_TABLE_FIELDS_FAILED: {
            return {
                ...state,
                listStatus: ActionStatus.Failed
            };
        }

        default: {
            return state;
        }
    }
}
