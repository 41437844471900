import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { strictEmailRegex } from '../../constants/constants';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';
import * as claimUserActions from '../../../core/store/claim-account/claim.actions'
import { Subscription } from 'rxjs';
import { redirectToURL } from '../../services/user.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'ah-claim-account',
  templateUrl: './claim-account.component.html',
  styleUrls: ['./claim-account.component.scss']
})
export class ClaimAccountComponent implements OnInit {

  emailRegex = strictEmailRegex;
  claimAccountForm: UntypedFormGroup;
  private subs: Subscription;

  constructor(private fb: UntypedFormBuilder, private store: Store<fromRoot.State>) { }

  confirmEmailValidator(claim_email: string, confirm_email: string) {
    return (formGroup: UntypedFormGroup) => {
      let control = formGroup.controls[claim_email];
      let matchingControl = formGroup.controls[confirm_email]
      if (matchingControl.errors && !matchingControl.errors.confirmEmailValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmEmailValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  ngOnInit(): void {
    this.subs = new Subscription();
    this.claimAccountForm = this.fb.group(
      {
        claim_email: ["", Validators.required],
        confirm_email: ["", Validators.required]
      },
      {
        validator: this.confirmEmailValidator("claim_email", "confirm_email")
      }
    );
  }

  onConfirm() {
    const emailId = this.claimAccountForm.value.claim_email;
    this.store.dispatch(new claimUserActions.ClaimUserAccount(emailId));

    const pathSub = this.store.select(fromRoot.getClaimUserDetailsPath).pipe(filter(u => u !== undefined), take(1)).subscribe((value: any) => {
      redirectToURL(value.path);
    });
    this.subs.add(pathSub)
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
