import { createSelector } from 'reselect';
import * as root from '../';
import * as bulkMoveModalSelectors from '../bulk-move-modal/bulk-move-modal.selectors';
import { Location } from '../models';

export function createLocationsForFranchise(locationIdsByFranchise: { [franchiseId: number]: number[] }, selectedFranchiseId: number, locationEntities: { [locId: number]: Location }) {
    if (locationIdsByFranchise[selectedFranchiseId]) {
        return locationIdsByFranchise[selectedFranchiseId].map(id => locationEntities[id]);
    } else {
        return [];
    }
}

export const getLocationsForSelectedFranchise = createSelector(root.getSelectedFranchiseId, root.getLocationIdsByFranchise, root.getLocationEntities, (selectedFranchiseId, locationIdsByFranchise, locationEntities) => {
    return createLocationsForFranchise(locationIdsByFranchise, selectedFranchiseId, locationEntities);
});

export const getWithSubLocations = createSelector(getLocationsForSelectedFranchise, root.getLocationSubLocationIds, root.getSubLocationEntities, (locations, locSubLocIds, subLocEntities) => {
    return locations.map(location => {
        return new Location({
            ...location,
            subLocations: locSubLocIds[location.id] ? locSubLocIds[location.id].map(id => subLocEntities[id]) : []
        });
    });
});

export const getForAssetModal = createSelector(root.getAssetModalSelectedLocationId, root.getLocationEntities, (id, locationMap) => locationMap[id]);

export const getForDispositionModal = createSelector(root.getAssetModalSelectedFranchiseId, root.getLocationIdsByFranchise, root.getLocationEntities, (selectedFranchiseId, locationIdsByFranchise, locationEntities) => {
    return createLocationsForFranchise(locationIdsByFranchise, selectedFranchiseId, locationEntities);
});

export const getForBulkMoveModal = createSelector(bulkMoveModalSelectors.getBulkMoveModalSelectedFranchiseId, root.getLocationIdsByFranchise, root.getLocationEntities, (selectedFranchiseId, locationIdsByFranchise, locationEntities) => {
    return createLocationsForFranchise(locationIdsByFranchise, selectedFranchiseId, locationEntities);
});

export const getSelectedWithSubLocations = createSelector(root.getSelectedLocation, root.getLocationSubLocationIds, root.getSubLocationEntities, (location, locSubLocIds, subLocEntities) => {
    if (location) {
        return new Location({
            ...location,
            subLocations: locSubLocIds[location.id] ? locSubLocIds[location.id].map(id => subLocEntities[id]) : []
        });
    } else {
        return null;
    }
});
