import { DEFAULT_FREE_FIELDS } from './../../../../../shared/fields/standard-fields';
import { FieldVisibility } from '../../../../../shared/enums/field-visibility.enum';
import { FAKE_FIELDS } from '../../../../../shared/fields/fake-fields';
import { FIELD_TYPES } from '../../../../../shared/fields/field-types';
import { DepartmentField } from '../departments/department-field.model';
import { Department } from '../departments/department.model';
import { BaseField } from './../base/base-field.model';

export const PURCHASE_FIELDS = ['vendor', 'cost', 'origin_currency', 'payment_method', 'payment_number', 'purchase_date', 'disposition_note', 'status_note'];
// always exclude from modal
export const DIVISION_EXCLUDED_FIELDS = ['photos', 'origin_currency', 'location_id', 'sub_location_id', 'disposition_id', 'disposition_note', 'sync_id', 'created_at', 'updated_at'];
export const FRANCHISE_EXCLUDED_FIELDS = ['photos', 'division_id', 'warehouse_id', 'warehouse_location_id', 'warehouse_sub_location_id', 'shelf', 'storage_box_id', 'status_id', 'status_note', 'sync_id', 'created_at', 'updated_at'];
// Fields for the bulk move modal
export const BULK_MOVE_FIELDS = ['division_id', 'franchise_id', 'project_id', 'location_id', 'sub_location_id', 'warehouse_id', 'warehouse_location_id', 'warehouse_sub_location_id', 'shelf', 'storage_box_id'];
export const BULK_UPDATE_FRANCHISE_INFO_FIELDS = ['franchise_id', 'project_id'];
// These fields are excluded from edit modals on the asset view
export const OWNER_FIELDS = [...BULK_MOVE_FIELDS, 'department_id', 'sub_department_id', 'character_id', 'set_id', 'disposition_id', 'status_id', 'group_ids'];
// These fields are used in transaction match assets
export const TRANSACTION_MATCH_ASSETS_FIELDS = ['name', 'description', 'color', 'group_ids', 'quantity', 'disposition_id', 'cost'];
export const STORAGE_BOX_FIELDS = ['warehouse_location_id', 'warehouse_sub_location_id', 'shelf'];
export const TRANSACTION_MATCH_REVIEW_FIELDS = ['photos', 'name', 'description', 'cost', 'brand', 'color', 'quantity', 'vendor', 'wrap_box', 'payment_method'];

export interface PicklistOption {
    id: number;
    value: string;
}

export interface FieldWithDepartmentField {
    departmentField: DepartmentField;
    field: Field;
}

export interface SplitFieldsObject {
    allFields: FieldWithDepartmentField[];
    ownerFields: FieldWithDepartmentField[];
    purchaseFields: FieldWithDepartmentField[];
    otherFields: FieldWithDepartmentField[];
}

export class Field extends BaseField {

    /** Needed for Global search */
    get field() {
        return 'displayFields.' + this.canonical_name;
    }
    id: number;
    studio_id: number = null;
    canonical_name = '';
    type = '';
    name: string = null;
    required_locked: boolean = null;
    delete_locked: boolean = null;
    sort_order: number = null;
    restricted: boolean = null;
    visibility: FieldVisibility = null;
    picklistOptions: PicklistOption[] = null;
    departmentFields: DepartmentField[] = null;
    deleted: boolean = false;

    constructor(fieldObj) {
        super();
        this.initializeData(fieldObj, this);
    }

    static splitByPurchase(fields: Field[], dept: Department, useDivisionFields: boolean, canAccessRestrictedinDivision: boolean, canAccessRestrictedinFranchise: boolean): SplitFieldsObject {
        let allFields, purchaseFields = [], ownerFields = [];
        const otherFields = [];
        const divisionField = fields.find(f => f.canonical_name === 'division_id');
        const franchiseField = fields.find(f => f.canonical_name === 'franchise_id');
        const ownerFieldNames = [];

        fields = this.filterFieldsForRestrictedAccess(fields, useDivisionFields, canAccessRestrictedinDivision, canAccessRestrictedinFranchise);

        allFields = dept.departmentFields.map((departmentField) => {
            const field = fields.find(f => f.id === departmentField.field_id);
            if (!field || field.canonical_name === 'qty_on_hand') {
                return null;
            }
            let purchaseFieldIndex, ownerFieldIndex;
            purchaseFieldIndex = PURCHASE_FIELDS.indexOf(field.canonical_name);
            ownerFieldIndex = OWNER_FIELDS.indexOf(field.canonical_name);

            const fieldWithDeptField = {
                departmentField,
                field
            };

            if (useDivisionFields) {
                this.changeRequiredForDivisionAsset(fieldWithDeptField);
            } else if (field.canonical_name === 'franchise_id') {
                fieldWithDeptField.departmentField = new DepartmentField({ ...fieldWithDeptField.departmentField });
                fieldWithDeptField.departmentField.required = true;
            }
            if (purchaseFieldIndex > -1) {
                purchaseFields[purchaseFieldIndex] = fieldWithDeptField;
            } else if (ownerFieldIndex > -1) {
                ownerFields[ownerFieldIndex] = fieldWithDeptField;
                ownerFieldNames.push(field.canonical_name);
            } else {
                otherFields.push(fieldWithDeptField);
            }
            return fieldWithDeptField;
        });
        otherFields.sort((a, b) => a.field.sort_order - b.field.sort_order);
        if (divisionField) {
            const divisionFieldIndex = ownerFieldNames.findIndex(f => f === 'division_id') > -1;
            if (!divisionFieldIndex) {
                const divisionDeptField = new DepartmentField({ department_id: dept.id, field_id: divisionField.id, required: true });
                const divFieldWithDeptField = { departmentField: divisionDeptField, field: divisionField };
                ownerFields.push(divFieldWithDeptField);
                allFields.push(divFieldWithDeptField);
            }
        }
        // add the owner field
        const ownerField = new Field(franchiseField);
        ownerField.canonical_name = 'owner';
        const ownerDeptField = new DepartmentField({ department_id: dept.id, field_id: ownerField.id, required: true });
        const ownerFieldWithDeptField = {
            departmentField: ownerDeptField,
            field: ownerField
        };
        ownerFields.push(ownerFieldWithDeptField);
        allFields.push(ownerFieldWithDeptField);

        allFields = allFields.filter(f => !!f);
        purchaseFields = purchaseFields.filter(f => !!f);
        ownerFields = ownerFields.filter(f => !!f);
        return { allFields, ownerFields, purchaseFields, otherFields };
    }

    static changeRequiredForDivisionAsset(fieldWithDeptField) {
        if (fieldWithDeptField.field.canonical_name === 'cost' || fieldWithDeptField.field.canonical_name === 'project_id' || fieldWithDeptField.field.canonical_name === 'franchise_id' || fieldWithDeptField?.field?.canonical_name === 'disposition_id') {
            fieldWithDeptField.departmentField = new DepartmentField({ ...fieldWithDeptField.departmentField });
            fieldWithDeptField.departmentField.required = false;
        } else if (fieldWithDeptField.field.canonical_name === 'warehouse_id') {
            fieldWithDeptField.departmentField = new DepartmentField({ ...fieldWithDeptField.departmentField });
            fieldWithDeptField.departmentField.required = true;
        }
    }

    private static filterFieldsForRestrictedAccess(fields: Field[], inDivision: boolean, canAccessRestrictedinDivision: boolean, canAccessRestrictedinFranchise: boolean) {
        if (inDivision && !canAccessRestrictedinDivision) {
            return fields.filter(field => !field.restricted && !DEFAULT_FREE_FIELDS.includes(field.canonical_name));
        } else if (!inDivision && !canAccessRestrictedinFranchise) {
            return fields.filter(field => !field.restricted && !DEFAULT_FREE_FIELDS.includes(field.canonical_name));
        } else {
            return fields.filter(field => !DEFAULT_FREE_FIELDS.includes(field.canonical_name));
        }
    }

    getColumnName() {
        return this.name.replace(/\s/g, '_').toLowerCase();
    }

    getValueFromAsset(asset) {
        if (this.type === FIELD_TYPES.DATE.type) {
            if (asset[this.canonical_name]) {
                return new Date(asset[this.canonical_name]);
            } else if (asset.attributes && asset.attributes[this.canonical_name]) {
                return new Date(asset.attributes[this.canonical_name]);
            }
            return null;
        } else if (this.type === FIELD_TYPES.CURRENCY.type) {
            // For divisions, assets won't have origin currency, so will just return aset.cost
            if (this.canonical_name === 'cost' && asset.allCosts && asset.origin_currency && (asset.allCosts[asset.origin_currency] || asset.allCosts[asset.origin_currency] === 0)) {
                return asset.allCosts[asset.origin_currency];
            } else if (asset[this.canonical_name] || asset[this.canonical_name] === 0) {
                return asset[this.canonical_name];
            } else if (asset.attributes && (asset.attributes[this.canonical_name] || asset.attributes[this.canonical_name] === 0)) {
                return asset.attributes[this.canonical_name];
            }
            return null;
        } else if (this.type === FIELD_TYPES.CHECKBOX.type) {
            // never let a checkbox be null, that's not a thing a checkbox should be
            if (asset[this.canonical_name]) {
                return !!asset[this.canonical_name];
            } else if (asset.attributes && asset.attributes[this.canonical_name]) {
                return !!asset.attributes[this.canonical_name];
            } else {
                return false;
            }
        }
        if (asset[this.canonical_name]) {
            return asset[this.canonical_name];
        }
        if (asset.attributes && asset.attributes[this.canonical_name]) {
            return asset.attributes[this.canonical_name];
        }
        return null;
    }

    isDivisionOnly() {
        return this.visibility === FieldVisibility.Division;
    }

    isFranchiseOnly() {
        return this.visibility === FieldVisibility.Franchise;
    }

    isTransactionOnly() {
        return this.visibility === FieldVisibility.Transaction;
    }

    isVisibilityAll() {
        return this.visibility === FieldVisibility.All;
    }
}

export const unmatchableFields = ['project_id', 'photos', 'sync_id', 'created_at', 'updated_at', 'created_by_user', 'assetEvents', 'free_field_1', 'free_field_2', 'free_field_3'];

export class FakeField extends Field {
    mapTo: string[];

    constructor(fakeFieldObj) {
        super(fakeFieldObj);
        this.mapTo = fakeFieldObj.mapTo;
    }
}

export const fakeFields = FAKE_FIELDS.map(ff => new FakeField(ff));
