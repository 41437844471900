import { createSelector } from 'reselect';
import { getIdsAndEntities } from '../utils';
import { ActionStatus } from '../types';
import * as excludedDispositionActions from './excluded-disposition.actions';
import { ExcludedDisposition } from './excluded-disposition.model';

export interface State {
    ids: number[];
    entities: {
        [dispositionId: number]: ExcludedDisposition;
    };
    saving: ActionStatus;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Inactive
};

export function reducer(state = initialState, action: excludedDispositionActions.Actions): State {
    switch (action.type) {
        case excludedDispositionActions.LIST_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }
        case excludedDispositionActions.ADD_COMPLETE: {
            const disposition = action.payload;
            let sortIds = [...state.ids];

            let dispositionObj = {};
            //add object key to state keys
            dispositionObj[disposition.id] = disposition;
            sortIds.push(disposition.id);

            const alterStateWith = {
                ids: sortIds,
                entities: {
                    ...state.entities,
                    ...dispositionObj
                }
            }
            return {
                ...state,
                ...alterStateWith,
                saving: ActionStatus.Complete
            };
        }
        case excludedDispositionActions.UPDATE_COMPLETE: {
            const disposition = action.payload;
            let sortIds = [...state.ids];

            let dispositionObj = {};
            //add object key to state keys
            dispositionObj[disposition.id] = disposition;

            const alterStateWith = {
                ids: sortIds,
                entities: {
                    ...state.entities,
                    ...dispositionObj
                }
            }
            return {
                ...state,
                ...alterStateWith,
                saving: ActionStatus.Complete
            };
        }
        case excludedDispositionActions.DELETE_COMPLETE: {
            const id = action.payload;

            let sortIds = [...state.ids];

            const entitiesCopy = { ...state.entities };
            sortIds = sortIds.filter(sId => sId != id);
            delete entitiesCopy[id];

            return {
                ...state,
                entities: entitiesCopy,
                ids: sortIds,
            };
        }
        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;

export const getExcludedDispositions = createSelector(getEntities, getIds, (ents, ids) => {
    return ids.map(id => ents[id]);
});
export const getSaving = (state: State) => state.saving;
