import { createSelector } from 'reselect';
import * as root from '../';

const getAssetImportIdsForFranchise = createSelector(root.getAssetImportsIdsForFranchises, root.getSelectedFranchise, (assetImportIds, franchise) => {
    return (franchise && assetImportIds[franchise.id]) ? assetImportIds[franchise.id] : [];
});

const getAssetImportIdsForDivision = createSelector(root.getAssetImportsIdsForDivisions, root.getSelectedDivision, (assetImportIds, division) => {
    return (division && assetImportIds[division.id]) ? assetImportIds[division.id] : [];
});

export const getAssetImportsForSelectedFranchise = createSelector(root.getAssetImportsEntities, getAssetImportIdsForFranchise, (assetImports, assetImportIdsForFranchise) => {
    return assetImportIdsForFranchise.map(id => assetImports[id]);
});

export const getAssetImportsForSelectedDivision = createSelector(root.getAssetImportsEntities, getAssetImportIdsForDivision, (assetImports, assetImportIdsForDivision) => {
    return assetImportIdsForDivision.map(id => assetImports[id]);
});