import { createSelector } from 'reselect';
import * as root from '../';

export const disposition = createSelector(root.getSelectedAsset, root.getDispositions, (asset, dispositions) => {
    return dispositions.find(d => d.id === asset.disposition_id);
});

export const status = createSelector(root.getSelectedAsset, root.getStatuses, (asset, statuses) => {
    return statuses.find(s => s.id === asset.status_id);
});

export const franchise = createSelector(root.getSelectedAsset, root.getFranchiseMap, (asset, franchiseMap) => {
    return franchiseMap[asset.franchise_id];
});

export const division = createSelector(root.getSelectedAsset, root.getDivisionEntities, (asset, divisionMap) => {
    return divisionMap[asset.division_id];
});

export const project = createSelector(root.getSelectedAsset, root.getProjectEntities, (asset, projectMap) => {
    return projectMap[asset.project_id];
});

export const warehouse = createSelector(root.getSelectedAsset, root.getWarehouseEntities, (asset, warehouseMap) => {
    return warehouseMap[asset.warehouse_id];
});

export const department = createSelector(root.getSelectedAsset, root.getAllDepartments, (asset, departments) => departments.find(d => d.id === asset.department_id));

export const history = createSelector(root.getSelectedAsset, root.getAssetHistory, (asset, assetHistory) => {
    return assetHistory.filter(ah => ah.asset_id === asset.id);
});

export const location = createSelector(root.getSelectedAsset, root.getLocations, (asset, locations) => {
    return locations.find(l => l.id === asset.location_id);
});

export const attachments = createSelector(root.getAttachmentsEntities, root.getAttachmentIdsByAsset, root.getSelectedAsset, (attEnts, attIdsByAsset, asset) => {
    return asset && attIdsByAsset[asset.id] ? attIdsByAsset[asset.id].map(id => attEnts[id]) : [];
});

export const groups = createSelector(root.getSelectedAsset, root.getGroupEntities, (asset, groupEnts) => {
    return asset && asset.group_ids ? asset.group_ids.map(groupId => groupEnts[groupId]) : [];
});

