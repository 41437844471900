import { DepartmentField } from '../departments/department-field.model';
import * as fieldActions from '../fields/field.actions';

export interface State {
    entities: {
        [departmentFieldId: number]: DepartmentField
    };
}

export const initialState: State = {
    entities: {}
};

export function reducer(state = initialState, action: fieldActions.Actions): State {
    switch (action.type) {
        case fieldActions.CREATE_COMPLETE:
        case fieldActions.UPDATE_COMPLETE:
        case fieldActions.LIST_COMPLETE: {
            let fields;
            if (action.type === fieldActions.CREATE_COMPLETE || action.type === fieldActions.UPDATE_COMPLETE) {
                fields = [action.payload.field];
            } else {
                fields = action.payload.fields;
            }
            const deptFieldIdMap = {};

            fields.forEach(f => {
                if (f.departmentFields) {
                    f.departmentFields.forEach(df => {
                        deptFieldIdMap[df.id] = df;
                    });
                }
            });

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...deptFieldIdMap
                }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
