import { createSelector } from 'reselect';
import * as root from '../';
import { Division } from '../models';

export const getDivisionsWithWarehouses = createSelector(root.getAllDivisions, root.getDivisionWarehousesIds, root.getWarehouseEntities, (divisions, divWarehouseIds, warehouseEnts) => {
    return divisions.map((division => {
        return new Division({
            ...division,
            warehouses: divWarehouseIds[division.id] ? divWarehouseIds[division.id].map(id => warehouseEnts[id]) : []
        });
    }));
});

/** Returns Divisions that the current user can move Assets to */
export const getOwnedDivisionsWithWarehouses = createSelector(root.getMoveToDivisions, root.getDivisionWarehousesIds, root.getWarehouseEntities, (divisions, divWarehouseIds, warehouseEnts) => {
    return divisions.map((division => {
        return new Division({
            ...division,
            warehouses: divWarehouseIds[division.id] ? divWarehouseIds[division.id].map(id => warehouseEnts[id]) : []
        });
    }));
});

export const getSelectedDivisionWithWarehouses = createSelector(root.getSelectedDivision, root.getDivisionWarehousesIds, root.getWarehouseEntities, (division, divWarehouseIds, warehouseEnts) => {
    return new Division({
        ...division,
        warehouses: division && divWarehouseIds[division.id] ? divWarehouseIds[division.id].map(id => warehouseEnts[id]) : []
    });
});
