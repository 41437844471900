import { createSelector } from 'reselect';
import * as root from '../';
import { FAKE_COLUMNS, FAKE_EXCLUDED_COLUMNS } from '../../../../../shared/fields/fake-fields';
import * as departmentFieldSelectors from '../department-fields/department-field.selectors';
import * as userSelectors from '../users/user.selectors';
import { DUPLICATE_MODAL_FIELDS, STANDARD_FIELDS } from './../../../../../shared/fields/standard-fields';
import { Field, STORAGE_BOX_FIELDS } from './field.model';
import { getWithDeptFieldFunction } from './field.selectors.utils';

/**
 * Used: Studio Asset Index & Request View Selectors
 * Gets sorted array of all fields (without FAKE_COLUMNS)
 * Returns an empty array if there are no fields
 */
export const getAllWithoutFakeColumns = createSelector(root.getFields, fields => {
    if (!fields.length) {
        return [];
    } else {
        return fields.sort((a, b) => a.name.localeCompare(b.name));
    }
});

/**
 * Used: Division Asset Index & Event View Selectors
 * Returns fields user has access to in the division
 */
export const getAllWithoutFakeColumnsAndDivisionRestricted = createSelector(getAllWithoutFakeColumns, userSelectors.canAccessRestrictedFieldsInCurrentDivision, (fields, canAccessRestricted) => {
    if (canAccessRestricted) {
        return fields;
    } else {
        return fields.filter(field => !field.restricted);
    }
});

export const getAllWithFakeExcludedColumns = createSelector(root.getFields, fields => {
    if (!fields.length) {
        return [];
    } else {
        return fields.concat(FAKE_EXCLUDED_COLUMNS.map(f => new Field(f))).sort((a, b) => a.name.localeCompare(b.name));
    }
});

/**
 * Used: Transaction ADT for fields
 * Returns fields user has access to in the franchise
 */
export const getAllWithFakeExcludedColumnsAndFranchiseRestricted = createSelector(getAllWithFakeExcludedColumns, userSelectors.canAccessRestrictedFieldsInCurrentFranchise, (fields, canAccessRestricted) => {
    if (canAccessRestricted) {
        return fields;
    } else {
        return fields.filter(field => !field.restricted);
    }
});

/**
 * Used: Franchise Asset Index, Transaction Index, Match Assets, Match Review, Asset View, Asset Modal, Bulk Move Modal & Request View Selectors
 * Gets a sorted array of all fields and adds in FAKE_COLUMNS.
 * Returns an empty array if there are no fields
 */
export const getAllWithFakeColumns = createSelector(root.getFields, fields => {
    if (!fields.length) {
        return [];
    } else {
        return fields.concat(FAKE_COLUMNS.map(f => new Field(f))).sort((a, b) => a.name.localeCompare(b.name));
    }
});

export const getAllWithFakeColumnsAndDeleted = createSelector(root.getAllFields, fields => {
    if (!fields.length) {
        return [];
    } else {
        return fields.concat(FAKE_COLUMNS.map(f => new Field(f))).sort((a, b) => a.name.localeCompare(b.name));
    }
});

export const getAllDivWithFakeColumns = createSelector(root.getFields, fields => {
    if (!fields.length) {
        return [];
    } else {
        return fields.sort((a, b) => a.name.localeCompare(b.name));
    }
});

/**
 * Used: Division level ADT for fields
 * Returns fields user has access to in the division
 */
export const getAllWithFakeColumnsAndDivisionRestricted = createSelector(getAllDivWithFakeColumns, userSelectors.canAccessRestrictedFieldsInCurrentDivision, (fields, canAccessRestricted) => {
    if (canAccessRestricted) {
        return fields;
    } else {
        return fields.filter(field => !field.restricted);
    }
});

/**
 * Used: Franchise Level ADT for fields
 * Returns fields user has access to in the franchise
 */
export const getAllWithFakeColumnsAndFranchiseRestricted = createSelector(getAllWithFakeColumns, userSelectors.canAccessRestrictedFieldsInCurrentFranchise, (fields, canAccessRestricted) => {
    if (canAccessRestricted) {
        return fields;
    } else {
        return fields.filter(field => !field.restricted);
    }
});

/**
 * Used: ADT for field entities
 * Gets all field entities and fake columns and fake excluded columns
 */
export const getFieldEntitiesWithAllFakeColumns = createSelector(root.getFieldEntities, (fieldEnts) => {
    if (Object.keys(fieldEnts).length) {
        return [...FAKE_COLUMNS, ...FAKE_EXCLUDED_COLUMNS].reduce((fieldsResult, fakeCol) => {
            return { ...fieldsResult, [fakeCol.id]: new Field(fakeCol) };
        }, fieldEnts);
    } else {
        return {};
    }
});

export const getDuplicateModalFields = createSelector(root.getFields, root.getSelectedAsset, root.getDepartmentEntities, (fields, asset, deptEntities) => {
    if (asset && deptEntities && deptEntities[asset.department_id]) {
        const duplicateModalFields = fields.filter(field => DUPLICATE_MODAL_FIELDS.has(field.canonical_name));
        return getWithDeptFieldFunction(deptEntities[asset.department_id], duplicateModalFields);
    }
    return [];
});

export const getWarehouseLocationField = createSelector(
    root.getFields,
    fields => fields.find(f => f.canonical_name === STANDARD_FIELDS.warehouseLocation.canonical_name)
);

export const getWarehouseSubLocationField = createSelector(
    root.getFields,
    fields => fields.find(f => f.canonical_name === STANDARD_FIELDS.warehouseSubLocation.canonical_name)
);

export const getShelfField = createSelector(
    root.getFields,
    fields => fields.find(f => f.canonical_name === STANDARD_FIELDS.shelf.canonical_name)
);

export const getStorageBoxFields = createSelector(
    root.getFields,
    fields => fields.filter(f => STORAGE_BOX_FIELDS.includes(f.canonical_name))
);

export const getStorageBoxField = createSelector(
    root.getFields,
    fields => fields.find(f => f.canonical_name === STANDARD_FIELDS.storageBox.canonical_name)
);

export const getStorageBoxModalFields = createSelector(
    root.getFields,
    fields => fields.filter(f => STORAGE_BOX_FIELDS.includes(f.canonical_name) || [STANDARD_FIELDS.name.canonical_name, STANDARD_FIELDS.description.canonical_name].includes(f.canonical_name))
);

export const getDispositionField = createSelector(root.getFields, fields=>fields.find(field=>field.canonical_name===STANDARD_FIELDS.disposition.canonical_name))
/**
 * This selector will give us all the data for the Fields Table
 */
export const getFieldsWithDepartmentFields = createSelector(root.getFields, departmentFieldSelectors.getDepartmentFieldEntities, root.getDeptFieldIdsForField, (fields, deptFieldsEntities, deptFieldIds) => {
    return fields.map(field => {
        const deptFieldIdsForField = deptFieldIds[field.id];
        const departmentFields = deptFieldIdsForField.map(deptFieldId => deptFieldsEntities[deptFieldId]);
        return new Field({ ...field, departmentFields });
    });
});

/**
 * Helper function for ADT Containers to get selected fields if any or the default
 * @param fields all available fields for that view
 * @param selectedIds selected ids
 * @param defaultFields default array of canonical names
 */
export function getSelectedOrDefaultFields(fields: Field[], selectedIds: number[], defaultFields: string[]) {
    const selectedFields: Field[] = [];
    if (selectedIds.length) {
        selectedIds.forEach(id => {
            let foundField = fields.find(f => f.id === id);
            if (foundField) {
                if (foundField.canonical_name === 'franchise_id') {
                    foundField = new Field({ ...foundField, name: 'Series/Feature' })
                }
                selectedFields.push(foundField);
            }
        });
    } else {
        defaultFields.forEach(canonicalName => {
            let foundField = fields.find(f => f.canonical_name === canonicalName);
            if (foundField) {
                if (foundField.canonical_name === 'franchise_id') {
                    foundField = new Field({ ...foundField, name: 'Series/Feature' })
                }
                selectedFields.push(foundField);
            }
        });
    }
    return selectedFields;
}
