import { Action } from '@ngrx/store';

export const SEARCH = '[Settings Page] Search';

export class SearchAction implements Action {
    readonly type = SEARCH;

    constructor(public payload: string) { }
}

export type Actions
    = SearchAction;
