import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as projectSetActions from './project-set.actions';
import { ProjectSet } from './project-set.model';

@Injectable()
export class ProjectSetEffects {
    static BASE_URL = '/api/project-sets';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectSetActions.ADD),
            map((action: projectSetActions.AddAction) => action.payload),
            mergeMap((data) => this.http.post(ProjectSetEffects.BASE_URL, data).pipe(
                map(res => new ProjectSet(res)),
                map((character: ProjectSet) => new projectSetActions.AddCompleteAction(character)),
                catchError((error) => observableOf(new projectSetActions.AddFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectSetActions.UPDATE),
            map((action: projectSetActions.UpdateAction) => action.payload),
            mergeMap((data) => this.http.put(`${ProjectSetEffects.BASE_URL}/${data.id}`, data).pipe(
                map(res => new ProjectSet(res)),
                map((character: ProjectSet) => new projectSetActions.UpdateCompleteAction(character)),
                catchError((error) => observableOf(new projectSetActions.UpdateFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectSetActions.DELETE),
            map((action: projectSetActions.DeleteAction) => action.payload),
            mergeMap((data) => this.http.delete(`${ProjectSetEffects.BASE_URL}/${data.id}`).pipe(
                map(() => new projectSetActions.DeleteCompleteAction(data.id)),
                catchError((error) => observableOf(new projectSetActions.DeleteFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(projectSetActions.ADD_FAILED, projectSetActions.UPDATE_FAILED, projectSetActions.DELETE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
