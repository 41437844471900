import { createSelector } from 'reselect';
import { ActionState, ActionStatus } from '../types';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as warehouseSubLocationActions from '../warehouse-sub-locations/warehouse-sub-location.actions';
import * as warehouseLocationActions from './warehouse-location.actions';
import { WarehouseLocation } from './warehouse-location.model';

export interface State {
    ids: number[];
    entities: {
        [warehouseLocationId: number]: WarehouseLocation
    };
    saving: ActionStatus;
    selectedId: number;
    warehouseSubLocationIds: {
        [warehouseLocationId: number]: number[]
    };
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Complete,
    selectedId: null,
    warehouseSubLocationIds: {},
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: warehouseLocationActions.Actions | warehouseSubLocationActions.Actions): State {
    switch (action.type) {
        case warehouseLocationActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case warehouseLocationActions.ADD_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, [action.payload]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case warehouseLocationActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case warehouseLocationActions.DELETE_COMPLETE: {
            const warehouseLocationId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            const warehouseSubLocationIdsCopy = { ...state.warehouseSubLocationIds };
            delete entitiesCopy[warehouseLocationId];
            delete warehouseSubLocationIdsCopy[warehouseLocationId];

            let selectedId = state.selectedId;
            if (selectedId === warehouseLocationId) {
                selectedId = null;
            }
            return {
                ...state,
                ids: state.ids.filter(id => id !== warehouseLocationId),
                entities: entitiesCopy,
                selectedId,
                warehouseSubLocationIds: warehouseSubLocationIdsCopy,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case warehouseLocationActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case warehouseLocationActions.LIST_COMPLETE:
        case warehouseLocationActions.LIST_BY_STUDIO_COMPLETE: {
            const warehouseLocations = action.payload;
            const { ids, entities } = getIdsAndEntities(state, warehouseLocations);

            const warehouseSubLocationIds = {};
            warehouseLocations.forEach(wareLoc => {
                if (wareLoc.warehouseSubLocations) {
                    const wsls = {};
                    const wslIds = wareLoc.warehouseSubLocations.map(wareSubLoc => {
                        wsls[wareSubLoc.id] = wareSubLoc;
                        return wareSubLoc.id;
                    });
                    warehouseSubLocationIds[wareLoc.id] = copyAndMultiSort(wslIds, wsls, [{ key: 'name' }], 1);
                }
            });
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                warehouseSubLocationIds: {
                    ...state.warehouseSubLocationIds,
                    ...warehouseSubLocationIds
                }
            };
        }

        case warehouseLocationActions.ADD:
        case warehouseLocationActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case warehouseLocationActions.ADD_FAILED:
        case warehouseLocationActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case warehouseLocationActions.UPDATE_COMPLETE: {
            const warehouseLocation = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [warehouseLocation]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case warehouseLocationActions.SELECT: {
            return {
                ...state,
                selectedId: action.payload
            };
        }

        case warehouseSubLocationActions.ADD_COMPLETE: {
            const warehouseSubLocation = action.payload;

            const ids = state.warehouseSubLocationIds[warehouseSubLocation.warehouse_location_id] ? state.warehouseSubLocationIds[warehouseSubLocation.warehouse_location_id] : [];
            if (ids.indexOf(warehouseSubLocation.id) > -1) {
                return state;
            }

            return {
                ...state,
                warehouseSubLocationIds: {
                    ...state.warehouseSubLocationIds,
                    [warehouseSubLocation.warehouse_location_id]: [...ids, warehouseSubLocation.id]
                }
            };
        }

        case warehouseSubLocationActions.DELETE_COMPLETE: {
            const warehouseSubLocation = action.payload;

            const ids = [...state.warehouseSubLocationIds[warehouseSubLocation.warehouse_location_id]];
            ids.splice(ids.indexOf(action.payload.id), 1);

            return {
                ...state,
                warehouseSubLocationIds: {
                    ...state.warehouseSubLocationIds,
                    [warehouseSubLocation.warehouse_location_id]: ids
                }
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getWarehouseLocationSubLocationIds = (state: State) => state.warehouseSubLocationIds;
const getSelectedId = (state: State) => state.selectedId;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getSaving = (state: State) => state.saving;
export const getSelected = createSelector(getEntities, getSelectedId, (entities, id) => entities[id]);
export const getDeleteState = (state: State) => state.deleteState;
