import { createSelector } from 'reselect';
import { ActionStatus, SortObject } from '../types';
import { getIdsAndEntities, sortStrings } from '../utils';
import * as customerActions from './customer.actions';
import { Customer } from './customer.model';

export interface State {
    ids: number[];
    entities: { [customerId: number]: Customer };
    loading: ActionStatus;
    saving: ActionStatus;
    sort: SortObject;
}

export const initialState: State = {
    ids: [],
    entities: {},
    loading: ActionStatus.Inactive,
    saving: ActionStatus.Inactive,
    sort: null
};

export function reducer(state = initialState, action: customerActions.Actions): State {
    switch (action.type) {
        case customerActions.ADD:
        case customerActions.ADD_TO_EVENT:
        case customerActions.DELETE:
        case customerActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case customerActions.ADD_FAILED:
        case customerActions.ADD_TO_EVENT_FAILED:
        case customerActions.DELETE_FAILED:
        case customerActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case customerActions.LIST_BY_DIVISION: {
            return { ...state, ids: [], entities: {}, loading: ActionStatus.Loading };
        }

        case customerActions.LIST_BY_DIVISION_FAILED: {
            return { ...state, loading: ActionStatus.Failed };
        }

        case customerActions.ADD_TO_EVENT_COMPLETE:
        case customerActions.ADD_COMPLETE: {
            let customer: Customer;
            if (action.type === customerActions.ADD_TO_EVENT_COMPLETE) {
                customer = action.payload.customer;
            } else {
                customer = action.payload;
            }
            const ids: number[] = [];

            if (!state.entities[customer.id]) {
                ids.push(customer.id);
            }

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, [customer.id]: customer },
                saving: ActionStatus.Complete
            };
        }

        case customerActions.DELETE_COMPLETE: {
            const customerId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[customerId];

            return {
                ...state,
                ids: state.ids.filter(id => id !== customerId),
                entities: entitiesCopy,
                saving: ActionStatus.Complete
            };
        }

        case customerActions.LIST_BY_DIVISION_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities,
                },
                loading: ActionStatus.Complete
            };
        }

        case customerActions.UPDATE_COMPLETE: {
            const customer = action.payload;
            const entitiesUpdate = { [customer.id]: customer };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entitiesUpdate
                },
                saving: ActionStatus.Complete
            };
        }

        case customerActions.SORT: {
            const sortData = { field: action.payload.field, order: action.payload.order, args: (action.payload.args ? action.payload.args : []) };

            if (state.sort
                && state.sort.args === sortData.args
                && state.sort.field === sortData.field
                && state.sort.order === sortData.order) {
                // Cancel sorting
                return state;
            }

            return {
                ...state,
                sort: sortData
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]).sort((a, b) => sortStrings(a.customer_name, b.customer_name)));
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getSort = (state: State) => state.sort;
