import { ActionStatus } from '../types';
import { getEntitiesObject } from '../utils';
import * as ledgerActions from './ledger.actions';
import { Ledger } from './ledger.model';
export interface State {
    id: number;
    showMatchingPage: boolean;
    franchise_id: number;
    project_id: number;
    fields: string[]; // fields from ledger with ID of id
    valueExamples: { [ledgerHeader: string]: string };
    unmatchedDepartments: string[];
    numUnmatched: number;
    unmatchedCurrencies: string[];
    hasMultipleCurrencies: boolean;
    createComplete: boolean;
    createError: boolean;
    deleteComplete: boolean;
    ledgerUploaded: boolean;
    type: number;
    entities: {
        [ledgerId: number]: Ledger
    };
    ledgerIdsForFranchise: {
        [franchiseId: number]: number[];
    };
    checkForPOStatus: ActionStatus;
    hasPurchaseOrder: boolean;
    sourceCodeColNameWarning: boolean;
}

export const initialState: State = {
    id: null,
    showMatchingPage: false,
    franchise_id: null,
    project_id: null,
    fields: [],
    valueExamples: {},
    unmatchedDepartments: [],
    numUnmatched: null,
    unmatchedCurrencies: [],
    hasMultipleCurrencies: null,
    createComplete: false,
    createError: false,
    deleteComplete: false,
    ledgerUploaded: false,
    type: null,
    entities: {},
    ledgerIdsForFranchise: {},
    checkForPOStatus: ActionStatus.Inactive,
    hasPurchaseOrder: null,
    sourceCodeColNameWarning: null
};

export function reducer(state = initialState, action: ledgerActions.Actions): State {
    switch (action.type) {
        case ledgerActions.ADD_FIELDS: {
            return {
                ...state,
                id: action.payload.id,
                franchise_id: action.payload.franchise_id,
                hasMultipleCurrencies: action.payload.hasMultipleCurrencies,
                project_id: action.payload.project_id,
                fields: action.payload.fields,
                valueExamples: action.payload.valueExamples,
                numUnmatched: action.payload.numUnmatched,
                unmatchedDepartments: action.payload.unmatchedDepartments,
                unmatchedCurrencies: action.payload.unmatchedCurrencies,
                createComplete: false,
                ledgerUploaded: true
            };
        }

        case ledgerActions.CREATE_TRANSACTIONS: {
            return {
                ...state,
                createComplete: false,
                createError: false
            };
        }

        case ledgerActions.CREATE_TRANSACTIONS_COMPLETE: {
            return {
                ...state,
                createComplete: true,
                createError: false,
                ledgerUploaded: false
            };
        }

        case ledgerActions.CREATE_TRANSACTIONS_FAILED: {
            return {
                ...state,
                createError: true,
                createComplete: true
            };
        }

        case ledgerActions.SET_TYPE: {
            const type = action.payload;
            return {
                ...state,
                type
            };
        }

        case ledgerActions.GET_FOR_TRANSACTION_COMPLETE: {
            const ledger = action.payload;
            const ledgerObj = getEntitiesObject([ledger]);
            const franchiseIdObj = buildLedgerIdsForFranchise([ledger]);

            const alterStateWith = {
                entities: { ...state.entities, ...ledgerObj },
                ledgerIdsForFranchise: { ...state.ledgerIdsForFranchise, ...franchiseIdObj }
            };

            return { ...state, ...alterStateWith };
        }

        case ledgerActions.LIST_BY_FRANCHISE_COMPLETE: {
            const ledgers = action.payload;
            const ledgerObj = getEntitiesObject(ledgers);
            const franchiseIdObj = buildLedgerIdsForFranchise(ledgers);

            const alterStateWith = {
                entities: { ...state.entities, ...ledgerObj },
                ledgerIdsForFranchise: { ...state.ledgerIdsForFranchise, ...franchiseIdObj }
            };

            return { ...state, ...alterStateWith };
        }

        case ledgerActions.DELETE: {
            return { ...state, deleteComplete: false };
        }

        case ledgerActions.DELETE_FAILED: {
            return { ...state, deleteComplete: true };
        }

        case ledgerActions.DELETE_COMPLETE: {
            const ledger = action.payload;
            const franchiseId = ledger.franchise_id;
            const entitiesCopy = { ...state.entities };
            const franchiseIdObj = { ...state.ledgerIdsForFranchise };

            delete entitiesCopy[ledger.id];
            franchiseIdObj[franchiseId] = franchiseIdObj[franchiseId].filter(id => id !== ledger.id);

            const alterStateWith = {
                ...state,
                deleteComplete: true,
                entities: entitiesCopy,
                ledgerIdsForFranchise: {
                    ...state.ledgerIdsForFranchise,
                    ...franchiseIdObj
                },
            };
            return { ...state, ...alterStateWith };
        }

        case ledgerActions.UPDATE_UNMATCHED_DEPTS: {
            const departments = action.payload;
            const departmentIds = departments.map(dept => dept.account_code);
            const newUnmatchedDepts = state.unmatchedDepartments.filter(dept => !departmentIds.includes(dept));

            return {
                ...state,
                unmatchedDepartments: newUnmatchedDepts
            };
        }

        case ledgerActions.SET_SHOW_MATCHING_PAGE: {
            return { ...state, showMatchingPage: action.payload };
        }

        case ledgerActions.CHECK_LEDGER_FOR_PURCHASE_ORDER: {
            return {
                ...state,
                checkForPOStatus: ActionStatus.Loading
            };
        }

        case ledgerActions.CHECK_LEDGER_FOR_PURCHASE_ORDER_COMPLETE: {
            return {
                ...state,
                checkForPOStatus: ActionStatus.Complete,
                hasPurchaseOrder: action.payload.hasPurchaseOrder,
                sourceCodeColNameWarning: action.payload.fallbackWarning
            };
        }

        case ledgerActions.CHECK_LEDGER_FOR_PURCHASE_ORDER_FAILED: {
            return {
                ...state,
                checkForPOStatus: ActionStatus.Failed
            };
        }

        case ledgerActions.RESET_LEDGER_IMPORT: {
            return {
                ...state,
                checkForPOStatus: initialState.checkForPOStatus,
                hasPurchaseOrder: initialState.hasPurchaseOrder,
                sourceCodeColNameWarning: initialState.sourceCodeColNameWarning
            };
        }

        default: {
            return state;
        }
    }

    function buildLedgerIdsForFranchise(newLedgers: Ledger[]) {
        const franchiseIdObj: { [franchiseId: number]: number[] } = {};
        if (newLedgers.length) {
            const franchiseId = newLedgers[0].franchise_id;
            if (state.ledgerIdsForFranchise[franchiseId]) {
                franchiseIdObj[franchiseId] = [...state.ledgerIdsForFranchise[franchiseId]];
            } else {
                franchiseIdObj[franchiseId] = [];
            }
            newLedgers.forEach((l) => {
                if (!franchiseIdObj[franchiseId].includes(l.id)) {
                    franchiseIdObj[franchiseId].push(l.id);
                }
            });
        }

        return franchiseIdObj;
    }
}

export const getComplete = (state: State) => state.createComplete;
export const getDeleteComplete = (state: State) => state.deleteComplete;
export const getFields = (state: State) => state.fields;
export const getValueExamples = (state: State) => state.valueExamples;
export const getUnmatchedDepartments = (state: State) => state.unmatchedDepartments;
export const getNumUnmatched = (state: State) => state.numUnmatched;
export const getUnmatchedCurrencies = (state: State) => state.unmatchedCurrencies;
export const getType = (state: State) => state.type;
export const getUploaded = (state: State) => state.id !== null;
export const getCreateError = (state: State) => state.createError;
export const getCheckForPOStatus = (state: State) => state.checkForPOStatus;
export const getHasPurchaseOrder = (state: State) => state.hasPurchaseOrder;
export const getSourceCodeColNameWarning = (state: State) => state.sourceCodeColNameWarning;

export const getEntities = (state: State) => state.entities;
export const getLedgerIdsForFranchise = (state: State) => state.ledgerIdsForFranchise;
export const getShowMatchingPage = (state: State) => state.showMatchingPage;
export const getHasMultipleCurrencies = (state: State) => state.hasMultipleCurrencies;
