import { ConfirmModalComponent } from './../components/confirm-modal/confirm-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as pluralize from 'pluralize';
import { filter, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import { AssetEvent, Field } from '../../core/store/models';
import { ActionStatus, Severity, TableTypes } from '../../core/store/types';
import { ASSET_MODAL_TYPE } from './../../assets/asset-modal/asset-modal.component';
import * as assetActions from './../../core/store/assets/asset.actions';
import { Asset } from './../../core/store/assets/asset.model';
import * as eventActions from './../../core/store/events/event.actions';
import * as layoutActions from './../../core/store/layout/layout.actions';
import * as assetEventActions from '../../core/store/asset-events/asset-event.actions';

export interface SaveAssetsOptions {
    infoAction?: layoutActions.InfoAction;
}

@Injectable()
export class AssetDataService {
    constructor(private store: Store<fromRoot.State>, private dialog: MatDialog) { }

    getAsset() {
        return this.store.select(fromRoot.getSelectedAsset).pipe(filter(selectedAsset => !!selectedAsset), take(1));
    }

    ceateAssetsFromTransactions(transactionIdList: number[], properties: any, dialogRef: MatDialogRef<any>, options?: SaveAssetsOptions, keepDialogOpen?: boolean, dialog?: MatDialog) {
        if (!keepDialogOpen) {
            dialogRef.componentInstance.saveDisabled = true;
        }

        if (!this.dialog) {
            this.dialog = dialog;
        }

        this.store.dispatch(new assetActions.AddFromTransactionAction({ transaction_id_list: transactionIdList, properties }));
        this.store.select(fromRoot.getAssetSaving).pipe(
            filter(loading => loading !== ActionStatus.Loading),
            take(1))
            .subscribe((loading) => {
                if (loading === ActionStatus.Complete) {
                    if (!keepDialogOpen) {
                        dialogRef.close(true);
                    }
                    if (options && options.infoAction) {
                        this.store.dispatch(options.infoAction);
                    } else {
                        if (dialogRef.componentInstance) {
                            dialogRef.componentInstance.saveDisabled = false;
                        }
                    }
                }
            });

        this.store.select(fromRoot.getErrorSavingAsset)
            .subscribe((error: any) => {
                if (error && typeof (error) == "string") {
                    if (!keepDialogOpen) {
                        dialogRef.close(true);
                    }
                    return this.store.dispatch(new layoutActions.ErrorAction({
                        error: new HttpErrorResponse({ error: new Error(error) })
                    }));
                }
            })
    }

    checkAndSaveAssets(newAssets: Asset[], action: string, dialogRef: MatDialogRef<any>, tableType: TableTypes, mutations: any, options?: SaveAssetsOptions, keepDialogOpen?: boolean, dialog?: MatDialog) {
        let warningDisplayed = false;

        if (!keepDialogOpen) {
            dialogRef.componentInstance.saveDisabled = true;
        }

        if (!this.dialog) {
            this.dialog = dialog;
        }

        let assetIdList = newAssets.map(a => a.id);

        if (action === ASSET_MODAL_TYPE.CREATE || action === ASSET_MODAL_TYPE.CREATE_FROM_TRANSACTION) {
            this.store.dispatch(new assetActions.AddAction({ assets: newAssets, tableType }));
        } else if (action === ASSET_MODAL_TYPE.EDIT || action === ASSET_MODAL_TYPE.EDIT_INDEX) {
            if (!mutations) {
                this.store.dispatch(new assetActions.UpdateAction({ assets: newAssets, forceRecalculate: true }))
            } else if (assetIdList.length > 0) {
                this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList, studioId: newAssets[0].studio_id, assetMutations: mutations }));
            }
        }

        this.store.select(fromRoot.getAssetSaving).pipe(
            filter(loading => loading !== ActionStatus.Loading),
            take(1))
            .subscribe((loading) => {
                if (loading === ActionStatus.Complete) {
                    if (!keepDialogOpen) {
                        dialogRef.close(true);
                    }
                    if (options && options.infoAction) {
                        this.store.dispatch(options.infoAction);
                    } else {
                        if (dialogRef.componentInstance) {
                            dialogRef.componentInstance.saveDisabled = false;
                        }
                    }
                }
            });


        this.store.select(fromRoot.getErrorMovingAsset)
            .subscribe((error: any) => {
                if (error && Object.entries(error).length && !warningDisplayed) {
                    warningDisplayed = true;
                    const messageBulleted = [
                        'Click Cancel so you can add the currency to the target Project and retain the Asset(s) Origin Currency, then try moving them again.',
                        'Click Proceed to move these Assets now, and the Origin Currency will be converted to USD in the target Project.'
                    ]
                    this.dialog.open(ConfirmModalComponent, {
                        data: {
                            message: `One or more of your Assets has an Origin Currency that does not exist in the target Project.`,
                            messageBulleted,
                            cancelButton: 'Cancel',
                            confirmButton: 'Proceed'
                        }
                    }).afterClosed().subscribe(res => {
                        if (res && res.hard_deleted) {
                            if (action === ASSET_MODAL_TYPE.EDIT || action === ASSET_MODAL_TYPE.EDIT_INDEX) {
                                this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList, studioId: newAssets[0].studio_id, assetMutations: mutations, forceRecalculate: true }));
                                dialogRef.close(true);
                            }
                        } else {
                            dialogRef.close(false);
                        }
                    })
                }
            })
    }

    bulkSaveAssets(assets: Asset[], assetMutations: any, dialogRef: MatDialogRef<any>) {
        if (assets.length > 0) {
            dialogRef.componentInstance.saveDisabled = true;

            const assetIdList = assets.map(a => a.id);
            const studioId = assets[0].studio_id;
            this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList, studioId, assetMutations }));
            this.store.select(fromRoot.getAssetSaving).pipe(
                filter(saving => saving !== ActionStatus.Loading),
                take(1))
                .subscribe(function(saving) {
                    if (saving === ActionStatus.Complete) {
                        dialogRef.close(true);
                    } else if (saving === ActionStatus.Failed) {
                        if (dialogRef.componentInstance) {
                            dialogRef.componentInstance.saveDisabled = false;
                            dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Save Failed' }];
                        } else {
                            this.store.dispatch(new layoutActions.ErrorAction({
                                error: new HttpErrorResponse({ error: new Error('Save Failed') })
                            }));
                        }
                    }
                });
        }
    }

    bulkUpdateAssets(assets: Asset[], dialogRef: MatDialogRef<any>, fieldValue: any = null, overwrite: boolean = false) {
        dialogRef.componentInstance.saveDisabled = true;
        const fieldName = Object.keys(fieldValue)[0];
        const studioId = assets[0].studio_id;
        const assetIds = assets.map(a => a.id);
        this.store.dispatch(new assetActions.UpdateFieldMultipleAction({ studioId: studioId, assetIds, field: fieldName, value: fieldValue[fieldName], overwrite }));
        this.store.select(fromRoot.getAssetSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                } else if (saving === ActionStatus.Failed) {
                    if (dialogRef.componentInstance) {
                        dialogRef.componentInstance.saveDisabled = false;
                        dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Save Failed' }];
                    } else {
                        this.store.dispatch(new layoutActions.ErrorAction({
                            error: new HttpErrorResponse({ error: new Error('Save Failed') })
                        }));
                    }
                }
            });
    }

    bulkAddAssetsToEvent(assets: Asset[], eventId: number, dialogRef: MatDialogRef<any>, options?: SaveAssetsOptions) {
        dialogRef.componentInstance.saveDisabled = true;
        const assetIds: number[] = assets.map((a) => a.id);
        const store = this.store;
        this.store.dispatch(new eventActions.AddAssetsAction({ eventId, assetIds }));
        this.store.select(fromRoot.getEventSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                    if (options && options.infoAction) {
                        store.dispatch(options.infoAction);
                    }
                } else {
                    if (dialogRef.componentInstance) {
                        dialogRef.componentInstance.saveDisabled = false;
                        dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Save Failed' }];
                    } else {
                        this.store.dispatch(new layoutActions.ErrorAction({
                            error: new HttpErrorResponse({ error: new Error('Save Failed') })
                        }));
                    }
                }
            });
    }

    transferAssetsToEvent(assetEvents: AssetEvent[], sourceEventId: number, targetEventId: number, dialogRef: MatDialogRef<any>) {
        dialogRef.componentInstance.saveDisabled = true;
        const newAssetEvents: AssetEvent[] = [];
        assetEvents.forEach(assetEvent => {
            const newAssetEvent = new AssetEvent(assetEvent);
            newAssetEvent.event_id = targetEventId;
            newAssetEvents.push(newAssetEvent);
        });
        this.store.dispatch(new assetEventActions.TransferAssetsAction({ event_id: sourceEventId, assetEvents: newAssetEvents }));
        this.store.select(fromRoot.getAssetEventSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                } else if (saving === ActionStatus.Failed) {
                    if (dialogRef.componentInstance) {
                        dialogRef.componentInstance.saveDisabled = false;
                        dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Transfer Failed' }];
                    } else {
                        this.store.dispatch(new layoutActions.ErrorAction({
                            error: new HttpErrorResponse({ error: new Error('Transfer Failed') })
                        }));
                    }
                }
            });
    }

    bulkAddGroupsToAssets(assets: Asset[], groupIds: number[], subgroupIds: number[], dialogRef: MatDialogRef<any>) {
        dialogRef.componentInstance.saveDisabled = true;
        const assetIds: number[] = assets.map((a) => a.id);
        const store = this.store;

        if (assets.length > 0) {
            const studioId = assets[0].studio_id;

            let assetMutations: any = {};
            if (groupIds.length > 0) {
                assetMutations.group_ids = groupIds;
            }
            if (subgroupIds.length > 0) {
                assetMutations.sub_group_ids = subgroupIds;
            }
            this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList: assetIds, studioId, assetMutations }));
        }

        this.store.select(fromRoot.getAssetSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                    let message = '';
                    if (groupIds.length && subgroupIds.length) {
                        message = `Added ${groupIds.length} ${pluralize('Group', groupIds.length)} & ${subgroupIds.length} ${pluralize('Subgroup', subgroupIds.length)} to ${assetIds.length} ${pluralize('Asset', assetIds.length)}`
                    } else if (groupIds.length) {
                        message = `Added ${groupIds.length} ${pluralize('Group', groupIds.length)} to ${assetIds.length} ${pluralize('Asset', assetIds.length)}`
                    } else {
                        message = `Added ${subgroupIds.length} ${pluralize('Subgroup', subgroupIds.length)} to ${assetIds.length} ${pluralize('Asset', assetIds.length)}`
                    }
                    const infoAction = new layoutActions.InfoAction({ message });
                    store.dispatch(infoAction);
                } else {
                    if (dialogRef.componentInstance) {
                        dialogRef.componentInstance.saveDisabled = false;
                        dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Save Failed' }];
                    } else {
                        this.store.dispatch(new layoutActions.ErrorAction({
                            error: new HttpErrorResponse({ error: new Error('Save Failed') })
                        }));
                    }
                }
            });
    }

    bulkRemoveAssetsFromGroup(assets: Asset[], groupId: number, dialogRef: MatDialogRef<any>) {
        dialogRef.componentInstance.saveDisabled = true;
        const assetIds: number[] = assets.map((a) => a.id);
        const store = this.store;

        if (assets.length > 0) {
            const studioId = assets[0].studio_id;
            const assetMutations = { group_id: groupId, action: 'DeleteGroup' };

            this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList: assetIds, studioId, assetMutations }));
        }
        this.store.select(fromRoot.getAssetSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                    const infoAction = new layoutActions.InfoAction({ message: `${assetIds.length} ${pluralize('assets', assetIds.length)} ${assetIds.length == 1 ? "is" : "are"} removed from this Group` });
                    store.dispatch(infoAction);
                } else {
                    if (dialogRef.componentInstance) {
                        dialogRef.componentInstance.saveDisabled = false;
                        dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Removing Failed' }];
                    } else {
                        this.store.dispatch(new layoutActions.ErrorAction({
                            error: new HttpErrorResponse({ error: new Error('Removing Failed') })
                        }));
                    }
                }
            });
    }

    bulkRemoveAssetsFromSubGroup(assets: Asset[], subGroupId: number, dialogRef: MatDialogRef<any>) {
        dialogRef.componentInstance.saveDisabled = true;
        const assetIds: number[] = assets.map((a) => a.id);
        const store = this.store;

        if (assets.length > 0) {
            const studioId = assets[0].studio_id;
            const assetMutations = { sub_group_id: subGroupId, action: 'DeleteSubGroup' };

            this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList: assetIds, studioId, assetMutations }));
        }

        this.store.select(fromRoot.getAssetSaving).pipe(
            filter(saving => saving !== ActionStatus.Loading),
            take(1))
            .subscribe(function(saving) {
                if (saving === ActionStatus.Complete) {
                    dialogRef.close(true);
                    const infoAction = new layoutActions.InfoAction({ message: `${assetIds.length} ${pluralize('assets', assetIds.length)} ${assetIds.length == 1 ? "is" : "are"} removed from this Group` });
                    store.dispatch(infoAction);
                } else {
                    if (dialogRef.componentInstance) {
                        dialogRef.componentInstance.saveDisabled = false;
                        dialogRef.componentInstance.savingMessage = [{ severity: Severity.Error, summary: 'Removing Failed' }];
                    } else {
                        this.store.dispatch(new layoutActions.ErrorAction({
                            error: new HttpErrorResponse({ error: new Error('Removing Failed') })
                        }));
                    }
                }
            });
    }

    bulkAddGroupOrSubGroupAssetsToEvent(groupId: number, subGroupId: number, eventId: number, dialogRef: MatDialogRef<any>) {
        dialogRef.componentInstance.saveDisabled = true;
        if (groupId) {
            this.store.dispatch(new assetEventActions.AddGroupAssetsToEvent({ eventId, groupId }));
        } else if (subGroupId) {
            this.store.dispatch(new assetEventActions.AddSubGroupAssetsToEvent({ eventId, subGroupId }));
        }
        dialogRef.close(true);
    }
    getSortField(field: Field) {
        if (field) {
            return field.canonical_name;
        } else {
            return null;
        }
    }
}
