import { BaseModel } from './../base/base.model';

export class Disposition extends BaseModel {
    id: number;
    studio_id: number = null;
    name: string = null;
    color: string = null;
    code: number = null;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(obj) {
        super();
        this.initializeData(obj, this);
    }
}
