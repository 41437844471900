import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as warehouseActions from './warehouse.actions';
import { Warehouse } from './warehouse.model';

@Injectable()
export class WarehouseEffects {
     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseActions.ADD),
            map((action: warehouseActions.AddAction) => {
                const warehouse: Warehouse = action.payload.warehouse;
                return {
                    name: warehouse.name,
                    division_id: action.payload.divisionId
                }
            }),
            switchMap((warehouseData) => this.http.post('/api/warehouses', warehouseData).pipe(
                map((res) => new Warehouse(res)),
                map((newWarehouse: Warehouse) => new warehouseActions.AddCompleteAction({ warhouse: newWarehouse, divisionId: warehouseData.division_id })),
                catchError(error => observableOf(new warehouseActions.AddFailedAction({ error, divisionId: warehouseData.division_id })))
            ))
        ));

     addComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseActions.ADD_COMPLETE),
            map((action: warehouseActions.AddCompleteAction) => action.payload),
            map((payload) => new layoutActions.InfoAction({ message: `Warehouse "${payload.warhouse.name}" created successfully.` }))
        ))


     addFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseActions.ADD_FAILED),
            map((action: warehouseActions.AddFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))
        ));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseActions.UPDATE),
            map((action: warehouseActions.UpdateAction) => action.payload),
            switchMap((warehouseData) => this.http.put(`/api/warehouses/${warehouseData.warehouse.id}`, warehouseData.warehouse).pipe(
                map(res => {
                    const response = res;
                    return new Warehouse(response);
                }),
                map((w: Warehouse) => new warehouseActions.UpdateCompleteAction(w)),
                catchError((error) => observableOf(new warehouseActions.UpdateFailedAction({ error, divisionId: warehouseData.divisionId }))))
            )));

     updateFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(warehouseActions.UPDATE_FAILED),
            map((action: warehouseActions.UpdateFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
