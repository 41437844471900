import { Action } from '@ngrx/store';
import { AssetEvent } from './asset-event.model';

export const LIST_QUANTITY_ON_HAND = '[Asset Event] List Quantity On Hand';
export const LIST_QUANTITY_ON_HAND_COMPLETE = '[Asset Event] List Quantity On Hand Complete';
export const LIST_QUANTITY_ON_HAND_FAILED = '[Asset Event] List Quantity On Hand Failed';

export const UPDATE_MULTIPLE = '[Asset Event] Update Multiple';
export const UPDATE_MULTIPLE_COMPLETE = '[Asset Event] Update Multiple Complete';
export const UPDATE_MULTIPLE_FAILED = '[Asset Event] Update Multiple Failed';

export const TRANSFER_ASSETS = '[Event] Transfer Assets';
export const TRANSFER_ASSETS_COMPLETE = '[Event] Transfer Assets Complete';
export const TRANSFER_ASSETS_FAILED = '[Event] Transfer Assets Failed';

export const ADD_GROUP_ASSETS_TO_EVENT = '[Group] Add Group Assets to Event';
export const ADD_GROUP_ASSETS_TO_EVENT_COMPLETE = '[Group] Add Group Assets to Event Complete';
export const ADD_GROUP_ASSETS_TO_EVENT_FAILED = '[Group] Add Group Assets to Event Failed';

export const ADD_SUBGROUP_ASSETS_TO_EVENT = '[Sub Group] Add Sub Group Assets to Event';
export const ADD_SUBGROUP_ASSETS_TO_EVENT_COMPLETE = '[Sub Group] Add Sub Group Assets to Event Complete';
export const ADD_SUBGROUP_ASSETS_TO_EVENT_FAILED = '[Sub Group] Add Sub Group Assets to Event Failed';


export class UpdateMultipleAction implements Action {
    readonly type = UPDATE_MULTIPLE;

    constructor(public payload: { event_id: number, assetEvents: AssetEvent[] }) { }
}

export class UpdateMultipleCompleteAction implements Action {
    readonly type = UPDATE_MULTIPLE_COMPLETE;

    constructor(public payload: AssetEvent[]) { }
}

export class UpdateMultipleFailedAction implements Action {
    readonly type = UPDATE_MULTIPLE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class TransferAssetsAction implements Action {
    readonly type = TRANSFER_ASSETS;

    constructor(public payload: { event_id: number, assetEvents: AssetEvent[] }) { }
}

export class TransferAssetsCompleteAction implements Action {
    readonly type = TRANSFER_ASSETS_COMPLETE;

    constructor(public payload: { assets: AssetEvent[] }) { }
}

export class TransferAssetsFailedAction implements Action {
    readonly type = TRANSFER_ASSETS_FAILED;

    constructor(public payload: { error: any }) { }
}
export class ListQuantityOnHandAction implements Action {
    readonly type = LIST_QUANTITY_ON_HAND;

    constructor(public payload: { division_id: number, asset_ids: number[] }) { }
}

export class ListQuantityOnHandCompleteAction implements Action {
    readonly type = LIST_QUANTITY_ON_HAND_COMPLETE;

    constructor(public payload: { quantityOnHandForAssetIds: { [assetId: number]: number } }) { }
}

export class ListQuantityOnHandFailedAction implements Action {
    readonly type = LIST_QUANTITY_ON_HAND_FAILED;

    constructor(public payload: any) { }
}

export class AddGroupAssetsToEvent implements Action {
    readonly type = ADD_GROUP_ASSETS_TO_EVENT;

    constructor(public payload: { groupId: number, eventId: number }) { }
}

export class AddGroupAssetsToEventComplete implements Action {
    readonly type = ADD_GROUP_ASSETS_TO_EVENT_COMPLETE;

    constructor(public payload: { groupId: number, count: number, existingAssetsCount: number, ids: number[], event: string }) { }
}

export class AddGroupAssetsToEventFailed implements Action {
    readonly type = ADD_GROUP_ASSETS_TO_EVENT_FAILED;

    constructor(public payload: any) { }
}

export class AddSubGroupAssetsToEvent implements Action {
    readonly type = ADD_SUBGROUP_ASSETS_TO_EVENT;

    constructor(public payload: { subGroupId: number, eventId: number }) { }
}

export class AddSubGroupAssetsToEventComplete implements Action {
    readonly type = ADD_SUBGROUP_ASSETS_TO_EVENT_COMPLETE;

    constructor(public payload: { subGroupId: number, count: number, existingAssetsCount: number, ids: number[], event: string }) { }
}

export class AddSubGroupAssetsToEventFailed implements Action {
    readonly type = ADD_SUBGROUP_ASSETS_TO_EVENT_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = ListQuantityOnHandAction
    | ListQuantityOnHandCompleteAction
    | ListQuantityOnHandFailedAction
    | UpdateMultipleAction
    | UpdateMultipleCompleteAction
    | UpdateMultipleFailedAction
    | TransferAssetsAction
    | TransferAssetsCompleteAction
    | TransferAssetsFailedAction
    | AddGroupAssetsToEvent
    | AddGroupAssetsToEventComplete
    | AddGroupAssetsToEventFailed
    | AddSubGroupAssetsToEvent
    | AddSubGroupAssetsToEventComplete
    | AddSubGroupAssetsToEventFailed;
