import { Action } from '@ngrx/store';
import { SortObject } from '../../../../../shared/types';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { TableFilterObject } from '../types';

export const SORT_STUDIO_LIST = '[Studio Asset Index] Sort Asset List';

export const FILTER_STUDIO_LIST = '[Studio Asset Index] Filter Asset List';

export const LIST_TABLE_STATE = '[Studio Asset Index] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Studio Asset Index] List Table State Complete';

export class SortStudioAssetsAction implements Action {
    readonly type = SORT_STUDIO_LIST;

    constructor(public payload: { studioId: number, sort: SortObject }) { }
}

export class FilterStudioAssetsAction implements Action {
    readonly type = FILTER_STUDIO_LIST;

    constructor(public payload: { studioId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { studioId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { studioId: number, tableState: TableState }) { }
}

export type Actions
    = SortStudioAssetsAction
    | FilterStudioAssetsAction
    | ListTableStateAction
    | ListTableStateCompleteAction;
