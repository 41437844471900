import { createSelector } from 'reselect';
import { ActionState, ActionStatus } from '../types';
import { getIdsAndEntities, sortStrings } from '../utils';
import * as characterActions from './character.actions';
import { Character } from './character.model';

export interface State {
    ids: number[];
    entities: { [characterId: number]: Character };
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: characterActions.Actions): State {
    switch (action.type) {
        case characterActions.LIST_BY_FRANCHISE: {
            return { ...state, ids: [], entities: {} };
        }

        case characterActions.LIST_BY_PROJECT: {
            return { ...state, ids: [], entities: {} };
        }

        case characterActions.LIST_BY_FRANCHISE_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities,
                }
            };
        }

        case characterActions.ADD_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, [action.payload]);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities,
                }
            };
        }

        case characterActions.UPDATE_COMPLETE: {
            const character = action.payload;
            const entitiesUpdate = {};
            entitiesUpdate[character.id] = character;
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entitiesUpdate
                }
            };
        }

        case characterActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case characterActions.DELETE_COMPLETE: {
            const charId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[charId];

            return {
                ...state,
                ids: state.ids.filter(id => id !== charId),
                entities: entitiesCopy,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case characterActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]).sort((a, b) => sortStrings(a.name, b.name)));
export const getDeleteState = (state: State) => state.deleteState;
