import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PageType, SelectType } from '../../../layout/sidenav-filter/sidenav-filter.component';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import * as sidenavFilterActions from './sidenav-filter.actions';

@Injectable()
export class SidenavFilterEffects {
     updateProject$ = createEffect(() => this.actions$
        .pipe(
            ofType(sidenavFilterActions.UPDATE_PROJECT),
            map((action: sidenavFilterActions.UpdateProjectAction) => action.payload),
            switchMap((payload): Observable<Action> => {
                const actionObject = {
                    completeAction: sidenavFilterActions.UpdateProjectCompleteAction,
                    failAction: sidenavFilterActions.UpdateProjectFailedAction
                };
                return this.updateLocalStorage(`sidenav${payload.pageType}Projects`, payload.franchiseId, payload.projectId, actionObject, payload.pageType, payload.selectType);
            })));

     updateMultipleProject$ = createEffect(() => this.actions$
        .pipe(
            ofType(sidenavFilterActions.UPDATE_MULTIPLE_PROJECT),
            map((action: sidenavFilterActions.UpdateMultipleProjectAction) => action.payload),
            switchMap((payload): Observable<Action> => {
                const actionObject = {
                    completeAction: sidenavFilterActions.UpdateMultipleProjectCompleteAction,
                    failAction: sidenavFilterActions.UpdateMultipleProjectFailedAction
                };
                return this.updateMultipleLocalStorage(`sidenav${payload.pageType}Projects`, payload.franchiseId, payload.projectId, actionObject, payload.pageType, payload.selectAll, false);
            })));

     listProjects$ = createEffect(() => this.actions$
        .pipe(
            ofType(sidenavFilterActions.LIST_PROJECTS),
            map((action: sidenavFilterActions.ListProjectsAction) => action.payload),
            switchMap((payload): Observable<Action> => {
                const actionObject = {
                    completeAction: sidenavFilterActions.ListProjectsCompleteAction,
                    failAction: sidenavFilterActions.ListProjectsFailedAction
                };
                return this.listFromLocalStorage(`sidenav${payload.pageType}Projects`, actionObject, payload.pageType);
            })));

     updateWarehouse$ = createEffect(() => this.actions$
        .pipe(
            ofType(sidenavFilterActions.UPDATE_WAREHOUSE),
            map((action: sidenavFilterActions.UpdateWarehouseAction) => action.payload),
            switchMap((payload): Observable<Action> => {
                const actionObject = {
                    completeAction: sidenavFilterActions.UpdateWarehouseCompleteAction,
                    failAction: sidenavFilterActions.UpdateWarehouseFailedAction
                };
                return this.updateLocalStorage(`sidenav${payload.pageType}Warehouses`, payload.divisionId, payload.warehouseId, actionObject, payload.pageType, payload.selectType);
            })));

     updateMultipleWarehouse$ = createEffect(() => this.actions$
        .pipe(
            ofType(sidenavFilterActions.UPDATE_MULTIPLE_WAREHOUSE),
            map((action: sidenavFilterActions.UpdateMultipleWarehouseAction) => action.payload),
            switchMap((payload): Observable<Action> => {
                const actionObject = {
                    completeAction: sidenavFilterActions.UpdateMultipleWarehouseCompleteAction,
                    failAction: sidenavFilterActions.UpdateMultipleWarehouseFailedAction
                };
                return this.updateMultipleLocalStorage(`sidenav${payload.pageType}Warehouses`, payload.divisionId, payload.warehouseId, actionObject, payload.pageType, payload.selectAll, true);
            })));

     listWarehouses$ = createEffect(() => this.actions$
        .pipe(
            ofType(sidenavFilterActions.LIST_WAREHOUSES),
            map((action: sidenavFilterActions.ListWarehousesAction) => action.payload),
            switchMap((payload): Observable<Action> => {
                const actionObject = {
                    completeAction: sidenavFilterActions.ListWarehousesCompleteAction,
                    failAction: sidenavFilterActions.ListWarehousesFailedAction
                };
                return this.listFromLocalStorage(`sidenav${payload.pageType}Warehouses`, actionObject, payload.pageType);
            })));

    constructor(private actions$: Actions, private localStorageService: LocalStorageService) { }

    listFromLocalStorage(localStorageName: string, actionObject, pageType?: PageType) {
        try {
            const entriesObject = JSON.parse(localStorage.getItem(localStorageName));
            if (!entriesObject) {
                return observableOf(new actionObject.completeAction({
                    entriesObject: {},
                    pageType
                }));
            }
            return observableOf(new actionObject.completeAction({ entriesObject, pageType }));
        } catch (err) {
            return observableOf(new actionObject.failAction(err));
        }
    }

    updateLocalStorage(localStorageName: string, franchiseId: number, idOfEntity: number, actionObject, pageType?: PageType, selectType?: SelectType) {
        try {
            let entriesObject = JSON.parse(localStorage.getItem(localStorageName));
            entriesObject = entriesObject ? entriesObject : {};
            let franchiseEntries = entriesObject && entriesObject[franchiseId] ? entriesObject[franchiseId] : [];

            if (selectType === SelectType.Single) {
                franchiseEntries = [idOfEntity];
            } else if (franchiseEntries && franchiseEntries.includes(idOfEntity)) {
                franchiseEntries.splice(franchiseEntries.indexOf(idOfEntity), 1);
            } else {
                franchiseEntries.push(idOfEntity);
            }

            entriesObject[franchiseId] = franchiseEntries;
            this.localStorageService.updateSettings(localStorageName, JSON.stringify(entriesObject));
            return observableOf(new actionObject.completeAction({ entriesObject, pageType }));
        } catch (err) {
            console.error('There was a problem updating local storage', err);
            return observableOf(new actionObject.failAction(err));
        }
    }

    updateMultipleLocalStorage(localStorageName: string, franchiseId: number, idOfEntity: number[], actionObject, pageType?: PageType, selectAll?: boolean, isDivisionTable?: boolean) {
        try {
            let entriesObject = JSON.parse(localStorage.getItem(localStorageName));
            entriesObject = entriesObject ? entriesObject : {};
            let franchiseEntries = entriesObject && entriesObject[franchiseId] ? entriesObject[franchiseId] : [];
            if (!selectAll) {
                const min = Math.min(...idOfEntity);
                franchiseEntries = !isDivisionTable ? [min] : [];
            } else {
                franchiseEntries = idOfEntity;
            }

            entriesObject[franchiseId] = franchiseEntries;
            this.localStorageService.updateSettings(localStorageName, JSON.stringify(entriesObject));

            return observableOf(new actionObject.completeAction({ entriesObject, pageType }));
        } catch (err) {
            console.error('There was a problem updating local storage', err);
            return observableOf(new actionObject.failAction(err));
        }
    }
}
