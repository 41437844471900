import { HttpErrorResponse } from '@angular/common/http';
import { Character, Department, Disposition, Division, Field, FinalApproverRequest, Franchise, Group, Location, ProjectCurrency, Request, RequestNote, Set, Status, WarehouseLocation, GroupTypes } from './models';
export * from '../../../../shared/types/sort-object';

export enum ActionStatus {
    Loading,
    Complete,
    Failed,
    Inactive,
    Timeout
}

// History types
export enum ActivityType {
    Create = 1,
    Update,
    Delete,
    Merge,
    DbImport
}

export enum OwnerType {
    Division,
    Franchise,
    Series
}

export enum Severity {
    Info = 'info',
    Success = 'success',
    Warn = 'warn',
    Error = 'error'
}

export interface AssetSatelliteList {
    characters?: Character[];
    departments?: Department[];
    dispositions?: Disposition[];
    division?: Division;
    divisions?: Division[];
    fields?: Field[];
    franchise?: Franchise;
    franchises?: {
        onlyOwned: Franchise[];
        notOnlyOwned: Franchise[];
    };
    groups?: {
        franchiseGroups: Group[];
        divisionGroups: Group[];
        studioGroups: Group[];
    };
    groupTypes?: GroupTypes[];
    locations?: Location[];
    projectCurrencies?: ProjectCurrency[];
    sets?: Set[];
    statuses?: Status[];
    warehouseLocations?: WarehouseLocation[];
}

export enum EventTypes {
    All = 1,
    Current,
    Future,
    Past,
    Active,
    Closed,
    Ending7Days,
    Ending30Days
}

export enum EventDisplayTypes {
    All = 'All Events',
    Current = 'Current Events',
    Future = 'Future Events',
    Past = 'Past Events',
    Active = 'Active Events',
    Closed = 'Closed Events',
    Ending7Days = 'Events Ending in 7 Days',
    Ending30Days = 'Events Ending in 30 Days'
}

export interface HeaderField {
    canonical_name: string;
    name: string;
}

export interface RequestAndNotes {
    request: Request;
    requestNotes: RequestNote[];
}

export interface FinalApproverRequestAndRequestAndNotes {
    finalApproverRequest: FinalApproverRequest;
    request: Request;
    requestNotes: RequestNote[];
}

export interface TableFilterObject {
    [name: string]: {
        matchMode: string;
        value: any;
        selectedProjectCurrencyId?: number;
    };
}

export interface SavingMessage {
    severity: Severity;
    summary: string;
}

export interface SortableIds {
    ids: number[];
    sortKey: string;
    sortOrder: number;
    sortArgs: any[];
}

export enum TableTypes {
    FranchiseTable,
    TransactionTable,
    DivisionTable,
    EventTable,
    DivisionGroupTable,
    FranchiseGroupTable,
    RequestTable,
    TransactionMatchTable,
    StudioTable,
    StudioGroupTable,
    FranchiseSubGroupTable,
    DivisionSubGroupTable,
    StudioSubGroupTable
}

export interface ActionState {
    status: ActionStatus;
    error: HttpErrorResponse;
}

// export interface AssetMutation {
//     name: string;
//     value: any;
//     action?: string;
// }