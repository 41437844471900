import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import * as franchiseActions from '../franchises/franchise.actions';
import { SortObject, TableFilterObject } from '../types';
import * as subGroupViewActions from './subgroup-view.actions';

export interface State {
    columnFiltersForSubGroupId: {
        [subGroupId: number]: ColumnFilterOptions;
    };
    subgroupAssetSort: {
        [subGroupId: number]: SortObject;
    };
    subgroupAssetFilter: {
        [subGroupId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    columnFiltersForSubGroupId: {},
    subgroupAssetSort: {},
    subgroupAssetFilter: {}
};

export function reducer(state = initialState, action: subGroupViewActions.Actions | franchiseActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case subGroupViewActions.SORT_SUB_GROUP_ASSET_LIST: {
            const subGroupId = action.payload.subGroupId;
            const assetSort = action.payload.sort;
            const currentSort = state.subgroupAssetSort[subGroupId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[subGroupId] = { ...action.payload.sort };

            return {
                ...state,
                subgroupAssetSort: { ...state.subgroupAssetSort, ...sortObj }
            };
        }

        case subGroupViewActions.FILTER_SUB_GROUP_ASSET_LIST: {
            const subGroupId = action.payload.subGroupId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.subgroupAssetFilter, subGroupId);
                return {
                    ...state,
                    subgroupAssetFilter: {
                        ...state.subgroupAssetFilter,
                        [subGroupId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.subgroupAssetFilter[subGroupId],
                ...action.payload.filter
            };

            return {
                ...state,
                subgroupAssetFilter: {
                    ...state.subgroupAssetFilter,
                    [subGroupId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case subGroupViewActions.LIST_TABLE_STATE_COMPLETE: {
            const subGroupId = action.payload.subGroupId;
            const { filter, sort } = listTableState(action.payload.tableState, state.subgroupAssetFilter, state.subgroupAssetSort, subGroupId);

            return {
                ...state,
                subgroupAssetFilter: filter,
                subgroupAssetSort: sort
            };
        }

        case assetActions.LIST_BY_SUB_GROUP_FILTERS_COMPLETE: {
            const columnFiltersForSubGroupId = { ...state.columnFiltersForSubGroupId };
            columnFiltersForSubGroupId[action.payload.subGroupId] = action.payload.columnFilters;

            return {
                ...state,
                columnFiltersForSubGroupId
            };
        }

        default: {
            return state;
        }
    }
}
