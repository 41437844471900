import * as assetActions from '../assets/asset.actions';
import * as finalApproverRequestActions from '../final-approver-requests/final-approver-request.actions';
import * as requestActions from '../requests/request.actions';
import * as requestAssetActions from './request-asset.actions';
import { RequestAsset } from './request-asset.model';

export interface State {
    conflictingRequestsForRequestAssetId: { [requestAssetId: number]: number[] };
    requestAssetForAssetId: { [assetId: number]: RequestAsset };
    selectedId: number;
}

export const initialState: State = {
    conflictingRequestsForRequestAssetId: [],
    requestAssetForAssetId: {},
    selectedId: null
};

export function reducer(state = initialState, action: requestAssetActions.Actions | requestActions.Actions | assetActions.Actions | finalApproverRequestActions.Actions): State {
    switch (action.type) {
        case finalApproverRequestActions.CREATE_COMPLETE:
        case assetActions.LIST_BY_REQUEST_COMPLETE:
        case assetActions.LIST_BY_FINAL_APPROVER_REQUEST_COMPLETE:
        case requestAssetActions.UPDATE_COMPLETE: {
            const requestAssets = action.payload.requestAssets;
            const requestAssetForAssetId = { ...state.requestAssetForAssetId };
            requestAssets.forEach(requestAsset => {
                requestAssetForAssetId[requestAsset.asset_id] = requestAsset;
            });

            return {
                ...state,
                requestAssetForAssetId
            };
        }

        case requestActions.DELETE_COMPLETE: {
            const requestId = action.payload;
            const requestAssetForAssetId = { ...state.requestAssetForAssetId };
            for (const key in requestAssetForAssetId) {
                if (requestAssetForAssetId[key] && requestAssetForAssetId[key].request_id === requestId) {
                    delete requestAssetForAssetId[key];
                }
            }

            return {
                ...state,
                requestAssetForAssetId
            };
        }

        case requestAssetActions.DELETE_COMPLETE: {
            const requestId = action.payload.requestId;
            const assetIds = action.payload.assetIds;
            const requestAssetForAssetId = { ...state.requestAssetForAssetId };
            assetIds.forEach(assetId => {
                if (requestAssetForAssetId[assetId].request_id === requestId) {
                    delete requestAssetForAssetId[assetId];
                }
            });

            return {
                ...state,
                requestAssetForAssetId
            };
        }

        case requestAssetActions.LIST_CONFLICTS_COMPLETE: {
            const requestAssetId = action.payload.requestAssetId;
            const requests = action.payload.requests;
            const conflictingRequestsForRequestAssetId = { ...state.conflictingRequestsForRequestAssetId };
            conflictingRequestsForRequestAssetId[requestAssetId] = requests.map(r => r.id);

            return {
                ...state,
                conflictingRequestsForRequestAssetId
            };
        }

        case requestAssetActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }
            return newState;
        }

        default:
            return state;
    }
}

export const getConflictingRequestsForRequestAssetId = (state: State) => state.conflictingRequestsForRequestAssetId;
export const getRequestAssetForAssetId = (state: State) => state.requestAssetForAssetId;
export const getSelectedRequestAssetId = (state: State) => state.selectedId;
