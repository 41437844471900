import { createSelector } from 'reselect';
import * as sosFieldActions from './sos-field.actions';
import { SosField } from './sos-field.model';

export interface State {
    ids: number[];
    entities: {
        [sosFieldId: number]: SosField;
    };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: sosFieldActions.Actions): State {
    switch (action.type) {
        case sosFieldActions.LIST_COMPLETE: {
            const sosFields = action.payload;
            const {entities, ids} = sosFields.reduce((acc, f) => {
                if (!state.entities[f.id]) {
                    acc.ids.push(f.id);
                }
                acc.entities[f.id] = f;
                return acc;
            }, { ids: [], entities: {} });

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;

export const getSosFields = createSelector(getEntities, getIds, (ents, ids) => {
    return ids.map(id => ents[id]);
});
