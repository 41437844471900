import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Asset } from '../assets/asset.model';
import * as layoutActions from '../layout/layout.actions';
import * as storageBoxActions from './storage-box.actions';
import { StorageBox } from './storage-box.model';

@Injectable()
export class StorageBoxEffects {

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(storageBoxActions.ADD),
            map((action: storageBoxActions.AddAction) => action.payload.storageBox),
            switchMap((storageBoxData) => this.http.post(`/api/storage-boxes`, storageBoxData).pipe(
                map(res => new StorageBox(res)),
                map((newStorageBox: StorageBox) => new storageBoxActions.AddCompleteAction({ storageBox: newStorageBox })),
                catchError((error) => observableOf(new storageBoxActions.AddFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(storageBoxActions.DELETE),
            map((action: storageBoxActions.DeleteAction) => action.payload),
            switchMap((data: { storageBoxId: number }) => this.http.delete(`/api/storage-boxes/${data.storageBoxId}`).pipe(
                map(() => new storageBoxActions.DeleteCompleteAction({ storageBoxId: data.storageBoxId })),
                catchError((error) => observableOf(new storageBoxActions.DeleteFailedAction({ error }))))
            )));

     listByWarehouse$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(storageBoxActions.LIST_BY_WAREHOUSE),
            map((action: storageBoxActions.ListByWarehouseAction) => action.payload),
            switchMap((warehouseId) => this.http.get<StorageBox[]>(`api/warehouses/${warehouseId}/storage-boxes`).pipe(
                map(res => res.map((sB) => new StorageBox(sB))),
                map((storageBoxes: StorageBox[]) => new storageBoxActions.ListByWarehouseCompleteAction(storageBoxes)),
                catchError(error => observableOf(new storageBoxActions.ListByWarehouseFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(storageBoxActions.UPDATE),
            map((action: storageBoxActions.UpdateAction) => action.payload),
            switchMap((storageBoxData) => this.http.put(`/api/storage-boxes/${storageBoxData.storageBox.id}`, storageBoxData.storageBox).pipe(
                map((results: { storageBox: StorageBox, assets: Asset[] }) => new storageBoxActions.UpdateCompleteAction({ storageBox: new StorageBox(results.storageBox), assets: results.assets ? results.assets.map(a => new Asset(a)) : [] })),
                catchError((error) => observableOf(new storageBoxActions.UpdateFailedAction(error))))
            )));

     requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(storageBoxActions.ADD_FAILED, storageBoxActions.LIST_BY_WAREHOUSE_FAILED, storageBoxActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
