<div class="row my-settings">
    <div class="headerContainer">
        <div class="header">
           <img class="edit-icon clickable"  style="width: 330px;" src="../../../../assets/images/ep-assethub.png">
        </div>
    </div>
    <div class="well">
        <form class="create-form" [formGroup]="claimAccountForm">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row flex">
                            <div class="position" style="flex: 0 0 35% !important; order: 1;">
                                <h2 style="font-weight: 300;color: #727272;padding: 0;margin: 0 0 40px 0;font-size: 35px;">Claim your EP account</h2>
                                <p class="small">
                                    Logging in to AssetHub now requires an EP account.
                                </p>
                                <p class="small">
                                    <b>If you do not have an EP account</b>, enter the email you use to
                                    log in to AssetHub and click "Continue". You will set up an EP
                                    account by inputting your AssetHub email again in the next step.
                                </p>
                                <p class="small">
                                    <b>If you already have an EP account,</b>
                                    <a href="/api/auth/login"> click here</a> to log in to
                                    AssetHub using your existing EP credentials.
                                    We will automatically link your accounts if both
                                    accounts use the same email address.
                                </p>
                            </div>
                            <div class="position" style="flex: 0 0 33% !important; order: 2;">
                                <div class="form-group" >
                                    <input pattern="{{emailRegex}}" type="email" matInput formControlName="claim_email" placeholder="Email">
                                    <mat-error *ngIf="claimAccountForm.get('claim_email').errors && !claimAccountForm.get('claim_email').errors.required">
                                        Please verify that your email is entered correctly
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <input pattern="{{emailRegex}}" type="email" matInput formControlName="confirm_email" placeholder="Confirm Email">
                                    <div *ngIf="claimAccountForm.get('confirm_email').errors">
                                        <mat-error *ngIf="!claimAccountForm.get('confirm_email').errors.required || claimAccountForm.get('confirm_email').errors.confirmEmailValidator">
                                            Please verify that your email is entered correctly
                                        </mat-error>
                                    </div>
                                </div>
                                <p class="small">
                                    By clicking "Continue" you agree to AssetHub's<br/>
                                    <a
                                        href="https://s3.amazonaws.com/synconset/subscription.html"
                                        rel="external"
                                        target="_blank"
                                    >
                                        subscription license agreement
                                    </a>
                                    and
                                    <a
                                        href="https://www.ep.com/legal/privacy-notice/"
                                        rel="external"
                                        target="_blank"
                                    >
                                        privacy policy
                                    </a>
                                    .
                                </p>
                                <button mat-raised-button large primary [disabled]="claimAccountForm.invalid" (click)="onConfirm()">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
