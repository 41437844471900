import { Action } from '@ngrx/store';

export const SELECT = '[Franchise SubGroup] Select Franchise Sub Group';

export const GET_ASSET_COUNT = '[Franchise SubGroup] Get Asset Count';
export const GET_ASSET_COUNT_COMPLETE = '[Franchise SubGroup] Get Asset Count Complete';
export const GET_ASSET_COUNT_FAILED = '[Franchise SubGroup] Get Asset Count Failed';

export class SelectFranchiseSubGroupAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class GetAssetCountAction implements Action {
    readonly type = GET_ASSET_COUNT;

    constructor(public payload: { franchiseId: number, subgroupId: number }) { }
}

export class GetAssetCountCompleteAction implements Action {
    readonly type = GET_ASSET_COUNT_COMPLETE;

    constructor(public payload: number) { }
}

export class GetAssetCountFailedAction implements Action {
    readonly type = GET_ASSET_COUNT_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = SelectFranchiseSubGroupAction
    | GetAssetCountAction
    | GetAssetCountCompleteAction
    | GetAssetCountFailedAction;

