import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromRoot from '../../core/store';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest as observableCombineLatest, Observable, of as observableOf, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as assetModalActions from '../../core/store/asset-modal/asset-modal.actions';
import * as assetActions from '../../core/store/assets/asset.actions';
import * as characterActions from '../../core/store/characters/character.actions';
import { Field, FieldWithDepartmentField, OWNER_FIELDS } from '../../core/store/fields/field.model';
import { getSelectedGroupIds, getSelectedGroups, getSelectedSubGroupIds } from '../../core/store/groups/group-utils';
import * as layoutActions from '../../core/store/layout/layout.actions';
import * as projectCurrencyActions from '../../core/store/project-currencies/project-currency.actions';
import { rootSelectors } from '../../core/store/selectors';
import * as setActions from '../../core/store/sets/set.actions';
import * as storageBoxActions from '../../core/store/storage-boxes/storage-box.actions';
import { OwnerType, TableTypes } from '../../core/store/types';
import { ActionStatus } from '../../core/store/types';
import { buildPDropdownOptions, getStudioFieldName, makeCharOrSetDropdown } from '../../core/store/utils';
import { WarehouseSubLocation } from '../../core/store/warehouse-sub-locations/warehouse-sub-location.model';
import { SaveAssetsOptions } from '../../shared/data-services/asset-data.service';
import { PDropdownOption } from '../../shared/interfaces/p-dropdown-options';
import { STANDARD_FIELDS } from './../../../../shared/fields/standard-fields';
import * as subGroupActions from "./../../core/store/sub-groups/sub-group.actions";
import {
    Asset,
    Character,
    CustomStudioFieldName,
    Department,
    DepartmentField,
    Disposition,
    Division,
    Franchise,
    Group,
    Location,
    Project,
    ProjectCurrency,
    Set,
    Status,
    StorageBox,
    Studio,
    SubDepartment,
    SubLocation,
    User,
    WarehouseLocation
} from './../../core/store/models';

export const ASSET_MODAL_TYPE = {
    CREATE: 'create',
    EDIT: 'edit',
    EDIT_INDEX: 'editIndex',
    CREATE_FROM_TRANSACTION: 'createFromTransaction'
};

const OWNER_SORT_ORDER = {
    ['owner']: 1,
    [STANDARD_FIELDS.division.canonical_name]: 2,
    [STANDARD_FIELDS.franchise.canonical_name]: 3,
    [STANDARD_FIELDS.project.canonical_name]: 4,
    [STANDARD_FIELDS.warehouse.canonical_name]: 5,
    [STANDARD_FIELDS.location.canonical_name]: 6,
    [STANDARD_FIELDS.warehouseLocation.canonical_name]: 6,
    [STANDARD_FIELDS.subLocation.canonical_name]: 7,
    [STANDARD_FIELDS.warehouseSubLocation.canonical_name]: 7,
    [STANDARD_FIELDS.shelf.canonical_name]: 8,
    [STANDARD_FIELDS.storageBox.canonical_name]: 9,
    [STANDARD_FIELDS.department.canonical_name]: 10,
    [STANDARD_FIELDS.sub_department.canonical_name]: 11,
    [STANDARD_FIELDS.character.canonical_name]: 12,
    [STANDARD_FIELDS.set.canonical_name]: 13,
    [STANDARD_FIELDS.status.canonical_name]: 0,
    [STANDARD_FIELDS.disposition.canonical_name]: 0,
    [STANDARD_FIELDS.group_ids.canonical_name]: 14
};

export interface AssetModalData {
    action: string;
    asset: Asset;
    assetFields: Field[];
    // Gets from Asset Modal Data Service
    assetModalSelected?: { [fieldName: string]: number | number[] };
    canAccessRestrictedFieldsInDivision: boolean;
    canAccessRestrictedFieldsInFranchise: boolean;
    characters$: Observable<Character[]>;
    checkAndSaveAssets?: (newAssets: Asset[], action: string, dialogRef: MatDialogRef<any>, tableType?: TableTypes,mutation?:any, options?: SaveAssetsOptions, keepDialogOpen?: boolean) => void;
    customFieldNames: CustomStudioFieldName;
    departments$: Observable<Department[]>;
    department$: Observable<Department>;
    disableOwnerChange?: boolean;
    dispositions$: Observable<Disposition[]>;
    divisionGroups$?: Observable<Group[]>;
    notDivisionGroups$?: Observable<Group[]>;
    allGroups$?: Observable<Group[]>;
    not$?: Observable<Group[]>;
    franchise?: Franchise;
    franchiseGroups$?: Observable<Group[]>;
    notFranchiseGroups$?: Observable<Group[]>;
    locations$: Observable<Location[]>;
    projects$: Observable<Project[]>;
    projectCurrencies$: Observable<ProjectCurrency[]>;
    saving$?: Observable<ActionStatus>;
    sets$: Observable<Set[]>;
    statuses$: Observable<Status[]>;
    storageBoxes$: Observable<StorageBox[]>;
    studio$: Observable<Studio>;
    subDepartments$: Observable<SubDepartment[]>;
    subLocations$: Observable<SubLocation[]>;
    tableType?: TableTypes;
    warehouseLocations$: Observable<WarehouseLocation[]>;
    warehouseSubLocations$: Observable<WarehouseSubLocation[]>;
    divisions: Division[];
    ownedFranchises: Franchise[];
    user: User;
}
export interface AssetSaveStatus {
    save: boolean;
    saveAndNew: boolean;
}
@Component({
    templateUrl: './asset-modal.component.html',
    styleUrls: ['./asset-modal.component.scss']
})
export class AssetModalComponent implements OnDestroy, OnInit {
    action: string;
    allFields: FieldWithDepartmentField[] = [];
    allGroups$: Observable<Group[]>;
    allInitialFields: Field[] = [];
    asset: Asset;
    assetForm: UntypedFormGroup;
    // Gets from Asset Modal Data Service
    assetModalSelected: { [fieldName: string]: number | number[] };
    ASSET_MODAL_TYPE = ASSET_MODAL_TYPE;
    canAccessRestrictedFieldsInDivision: boolean;
    canAccessRestrictedFieldsInFranchise: boolean;
    characterOptions$: Observable<PDropdownOption[]> = observableOf([]);
    /** displayed for Franchise Users when they have no owned options and the input is disabled */
    currentFranchiseOption: PDropdownOption[] = [];
    customFieldNames: CustomStudioFieldName;
    department: Department;
    departmentOptions$: Observable<PDropdownOption[]> = observableOf([]);
    disableOwnerChange: boolean;
    dispositionOptions$: Observable<PDropdownOption[]> = observableOf([]);
    divisionGroups$: Observable<Group[]>;
    notDivisionGroups$: Observable<Group[]>;
    divisionOptions: PDropdownOption[] = [];
    franchise: Franchise;
    franchiseGroups$: Observable<Group[]>;
    notFranchiseGroups$: Observable<Group[]>;
    initialFields: Field[] = [];
    locationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    originalFranchise: Franchise;
    otherFields: FieldWithDepartmentField[] = [];
    ownedFranchises: Franchise[] = [];
    ownedFranchiseOptions: PDropdownOption[] = [];
    ownerFields: FieldWithDepartmentField[] = [];
    OwnerType = OwnerType;
    projectCurrencyOptions$: Observable<PDropdownOption[]> = observableOf([]);
    projectOptions$: Observable<PDropdownOption[]> = observableOf([]);
    purchaseFields: FieldWithDepartmentField[] = [];
    saveDisabled = false; /** set in AssetDataService */
    saveText = 'Save';
    saveAndCreateText = 'Save';
    saving$: Observable<ActionStatus>;
    selectedGroups: string[] = [];
    selectedSubGroups: string[] = [];
    selectedOwner: number;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    setOptions$: Observable<PDropdownOption[]> = observableOf([]);
    standardFields = STANDARD_FIELDS;
    statusOptions$: Observable<PDropdownOption[]> = observableOf([]);
    storageBoxOptions$: Observable<PDropdownOption[]> = observableOf([]);
    studio: Studio;
    subDepartmentOptions$: Observable<PDropdownOption[]> = observableOf([]);
    subLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    tableType: TableTypes;
    warehouseLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    warehouseLocationSubLocationShelfDisabled = false;
    warehouseOptions: PDropdownOption[] = [];
    warehouseSubLocationOptions$: Observable<PDropdownOption[]> = observableOf([]);
    user: User;
    trashOption = { label: 'Trash Asset', command: () => this.moveToTrash() };
    recoverOption = { label: 'Recover Asset', command: () => this.recover() };
    modalOptions = [
        this.trashOption
    ];
    divisionOption = { value: OwnerType.Division, label: 'Owner: Division' };
    franchiseOption = { value: OwnerType.Franchise, label: 'Owner: Feature' };
    seriesOption = { value: OwnerType.Series, label: 'Owner: Series' };
    ownerOptions: PDropdownOption[] = [];
    @ViewChild('groupInput', { static: false }) groupInput: ElementRef;
    @ViewChild('menu', { static: false }) menuElement;
    placeholder: string = null;
    checkAndSaveAssets: (newAssets: Asset[], action: string, dialogRef: MatDialogRef<any>, tableType?: TableTypes, mutation?: any, options?: SaveAssetsOptions, keepDialogOpen?: boolean, dialog?: MatDialog) => void;

    private subs = new Subscription();
    private isDivisionTable: boolean;
    private isFranchiseTable: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) private data: AssetModalData, public dialogRef: MatDialogRef<AssetModalComponent>, private fb: UntypedFormBuilder, private store: Store<rootSelectors.State>, public deviceService: DeviceDetectorService, private cdf: ChangeDetectorRef, public dialog: MatDialog) { }

    ngOnInit() {
        this.asset = new Asset(this.data.asset);
        this.user = this.data.user;
        this.action = this.data.action;
        if (this.action === ASSET_MODAL_TYPE.CREATE) {
            this.saveText = 'Create Asset';
            this.saveAndCreateText = "Save and Create Another"
        }
        this.customFieldNames = this.data.customFieldNames;
        this.tableType = this.data.tableType;

        this.canAccessRestrictedFieldsInDivision = this.data.canAccessRestrictedFieldsInDivision;
        this.canAccessRestrictedFieldsInFranchise = this.data.canAccessRestrictedFieldsInFranchise;

        this.modalOptions = this.asset.trashed ? [this.recoverOption] : [this.trashOption];
        this.checkAndSaveAssets = this.data.checkAndSaveAssets;
        this.saving$ = this.data.saving$;
        this.disableOwnerChange = this.data.disableOwnerChange ? this.data.disableOwnerChange : false;
        this.initialFields = this.data.assetFields;
        this.allInitialFields = this.initialFields;
        this.ownedFranchises = this.data.ownedFranchises;

        this.allGroups$ = this.data.allGroups$;
        this.divisionGroups$ = this.data.divisionGroups$;
        this.notDivisionGroups$ = this.data.notDivisionGroups$;
        this.franchiseGroups$ = this.data.franchiseGroups$;
        this.notFranchiseGroups$ = this.data.notFranchiseGroups$;
        this.data.studio$.pipe(take(1)).subscribe(studio => this.studio = studio);

        const franchiseName = getStudioFieldName(this.standardFields.franchise, this.allInitialFields);
        const divisionName = getStudioFieldName(this.standardFields.division, this.allInitialFields);
        if (this.user.permission.hasDivisionPermissionForStudio(this.studio.id)) {
            this.divisionOption.label = `Owner: ${divisionName}`;
            this.ownerOptions.push(this.divisionOption);
        }
        if (!this.studio.franchise_hidden) {
            this.franchiseOption.label = `Owner: ${franchiseName}`;
            this.ownerOptions.push(this.franchiseOption);
        }
        if (!this.studio.series_hidden) {
            this.seriesOption.label = `Owner: ${this.customFieldNames.series_id}`;
            this.ownerOptions.push(this.seriesOption);
        }

        this.isDivisionTable = (this.tableType === TableTypes.DivisionTable || this.tableType === TableTypes.DivisionGroupTable) ? true : false;
        this.isFranchiseTable = (this.tableType === TableTypes.FranchiseTable || this.tableType === TableTypes.FranchiseGroupTable) ? true : false;

        this.assetModalSelected = this.data.assetModalSelected;
        if (this.assetModalSelected && this.action === ASSET_MODAL_TYPE.CREATE) {
            OWNER_FIELDS.forEach(f => {
                if (this.assetModalSelected[f]) {
                    this.asset[f] = this.assetModalSelected[f];
                    if (f === STANDARD_FIELDS.group_ids.canonical_name) {
                        this.selectedGroups = getSelectedGroups(this.franchiseGroups$, this.divisionGroups$, this.isDivisionTable, this.asset, this.isFranchiseTable, this.allGroups$);

                        this.store.dispatch(new subGroupActions.GetAssetSubGroupsNamesAction({ subgroupIds: this.asset.sub_group_ids }));
                        this.subs.add(this.store.select(fromRoot.getSubGroupNames).subscribe((subGroupNames) => {
                            this.selectedSubGroups = Array.from(Object.values(subGroupNames));
                        }));
                    }
                }
            });
        }
        const departmentName = getStudioFieldName(this.standardFields.department, this.allInitialFields);
        this.departmentOptions$ = this.data.departments$.pipe(
            map(deptList => {
                return deptList.map(dept => {
                    return {
                        id: dept.id,
                        name: `${dept.account_code} ${dept.name}`
                    }
                });
            }),
            map(depts => buildPDropdownOptions(depts, {
                nullOption: true,
                nullLabel: `${departmentName} *`
            }))

        );
        this.subDepartmentOptions$ = this.data.subDepartments$.pipe(
            map(subDepts => {
                const subDeptOpts = subDepts.map(subDept => {
                    return {
                        value: subDept.id,
                        label: subDept.displayName()
                    };
                });
                return subDeptOpts;
            }));

        this.fixCostField(this.initialFields);
        this.createAssetInit();

        this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
            this.subs.unsubscribe();
            if (this.action !== ASSET_MODAL_TYPE.CREATE) {
                this.store.dispatch(new assetModalActions.SelectDivisionAction(null));
                this.store.dispatch(new assetModalActions.SelectFranchiseAction(null));
                this.store.dispatch(new assetModalActions.SelectLocationAction(null));
                this.store.dispatch(new assetModalActions.SelectProjectAction(null));
                this.store.dispatch(new assetModalActions.SelectWarehouseAction(null));
                this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(null));
                this.store.dispatch(new assetModalActions.SelectDepartmentAction(null));
                this.store.dispatch(new assetModalActions.SelectGroupsAction([]));
            }
        });
    }

    ngAfterContentChecked() {
        this.cdf.detectChanges();
    }

    ngOnDestroy() {
        if (this.subs) { this.subs.unsubscribe() };
    }

    createAssetInit() {
        this.originalFranchise = this.ownedFranchises.find(f => f.id === this.asset.franchise_id);
        this.store.dispatch(new assetModalActions.SelectFranchiseAction(this.asset.franchise_id));
        if (this.asset.warehouse_id) {
            this.store.dispatch(new storageBoxActions.ListByWarehouseAction(this.asset.warehouse_id));
        }
        this.store.dispatch(new assetModalActions.SelectWarehouseAction(this.asset.warehouse_id));
        this.store.dispatch(new assetModalActions.SelectLocationAction(this.asset.location_id));
        this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(this.asset.warehouse_location_id));
        this.store.dispatch(new assetModalActions.SelectDivisionAction(this.asset.division_id));
        this.store.dispatch(new assetModalActions.SelectProjectAction(this.asset.project_id));
        this.store.dispatch(new assetModalActions.SelectDepartmentAction(this.asset.department_id));
        if (this.asset.storage_box_id) {
            this.warehouseLocationSubLocationShelfDisabled = true;
        }

        this.franchise = this.data.franchise ? this.data.franchise : null;
        if (this.asset.division_id) {
            this.selectedOwner = OwnerType.Division;
        } else {
            this.selectedOwner = this.franchise.is_series ? OwnerType.Series : OwnerType.Franchise;
        }
        this.filterOwnerFields();

        this.divisionOptions = this.data.divisions ? buildPDropdownOptions(this.data.divisions, {
            nullOption: true,
            nullLabel: `${this.customFieldNames.division_id} *`
        }) : [];

        const franchiseName = getStudioFieldName(this.standardFields.franchise, this.allInitialFields);
        this.currentFranchiseOption = this.franchise ? buildPDropdownOptions([this.franchise], {
            nullOption: true,
            nullLabel: `${franchiseName}/${this.customFieldNames.series_id}`
        }) : [];
        if (this.selectedOwner === OwnerType.Franchise) {
            this.ownedFranchiseOptions = buildPDropdownOptions(this.ownedFranchises.filter(f => !f.is_series), {
                nullOption: true,
                nullLabel: `${franchiseName} *`
            });
        } else if (this.selectedOwner === OwnerType.Series) {
            this.ownedFranchiseOptions = buildPDropdownOptions(this.ownedFranchises.filter(f => f.is_series), {
                nullOption: true,
                nullLabel: `${this.customFieldNames.series_id} *`
            });
        } else {
            this.ownedFranchiseOptions = buildPDropdownOptions(this.ownedFranchises, {
                nullOption: true,
                nullLabel: `${franchiseName}/${this.customFieldNames.series_id}`
            });
        }
        this.projectOptions$ = this.data.projects$.pipe(map(projects => {
            return buildPDropdownOptions(projects, {
                nullOption: true,
                nullLabel: this.getNullLabel()
            })
        }
        ));
        const division = this.data.divisions.find(div => div.id === this.asset.division_id);

        const warehouseName = getStudioFieldName(this.standardFields.warehouse, this.allInitialFields);
        this.warehouseOptions = division && division.warehouses ? buildPDropdownOptions(division.warehouses, {
            nullOption: true,
            nullLabel: `${warehouseName} *`
        }) : [];

        const locationName = getStudioFieldName(this.standardFields.location, this.allInitialFields);
        this.locationOptions$ = this.data.locations$.pipe(map(locs => buildPDropdownOptions(locs, {
            nullOption: true,
            nullLabel: locationName
        })));

        const warehouseLocationName = getStudioFieldName(this.standardFields.warehouseLocation, this.allInitialFields);
        this.warehouseLocationOptions$ = this.data.warehouseLocations$.pipe(map(locs => {
            const field = this.data.assetFields.find(f => f.canonical_name === STANDARD_FIELDS.warehouseLocation.canonical_name);
            return buildPDropdownOptions(locs, {
                nullOption: true,
                nullLabel: warehouseLocationName,
                field
            });
        }));

        const subLocationName = getStudioFieldName(this.standardFields.subLocation, this.allInitialFields);
        this.subLocationOptions$ = this.data.subLocations$.pipe(map(sublocs => buildPDropdownOptions(sublocs, {
            nullOption: true,
            nullLabel: subLocationName
        })));

        const warehouseSubLocationName = getStudioFieldName(this.standardFields.warehouseSubLocation, this.allInitialFields);
        this.warehouseSubLocationOptions$ = this.data.warehouseSubLocations$.pipe(map(sublocs => {
            const field = this.data.assetFields.find(f => f.canonical_name === STANDARD_FIELDS.warehouseSubLocation.canonical_name);
            return buildPDropdownOptions(sublocs, {
                nullOption: true,
                nullLabel: warehouseSubLocationName,
                field
            });
        }));

        const storageBoxName = getStudioFieldName(this.standardFields.storageBox, this.allInitialFields);
        this.storageBoxOptions$ = this.data.storageBoxes$.pipe(map(storageBoxes => storageBoxes ? buildPDropdownOptions(storageBoxes, { nullOption: true, nullLabel: storageBoxName }) : []));

        const dispositionName = getStudioFieldName(this.standardFields.disposition, this.allInitialFields);
        this.dispositionOptions$ = this.data.dispositions$.pipe(map(disps => buildPDropdownOptions(disps, {
            nullOption: true,
            nullLabel: this.selectedOwner === OwnerType.Division ? `${dispositionName}` : `${dispositionName} *`
        })));

        const statusName = getStudioFieldName(this.standardFields.status, this.allInitialFields);
        this.statusOptions$ = this.data.statuses$.pipe(map(statuses => buildPDropdownOptions(statuses, {
            nullOption: true,
            nullLabel: `${statusName}`
        })));
        this.characterOptions$ = makeCharOrSetDropdown(this.data.characters$, true);
        this.setOptions$ = makeCharOrSetDropdown(this.data.sets$, false);
        this.projectCurrencyOptions$ = this.data.projectCurrencies$.pipe(
            map(projectCurrencies => {
                if (projectCurrencies && projectCurrencies.length) {
                    if (this.assetForm) {
                        const value = this.assetForm.getRawValue();
                        if (!projectCurrencies.find(c => c.id === value.origin_currency)) {
                            const base = projectCurrencies.find(pc => pc.base);
                            this.assetForm.patchValue({ origin_currency: base.id });
                        }

                    }
                    const pcOpts = projectCurrencies.map(pc => {
                        return {
                            value: pc.id,
                            label: pc.accounting_code
                        };
                    });
                    return pcOpts;
                }
                return [];
            }));

        if (this.action !== ASSET_MODAL_TYPE.CREATE) {
            if (this.asset.group_ids && this.asset.group_ids.length) {
                this.selectedGroups = getSelectedGroups(this.franchiseGroups$, this.divisionGroups$, this.isDivisionTable, this.asset, this.isFranchiseTable, this.allGroups$);
            }
            if (this.asset.sub_group_ids && this.asset.sub_group_ids.length) {
                this.store.dispatch(new subGroupActions.GetAssetSubGroupsNamesAction({ subgroupIds: this.asset.sub_group_ids }));
                this.subs.add(this.store.select(fromRoot.getSubGroupNames).subscribe((subGroupNames) => {
                    this.selectedSubGroups = Array.from(Object.values(subGroupNames));
                }));
            }
        }
        this.setupSubscriptions();
    }

    setupSubscriptions() {
        this.subs.add(observableCombineLatest(this.data.department$, this.data.departments$).subscribe(([dept, departments]: [Department, Department[]]) => {
            if (dept) {
                this.department = dept;
                this.buildForm(dept);
            } else if (departments.length) {
                this.department = this.asset.department_id ? departments.find(d => d.id === this.asset.department_id) : departments[0];
                this.store.dispatch(new assetModalActions.SelectDepartmentAction(this.department.id));
                this.buildForm(this.department);
            }
        }));
    }

    buildForm(dept: Department) {
        this.selectedOwner = this.assetForm ? this.assetForm.getRawValue().owner : this.selectedOwner;
        const useDivisionFields = this.selectedOwner === OwnerType.Division ? true : false;
        const { allFields, ownerFields, purchaseFields, otherFields } = Field.splitByPurchase(this.initialFields, dept, useDivisionFields, this.canAccessRestrictedFieldsInDivision, this.canAccessRestrictedFieldsInFranchise);
        this.allFields = allFields;
        this.purchaseFields = purchaseFields;
        this.otherFields = otherFields.filter(o => o.field.canonical_name != "sub_code" && o.field.canonical_name != "assetEvents");
        this.ownerFields = ownerFields.sort((a, b) => OWNER_SORT_ORDER[a.field.canonical_name] - OWNER_SORT_ORDER[b.field.canonical_name]);
        // Build reactive form from fields
        const formObj = {};
        allFields.forEach(field => {
            if (field.field.canonical_name === 'owner') {
                formObj['owner'] = [
                    { value: (this.assetForm && this.assetForm.value['owner']) ? this.assetForm.value['owner'] : this.selectedOwner, disabled: false },
                    []
                ];
            } else {
                //exclude disposition_id and department_id when create a new asset
                const excludeValueArray = (this.action === ASSET_MODAL_TYPE.CREATE) ? ['disposition_id', 'department_id', 'status_id', 'warehouse_location_id', 'warehouse_sub_location_id', 'group_ids', 'warehouse_id', 'sub_department_id'] : [];
                const initial = {
                    value: (this.assetForm && this.assetForm.value[field.field.canonical_name])
                        ? this.assetForm.value[field.field.canonical_name]
                        : (excludeValueArray.includes(field.field.canonical_name)) ? null : field.field.getValueFromAsset(this.asset),
                    disabled: this.action === 'createFromTransaction' && field.field.canonical_name === 'project_id'
                };
                // If disposition is visible for division assets, we don't want it to be a required field in the modal
                let validators;
                if (field.field.canonical_name === this.standardFields.disposition.canonical_name && this.selectedOwner === OwnerType.Division) {
                    field.field.getValidators(false);
                } else {
                    validators = field.field.getValidators(field.departmentField.required);
                }

                formObj[field.field.canonical_name] = [initial, validators];
            }
        });
        if (this.action === ASSET_MODAL_TYPE.CREATE) {
            this.selectedGroups = [];
            this.selectedSubGroups = [];
        }
        this.assetForm = this.fb.group(formObj);
    }

    close() {
        this.dialogRef.close();
    }

    moveToTrash() {
        const assetMutations = { trashed: true };
        this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList: [this.asset.id], studioId: this.asset.studio_id, assetMutations }));
        this.saving$.pipe(filter(s => s !== ActionStatus.Loading), take(1)).subscribe(status => {
            if (status === ActionStatus.Complete) {
                this.dialogRef.close();
            }
        });
    }

    recover() {
        const assetMutations = { trashed: false };
        this.store.dispatch(new assetActions.UpdateMultipleAction({ assetIdList: [this.asset.id], studioId: this.asset.studio_id, assetMutations }));
        this.saving$.pipe(filter(s => s !== ActionStatus.Loading), take(1)).subscribe(status => {
            if (status === ActionStatus.Complete) {
                this.asset.trashed = false;
                this.modalOptions = [this.trashOption];
            }
        });
    }

    saveOrClearForm(saveAndNew: boolean) {
        const values = this.assetForm.getRawValue();
        const newAsset = new Asset({ ...this.asset, ...values });
        OWNER_FIELDS.forEach(field => {
            newAsset[field] = values.hasOwnProperty(field) ? values[field] : null;
        });
        newAsset.group_ids = getSelectedGroupIds(this.divisionGroups$, this.notDivisionGroups$, this.franchiseGroups$, this.notFranchiseGroups$, this.isDivisionTable, this.isFranchiseTable, this.selectedGroups, this.allGroups$);
        newAsset.sub_group_ids = getSelectedSubGroupIds(this.divisionGroups$, this.notDivisionGroups$, this.franchiseGroups$, this.notFranchiseGroups$, this.isDivisionTable, this.isFranchiseTable, this.selectedSubGroups, this.allGroups$);
        const infoAction = new layoutActions.InfoAction({ message: `Asset has been ${this.action === ASSET_MODAL_TYPE.CREATE ? 'created' : 'updated'}` });
        this.checkAndSaveAssets([newAsset], this.action, this.dialogRef, this.tableType, null, { infoAction }, saveAndNew, this.dialog);

        if (saveAndNew) {
            const transitionFormValueArray = ['disposition_id', 'department_id', 'owner', 'project_id', 'division_id', 'franchise_id', 'origin_currency', 'warehouse_id', 'status_id', 'warehouse_location_id', 'warehouse_sub_location_id'];
            Object.keys(this.assetForm.controls).forEach((key) => {

                if (!transitionFormValueArray.includes(key)) { // do not clear the values in transitionFormValueArray
                    this.assetForm.controls[key].setValue(null);
                }
            });
            //clear groups
            this.selectedGroups = [];
            this.selectedSubGroups = [];
        }
    }

    saveAsset(event: Event, action: boolean) {
        event.preventDefault();
        if (this.assetForm.invalid || this.asset.trashed) {
            return;
        }
        if (this.action === ASSET_MODAL_TYPE.CREATE) //show save and add new only in add mode
            this.saveOrClearForm(action);
        else
            this.saveOrClearForm(false);
    }

    selectOwner() {
        this.selectedOwner = this.assetForm.getRawValue().owner;
        this.filterOwnerFields();
        let setToOriginalFranchise;
        let switchFranchiseType;
        const currentFranchiseId = this.assetForm.getRawValue().franchise_id;
        const currentFranchise = this.ownedFranchises.find(f => f.id === currentFranchiseId);
        const currentProjectId = this.assetForm.getRawValue().project_id;
        const franchiseName = getStudioFieldName(this.standardFields.franchise, this.allInitialFields);
        if (this.selectedOwner === OwnerType.Division) {
            this.ownedFranchiseOptions = buildPDropdownOptions(this.ownedFranchises, {
                nullOption: true,
                nullLabel: `${franchiseName}/${this.customFieldNames.series_id}`
            });
            if (this.originalFranchise && currentFranchiseId === this.originalFranchise.id) {
                this.store.dispatch(new assetModalActions.SelectFranchiseAction(this.originalFranchise.id));
                this.store.dispatch(new assetModalActions.SelectProjectAction(this.asset.project_id));
            } else if (currentFranchiseId) {
                this.selectFranchise(currentFranchiseId);
                if (currentProjectId) {
                    this.selectProject(currentProjectId);
                    this.assetForm.patchValue({ project_id: currentProjectId });
                }
            }
        } else {
            if (this.selectedOwner === OwnerType.Franchise) {
                this.ownedFranchiseOptions = buildPDropdownOptions(this.ownedFranchises.filter(f => !f.is_series), {
                    nullOption: true,
                    nullLabel: `${franchiseName} *`
                });
                this.placeholder = 'Project';
                setToOriginalFranchise = this.originalFranchise && currentFranchiseId === this.originalFranchise.id ? !this.originalFranchise.is_series : false;
                switchFranchiseType = currentFranchise && currentFranchise.is_series ? true : false;
            } else {
                this.placeholder = 'Season';
                this.ownedFranchiseOptions = buildPDropdownOptions(this.ownedFranchises.filter(f => f.is_series), {
                    nullOption: true,
                    nullLabel: `${this.customFieldNames.series_id} *`
                });
                setToOriginalFranchise = this.originalFranchise && currentFranchiseId === this.originalFranchise.id ? this.originalFranchise.is_series : false;
                switchFranchiseType = currentFranchise && !currentFranchise.is_series ? true : false;
            }
            if (this.originalFranchise && switchFranchiseType) {
                this.clearOrResetFranchise(setToOriginalFranchise);
            } else if (!switchFranchiseType) {
                this.selectFranchise(currentFranchiseId);
            }
        }
    }

    clearOrResetFranchise(setToOriginalFranchise: boolean) {
        if (setToOriginalFranchise) {
            this.store.dispatch(new assetModalActions.SelectFranchiseAction(this.originalFranchise.id));
            this.store.dispatch(new assetModalActions.SelectProjectAction(this.asset.project_id));
            this.store.dispatch(new assetModalActions.SelectLocationAction(this.asset.location_id));
            this.store.dispatch(new assetModalActions.SelectSubLocationAction(this.asset.sub_location_id));
        } else {
            this.store.dispatch(new assetModalActions.SelectFranchiseAction(null));
            this.store.dispatch(new assetModalActions.SelectProjectAction(null));
            this.store.dispatch(new assetModalActions.SelectLocationAction(null));
            this.store.dispatch(new assetModalActions.SelectSubLocationAction(null));
            this.assetForm.patchValue({ franchise_id: null, project_id: null, location_id: null, sub_location_id: null, set_id: null, character_id: null });
        }
    }

    selectDivision(divId: number) {
        const division = this.data.divisions.find(div => div.id === divId);
        const warehouseName = getStudioFieldName(this.standardFields.warehouse, this.allInitialFields);
        this.warehouseOptions = division && division.warehouses ? buildPDropdownOptions(division.warehouses, {
            nullOption: true,
            nullLabel: `${warehouseName} *`
        }) : [];
        if (division) {
            this.store.dispatch(new assetModalActions.SelectDivisionAction(division.id));
        } else {
            this.store.dispatch(new assetModalActions.SelectDivisionAction(null));
        }
        this.store.dispatch(new assetModalActions.SelectWarehouseAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseSubLocationAction(null));
        this.assetForm.patchValue({ warehouse_id: null, warehouse_location_id: null, warehouse_sub_location_id: null, shelf: null, storage_box_id: null });
    }

    selectFranchise(franchiseId: number) {
        this.franchise = this.ownedFranchises.find(f => f.id === franchiseId);
        this.store.dispatch(new assetModalActions.SelectFranchiseAction(franchiseId));
        this.store.dispatch(new assetModalActions.SelectLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectSubLocationAction(null));
        if (franchiseId) {
            this.store.dispatch(new characterActions.ListByFranchiseAction(franchiseId));
            this.store.dispatch(new projectCurrencyActions.ListAction(franchiseId));
            this.store.dispatch(new setActions.ListAction(franchiseId));
        } else {
            if (this.selectedOwner === OwnerType.Series) {
                this.placeholder = "Season"
            } else if (this.selectedOwner === OwnerType.Franchise) {
                this.placeholder = "Project"
            }
        }
        this.ownerFields.map(field => {
            if (field.field.canonical_name === 'project_id' && franchiseId) {
                const df = new DepartmentField(field.departmentField);
                df.required = true;
                field.departmentField = df;
                this.saveDisabled = true;
            }
        });

        this.assetForm.patchValue({ location_id: null, sub_location_id: null, set_id: null, character_id: null });
    }

    selectProject(projectId: number) {
        if (projectId) {
            this.store.dispatch(new assetModalActions.SelectProjectAction(projectId));
            this.assetForm.patchValue({ character_id: null, set_id: null });

            this.saveDisabled = false;
        } else {
            this.saveDisabled = true;
        }

    }
    selectDepartment(deptId: number) {
        this.store.dispatch(new assetModalActions.SelectDepartmentAction(deptId));
        this.store.dispatch(new assetModalActions.SelectSubDepartmentAction(null));
        this.assetForm.patchValue({ sub_department_id: null, character_id: null, set_id: null });
        // If a storage box id is selected, warehouse location fields are disabled so when the department fields change and try to set them, it doesn't work so those fields needs to be set here
        const storageBoxId = this.assetForm.getRawValue().storage_box_id;
        if (storageBoxId) {
            this.selectStorageBox(storageBoxId);
        }
    }

    selectLocation(locationId: number) {
        this.store.dispatch(new assetModalActions.SelectLocationAction(locationId));
        this.store.dispatch(new assetModalActions.SelectSubLocationAction(null));
        this.assetForm.patchValue({ sub_location_id: null });
    }

    selectWarehouseLocation(warehouseLocationId: number) {
        this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(warehouseLocationId));
        this.store.dispatch(new assetModalActions.SelectWarehouseSubLocationAction(null));
        this.assetForm.patchValue({ warehouse_sub_location_id: null });
    }

    selectWarehouse(warehouseId: number) {
        this.store.dispatch(new assetModalActions.SelectWarehouseAction(warehouseId));
        this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(null));
        this.store.dispatch(new assetModalActions.SelectWarehouseSubLocationAction(null));
        if (warehouseId) {
            this.store.dispatch(new storageBoxActions.ListByWarehouseAction(warehouseId));
        }
        this.assetForm.patchValue({ warehouse_location_id: null, warehouse_sub_location_id: null, shelf: null, storage_box_id: null });
        this.warehouseLocationSubLocationShelfDisabled = false;
    }

    selectWarehouseSubLocation(warehouseSubLocationId: number) {
        this.store.dispatch(new assetModalActions.SelectWarehouseSubLocationAction(warehouseSubLocationId));
    }

    selectStatus(statusId: number) {
        this.store.dispatch(new assetModalActions.SelectStatusAction(statusId));
    }

    selectStorageBox(storageBoxId: number) {
        if (storageBoxId) {
            // Set Warehouse Location, Warehouse Sub Location and Shelf to storage box's and disable those inputs
            this.data.storageBoxes$.pipe(take(1)).subscribe(storageBoxes => {
                const storageBox = storageBoxes.find(sB => sB.id === storageBoxId);
                const warehouseLocationId = storageBox.warehouse_location_id;
                const warehouseSubLocationId = storageBox.warehouse_sub_location_id;
                this.store.dispatch(new assetModalActions.SelectWarehouseLocationAction(warehouseLocationId));
                this.store.dispatch(new assetModalActions.SelectWarehouseSubLocationAction(warehouseSubLocationId));
                this.assetForm.patchValue({ warehouse_location_id: warehouseLocationId, warehouse_sub_location_id: warehouseSubLocationId, shelf: storageBox.shelf });
                this.warehouseLocationSubLocationShelfDisabled = true;
            });
        } else {
            this.warehouseLocationSubLocationShelfDisabled = false;
        }
    }

    selectSubLocation(subLocationId: number) {
        this.store.dispatch(new assetModalActions.SelectSubLocationAction(subLocationId));
    }

    selectSubDepartment(subDepartmentId: number) {
        this.store.dispatch(new assetModalActions.SelectSubDepartmentAction(subDepartmentId));
    }

    selectDisposition(dispositionId: number) {
        this.store.dispatch(new assetModalActions.SelectDispositionAction(dispositionId));
    }

    selectCharacter(characterId: number) {
        this.store.dispatch(new assetModalActions.SelectCharacterAction(characterId));
    }

    selectSet(setId: number) {
        this.store.dispatch(new assetModalActions.SelectSetAction(setId));
    }

    selectGroups() {
        this.allGroups$.pipe(
            take(1)
        ).subscribe(groups => {
            const groupIds = this.getSelectedGroupIdsFromAllGroups(groups);
            this.store.dispatch(new assetModalActions.SelectGroupsAction(groupIds));
        });
    }

    private filterOwnerFields() {
        const franchiseView = this.selectedOwner !== OwnerType.Division;
        this.initialFields = this.allInitialFields.filter(f => {
            if (f.canonical_name === STANDARD_FIELDS.photos.canonical_name || f.canonical_name === STANDARD_FIELDS.sync_id.canonical_name || f.canonical_name === STANDARD_FIELDS.created_at.canonical_name || f.canonical_name === STANDARD_FIELDS.updated_at.canonical_name) {
                return false;
            }
            if (franchiseView) {
                return !f.isDivisionOnly() && f.canonical_name !== STANDARD_FIELDS.division.canonical_name;
            } else {
                return (!f.isFranchiseOnly() || (f.isVisibilityAll() && f.canonical_name === STANDARD_FIELDS.disposition.canonical_name)) && f.canonical_name !== STANDARD_FIELDS.origin_currency.canonical_name;
            }
        });
        if (this.assetForm) {
            this.buildForm(this.department);
        }
    }

    private fixCostField(fields: Field[]) {
        const index = fields.findIndex(f => f.canonical_name === 'cost');
        if (index >= 0) {
            fields[index] = new Field({ ...fields[index] });
            fields[index].name = 'Origin Cost';
        }
    }

    private getSelectedGroupIdsFromAllGroups(groups: Group[]) {
        return groups.reduce((groupIds: number[], grp) => {
            if (this.selectedGroups.includes(grp.name)) {
                groupIds.push(grp.id);
            }
            return groupIds;
        }, []);
    }

    private getNullLabel(): string {
        let nullLabel = '';
        if (this.selectedOwner === OwnerType.Franchise) {
            nullLabel = `${this.customFieldNames.project_id} *`;
        } else if (this.selectedOwner === OwnerType.Division) {
            if (this.franchise) {
                if (this.franchise.is_series) {
                    this.placeholder = `${this.customFieldNames.season_id}`;
                    nullLabel = `${this.customFieldNames.season_id} *`;
                } else {
                    this.placeholder = `${this.customFieldNames.project_id}`;
                    nullLabel = `${this.customFieldNames.project_id} *`;
                }
            } else {
                this.placeholder = `${this.customFieldNames.project_id}/${this.customFieldNames.season_id}`;
                nullLabel = `${this.customFieldNames.project_id}/${this.customFieldNames.season_id}`;
            }
        } else if (this.selectedOwner === OwnerType.Series) {
            nullLabel = `${this.customFieldNames.season_id} *`;
        }
        return nullLabel;
    }
}
