import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as projectCharacterActions from './project-character.actions';
import { ProjectCharacter } from './project-character.model';

@Injectable()
export class ProjectCharacterEffects {
    static BASE_URL = '/api/project-characters';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCharacterActions.ADD),
            map((action: projectCharacterActions.AddAction) => action.payload),
            mergeMap((data) => this.http.post(ProjectCharacterEffects.BASE_URL, data).pipe(
                map(res => new ProjectCharacter(res)),
                map((character: ProjectCharacter) => new projectCharacterActions.AddCompleteAction(character)),
                catchError((error) => observableOf(new projectCharacterActions.AddFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCharacterActions.UPDATE),
            map((action: projectCharacterActions.UpdateAction) => action.payload),
            mergeMap((data) => this.http.put(`${ProjectCharacterEffects.BASE_URL}/${data.id}`, data).pipe(
                map(res => new ProjectCharacter(res)),
                map((character: ProjectCharacter) => new projectCharacterActions.UpdateCompleteAction(character)),
                catchError((error) => observableOf(new projectCharacterActions.UpdateFailedAction({ error }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCharacterActions.DELETE),
            map((action: projectCharacterActions.DeleteAction) => action.payload),
            mergeMap((projectChar) => this.http.delete(`${ProjectCharacterEffects.BASE_URL}/${projectChar.id}`).pipe(
                map(() => new projectCharacterActions.DeleteCompleteAction(projectChar)),
                catchError((error) => observableOf(new projectCharacterActions.DeleteFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(projectCharacterActions.ADD_FAILED, projectCharacterActions.UPDATE_FAILED, projectCharacterActions.DELETE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
