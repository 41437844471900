import { Moment, sosMoment } from '../../../shared/services/moment.service';
import { Currency } from '../currencies/currency.model';
import { BaseModel } from './../base/base.model';

export class ProjectCurrency extends BaseModel {
    id: number;
    project_id: number = null;
    currency_id: number = null;
    accounting_code: string = null;
    rate: string = null;
    base: boolean = null;
    locked: boolean = null;
    date_of_rate: Date = null;
    country: string = null;
    currency: Currency = null;
    local: boolean = null;
    currencyRequired: boolean = null;
    moment: Moment;

    constructor(currencyObject) {
        super();
        this.initializeData(currencyObject, this);
        this.moment = sosMoment;
        if (currencyObject.currency) {
            this.currency = new Currency(currencyObject.currency);
        }
    }

    getFormattedDate() {
        return this.moment.utc(this.date_of_rate).format('DD MMM. YYYY');
    }
}
