import { BaseModel } from './../base/base.model';

export class Warehouse extends BaseModel {
    id: number;
    division_id: number = null;
    name: string = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(warehouseObj: any = {}) {
        super();
        this.initializeData(warehouseObj, this);
    }
}

export const warehouseObjectMock = {
    id: 1,
    division_id: 1,
    name: 'Warehouse 1'
};
