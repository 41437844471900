import { BaseModel } from './../base/base.model';
import { ProjectSet } from './../project-sets/project-set.model';

export class Set extends BaseModel {
    static modelRelatedIdName = 'set_id';
    static relatedFieldString = 'projectSets';

    id: number;
    name: string = null;
    franchise_id: number = null;
    projectSets?: ProjectSet[]; // not maintained

    constructor(setObj) {
        super();
        this.initializeData(setObj, this);
        if (setObj.projectSets) {
            this.projectSets = setObj.projectSets.map(ps => new ProjectSet(ps));
        }
    }

    getNameWithCode() {
        if (this.projectSets[0] && this.projectSets[0].code) {
            return `${this.projectSets[0].code} ${this.name}`;
        } else {
            return this.name;
        }
    }
}
