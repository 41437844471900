import { createSelector } from 'reselect';
import * as assetActions from '../assets/asset.actions';
import * as divisionGroupActions from '../division-groups/division-group.actions';
import * as franchiseGroupActions from '../franchise-groups/franchise-group.actions';
import { Group } from '../models';
import { ActionStatus } from '../types';
import { getEntitiesObject, getIdsAndEntities, uniqueArrayMerge } from '../utils';
import * as groupActions from './group.actions';
export interface State {
    ids: number[];
    entities: {
        [groupId: number]: Group
    };
    groupIdsForFranchiseId: {
        [franchiseId: number]: number[]
    };
    groupIdsForDivisionId: {
        [divisionId: number]: number[]
    };
    groupIdsForAsset: {
        [assetId: number]: number[];
    };
    assetCountForGroupId: {
        [groupId: number]: number;
    };
    loading: ActionStatus;
    saving: ActionStatus;
    adding: ActionStatus;
    selectedId: number;
}

export const initialState: State = {
    ids: [],
    entities: {},
    groupIdsForFranchiseId: {},
    groupIdsForDivisionId: {},
    groupIdsForAsset: {},
    assetCountForGroupId: {},
    loading: ActionStatus.Inactive,
    saving: ActionStatus.Inactive,
    adding: ActionStatus.Inactive,
    selectedId: null
};

export function reducer(state = initialState, action: groupActions.Actions | franchiseGroupActions.Actions | divisionGroupActions.Actions | assetActions.Actions): State {
    switch (action.type) {

        case groupActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }
            return newState;
        }
        case groupActions.ADD_IN_FRANCHISE_COMPLETE: {
            const group = action.payload.group;
            const { ids, entities } = getIdsAndEntities(state, [group]);
            const allEnts = { ...state.entities, ...entities };
            const allIds = [...state.ids, ...ids];
            const groupIdsForFranchiseId = { ...state.groupIdsForFranchiseId };
            if (action.payload.franchiseGroup && action.payload.franchiseGroup.franchise_id) {
                const franchiseGroup = action.payload.franchiseGroup;
                groupIdsForFranchiseId[franchiseGroup.franchise_id] = uniqueArrayMerge(groupIdsForFranchiseId[franchiseGroup.franchise_id], [group.id]);
            }

            return {
                ...state,
                entities: allEnts,
                ids: allIds,
                groupIdsForFranchiseId,
                adding: ActionStatus.Complete
            };
        }

        case groupActions.ADD_IN_DIVISION_COMPLETE: {
            const group = action.payload.group;
            const { ids, entities } = getIdsAndEntities(state, [group]);
            const allEnts = { ...state.entities, ...entities };
            const allIds = [...state.ids, ...ids];
            const groupIdsForDivisionId = { ...state.groupIdsForDivisionId };
            if (action.payload.divisionGroup && action.payload.divisionGroup.division_id) {
                const divisionGroup = action.payload.divisionGroup;
                groupIdsForDivisionId[divisionGroup.division_id] = uniqueArrayMerge(groupIdsForDivisionId[divisionGroup.division_id], [group.id]);
            }

            return {
                ...state,
                entities: allEnts,
                ids: allIds,
                groupIdsForDivisionId,
                adding: ActionStatus.Complete
            };
        }

        case groupActions.ADD_IN_STUDIO_COMPLETE: {
            const group = action.payload.group;
            const { ids, entities } = getIdsAndEntities(state, [group]);
            const allEnts = { ...state.entities, ...entities };
            const allIds = [...state.ids, ...ids];

            return {
                ...state,
                entities: allEnts,
                ids: allIds,
                adding: ActionStatus.Complete
            };
        }

        case groupActions.ADD_IN_DIVISION:
        case groupActions.ADD_IN_FRANCHISE:
        case groupActions.ADD_IN_STUDIO: {
            return { ...state, adding: ActionStatus.Loading };
        }

        case groupActions.GET_COMPLETE: {
            const newGroup = action.payload.group;
            const groupObj = getEntitiesObject([newGroup]);

            return {
                ...state,
                ids: uniqueArrayMerge(state.ids, [newGroup.id]),
                entities: {
                    ...state.entities,
                    ...groupObj,
                }
            };
        }

        case groupActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case groupActions.LIST_BY_FRANCHISE:
        case groupActions.LIST_BY_DIVISION:
        case groupActions.LIST:
        case groupActions.GET: {
            return { ...state, loading: ActionStatus.Loading };
        }

        case groupActions.LIST_COMPLETE: {
            const groups = action.payload.groups;
            const { ids, entities } = getIdsAndEntities(state, groups);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities,
                loading: ActionStatus.Complete,
                saving: ActionStatus.Inactive
            };
        }

        case groupActions.LIST_BY_STUDIO_GROUP_TYPE_COMPLETE: {
            const groups = action.payload.groups;
            const { allIds, entities } = getIdsAndEntities(state, groups);

            return {
                ...state,
                ids: allIds,
                entities,
                loading: ActionStatus.Complete,
                saving: ActionStatus.Inactive
            };
        }

        case groupActions.LIST_BY_FRANCHISE_COMPLETE: {
            const groups = action.payload.groups;
            const { ids, entities } = getIdsAndEntities(state, groups);
            const groupIdsForFranchiseId = { ...state.groupIdsForFranchiseId };
            const groupIds = action.payload.groups.map(g => g.id);
            groupIdsForFranchiseId[action.payload.franchiseId] = groupIds;

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities },
                groupIdsForFranchiseId,
                loading: ActionStatus.Complete
            };
        }

        case groupActions.LIST_BY_DIVISION_COMPLETE: {
            const groups = action.payload.groups;
            const { ids, entities } = getIdsAndEntities(state, groups);
            const groupIdsForDivisionId = { ...state.groupIdsForDivisionId };
            const groupIds = action.payload.groups.map(g => g.id);
            groupIdsForDivisionId[action.payload.divisionId] = groupIds;

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities },
                groupIdsForDivisionId,
                loading: ActionStatus.Complete
            };
        }

        case groupActions.UPDATE_COMPLETE: {
            const group = action.payload;
            const entities = getEntitiesObject([group]);
            const allEnts = { ...state.entities, ...entities };
            return {
                ...state,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case franchiseGroupActions.DELETE_FROM_FRANCHISE_COMPLETE: {
            const { groupIdsForFranchiseId, groupIdsForAsset } = updateAfterDelete(action.payload, state);
            return {
                ...state,
                groupIdsForFranchiseId,
                groupIdsForAsset
            };
        }

        case groupActions.DELETE_FROM_STUDIO_COMPLETE: {
            const id = action.payload;
            let sortIds = [...state.ids];
            const entitiesCopy = { ...state.entities };
            sortIds = sortIds.filter(sId => sId != id);
            delete entitiesCopy[id];

            return {
                ...state,
                saving: ActionStatus.Complete,
                entities: entitiesCopy,
                ids: sortIds
            };
        }

        case divisionGroupActions.DELETE_FROM_DIVISION_COMPLETE: {
            const { groupIdsForDivisionId, groupIdsForAsset } = updateAfterDelete(action.payload, state);

            return {
                ...state,
                groupIdsForDivisionId,
                groupIdsForAsset
            };
        }

        case groupActions.CONVERT_GROUP_TO_SUBGROUP_COMPLETE: {
            const { groupId, subGroup } = action.payload;
            let sortIds = [...state.ids];
            let entitiesCopy = { ...state.entities };
            sortIds = sortIds.filter(sId => sId != groupId);
            const groupIdsForFranchiseId = { ...state.groupIdsForFranchiseId };
            const franchiseKeys = Object.keys(groupIdsForFranchiseId);
            for (const key of franchiseKeys) {
                groupIdsForFranchiseId[key] = groupIdsForFranchiseId[key].filter(id => id !== groupId)
            }
            const groupIdsForDivisionId = { ...state.groupIdsForDivisionId };

            const divisionKeys = Object.keys(groupIdsForDivisionId);
            for (const key of divisionKeys) {
                groupIdsForDivisionId[key] = groupIdsForDivisionId[key].filter(id => id !== groupId)
            }

            delete entitiesCopy[groupId];
            let newGroup = new Group({ ...entitiesCopy[subGroup.group_id], subGroupAttachments: subGroup.attachments, subGroups: [...entitiesCopy[subGroup.group_id].subGroups, subGroup] });

            entitiesCopy = { ...entitiesCopy, [newGroup.id]: newGroup };

            return {
                ...state,
                groupIdsForFranchiseId,
                groupIdsForDivisionId,
                adding: ActionStatus.Complete,
                entities: entitiesCopy,
                ids: sortIds
            };
        }

        case groupActions.CONVERT_SUBGROUP_TO_GROUP_COMPLETE: {
            const { subgroup_id, group, group_id } = action.payload;
            let sortIds = [...state.ids, group.id];
            let entitiesCopy = { ...state.entities };
            const subGroupDeletedGroup = new Group({ ...entitiesCopy[group_id], subGroups: entitiesCopy[group_id].subGroups.filter(sg => sg.id !== subgroup_id) });
            entitiesCopy = { ...entitiesCopy, [subGroupDeletedGroup.id]: subGroupDeletedGroup, [group.id]: group }

            const groupIdsForFranchiseId = { ...state.groupIdsForFranchiseId };
            const franchiseKeys = Object.keys(groupIdsForFranchiseId);
            for (const key of franchiseKeys) {
                groupIdsForFranchiseId[key] = [...groupIdsForFranchiseId[key], group.id]
            }
            const groupIdsForDivisionId = { ...state.groupIdsForDivisionId };

            const divisionKeys = Object.keys(groupIdsForDivisionId);
            for (const key of divisionKeys) {
                groupIdsForDivisionId[key] = [...groupIdsForDivisionId[key], group.id]
            }

            return {
                ...state,
                groupIdsForFranchiseId,
                groupIdsForDivisionId,
                saving: ActionStatus.Complete,
                adding: ActionStatus.Complete,
                entities: entitiesCopy,
                ids: sortIds
            };
        }
        case assetActions.ADD_COMPLETE:
        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const assets = action.payload.assets;

            const groupIdsForAsset = { ...state.groupIdsForAsset };
            const groupIdsForDivisionId = { ...state.groupIdsForDivisionId };
            const groupIdsForFranchiseId = { ...state.groupIdsForFranchiseId };
            assets.forEach(asset => {
                if (asset.group_ids && asset.group_ids.length) {
                    groupIdsForAsset[asset.id] = asset.group_ids;
                    if (asset.division_id) {
                        groupIdsForDivisionId[asset.division_id] = uniqueArrayMerge(groupIdsForDivisionId[asset.division_id], asset.group_ids);
                    } else {
                        groupIdsForFranchiseId[asset.franchise_id] = uniqueArrayMerge(groupIdsForFranchiseId[asset.franchise_id], asset.group_ids);
                    }
                }
            });

            return {
                ...state,
                groupIdsForAsset,
                groupIdsForDivisionId,
                groupIdsForFranchiseId
            };
        }

        case groupActions.GET_ASSET_COUNT_COMPLETE: {
            const assetCount = action.payload.assetCount;
            const groupId = action.payload.groupId;
            const assetCountForGroupId = { ...state.assetCountForGroupId };
            assetCountForGroupId[groupId] = assetCount;

            return {
                ...state,
                assetCountForGroupId
            };
        }

        case groupActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case groupActions.ADD_IN_FRANCHISE_FAILED:
        case groupActions.ADD_IN_DIVISION_FAILED:
        case groupActions.ADD_IN_STUDIO_FAILED: {
            return { ...state, adding: ActionStatus.Failed };
        }

        case groupActions.GET_ASSET_COUNT_FAILED:
        case groupActions.LIST_FAILED:
        case groupActions.LIST_BY_STUDIO_GROUP_TYPE_FAILED:
        case groupActions.LIST_BY_FRANCHISE_FAILED:
        case groupActions.LIST_BY_DIVISION_FAILED:
        case groupActions.CONVERT_GROUP_TO_SUBGROUP_FAILED:
        case groupActions.CONVERT_SUBGROUP_TO_GROUP_FAILED: {
            return { ...state, loading: ActionStatus.Failed, adding: ActionStatus.Complete };
        }

        case groupActions.RESET_ADDING: {
            return { ...state, adding: ActionStatus.Inactive, saving: ActionStatus.Inactive };
        }

        default: {
            return state;
        }
    }
}

function updateAfterDelete(payload, state) {
    const groupId = payload.groupId;
    const franchiseId = payload.franchiseId;
    const divisionId = payload.divisionId;
    const assetIds = payload.assetIds;
    const groupIdsForFranchiseId = { ...state.groupIdsForFranchiseId };
    const groupIdsForDivisionId = { ...state.groupIdsForDivisionId };
    const groupIdsForAsset = { ...state.groupIdsForAsset };
    assetIds.forEach(assetId => {
        if (groupIdsForAsset[assetId]) {
            groupIdsForAsset[assetId] = groupIdsForAsset[assetId].filter(id => id !== groupId);
        }
    });
    if (franchiseId) {
        groupIdsForFranchiseId[franchiseId] = groupIdsForFranchiseId[franchiseId].filter(id => id !== groupId);
    } else if (divisionId) {
        groupIdsForDivisionId[divisionId] = groupIdsForDivisionId[divisionId].filter(id => id !== groupId);
    }
    return { groupIdsForFranchiseId, groupIdsForDivisionId, groupIdsForAsset };
}

export const getIds = (state: State) => state.ids;
export const getEntities = (state: State) => state.entities;
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getAdding = (state: State) => state.adding;
export const getGroupIdsForFranchiseId = (state: State) => state.groupIdsForFranchiseId;
export const getGroupIdsForDivisionId = (state: State) => state.groupIdsForDivisionId;
export const getGroupIdsForAsset = (state: State) => state.groupIdsForAsset;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => {
    return entities[id];
}));
export const getAssetCountForGroupId = (state: State) => state.assetCountForGroupId;
export const getSelectedId = (state: State) => state.selectedId;
