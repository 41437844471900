import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import { DepartmentField } from './department-field.model';
import * as departmentActions from './department.actions';
import { Department } from './department.model';

@Injectable()
export class DepartmentEffects {

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.ADD),
            map((action: departmentActions.AddAction) => action.payload),
            switchMap((payload) => this.http.post<Department[]>(`/api/studios/${payload.studioId}/departments`, payload.departments).pipe(
                map(res => {
                    const response = res;
                    return response.map((f) => new Department(f));
                }),
                map((newDepartments: Department[]) => new departmentActions.AddCompleteAction(newDepartments)),
                catchError((error) => observableOf(new departmentActions.AddFailedAction({ error, studioId: payload.studioId }))))
            )));

     addDepartmentField$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.ADD_DEPARTMENT_FIELD),
            map((action: departmentActions.AddDepartmentFieldAction) => action.payload),
            switchMap((deptField) => this.http.post(`/api/department-fields`, deptField).pipe(
                map(res => new DepartmentField(res)),
                map(newDeptField => new departmentActions.AddDepartmentFieldCompleteAction(newDeptField)),
                catchError((error) => observableOf(new departmentActions.AddDepartmentFieldFailedAction({ error }))))
            )));

     deleteDepartmentField$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.DELETE_DEPARTMENT_FIELD),
            map((action: departmentActions.DeleteDepartmentFieldAction) => action.payload),
            switchMap((deptField) => this.http.delete(`/api/department-fields/${deptField.id}`).pipe(
                map(() => new departmentActions.DeleteDepartmentFieldCompleteAction(deptField)),
                catchError((error) => observableOf(new departmentActions.DeleteDepartmentFieldFailedAction({ error }))))
            )));

     updateDepartmentField$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.UPDATE_DEPARTMENT_FIELD),
            map((action: departmentActions.UpdateDepartmentFieldAction) => action.payload),
            switchMap((deptField) => this.http.put(`/api/department-fields/${deptField.id}`, deptField).pipe(
                map(res => new DepartmentField(res)),
                map(newDeptField => new departmentActions.UpdateDepartmentFieldCompleteAction(newDeptField)),
                catchError((error) => observableOf(new departmentActions.UpdateDepartmentFieldFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.UPDATE),
            map((action: departmentActions.UpdateAction) => action.payload),
            switchMap((departmentData) => this.http.put(`/api/departments/${departmentData.id}`, departmentData).pipe(
                map(res => new Department(res)),
                map((newDepartment: Department) => new departmentActions.UpdateCompleteAction(newDepartment)),
                catchError((error) => observableOf(new departmentActions.UpdateFailedAction({ error, studioId: departmentData.studio_id }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.LIST),
            map((action: departmentActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<Department[]>(`/api/studios/${studioId}/departments`).pipe(
                map(res => {
                    const response = res;
                    return response.map((f) => new Department(f));
                }),
                map((departments: Department[]) => new departmentActions.ListCompleteAction({ departments, studioId })),
                catchError((error) => observableOf(new departmentActions.ListFailedAction({ error, studioId }))))
            )));

     listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.LIST_BY_FRANCHISE),
            map((action: departmentActions.ListByFranchiseAction) => action.payload),
            switchMap((franchiseId) => this.http.get<Department[]>(`/api/franchises/${franchiseId}/departments`).pipe(
                map(res => {
                    const response = res;
                    return response.map((f) => new Department(f));
                }),
                map((departments: Department[]) => new departmentActions.ListByFranchiseCompleteAction(departments)),
                catchError((error) => observableOf(new departmentActions.ListByFranchiseFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(departmentActions.ADD_FAILED, departmentActions.ADD_DEPARTMENT_FIELD_FAILED, departmentActions.DELETE_DEPARTMENT_FIELD_FAILED, departmentActions.UPDATE_DEPARTMENT_FIELD_FAILED, departmentActions.UPDATE_FAILED, departmentActions.LIST_FAILED, departmentActions.LIST_BY_FRANCHISE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
