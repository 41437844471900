import baseEnvironment from './base';

let sosUrl = "https://www.synconset.com"
let intercomAppId = "twidi4l3";

if (window.location.href.indexOf('hound.sosv2.net') > -1) {
    sosUrl = 'https://dev.sosv2.net';
    intercomAppId = 'atuozw3k';
}
else if (window.location.href.indexOf('assets-qa.sosv2.net') > -1) {
    sosUrl = 'https://qa.sosv2.net';
    intercomAppId = 'atuozw3k';
}
else if (window.location.href.indexOf('assets.sosv2.net') > -1) {
    sosUrl = 'https://staging.sosv2.net';
    intercomAppId = 'atuozw3k';
}
else if (window.location.href.indexOf('assets.synconset.com') > -1) {
    sosUrl = 'https://www.synconset.com';
    intercomAppId = 'twidi4l3';
}
else if (window.location.href.indexOf('https://assethub-dev.ep.com') > -1) {
    sosUrl = 'https://crewtools-dev.ep.com';
    intercomAppId = 'atuozw3k';
}
else if (window.location.href.indexOf('https://assethub-qa.ep.com') > -1) {
    sosUrl = 'https://crewtools-qa.ep.com';
    intercomAppId = 'atuozw3k';
}
else if (window.location.href.indexOf('https://assethub-uat.ep.com') > -1) {
    sosUrl = 'https://crewtools-uat.ep.com';
    intercomAppId = 'atuozw3k';
}

export const environment = Object.assign(baseEnvironment, {
    production: true,
    sosUrl: sosUrl,
    intercomAppId: intercomAppId
});
