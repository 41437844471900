import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Character } from './character.model';

export const ADD = '[Character] Add';
export const ADD_COMPLETE = '[Character] Add Complete';
export const ADD_FAILED = '[Character] Add Failed';

export const DELETE = '[Character] Delete';
export const DELETE_COMPLETE = '[Character] Delete Complete';
export const DELETE_FAILED = '[Character] Delete Failed';

export const LIST_BY_FRANCHISE = '[Character] List By Franchise';
export const LIST_BY_PROJECT = '[Character] List By Project';
export const LIST_BY_FRANCHISE_COMPLETE = '[Character] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Character] List By Franchise Failed';

export const UPDATE = '[Character] Update';
export const UPDATE_COMPLETE = '[Character] Update Complete';
export const UPDATE_FAILED = '[Character] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: Character) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Character) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: Character) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Character) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByProjectAction implements Action {
    readonly type = LIST_BY_PROJECT;

    constructor(public payload: any) { }
}
export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: number) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: Character[]) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Character) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Character) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListByFranchiseAction
    | ListByProjectAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
