import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import * as layoutActions from '../layout/layout.actions';
import * as studioActions from './studio.actions';
import { Studio } from './studio.model';

@Injectable()
export class StudioEffects {

    constructor(private actions$: Actions, private http: HttpClient, private localStorageService: LocalStorageService) {
    }

    get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.GET),
            map((action: studioActions.GetAction) => action.payload),
            switchMap((payload) => this.http.get(`/api/studios/${payload}`).pipe(
                map(res => new Studio(res)),
                map(studioModel => new studioActions.GetCompleteAction(studioModel)),
                catchError((error) => observableOf(new studioActions.GetFailedAction({ error }))))
            )));

    getFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.GET_FAILED),
            map((action: studioActions.GetFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    select$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.SELECT),
            map((action: studioActions.SelectAction) => action.payload),
            switchMap((payload) => {
                const selectedStudioId = JSON.stringify(payload);
                if (!localStorage.getItem('selectedStudioId')) {
                    this.localStorageService.updateSettings('selectedStudioId', selectedStudioId);
                } else if (selectedStudioId !== localStorage.getItem('selectedStudioId')) {
                    const dark = localStorage.getItem('toolbar.dark');
                    localStorage.clear();
                    sessionStorage.clear();
                    if (dark) {
                        this.localStorageService.updateSettings('toolbar.dark', dark);
                    }
                }
                return observableOf(new studioActions.SelectCompleteAction());
            })));

    listByUser$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.LIST_BY_USER),
            map((action: studioActions.ListByUserAction) => action.payload),
            switchMap((userId) => {
                return this.http.get<Studio[]>(`/api/users/${userId}/studios`)
                    .pipe(
                        map(res => res.map(studioObj => new Studio(studioObj))),
                        map(studioModels => new studioActions.ListByUserCompleteAction(studioModels)),
                        catchError((error) => observableOf(new studioActions.ListByUserFailedAction({ error, userId }))))
            }
            )));

    listByUserFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.LIST_BY_USER_FAILED),
            map((action: studioActions.ListByUserFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));



    updateStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.UPDATE_STUDIO),
            map((action: studioActions.UpdateStudioAction) => action.payload),
            switchMap((payload) => this.http.put<Studio>(`/api/studios/${payload.studioId}`, { name: payload.name, read_only: payload.isStudioReadOnly }).pipe(
                map(studio => new studioActions.UpadteStudioCompleteAction(new Studio(studio))),
                catchError((error) => observableOf(new studioActions.UpdateStudioFailedAction({ error })))
            ))
        ))

    updateStudioFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(studioActions.UPDATE_STUDIO_FAILED),
            map((action: studioActions.UpdateStudioFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));
}
