import { createSelector } from 'reselect';
import * as root from '../';
import { MatchField } from './match-field.model';

export const getWithField = createSelector(root.getMatchFields, root.getFields, (matchFields, fields) => {
    if (!matchFields.length || !fields.length) {
        return [];
    }

    return matchFields.map((matchField => {
        return new MatchField(Object.assign({}, matchField, {
            field: fields.find((field) => field.id === matchField.field_id)
        }));
    })).sort((a, b) => {
        return a.field.sort_order - b.field.sort_order;
    });
});
