import { Action } from '@ngrx/store';
import { SortObject } from '../../../../../shared/types';
import { Request, RequestAsset, RequestNote } from '../models';
import { FinalApproverRequest } from './final-approver-request.model';

export const ALERT_FINAL_APPROVER = '[Final Approver Request] Alert Final  Approver';
export const ALERT_FINAL_APPROVER_COMPLETE = '[Final Approver Request] Alert  Final Approver Complete';
export const ALERT_FINAL_APPROVER_FAILED = '[Final Approver Request] Alert Final  Approver Failed';

export const CREATE = '[Final Approver Request] Create';
export const CREATE_COMPLETE = '[Final Approver Request] Create Complete';
export const CREATE_FAILED = '[Final Approver Request] Create Failed';

export const SORT = '[Final Approver Request] Sort';

export const UPDATE = '[Final Approver Request] Update';
export const UPDATE_COMPLETE = '[Final Approver Request] Update Complete';
export const UPDATE_FAILED = '[Final Approver Request] Update Failed';

export class AlertFinalApproverAction implements Action {
    readonly type = ALERT_FINAL_APPROVER;

    constructor(public payload: { final_approver_request_id: number }) { }
}

export class AlertFinalApproverCompleteAction implements Action {
    readonly type = ALERT_FINAL_APPROVER_COMPLETE;

    constructor() { }
}

export class AlertFinalApproverFailedAction implements Action {
    readonly type = ALERT_FINAL_APPROVER_FAILED;

    constructor(public payload: any) { }
}

export class CreateAction implements Action {
    readonly type = CREATE;

    constructor(public payload: { request_id: number, note: string, userIds: number[], assetIds: number[] }) { }
}

export class CreateCompleteAction implements Action {
    readonly type = CREATE_COMPLETE;

    constructor(public payload: { request: Request, requestNote: RequestNote, requestAssets: RequestAsset[] }) { }
}

export class CreateFailedAction implements Action {
    readonly type = CREATE_FAILED;

    constructor(public payload: any) { }
}

export class SortAction implements Action {
    readonly type = SORT;

    constructor(public payload: SortObject) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { studio_id: number, final_approver_request_id: number, note: string }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { finalApproverRequest: FinalApproverRequest, request: Request, requestNote: RequestNote }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = AlertFinalApproverAction
    | AlertFinalApproverCompleteAction
    | AlertFinalApproverFailedAction
    | CreateAction
    | CreateCompleteAction
    | CreateFailedAction
    | SortAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
