import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Asset, Group } from './../../../core/store/models';

export function getSelectedGroups(franchiseGroups$: Observable<Group[]>, divisionGroups$: Observable<Group[]>, isDivision: boolean, asset: Asset, isFranchise: boolean, allGroups$: Observable<Group[]>) {
    let groups$;
    if (isDivision) {
        groups$ = divisionGroups$;
    } else if (isFranchise) {
        groups$ = franchiseGroups$;
    } else {
        groups$ = allGroups$;
    }
    const selectedGroups = [];
    groups$.pipe(take(1)).subscribe(groups => {
        asset.group_ids.forEach(groupId => {
            const selectedGroup = groups.find(g => g.id === groupId);
            if (selectedGroup) {
                selectedGroups.push(selectedGroup.name);
            }
        });
    });
    return selectedGroups;
}

export function getSelectedStudioGroupIds(allGroups$: Observable<Group[]>, selectedGroups: string[]) {
    return [...getSelectedGroupsIdsFromObs(allGroups$, selectedGroups)];
}

export function getSelectedGroupIds(divisionGroups$: Observable<Group[]>, notDivisionGroups$: Observable<Group[]>, franchiseGroups$: Observable<Group[]>, notFranchiseGroups$: Observable<Group[]>, isDivisionAsset: boolean, isFranchiseAsset: boolean, selectedGroups: string[], allGroups$: Observable<Group[]>) {
    if (isDivisionAsset) {
        return [...getSelectedGroupsIdsFromObs(divisionGroups$, selectedGroups), ...getSelectedGroupsIdsFromObs(notDivisionGroups$, selectedGroups)];
    } else if (isFranchiseAsset) {
        return [...getSelectedGroupsIdsFromObs(franchiseGroups$, selectedGroups), ...getSelectedGroupsIdsFromObs(notFranchiseGroups$, selectedGroups)];
    } else {
        return [...getSelectedGroupsIdsFromObs(allGroups$, selectedGroups)];
    }
}

function getSelectedGroupsIdsFromObs(groups$: Observable<Group[]>, selectedGroups: string[]) {
    const selectedGroupIds = [];
    groups$.pipe(take(1)).subscribe(groups => {
        selectedGroups.forEach(selectedGroup => {
            const foundSelectedGroup = groups.find(g => g.name === selectedGroup);
            if (foundSelectedGroup) {
                selectedGroupIds.push(foundSelectedGroup.id);
            }
        });
    });
    return selectedGroupIds;
}

export function getSelectedSubGroupIds(divisionGroups$: Observable<Group[]>, notDivisionGroups$: Observable<Group[]>, franchiseGroups$: Observable<Group[]>, notFranchiseGroups$: Observable<Group[]>, isDivisionAsset: boolean, isFranchiseAsset: boolean, selectedSubGroups: string[], allGroups$: Observable<Group[]>) {
    if (isDivisionAsset) {
        return [...getSelectedSubGroupsIdsFromObjects(divisionGroups$, selectedSubGroups), ...getSelectedSubGroupsIdsFromObjects(notDivisionGroups$, selectedSubGroups)];
    } else if (isFranchiseAsset) {
        return [...getSelectedSubGroupsIdsFromObjects(franchiseGroups$, selectedSubGroups), ...getSelectedSubGroupsIdsFromObjects(notFranchiseGroups$, selectedSubGroups)];
    } else {
        return [...getSelectedSubGroupsIdsFromObjects(allGroups$, selectedSubGroups)];
    }
}

const getSelectedSubGroupsIdsFromObjects = (groups$: Observable<Group[]>, selectedSubGroups: string[]) => {
    const selectedGroupIds = [];
    groups$.pipe(take(1)).subscribe(groups => {
        selectedSubGroups.forEach(selectedGroup => {
            groups.forEach((group) => {
                const foundSelectedSubGroup = group.subGroups.find(sg => `${group.name} - ${sg.name}` === selectedGroup);
                if (foundSelectedSubGroup) {
                    selectedGroupIds.push(foundSelectedSubGroup.id);
                }
            })
        });
    });
    return selectedGroupIds;
}