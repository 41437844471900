export class BaseJsonModel {
    constructor() { }

    initializeData(initData) {
        for (const key in initData) {
            if (initData.hasOwnProperty(key)) {
                if (key === 'attributes') {
                    for (const match in initData.attributes) {
                        this[match] = initData.attributes[match];
                    }
                } else {
                    this[key] = initData[key];
                }
            }
        }
    }
}
