import { Action } from '@ngrx/store';
import { AssetHistory } from './asset-history.model';

export const HISTORY_LOAD_COMPLETE = '[AssetHistory] History Load Complete';

export const SORT_EVENTS_LIST = '[AssetHistory] Sort Events List';

export const GET = '[Asset History] Get History';
export const GET_FAILED = '[Asset History] Get History Failed';
export const GET_COMPLETE = '[Asset History] Get History Complete';

export const UPDATE = '[AssetHistory] Update';
export const UPDATE_COMPLETE = '[AssetHistory] Update Complete';
export const UPDATE_FAILED = '[AssetHistory] Update Failed';

export class SortEventsAction implements Action {
    readonly type = SORT_EVENTS_LIST;

    constructor(public payload: {
        field: string,
        order: number,
        args: any[],
    }) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: { asset_id: number }) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: { assetHistory: AssetHistory[] }) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: any) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { assetId: number, assetHistoryId: number, note: string }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { assetHistory: AssetHistory }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = SortEventsAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
