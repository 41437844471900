import { createSelector } from 'reselect';
import * as root from '../';
import * as bulkMoveModalSelectors from '../bulk-move-modal/bulk-move-modal.selectors';

export const getForAssetModal = createSelector(root.getAssetModalSelectedProjectId, root.getProjectEntities, (id, projectMap) => projectMap[id]);

export const getForAssetModalSelectedFranchise = createSelector(root.getAssetModalSelectedFranchiseId, root.getAllProjects, (selectedFranchiseId, projects) => {
    return projects.filter(project => {
        return project.franchise_id === selectedFranchiseId;
    });
});

export const getForBulkMoveModal = createSelector(bulkMoveModalSelectors.getBulkMoveModalSelectedProjectId, root.getProjectEntities, (id, projectMap) => projectMap[id]);

export const getForBulkMoveModalSelectedFranchise = createSelector(bulkMoveModalSelectors.getBulkMoveModalSelectedFranchiseId, root.getAllProjects, (selectedFranchiseId, projects) => {
    return projects.filter(project => {
        return project.franchise_id === selectedFranchiseId;
    });
});
