import { createSelector } from 'reselect';
import * as root from '../';
import { RequestNoteTypes } from '../../../../../shared/enums/request-note-types.enum';
import { RequestStatus } from '../../../../../shared/enums/request-status.enum';
import { RequestTypes } from '../../../../../shared/enums/request-types.enum';
import * as fromFinalApproverRequest from '../final-approver-requests/final-approver-request.reducer';
import { RequestNote } from '../models';
import { FinalApproverRequestAndRequestAndNotes, RequestAndNotes, SortObject } from '../types';
import { User } from '../users/user.model';
import { copyAndMultiSort } from '../utils';
import { Request } from './request.model';
import * as fromRequest from './request.reducer';

export const getRequestState = (state: root.State) => state.request;

export const getIds = createSelector(getRequestState, fromRequest.getIds);
export const getAlertApproverSaving = createSelector(getRequestState, fromRequest.getAlertApproverSaving);
export const getFilter = createSelector(getRequestState, fromRequest.getFilter);
export const getRequestEntities = createSelector(getRequestState, fromRequest.getEntities);
export const getRequestGetting = createSelector(getRequestState, fromRequest.getGetting);
export const getRequestLoading = createSelector(getRequestState, fromRequest.getLoading);
export const getRequestSaving = createSelector(getRequestState, fromRequest.getSaving);
export const getApprovalRequestsLoading = createSelector(getRequestState, fromRequest.getApprovalRequestsLoading);
export const getFaRequestsLoading = createSelector(getRequestState, fromRequest.getFaRequestsLoading);
export const getUnsentRequestsLoading = createSelector(getRequestState, fromRequest.getUnsentRequestsLoading);

export const getAllRequests = createSelector(getRequestState, fromRequest.getAll);
export const getNotesForRequestId = createSelector(getRequestState, fromRequest.getNotesForRequestId);
export const getAllFinalApproverRequests = createSelector(root.getFinalApproverRequestState, fromFinalApproverRequest.getAll);
export const getFinalApproverRequestEntities = createSelector(root.getFinalApproverRequestState, fromFinalApproverRequest.getEntities);
export const getFinalApproverRequestIds = createSelector(root.getFinalApproverRequestState, fromFinalApproverRequest.getIds);

export const getSelectedRequestId = createSelector(getRequestState, fromRequest.getSelectedId);
export const getSelectedRequest = createSelector(getRequestEntities, getSelectedRequestId, (entities, selectedId) => {
    return selectedId ? entities[selectedId] : null;
});

export const getUnsentRequests = createSelector(getAllRequests, getNotesForRequestId, (requests, requestNotes) => {
    const unsentRequests = requests.filter(request => request.status === RequestStatus.Unsent);
    return getFilteredRequestsAndNotes(unsentRequests, requestNotes);
});

export const getUnsentRequestsDropdownOptions = createSelector(getUnsentRequests, (unsentRequests) => {
    const requests = unsentRequests.map(unsentRequest => unsentRequest.request);
    return requests.map(request => {
        return {
            value: request.id,
            label: request.getIdAndName()
        };
    });
});

export const getMyRequests = createSelector(root.getUser, getAllRequests, getNotesForRequestId, getFilter, root.getUserEntities, (user, requests, requestNotes, filterObj, userEnts) => {
    if (user && !user.isApprover(user.current_studio_id)) {
        const myRequests = requests.filter(request => request.created_by === user.id);
        if (filterObj && filterObj.requestIndexType.value === RequestTypes.Requester) {
            return getFilteredRequestsAndNotes(myRequests, requestNotes, filterObj, userEnts);
        } else {
            return getFilteredRequestsAndNotes(myRequests, requestNotes, null, userEnts);
        }
    } else if (user && user.isApprover(user.current_studio_id)) {
        const myRequests = requests.filter(request => request.created_by === user.id && request.status === RequestStatus.Unsent);
        if (filterObj && filterObj.requestIndexType.value === RequestTypes.Requester) {
            return getFilteredRequestsAndNotes(myRequests, requestNotes, filterObj, userEnts);
        } else {
            return getFilteredRequestsAndNotes(myRequests, requestNotes, null, userEnts);
        }
    }
    return [];
});

export const getApproverRequests = createSelector(root.getUser, getAllRequests, getNotesForRequestId, getFilter, root.getUserEntities, (user, requests, requestNotes, filterObj, userEnts) => {
    if (user && user.isApprover(user.current_studio_id)) {
        const approverRequests = requests.filter(request => request.status !== RequestStatus.Unsent);
        if (filterObj && filterObj.requestIndexType.value === RequestTypes.Approver) {
            return getFilteredRequestsAndNotes(approverRequests, requestNotes, filterObj, userEnts);
        } else {
            return getFilteredRequestsAndNotes(approverRequests, requestNotes, null, userEnts);
        }
    }
    return [];
});

export const getFinalApproverRequests = createSelector(getAllFinalApproverRequests, getRequestEntities, getNotesForRequestId, getFilter, root.getUserEntities, root.getFinalApproverRequestSort, getFinalApproverRequestEntities, getFinalApproverRequestIds, (finalApproverRequests, requestEnts, requestNotes, filter, userEnts, sortData: SortObject, faRequestEnts, faRequestIds: number[]) => {
    let requestIds: number[];

    if (sortData) {
        requestIds = finalApproverRequests.map(far => far.request_id);

        if (sortData.field === 'note') {
            const requesterNotes = requestIds.map(requestId => requestNotes[requestId].find(note => note.note_type === RequestNoteTypes.Requester));
            let requesterNoteIds = requesterNotes.map(reqNote => reqNote.id);
            const requesterNoteEnts = {};

            requesterNotes.forEach(reqNote => {
                requesterNoteEnts[reqNote.id] = reqNote;
            });
            requesterNoteIds = copyAndMultiSort(requesterNoteIds, requesterNoteEnts, [{ key: sortData.field }, { key: 'id' }], sortData.order);
            requestIds = requesterNoteIds.map(requestNoteId => requesterNoteEnts[requestNoteId].request_id);
            faRequestIds = requestIds.map(id => finalApproverRequests.find(far => far.request_id === id).id);
        } else if (sortData.field === 'final_request_created_by') {
            faRequestIds = copyAndMultiSort(faRequestIds, faRequestEnts, [{ key: 'displayCreatedByName' }], sortData.order, [userEnts]);
        } else {
            requestIds = copyAndMultiSort(requestIds, requestEnts, [{ key: sortData.field }], sortData.order);
            faRequestIds = requestIds.map(id => finalApproverRequests.find(far => far.request_id === id).id);
        }
    }

    if (finalApproverRequests && finalApproverRequests.length) {
        const farAndRequestsAndRequestNotes = [];

        faRequestIds.forEach(id => {
            const request = requestEnts[faRequestEnts[id].request_id];
            if (request) {
                const farRequestNotes = requestNotes && requestNotes[request.id] && requestNotes[request.id].length ? requestNotes[request.id] : [];
                farAndRequestsAndRequestNotes.push({
                    finalApproverRequest: faRequestEnts[id],
                    request,
                    requestNotes: farRequestNotes
                });
            }
        });

        return getFilteredFaRequestsAndNotes(farAndRequestsAndRequestNotes.filter(far => !far.finalApproverRequest.confirmed), filter, userEnts);
    }
    return [];
});

function getFilteredFaRequestsAndNotes(faRequestsAndRequestsAndNotes: FinalApproverRequestAndRequestAndNotes[], filter: fromRequest.Filter & fromRequest.RequestIndexType, userList: { [userId: number]: User }): FinalApproverRequestAndRequestAndNotes[] {
    let filterText: string;

    if (filter && userList) {
        if (filter.search && typeof filter.search.value === 'string') {
            filterText = filter.search.value ? filter.search.value.toLowerCase() : '';
        }

        if (filterText) {
            return faRequestsAndRequestsAndNotes.filter((far) => {
                const numNameNoteFranchiseFilters: boolean = String(far.request.studio_generated_id).toLowerCase().includes(filterText) || far.request.name.toLowerCase().includes(filterText) || (far.requestNotes[0].note ? far.requestNotes[0].note.toLowerCase().includes(filterText) : false) || (far.request.commonFranchise ? far.request.commonFranchise.toLowerCase().includes(filterText) : false);
                const createdByFilter: boolean = userList && userList[far.request.created_by] ? userList[far.request.created_by].fullName().toLowerCase().includes(filterText) : false;
                const farCreatedByFilter: boolean = far.finalApproverRequest && far.finalApproverRequest.created_by && userList[far.finalApproverRequest.created_by].fullName().toLowerCase().includes(filterText);

                return numNameNoteFranchiseFilters || createdByFilter || farCreatedByFilter;
            });
        }
    }

    return faRequestsAndRequestsAndNotes;
}

/** Return RequestAndNotes objects where request notes are sorted by note type */
function getFilteredRequestsAndNotes(requests: Request[], requestNotes: { [requestId: number]: RequestNote[] }, filter?: fromRequest.Filter & fromRequest.RequestIndexType, userList?: { [userId: number]: User }) {
    let farRequestsAndNotes: RequestAndNotes[] = [];
    let filterText: string;
    let statusFilter: number[];

    requests.forEach(request => {
        let requestsNotes = requestNotes && requestNotes[request.id] && requestNotes[request.id].length ? requestNotes[request.id] : [];
        requestsNotes = requestsNotes.map(n => new RequestNote(n)).sort((a, b) => {
            return a.note_type - b.note_type;
        });
        farRequestsAndNotes.push({ request, requestNotes: requestsNotes });
    });

    if (filter && userList) {
        const requestIndexType: RequestTypes = filter.requestIndexType.value as RequestTypes;

        if (filter.search && typeof filter.search.value === 'string') {
            filterText = filter.search.value ? filter.search.value.toLowerCase() : '';
        }
        if (filter['status']) {
            statusFilter = filter['status'].value as number[];
        }

        if (filterText) {
            farRequestsAndNotes = farRequestsAndNotes.filter((r) => {
                let numNameNoteFranchiseFilters = false;
                if (r && r.request) {
                    numNameNoteFranchiseFilters = String(r.request.studio_generated_id).toLowerCase().includes(filterText) || (r.request.name ? r.request.name.toLowerCase().includes(filterText) : false) || (r.requestNotes[0].note ? r.requestNotes[0].note.toLowerCase().includes(filterText) : false) || (r.request.commonFranchise ? r.request.commonFranchise.toLowerCase().includes(filterText) : false);
                }
                const createdByFilter = userList && userList[r.request.created_by] ? userList[r.request.created_by].fullName().toLowerCase().includes(filterText) : false;
                if (requestIndexType === RequestTypes.Requester) {
                    return numNameNoteFranchiseFilters;
                } else if (requestIndexType === RequestTypes.Approver) {
                    return numNameNoteFranchiseFilters || createdByFilter;
                } else {
                    const finalApproverRequest = r['finalApproverRequest'];
                    const farCreatedByFilter = finalApproverRequest && finalApproverRequest.created_by && userList[finalApproverRequest.created_by].fullName().toLowerCase().includes(filterText);
                    return numNameNoteFranchiseFilters || createdByFilter || farCreatedByFilter;
                }
            });
        }

        if (statusFilter && statusFilter.length) {
            farRequestsAndNotes = farRequestsAndNotes.filter(r => statusFilter.includes(r.request.getStatusDisplayBasedOnView(requestIndexType)));
        }
    }

    return farRequestsAndNotes;
}
