import { Action } from '@ngrx/store';
import { Division } from '../divisions/division.model';
import { Franchise } from '../franchises/franchise.model';
import { DivisionPermission } from '../permissions/division-permission.model';
import { FranchisePermission } from '../permissions/franchise-permission.model';
import { StudioPermission } from '../permissions/studio-permission.model';
import { SortObject } from '../types';
import { User } from './user.model';

export const GET = '[User] Get';
export const GET_COMPLETE = '[User] Get Complete';
export const GET_FAILED = '[User] Get Failed';

export const LIST_ALL_STUDIO = '[User] List All Studio';
export const LIST_ALL_STUDIO_COMPLETE = '[User] List All Studio Complete';
export const LIST_ALL_STUDIO_FAILED = '[User] List All Studio Failed';

export const LIST_BY_FRANCHISE = '[User] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[User] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[User] List By Franchise Failed';
export const SORT_FRANCHISE_LIST = '[User] Sort Franchise List';
export const CLEAR_FRANCHISE_LIST = '[User] Sort Franchise List Clear';

export const LIST_BY_DIVISION = '[User] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[User] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[User] List By Division Failed';
export const SORT_DIVISION_LIST = '[User] Sort Division List';
export const CLEAR_DIVISION_LIST = '[User] Sort Division List Clear';

export const LIST_BY_STUDIO = '[User] List By Studio';
export const LIST_BY_STUDIO_COMPLETE = '[User] List By Studio Complete';
export const LIST_BY_STUDIO_FAILED = '[User] List By Studio Failed';
export const SORT_STUDIO_LIST = '[User] Sort Studio List';

export const LIST_FINAL_APPROVERS = '[User] List Final Approvers';
export const LIST_FINAL_APPROVERS_COMPLETE = '[User] List Final Approvers Complete';
export const LIST_FINAL_APPROVERS_FAILED = '[User] List Final Approvers Failed';

export const UPDATE = '[User] Update';
export const UPDATE_FAILED = '[User] Update Failed';

export const UPDATE_STUDIO_PERMISSION = '[User] Update Studio Permission';
export const UPDATE_STUDIO_PERMISSION_FAILED = '[User] Update Studio Permission Failed';

export const UPDATE_FRANCHISE_PERMISSION = '[User] Update Franchise Permission';
export const UPDATE_FRANCHISE_PERMISSION_FAILED = '[User] Update Franchise Permission Failed';

export const UPDATE_DIVISION_PERMISSION = '[User] Update Division Permission';
export const UPDATE_DIVISION_PERMISSION_FAILED = '[User] Update Division Permission Failed';

export const UPDATE_COMPLETE = '[User] Update Complete';

export const DELETE_STUDIO_PERMISSION = '[User] Delete Studio Permission';
export const DELETE_STUDIO_PERMISSION_FAILED = '[User] Delete Studio Permission Failed';
export const DELETE_STUDIO_PERMISSION_COMPLETE = '[User] Delete Studio Permission Complete';

export const DELETE_FRANCHISE_PERMISSION = '[User] Delete Franchise Permission';
export const DELETE_FRANCHISE_PERMISSION_FAILED = '[User] Delete Franchise Permission Failed';
export const DELETE_FRANCHISE_PERMISSION_COMPLETE = '[User] Delete Franchise Permission Complete';

export const DELETE_DIVISION_PERMISSION = '[User] Delete Division Permission';
export const DELETE_DIVISION_PERMISSION_FAILED = '[User] Delete Division Permission Failed';
export const DELETE_DIVISION_PERMISSION_COMPLETE = '[User] Delete Division Permission Complete';

export const ENABLE_STUDIO_USERS_MFA = '[User] Enable Studio Users MFA';
export const ENABLE_STUDIO_USERS_MFA_COMPLETE = '[User] Enable Studio Users MFA Complete';
export const ENABLE_STUDIO_USERS_MFA_FAILED = '[User] Enable Studio Users MFA Failed';

export const DISABLE_STUDIO_USERS_MFA = '[User] Disable Studio Users MFA';
export const DISABLE_STUDIO_USERS_MFA_COMPLETE = '[User] Disable Studio Users MFA Complete';
export const DISABLE_STUDIO_USERS_MFA_FAILED = '[User] Disable Studio Users MFA Failed';

export const REMOVE_FROM_STORE = '[User] Remove User From Store';

export class GetAction implements Action {
    readonly type = GET;
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: User) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: { error: any }) { }
}

/** Gets all users in a studio including franchise users */
export class ListAllStudioAction implements Action {
    readonly type = LIST_ALL_STUDIO;

    constructor(public payload: number) { }
}

export class ListAllStudioCompleteAction implements Action {
    readonly type = LIST_ALL_STUDIO_COMPLETE;

    constructor(public payload: { studioId: number, users: User[] }) { }
}

export class ListAllStudioFailedAction implements Action {
    readonly type = LIST_ALL_STUDIO_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: Franchise) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: { users: User[], franchise: Franchise }) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: any, franchise: Franchise }) { }
}

export class SortFranchiseListAction implements Action {
    readonly type = SORT_FRANCHISE_LIST;

    constructor(public payload: SortObject) { }
}

export class ClearFranchiseListAction implements Action {
    readonly type = CLEAR_FRANCHISE_LIST;

    constructor() { }
}

export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: Division) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: { users: User[], division: Division }) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: { error: any, division: Division }) { }
}

export class SortDivisionListAction implements Action {
    readonly type = SORT_DIVISION_LIST;

    constructor(public payload: SortObject) { }
}

export class ListFinalApproversAction implements Action {
    readonly type = LIST_FINAL_APPROVERS;

    constructor(public payload: { studio_id: number, request_id: number, assetIds: number[] }) { }
}

export class ListFinalApproversCompleteAction implements Action {
    readonly type = LIST_FINAL_APPROVERS_COMPLETE;

    constructor(public payload: { users: User[] }) { }
}

export class ListFinalApproversFailedAction implements Action {
    readonly type = LIST_FINAL_APPROVERS_FAILED;

    constructor(public payload: { error: any }) { }
}

export class ClearDivisionListAction implements Action {
    readonly type = CLEAR_DIVISION_LIST;

    constructor() { }
}

/** Gets all admin users in a studio */
export class ListByStudioAction implements Action {
    readonly type = LIST_BY_STUDIO;

    constructor(public payload: number) { }
}

export class ListByStudioCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_COMPLETE;

    constructor(public payload: { studioId: number, users: User[] }) { }
}

export class ListByStudioFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class SortStudioListAction implements Action {
    readonly type = SORT_STUDIO_LIST;

    constructor(public payload: SortObject) { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { user: User, selectedStudioId?: number }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateStudioPermissionAction implements Action {
    readonly type = UPDATE_STUDIO_PERMISSION;

    constructor(public payload: StudioPermission) { }
}

export class UpdateStudioPermissionFailedAction implements Action {
    readonly type = UPDATE_STUDIO_PERMISSION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateFranchisePermissionAction implements Action {
    readonly type = UPDATE_FRANCHISE_PERMISSION;

    constructor(public payload: FranchisePermission) { }
}

export class UpdateFranchisePermissionFailedAction implements Action {
    readonly type = UPDATE_FRANCHISE_PERMISSION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateDivisionPermissionAction implements Action {
    readonly type = UPDATE_DIVISION_PERMISSION;

    constructor(public payload: DivisionPermission) { }
}

export class UpdateDivisionPermissionFailedAction implements Action {
    readonly type = UPDATE_DIVISION_PERMISSION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: User) { }
}

export class DeleteStudioPermissionAction implements Action {
    readonly type = DELETE_STUDIO_PERMISSION;

    constructor(public payload: { permission: StudioPermission }) { }
}

export class DeleteStudioPermissionFailedAction implements Action {
    readonly type = DELETE_STUDIO_PERMISSION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteStudioPermissionCompleteAction implements Action {
    readonly type = DELETE_STUDIO_PERMISSION_COMPLETE;

    constructor(public payload: User) { }
}

export class DeleteFranchisePermissionAction implements Action {
    readonly type = DELETE_FRANCHISE_PERMISSION;

    constructor(public payload: { permission: FranchisePermission, last: boolean }) { }
}

export class DeleteFranchisePermissionFailedAction implements Action {
    readonly type = DELETE_FRANCHISE_PERMISSION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteFranchisePermissionCompleteAction implements Action {
    readonly type = DELETE_FRANCHISE_PERMISSION_COMPLETE;

    constructor(public payload: { user: User, last: boolean }) { }
}

export class DeleteDivisionPermissionAction implements Action {
    readonly type = DELETE_DIVISION_PERMISSION;

    constructor(public payload: { permission: DivisionPermission, last: boolean }) { }
}

export class DeleteDivisionPermissionFailedAction implements Action {
    readonly type = DELETE_DIVISION_PERMISSION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DeleteDivisionPermissionCompleteAction implements Action {
    readonly type = DELETE_DIVISION_PERMISSION_COMPLETE;

    constructor(public payload: { user: User, last: boolean }) { }
}

export class EnableStudioUsersMFAAction implements Action {
    readonly type = ENABLE_STUDIO_USERS_MFA;

    constructor(public payload: { studio_id: number }) { }
}

export class EnableStudioUsersMFAFailedAction implements Action {
    readonly type = ENABLE_STUDIO_USERS_MFA_FAILED;

    constructor(public payload: { error: any }) { }
}

export class EnableStudioUsersMFACompleteAction implements Action {
    readonly type = ENABLE_STUDIO_USERS_MFA_COMPLETE;

    constructor(public payload: { status: string }) { }
}

export class DisableStudioUsersMFAAction implements Action {
    readonly type = DISABLE_STUDIO_USERS_MFA;

    constructor(public payload: { studio_id: number }) { }
}

export class DisableStudioUsersMFAFailedAction implements Action {
    readonly type = DISABLE_STUDIO_USERS_MFA_FAILED;

    constructor(public payload: { error: any }) { }
}

export class DisableStudioUsersMFACompleteAction implements Action {
    readonly type = DISABLE_STUDIO_USERS_MFA_COMPLETE;

    constructor(public payload: { status: string }) { }
}

export class RemoveFromStoreAction implements Action {
    readonly type = REMOVE_FROM_STORE;

    constructor(public payload: number) { }
}

export type Actions
    = GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListAllStudioAction
    | ListAllStudioCompleteAction
    | ListAllStudioFailedAction
    | ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | SortFranchiseListAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | SortDivisionListAction
    | ListByStudioAction
    | ListByStudioCompleteAction
    | ListByStudioFailedAction
    | SortStudioListAction
    | ListFinalApproversAction
    | ListFinalApproversCompleteAction
    | ListFinalApproversFailedAction
    | ClearFranchiseListAction
    | ClearDivisionListAction
    | UpdateAction
    | UpdateFailedAction
    | UpdateStudioPermissionAction
    | UpdateStudioPermissionFailedAction
    | UpdateFranchisePermissionAction
    | UpdateFranchisePermissionFailedAction
    | UpdateDivisionPermissionAction
    | UpdateDivisionPermissionFailedAction
    | UpdateCompleteAction
    | DeleteFranchisePermissionAction
    | DeleteFranchisePermissionCompleteAction
    | DeleteFranchisePermissionFailedAction
    | DeleteDivisionPermissionAction
    | DeleteDivisionPermissionCompleteAction
    | DeleteDivisionPermissionFailedAction
    | DeleteStudioPermissionAction
    | DeleteStudioPermissionCompleteAction
    | DeleteStudioPermissionFailedAction
    | EnableStudioUsersMFAAction
    | EnableStudioUsersMFACompleteAction
    | EnableStudioUsersMFAFailedAction
    | DisableStudioUsersMFAAction
    | DisableStudioUsersMFAFailedAction
    | DisableStudioUsersMFACompleteAction
    | RemoveFromStoreAction;
