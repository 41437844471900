import { Moment } from '../../../shared/services/moment.service';
import { Address } from '../addresses/address.model';
import { Attachment } from '../attachments/attachment.model';
import { Customer } from '../customers/customer.model';
import { AssetEvent } from '../models';
import { Shipment } from '../shipments/shipment.model';
import { BaseModel } from './../base/base.model';

export class Event extends BaseModel {
    id: number;
    division_id: number = null;
    customer_id: number = null;
    address_id: number = null;
    loan_address_id: number = null;
    name: string = null;
    description: string = null;
    start_date: Moment | Date = null;
    end_date: Moment | Date = null;
    exhibit_start_date: Moment | Date = null;
    exhibit_end_date: Moment | Date = null;
    event_number: string = null;
    deleted = false;
    active = true;
    attributes: any = {};
    customer?: Customer;
    address?: Address;
    loanAddress?: Address;
    shipments?: Shipment[];
    attachments?: Attachment[] = [];
    displayFields = null;
    created_at: Moment | Date = null;
    assetEvents?: AssetEvent[] = [];
    loan_venue_name?: string = null;

    constructor(eventObject) {
        super();
        this.initializeData(eventObject, this);

        if (eventObject && eventObject.attachments) {
            this.attachments = eventObject.attachments.map(attachment => {
                const newAttachment: Attachment = new Attachment(attachment);
                newAttachment.owner_id = this.id;
                return newAttachment;
            });
        }

        if (eventObject && eventObject.customer) {
            this.customer = new Customer(eventObject.customer);
        } else {
            this.customer = new Customer({ division_id: this.division_id });
        }

        if (eventObject && eventObject.address) {
            this.address = new Address(eventObject.address);
        } else {
            this.address = new Address({});
        }

        if (eventObject && eventObject.loanAddress) {
            this.loanAddress = new Address(eventObject.loanAddress);
        } else {
            this.loanAddress = new Address({});
        }

        if (eventObject && eventObject.shipments) {
            const ships = [];
            eventObject.shipments.forEach(shipment => {
                ships.push(new Shipment(shipment));
            });
            this.shipments = ships;
        } else {
            this.shipments = [new Shipment({ event_id: this.id })];
        }

        this.loan_venue_name = this.displayFields?.loan_venue_name;
    }

    getFormattedDateRange(startDate, endDate, moment) {
        const formattedStartDate = moment.utc(startDate).format('DD MMM. YYYY');
        const formattedEndDate = moment.utc(endDate).format('DD MMM. YYYY');
        if (formattedStartDate === formattedEndDate) {
            return `${formattedStartDate}`;
        }
        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    getEventLength(moment: Moment): number {
        const startDate = moment(this.start_date);
        const endDate = moment(this.end_date);
        return endDate.diff(startDate, 'days') + 1;
    }

    getEventExhibitLength(moment: Moment): number {
        const startDate = moment(this.exhibit_start_date);
        const endDate = moment(this.exhibit_end_date);
        return endDate.diff(startDate, 'days') + 1;
    }

    getArchiveRepDisplay() {
        const archiveRepName = this.attributes && this.attributes.archive_rep_name ? this.attributes.archive_rep_name : '';
        const archiveRepPhone = this.attributes && this.attributes.archive_rep_phone ? this.attributes.archive_rep_phone : '';
        if (!archiveRepName && !archiveRepPhone) {
            return null;
        }
        return `Archive Rep:${archiveRepName ? ' ' + archiveRepName : ''}${archiveRepName && archiveRepPhone ? ' -' : ''}${archiveRepPhone ? ' ' + archiveRepPhone : ''}`;
    }

    checkEventDetails() {
        const addressId = this.address_id ? true : false;
        const loanAddressId = this.loan_address_id ? true : false;

        const attributesContactName = Object.keys(this.attributes).length !== 0 && this.attributes.contact_name && this.attributes.contact_name !== '' ? true : false;
        const attributesContactPhone = Object.keys(this.attributes).length !== 0 && this.attributes.contact_phone && this.attributes.contact_phone !== '' ? true : false;
        const attributesExhibitvenueName = Object.keys(this.attributes).length !== 0 && this.attributes.exhibit_venue_name && this.attributes.exhibit_venue_name !== '' ? true : false;
        const attributesLoanContactPhone = Object.keys(this.attributes).length !== 0 && this.attributes.loan_contact_phone && this.attributes.loan_contact_phone !== '' ? true : false;
        const attributesLoanContactName = Object.keys(this.attributes).length !== 0 && this.attributes.loan_contact_name && this.attributes.loan_contact_name !== '' ? true : false;
        const attributesLoanVenueName = Object.keys(this.attributes).length !== 0 && this.attributes.loan_venue_name && this.attributes.loan_venue_name !== '' ? true : false;
        if (addressId || loanAddressId || attributesContactName || attributesContactPhone || attributesExhibitvenueName || attributesLoanContactPhone || attributesLoanContactName || attributesLoanVenueName) {
            return true
        } else {
            return false
        }
    }

    checkEventShippingDetails() {
        if (this.shipments.length) {
            const shipment = Object.entries(this.shipments[0])
            const data = shipment.filter(([_, value]) => ![null, ""].includes(value));
            if (data.length > 1) {
                return true
            }
        }
        return false;
    }
}
