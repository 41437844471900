import { createSelector } from 'reselect';
import * as divisionActions from '../divisions/division.actions';
import { getIdsAndEntities } from '../utils';
import * as warehouseActions from './warehouse.actions';
import { Warehouse } from './warehouse.model';

export interface State {
    ids: number[];
    entities: {
        [warehouseId: number]: Warehouse;
    };
}

export const initialState: State = {
    ids: [],
    entities: {},
};

export function reducer(state = initialState, action: warehouseActions.Actions | divisionActions.Actions): State {
    switch (action.type) {
        case divisionActions.ADD_COMPLETE: {
            const { entities, ids } = action.payload.warehouses.reduce((acc, warehouse) => {
                if (!state.entities[warehouse.id]) {
                    acc.ids.push(warehouse.id)
                }
                acc.entities[warehouse.id] = warehouse;
                return acc;
            }, { ids: [], entities: {} });
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        case divisionActions.GET_COMPLETE: {
            const { entities, ids } = getIdsAndEntities(state, action.payload.warehouses);
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        case divisionActions.LIST_BY_STUDIO_COMPLETE:
        case divisionActions.LIST_MOVE_OPTIONS_COMPLETE: {
            const divisions = action.payload;
            const warehousesArr = divisions.map(f => f.warehouses);
            const { entities, ids } = warehousesArr.reduce((acc, warehouses) => {
                warehouses.forEach(p => {
                    if (!state.entities[p.id]) {
                        acc.ids.push(p.id);
                    }
                    acc.entities[p.id] = p;
                });
                return acc;
            }, { ids: [], entities: {} });
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        case warehouseActions.ADD_COMPLETE: {
            const warehouse = action.payload.warhouse;
            const ids = [];
            if (!state.entities[warehouse.id]) {
                ids.push(warehouse.id);
            }
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, [warehouse.id]: warehouse }
            };
        }

        case warehouseActions.UPDATE_COMPLETE: {
            const warehouse = action.payload;

            const alterStateWith = {
                entities: { ...state.entities, [warehouse.id]: warehouse },
            };
            return { ...state, ...alterStateWith };
        }

        case warehouseActions.ADD:
        case warehouseActions.ADD_FAILED:
        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;

export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
