import { BaseModel } from './../base/base.model';

export class StorageBox extends BaseModel {
    id: number;
    division_id: number = null;
    warehouse_id: number = null;
    warehouse_location_id: number = null;
    warehouse_sub_location_id: number = null;
    name: string = null;
    shelf: string = null;
    description: string = null;
    space = false;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(storageBoxObj) {
        super();
        this.initializeData(storageBoxObj, this);
    }
}
