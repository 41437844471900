import { Event } from '../models';
import { BaseModel } from './../base/base.model';

export class AssetEvent extends BaseModel {
    id: number;
    asset_id: number = null;
    event_id: number = null;
    qty_on_loan: number = null;
    qty_returned: number = null;
    event: Event = null;
    check_out_qty: number = null;
    check_in_qty: number = null;

    constructor(assetEventObject) {
        super();
        this.initializeData(assetEventObject, this);
    }
}
