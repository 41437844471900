import { BaseModel } from './../base/base.model';
import { getFranchisePermissionObjectMock } from './franchise-permission.model';

export interface StudioPermissionObject {
    id: number;
    role: number;
    studio_id: number;
    user_id: number;
    approver: boolean;
    divisionPermissions: any[];
    franchisePermissions: any[];
}

export class StudioPermission extends BaseModel {
    id: number;
    role: number = null;
    studio_id: number = null;
    user_id: number = null;
    approver: boolean = null;
    divisionPermissions: any[] = [];
    franchisePermissions: any[] = [];

    constructor(studioPermission) {
        super();
        this.initializeData(studioPermission, this);
    }
}

const studioPermissionObjectMock: StudioPermissionObject = {
    id: 1,
    role: null,
    studio_id: 1,
    user_id: 60,
    approver: false,
    divisionPermissions: [],
    franchisePermissions: []
};

export function getStudioPermissionObjectMock(role: number, franchiseAdmin?: boolean,
                                              withFranchisePerms?: boolean, withDepartmentPerms?: boolean): StudioPermissionObject {
    const studioPermsObj = Object.assign({}, studioPermissionObjectMock);
    studioPermsObj.role = role;
    if (withFranchisePerms) {
        studioPermsObj.franchisePermissions = [getFranchisePermissionObjectMock(franchiseAdmin, withDepartmentPerms)];
    }
    return studioPermsObj;
}
