import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import * as franchiseActions from '../franchises/franchise.actions';
import { SortObject, TableFilterObject } from '../types';
import * as eventViewIndexActions from './event-view.actions';

export interface State {
    columnFiltersForEventId: {
        [eventId: number]: ColumnFilterOptions;
    };
    eventViewSort: {
        [eventId: number]: SortObject;
    };
    eventViewFilter: {
        [eventId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    columnFiltersForEventId: {},
    eventViewSort: {},
    eventViewFilter: {}
};

export function reducer(state = initialState, action: eventViewIndexActions.Actions | franchiseActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case eventViewIndexActions.SORT_EVENT_ASSETS_LIST: {
            const eventId = action.payload.eventId;
            const assetSort = action.payload.sort;
            const currentSort = state.eventViewSort[eventId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[eventId] = { ...action.payload.sort };

            return {
                ...state,
                eventViewSort: { ...state.eventViewSort, ...sortObj }
            };
        }

        case eventViewIndexActions.FILTER_EVENT_ASSETS_LIST: {
            const eventId = action.payload.eventId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.eventViewFilter, eventId);
                return {
                    ...state,
                    eventViewFilter: {
                        ...state.eventViewFilter,
                        [eventId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.eventViewFilter[eventId],
                ...action.payload.filter
            };

            return {
                ...state,
                eventViewFilter: {
                    ...state.eventViewFilter,
                    [eventId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case assetActions.LIST_BY_EVENT_FILTERS_COMPLETE: {
            const columnFiltersForEventId = { ...state.columnFiltersForEventId };
            columnFiltersForEventId[action.payload.eventId] = action.payload.columnFilters;

            return {
                ...state,
                columnFiltersForEventId
            };
        }

        case eventViewIndexActions.LIST_TABLE_STATE_COMPLETE: {
            const eventId = action.payload.eventId;
            const { filter, sort } = listTableState(action.payload.tableState, state.eventViewFilter, state.eventViewSort, eventId);

            return {
                ...state,
                eventViewFilter: filter,
                eventViewSort: sort
            };
        }

        default: {
            return state;
        }
    }
}

export const getDefaultAssetSort = (state: State) => state.eventViewSort;
export const getFranchiseAssetSort = (state: State) => state.eventViewSort;
export const getFranchiseAssetFilter = (state: State) => state.eventViewFilter;
