import { Action } from '@ngrx/store';
import { SubDepartment } from './sub-department.model';

export const ADD = '[SubDepartment] Add';
export const ADD_COMPLETE = '[SubDepartment] Add Complete';
export const ADD_FAILED = '[SubDepartment] Add Failed';

export const UPDATE = '[SubDepartment] Update';
export const UPDATE_COMPLETE = '[SubDepartment] Update Complete';
export const UPDATE_FAILED = '[SubDepartment] Update Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: SubDepartment) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: SubDepartment) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: any }) { }
}
export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: SubDepartment) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: SubDepartment) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction;
