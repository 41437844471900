import { ActionStatus } from '../types';
import { AssetTransaction } from './asset-transaction.model';
import * as assetTransactionAction from './asset-transaction.action'
import { getIdsAndEntities } from '../utils';
import { Transaction } from '../models';
import * as assetActions from './../assets/asset.actions';

export interface State {
    entities: {
        [id: number]: AssetTransaction
    },
    ids: number[],
    saving: ActionStatus,
    transactionEntities: {
        [id: number]: Transaction
    }
}

export const initialState: State = {
    entities: {},
    ids: [],
    saving: ActionStatus.Inactive,
    transactionEntities: {}
}

export function reducer(state = initialState, action: assetTransactionAction.Actions | assetActions.Actions): State {
    switch (action.type) {
        case assetTransactionAction.LIST_ASSET_TRANSACTION: {
            return {
                ...state,
                saving: ActionStatus.Loading
            }
        }

        case assetTransactionAction.LIST_ASSET_TRANSACTION_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            }
        }

        case assetTransactionAction.LIST_ASSET_TRANSACTION_COMPLETE: {
            const data = action.payload.assetTransaction;
            const { allIds, entities } = getIdsAndEntities(state, data)

            return {
                ...state,
                entities: entities,
                ids: allIds,
                saving: ActionStatus.Complete
            }
        }

        case assetTransactionAction.GET_ASSET_TRANSACTION_COMPLETE: {
            const entries = action.payload.transactions;
            const { allIds, entities } = getIdsAndEntities(state, entries)

            return {
                ...state,
                transactionEntities: entities,
                ids: allIds
            }
        }

        case assetActions.REMOVE_TRANSACTIONS_COMPLETE: {
            const assetTransactions = Object.values(state.transactionEntities);
            action.payload.transactionIds.forEach(id => {
                const index = assetTransactions.findIndex(t => t.id === id);
                if (index !== -1) {
                    assetTransactions.splice(index, 1);
                }
            });

            const { allIds, entities } = getIdsAndEntities(state, assetTransactions)
            return {
                ...state,
                transactionEntities: entities,
                ids: allIds
            }
        }

        default:
            return state;
    }
}
export const getAssetTransactions = (state: State) => {
    const assetTransactions = state.ids.map(id => state.entities[id]);
    return assetTransactions.length ? assetTransactions : [];
}
export const getAssetTransationSaving = (state: State) => state.saving;
export const getListAssetTransactions = (state: State) => {
    const assetTransactions = state.ids.map(id => state.transactionEntities[id]);
    return assetTransactions.length ? assetTransactions : [];
}