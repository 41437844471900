import { STUDIO_PERMISSION_ROLES } from '../../../../../shared/enums/studio-permission-roles.enum';
import { Asset } from '../assets/asset.model';
import { Division } from '../divisions/division.model';
import { Franchise } from '../franchises/franchise.model';
import { CustomStudioFieldName } from '../models';
import { Permission } from '../permissions/permission.model';
import { BaseModel } from './../base/base.model';

export const USER_ROLE_TITLE = 'Series/Feature Owner';

export class User extends BaseModel {
    id: number;
    email: string = null;
    first_name: string = null;
    last_name: string = null;
    phone: string = null;
    current_division_id: number = null;
    current_studio_id: number = null;
    current_franchise_id: number = null;
    intercom_hash: string = null;
    created_at: string = null;
    permission: Permission = null;
    admin: boolean = null;
    position: string = null;
    assethub_role: string = null;
    settings: any = null;
    smart_id: string = null;
    smart_id_mfa_federated: boolean = null;
    activated: boolean = null;

    constructor(userObject) {
        super();
        this.initializeData(userObject, this);
        this.permission = new Permission(userObject);
    }

    canEditAsset(asset: Asset) {
        if (asset.division_id) {
            return this.canEditInDivision(asset.division_id, asset.studio_id);
        } else {
            return this.canEditInFranchise(asset.franchise_id, asset.studio_id);
        }
    }

    canEditInFranchise(franchiseId: number, studioId: number) {
        return this.permission.canEditInFranchise(franchiseId, studioId);
    }

    canUnlinkTransactionsInFranchise(franchiseId: number, studioId: number) {
        return this.permission.canUnlinkTransactionsInFranchise(franchiseId, studioId);
    }

    canEditInDivision(divisionId: number, studioId: number) {
        return this.permission.canEditInDivision(divisionId, studioId);
    }

    canAccessCustomersInDivision(divisionId: number, studioId: number) {
        return this.permission.canAccessCustomersInDivision(divisionId, studioId);
    }

    displayDivisionUserType(divisionId: number): string {
        return this.permission.displayDivisionUserType(divisionId);
    }

    displayDivisionPosition(divisionId) {
        return this.permission.getDivisionPosition(divisionId);
    }

    displayFranchisePosition(franchise, customFieldNames?: CustomStudioFieldName) {
        return this.permission.getFranchisePosition(franchise, customFieldNames);
    }

    displayStudioRole(studioId, customFieldNames?: CustomStudioFieldName) {
        const roleNum = this.permission.getStudioRole(studioId);
        if (roleNum === STUDIO_PERMISSION_ROLES.USER) {
            return customFieldNames ? `${customFieldNames.series_id}/${customFieldNames.franchise_id} Owner` : USER_ROLE_TITLE;
        } else if (roleNum === STUDIO_PERMISSION_ROLES.MANAGER) {
            const [divisionId] = Object.keys(this.permission.divisionPermissions);
            if (divisionId && this.permission.divisionPermissions[divisionId].admin) {
                return 'Division Owner';
            }
        }
        return `Studio ${STUDIO_PERMISSION_ROLES[roleNum][0].toUpperCase()}${STUDIO_PERMISSION_ROLES[roleNum].slice(1).toLowerCase()}`;
    }

    fullName() {
        return `${this.first_name} ${this.last_name}`;
    }

    getDepartments(departmentList, franchise) {
        if (!departmentList) {
            return [];
        }
        if (franchise && this.permission.isFranchiseAdmin(franchise.id, franchise.studio_id)) {
            return [];
        }
        const depts = [];
        if (this.permission.franchisePermissions && franchise) {
            this.permission.franchisePermissions[franchise.id]?.departmentPermissions.forEach(dp => {
                depts.push(departmentList.find(d => d.id === dp['department_id']));
            });
            return depts.sort((a, b) => a.id - b.id);
        }
    }

    getObject() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            phone: this.phone,
            current_studio_id: this.current_studio_id,
            current_franchise_id: this.current_franchise_id,
            current_division_id: this.current_division_id,
            assethub_role: this.assethub_role,
            settings: this.settings
        };
    }

    isApprover(studioId: number): boolean {
        return this.permission.isApprover(studioId);
    }

    /**
     * Check if User has access to any Division
     */
    hasDivisionPermission(studioId: number): boolean {
        return this.permission.hasDivisionPermissionForStudio(studioId);
    }

    hasFranchisePermission(franchiseId: number, studioId: number): boolean {
        return this.permission.isFranchiseUser(franchiseId, studioId);
    }

    isFranchiseAdmin(franchise: Franchise): boolean {
        return this.permission.isFranchiseAdmin(franchise?.id, franchise?.studio_id);
    }

    isDivisionAdmin(division: Division): boolean {
        return this.permission.isDivisionAdmin(division?.id, division?.studio_id);
    }

    isDivisionUser(division: Division): boolean {
        return this.permission.isDivisionUser(division.id, division.studio_id);
    }

    isStudioAdmin(studioId: number): boolean {
        return this.permission.isStudioAdmin(studioId);
    }

    isStudioOwner(studioId: number): boolean {
        return this.permission.isStudioOwner(studioId);
    }

    studioRole(studioId: number) {
        return this.permission.getStudioRole(studioId);
    }

    isStudioOwnerOrFranchiseOwner(studioId: number, franchise: Franchise) {
        return this.isStudioAdmin(studioId) || this.isFranchiseAdmin(franchise)
    }
}
