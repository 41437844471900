import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FIELD_TYPES } from '../../../../../shared/fields/field-types';
import { BaseModel } from './base.model';

export class BaseField extends BaseModel {
    canonical_name: string = null;
    name: string = null;
    type: string = null;

    get input_type() {
        return FIELD_TYPES[this.type].input_type;
    }

    get max_length() {
        return FIELD_TYPES[this.type].max_length;
    }

    get pattern() {
        return FIELD_TYPES[this.type].pattern;
    }

    getFormControl(required: boolean, value?: any): UntypedFormControl | UntypedFormGroup {
        return new UntypedFormControl(this.type === FIELD_TYPES.CHECKBOX.type ? !!value : value, this.getValidators(required));
    }

    getValidators(required: boolean) {
        const validators = [];
        const fieldType = FIELD_TYPES[this.type];
        if (fieldType && fieldType.max_length) {
            validators.push(Validators.maxLength(fieldType.max_length));
        }
        if (required) {
            validators.push(Validators.required);
        }
        if (fieldType && fieldType.pattern) {
            validators.push(Validators.pattern(fieldType.pattern.pattern));
        }
        return validators;
    }
}
