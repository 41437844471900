import { createSelector } from 'reselect';
import * as root from '../';
import { ActionStatus } from '../types';
import { getArrayFromIdsAndEntities } from '../utils';

const getAssetState = (state: root.State) => state.asset;
const getStudioIds = createSelector(getAssetState, (assetState) => assetState.studioIds);
const getStudioTotalCount = createSelector(getAssetState, (assetState) => assetState.studioTotalCount);

export const getCurrentDivisionAssets = createSelector(root.getAssetEntities, root.getDivisionAssetIds, root.getAssetLoading, root.getSelectedDivisionId, (entities, ids, loading, divisionId) => {
    if (loading !== ActionStatus.Complete || !divisionId || !ids[divisionId]) {
        return [];
    }
    return getArrayFromIdsAndEntities(ids[divisionId], entities);
});

export const getCurrentDivisionReviewAssets = createSelector(root.getAssetEntities, root.getDivisionReviewAssetIds, root.getAssetLoading, root.getSelectedDivisionId, (entities, ids, loading, divisionId) => {
    if (loading !== ActionStatus.Complete || !divisionId || !ids[divisionId]) {
        return [];
    }
    return getArrayFromIdsAndEntities(ids[divisionId], entities)

})

export const getCurrentFranchiseAssets = createSelector(root.getAssetEntities, root.getFranchiseAssetIds, root.getAssetLoading, root.getSelectedFranchiseId, (entities, ids, loading, franchiseId) => {
    if (loading !== ActionStatus.Complete || !franchiseId || !ids[franchiseId]) {
        return [];
    }
    return getArrayFromIdsAndEntities(ids[franchiseId], entities);
});

export const getAssetCountForCurrentFranchise = createSelector(root.getAssetCountForFranchise, root.getSelectedFranchiseId, (assetCountForFranchiseId, franchiseId) => {
    return franchiseId && assetCountForFranchiseId[franchiseId] ? assetCountForFranchiseId[franchiseId] : 0;
});

export const getCurrentReviewAssets = createSelector(root.getAssetEntities, root.getReviewAssetIds, root.getAssetLoading, root.getSelectedFranchiseId, (entities, ids, loading, franchiseId) => {
    if (loading !== ActionStatus.Complete || !franchiseId || !ids[franchiseId]) {
        return [];
    }
    return getArrayFromIdsAndEntities(ids[franchiseId], entities);
});

export const getAssetsForSelectedEvent = createSelector(root.getAssetEntities, root.getAssetIdsForEvents, root.getSelectedEventId, (assets, assetIds, eventId) => {
    return (eventId && assetIds[eventId]) ? assetIds[eventId].map(id => assets[id]) : [];
});

export const getCacheLastValidatedForSelectedDivision = createSelector(root.getSelectedDivisionId, root.getCacheLastValidated, (divisionId, cacheLastValidated) => {
    return divisionId && cacheLastValidated[divisionId] ? cacheLastValidated[divisionId] : undefined;
});

export const getAssetsForSelectedStudio = createSelector(root.getAssetEntities, getStudioIds, root.getSelectedStudioId, (assetEntities, studioIds, selectedStudioId) => selectedStudioId && studioIds[selectedStudioId] ? studioIds[selectedStudioId].map(assetId => assetEntities[assetId]) : []);
export const getAssetsIdsForSelectedStudio = createSelector(root.getAssetEntities, getStudioIds, root.getSelectedStudioId, (assetEntities, studioIds, selectedStudioId) => selectedStudioId && studioIds[selectedStudioId] ? studioIds[selectedStudioId].map(assetId => assetEntities[assetId].id) : []);

export const getSelectedStudioTotalCount = createSelector(getStudioTotalCount, root.getSelectedStudioId, (studioTotalCount, selectedStudioId) => selectedStudioId && studioTotalCount[selectedStudioId] ? studioTotalCount[selectedStudioId] : 0);
export const getErrorMovingAsset = createSelector(getAssetState, (assetState) => assetState.error);
export const getErrorSavingAsset = createSelector(getAssetState, (assetState) => assetState.error);
