import { createSelector } from 'reselect';
import * as root from '../';
import { EventFieldWithDivisionEventField } from './event-field.model';

export const getWithDivisionEventFieldForSelectedDivision = createSelector(root.getSelectedDivision, root.getEventFields, (division, eventFields) => {
    const eventFieldsWithDivEventFields: EventFieldWithDivisionEventField[] = [];
    eventFields.forEach(eventField => {
        const divisionEventField = division.divisionEventFields ? division.divisionEventFields.find(df => df.event_field_id === eventField.id) : null;
        if (divisionEventField) {
            eventFieldsWithDivEventFields.push({ divisionEventField, eventField });
        }
    });

    return eventFieldsWithDivEventFields;
});

export const getForSelectedDivision = createSelector(root.getSelectedDivision, root.getEventFieldEntities, (division, eventFieldEntities) => {
    if (!division || !division.divisionEventFields || Object.keys(eventFieldEntities).length === 0) {
        return [];
    }
    const eventFields = division.divisionEventFields.map(divisionEventField => eventFieldEntities[divisionEventField.event_field_id]);
    eventFields.sort((a, b) => a.sort_order - b.sort_order);
    return eventFields;
});
