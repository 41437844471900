import { createSelector } from 'reselect';
import { ActionStatus } from '../types';
import { sortStrings } from '../utils';
import * as dispositionActions from './disposition.actions';
import { Disposition } from './disposition.model';

export interface State {
    ids: number[];
    entities: {
        [dispositionId: number]: Disposition;
    };
    saving: ActionStatus;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Inactive
};

export function reducer(state = initialState, action: dispositionActions.Actions): State {
    switch (action.type) {
        case dispositionActions.LIST_COMPLETE: {
            const dispositions = action.payload;
            const { entities, ids } = dispositions.reduce((acc, d) => {
                if (!state.entities[d.id]) {
                    acc.ids.push(d.id);
                }
                acc.entities[d.id] = d;
                return acc;
            }, { ids: [], entities: {} });

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }
        case dispositionActions.ADD_COMPLETE: {
            const disposition = action.payload;
            let sortIds = [...state.ids];

            let dispositionObj = {};
            //add object key to state keys
            dispositionObj[disposition.id] = disposition;
            sortIds.push(disposition.id);

            const alterStateWith = {
                ids: sortIds,
                entities: {
                    ...state.entities,
                    ...dispositionObj
                }
            }
            return { ...state, ...alterStateWith, saving: ActionStatus.Complete };
        }
        case dispositionActions.UPDATE_COMPLETE: {
            const disposition = action.payload;
            let sortIds = [...state.ids];

            let dispositionObj = {};
            //add object key to state keys
            dispositionObj[disposition.id] = disposition;

            const alterStateWith = {
                ids: sortIds,
                entities: {
                    ...state.entities,
                    ...dispositionObj
                }
            }
            return {
                ...state,
                ...alterStateWith,
                saving: ActionStatus.Complete
            };
        }
        case dispositionActions.DELETE_COMPLETE: {
            const id = action.payload;

            let sortIds = [...state.ids];

            const entitiesCopy = { ...state.entities };
            sortIds = sortIds.filter(sId => sId != id);
            delete entitiesCopy[id];

            return {
                ...state,
                entities: entitiesCopy,
                ids: sortIds,
            };
        }
        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;

export const getDispositions = createSelector(getEntities, getIds, (ents, ids) =>
    ids.map(id => ents[id]).sort((a, b) => sortStrings(a.name, b.name))
);
export const getSaving = (state: State) => state.saving;
