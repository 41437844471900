import { FIELD_TYPES } from './field-types';

export const FAKE_FIELDS = [
    {
        // code field for set and character matching to ledgers
        id: -1,
        studio_id: null,
        canonical_name: 'code',
        name: 'Char/Set Code',
        type: 'SHORT_TEXT_AREA',
        required_locked: true,
        delete_locked: true,
        sort_order: null,
        picklistOptions: null,
        mapTo: ['set_id', 'character_id']
    }
];

export const FAKE_COLUMNS = [
    {
        id: -2,
        studio_id: null,
        canonical_name: 'other_cost',
        name: 'Cost (Other)',
        type: 'CURRENCY',
        restricted: true,
        sort_order: 112
    },
    {
        id: -3,
        studio_id: null,
        canonical_name: 'local_cost',
        name: 'Local Cost',
        type: 'CURRENCY',
        restricted: true,
        sort_order: 113
    },
    {
        id: -14,
        studio_id: null,
        canonical_name: 'import_payload',
        name: 'SyncOnSet Import',
        type: 'CHECKBOX',
        restricted: false,
        sort_order: 114
    }
];

export const FAKE_EXCLUDED_COLUMNS = [
    {
        id: -4,
        studio_id: null,
        canonical_name: 'excluded_disposition_id',
        name: 'Excluded Disposition',
        type: 'PICKLIST',
        sort_order: 255
    },
    {
        id: -5,
        studio_id: null,
        canonical_name: 'excluded_note',
        name: 'Excluded Note',
        type: 'LONG_TEXT_AREA',
        sort_order: 256
    }
];

export const FAKE_EVENT_FIELD = {
    id: -1,
    studio_id: null,
    canonical_name: 'event_id',
    name: 'Event',
    type: 'PICKLIST',
    required_locked: true,
    delete_locked: true,
    sort_order: null,
    picklistOptions: null,
};

export const FAKE_EXCLUDED_FIELDS = {
    excluded_disposition: {
        id: -4,
        studio_id: null,
        name: 'Excluded Disposition',
        canonical_name: 'excluded_disposition_id',
        type: 'PICKLIST',
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: true,
        sort_order: 255,
        picklistOptions: null
    },
    excluded_note: {
        id: -5,
        studio_id: null,
        name: 'Excluded Note',
        canonical_name: 'excluded_note',
        type: 'LONG_TEXT_AREA',
        default: true,
        initially_required: false,
        required_locked: false,
        delete_locked: false,
        sort_order: 256,
        picklistOptions: null
    }
};

export const FAKE_QUANTITY_ON_HAND_FIELD = {
    id: -10,
    name: 'Qty On Hand',
    canonical_name: 'qty_on_hand',
    type: FIELD_TYPES.NUMBER.type
};

export const FAKE_QUANTITY_ON_LOAN_FIELD = {
    id: -11,
    name: 'Qty On Loan',
    canonical_name: 'qty_on_loan',
    type: FIELD_TYPES.NUMBER.type
};

export const FAKE_CHECK_IN_QTY_FIELD = {
    id: -12,
    name: 'Check In Qty',
    canonical_name: 'check_in_qty',
    type: FIELD_TYPES.NUMBER.type,
    pattern: FIELD_TYPES.NUMBER.pattern,
    input_type: FIELD_TYPES.NUMBER.input_type
};

export const FAKE_CHECK_OUT_QTY_FIELD = {
    id: -13,
    name: 'Check Out Qty',
    canonical_name: 'check_out_qty',
    type: FIELD_TYPES.NUMBER.type,
    pattern: FIELD_TYPES.NUMBER.pattern,
    input_type: FIELD_TYPES.NUMBER.input_type
};
