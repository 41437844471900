import { BaseModel } from './../base/base.model';

export class AssetTransaction extends BaseModel {
    id: number;
    asset_id: number = null;
    transaction_id: number = null;
    keep_total_cost_from_ct: boolean = null;

    constructor(assetTransactionObject) {
        super();
        this.initializeData(assetTransactionObject, this);
    }
}
