<div [ngSwitch]="field.field.canonical_name" class="owner-fields">
    <!-- Owner Field -->
    <div class="input-wrapper-p-dropdown fake-owner">
        <ah-asset-field-input *ngSwitchCase="'owner'" [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="ownerOptions"
            (change)="selectOwnerEmit($event)" [icon]="'remove_red_eye'" [iconColor]="'#F3A536'" [disabled]="disabled || disableOwnerChange">
        </ah-asset-field-input>
    </div>
    <!-- Division Field -->
    <div class="input-wrapper-p-dropdown no-top-border" *ngSwitchCase="standardFields.division.canonical_name">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="divisionOptions" (change)="selectDivisionEmit($event)">
        </ah-asset-field-input>
    </div>
    <!-- Franchise Field -->
    <div class="input-wrapper-p-dropdown">
        <ah-asset-field-input *ngSwitchCase="standardFields.franchise.canonical_name" [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required"
            [options]="(selectedOwner !== OwnerType.Division && disableOwnerChange) ? currentFranchiseOption : ownedFranchiseOptions"
            (change)="selectFranchiseEmit($event)" [icon]="'movie'" [iconColor]="'#4185DE'" [disabled]="disabled || (selectedOwner !== OwnerType.Division && disableOwnerChange)">
        </ah-asset-field-input>
    </div>
    <!-- Project Field -->
    <div class="bottom-margin no-top-border">
        <ah-asset-field-input *ngSwitchCase="standardFields.project.canonical_name" [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required"
            [options]="projectOptions$ | async" (change)="selectProjectEmit($event)" [disabled]="disabled || (projectOptions$ | async)?!(projectOptions$ | async).length:true" [placeholder]="placeholder">
        </ah-asset-field-input>
    </div>
    <!-- Warehouse Field -->
    <div class="input-wrapper-p-dropdown">
        <ah-asset-field-input *ngSwitchCase="standardFields.warehouse.canonical_name" [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required"
            [options]="warehouseOptions" (change)="selectWarehouseEmit($event)" [icon]="'business'" [iconColor]="'#891EFE'" [disabled]="disabled">
        </ah-asset-field-input>
    </div>
    <!-- Location/Warehouse Location Field -->
    <div *ngSwitchCase="standardFields.location.canonical_name">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="locationOptions$ | async" (change)="selectLocationEmit($event)"
            [disabled]="disabled || !(locationOptions$ | async).length" [icon]="'pin_drop'" [iconColor]="'#396A0A'">
        </ah-asset-field-input>
    </div>
    <div class="no-top-border" *ngSwitchCase="standardFields.warehouseLocation.canonical_name">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="warehouseLocationOptions$ | async"
            [disabled]="disabled || !(warehouseLocationOptions$ | async)?.length || warehouseLocationSubLocationShelfDisabled" (change)="selectWarehouseLocationEmit($event)"
            [icon]="'pin_drop'" [iconColor]="'#396A0A'"></ah-asset-field-input>
    </div>
    <!-- SubLocation/Warehouse SubLocation Field -->
    <div *ngSwitchCase="standardFields.subLocation.canonical_name" class="bottom-margin no-top-border">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="subLocationOptions$ | async"
            [disabled]="disabled || !(subLocationOptions$ | async).length" (change)="selectSubLocationEmit($event)">
        </ah-asset-field-input>
    </div>
    <div *ngSwitchCase="standardFields.warehouseSubLocation.canonical_name" class="no-top-border">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="(warehouseSubLocationOptions$ | async)"
            [disabled]="disabled || !(warehouseSubLocationOptions$ | async).length || warehouseLocationSubLocationShelfDisabled" (change)="selectWarehouseSubLocationEmit($event)">
        </ah-asset-field-input>
    </div>
    <!-- Shelf Field -->
    <div *ngSwitchCase="standardFields.shelf.canonical_name" class="input-owner-field">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [disabled]="disabled || warehouseLocationSubLocationShelfDisabled">
        </ah-asset-field-input>
    </div>
    <!-- Storage Box Field -->
    <div *ngSwitchCase="standardFields.storageBox.canonical_name" class="bottom-margin no-top-border">
        <ah-asset-field-input [isButton]="true" [group]="assetForm" [field]="field.field" [required]="field.departmentField.required" [options]="storageBoxOptions$ | async" [disabled]="disabled || !(storageBoxOptions$ | async)?.length"
            (change)="selectStorageBoxEmit($event)">
        </ah-asset-field-input>
    </div>
</div>
