import { createSelector } from 'reselect';
import * as root from '../';
import { ProjectCurrency } from '../models';

const getCurrenciesForSelectedProject = (currencies: ProjectCurrency[], projectIds: number[]) => {
    const projectId = projectIds.length === 1 ? projectIds[0] : null;
    return currencies.filter(currency => currency.project_id === projectId);
};

const getSelectedIdForProject = (currencies: ProjectCurrency[], selectedId: number) => {
    if (!currencies || !currencies.length) {
        return null;
    }

    const selected = currencies.find(curr => curr.id === selectedId);
    return selected ? selected.id : currencies.find(curr => curr.base === true).id;
};

export const getForSelectedProjectOnSettings = createSelector(root.getProjectCurrencies, root.getSidenavSettingsProjectIds, getCurrenciesForSelectedProject);
export const getForSelectedProjectOnAsset = createSelector(root.getProjectCurrencies, root.getSidenavAssetProjectIds, getCurrenciesForSelectedProject);
export const getForSelectedProjectOnTransaction = createSelector(root.getProjectCurrencies, root.getSidenavTransactionProjectIds, getCurrenciesForSelectedProject);
export const getForSelectedAssetOnView = createSelector(root.getProjectCurrencies, root.getSelectedAsset, (pCurrencies, asset) => {
    return asset ? getCurrenciesForSelectedProject(pCurrencies, [asset.project_id]) : [];
});
export const getForAssetModalSelectedProject = createSelector(root.getProjectCurrencies, root.getAssetModalSelectedProjectId, (pcs: ProjectCurrency[], projectId) => {
    return pcs.filter(pc => pc.project_id === projectId);
});
export const getForInlineEditSelectedProject = createSelector(root.getProjectCurrencies, root.getSelectedInlineEditAsset, (pcs: ProjectCurrency[], asset) => {
    return pcs.filter(pc => pc.project_id === asset?.project_id);
});
export const getForMatchReviewSelectedProject = createSelector(root.getProjectCurrencies, root.getMatchReviewSelectedProjectId, (pcs: ProjectCurrency[], projectId) => {
    return pcs.filter(pc => pc.project_id === projectId);
});
export const getForMatchAssetSelectedProject = createSelector(root.getProjectCurrencies, root.getMatchAssetSelectedProjectId, (pcs: ProjectCurrency[], projectId) => {
    return pcs.filter(pc => pc.project_id === projectId);
});

export const getForAssetImportCurrenciesForSelectedProject = createSelector(root.getProjectCurrencies, root.getAssetImportsSelectedProjectId, (pcs: ProjectCurrency[], projectId) => {
    return pcs.filter(pc => pc.project_id === projectId)
})

export const getSelectedIdForProjectOnAsset = createSelector(getForSelectedProjectOnAsset, root.getProjectCurrencySelectedId, getSelectedIdForProject);
export const getSelectedIdForProjectOnAssetView = createSelector(getForSelectedAssetOnView, root.getProjectCurrencySelectedId, getSelectedIdForProject);
export const getSelectedIdForProjectOnTransaction = createSelector(getForSelectedProjectOnTransaction, root.getProjectCurrencySelectedId, getSelectedIdForProject);
