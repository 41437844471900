import { Action } from '@ngrx/store';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { SortObject, TableFilterObject } from '../types';

export const SELECT_TAB = '[Division Asset Index] Select Tab';

export const SORT_DIVISION_LIST = '[Division Asset Index] Sort Division Asset List';
export const SORT_DIVISION_LIST_FOR_REVIEW_LIST = '[Division Asset Index] Sort Division For Review Asset List';
export const FILTER_DIVISION_LIST = '[Division Asset Index] Filter Division Asset List';
export const FILTER_DIVISION_FOR_REVIEW_LIST = '[Division Asset Index] Filter Division For Review List';
export const INVALIDATE_DIVISION_CACHE = '[Division Asset Index] Invalidate Division Cache';
export const LIST_TABLE_STATE = '[Division Asset Index] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Division Asset Index] List Table State Complete';
export const LIST_REVIEW_TABLE_STATE = '[Division Asset Index] List Review Table State';
export const LIST_REVIEW_TABLE_STATE_COMPLETE = '[Division Asset Index] List Review Table State Complete';

export class SelectTabAction implements Action {
    readonly type = SELECT_TAB;

    constructor(public payload: number) { }
}
export class SortDivisionAssetsAction implements Action {
    readonly type = SORT_DIVISION_LIST;

    constructor(public payload: { divisionId: number, sort: SortObject }) { }
}

export class SortDivisionForReviewAssetsAction implements Action {
    readonly type = SORT_DIVISION_LIST_FOR_REVIEW_LIST;

    constructor(public payload: { divisionId: number, sort: SortObject }) { }
}

export class FilterDivisionListAction implements Action {
    readonly type = FILTER_DIVISION_LIST;

    constructor(public payload: { divisionId: number, filter: TableFilterObject, reset?: boolean }) { }
}

export class FilterDivisionForReviewListAction implements Action {
    readonly type = FILTER_DIVISION_FOR_REVIEW_LIST;

    constructor(public payload: { divisionId: number, filter: TableFilterObject, reset?: boolean }) { }
}
export class InvalidateDivisionCacheAction implements Action {
    readonly type = INVALIDATE_DIVISION_CACHE;

    constructor(public payload: { divisionId: number, validCache: boolean }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { divisionId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { divisionId: number, tableState: TableState }) { }
}

export class ListReviewTableStateAction implements Action {
    readonly type = LIST_REVIEW_TABLE_STATE;

    constructor(public payload: { divisionId: number, stateKey: string }) { }
}

export class ListReviewTableStateCompleteAction implements Action {
    readonly type = LIST_REVIEW_TABLE_STATE_COMPLETE;

    constructor(public payload: { divisionId: number, tableState: TableState }) { }
}
export type Actions
    = SortDivisionAssetsAction
    | SortDivisionForReviewAssetsAction
    | FilterDivisionListAction
    | InvalidateDivisionCacheAction
    | ListTableStateAction
    | ListTableStateCompleteAction
    | SelectTabAction
    | ListReviewTableStateAction
    | ListReviewTableStateCompleteAction
    | FilterDivisionForReviewListAction;
