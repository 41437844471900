import { createSelector } from 'reselect';
import * as root from '../';
import * as fieldSelectors from '../fields/field.selectors';
import { buildPDropdownOptions } from '../utils';
import * as warehouseLocationSelectors from '../warehouse-locations/warehouse-location.selectors';
import * as fromStorageBoxModal from './storage-box-modal.reducer';

export const getStorageBoxModalState = (state: root.State) => state.storageBoxModal;
export const getStorageBoxModalSelectedWarehouseLocationId = createSelector(getStorageBoxModalState, fromStorageBoxModal.getSelectedWarehouseLocationId);
export const getSubLocationsForStorageBoxModal = createSelector(root.getWarehouseSubLocationEntities, root.getWarehouseLocationSubLocationIds, getStorageBoxModalSelectedWarehouseLocationId, (subLocationEntities, subLocationIds, locationId) => {
    return locationId ? subLocationIds[locationId].map(id => subLocationEntities[id]) : [];
});
export const getWarehouseLocationOptions = createSelector(warehouseLocationSelectors.getForSelectedWarehouseOnStoragePage, fieldSelectors.getWarehouseLocationField, (warehouseLocations, warehouseLocationField) => {
    return buildPDropdownOptions(warehouseLocations, {
        nullOption: true,
        nullLabel: warehouseLocationField.name,
        field: warehouseLocationField
    });
});
export const getWarehouseSubLocationOptions = createSelector(getSubLocationsForStorageBoxModal, fieldSelectors.getWarehouseSubLocationField, (warehouseSubLocations, warehouseSubLocationField) => {
    return buildPDropdownOptions(warehouseSubLocations, {
        nullOption: true,
        nullLabel: warehouseSubLocationField.name,
        field: warehouseSubLocationField
    });
});
