import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import * as studioActions from './../../core/store/studios/studio.actions';
import * as userActions from './../../core/store/users/user.actions';
import { User } from './../../core/store/users/user.model';

@Injectable()
export class StudioDataService {
    constructor(private store: Store<fromRoot.State>) { }

    getAllStudios() {
        const combined = observableCombineLatest(this.store.select(fromRoot.getUser), this.store.select(fromRoot.getStudioList));
        return combined.pipe(map(([user, studios]) => {
            if (!studios) {
                this.store.dispatch(new studioActions.ListByUserAction(user.id));
            }
            return studios;
        }));
    }

    getSelectedStudio(route: ActivatedRouteSnapshot) {
        const paramStudioId = parseInt(route.params.studioId, 10);

        return observableCombineLatest(this.store.select(fromRoot.getUser), this.store.select(fromRoot.getSelectedStudio)).pipe(filter(([user, studio]) => {
            if (!studio || (paramStudioId && studio.id !== paramStudioId)) {
                this.store.dispatch(new studioActions.SelectAction(paramStudioId));
                return false;
            }
            return true;
        }), map(([user, studio]) => {
            if (studio.id !== user.current_studio_id) {
                const userCopy = new User(user);
                userCopy.current_studio_id = studio.id;
                userCopy.current_franchise_id = null;
                userCopy.current_division_id = null;
                this.store.dispatch(new userActions.UpdateAction({ user: userCopy }));
            }

            return studio;
        }), take(1));
    }

    waitForStudios() {
        return this.store.select(fromRoot.getStudioList).pipe(
            filter((studios) => studios !== null),
            take(1));
    }
}
