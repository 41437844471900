import { createSelector } from 'reselect';
import * as root from '../';
import * as fromSidenavFilter from '../sidenav-filter/sidenav-filter.reducer';

export const getSidenavFilterSelectedAssetWarehouses = createSelector(root.getSidenavFilterState, fromSidenavFilter.getSidenavFilterSelectedAssetWarehouseIds);
export const getSidenavAssetWarehouseIds = createSelector(getSidenavFilterSelectedAssetWarehouses, root.getSelectedDivisionId, (warehouses, divisionId) => {
    return warehouses && warehouses[divisionId] ? warehouses[divisionId] : [];
});

export const getSidenavFilterSelectedStorageWarehouseIds = createSelector(root.getSidenavFilterState, fromSidenavFilter.getSidenavFilterSelectedStorageWarehouseIds);
export const getSidenavStorageWarehouseIds = createSelector(getSidenavFilterSelectedStorageWarehouseIds, root.getSelectedDivisionId, (warehouses, divisionId) => {
    return warehouses && warehouses[divisionId] ? warehouses[divisionId] : [];
});
