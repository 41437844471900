import * as settingsPageActions from './settings-page.actions';

export interface State {
    projectSearch: string;
}

export const initialState: State = {
    projectSearch: null
};

export function reducer(state = initialState, action: settingsPageActions.Actions): State {
    switch (action.type) {
        case settingsPageActions.SEARCH: {
            if (action.payload !== state.projectSearch) {
                return { ...state, projectSearch: action.payload };
            }
            return state;
        }

        default: {
            return state;
        }
    }
}

export const getProjectSearch = (state: State) => state.projectSearch;
