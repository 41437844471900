import { Action } from '@ngrx/store';
import { GroupTypes } from '../models';

export const LIST = '[GroupTypes] List';
export const LIST_COMPLETE = '[GroupTypes] List Complete';
export const LIST_FAILED = '[GroupTypes] List Failed';

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: number) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: { studioId: number, groupTypes: GroupTypes[] }) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = ListAction
    | ListCompleteAction
    | ListFailedAction;
