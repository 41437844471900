import { createSelector } from 'reselect';
import * as locationActions from '../locations/location.actions';
import { ActionState, ActionStatus } from '../types';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as subLocationActions from './sub-location.actions';
import { SubLocation } from './sub-location.model';

export interface State {
    ids: number[];
    entities: {
        [subLocationId: number]: SubLocation
    };
    saving: ActionStatus;
    deleteState: ActionState;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Complete,
    deleteState: { status: ActionStatus.Inactive, error: null }
};

export function reducer(state = initialState, action: subLocationActions.Actions | locationActions.Actions): State {
    switch (action.type) {
        case locationActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case subLocationActions.ADD:
        case subLocationActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case subLocationActions.ADD_FAILED:
        case subLocationActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case subLocationActions.ADD_COMPLETE: {
            const subLocation = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [subLocation]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case subLocationActions.DELETE: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Loading, error: null }
            };
        }

        case subLocationActions.DELETE_COMPLETE: {
            const subLocationId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            const idsCopy = [...state.ids];

            delete entitiesCopy[subLocationId];
            idsCopy.splice(idsCopy.indexOf(subLocationId), 1);
            return {
                ...state,
                ids: idsCopy,
                entities: entitiesCopy,
                deleteState: { status: ActionStatus.Complete, error: null }
            };
        }

        case subLocationActions.DELETE_FAILED: {
            return {
                ...state,
                deleteState: { status: ActionStatus.Failed, error: action.payload.error }
            };
        }

        case subLocationActions.UPDATE_COMPLETE: {
            const subLocation = action.payload;
            const { ids, entities } = getIdsAndEntities(state, [subLocation]);
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);
            return {
                ...state,
                ids: sortedIds,
                entities: allEnts,
                saving: ActionStatus.Complete
            };
        }

        case locationActions.LIST_COMPLETE:
        case locationActions.LIST_BY_STUDIO_COMPLETE: {
            const locations = action.payload;
            const subLocsArr = locations.map(loc => loc.subLocations);

            const ids = [];
            const entities = {};
            subLocsArr.forEach(subLocs => {
                subLocs.forEach(subLoc => {
                    if (!state.entities[subLoc.id]) {
                        ids.push(subLoc.id);
                    }
                    entities[subLoc.id] = subLoc;
                });
            });
            const allEnts = { ...state.entities, ...entities };
            const sortedIds = copyAndMultiSort([...state.ids, ...ids], allEnts, [{ key: 'name' }], 1);

            return {
                ...state,
                ids: sortedIds,
                entities: allEnts
            };
        }

        case locationActions.DELETE_COMPLETE: {
            const locationId = action.payload.id;
            const idsToDelete = [];
            const entitiesCopy = { ...state.entities };
            Object.keys(state.entities).forEach(key => {
                const subLoc = state.entities[key];
                if (subLoc.location_id === locationId) {
                    idsToDelete.push(subLoc.id);
                    delete entitiesCopy[key];
                }
            });
            return {
                ...state,
                ids: state.ids.filter(id => idsToDelete.indexOf(id) === -1),
                entities: entitiesCopy
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getSaving = (state: State) => state.saving;
export const getDeleteState = (state: State) => state.deleteState;
