import { BaseModel } from '../base/base.model';

export class WarehouseSubLocation extends BaseModel {
    id: number;
    warehouse_location_id: number = null;
    name: string = null;

    constructor(warehouseSubLocObj) {
        super();
        this.initializeData(warehouseSubLocObj, this);
    }
}
