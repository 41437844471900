import { Action } from '@ngrx/store';
import { TransactionTypes } from '../../../../../shared/enums/transaction-types';
import { ListOptions } from '../../../../../shared/types';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { Transaction } from './transaction.model';

export const GET = '[Transaction] Get';
export const GET_COMPLETE = '[Transaction] Get Complete';
export const GET_FAILED = '[Transaction] Get Failed';

export const LIST = '[Transaction] List';
export const LIST_COMPLETE = '[Transaction] List Complete';
export const LIST_FAILED = '[Transaction] List Failed';

export const LIST_FILTERS = '[Transaction] List Filters';
export const LIST_FILTERS_COMPLETE = '[Transaction] List Filters Complete';
export const LIST_FILTERS_FAILED = '[Transaction] List Filters Failed';

export const SEND_RECONCILE_EMAILS = '[Transaction] Send Reconcile Emails';
export const SEND_RECONCILE_EMAILS_COMPLETE = '[Transaction] Send Reconcile Emails Complete';
export const SEND_RECONCILE_EMAILS_FAILED = '[Transaction] Send Reconcile Emails Failed';

export const UPDATE_MULTIPLE = '[Transaction] Update Transactions';
export const UPDATE_MULTIPLE_COMPLETE = '[Transaction] Update Transactions Complete';
export const UPDATE_MULTIPLE_FAILED = '[Transaction] Update Transactions Failed';

export const SELECT_TRANSACTIONS_SHOWN = '[Transaction] Select Transaction Types To Show';

export const SELECT = '[Transaction] Select';

export const SELECT_TO_MATCH = '[Transaction] Select to Match';

export const REMOVE = '[Transaction] Remove';

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number[]) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: Transaction[]) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: any) { }
}

export class ListAction implements Action {
    readonly type = LIST;

    constructor(public payload: { franchiseId: number, options: ListOptions }) { }
}

export class ListCompleteAction implements Action {
    readonly type = LIST_COMPLETE;

    constructor(public payload: { franchiseId: number, totalCount: number, transactions: Transaction[], options: ListOptions }) { }
}

export class ListFailedAction implements Action {
    readonly type = LIST_FAILED;

    constructor(public payload: any) { }
}

export class ListFiltersAction implements Action {
    readonly type = LIST_FILTERS;

    constructor(public payload: { franchiseId: number, transactionTypes: TransactionTypes }) { }
}

export class ListFiltersCompleteAction implements Action {
    readonly type = LIST_FILTERS_COMPLETE;

    constructor(public payload: { franchiseId: number, columnFilters: ColumnFilterOptions, columnFiltersForExcluded: ColumnFilterOptions }) { }
}

export class ListFiltersFailedAction implements Action {
    readonly type = LIST_FILTERS_FAILED;

    constructor(public payload: any) { }
}

export class SendReconcileEmailsAction implements Action {
    readonly type = SEND_RECONCILE_EMAILS;

    constructor(public payload: { projectId: number, deptIds: number[], incldueFranchiseOwners: boolean }) { }
}

export class SendReconcileEmailsCompleteAction implements Action {
    readonly type = SEND_RECONCILE_EMAILS_COMPLETE;

    constructor(public payload: { projectId: number, deptIds: number[], incldueFranchiseOwners: boolean }) { }
}

export class SendReconcileEmailsFailedAction implements Action {
    readonly type = SEND_RECONCILE_EMAILS_FAILED;

    constructor(public payload: any) { }
}

export class SelectTransactionsShown implements Action {
    readonly type = SELECT_TRANSACTIONS_SHOWN;

    constructor(public payload: TransactionTypes) { }
}

export class RemoveAction implements Action {
    readonly type = REMOVE;
    constructor(public payload: { franchiseId: number, transactionIds: number[], isExcluded: boolean }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;
    constructor(public payload: number) { }
}

export class SelectToMatchAction implements Action {
    readonly type = SELECT_TO_MATCH;
    constructor(public payload: number[]) { }
}

export class UpdateMultipleAction implements Action {
    readonly type = UPDATE_MULTIPLE;

    constructor(public payload: { franchiseId: number, transactions: Transaction[] }) { }
}

export class UpdateMultipleCompleteAction implements Action {
    readonly type = UPDATE_MULTIPLE_COMPLETE;

    constructor(public payload: Transaction[]) { }
}

export class UpdateMultipleFailedAction implements Action {
    readonly type = UPDATE_MULTIPLE_FAILED;

    constructor(public payload: any) { }
}

export type Actions
    = GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListAction
    | ListCompleteAction
    | ListFailedAction
    | ListFiltersAction
    | ListFiltersCompleteAction
    | ListFiltersFailedAction
    | SendReconcileEmailsAction
    | SendReconcileEmailsCompleteAction
    | SendReconcileEmailsFailedAction
    | SelectTransactionsShown
    | RemoveAction
    | SelectAction
    | SelectToMatchAction
    | UpdateMultipleAction
    | UpdateMultipleCompleteAction
    | UpdateMultipleFailedAction;
