import { BaseModel } from '../base/base.model';
import { SosField } from '../sos-fields/sos-field.model';

export class DepartmentField extends BaseModel {
    id: number;
    department_id: number = null;
    field_id: number = null;
    sos_field_id: number = null;
    required: boolean = null;
    created_by: number = null;
    updated_by: number = null;
    created_at: Date = null;
    updated_at: Date = null;
    sosField: SosField = null;

    constructor(deptFieldObj) {
        super();
        this.initializeData(deptFieldObj, this);
    }
}
