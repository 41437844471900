import { BaseModel } from '../base/base.model';
import { WarehouseSubLocation } from '../warehouse-sub-locations/warehouse-sub-location.model';

export class WarehouseLocation extends BaseModel {
    id: number;
    warehouse_id: number = null;
    name: string = null;
    warehouseSubLocations?: WarehouseSubLocation[];

    constructor(warehouseLocationObj) {
        super();
        this.initializeData(warehouseLocationObj, this);
        if (warehouseLocationObj.warehouseSubLocations) {
            this.warehouseSubLocations = warehouseLocationObj.warehouseSubLocations.map(wsl => new WarehouseSubLocation(wsl));
        }
    }
}
