import { createSelector } from 'reselect';
import * as franchiseActions from '../franchises/franchise.actions';
import * as projectActions from './project.actions';
import { Project } from './project.model';

export interface State {
    ids: number[];
    entities: {
        [projectId: number]: Project;
    };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: projectActions.Actions | franchiseActions.Actions): State {
    switch (action.type) {
        case franchiseActions.ADD_COMPLETE: {
            const { entities, ids } = action.payload.projects.reduce((acc, p) => {
                if (!state.entities[p.id]) {
                    acc.ids.push(p.id);
                }
                acc.entities[p.id] = p;
                return acc;
            }, { ids: [], entities: {} });
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }
        case franchiseActions.LIST_FRANCHISE_DIVISION_USER_COMPLETE:
        case franchiseActions.LIST_COMPLETE: {
            const franchises = action.payload.franchises;
            const projectsArr = franchises.map(f => f.projects);
            const { entities, ids } = projectsArr.reduce((acc, projs) => {
                projs.forEach(p => {
                    if (!state.entities[p.id]) {
                        acc.ids.push(p.id);
                    }
                    acc.entities[p.id] = p;
                });
                return acc;
            }, { ids: [], entities: {} });
            if (action.payload.onlyOwnerOptions) {
                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: { ...state.entities, ...entities }
                };
            } else {
                return {
                    ...state,
                    ids: [...state.ids, ...ids],
                    entities: { ...state.entities, ...entities }
                };
            }
        }

        case franchiseActions.GET_COMPLETE: {
            const franchise = action.payload;
            const ids = [];
            const entities = {};
            franchise.projects.forEach(p => {
                if (!state.entities[p.id]) {
                    ids.push(p.id);
                }
                entities[p.id] = p;
            });

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        case projectActions.ADD_COMPLETE: {
            const project = action.payload.project;
            const ids = [];
            if (!state.entities[project.id]) {
                ids.push(project.id);
            }
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, [project.id]: project }
            };
        }

        case projectActions.UPDATE_COMPLETE: {
            const project = action.payload;

            const alterStateWith = {
                entities: { ...state.entities, [project.id]: project },
            };
            return { ...state, ...alterStateWith };
        }

        case projectActions.ADD:
        case projectActions.ADD_FAILED:
        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
