import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store, StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { filter, take } from 'rxjs/operators';
import version from '../../shared/constants/version.constant';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import * as fromRoot from './core/store';
import { metaReducers, reducers } from './core/store/index';
import * as userActions from './core/store/users/user.actions';
import { EffectsModule } from './effects/effects.module';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './material/material.module';
import { httpInterceptorProviders } from './http-interceptors';

@Injectable()
class SentryErrorHandler extends ErrorHandler {
    constructor() {
        super();
    }

    handleError(error) {
        Sentry.captureException(error.originalError || error);
        return super.handleError(error);
    }
}

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false }
    };
}

const providers: any = [
    {
        provide: APP_INITIALIZER,
        deps: [Store],
        multi: true,
        useFactory: run,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }
];

if (!environment.local || environment.sentryDebug) {
    Sentry.init({
        dsn: 'https://031d2fb8ca444601a256dcf9b2616345@sentry.io/194024',
        environment: environment.envName,
        release: version
    });
    providers.push({
        provide: ErrorHandler,
        useClass: SentryErrorHandler
    });
}

providers.push(httpInterceptorProviders);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        EffectsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'AH_CSRF_TOKEN',
            headerName: 'X-CSRF-TOKEN',
        }),
        LayoutModule,
        MaterialModule,
        StoreModule.forRoot(reducers, { metaReducers }),
    ],
    providers,
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function run(store: Store<fromRoot.State>) {
    return runBlock;

    function runBlock() {
        store.dispatch(new userActions.GetAction());

        // We need to wait for the user fetch to complete or fail,
        // but can't filter because we need this promise to complete either way
        return store.select(fromRoot.getUserState).pipe(
            filter((state) => state.complete),
            take(1))
            .toPromise();
    }
}