import * as assetModalActions from './asset-modal.actions';

export interface State {
    selectedDepartmentId: number;
    selectedDivisionId: number;
    selectedEventId: number;
    selectedLocationId: number;
    selectedFranchiseId: number;
    selectedProjectId: number;
    selectedWarehouseId: number;
    selectedWarehouseLocationId: number;
    selectedWarehouseSubLocationId: number;
    selectedStatusId: number;
    selectedSubLocationId: number;
    selectedSubDepartmentId: number;
    selectedDispositionId: number;
    selectedCharacterId: number;
    selectedSetId: number;
    selectedGroupIds: number[];
}

export const initialState: State = {
    selectedDepartmentId: null,
    selectedDivisionId: null,
    selectedEventId: null,
    selectedLocationId: null,
    selectedFranchiseId: null,
    selectedProjectId: null,
    selectedWarehouseId: null,
    selectedWarehouseLocationId: null,
    selectedWarehouseSubLocationId: null,
    selectedStatusId: null,
    selectedSubLocationId: null,
    selectedSubDepartmentId: null,
    selectedDispositionId: null,
    selectedCharacterId: null,
    selectedSetId: null,
    selectedGroupIds: []
};

export function reducer(state = initialState, action: assetModalActions.Actions): State {
    switch (action.type) {
        case assetModalActions.SELECT_DEPARTMENT: {
            return selectEntity('selectedDepartmentId', action.payload, state);
        }

        case assetModalActions.SELECT_DIVISION: {
            return selectEntity('selectedDivisionId', action.payload, state);
        }

        case assetModalActions.SELECT_EVENT: {
            return selectEntity('selectedEventId', action.payload, state);
        }

        case assetModalActions.SELECT_LOCATION: {
            return selectEntity('selectedLocationId', action.payload, state);
        }

        case assetModalActions.SELECT_FRANCHISE: {
            const newState = selectEntity('selectedFranchiseId', action.payload, state);
            return {
                ...newState,
                selectedLocationId: null
            };
        }

        case assetModalActions.SELECT_PROJECT: {
            return selectEntity('selectedProjectId', action.payload, state);
        }

        case assetModalActions.SELECT_WAREHOUSE: {
            const newState = selectEntity('selectedWarehouseId', action.payload, state);
            return {
                ...newState,
                selectedWarehouseLocationId: null
            };
        }

        case assetModalActions.SELECT_WAREHOUSE_LOCATION: {
            return selectEntity('selectedWarehouseLocationId', action.payload, state);
        }

        case assetModalActions.SELECT_WAREHOUSE_SUB_LOCATION: {
            return selectEntity('selectedWarehouseSubLocationId', action.payload, state);
        }

        case assetModalActions.SELECT_STATUS: {
            return selectEntity('selectedStatusId', action.payload, state);
        }

        case assetModalActions.SELECT_SUB_LOCATION: {
            return selectEntity('selectedSubLocationId', action.payload, state);
        }

        case assetModalActions.SELECT_SUB_DEPARTMENT: {
            return selectEntity('selectedSubDepartmentId', action.payload, state);
        }

        case assetModalActions.SELECT_DISPOSITION: {
            return selectEntity('selectedDispositionId', action.payload, state);
        }

        case assetModalActions.SELECT_CHARACTER: {
            return selectEntity('selectedCharacterId', action.payload, state);
        }

        case assetModalActions.SELECT_SET: {
            return selectEntity('selectedSetId', action.payload, state);
        }

        case assetModalActions.SELECT_GROUPS: {
            return selectEntity('selectedGroupIds', action.payload, state);
        }

        default: {
            return state;
        }
    }

}

function selectEntity(name: string, payload: number | number[], state: State) {
    let newState = state;
    if (payload !== state[name]) {
        newState = {
            ...state,
            [name]: payload
        };
    }
    return newState;
}

export const getSelectedDepartmentId = (state: State) => state.selectedDepartmentId;
export const getSelectedDivisionId = (state: State) => state.selectedDivisionId;
export const getSelectedEventId = (state: State) => state.selectedEventId;
export const getSelectedLocationId = (state: State) => state.selectedLocationId;
export const getSelectedFranchiseId = (state: State) => state.selectedFranchiseId;
export const getSelectedProjectId = (state: State) => state.selectedProjectId;
export const getSelectedWarehouseId = (state: State) => state.selectedWarehouseId;
export const getSelectedWarehouseLocationId = (state: State) => state.selectedWarehouseLocationId;
export const getSelectedWarehouseSubLocationId = (state: State) => state.selectedSubLocationId;
export const getSelectedStatusId = (state: State) => state.selectedStatusId;
export const getSelectedSubLocationId = (state: State) => state.selectedSubLocationId;
export const getSelectedDispositionId = (state: State) => state.selectedDispositionId;
export const getSelectedCharacterId = (state: State) => state.selectedCharacterId;
export const getSelectedSetId = (state: State) => state.selectedSetId;
export const getSelectedGroupIds = (state: State) => state.selectedGroupIds;
