import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RemoveModalComponent } from '../../../shared/components/remove-modal/remove-modal.component';
import * as layoutActions from '../layout/layout.actions';
import * as dispositionActions from './disposition.actions';
import { Disposition } from './disposition.model';

@Injectable()
export class DispositionEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.LIST),
            map((action: dispositionActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<Disposition[]>(`/api/studios/${studioId}/dispositions`).pipe(
                map(res => res.map((u) => new Disposition(u))),
                map((dispositions: Disposition[]) => new dispositionActions.ListCompleteAction(dispositions)),
                catchError((error) => observableOf(new dispositionActions.ListFailedAction({ error }))))
            )));

     listFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.LIST_FAILED),
            map((action: dispositionActions.ListFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

     create$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.ADD),
            map((action: dispositionActions.AddAction) => action.payload),
            switchMap((disposition) => this.http.post(`/api/studios/${disposition.studio_id}/disposition`, disposition).pipe(
                map((res) => new dispositionActions.AddCompleteAction(new Disposition(res))),
                catchError(error => {
                    return observableOf(new dispositionActions.AddFailedAction({ error }))
                }))
            )));

     addFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.ADD_FAILED),
            map((action: dispositionActions.AddFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.UPDATE),
            map((action: dispositionActions.UpdateAction) => action.payload),
            mergeMap((disposition) => this.http.put(`/api/studios/${disposition.studio_id}/disposition`, disposition).pipe(
                map((res) => new dispositionActions.UpdateCompleteAction(new Disposition(res))),
                catchError(error => {
                    return observableOf(new dispositionActions.UpdateFailedAction({ error }))
                }))
            )));

     updateComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.UPDATE_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Disposition has been updated.' }))));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.DELETE),
            map((action: dispositionActions.DeleteAction) => action.payload),
            switchMap((id) => this.http.delete(`/api/studios/disposition/${id}`).pipe(
                map((assets: any[]) => {
                    if (assets.length > 0) {
                        // dispostion is being used
                        this.dialog.open(RemoveModalComponent, {
                            data: {
                                title: "Cannot Remove Disposition",
                                message: "This Disposition cannot be removed because it is currently associated with one or more Assets in the following locations:",
                                assets: assets
                            }
                        });
                        return new layoutActions.InfoDisplayedAction();
                    }
                    else {
                        return new dispositionActions.DeleteCompleteAction(id);
                    }
                }),
                catchError((error) => observableOf(new dispositionActions.DeleteFailedAction({ error }))))
            )));

     deleteComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.DELETE_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Disposition has been deleted.' }))));

     updateFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(dispositionActions.UPDATE_FAILED, dispositionActions.DELETE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient, private dialog: MatDialog) { }
}
