import { createSelector } from 'reselect';
import * as customerActions from '../customers/customer.actions';
import { ActionStatus, SortObject } from '../types';
import { getEntitiesObject, getIdsAndEntities } from '../utils';
import * as eventActions from './event.actions';
import { Event } from './event.model';

export interface State {
    ids: number[];
    entities: { [eventId: number]: Event };
    gettingOverlap: ActionStatus;
    loading: ActionStatus;
    numOverlapAssets: number;
    saving: ActionStatus;
    selectedId: number;
    sort: SortObject;
}

export const initialState: State = {
    ids: [],
    entities: {},
    gettingOverlap: ActionStatus.Inactive,
    loading: ActionStatus.Inactive,
    numOverlapAssets: null,
    saving: ActionStatus.Complete,
    selectedId: null,
    sort: null
};

export function reducer(state = initialState, action: customerActions.Actions | eventActions.Actions): State {
    switch (action.type) {
        case customerActions.ADD_TO_EVENT_COMPLETE: {
            const event = action.payload.event;
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [event.id]: event
                }
            };
        }

        case eventActions.GET_OVERLAP_ASSETS: {
            return { ...state, gettingOverlap: ActionStatus.Loading, saving: ActionStatus.Loading };
        }

        case eventActions.CLONE:
        case eventActions.ADD:
        case eventActions.UPDATE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case eventActions.GET_OVERLAP_ASSETS_FAILED: {
            return { ...state, gettingOverlap: ActionStatus.Failed };
        }

        case eventActions.SET_OVERLAP_ASSETS_INACTIVE: {
            return { ...state, gettingOverlap: ActionStatus.Inactive };
        }

        case eventActions.CLONE_FAILED:
        case eventActions.ADD_FAILED:
        case eventActions.UPDATE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case eventActions.LIST_BY_DIVISION: {
            return { ...state, ids: [], entities: {}, loading: ActionStatus.Loading };
        }

        case eventActions.LIST_BY_DIVISION_FAILED: {
            return { ...state, loading: ActionStatus.Failed };
        }

        case eventActions.LIST_BY_DIVISION_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, action.payload);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities,
                },
                loading: ActionStatus.Complete,
                saving: ActionStatus.Loading
            };
        }

        case eventActions.CLONE_COMPLETE:
        case eventActions.ADD_COMPLETE: {
            const { ids, entities } = getIdsAndEntities(state, [action.payload]);

            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: {
                    ...state.entities,
                    ...entities,
                },
                saving: ActionStatus.Complete
            };
        }

        case eventActions.GET_COMPLETE: {
            const newEvent = action.payload.event;
            const eventObj = getEntitiesObject([newEvent]);

            return {
                ...state,
                ids: [...state.ids, newEvent.id],
                entities: {
                    ...state.entities,
                    ...eventObj,
                }
            };
        }

        case eventActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }
            return newState;
        }

        case eventActions.SORT: {
            const sortData = { field: action.payload.field, order: action.payload.order, args: (action.payload.args ? action.payload.args : []) };

            if (state.sort
                && state.sort.args === sortData.args
                && state.sort.field === sortData.field
                && state.sort.order === sortData.order) {
                // Cancel sorting
                return state;
            }

            return {
                ...state,
                sort: sortData
            };
        }

        case eventActions.UPDATE_COMPLETE: {
            const event = action.payload;
            const entitiesUpdate = { [event.id]: event };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entitiesUpdate
                },
                saving: ActionStatus.Complete
            };
        }

        case eventActions.GET_OVERLAP_ASSETS_COMPLETE: {
            const numOverlapAssets = action.payload;
            return {
                ...state,
                numOverlapAssets,
                gettingOverlap: ActionStatus.Complete
            };
        }

        case eventActions.DELETE_COMPLETE: {
            const eventId = action.payload.id;
            const entitiesCopy = { ...state.entities };
            delete entitiesCopy[eventId];

            return {
                ...state,
                ids: state.ids.filter(id => id !== eventId),
                entities: entitiesCopy,
            };
        }
        case eventActions.ADD_ASSETS: {
            return { ...state, saving: ActionStatus.Loading }
        }
        case eventActions.ADD_ASSETS_COMPLETE: {
            return {
                ...state,
                saving: ActionStatus.Complete
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getLoading = (state: State) => state.loading;
export const getSaving = (state: State) => state.saving;
export const getGettingOverlap = (state: State) => state.gettingOverlap;
export const getNumOverlapAssets = (state: State) => state.numOverlapAssets;
export const getSelectedEventId = (state: State) => state.selectedId;
export const getSelected = createSelector(getEntities, getSelectedEventId, (entities, selectedId) => entities[selectedId]);
export const getSort = (state: State) => state.sort;
