import { createSelector } from 'reselect';
import { Entities } from '../../../../../shared/types';
import { sosMoment } from '../../../shared/services/moment.service';
import * as departmentActions from '../departments/department.actions';
import * as divisionGroupActions from '../division-groups/division-group.actions';
import * as eventActions from '../events/event.actions';
import * as franchiseGroupActions from '../franchise-groups/franchise-group.actions';
import * as franchiseActions from '../franchises/franchise.actions';
import * as groupActions from '../groups/group.actions';
import * as requestAssetActions from '../request-assets/request-asset.actions';
import * as requestActions from '../requests/request.actions';
import * as storageBoxActions from '../storage-boxes/storage-box.actions';
import { ActionStatus, TableTypes } from '../types';
import { getEntitiesObject, getIdsAndEntities, parseHttpErrorResponse } from '../utils';
import * as assetActions from './asset.actions';
import * as assetImportsAction from '../asset-imports/asset-imports.actions';
import { Asset, AssetTypes } from './asset.model';
import { LIMIT } from '../../../../../shared/constants/asset-limit.constant';
import * as franchiseSubGroupActions from '../franchise-subgroup/franchise-subgroup.actions';
import * as divisionSubGroupActions from '../division-subgroups/division-subgroup.actions';
import * as subGroupActions from '../sub-groups/sub-group.actions';

export interface State {
    entities: {
        [assetId: number]: Asset
    };
    divisionIds: { [divisionId: number]: number[] };
    franchiseIds: { [franchiseId: number]: number[] };
    reviewIds: { [franchiseId: number]: number[] };
    divisionReviewIds: { [divisionId: number]: number[] };
    studioIds: { [studioId: number]: number[] };
    divisionTotalCount: { [divisionId: number]: number };
    franchiseTotalCount: { [franchiseId: number]: number; };
    reviewTotalCount: { [franchiseId: number]: number; };
    divisionReviewTotalCount: { [divisionId: number]: number };
    studioTotalCount: { [studioId: number]: number };
    getting: ActionStatus;
    loading: ActionStatus;
    saving: ActionStatus;
    adding: ActionStatus;
    selectedAssetId: number;
    selectedInlineEditAssetId: number;
    indexAssetsShown: AssetTypes;
    cacheLastValidated: {
        [divisionId: number]: any
    };
    limit: number;
    updatedFieldAssetCount: number;
    error: any;
}

export const initialState: State = {
    entities: {},
    divisionIds: {},
    franchiseIds: {},
    reviewIds: {},
    divisionReviewIds: {},
    studioIds: {},
    divisionTotalCount: {},
    franchiseTotalCount: {},
    reviewTotalCount: {},
    divisionReviewTotalCount: {},
    studioTotalCount: {},
    adding: ActionStatus.Complete,
    getting: ActionStatus.Complete,
    loading: ActionStatus.Complete,
    saving: ActionStatus.Complete,
    selectedAssetId: null,
    selectedInlineEditAssetId: null,
    indexAssetsShown: AssetTypes.All,
    cacheLastValidated: {},
    limit: LIMIT,
    updatedFieldAssetCount: 0,
    error: {}
};

export function reducer(state = initialState, action: assetActions.Actions | eventActions.Actions | franchiseActions.Actions | departmentActions.Actions | groupActions.Actions | divisionGroupActions.Actions | franchiseGroupActions.Actions | subGroupActions.Actions | divisionSubGroupActions.Actions | franchiseSubGroupActions.Actions | requestActions.Actions | storageBoxActions.Actions | requestAssetActions.Actions | assetImportsAction.Actions): State {
    switch (action.type) {
        case assetActions.ADD:
        case assetActions.ADD_FROM_TRANSACTION:
        case assetActions.DUPLICATE_ASSET: {
            return { ...state, saving: ActionStatus.Loading, adding: ActionStatus.Loading };
        }

        case assetActions.DUPLICATE_ASSET:
        case eventActions.REMOVE_ASSETS:
        case requestAssetActions.DELETE:
        case assetActions.UPDATE_MULTIPLE:
        case assetActions.UPDATE_FIELD_MULTIPLE:
        case assetActions.MATCH_ASSETS:
        case assetActions.MERGE_ASSETS: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case requestAssetActions.DELETE_COMPLETE:
        case eventActions.REMOVE_ASSETS_COMPLETE: {
            return { ...state, saving: ActionStatus.Complete };
        }

        case requestAssetActions.DELETE_FAILED:
        case eventActions.REMOVE_ASSETS_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case assetActions.GET: {
            return { ...state, getting: ActionStatus.Loading };
        }

        case assetActions.LIST_BY_DIVISION:
        case assetActions.LIST_BY_EVENT:
        case assetActions.LIST_BY_FINAL_APPROVER_REQUEST:
        case assetActions.LIST_BY_FRANCHISE:
        case assetActions.LIST_BY_REQUEST:
        case assetActions.LIST_BY_STUDIO:
        case assetActions.LIST_BY_GROUP: {
            return { ...state, loading: ActionStatus.Loading };
        }

        case assetActions.GET_COMPLETE: {
            const newAsset = action.payload;
            const { entities } = getIdsAndEntities(state, [newAsset]);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                getting: ActionStatus.Complete
            };
        }

        case assetActions.ADD_PHOTO: {
            const asset = state.entities[action.payload.asset_id];
            const newAsset = new Asset(asset);
            newAsset.photos = [...newAsset.photos, ...action.payload.photos];

            return {
                ...state,
                entities: { ...state.entities, ...{ [newAsset.id]: newAsset } }
            };
        }

        case assetActions.ADD_PHOTOS: {
            let modifiedAssets: Asset[] = [];
            for (let assetId of action.payload.assets) {
                const asset = state.entities[assetId];
                const newAsset = new Asset(asset);
                newAsset.photos = [...newAsset.photos, ...action.payload.photos];
                modifiedAssets.push(newAsset);
            }

            const entities: Entities<Asset> = getEntitiesObject(modifiedAssets);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                }
            };
        }

        case assetActions.DELETE_PHOTO_COMPLETE: {
            const asset = state.entities[action.payload.asset_id];
            const newAsset = new Asset(asset);

            newAsset.photos = asset.photos.filter(photo => photo.id !== action.payload.id);

            return {
                ...state,
                entities: { ...state.entities, ...{ [newAsset.id]: newAsset } }
            };
        }

        case assetActions.SET_MAIN_PHOTO_COMPLETE: {
            const newAsset = new Asset(action.payload);

            return {
                ...state,
                entities: { ...state.entities, ...{ [newAsset.id]: newAsset } }
            };
        }

        case assetActions.ROTATE_PHOTO_COMPLETE: {
            const newAsset = new Asset(action.payload);

            return {
                ...state,
                entities: { ...state.entities, ...{ [newAsset.id]: newAsset }, }
            };
        }

        case assetActions.ADD_FAILED:
        case assetActions.DUPLICATE_ASSET_FAILED: {
            return {
                ...state,
                adding: ActionStatus.Failed,
                saving: ActionStatus.Failed
            };
        }

        case assetActions.MATCH_ASSETS_FAILED:
        case assetActions.MERGE_ASSETS_FAILED:
        case assetActions.UPDATE_FAILED:
        case assetActions.UPDATE_MULTIPLE_FAILED:
        case assetActions.UPDATE_FIELD_MULTIPLE_FAILED:
        case eventActions.ADD_ASSETS_FAILED:
        case franchiseGroupActions.ADD_TO_FRANCHISE_GROUP_FAILED:
        case divisionGroupActions.ADD_TO_DIVISION_GROUP_FAILED:
        case assetActions.ROTATE_PHOTO_FAILED: {
            return { ...state, saving: parseHttpErrorResponse(action.payload.error) };
        }

        case assetActions.GET_FAILED:
        case assetActions.LIST_BY_DIVISION_FAILED:
        case assetActions.LIST_BY_EVENT_FAILED:
        case assetActions.LIST_BY_FINAL_APPROVER_REQUEST_FAILED:
        case assetActions.LIST_BY_FRANCHISE_FAILED:
        case assetActions.LIST_BY_REQUEST_FAILED:
        case assetActions.LIST_BY_STUDIO_FAILED:
        case assetActions.LIST_BY_FRANCHISE_FAILED: {
            return { ...state, loading: ActionStatus.Failed };
        }

        case assetActions.ADD_FROM_TRANSACTION_FAILED: {
            let errObj = action.payload.error;
            let errMessage = errObj.message;
            if (errObj.error) {
                errMessage = errObj.error.message;
            }
            return { ...state, error: errMessage };
        }

        case assetActions.LIST_BY_FRANCHISE_COMPLETE: {
            const assets = action.payload.assets;
            const { allIds, entities } = getIdsAndEntities(state, assets);
            const listOptions = action.payload.options;
            const totalCount = action.payload.totalCount;

            const alterWith: Partial<State> = {};
            const franchiseId = action.payload.franchiseId;
            const reviewIds = { ...state.reviewIds };
            const franchiseIds = { ...state.franchiseIds };
            if (listOptions.forReview) {
                alterWith.reviewTotalCount = { ...state.reviewTotalCount, [franchiseId]: totalCount };
                reviewIds[franchiseId] = allIds;
            } else {
                alterWith.franchiseTotalCount = { ...state.franchiseTotalCount, [franchiseId]: totalCount };
                franchiseIds[franchiseId] = allIds;
            }

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                loading: ActionStatus.Complete,
                ...alterWith,
                franchiseIds,
                reviewIds,
            };
        }

        case assetActions.LIST_BY_DIVISION_COMPLETE: {
            const assets = action.payload.assets;
            const { allIds, entities } = getIdsAndEntities(state, assets);
            const divisionId = action.payload.divisionId;
            const listOptions = action.payload.options;
            const totalCount = action.payload.totalCount;

            const alterWith: Partial<State> = {};
            const divisionIds = { ...state.divisionIds };
            const divisionReviewIds = { ...state.divisionReviewIds };
            if (listOptions.forReview) {
                alterWith.divisionReviewTotalCount = { ...state.divisionReviewTotalCount, [divisionId]: totalCount };
                divisionReviewIds[divisionId] = allIds;
            } else {
                alterWith.divisionTotalCount = { ...state.divisionTotalCount, [divisionId]: totalCount }
                divisionIds[divisionId] = allIds;
            }
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                divisionIds,
                divisionReviewIds,
                ...alterWith,
                loading: ActionStatus.Complete,
                cacheLastValidated: {
                    ...state.cacheLastValidated,
                    [divisionId]: sosMoment()
                }
            };
        }

        case assetActions.LIST_BY_STUDIO_COMPLETE: {
            const assets = action.payload.assets;
            const { allIds, entities } = getIdsAndEntities(state, assets);
            const totalCount = action.payload.totalCount;

            const studioId = action.payload.studioId;
            const studioIds = { ...state.studioIds, [studioId]: allIds };
            const studioTotalCount = { ...state.studioTotalCount, [studioId]: totalCount };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                loading: ActionStatus.Complete,
                studioTotalCount,
                studioIds
            };
        }

        case assetActions.ADD_COMPLETE:
        case assetActions.DUPLICATE_ASSET_COMPLETE: {
            const assets = action.payload.assets;

            const alterWith: Partial<State> = {};
            if (action.type === assetActions.ADD_COMPLETE) {
                const tableType = action.payload.tableType;
                if (tableType === TableTypes.FranchiseTable) {
                    const franchiseId = assets[0].franchise_id;
                    alterWith.franchiseTotalCount = { ...state.franchiseTotalCount, [franchiseId]: state.franchiseTotalCount[franchiseId] + assets.length };
                    alterWith.franchiseIds = { ...state.franchiseIds, [franchiseId]: [assets[0].id, ...(state.franchiseIds[franchiseId] || [])] };
                } else if (tableType === TableTypes.DivisionTable) {
                    const divisionId = assets[0].division_id;
                    alterWith.divisionTotalCount = { ...state.divisionTotalCount, [divisionId]: state.divisionTotalCount[divisionId] + assets.length };
                    alterWith.divisionIds = { ...state.divisionIds, [divisionId]: [assets[0].id, ...state.divisionIds[divisionId]] };
                }
            }

            const { entities } = getIdsAndEntities(state, assets);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                adding: ActionStatus.Complete,
                saving: ActionStatus.Complete,
                ...alterWith,
                error: {}
            };
        }

        case assetActions.ADD_FROM_TRANSACTION_COMPLETE:
            return {
                ...state,
                entities: {
                    ...state.entities,
                },
                adding: ActionStatus.Complete,
                saving: ActionStatus.Complete,
                error: {}
            }

        case assetActions.LIST_BY_EVENT_COMPLETE:
        case assetActions.LIST_BY_FINAL_APPROVER_REQUEST_COMPLETE:
        case assetActions.LIST_BY_REQUEST_COMPLETE:
        case assetActions.LIST_BY_GROUP_COMPLETE: {
            const assets = action.payload.assets;
            const { entities } = getIdsAndEntities(state, assets);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                loading: ActionStatus.Complete
            };
        }

        case assetActions.LIST_BY_SUB_GROUP_COMPLETE: {
            const assets = action.payload.assets;
            const { entities } = getIdsAndEntities(state, assets);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                loading: ActionStatus.Complete
            };
        }

        case divisionGroupActions.ADD_TO_DIVISION_GROUP_COMPLETE:
        case franchiseGroupActions.ADD_TO_FRANCHISE_GROUP_COMPLETE: {
            return {
                ...state,
                loading: ActionStatus.Complete
            };
        }

        case assetActions.ADD_FOR_EVENT_COMPLETE:
        case eventActions.ADD_ASSETS_COMPLETE: {
            const assets = action.payload.assets;
            const { entities } = getIdsAndEntities(state, assets);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                adding: ActionStatus.Complete,
                saving: ActionStatus.Complete
            };
        }

        case eventActions.REMOVE_ASSETS_COMPLETE: {
            return {
                ...state,
                loading: ActionStatus.Complete
            };
        }

        case assetActions.UPDATE_COMPLETE: {
            const error = action.payload?.warning;
            if (error) {
                return { ...state, error, saving: ActionStatus.Failed }
            }
            const updatedAssets: Asset[] = action.payload.assets;
            const entities: Entities<Asset> = getEntitiesObject(updatedAssets);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                saving: ActionStatus.Complete,
                error: {}
            };
        }

        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const error = action.payload?.warning;
            if (error) {
                return { ...state, error, saving: ActionStatus.Failed }
            }
            const updatedAssets: Asset[] = action.payload.assets;
            const entities: Entities<Asset> = getEntitiesObject(updatedAssets);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entities
                },
                saving: ActionStatus.Complete,
                error: {}
            };
        }

        case assetActions.UPDATE_FIELD_MULTIPLE_COMPLETE:
            {
                const updatedAssets: Asset[] = action.payload;
                const entities: Entities<Asset> = getEntitiesObject(updatedAssets);

                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        ...entities
                    },
                    updatedFieldAssetCount: updatedAssets.length,
                    saving: ActionStatus.Complete
                };
            }

        case assetActions.MERGE_ASSETS_COMPLETE: {
            const updatedAsset: Asset = action.payload.asset;
            const trashedAssetIds = action.payload.assetIds;
            const tableType = action.payload.tableType;
            const entities = { ...state.entities };
            entities[updatedAsset.id] = updatedAsset;
            for (const trashedAssetId of trashedAssetIds) {
                delete entities[trashedAssetId];
            }
            const alterWith: Partial<State> = {};

            // Remove trashed asset from ids and update count based on table type
            if (tableType === TableTypes.FranchiseTable) {
                const franchiseId = updatedAsset.franchise_id;
                alterWith.franchiseTotalCount = { ...state.franchiseTotalCount, [franchiseId]: state.franchiseTotalCount[franchiseId] - 1 };
                alterWith.franchiseIds = {
                    ...state.franchiseIds,
                    [franchiseId]: state.franchiseIds[franchiseId].filter(id => !trashedAssetIds.includes(id))
                };
            } else if (tableType === TableTypes.DivisionTable) {
                const divisionId = updatedAsset.division_id;
                alterWith.divisionTotalCount = { ...state.divisionTotalCount, [divisionId]: state.divisionTotalCount[divisionId] - 1 };
                alterWith.divisionIds = {
                    ...state.divisionIds,
                    [divisionId]: state.divisionIds[divisionId].filter(id => !trashedAssetIds.includes(id))
                };
            }

            return {
                ...state,
                entities,
                saving: ActionStatus.Complete,
                ...alterWith
            };
        }

        case assetActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedAssetId) {
                newState = {
                    ...state,
                    selectedAssetId: action.payload
                };
            }

            return newState;
        }

        case assetActions.SELECT_INLINE_EDIT: {
            let newState = state;
            if (action.payload !== state.selectedInlineEditAssetId) {
                newState = {
                    ...state,
                    selectedInlineEditAssetId: action.payload
                };
            }

            return newState;
        }

        case assetActions.MATCH_ASSETS_COMPLETE: {
            return { ...state, saving: ActionStatus.Complete };
        }

        case assetActions.SELECT_ASSETS_SHOWN: {
            return { ...state, indexAssetsShown: action.payload };
        }

        case storageBoxActions.UPDATE_COMPLETE: {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...getEntitiesObject(action.payload.assets)
                }
            };
        }

        case assetActions.SET_ASSET_LIMIT: {
            return { ...state, limit: action.payload };
        }

        case assetImportsAction.ADD_ASSETS_IMPORTS_COMPLETE: {
            let newState = state;
            if (action.payload.franchiseId) {
                newState = {
                    ...state,
                    reviewTotalCount: { ...state.reviewTotalCount, [action.payload.franchiseId]: action.payload.assets.length }
                };
            }
            else if (action.payload.divisionId) {
                newState = {
                    ...state,
                    divisionReviewTotalCount: { ...state.divisionReviewTotalCount, [action.payload.divisionId]: action.payload.assets.length }
                };
            }
            return newState;
        }

        default: {
            return state;
        }
    }
}

const selectedAssetId = (state: State) => state.selectedAssetId;
const selectedInlineEditAssetId = (state: State) => state.selectedInlineEditAssetId;
export const getEntities = (state: State) => state.entities;
export const getDivisionIds = (state: State) => state.divisionIds;
export const getFranchiseIds = (state: State) => state.franchiseIds;
export const getReviewIds = (state: State) => state.reviewIds;
export const getDivisionReviewIds = (state: State) => state.divisionReviewIds;
export const getStudioIds = (state: State) => state.studioIds;
export const getSelectedAsset = createSelector(selectedAssetId, getEntities, (selectedId, entities) => {
    if (selectedId && entities[selectedId]) {
        return entities[selectedId];
    }
    return null;
});
export const getSelectedInlineEditAsset = createSelector(selectedInlineEditAssetId, getEntities, (selectedId, entities) => {
    if (selectedId && entities[selectedId]) {
        return entities[selectedId];
    }
    return null;
});

export const getSaving = (state: State) => state.saving;
export const getLoading = (state: State) => state.loading;
export const getGetting = (state: State) => state.getting;
export const getAdding = (state: State) => state.adding;
export const getAssetsShown = (state: State) => state.indexAssetsShown;
export const getAssetCountsForDivision = (state: State) => state.divisionTotalCount;
export const getAssetCountsForFranchise = (state: State) => state.franchiseTotalCount;
export const getReviewAssetCountsForFranchise = (state: State) => state.reviewTotalCount;
export const getReviewAssetCountForDivisoin = (state: State) => state.divisionReviewTotalCount;
export const getCacheLastValidated = (state: State) => state.cacheLastValidated;
export const getAssetLimit = (state: State) => state.limit;
export const getUpdatedFieldAssetCount = (state: State) => state.updatedFieldAssetCount;
export const getErrorMovingAsset = (state: State) => state.error;
export const getErrorSavingAsset = (state: State) => state.error;