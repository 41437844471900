import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Event } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { fromEvent as observableFromEvent, merge as observableMerge, Observable, of as observableOf, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { NAV_TYPES } from '../../../shared/enums/nav-types.enum';
import * as fromRoot from '../core/store';
import * as divisionActions from '../core/store/divisions/division.actions';
import * as franchiseActions from '../core/store/franchises/franchise.actions';
import * as layoutActions from '../core/store/layout/layout.actions';
import { CustomStudioFieldName } from '../core/store/models';
import { layoutSelectors } from '../core/store/selectors';
import { NavShowFilterOptions } from '../core/store/nav/nav.reducer';
import * as studioActions from '../core/store/studios/studio.actions';
import { Studio } from '../core/store/studios/studio.model';
import { ActionStatus } from '../core/store/types';
import * as userActions from '../core/store/users/user.actions';
import { User } from '../core/store/users/user.model';
import { StudioDataService } from '../shared/data-services/studio-data.service';
import { UserDataService } from '../shared/data-services/user-data.service';
import { IntercomService } from '../shared/services/intercom.service';
import { PendoService } from '../shared/services/pendo.service';
import { redirectToLogin, redirectToLogout } from '../shared/services/user.service';
import { SidenavFilterService } from './sidenav-filter/sidenav-filter.service';
import { TimeoutModalComponent } from './timeout-modal/timeout-modal.component';

@Component({
    selector: 'ah-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    providers: [Idle, SidenavFilterService]
})
export class LayoutComponent implements OnInit, OnDestroy {
    navType: NAV_TYPES;
    customFieldNames: Observable<CustomStudioFieldName>;
    filtersSelected = false;
    isIdle = false;
    isMobile = false;
    navMode = 'side';
    sidenavFilterOptionsVisibility: NavShowFilterOptions;
    navWidth = 90;
    secondsToIdle = 1500;
    secondsToTimeout = 300;
    studioList: Observable<Studio[]>;
    user: Observable<User>;
    sub: Subscription;
    authenticated = false;

    private userDataServiceSub;
    private sidenavFilterOptionsSub;
    private getAllStudiosSub;
    private routerEventSub;
    private navTypeSub;
    private onlineSub;
    private breakpointSub;
    private navShowHideSub;
    private filterSelectedSub;
    private idleSub;
    private path;

    constructor(private activatedRoute: ActivatedRoute, private breakpointObserver: BreakpointObserver, private idle: Idle, public dialog: MatDialog, private intercomService: IntercomService, private pendoService: PendoService, private studioDataService: StudioDataService, private router: Router, private store: Store<fromRoot.State>, private sidenavFilterSrv: SidenavFilterService, private userDataService: UserDataService, private http: HttpClient) { }

    ngOnInit() {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.path = event.url;
                if (this.path != "/login" && this.path != "/session-expired" && this.authenticated) {
                    this.http.get("/api/auth/isSessionActive").subscribe((res: any) => {
                        this.authenticated = true;
                        if (!res.hasActiveSession) {
                            const userId = res.user_id;
                            this.store.dispatch(new userActions.RemoveFromStoreAction(userId));
                            this.authenticated = false;
                            redirectToLogin();
                        }
                    }, err => {
                        if (err.status == HttpStatusCode.Unauthorized) {
                            this.authenticated = false;
                            redirectToLogin();
                        }
                    });
                } else {
                    this.authenticated = false;
                }
            }
        });

        this.userDataServiceSub = this.userDataService.getUser().subscribe(user => {
            if (user) {
                this.authenticated = true;
                this.getAllStudiosSub = this.studioDataService.getAllStudios().subscribe();

                this.user = this.store.select(fromRoot.getUser);
                this.studioList = this.store.select(fromRoot.getStudioList);
                this.customFieldNames = this.store.select(fromRoot.getCustomStudioFieldName);

                this.navTypeSub = this.store.select(fromRoot.getNavType).subscribe((navType) =>
                    setTimeout(() => { this.navType = navType; }) // Avoids ExpressionChangedAfterItHasBeenCheckedError
                );

                this.sidenavFilterOptionsSub = this.store.select(fromRoot.getSidenavShowFilterOptions).subscribe((result) =>
                    setTimeout(() => { this.sidenavFilterOptionsVisibility = result; }) // Avoids ExpressionChangedAfterItHasBeenCheckedError
                );

                this.setupTimeoutModal(user);
                this.setupSidenavSwitcher();
                this.setupIntercom(user);
                this.setupPendo(user);
            }
            else {
                this.authenticated = false;
                this.setupIntercom(null);
            }
        });

        this.breakpointSub = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
            this.navMode = result.matches ? 'over' : 'side';
            this.isMobile = result.matches ? true : false;
        });

        this.filterSelectedSub = this.store.select(layoutSelectors.isFilterSelected).subscribe(result => {
            setTimeout(() => {
                this.filtersSelected = result;
            });
        });

        this.sub = new Subscription();
        this.sub.add(this.userDataServiceSub)
            .add(this.breakpointSub)
            .add(this.filterSelectedSub);
    }

    ngOnDestroy() {
        this.getAllStudiosSub.unsubscribe();
        this.navTypeSub.unsubscribe();
        this.breakpointSub.unsubscribe();
        this.sidenavFilterSrv.unsubscribe();
        this.sub.unsubscribe();
        this.idleSub.unsubscribe();
        this.onlineSub.unsubscribe();
        this.routerEventSub.unsubscribe();
        this.navShowHideSub.unsubscribe();
        this.sidenavFilterOptionsSub.unsubscribe();
    }

    setupSidenavSwitcher() {
        this.routerEventSub = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                // Close toast if there is one
                this.store.dispatch(new layoutActions.ClearAction());
                const routeList = [];
                let currentRoute = this.activatedRoute;
                while (currentRoute) {
                    routeList.push(currentRoute);
                    currentRoute = currentRoute.firstChild;
                }
                // loop through routes from most recent to least
                // until you find one with a sidenav value
                let navData = null;
                for (let i = routeList.length - 1; i >= 0; i--) {
                    const route = routeList[i];
                    if (route.data.getValue().navType) {
                        navData = route.data.getValue().navType;
                        break;
                    }
                }
                this.switchNav(navData);
                return event;
            });
        this.onlineSub = observableMerge(
            observableOf(navigator.onLine),
            observableFromEvent(window, 'online').pipe(map(() => true)),
            observableFromEvent(window, 'offline').pipe(map(() => false))
        ).pipe(filter(isOnline => !isOnline)).subscribe(() => {
            this.store.dispatch(new layoutActions.ErrorAction({
                error: new HttpErrorResponse({ error: new Error('No internet connection. You will not be able to save changes until your connection is restored.') })
            }));
        });

        this.navShowHideSub = this.sidenavFilterSrv.showHide();
    }

    setupTimeoutModal(user: User) {
        // Idle after 25 minutes
        this.idle.setIdle(this.secondsToIdle);
        // And timeout 5 minutes after (total = 30 minutes)
        this.idle.setTimeout(this.secondsToTimeout);

        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idleSub = this.idle.onIdleStart.subscribe(() => {
            if (!this.isIdle) {
                this.isIdle = true;
                if (this.path != "/login" && this.path != "/session-expired" && this.authenticated) {
                    const dialogRef = this.dialog.open(TimeoutModalComponent, { data: { idleInstance: this.idle, userId: user.id } });
                    dialogRef.afterClosed().pipe(take(1)).subscribe(logout => {
                        this.isIdle = false;
                        if (logout) {
                            redirectToLogout();
                        } else {
                            this.http.get("/api/auth/extendSession").subscribe((sessionExtendTime) => {
                                if (sessionExtendTime != null) {
                                    sessionStorage.ping_session_last_checked = sessionExtendTime;
                                }
                            });
                        }
                    });
                }
            }
        });
        this.idle.watch();
    }

    setupIntercom(user: User) {
        this.intercomService.launch(user);
    }

    setupPendo(user: User) {
        this.pendoService.launch(user);
    }

    switchNav(navType) {
        this.store.dispatch(new layoutActions.SwitchNavAction(navType));
    }

    selectStudio(data: { studio: Studio, refresh: boolean }) {
        this.user.pipe(take(1)).subscribe(currentUser => {
            const userCopy = new User(currentUser);
            userCopy.current_studio_id = data.studio.id;
            userCopy.current_franchise_id = null;
            this.store.dispatch(new userActions.UpdateAction({ user: userCopy }));
            this.store.dispatch(new studioActions.SelectAction(data.studio.id));
            this.store.dispatch(new franchiseActions.SelectAction(null));
            this.store.dispatch(new divisionActions.SelectAction(null));
            this.store.select(fromRoot.getUserUpdating).pipe(filter(u => u !== ActionStatus.Loading), take(1)).subscribe(status => {
                if (status === ActionStatus.Complete) {
                    if (data.refresh) {
                        window.location.href = `/studio/${data.studio.id}`;
                    } else {
                        this.router.navigate(['/studio', data.studio.id]);
                    }
                }
            });
        });
    }

    closeSidenavOpenFilter() {
        this.sidenavFilterSrv.toggleVisible();
    }
}
