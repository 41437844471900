import { Attachment } from '../attachments/attachment.model';
import { BaseModel } from '../base/base.model';
import { Group } from '../groups/group.model';

export class SubGroup extends BaseModel {
    id: number;
    countForFranchise: { [franchiseId: number]: number } = {};
    countForDivision: { [divisionId: number]: number } = {};
    attachmentCount: number = null;
    group_id: number = null;
    name: string = null;
    description: string = null;
    created_at: Date = null;
    attachments?: Attachment[] = [];
    assetCount?: number = 0;
    group: Group = null;

    constructor(subGroupObject) {
        super();
        this.initializeData(subGroupObject, this);

        if (subGroupObject && subGroupObject.attachments) {
            this.attachments = subGroupObject.attachments.map(attachment => {
                const newAttachment = new Attachment(attachment);
                newAttachment.owner_id = this.id;
                return newAttachment;
            });
        }
    }
}

export const subGroupObjectMock = {
    id: 1,
    name: 'Good Stuff',
};
