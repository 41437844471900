import { createSelector } from 'reselect';
import { copyAndMultiSort, getIdsAndEntities } from '../utils';
import * as currencyActions from './currency.actions';
import { Currency } from './currency.model';

export interface State {
    ids: number[];
    entities: {
        [currencyId: number]: Currency
    };
}

export const initialState: State = {
    ids: [],
    entities: {}
};

export function reducer(state = initialState, action: currencyActions.Actions): State {
    switch (action.type) {
        case currencyActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case currencyActions.LIST_COMPLETE: {
            const currencies = action.payload;
            const { ids, entities } = getIdsAndEntities(state, currencies);

            return {
                ...state,
                ids,
                entities
            };
        }

        default:
            return state;
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
