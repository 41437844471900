import * as bulkMoveModalActions from './bulk-move-modal.actions';

export interface State {
    selectedDivisionId: number;
    selectedLocationId: number;
    selectedFranchiseId: number;
    selectedProjectId: number;
    selectedWarehouseId: number;
    selectedWarehouseLocationId: number;
    selectedWarehouseSubLocationId: number;
    selectedSubLocationId: number;
}

export const initialState: State = {
    selectedDivisionId: null,
    selectedLocationId: null,
    selectedFranchiseId: null,
    selectedProjectId: null,
    selectedWarehouseId: null,
    selectedWarehouseLocationId: null,
    selectedWarehouseSubLocationId: null,
    selectedSubLocationId: null
};

export function reducer(state = initialState, action: bulkMoveModalActions.Actions): State {
    switch (action.type) {
        case bulkMoveModalActions.SELECT_DIVISION: {
            return selectEntity('selectedDivisionId', action.payload, state);
        }

        case bulkMoveModalActions.SELECT_LOCATION: {
            return selectEntity('selectedLocationId', action.payload, state);
        }

        case bulkMoveModalActions.SELECT_FRANCHISE: {
            const newState = selectEntity('selectedFranchiseId', action.payload, state);
            return {
                ...newState,
                selectedLocationId: null
            };
        }

        case bulkMoveModalActions.SELECT_PROJECT: {
            return selectEntity('selectedProjectId', action.payload, state);
        }

        case bulkMoveModalActions.SELECT_WAREHOUSE: {
            const newState = selectEntity('selectedWarehouseId', action.payload, state);
            return {
                ...newState,
                selectedWarehouseLocationId: null
            };
        }

        case bulkMoveModalActions.SELECT_WAREHOUSE_LOCATION: {
            return selectEntity('selectedWarehouseLocationId', action.payload, state);
        }

        case bulkMoveModalActions.SELECT_WAREHOUSE_SUB_LOCATION: {
            return selectEntity('selectedWarehouseSubLocationId', action.payload, state);
        }

        case bulkMoveModalActions.SELECT_SUB_LOCATION: {
            return selectEntity('selectedSubLocationId', action.payload, state);
        }

        default: {
            return state;
        }
    }

}

function selectEntity(name: string, payload: number | number[], state: State) {
    let newState = state;
    if (payload !== state[name]) {
        newState = {
            ...state,
            [name]: payload
        };
    }

    return newState;
}

export const getSelectedDivisionId = (state: State) => state.selectedDivisionId;
export const getSelectedLocationId = (state: State) => state.selectedLocationId;
export const getSelectedFranchiseId = (state: State) => state.selectedFranchiseId;
export const getSelectedProjectId = (state: State) => state.selectedProjectId;
export const getSelectedWarehouseId = (state: State) => state.selectedWarehouseId;
export const getSelectedWarehouseLocationId = (state: State) => state.selectedWarehouseLocationId;
export const getSelectedWarehouseSubLocationId = (state: State) => state.selectedSubLocationId;
export const getSelectedSubLocationId = (state: State) => state.selectedSubLocationId;
