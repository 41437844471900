import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { deleteUnusedFilterKeys, getExistingGlobalSearch, listTableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import * as assetActions from '../assets/asset.actions';
import * as franchiseActions from '../franchises/franchise.actions';
import { SortObject, TableFilterObject } from '../types';
import * as groupViewActions from './group-view.actions';

export interface State {
    columnFiltersForGroupId: {
        [groupId: number]: ColumnFilterOptions;
    };
    groupAssetSort: {
        [groupId: number]: SortObject;
    };
    groupAssetFilter: {
        [groupId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    columnFiltersForGroupId: {},
    groupAssetSort: {},
    groupAssetFilter: {}
};

export function reducer(state = initialState, action: groupViewActions.Actions | franchiseActions.Actions | assetActions.Actions): State {
    switch (action.type) {
        case groupViewActions.SORT_GROUP_ASSET_LIST: {
            const groupId = action.payload.groupId;
            const assetSort = action.payload.sort;
            const currentSort = state.groupAssetSort[groupId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[groupId] = { ...action.payload.sort };

            return {
                ...state,
                groupAssetSort: { ...state.groupAssetSort, ...sortObj }
            };
        }

        case groupViewActions.FILTER_GROUP_ASSET_LIST: {
            const groupId = action.payload.groupId;
            if (action.payload.reset) {
                const globalFilter = getExistingGlobalSearch(state.groupAssetFilter, groupId);
                return {
                    ...state,
                    groupAssetFilter: {
                        ...state.groupAssetFilter,
                        [groupId]: globalFilter
                    }
                };
            }
            const updatedFilter: TableFilterObject = {
                ...state.groupAssetFilter[groupId],
                ...action.payload.filter
            };

            return {
                ...state,
                groupAssetFilter: {
                    ...state.groupAssetFilter,
                    [groupId]: deleteUnusedFilterKeys(updatedFilter)
                }
            };
        }

        case assetActions.LIST_BY_GROUP_FILTERS_COMPLETE: {
            const columnFiltersForGroupId = { ...state.columnFiltersForGroupId };
            columnFiltersForGroupId[action.payload.groupId] = action.payload.columnFilters;

            return {
                ...state,
                columnFiltersForGroupId
            };
        }

        case groupViewActions.LIST_TABLE_STATE_COMPLETE: {
            const groupId = action.payload.groupId;
            const { filter, sort } = listTableState(action.payload.tableState, state.groupAssetFilter, state.groupAssetSort, groupId);

            return {
                ...state,
                groupAssetFilter: filter,
                groupAssetSort: sort
            };
        }

        default: {
            return state;
        }
    }
}
