import { Action } from '@ngrx/store';
import { RequestTypes } from '../../../../../shared/enums/request-types.enum';
import { SortObject } from '../types';

export const CREATE = '[Report] Create';
export const CREATE_DIVISION = '[Report] Create Division';
export const CREATE_EVENT = '[Report] Create Event';
export const CREATE_REQUEST = '[Report] Create Request';
export const CREATE_COMPLETE = '[Report] Create Complete';
export const CREATE_FAILED = '[Report] Create Failed';

export class CreateAction implements Action {
    readonly type = CREATE;

    constructor(public payload: {
        franchise_id?: number,
        entities: number[],
        included_fields: string[],
        sort: SortObject,
        other_currency_id: number,
        singlePage: boolean,
        studio_id?: number
    }) { }
}

export class CreateDivisionAction implements Action {
    readonly type = CREATE_DIVISION;

    constructor(public payload: { division_id: number, entities: number[], included_fields: string[], sort: SortObject, singlePage: boolean }) { }
}

export class CreateEventAction implements Action {
    readonly type = CREATE_EVENT;

    constructor(public payload: { division_id: number, event_id: number, entities: number[], included_fields: string[], sort: SortObject, singlePage: boolean }) { }
}

export class CreateRequestAction implements Action {
    readonly type = CREATE_REQUEST;

    constructor(public payload: { studio_id: number, request_id: number, requestType: RequestTypes, entities: number[], included_fields: string[], sort: SortObject, singlePage: boolean }) { }
}

export class CreateCompleteAction implements Action {
    readonly type = CREATE_COMPLETE;
    constructor(public payload: { blob: Blob }) { }
}

export class CreateFailedAction implements Action {
    readonly type = CREATE_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions = CreateAction
    | CreateDivisionAction
    | CreateEventAction
    | CreateRequestAction
    | CreateCompleteAction
    | CreateFailedAction;
