import * as divisionSubGroupActions from '../division-subgroups/division-subgroup.actions';
import * as franchiseSubGroupActions from '../franchise-subgroup/franchise-subgroup.actions';
import { ActionStatus } from '../types';
import * as assetActions from './asset.actions';

export interface State {
    assetIdsForSubGroup: {
        [subGroupId: number]: number[];
    };
    subGroupTotalCount: {
        [subGroupId: number]: number;
    };
    loadingSubGroupAssets: ActionStatus;
}

export const initialState: State = {
    assetIdsForSubGroup: {},
    subGroupTotalCount: {},
    loadingSubGroupAssets: ActionStatus.Complete,
};

export function reducer(state = initialState, action: assetActions.Actions | divisionSubGroupActions.Actions | franchiseSubGroupActions.Actions): State {
    switch (action.type) {
        case assetActions.LIST_BY_SUB_GROUP: {
            return {
                ...state,
                loadingSubGroupAssets: ActionStatus.Loading
            };
        }
        case assetActions.LIST_BY_SUB_GROUP_COMPLETE: {
            const assets = action.payload.assets;
            const totalCount = action.payload.totalCount;
            const subGroupId = action.payload.subGroupId;

            const subGroupIdObj = {};
            const subGroupCountObj = {};

            subGroupCountObj[subGroupId] = totalCount;
            subGroupIdObj[subGroupId] = assets.map(a => a.id);

            return {
                ...state,
                assetIdsForSubGroup: {
                    ...state.assetIdsForSubGroup,
                    ...subGroupIdObj
                },
                subGroupTotalCount: {
                    ...state.subGroupTotalCount,
                    ...subGroupCountObj
                },
                loadingSubGroupAssets: ActionStatus.Complete
            };
        }

        case assetActions.UPDATE_MULTIPLE_COMPLETE: {
            const updatedAssets = action.payload.assets;

            // clear all existing sub group info for assets that were updated
            const assetIdsForSubGroup = { ...state.assetIdsForSubGroup };
            const subGroupTotalCount = { ...state.subGroupTotalCount };
            for (const key in assetIdsForSubGroup) {
                if (assetIdsForSubGroup[key]) {
                    updatedAssets.forEach(a => {
                        assetIdsForSubGroup[key] = assetIdsForSubGroup[key].filter(assetId => {
                            if (assetId !== a.id) {
                                return true;
                            }
                            if (subGroupTotalCount[key]) {
                                subGroupTotalCount[key] -= 1;
                            }
                            return false;
                        });
                    });
                }
            }
            // add updated sub group info for assets that were updated
            updatedAssets.forEach(a => {
                if (!a.trashed) {
                    a.sub_group_ids.forEach(subGroupId => {
                        assetIdsForSubGroup[subGroupId] = assetIdsForSubGroup[subGroupId] ? [...assetIdsForSubGroup[subGroupId], a.id] : [a.id];
                        if (subGroupTotalCount[subGroupId]) {
                            subGroupTotalCount[subGroupId] += 1;
                        } else {
                            subGroupTotalCount[subGroupId] = 1;
                        }
                    });
                }
            });

            return {
                ...state,
                assetIdsForSubGroup,
                subGroupTotalCount
            };
        }

        default: {
            return state;
        }
    }
}

export const getAssetIdsForSubGroup = (state: State) => state.assetIdsForSubGroup;
export const getLoadingSubGroupAssets = (state: State) => state.loadingSubGroupAssets;
export const getAssetCountsForSubGroup = (state: State) => state.subGroupTotalCount;
