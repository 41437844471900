
import * as assetImportsActions from './asset-imports.actions';
import { AssetImports } from './asset-imports.model';
import { getEntitiesObject } from '../utils';

export interface State {
    importId: number;
    studioId: number;
    franchiseId: number;
    projectId: number;
    divisionId: number;
    warehouseId: number;
    originCurrency: number;
    valueExamples: { [assetImportsHeader: string]: string };
    createComplete: boolean;
    createError: boolean;
    deleteComplete: boolean;
    deleteFailed: boolean,
    assetsUploaded: boolean;
    assetsCount: number;
    entities: {
        [importId: number]: AssetImports
    };
    importIdsForFranchise: {
        [franchiseId: number]: number[];
    };
    importIdsForDivision: {
        [divisionId: number]: number[];
    };
}

export const initialState: State = {
    importId: null,
    studioId: null,
    franchiseId: null,
    projectId: null,
    divisionId: null,
    warehouseId: null,
    originCurrency: null,
    valueExamples: {},
    createComplete: false,
    createError: false,
    deleteComplete: false,
    deleteFailed: false,
    assetsUploaded: false,
    assetsCount: 0,
    entities: {},
    importIdsForFranchise: {},
    importIdsForDivision: {}
};

export function reducer(state = initialState, action: assetImportsActions.Actions): State {
    switch (action.type) {
        case assetImportsActions.UPLOAD_DONE: {
            return {
                ...state,
                importId: action.payload.importId,
                studioId: action.payload.studioId,
                franchiseId: action.payload.franchiseId,
                divisionId: action.payload.divisionId,
                projectId: action.payload.projectId,
                warehouseId: action.payload.warehouseId,
                valueExamples: action.payload.valueExamples,
                assetsCount: action.payload.assetsCount,
                originCurrency: action.payload.originCurrency,
                createComplete: false,
                assetsUploaded: true
            };
        }

        case assetImportsActions.ADD_ASSETS_IMPORTS: {
            return {
                ...state,
                createComplete: false,
                createError: false
            };
        }

        case assetImportsActions.ADD_ASSETS_IMPORTS_COMPLETE: {
            return {
                ...state,
                createComplete: true,
                createError: false,
                assetsUploaded: false
            };
        }

        case assetImportsActions.ADD_ASSETS_IMPORTS_FAILED: {
            return {
                ...state,
                createError: true,
                createComplete: true
            };
        }

        case assetImportsActions.LIST_BY_FRANCHISE_COMPLETE: {
            const assetImports = action.payload;
            const aiObj = getEntitiesObject(assetImports);
            const franchiseIdObj = buildAssetImportIdsForFranchise(assetImports);

            const alterStateWith = {
                entities: { ...state.entities, ...aiObj },
                importIdsForFranchise: { ...state.importIdsForFranchise, ...franchiseIdObj }
            };

            return { ...state, ...alterStateWith };
        }

        case assetImportsActions.LIST_BY_DIVISION_COMPLETE: {
            const assetImports = action.payload;
            const aiObj = getEntitiesObject(assetImports);
            const divisionIdObj = buildAssetImportIdsForDivision(assetImports);

            const alterStateWith = {
                entities: { ...state.entities, ...aiObj },
                importIdsForDivision: { ...state.importIdsForFranchise, ...divisionIdObj }
            };

            return { ...state, ...alterStateWith };
        }

        case assetImportsActions.DELETE: {
            return { ...state, deleteComplete: false };
        }

        case assetImportsActions.DELETE_FAILED: {
            return { ...state, deleteFailed: true };
        }

        case assetImportsActions.DELETE_COMPLETE: {
            return { ...state, deleteComplete: true };
        }

        case assetImportsActions.SELECT_PROJECT: {
            return { ...state, projectId: action.payload }
        }

        default: {
            return state;
        }
    }

    function buildAssetImportIdsForFranchise(newAssetImports: AssetImports[]) {
        const franchiseIdObj: { [franchiseId: number]: number[] } = {};
        if (newAssetImports.length) {
            const franchiseId = newAssetImports[0].franchise_id;
            if (state.importIdsForFranchise[franchiseId]) {
                franchiseIdObj[franchiseId] = [...state.importIdsForFranchise[franchiseId]];
            } else {
                franchiseIdObj[franchiseId] = [];
            }
            newAssetImports.forEach((l) => {
                if (!franchiseIdObj[franchiseId].includes(l.id)) {
                    franchiseIdObj[franchiseId].push(l.id);
                }
            });
        }

        return franchiseIdObj;
    }

    function buildAssetImportIdsForDivision(newAssetImports: AssetImports[]) {
        const divisionIdObj: { [divisionId: number]: number[] } = {};
        if (newAssetImports.length) {
            const divisionId = newAssetImports[0].division_id;
            if (state.importIdsForDivision[divisionId]) {
                divisionIdObj[divisionId] = [...state.importIdsForDivision[divisionId]];
            } else {
                divisionIdObj[divisionId] = [];
            }
            newAssetImports.forEach((l) => {
                if (!divisionIdObj[divisionId].includes(l.id)) {
                    divisionIdObj[divisionId].push(l.id);
                }
            });
        }

        return divisionIdObj;
    }
}

export const getUploaded = (state: State) => state.importId !== null;
export const getValueExamples = (state: State) => state.valueExamples;
export const getCreateComplete = (state: State) => state.createComplete;
export const getCreateFailed = (state: State) => state.createError;
export const getIdsForFranchise = (state: State) => state.importIdsForFranchise;
export const getIdsForDivision = (state: State) => state.importIdsForDivision;
export const getEntities = (state: State) => state.entities;
export const getDeleteComplete = (state: State) => state.deleteComplete;
export const getDeleteFailed = (state: State) => state.deleteFailed;
export const getSelectedProjectId = (state: State) => state.projectId;
