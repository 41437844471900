import { createSelector } from 'reselect';
import { ActionStatus } from '../types';
import { getEntitiesObject, getIdsAndEntities } from '../utils';
import * as matchFieldActions from './match-field.actions';
import { MatchField } from './match-field.model';

export interface State {
    ids: number[];
    entities: { [matchFieldId: number]: MatchField };
    saving: ActionStatus;
}

export const initialState: State = {
    ids: [],
    entities: {},
    saving: ActionStatus.Complete,
};

export function reducer(state = initialState, action: matchFieldActions.Actions): State {
    switch (action.type) {
        case matchFieldActions.LIST: {
            return { ...state, ids: [], entities: {} };
        }

        case matchFieldActions.LIST_COMPLETE: {
            const matchFields = action.payload;
            const { ids, entities } = getIdsAndEntities(state, matchFields);
            return {
                ...state,
                ids: [...state.ids, ...ids],
                entities: { ...state.entities, ...entities }
            };
        }

        case matchFieldActions.UPDATE:
        case matchFieldActions.UPDATE_MULTIPLE: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case matchFieldActions.SET_MULTIPLE:
        case matchFieldActions.UPDATE_MULTIPLE_COMPLETE: {
            const matchFieldsObj = getEntitiesObject(action.payload);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...matchFieldsObj
                },
                saving: ActionStatus.Complete
            };
        }

        case matchFieldActions.UPDATE_COMPLETE: {
            const matchField = action.payload;
            const entitiesUpdate = {};
            entitiesUpdate[matchField.id] = matchField;
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...entitiesUpdate
                },
                saving: ActionStatus.Complete
            };
        }

        case matchFieldActions.UPDATE_MULTIPLE_FAILED:
        case matchFieldActions.UPDATE_FAILED:
        case matchFieldActions.LIST_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
export const getSaving = (state: State) => state.saving;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
