import { CustomStudioFieldName, Franchise, Project } from '../models';
import { BaseJsonModel } from './../base/base-json.model';

export class Transaction extends BaseJsonModel {
    id: number;
    name = '';
    vendor = '';
    purchase_date: string;
    franchise_id: number = null;
    project_id: number = null;
    department_id: number = null;
    cost?: string;
    created?: boolean;
    original_data?: any;
    origin_currency: number = null;
    excluded = 0;
    displayFields;
    allCosts: { [projectCurrencyId: number]: string } = [];
    ledger_id: number = null;
    character_id: number = null;
    set_id: number = null;
    sub_department_id: number = null;
    sub_location_id: number = null;
    sub_code: string = null;

    constructor(transactionObj = {}) {
        super();
        this.initializeData.call(this, transactionObj);
    }

    static getOriginalData(transactions: Transaction[], customFieldNames?: CustomStudioFieldName, franchiseEnts?: { [id: number]: Franchise }, projectEnts?: { [id: number]: Project }) {
        const rows = [];
        const cols = [];
        const franchiseProjectLabel = `${customFieldNames ? customFieldNames.franchise_id : 'Feature'} - ${customFieldNames ? customFieldNames.project_id : 'Project'}`;
        cols.push({
            label: franchiseProjectLabel,
            value: franchiseProjectLabel
        });
        transactions.forEach(transaction => {
            if (transaction.original_data) {
                const row = {
                    id: transaction.id
                };
                if (franchiseEnts && franchiseEnts[transaction.franchise_id] && projectEnts && projectEnts[transaction.project_id]) {
                    row[franchiseProjectLabel] = `${franchiseEnts[transaction.franchise_id].name} - ${projectEnts[transaction.project_id].name}`;
                }
                transaction.original_data.forEach(data => {
                    if (data) {
                        // replace periods with underscores because turbotable
                        // assumes periods in fields indicate subobjects
                        // when exporting to csv [ch10667]
                        row[data.fieldName.replace(/\./g, '_')] = data.value;
                        if (!cols.find(col => col.label === data.fieldName)) {
                            cols.push({
                                label: data.fieldName,
                                value: data.fieldName
                            });
                        }
                    }
                });
                rows.push(row);
            }
        });
        return { cols, rows };
    }
}
