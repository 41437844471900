import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as projectCurrencyActions from './project-currency.actions';
import { ProjectCurrency } from './project-currency.model';

@Injectable()
export class ProjectCurrencyEffects {
    static BASE_URL = '/api/project-currencies';

     add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCurrencyActions.ADD),
            map((action: projectCurrencyActions.AddAction) => action.payload),
            mergeMap((data) => this.http.post<ProjectCurrency[]>(ProjectCurrencyEffects.BASE_URL, data).pipe(
                map(res => res.map(s => new ProjectCurrency(s))),
                map((currencies: ProjectCurrency[]) => new projectCurrencyActions.AddCompleteAction(currencies)),
                catchError((error) => observableOf(new projectCurrencyActions.AddFailedAction({ error }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCurrencyActions.LIST),
            map((action: projectCurrencyActions.ListAction) => action.payload),
            switchMap(franchiseId => this.http.get<ProjectCurrency[]>(`/api/franchises/${franchiseId}/project-currencies`).pipe(
                map(res => res.map(s => new ProjectCurrency(s))),
                map((currencies: ProjectCurrency[]) => new projectCurrencyActions.ListCompleteAction(currencies)),
                catchError(error => observableOf(new projectCurrencyActions.ListFailedAction({ error, franchiseId }))))
            )));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCurrencyActions.DELETE),
            map((action: projectCurrencyActions.DeleteAction) => action.payload),
            mergeMap((data) => this.http.delete(`${ProjectCurrencyEffects.BASE_URL}/${data.id}`).pipe(
                map((res) => new ProjectCurrency(res)),
                map((usPC) => new projectCurrencyActions.DeleteCompleteAction({ deletedPC: data, usPC })),
                catchError((error) => observableOf(new projectCurrencyActions.DeleteFailedAction({ error }))))
            )));

     update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(projectCurrencyActions.UPDATE),
            map((action: projectCurrencyActions.UpdateAction) => action.payload),
            mergeMap((data) => this.http.put<ProjectCurrency[]>(`${ProjectCurrencyEffects.BASE_URL}/${data.id}`, data).pipe(
                map(res => res.map(s => new ProjectCurrency(s))),
                map((currencies: ProjectCurrency[]) => new projectCurrencyActions.UpdateCompleteAction(currencies)),
                catchError((error) => observableOf(new projectCurrencyActions.UpdateFailedAction({ error }))))
            )));

     failed$ = createEffect(() => this.actions$
        .pipe(
            ofType(projectCurrencyActions.ADD_FAILED, projectCurrencyActions.LIST_FAILED, projectCurrencyActions.UPDATE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
