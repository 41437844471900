export function redirectToLogin() {
    window.location.href = `/login`;
}

export function redirectToLogout() {
    window.location.href = `/api/auth/logout`;
}

export function redirectToURL(url) {
    window.location.href = url;
}