import { BaseModel } from './../base/base.model';

export class Photo extends BaseModel {
    id: number;
    studio_id: number = null;
    asset_id: number = null;
    file_name: string = null;
    main_photo: boolean = null;
    resize_attempts: number = null;
    links: { thumbnail: string, preview: string, full: string } = null;
    original_name: string = null;

    constructor(departmentObj) {
        super();
        this.initializeData(departmentObj, this);
    }
}
