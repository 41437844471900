import * as franchiseGroupActions from './franchise-group.actions';

export interface State {
    selectedId: number;
    selectedAssetCount: number;
}

export const initialState: State = {
    selectedId: null,
    selectedAssetCount: null
};

export function reducer(state = initialState, action: franchiseGroupActions.Actions): State {
    switch (action.type) {
        case franchiseGroupActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedId) {
                newState = {
                    ...state,
                    selectedId: action.payload
                };
            }
            return newState;
        }

        case franchiseGroupActions.GET_ASSET_COUNT_COMPLETE: {
            return {
                ...state,
                selectedAssetCount: action.payload
            };
        }

        default: {
            return state;
        }
    }
}

export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedAssetCount = (state: State) => state.selectedAssetCount;
