import { NoStudioPermsComponent } from './layout/no-studio-perms/no-studio-perms.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './layout/error/error.component';
import { Four0fourComponent } from './layout/four0four/four0four.component';
import { LoginComponent } from './shared/components/login/login.component';
import { DivisionGuard } from './shared/guards/division.guard';
import { FranchiseGuard } from './shared/guards/franchise.guard';
import { StudioGuard } from './shared/guards/studio.guard';
import { CustomStudioFieldNameResolver } from './shared/resolvers/custom-studio-field-name.resolvers';
import { UserResolver } from './shared/resolvers/user.resolvers';
import { ActivateUserComponent } from './shared/components/activate-user/activate-user.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { ClaimAccountComponent } from './shared/components/claim-account/claim-account.component';
import { UserSettingsComponent } from './shared/components/user-settings/user-settings.component';
import { LoginGuard } from './shared/guards/login.guard';

const routes: Routes = [
    // studio guard handles redirecting to the right place
    { path: '', component: Four0fourComponent, canActivate: [AuthenticationGuard, StudioGuard] },
    { path: '404', component: Four0fourComponent },
    { path: 'error', component: ErrorComponent },
    // this should be canLoad, but if it is it doesn't download the module ever, so...
    { path: 'studio/:studioId', loadChildren: () => import('../app/studio/studio.module').then(m => m.StudioModule), resolve: { user: UserResolver, customFieldNames: CustomStudioFieldNameResolver }, canActivate: [AuthenticationGuard] },
    { path: 'franchise/:franchiseId', loadChildren: () => import('../app/franchise/franchise.module').then(m => m.FranchiseModule), resolve: { user: UserResolver, customFieldNames: CustomStudioFieldNameResolver }, canActivate: [AuthenticationGuard] },
    { path: 'division/:divisionId', loadChildren: () => import('../app/division/division.module').then(m => m.DivisionModule), resolve: { user: UserResolver, customFieldNames: CustomStudioFieldNameResolver }, canActivate: [AuthenticationGuard] },
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'session-expired', component: LoginComponent },
    { path: 'claim-account', component: ClaimAccountComponent },
    { path: 'activate-user', component: ActivateUserComponent },
    { path: 'no-studio-perms', component: NoStudioPermsComponent },
    { path: 'edit-account', component: UserSettingsComponent },
    { path: '**', component: Four0fourComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [UserResolver, CustomStudioFieldNameResolver, StudioGuard, FranchiseGuard, DivisionGuard, AuthenticationGuard, LoginGuard]
})
export class AppRoutingModule { }
