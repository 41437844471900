import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { SharedModule } from '../shared/shared.module';
import { ErrorComponent } from './error/error.component';
import { Four0fourComponent } from './four0four/four0four.component';
import { LayoutComponent } from './layout.component';
import { TimeoutModalComponent } from './timeout-modal/timeout-modal.component';
import { ToolbarMenuComponent } from './toolbar/toolbar-menu/toolbar-menu.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NoStudioPermsComponent } from './no-studio-perms/no-studio-perms.component';

@NgModule({
    imports: [
        NgIdleModule.forRoot(),
        RouterModule,
        SharedModule
    ],
    declarations: [
        ErrorComponent,
        Four0fourComponent,
        LayoutComponent,
        TimeoutModalComponent,
        ToolbarComponent,
        ToolbarMenuComponent,
        NoStudioPermsComponent
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule { }
