import { STUDIO_PERMISSION_ROLES } from '../../../../../shared/enums/studio-permission-roles.enum';
import { CustomStudioFieldName, Franchise } from '../models';
import { BaseModel } from './../base/base.model';
import { DivisionPermission } from './division-permission.model';
import { FranchisePermission } from './franchise-permission.model';
import { StudioPermission } from './studio-permission.model';

export class Permission extends BaseModel {
    user_id: number = null;
    studioPermissions: { [studioId: number]: StudioPermission } = {};
    franchisePermissions: { [franchiseId: number]: FranchisePermission } = {};
    divisionPermissions: { [divisionId: number]: DivisionPermission } = {};

    constructor(userObject) {
        super();
        let i, j, studioPerm, franchisePerm;
        this.user_id = userObject.id;
        if (userObject.studioPermissions) {
            for (i = 0; i < userObject.studioPermissions.length; i++) {
                studioPerm = userObject.studioPermissions[i];
                this.studioPermissions[studioPerm.studio_id] = new StudioPermission(studioPerm);
                if (studioPerm.franchisePermissions) {
                    for (j = 0; j < studioPerm.franchisePermissions.length; j++) {
                        franchisePerm = studioPerm.franchisePermissions[j];
                        this.franchisePermissions[franchisePerm.franchise_id] = new FranchisePermission(franchisePerm);
                    }
                }

                if (studioPerm.divisionPermissions) {
                    for (const divPerm of studioPerm.divisionPermissions) {
                        this.divisionPermissions[divPerm.division_id] = new DivisionPermission(divPerm);
                    }
                }
            }
        }
    }

    canEditInFranchise(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_edit', franchiseId, studioId);
    }

    canEditGroupInFranchise(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_edit_groups', franchiseId, studioId);
    }

    canEditInDivision(divisionId: number, studioId: number) {
        return this.checkDivisionPerm('can_edit', divisionId, studioId);
    }

    canEditGroupInDivision(divisionId: number, studioId: number) {
        return this.checkDivisionPerm('can_edit_groups', divisionId, studioId);
    }

    canAccessRestrictedInFranchise(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_access_restricted', franchiseId, studioId);
    }

    canAccessRestrictedInDivision(divisionId: number, studioId: number) {
        return this.checkDivisionPerm('can_access_restricted', divisionId, studioId);
    }

    canAccessAssetDetailsInFranchise(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_access_asset_details', franchiseId, studioId);
    }

    canAccessAssetDetailsInDivision(divisionId: number, studioId: number) {
        return this.checkDivisionPerm('can_access_asset_details', divisionId, studioId);
    }

    /**
    * Returns true if user is studio admin,
    * else, if user is studio user, return true if there are any franchisePermission.can_access_asset_search or
    * division_permissions.can_access_asset_search = true for that studio
    */
    canAccessAssetSearch(studioId: number) {
        const studioPerm = this.studioPermissions[studioId] ? this.studioPermissions[studioId] : null;
        if (studioPerm) {
            if (studioPerm.role > STUDIO_PERMISSION_ROLES.USER) {
                return true;
            } else {
                for (const divId in this.divisionPermissions) {
                    if (this.divisionPermissions.hasOwnProperty(divId)) {
                        const divPerm = this.divisionPermissions[divId];
                        if (divPerm.studio_permission_id === studioPerm.id && divPerm.can_access_asset_search) {
                            return true;
                        }
                    }
                }
                for (const franchiseId in this.franchisePermissions) {
                    if (this.franchisePermissions.hasOwnProperty(franchiseId)) {
                        const franchPerm = this.franchisePermissions[franchiseId];
                        if (franchPerm.studio_permission_id === studioPerm.id && franchPerm.can_access_asset_search) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    canAccessCustomersInDivision(divisionId: number, studioId: number) {
        return this.checkDivisionPerm('can_access_customers', divisionId, studioId);
    }

    canUploadLedger(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_upload_ledger', franchiseId, studioId);
    }

    canViewHistorical(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_view_historical', franchiseId, studioId);
    }

    canViiewHistoricalDivision(divisionId: number, studioId: number) {
        return this.checkDivisionPerm('can_view_historical', divisionId, studioId)
    }

    canUnlinkTransactionsInFranchise(franchiseId: number, studioId: number) {
        return this.checkFranchisePerm('can_unlink_transactions', franchiseId, studioId);
    }

    /**
     * Returns true if user is studio admin,
     * else, if user is studio user, return true if there are any divisionPermissions for that studio
     */
    hasDivisionPermissionForStudio(studioId: number) {
        const studioPerm = this.studioPermissions[studioId] ? this.studioPermissions[studioId] : null;
        if (studioPerm) {
            if (studioPerm.role > STUDIO_PERMISSION_ROLES.USER) {
                return true;
            } else {
                for (const divId in this.divisionPermissions) {
                    if (this.divisionPermissions.hasOwnProperty(divId)) {
                        const divPerm = this.divisionPermissions[divId];
                        if (divPerm.studio_permission_id === studioPerm.id) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    isStudioOwner(studioId: number) {
        if (typeof this.studioPermissions[studioId] !== 'undefined') {
            return this.studioPermissions[studioId].role === STUDIO_PERMISSION_ROLES.OWNER;
        }
        return false;
    }

    isStudioAdmin(studioId: number) {
        if (typeof this.studioPermissions[studioId] !== 'undefined') {
            return this.studioPermissions[studioId].role > STUDIO_PERMISSION_ROLES.USER;
        }
        return false;
    }

    getStudioRole(studioId: number): number {
        return this.studioPermissions[studioId].role;
    }

    isStudioUser(studioId: number) {
        return (typeof this.studioPermissions[studioId] !== 'undefined');
    }

    /**
     * Check studio permission to see if user is an approver
     */
    isApprover(studioId: number) {
        const studioPermission = typeof this.studioPermissions[studioId] !== 'undefined' ? this.studioPermissions[studioId] : null;
        if (studioPermission) {
            return studioPermission.approver;
        }
        return false;
    }

    isFranchiseAdmin(franchiseId: number, studioId: number) {
        const franchisePerm = this.getFranchisePerm(franchiseId);

        if (franchisePerm && franchisePerm.admin) {
            return true;
        } else {
            return this.isStudioAdmin(studioId);
        }
    }

    isDivisionAdmin(divisionId: number, studioId: number): boolean {
        const divisionPerm = this.divisionPermissions[divisionId];
        if (divisionPerm && divisionPerm?.admin) {
            return true;
        } else {
            return this.isStudioAdmin(studioId);
        }
    }

    isDivisionUser(divisionId: number, studioId: number): boolean {
        const divPerm = this.divisionPermissions[divisionId];
        return (!!divPerm || this.isStudioAdmin(studioId));
    }

    getFranchisePosition(franchise: Franchise, customFieldNames?: CustomStudioFieldName): string {
        let position = this.franchisePermissions[franchise.id].position;
        if (!position && this.franchisePermissions[franchise.id].admin) {
            position = customFieldNames && franchise.is_series ? `${customFieldNames.series_id} Owner` : `${customFieldNames.franchise_id} Owner`;
        }
        return position;
    }

    displayDivisionUserType(divisionId: number): string {
        return this.divisionPermissions[divisionId]?.admin ? 'Division Owner' : 'Division User';
    }

    getDivisionPosition(divisionId): string {
        const position = this.divisionPermissions[divisionId]?.position;
        return position;
    }

    isFranchiseUser(franchiseId: number, studioId: number): boolean {
        const franchisePerm = this.getFranchisePerm(franchiseId);
        return (!!franchisePerm || this.isStudioAdmin(studioId));
    }

    checkDivisionPerm(permName: keyof DivisionPermission, divisionId: number, studioId: number) {
        const divisionPerm = this.getDivisionPerm(divisionId);
        if (divisionPerm && divisionPerm[permName] || this.isStudioAdmin(studioId)) {
            return true;
        }
        return false;
    }

    private checkFranchisePerm(permName: keyof FranchisePermission, franchiseId: number, studioId: number) {
        const franchisePerm = this.getFranchisePerm(franchiseId);
        if (franchisePerm && franchisePerm[permName] || (this.isFranchiseAdmin(franchiseId, studioId) && permName !== 'can_view_historical')) {
            return true;
        }
        return false;
    }

    private getFranchisePerm(franchiseId: number): FranchisePermission {
        return typeof this.franchisePermissions[franchiseId] === 'undefined' ? null : this.franchisePermissions[franchiseId];
    }

    private getDivisionPerm(divisionId: number): DivisionPermission {
        return typeof this.divisionPermissions[divisionId] === 'undefined' ? null : this.divisionPermissions[divisionId];
    }
}
