import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '../../core/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class UserInfo {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
}

@Injectable()
export class UserDataService {
    constructor(private store: Store<fromRoot.State>, private httpClient: HttpClient) { }

    getUser() {
        return this.store.select(fromRoot.getUser).pipe(map(user => {
            return user;
        }), take(1));
    }

    getUserByToken(token): Observable<UserInfo> {
        return this.httpClient.get<UserInfo>(`/api/token/${token}/user`);
    }

    activateUser(userInfo: any) {
        return this.httpClient.post(`/api/users/activate`, userInfo);
    }
}
