import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import * as claimActions from './claim.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as layoutActions from '../layout/layout.actions';

@Injectable()
export class ClaimEffects {
    constructor(private actions$: Actions, private http: HttpClient) { }

     claimUser$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(claimActions.CLAIM_USER_ACCOUNT),
            map((action: claimActions.ClaimUserAccount) => action.payload),
            switchMap((emailId) => this.http.get<{}>(`/api/users/${emailId}/claim`).pipe(
                map((path) => new claimActions.ClaimUserAccountCompleteAction(path)),
                catchError((error) => observableOf(new claimActions.ClaimUserAccountFailedAction({ error }))))
            )));

     requestFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(claimActions.CLAIM_USER_ACCOUNT_FAILED),
            map((action: any) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))));
}