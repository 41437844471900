import { SosProjectDepartment } from './sos-project-department.model';
import * as sosLinkActions from './sos-link.actions';
import { copyAndMultiSort, getEntitiesObject } from '../utils';
import { createSelector } from 'reselect';
import { SortableIds } from '../types';


export interface State {
    entities: {
        [sosLinkId: number]: SosProjectDepartment;
    };
    studio: SortableIds;
}

export const initialState: State = {
    entities: [],
    studio: {
        ids: [],
        sortKey: 'email',
        sortOrder: 1,
        sortArgs: []
    },
};

const sortIds = (ids, entities, key, order, args): number[] => {
    return copyAndMultiSort(ids, entities, [{ key }], order, args);
}

export function reducer(state = initialState, action: sosLinkActions.Actions): State {
    switch (action.type) {
        case sosLinkActions.LIST_COMPLETE: {
            const sosLinks = action.payload;
            const sosLinksObj = getEntitiesObject(sosLinks);

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...sosLinksObj
                },
                studio: {
                    ...state.studio,
                    ids: sortIds(
                        sosLinks.map(link => link.id),
                        sosLinksObj, state.studio.sortKey, state.studio.sortOrder,
                        state.studio.sortArgs
                    )
                }
            }
            return { ...state, ...alterStateWith };
        }
        case sosLinkActions.DISPLAY_COMPLETE: {
            const sosLink = action.payload;
            let studioSortIds = [...state.studio.ids];

            let sosLinksObj = null;
            for (let key in state.entities) {
                if (state.entities[key].sos_episodic_id === sosLink.sos_episodic_id
                    && state.entities[key].project_id === sosLink.project_id) {
                    let sosLinkObj = new SosProjectDepartment(sosLink);
                    sosLinkObj.id = parseInt(key);
                    state.entities[key].departments.forEach(department => {
                        sosLinkObj.departments.push(department);
                    });
                    sosLinksObj = {};
                    sosLinksObj[key] = sosLinkObj;
                    break;
                }
            }
            //add object key to state keys
            if (!sosLinksObj) {
                sosLinksObj = {};
                sosLinksObj[sosLink.id] = sosLink;
                studioSortIds.push(sosLink.id);
            }

            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...sosLinksObj
                },
                studio: {
                    ...state.studio,
                    ids: studioSortIds
                },
            }
            console.log('alteredState,', alterStateWith);
            return { ...state, ...alterStateWith };
        }
        case sosLinkActions.DELETE_COMPLETE: {
            const linkIds = action.payload;
            let studioSortIds = [...state.studio.ids];

            const entitiesCopy = { ...state.entities };
            studioSortIds = studioSortIds.filter(id => !linkIds.includes(id));

            linkIds.forEach((id) => {
                delete entitiesCopy[id];
            })

            studioSortIds = studioSortIds.filter(id => !linkIds.includes(id));
            return {
                ...state,
                entities: entitiesCopy,
                studio: {
                    ...state.studio,
                    ids: studioSortIds
                },
            };
        }
        default: {
            return state;
        }
    }
}
const entitiesSelector = (state: State) => state.entities;
const studioIdsSelector = (state: State) => state.studio.ids;

export const getSosLinksForStudio = createSelector(
    entitiesSelector,
    studioIdsSelector,
    (sosLink, ids) => ids.map(id => sosLink[id])
);



