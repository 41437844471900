import { FAKE_COLUMNS } from '../../../../../shared/fields/fake-fields';
import { Department, DepartmentField, Field } from '../models';
import { STANDARD_FIELDS } from './../../../../../shared/fields/standard-fields';

const costumeFields = [
    {
        canonical_name: 'brand',
        sort_order: null
    },
    {
        canonical_name: 'color',
        sort_order: null
    },
    {
        canonical_name: 'description',
        sort_order: 85
    },
    {
        canonical_name: 'quantity',
        sort_order: null
    },
    {
        canonical_name: 'disposition_id',
        sort_order: 95
    },
    {
        canonical_name: 'cost',
        sort_order: null
    },
    {
        canonical_name: 'character_id',
        sort_order: 30
    }
];

/**
 * Gets the union of all the Fields that belong to the Depts selected in the sidenav. Optionally adds FakeColumns
 */
export const getForSidenavSelectedDepts = (departments: Department[], selectedDeptIds: number[], entities: { [fieldId: number]: Field }, fakeFields?: typeof FAKE_COLUMNS) => {
    let depts = departments;
    if (selectedDeptIds && selectedDeptIds.length) {
        depts = depts.filter(dept => selectedDeptIds.includes(dept.id));
    }

    let fields: Field[] = [];
    const fieldHash = {};
    depts.forEach(dept => {
        dept.getFieldIds().forEach(id => {
            if (!fieldHash[id] && entities[id]) {
                fields.push(entities[id]);
                fieldHash[id] = true;
            }
        });
    });

    if (fields.length && fakeFields) {
        fields = fields.concat(fakeFields.map(f => new Field(f)));
    }

    return fields.sort((a, b) => a.name.localeCompare(b.name));
};

export const getForSidenavSelectedDeptsWithRestrictedCheckAndFakeFields = (departments: Department[], ids: number[], entities: { [fieldId: number]: Field }, canAccessRestricted: boolean) => {
    const fields = getForSidenavSelectedDepts(departments, ids, entities, FAKE_COLUMNS);
    if (canAccessRestricted) {
        return fields;
    } else {
        return fields.filter(field => !field.restricted);
    }
};

export const getWithDeptFieldFunction = (dept: Department, fields: Field[]) => {
    const fieldsWithDeptField: { departmentField: DepartmentField, field: Field }[] = [];
    fields = fields.filter(f => !f.isDivisionOnly() && f.canonical_name !== STANDARD_FIELDS.franchise.canonical_name);

    fields.forEach(field => {
        let departmentField;

        if (dept) {
            departmentField = dept.departmentFields.find(df => df.field_id === field.id);
        }

        if (departmentField) {
            const fieldWithDeptField = { departmentField, field };
            // Special case for Costume Department
            if (dept.sos_department_id === 1) {
                costumeFields.forEach(cField => {
                    if (field.canonical_name === cField.canonical_name) {
                        // if (!fieldWithDeptField.departmentField.required) {
                        //     fieldWithDeptField.departmentField = new DepartmentField({ ...departmentField, required: true });
                        // }
                        if (cField.sort_order) {
                            fieldWithDeptField.field = new Field({ ...field, sort_order: cField.sort_order });
                        }
                    }
                });
            }
            fieldsWithDeptField.push(fieldWithDeptField);
        }
    });

    return fieldsWithDeptField;
};

export const getWithDeptFieldFunctionForDeptView = (dept: Department, fields: Field[]) => {
    const fieldsToIgnore = [STANDARD_FIELDS.disposition.canonical_name, STANDARD_FIELDS.disposition_note.canonical_name, STANDARD_FIELDS.project.canonical_name, STANDARD_FIELDS.origin_currency.canonical_name, STANDARD_FIELDS.department.canonical_name, STANDARD_FIELDS.sub_department.canonical_name];
    fields = fields.filter(f => !fieldsToIgnore.includes(f.canonical_name));
    return getWithDeptFieldFunction(dept, fields);
};
