import { Action } from '@ngrx/store';
import { SortObject } from '../../../../../shared/types';
import { TableState } from '../../../shared/components/asset-data-table/asset-data-table.helpers';
import { TableFilterObject } from '../types';

export const SELECT_PROJECT = '[Match Assets] Select Project';
export const SORT_ASSETS_LIST = '[Match Assets] Sort Asset List';
export const FILTER_ASSETS_LIST = '[Match Assets] Filter Asset List';
export const LIST_TABLE_STATE = '[Match Assets] List Table State';
export const LIST_TABLE_STATE_COMPLETE = '[Match Assets] List Table State Complete';

export class SelectProjectAction implements Action {
    readonly type = SELECT_PROJECT;
    constructor(public payload: number) { }
}
export class SortAssetsAction implements Action {
    readonly type = SORT_ASSETS_LIST;

    constructor(public payload: { transactionId: number, sort: SortObject }) { }
}

export class FilterAssetsAction implements Action {
    readonly type = FILTER_ASSETS_LIST;

    constructor(public payload: { transactionId: number, filter: TableFilterObject }) { }
}

export class ListTableStateAction implements Action {
    readonly type = LIST_TABLE_STATE;

    constructor(public payload: { transactionId: number, stateKey: string }) { }
}

export class ListTableStateCompleteAction implements Action {
    readonly type = LIST_TABLE_STATE_COMPLETE;

    constructor(public payload: { transactionId: number, tableState: TableState }) { }
}

export type Actions
    = SelectProjectAction
    | SortAssetsAction
    | FilterAssetsAction
    | ListTableStateAction
    | ListTableStateCompleteAction;
