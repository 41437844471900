import { AssetIndexTypes } from '../../../../../shared/enums/asset-index-types.enum';
import { STANDARD_FIELDS } from '../../../../../shared/fields/standard-fields';
import { ListOptions } from '../../../../../shared/types';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { Field } from '../../../core/store/models';
import { SortObject, TableFilterObject } from '../../../core/store/types';
import { FilterEntry } from '../../../layout/sidenav-filter/sidenav-filter.component';
import { PDropdownOption } from '../../interfaces/p-dropdown-options';

export const assetTableDefaultSortObject: SortObject = { field: STANDARD_FIELDS.updated_at.canonical_name, order: -1 };

export interface TableState {
    columnOrder?: string[];
    columnWidths?: string;
    filters?: TableFilterObject;
    first?: number;
    sortField?: string;
    sortOrder?: number;
    rows?: number;
}

/**
 * Set selected departments for a component's sidenav
 * @param {{ value: number[], matchMode: string }} deptFilters department filter object
 * @param {AssetIndexTypes} indexType for components with tabs, specify the indexType to set sidenav filters for
 * @param {AssetIndexTypes} currentView for components with tabs, the current tab
 * @param {FilterEntry} departmentSidenavFilter the current state of department sidenav filters
 */
export function setSidenavFilterDepartments(deptFilters: { value: number[], matchMode: string }, indexType: AssetIndexTypes, currentView: AssetIndexTypes, departmentSidenavFilter: FilterEntry): FilterEntry {
    if (currentView === indexType && departmentSidenavFilter) {
        departmentSidenavFilter.selectedEntityIds = deptFilters ? deptFilters.value : [];
    }

    return departmentSidenavFilter;
}

export function listTableState(tableState: TableState, filter: { [id: number]: TableFilterObject }, sort: { [id: number]: SortObject }, id: number): { filter: { [id: number]: TableFilterObject }, sort: { [id: number]: SortObject } } {
    const updatedFilter = { ...filter };
    const updatedSort = { ...sort };

    if (tableState) {
        if (tableState.filters) {
            updatedFilter[id] = tableState.filters;
        }
        if (tableState.sortField && tableState.sortOrder) {
            updatedSort[id] = { field: tableState.sortField, order: tableState.sortOrder, args: [] };
        }
    } else {
        updatedSort[id] = assetTableDefaultSortObject;
    }

    return { filter: updatedFilter, sort: updatedSort };
}

/**
 * Deletes filter keys without meaningful data (to match TurboTable functionality)
 * @param {TableFilterObject} filter filter object from redux
 * @return {TableFilterObject}
 */
export function deleteUnusedFilterKeys(filter: TableFilterObject): TableFilterObject {
    const updatedFilter: TableFilterObject = { ...filter };

    Object.keys(updatedFilter).forEach(key => {
        if ((Array.isArray(updatedFilter[key].value) || typeof updatedFilter[key].value === 'string') && !updatedFilter[key].value.length) {
            delete updatedFilter[key];
        }
    });

    return updatedFilter;
}


/**
 * Check that the table state in the session storage is the same length as the selectedFields being passed into the table
 */
export function validateTableState(selectedFields: Field[], stateKey: string) {
    // If selectedFields don't match tableState's then clear session storage
    const tableState = JSON.parse(localStorage.getItem(stateKey));
    if (tableState && tableState.columnOrder && selectedFields && selectedFields.length !== tableState.columnOrder.length) {
        localStorage.removeItem(stateKey);
    }
    // Updating column order on user settings when column orde changes
    if (tableState) {
        const colFields = [];
        for (let i = 0; i < selectedFields.length; i++) {
            colFields.push(`displayFields.${selectedFields[i].canonical_name}`);
        }
        tableState.columnOrder = colFields;
        localStorage.setItem(stateKey, JSON.stringify(tableState));
    }
}

/**
 * Gets existing global filter option
 */

export function getExistingGlobalSearch(filters: { [key: number]: TableFilterObject }, index: number) {
    const global = filters[index] ? filters[index]['global'] : null;
    return global && global.value ? { global } : {};
}

/** Handles getting the select options since sometimes Field.c_name doesn't correspond to the ColumnFilterOptions key */
function getSelectOptionForKey(selectOptions: ColumnFilterOptions, key: string) {
    let selectOption: PDropdownOption[];
    if (key === 'origin_currency') {
        // options for the origin_currency Field are in project_currency_id
        selectOption = selectOptions['project_currency_id'];
    } else {
        selectOption = selectOptions[key];
    }

    return selectOption;
}

/** Check for reListing Assets based on condition - Asset List should not re-listed if we change the column order or adding or removing the columns */
export function reListingAssets(previousListOptions: ListOptions, newListOptions: ListOptions): boolean {
    if (
        Object.keys(previousListOptions).length == 0 ||
        JSON.stringify(previousListOptions.assetTypes) != JSON.stringify(newListOptions.assetTypes) ||
        JSON.stringify(previousListOptions.forReview) != JSON.stringify(newListOptions.forReview) ||
        JSON.stringify(previousListOptions.projectIds) != JSON.stringify(newListOptions.projectIds) ||
        JSON.stringify(previousListOptions.filter) != JSON.stringify(newListOptions.filter) ||
        JSON.stringify(previousListOptions.limit) != JSON.stringify(newListOptions.limit) ||
        JSON.stringify(previousListOptions.sort) != JSON.stringify(newListOptions.sort) ||
        JSON.stringify(previousListOptions.warehouseIds) != JSON.stringify(newListOptions.warehouseIds)
    ) {
        return true;
    }
    return false;
}

// disable the clear filter options
export function hideClearFilter(filters: TableFilterObject) {
    let hideClearFilter: boolean = true;
    for (const [key, value] of Object.entries(filters)) {
        let filterValueEmpty = false;
        if (value.matchMode === "in") {
            if (value.value) {
                if ((typeof (value.value) === 'string' && value.value !== '') || (typeof (value.value) === 'object' && Array.isArray(value.value) && value.value.length > 0)) {
                    hideClearFilter = false;
                    break;
                }
            }
        }
        if (value.matchMode === 'betweenCurrency') {
            filterValueEmpty = value.value.some(item => item !== "")
            if (filterValueEmpty) {
                hideClearFilter = false;
                break;
            }
        }
        if (value.matchMode === 'between') {
            value.value.forEach(item => {
                item = item === undefined ? null : item;
                if (item !== null) {
                    filterValueEmpty = true;
                }
            });
            if (filterValueEmpty) {
                hideClearFilter = false;
                break;
            }
        }
    }
    return hideClearFilter;
}
