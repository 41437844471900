import * as divisionActions from '../divisions/division.actions';
import { SortObject, TableFilterObject } from '../types';
import * as divisionTrashedActions from './division-trash.actions';

export interface State {
    trashedAssetSort: {
        [divisionId: number]: SortObject;
    };
    trashedAssetFilter: {
        [divisionId: number]: TableFilterObject;
    };
}

export const initialState: State = {
    trashedAssetSort: {},
    trashedAssetFilter: {}
};

export function reducer(state = initialState, action: divisionTrashedActions.Actions | divisionActions.Actions): State {
    switch (action.type) {
        case divisionTrashedActions.SORT_DIVISION_TRASHED_LIST: {
            const divisionId = action.payload.divisionId;
            const assetSort = action.payload.sort;
            const currentSort = state.trashedAssetSort[divisionId];

            if (currentSort && currentSort.field === assetSort.field && currentSort.order === assetSort.order) {
                return state;
            }

            const sortObj = {};
            sortObj[divisionId] = { ...action.payload.sort };

            return {
                ...state,
                trashedAssetSort: { ...state.trashedAssetSort, ...sortObj }
            };
        }

        case divisionTrashedActions.FILTER_DIVISION_TRASHED_LIST: {
            const divisionId = action.payload.divisionId;
            const assetFilter = action.payload.filter;
            const assetFilterObj = { ...state.trashedAssetFilter };
            const newFilter: TableFilterObject = {};

            if (!assetFilterObj[divisionId]) {
                assetFilterObj[divisionId] = {};
            }
            Object.keys(assetFilter).forEach(filter => {
                const value = assetFilter[filter].value;
                if (!newFilter[filter] && value && !(Array.isArray(value) && !value.length)) {
                    newFilter[filter] = assetFilter[filter];
                } else if (newFilter[filter]) {
                    if (value && !(Array.isArray(value) && !value.length)) {
                        newFilter[filter] = assetFilter[filter];
                    } else {
                        delete newFilter[filter];
                    }
                }
            });
            assetFilterObj[divisionId] = { ...newFilter };

            return {
                ...state,
                trashedAssetFilter: { ...assetFilterObj }
            };
        }

        default: {
            return state;
        }
    }
}
