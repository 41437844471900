import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as eventFieldActions from './event-field.actions';
import { EventField } from './event-field.model';

@Injectable()
export class EventFieldEffects {
     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(eventFieldActions.LIST),
            map((action: eventFieldActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<Event[]>(`/api/studios/${studioId}/event-fields`).pipe(
                map(res => {
                    const response = res;
                    return response.map(f => new EventField(f));
                }),
                map((eventFields: EventField[]) => new eventFieldActions.ListCompleteAction(eventFields)),
                catchError((error) => observableOf(new eventFieldActions.ListFailedAction({ error }))))
            )));

     listFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(eventFieldActions.LIST_FAILED),
            map((action: eventFieldActions.ListFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
