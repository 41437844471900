import { BaseModel } from './../base/base.model';
import { departmentPermissionObjectMock } from './department-permission.model';
import { DepartmentPermission } from './department-permission.model';

export interface FranchisePermissionObject {
    id: number;
    admin: boolean;
    franchise_id: number;
    studio_permission_id: number;
    departmentPermissions: any[];
    position: string;
    can_edit: boolean;
    can_access_restricted: boolean;
    can_access_asset_details: boolean;
    can_upload_ledger: boolean;
    can_view_historical: boolean;
    can_unlink_transactions: boolean;
    can_access_asset_search: boolean;
    can_edit_groups: boolean;
}

export class FranchisePermission extends BaseModel {
    id: number;
    admin: boolean = null;
    franchise_id: number = null;
    studio_permission_id: number = null;
    departmentPermissions: DepartmentPermission[] = [];
    position = '';
    can_edit: boolean = null;
    can_access_restricted: boolean = null;
    can_access_asset_details: boolean = null;
    can_upload_ledger: boolean = null;
    can_view_historical: boolean = null;
    can_unlink_transactions: boolean = null;
    can_access_asset_search: boolean = null;
    can_edit_groups: boolean = null;

    constructor(franchisePermission: FranchisePermissionObject) {
        super();
        this.initializeData(franchisePermission, this);
        if (franchisePermission.departmentPermissions) {
            this.departmentPermissions = franchisePermission.departmentPermissions.map(dp => new DepartmentPermission(dp));
        }
    }
}

const franchisePermissionObjectMock: FranchisePermissionObject = {
    id: 1,
    admin: null,
    franchise_id: 1,
    studio_permission_id: 1,
    departmentPermissions: [],
    position: 'Assistant',
    can_edit: null,
    can_access_restricted: null,
    can_access_asset_details: null,
    can_upload_ledger: null,
    can_view_historical: null,
    can_unlink_transactions: null,
    can_access_asset_search: null,
    can_edit_groups: null
};

export function getFranchisePermissionObjectMock(admin: boolean, withDepartmentPerms?: boolean) {
    const franchisePermsObj = Object.assign({}, franchisePermissionObjectMock);
    franchisePermsObj.admin = admin;
    if (withDepartmentPerms) {
        franchisePermsObj.departmentPermissions = [departmentPermissionObjectMock];
    }
    return franchisePermsObj;
}
