import { createSelector } from 'reselect';
import * as root from '../';
import { NAV_TYPES } from '../../../../../shared/enums/nav-types.enum';
import { getFilteredDeptIds as getFranchiseAssetIndexFilteredDeptIds } from '../../../assets/franchise-asset-index/franchise-asset-index.selectors';
import { State as SidenavFilterState } from '../sidenav-filter/sidenav-filter.reducer';

function checkFiltersSelected(assetFilterOptions: boolean, navType: NAV_TYPES, sidenavFilterState: SidenavFilterState, franchiseAssetIndexDeptIds: number[], franchiseId: number, divisionId: number) {
    if (navType && sidenavFilterState && assetFilterOptions) {
        if (navType === NAV_TYPES.FRANCHISE) {
            return (franchiseAssetIndexDeptIds && !!franchiseAssetIndexDeptIds.length) || objectHasItems(sidenavFilterState.assetProjectIds, franchiseId);
        } else if (navType === NAV_TYPES.DIVISION) {
            return objectHasItems(sidenavFilterState.assetWarehouseIds, divisionId);
        }
    }
    return false;
}

// This thing only works on the franchise asset index and probably the division asset index.
// It doesn't do anything on the transaction asset index
export const isFilterSelected = createSelector(root.getSidenavShowAssetFilterOptions, root.getNavType, root.getSidenavFilterState, getFranchiseAssetIndexFilteredDeptIds, root.getSelectedFranchiseId, root.getSelectedDivisionId, (assetFilterOptions, navType, sidenavFilterState, franchiseAssetIndexDeptIds, franchiseId, divisionId) => {
    return checkFiltersSelected(assetFilterOptions, navType, sidenavFilterState, franchiseAssetIndexDeptIds, franchiseId, divisionId);
});

function objectHasItems(obj, id) {
    const value = obj[id] ? obj[id].length : 0;
    return value ? true : false;
}
