import { Action } from '@ngrx/store';
import { SortObject } from '../types';
import { Invite } from './invite.model';

export const LIST_BY_FRANCHISE = '[Invite] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[Invite] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Invite] List By Franchise Failed';
export const SORT_FRANCHISE_LIST = '[Invite] Sort Franchise List';

export const LIST_BY_DIVISION = '[Invite] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[Invite] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[Invite] List By Division Failed';
export const SORT_DIVISION_LIST = '[Invite] Sort Division List';

export const LIST_BY_STUDIO = '[Invite] List By Studio';
export const LIST_BY_STUDIO_COMPLETE = '[Invite] List By Studio Complete';
export const LIST_BY_STUDIO_FAILED = '[Invite] List By Studio Failed';
export const SORT_STUDIO_LIST = '[Invite] Sort Studio List';

export const ADD = '[Invite] Add';
export const ADD_COMPLETE = '[Invite] Add Complete';
export const ADD_FAILED = '[Invite] Add Failed';

export const DELETE = '[Invite] Delete';
export const DELETE_COMPLETE = '[Invite] Delete Complete';
export const DELETE_FAILED = '[Invite] Delete Failed';

export const RESEND = '[Invite] Resend';
export const RESEND_COMPLETE = '[Invite] Resend Complete';
export const RESEND_FAILED = '[Invite] Resend Failed';

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: number) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: Invite[]) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: any, franchiseId: number }) { }
}

export class SortFranchiseListAction implements Action {
    readonly type = SORT_FRANCHISE_LIST;

    constructor(public payload: SortObject) { }
}

export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: number) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: Invite[]) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: { error: any, divisionId: number }) { }
}

export class SortDivisionListAction implements Action {
    readonly type = SORT_DIVISION_LIST;

    constructor(public payload: SortObject) { }
}

export class ListByStudioAction implements Action {
    readonly type = LIST_BY_STUDIO;

    constructor(public payload: number) { }
}

export class ListByStudioCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_COMPLETE;

    constructor(public payload: Invite[]) { }
}

export class ListByStudioFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class SortStudioListAction implements Action {
    readonly type = SORT_STUDIO_LIST;

    constructor(public payload: SortObject) { }
}

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: Invite[]) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Invite[]) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: any, studioId: number }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: { inviteIds: number[] }) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: number[]) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) { }
}

export class ResendAction implements Action {
    readonly type = RESEND;

    constructor(public payload: { inviteIds: number[], studioId?: number, franchiseId?: number, divisionId?: number }) { }
}

export class ResendCompleteAction implements Action {
    readonly type = RESEND_COMPLETE;

    constructor(public payload: { invites: Invite[], studioId?: number, franchiseId?: number, divisionId?: number }) { }
}

export class ResendFailedAction implements Action {
    readonly type = RESEND_FAILED;

    constructor(public payload: any) { }
}
export type Actions
    = ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | SortFranchiseListAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | SortDivisionListAction
    | ListByStudioAction
    | ListByStudioCompleteAction
    | ListByStudioFailedAction
    | SortStudioListAction
    | AddAction
    | AddCompleteAction
    | AddFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ResendAction
    | ResendCompleteAction
    | ResendFailedAction;
