import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfirmDuplicateModalComponent } from '../../../shared/components/confirm-duplicate-modal/confirm-duplicate-modal.component';
import * as layout from '../layout/layout.actions';
import * as sosLinkActions from './sos-link.actions';
import { SosProjectDepartment } from './sos-project-department.model';

@Injectable()
export class SosLinkEffects {
     create$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosLinkActions.CREATE),
            map((action: sosLinkActions.CreateAction) => action.payload),
            switchMap((linkData) => this.http.post(`/api/projects/link`, linkData).pipe(
                map((res) => new sosLinkActions.CreateCompleteAction(res)),
                catchError(error => {
                    return observableOf(new sosLinkActions.CreateFailedAction({ error }))
                }))
            )));

     createComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosLinkActions.CREATE_COMPLETE),
            map((action: sosLinkActions.CreateCompleteAction) => {
                let message = 'Prod Link Succeeded';
                if (action.payload.duplicate) {
                    this.dialog.open(ConfirmDuplicateModalComponent, {
                        data: {
                            title: 'Warning', confirmColor: 'warn',
                            duplicate: action.payload.duplicate, linked: action.payload.addedLink
                        }
                    });
                    if (action.payload.addedLink)
                        return new sosLinkActions.DisplayWarningInfoCompleteAction(new SosProjectDepartment(action.payload.addedLink));
                    else
                        return new layout.InfoDisplayedAction();
                } else if (action.payload.addedLink) {
                    this.dialog.open(ConfirmDuplicateModalComponent, { data: { title: 'Information', duplicate: action.payload.duplicate, linked: action.payload.addedLink } });
                    return new sosLinkActions.DisplayWarningInfoCompleteAction(new SosProjectDepartment(action.payload.addedLink));
                } else {
                    return new layout.InfoAction({ message: message });
                }
            })));

     delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosLinkActions.DELETE),
            map((action: sosLinkActions.DeleteAction) => action.payload),
            switchMap((id) => this.http.delete(`/api/projects/link/${id}`).pipe(
                map((list: number[]) => new sosLinkActions.DeleteCompleteAction(list)),
                catchError((error) => observableOf(new sosLinkActions.DeleteFailedAction({ error }))))
            )));

     list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosLinkActions.LIST),
            map((action: sosLinkActions.ListAction) => action.payload),
            switchMap((studioId) => this.http.get<SosProjectDepartment[]>(`/api/projects/${studioId}/links`).pipe(
                map(res => res.map(s => new SosProjectDepartment(s))),
                map((links: SosProjectDepartment[]) => new sosLinkActions.ListCompleteAction(links)),
                catchError(error => observableOf(new sosLinkActions.ListFailedAction({ error }))))
            )));

     failed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(sosLinkActions.CREATE_FAILED, sosLinkActions.LIST_FAILED, sosLinkActions.DELETE_FAILED),
            map((action: any) => action.payload),
            map(payload => new layout.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient, private dialog: MatDialog) { }
}
