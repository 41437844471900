import { createSelector } from 'reselect';
import * as projectActions from '../projects/project.actions';
import { ActionStatus } from '../types';
import * as franchiseActions from './franchise.actions';
import { Franchise } from './franchise.model';

export interface State {
    ids: number[];
    ownedFranchiseIds: number[];
    entities: {
        [franchiseId: number]: Franchise;
    };
    franchiseProjectsIds: {
        [franchiseId: number]: number[];
    };
    ownedFranchiseProjectsIds: {
        [franchiseId: number]: number[];
    };
    getting: ActionStatus;
    listing: ActionStatus;
    selectedFranchiseId: number;
}

export const initialState: State = {
    ids: [],
    ownedFranchiseIds: [],
    entities: {},
    franchiseProjectsIds: {},
    ownedFranchiseProjectsIds: {},
    getting: ActionStatus.Complete,
    listing: ActionStatus.Complete,
    selectedFranchiseId: null
};

export function reducer(state = initialState, action: franchiseActions.Actions | projectActions.Actions): State {
    switch (action.type) {
        case franchiseActions.ADD_COMPLETE: {
            const newFranchise = action.payload;

            let franchiseProjectsIds = state.franchiseProjectsIds;
            if (newFranchise.projects) {
                franchiseProjectsIds = {
                    ...state.franchiseProjectsIds,
                    [newFranchise.id]: newFranchise.projects.map(p => p.id)
                };
            }

            return {
                ...state,
                ids: [...state.ids, newFranchise.id],
                entities: {
                    ...state.entities,
                    [newFranchise.id]: newFranchise
                },
                franchiseProjectsIds
            };
        }

        case franchiseActions.UPDATE_COMPLETE: {
            const franchise = action.payload;

            const alterStateWith = {
                entities: { ...state.entities, [franchise.id]: franchise },
            };
            return { ...state, ...alterStateWith };
        }

        case franchiseActions.GET: {
            return { ...state, getting: ActionStatus.Loading };
        }

        case franchiseActions.GET_COMPLETE: {
            const franchise = action.payload;

            const newEnts = { [franchise.id]: franchise };
            const franProjMap = { [franchise.id]: franchise.projects.map(p => p.id) };

            const newIds = [];
            if (!state.entities[franchise.id]) {
                newIds.push(franchise.id);
            }

            return {
                ...state,
                ids: [...state.ids, ...newIds],
                entities: {
                    ...state.entities,
                    ...newEnts,
                },
                getting: ActionStatus.Complete,
                franchiseProjectsIds: {
                    ...state.franchiseProjectsIds,
                    ...franProjMap
                }
            };
        }

        case franchiseActions.GET_FAILED: {
            return { ...state, getting: ActionStatus.Failed };
        }

        case franchiseActions.LIST:
        case franchiseActions.LIST_FRANCHISE_DIVISION_USER: {
            return { ...state, listing: ActionStatus.Loading };
        }

        case franchiseActions.LIST_FAILED:
        case franchiseActions.LIST_FRANCHISE_DIVISION_USER_FAILED: {
            return { ...state, listing: ActionStatus.Failed };
        }

        case franchiseActions.LIST_COMPLETE:
        case franchiseActions.LIST_FRANCHISE_DIVISION_USER_COMPLETE: {
            const franchisesForStudio = action.payload.franchises;

            const franchiseIdMap = {};
            const franchiseIds = [];
            const franchiseProjectIdMap = { ...state.franchiseProjectsIds };
            const ownedFranchiseProjectIdMap = {};

            franchisesForStudio.forEach(f => {
                franchiseIdMap[f.id] = f;
                if (f.projects) {
                    if (franchiseProjectIdMap[f.id]) {
                        for (const id of f.projects.map(p => p.id)) {
                            if (!franchiseProjectIdMap[f.id].includes(id)) {
                                franchiseProjectIdMap[f.id] = [...franchiseProjectIdMap[f.id], id];
                            }
                        }
                    } else {
                        franchiseProjectIdMap[f.id] = f.projects.map(p => p.id);
                    }
                    if (action.payload.onlyOwnerOptions) {
                        ownedFranchiseProjectIdMap[f.id] = f.projects.map(p => p.id);
                    }
                }
                franchiseIds.push(f.id);
            });
            if (action.payload.onlyOwnerOptions) {
                return {
                    ...state,
                    ownedFranchiseIds: franchiseIds,
                    entities: { ...state.entities, ...franchiseIdMap },
                    franchiseProjectsIds: franchiseProjectIdMap,
                    ownedFranchiseProjectsIds: ownedFranchiseProjectIdMap
                };
            } else {
                return {
                    ...state,
                    ids: franchiseIds,
                    entities: { ...state.entities, ...franchiseIdMap },
                    franchiseProjectsIds: franchiseProjectIdMap,
                    listing: ActionStatus.Complete
                };
            }
        }

        case franchiseActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedFranchiseId) {
                newState = {
                    ...state,
                    selectedFranchiseId: action.payload
                };
            }
            return newState;
        }

        case projectActions.ADD_COMPLETE: {
            const project = action.payload.project;
            const franchiseProjectIds = state.franchiseProjectsIds[action.payload.franchiseId];
            if (franchiseProjectIds.indexOf(project.id) > -1) {
                return state;
            }
            return {
                ...state,
                franchiseProjectsIds: {
                    ...state.franchiseProjectsIds,
                    [action.payload.franchiseId]: [...franchiseProjectIds, project.id]
                }
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
const getIds = (state: State) => state.ids;
const getOwnedIds = (state: State) => state.ownedFranchiseIds;
export const getSelectedFranchiseId = (state: State) => state.selectedFranchiseId;
export const getGetting = (state: State) => state.getting;
export const getListing = (state: State) => state.listing;

export const getFranchiseProjectsIds = (state: State) => state.franchiseProjectsIds;
export const getOwnedFranchiseProjectsIds = (state: State) => state.ownedFranchiseProjectsIds;

export const getSelectedFranchiseProjectIds = createSelector(getSelectedFranchiseId, getFranchiseProjectsIds, (selectedId, projectIdsMap) => selectedId && projectIdsMap[selectedId] ? projectIdsMap[selectedId] : []);

export const getSelected = createSelector(getEntities, getSelectedFranchiseId, (entities, selectedId) => entities[selectedId]);
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => {
    return entities[id];
}));
export const getOwned = createSelector(getEntities, getOwnedIds, (entities, ids) => ids.map(id => entities[id]));
