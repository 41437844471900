import { Attachment } from '../attachments/attachment.model';
import { SubGroup } from '../sub-groups/sub-group.model';
import { BaseModel } from './../base/base.model';

export class Group extends BaseModel {
    id: number;
    countForFranchise: { [franchiseId: number]: number } = {};
    countForDivision: { [divisionId: number]: number } = {};
    attachmentCount: number = null;
    subGroupAttachmentCount?: number = 0;
    studio_id: number = null;
    name: string = null;
    description: string = null;
    created_at: Date = null;
    attachments?: Attachment[] = [];
    assetCount?: number = 0;
    group_type_id: number = null;
    franchise_id?: number = null;
    project_id?: number = null;
    character: string = null;
    subGroups?: SubGroup[] = [];
    subGroupAttachments?: Attachment[] = [];
    allAttachments?: Attachment[] = [];

    constructor(groupObject) {
        super();
        this.initializeData(groupObject, this);

        if (groupObject && groupObject.attachments) {
            this.attachments = groupObject.attachments.map(attachment => {
                const newAttachment = new Attachment(attachment);
                newAttachment.owner_id = this.id;
                return newAttachment;
            });
        }
        if (groupObject && groupObject.subGroupAttachments) {
            this.subGroupAttachments = groupObject.subGroupAttachments.map(attachment => {
                const newAttachment = new Attachment(attachment);
                newAttachment.owner_id = this.id;
                return newAttachment;
            });
        }
        this.allAttachments = [...this.attachments, ...this.subGroupAttachments];

        if (groupObject && groupObject.subGroups) {
            this.subGroups = groupObject.subGroups.map(subGroup => {
                const newSubGroup = new SubGroup(subGroup);
                return newSubGroup;
            });
        }
    }
}

export const groupObjectMock = {
    id: 1,
    name: 'Good Stuff',
};
