import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as layoutActions from '../layout/layout.actions';
import * as reportActions from './report.actions';

@Injectable()
export class ReportEffects {

     create$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(reportActions.CREATE, reportActions.CREATE_DIVISION, reportActions.CREATE_EVENT, reportActions.CREATE_REQUEST),
            map((action: reportActions.CreateAction) => action.payload),
            switchMap((reportData) => this.http.post(`/api/assetreport`, reportData, { responseType: 'arraybuffer' }).pipe(
                map((res) => new Blob([res], { type: 'application/pdf' })),
                map((blob: Blob) => new reportActions.CreateCompleteAction({ blob })),
                catchError((error) => observableOf(new reportActions.CreateFailedAction({ error }))))
            )));

     createComplete$ = createEffect(() => this.actions$
        .pipe(
            ofType(reportActions.CREATE_COMPLETE),
            map((action: reportActions.CreateCompleteAction) => {
                saveAs(action.payload.blob, 'AssetReport.pdf');
                return 'AssetReport.pdf';
            }),
            map((filename) => new layoutActions.InfoAction({ message: `Created asset report ${filename}` }))));

     createFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(reportActions.CREATE_FAILED),
            map((action: reportActions.CreateFailedAction) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    constructor(private actions$: Actions, private http: HttpClient) { }
}
