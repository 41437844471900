import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf } from 'rxjs';
import { Action } from '@ngrx/store';
import * as franchiseSubGroupActions from './franchise-subgroup.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FranchiseSubGroupEffects {
     getAssetCount$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(franchiseSubGroupActions.GET_ASSET_COUNT),
            map((action: franchiseSubGroupActions.GetAssetCountAction) => action.payload),
            switchMap(({ franchiseId, subgroupId }) => this.http.get<number>(`/api/franchises/${franchiseId}/subgroups/${subgroupId}/asset-count`).pipe(
                map((res) => new franchiseSubGroupActions.GetAssetCountCompleteAction(res)),
                catchError((error) => observableOf(new franchiseSubGroupActions.GetAssetCountFailedAction({ error }))))
            )));

    constructor(private actions$: Actions, private http: HttpClient) { }
}