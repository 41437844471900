import { createSelector } from 'reselect';
import { SortableIds } from '../types';
import { ActionStatus } from '../types';
import { copyAndMultiSort, getEntitiesObject } from '../utils';
import * as inviteActions from './invite.actions';
import { Invite } from './invite.model';

export interface State {
    entities: {
        [inviteId: number]: Invite
    };
    franchise: SortableIds;
    studio: SortableIds;
    division: SortableIds;
    saving: ActionStatus;
}

export const initialState = {
    entities: {},
    franchise: {
        ids: [],
        sortKey: 'email',
        sortOrder: 1,
        sortArgs: []
    },
    studio: {
        ids: [],
        sortKey: 'email',
        sortOrder: 1,
        sortArgs: []
    },
    division: {
        ids: [],
        sortKey: 'email',
        sortOrder: 1,
        sortArgs: []
    },
    saving: ActionStatus.Complete
};

function sortIds(ids, entities, key, order, args): number[] {
    return copyAndMultiSort(ids, entities, [{ key }], order, args);
}

export function reducer(state = initialState, action: inviteActions.Actions): State {
    switch (action.type) {
        case inviteActions.RESEND: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case inviteActions.RESEND_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case inviteActions.LIST_BY_FRANCHISE_COMPLETE: {
            const invites = action.payload;
            const inviteObj = getEntitiesObject(invites);
            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...inviteObj
                },
                franchise: {
                    ...state.franchise,
                    ids: sortIds(
                        invites.map(invite => invite.id),
                        inviteObj, state.franchise.sortKey,
                        state.franchise.sortOrder, state.franchise.sortArgs
                    )
                }
            };

            return { ...state, ...alterStateWith };
        }

        case inviteActions.LIST_BY_STUDIO_COMPLETE: {
            const invites = action.payload;
            const inviteObj = getEntitiesObject(invites);
            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...inviteObj
                },
                studio: {
                    ...state.studio,
                    ids: sortIds(
                        invites.map(invite => invite.id),
                        inviteObj, state.studio.sortKey, state.studio.sortOrder,
                        state.studio.sortArgs
                    )
                }
            };

            return { ...state, ...alterStateWith };
        }

        case inviteActions.LIST_BY_DIVISION_COMPLETE: {
            const invites = action.payload;
            const inviteObj = getEntitiesObject(invites);
            const alterStateWith = {
                entities: {
                    ...state.entities,
                    ...inviteObj
                },
                division: {
                    ...state.division,
                    ids: sortIds(
                        invites.map(invite => invite.id),
                        inviteObj, state.division.sortKey,
                        state.division.sortOrder, state.division.sortArgs
                    )
                }
            };

            return { ...state, ...alterStateWith };
        }

        case inviteActions.SORT_FRANCHISE_LIST: {
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;

            const sorted = sortIds(state.franchise.ids, state.entities, sortKey, sortOrder, sortArgs);

            return {
                ...state,
                franchise: {
                    ids: sorted,
                    sortKey,
                    sortOrder,
                    sortArgs
                }
            };
        }

        case inviteActions.SORT_STUDIO_LIST: {
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;

            const sorted = sortIds(state.studio.ids, state.entities, sortKey, sortOrder, sortArgs);

            return {
                ...state,
                studio: {
                    ids: sorted,
                    sortKey,
                    sortOrder,
                    sortArgs
                }
            };
        }

        case inviteActions.SORT_DIVISION_LIST: {
            const sortKey = action.payload.field;
            const sortOrder = action.payload.order;
            const sortArgs = action.payload.args;

            const sorted = sortIds(state.division.ids, state.entities, sortKey, sortOrder, sortArgs);

            return {
                ...state,
                division: {
                    ids: sorted,
                    sortKey,
                    sortOrder,
                    sortArgs
                }
            };
        }

        case inviteActions.ADD: {
            return {
                ...state,
                saving: ActionStatus.Loading
            };
        }

        case inviteActions.ADD_FAILED: {
            return {
                ...state,
                saving: ActionStatus.Failed
            };
        }

        case inviteActions.ADD_COMPLETE: {
            const invites = action.payload;
            const inviteObj = getEntitiesObject(invites);
            const updatedEntities = { ...state.entities, ...inviteObj };
            const studioIds = state.studio.ids.concat(invites.map(invite => invite.id));

            const franchiseIds = state.franchise.ids.slice();
            const divisionIds = state.division.ids.slice();
            invites.forEach(invite => {
                if (invite.franchise_id) {
                    franchiseIds.push(invite.id);
                }
                if (invite.division_id) {
                    divisionIds.push(invite.id);
                }
            });
            const alterStateWith = {
                entities: updatedEntities,
                franchise: {
                    ...state.franchise,
                    ids: sortIds(franchiseIds, updatedEntities, state.franchise.sortKey, state.franchise.sortOrder, state.franchise.sortArgs)
                },
                studio: {
                    ...state.studio,
                    ids: sortIds(studioIds, updatedEntities, state.studio.sortKey, state.studio.sortOrder, state.studio.sortArgs)
                },
                division: {
                    ...state.division,
                    ids: sortIds(divisionIds, updatedEntities, state.division.sortKey, state.division.sortOrder, state.division.sortArgs)
                },
                saving: ActionStatus.Complete
            };
            return { ...state, ...alterStateWith };
        }

        case inviteActions.DELETE_COMPLETE: {
            const inviteIds = action.payload;
            const entitiesCopy = { ...state.entities };
            let franchiseSortIds = [...state.franchise.ids];
            let studioSortIds = [...state.studio.ids];
            let divisionIds = [...state.division.ids];

            let alterStateWith = { ...state };

            for (const inviteId of inviteIds) {
                delete entitiesCopy[inviteId];
                franchiseSortIds = franchiseSortIds.filter(id => id !== inviteId);
                studioSortIds = studioSortIds.filter(id => id !== inviteId);
                divisionIds = divisionIds.filter(id => id !== inviteId);

                alterStateWith = {
                    ...alterStateWith,
                    entities: entitiesCopy,
                    franchise: {
                        ...state.franchise,
                        ids: franchiseSortIds
                    },
                    studio: {
                        ...state.studio,
                        ids: studioSortIds
                    },
                    division: {
                        ...state.division,
                        ids: divisionIds
                    }
                };
            }
            return { ...state, ...alterStateWith };
        }

        case inviteActions.RESEND_COMPLETE: {
            const invites = action.payload.invites;
            const inviteObj = getEntitiesObject(invites);
            let alterStateWith;
            if (action.payload.studioId) {
                alterStateWith = {
                    studio: {
                        ...state.studio,
                        ids: sortIds(
                            invites.map(invite => invite.id),
                            inviteObj, state.studio.sortKey, state.studio.sortOrder,
                            state.studio.sortArgs
                        )
                    }
                };
            } else if (action.payload.franchiseId) {
                alterStateWith = {
                    ...alterStateWith,
                    franchise: {
                        ...state.franchise,
                        ids: sortIds(
                            invites.map(invite => invite.id),
                            inviteObj, state.franchise.sortKey,
                            state.franchise.sortOrder, state.franchise.sortArgs
                        )
                    }
                };
            } else if (action.payload.divisionId) {
                alterStateWith = {
                    division: {
                        ...state.division,
                        ids: sortIds(
                            invites.map(invite => invite.id),
                            inviteObj, state.division.sortKey,
                            state.division.sortOrder, state.division.sortArgs
                        )
                    }
                };
            }

            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...inviteObj
                },
                ...alterStateWith,
                saving: ActionStatus.Complete
            };
        }

        default: {
            return state;
        }
    }
}

const entitiesSelector = (state: State) => state.entities;
const franchiseIdsSelector = (state: State) => state.franchise.ids;
const studioIdsSelector = (state: State) => state.studio.ids;
const divisionIdsSelector = (state: State) => state.division.ids;
export const getSaving = (state: State) => state.saving;

export const getInvitesForFranchise = createSelector(
    entitiesSelector,
    franchiseIdsSelector,
    (invites, ids) => ids.map(id => invites[id])
);

export const getInvitesForStudio = createSelector(
    entitiesSelector,
    studioIdsSelector,
    (invites, ids) => ids.map(id => invites[id])
);

export const getInvitesForDivision = createSelector(
    entitiesSelector,
    divisionIdsSelector,
    (invites, ids) => ids.map(id => invites[id])
);
