import { Component } from '@angular/core';

@Component({
    selector: 'ah-root',

    template: ` 
            <ah-layout></ah-layout>
    `,
})
export class AppComponent {
    constructor() {
    }
}
