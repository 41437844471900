import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Asset } from '../assets/asset.model';
import { AssetEvent } from '../models';
import { Event } from './event.model';

export const ADD = '[Event] Add';
export const ADD_COMPLETE = '[Event] Add Complete';
export const ADD_FAILED = '[Event] Add Failed';

export const ADD_ASSETS = '[Event] Add Assets';
export const ADD_ASSETS_COMPLETE = '[Event] Add Assets Complete';
export const ADD_ASSETS_FAILED = '[Event] Add Assets Failed';

export const DELETE = '[Event] Delete';
export const DELETE_COMPLETE = '[Event] Delete Complete';
export const DELETE_FAILED = '[Event] Delete Failed';

export const REMOVE_ASSETS = '[Event] Remove Assets';
export const REMOVE_ASSETS_COMPLETE = '[Event] Remove Assets Complete';
export const REMOVE_ASSETS_FAILED = '[Event] Remove Assets Failed';

export const LIST_BY_DIVISION = '[Event] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[Event] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[Event] List By Division Failed';

export const GET = '[Event] Get';
export const GET_COMPLETE = '[Event] Get Complete';
export const GET_FAILED = '[Event] Get Failed';

export const GET_OVERLAP_ASSETS = '[Event] Get Overlap Assets';
export const GET_OVERLAP_ASSETS_COMPLETE = '[Event] Get Overlap Assets Complete';
export const GET_OVERLAP_ASSETS_FAILED = '[Event] Get Overlap Assets Failed';
export const SET_OVERLAP_ASSETS_INACTIVE = '[Event] Set Overlap Assets Inactive';

export const UPDATE = '[Event] Update';
export const UPDATE_COMPLETE = '[Event] Update Complete';
export const UPDATE_FAILED = '[Event] Update Failed';

export const SELECT = '[Event] Select';

export const SORT = '[Events] Sort Events';

export const CLONE = '[Event] Clone';
export const CLONE_COMPLETE = '[Event] Clone Complete';
export const CLONE_FAILED = '[Event] Clone Failed';

export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: Event) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: Event) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: any) { }
}

export class AddAssetsAction implements Action {
    readonly type = ADD_ASSETS;

    constructor(public payload: { eventId: number, assetIds: number[] }) { }
}

export class AddAssetsCompleteAction implements Action {
    readonly type = ADD_ASSETS_COMPLETE;

    constructor(public payload: { assets: Asset[], eventId: number }) { }
}

export class AddAssetsFailedAction implements Action {
    readonly type = ADD_ASSETS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DeleteAction implements Action {
    readonly type = DELETE;

    constructor(public payload: Event) { }
}

export class DeleteCompleteAction implements Action {
    readonly type = DELETE_COMPLETE;

    constructor(public payload: Event) { }
}

export class DeleteFailedAction implements Action {
    readonly type = DELETE_FAILED;

    constructor(public payload: { error: any }) { }
}

export class RemoveAssetsAction implements Action {
    readonly type = REMOVE_ASSETS;

    constructor(public payload: { assetIds: number[], eventId: number }) { }
}

export class RemoveAssetsCompleteAction implements Action {
    readonly type = REMOVE_ASSETS_COMPLETE;

    constructor(public payload: { assetIds: number[], eventId: number }) { }
}

export class RemoveAssetsFailedAction implements Action {
    readonly type = REMOVE_ASSETS_FAILED;

    constructor(public payload: any) { }
}

export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: number) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: Event[]) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: { error: any }) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: { event: Event, assetEvents: AssetEvent[] }) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: { error: any }) { }
}

export class GetOverlapAssetsAction implements Action {
    readonly type = GET_OVERLAP_ASSETS;

    constructor(public payload: { event: Event, assetIds?: number[] }) { }
}

export class GetOverlapAssetsCompleteAction implements Action {
    readonly type = GET_OVERLAP_ASSETS_COMPLETE;

    constructor(public payload: number) { }
}

export class GetOverlapAssetsFailedAction implements Action {
    readonly type = GET_OVERLAP_ASSETS_FAILED;

    constructor(public payload: any) { }
}

export class SetOverlapAssetsInactive implements Action {
    readonly type = SET_OVERLAP_ASSETS_INACTIVE;

    constructor() { }
}

export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: Event) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: Event) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: any) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class SortAction implements Action {
    readonly type = SORT;

    constructor(public payload: {
        field: string,
        order: number,
        args: any[]
    }) { }
}

export class CloneAction implements Action {
    readonly type = CLONE;

    constructor(public payload: { event: Event, eventId: number }) { }
}

export class CloneCompleteAction implements Action {
    readonly type = CLONE_COMPLETE;

    constructor(public payload: Event) { }
}

export class CloneFailedAction implements Action {
    readonly type = CLONE_FAILED;

    constructor(public payload: { error: any }) { }
}

export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | AddAssetsAction
    | AddAssetsCompleteAction
    | AddAssetsFailedAction
    | RemoveAssetsAction
    | RemoveAssetsCompleteAction
    | RemoveAssetsFailedAction
    | DeleteAction
    | DeleteCompleteAction
    | DeleteFailedAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | GetOverlapAssetsAction
    | GetOverlapAssetsCompleteAction
    | GetOverlapAssetsFailedAction
    | SetOverlapAssetsInactive
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | SelectAction
    | SortAction
    | CloneAction
    | CloneCompleteAction
    | CloneFailedAction;
