import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as picklistOptionActions from './picklist-option.actions';
import * as layoutActions from '../layout/layout.actions';
import { PicklistOption } from './picklist-option.model';
import { MatDialog } from '@angular/material/dialog';
import { RemoveModalComponent } from '../../../shared/components/remove-modal/remove-modal.component';

@Injectable()
export class PicklistOptionEffects {


    list$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.LIST),
            map((action: picklistOptionActions.ListAction) => action.payload),
            switchMap((payload) => this.http.get<PicklistOption[]>(`/api/studio/${payload.studioId}/picklist-options/${payload.fieldId}`)
                .pipe(
                    map(res => res.map((po) => new PicklistOption(po))),
                    map((picklistOptions: PicklistOption[]) => new picklistOptionActions.ListCompleteAction(picklistOptions)),
                    catchError((error) => observableOf(new picklistOptionActions.ListFailedAction({ error })))
                )
            )
        ));


    listFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.LIST_FAILED),
            map((action: picklistOptionActions.ListFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))
        ));



    add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.ADD),
            map((action: picklistOptionActions.AddAction) => action.payload),
            switchMap((data: { studioId: number, picklistOption: PicklistOption }) => this.http.post(`/api/studio/${data.studioId}/picklist-option`, data.picklistOption)
                .pipe(
                    map((res) => new picklistOptionActions.AddCompleteAction(new PicklistOption(res))),
                    map(() => new layoutActions.InfoAction({ message: 'Picklist option has been created.' })),
                    catchError(error => observableOf(new picklistOptionActions.AddFailedAction({ error })))
                )
            )
        ))



    addFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.ADD_FAILED),
            map((action: picklistOptionActions.AddFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))
        ));



    update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.UPDATE),
            map((action: picklistOptionActions.UpdateAction) => action.payload),
            mergeMap((data: { studioId: number, picklistOption: PicklistOption }) => this.http.put(`/api/studio/${data.studioId}/picklist-option`, data.picklistOption)
                .pipe(
                    map((res) => new picklistOptionActions.UpdateCompleteAction(new PicklistOption(res))),
                    map(() => new layoutActions.InfoAction({ message: `Picklist option updated successfully.` })),
                    catchError(error => observableOf(new picklistOptionActions.UpdateFailedAction({ error })))
                )
            )
        ))



    updateFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.UPDATE_FAILED),
            map((action: picklistOptionActions.UpdateFailedAction) => action.payload),
            map(payload => new layoutActions.ErrorAction(payload))
        ));



    delete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.DELETE),
            map((action: picklistOptionActions.DeleteAction) => action.payload),
            switchMap((payload) => this.http.delete(`/api/studio/${payload.studioId}/picklist-option/${payload.id}`)
                .pipe(
                    map((assets: any[]) => {
                        if (assets.length > 0) {
                            this.dialog.open(RemoveModalComponent, {
                                data: {
                                    title: 'Cannot Remove Picklist Option',
                                    message: 'This Picklist option can not be removed because it is currently associated with one or more Assets in following locations:',
                                    assets
                                }
                            });
                            return new layoutActions.InfoDisplayedAction();
                        } else {
                            return new picklistOptionActions.DeleteCompleteAction(payload.id);
                        }
                    }),
                    catchError(error => observableOf(new picklistOptionActions.DeleteFailedAction({ error })))
                )
            )
        ));

    deleteComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.DELETE_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Picklist Option has been deleted.' }))));

    deleteFailed$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(picklistOptionActions.DELETE_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload)))
    );

    constructor(private actions$: Actions, private http: HttpClient, private dialog: MatDialog) { }
}
