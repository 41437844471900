<mat-form-field style="width:80%" [formGroup]="group">
    <mat-label>{{placeholder}}</mat-label>
    <textarea
        matInput
        matTextareaAutosize
        type="text"
        name="{{name}}"
        [value]="value"
        (input)="onUserInput($event)"
        #input
        formControlName="{{controlName}}"
    ></textarea>
</mat-form-field>
<div style="text-align: right; display: inline-block" [ngClass]="{ 'disabled': disable }">
    <img class="clickable" style="filter: invert(50%)" (click)="scanBarcode($event)" src="../../../assets/layout/images/barcode-scan.svg">
</div>

