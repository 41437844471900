import * as assetActions from '../assets/asset.actions';

export interface State {
    duplicateAssetIds: number[];
}

export const initialState: State = {
    duplicateAssetIds: null
};

export function reducer(state = initialState, action: assetActions.Actions): State {
    switch (action.type) {

        case assetActions.DUPLICATE_ASSET: {
            return {
                duplicateAssetIds: null
            };
        }

        case assetActions.DUPLICATE_ASSET_COMPLETE: {
            const duplicateAssetIds = [action.payload.assetId, ...action.payload.assets.map(a => a.id)];

            return {
                duplicateAssetIds
            };
        }

        default: {
            return state;
        }
    }
}

export const getDuplicateAssetIds = (state: State) => state.duplicateAssetIds;
