import { CustomStudioFieldName } from '../models';
import { BaseModel } from './../base/base.model';

export class Project extends BaseModel {
    id: number;
    franchise_id: number = null;
    currency_enabled = false;
    name: string = null;
    created_at: Date = null;
    updated_at: Date = null;

    constructor(projectObj) {
        super();
        this.initializeData(projectObj, this);
    }

    displayName(isSeries: boolean | number, customFieldNames?: CustomStudioFieldName): string {
        const seasonLabel = customFieldNames ? customFieldNames.season_id : 'Season';
        return isSeries ? `${seasonLabel} ${this.name}` : this.name;
    }
}
