import { createSelector } from 'reselect';
import { TransactionTypes } from '../../../../../shared/enums/transaction-types';
import { ActionStatus } from '../types';
import { copyWithoutIndex, getEntitiesObject } from '../utils';
import * as transactionActions from './transaction.actions';
import { Transaction } from './transaction.model';

export interface State {
    emailSending: ActionStatus;
    entities: {
        [transactionId: number]: Transaction
    };
    loading: ActionStatus;
    transactionIdsForFranchise: {
        [franchiseId: number]: number[];
    };
    selectedTransactionId: number;
    selectedTransactionIdsToMatch: number[];
    excludedTransactionIdsForFranchise: {
        [franchiseId: number]: number[];
    };
    transactionTotalCount: {
        [franchiseId: number]: number;
    };
    excludedTransactionTotalCount: {
        [franchiseId: number]: number;
    };
    indexTransactionsShown: TransactionTypes;
    saving: ActionStatus;
}

export const initialState: State = {
    emailSending: ActionStatus.Complete,
    entities: {},
    indexTransactionsShown: TransactionTypes.Unreconciled,
    loading: ActionStatus.Complete,
    transactionIdsForFranchise: {},
    selectedTransactionId: null,
    selectedTransactionIdsToMatch: [],
    saving: ActionStatus.Complete,
    excludedTransactionIdsForFranchise: {},
    transactionTotalCount: {},
    excludedTransactionTotalCount: {}
};

export function reducer(state = initialState, action: transactionActions.Actions): State {
    switch (action.type) {
        case transactionActions.GET_COMPLETE: {
            const transactions = action.payload;
            const transactionObj = getEntitiesObject(transactions);
            const updatedEntities = { ...state.entities, ...transactionObj };
            const franchiseId = transactions[0].franchise_id;
            const franchiseIdObj = {};
            if (state.transactionIdsForFranchise[franchiseId]) {
                transactions.forEach(transaction => {
                    if (state.transactionIdsForFranchise[franchiseId].indexOf(transaction.id) === -1) {
                        franchiseIdObj[franchiseId] = [...state.transactionIdsForFranchise[franchiseId], transaction.id];
                    }
                });
            } else {
                franchiseIdObj[franchiseId] = transactions.map(t => t.id);
            }
            const alterStateWith = {
                entities: updatedEntities,
                transactionIdsForFranchise: { ...state.transactionIdsForFranchise, ...franchiseIdObj }
            };
            return { ...state, ...alterStateWith };
        }

        case transactionActions.LIST_COMPLETE: {
            const transactions = action.payload.transactions;
            const franchiseId = action.payload.franchiseId;
            const totalCount = action.payload.totalCount;
            const transactionObj = getEntitiesObject(transactions);
            const franchiseIdObj = {};
            const excludedFranchiseIdObj = {};
            const countObj = {};
            const excluded = action.payload.options.excluded;
            let alterStateWith;

            countObj[franchiseId] = totalCount;
            if (excluded) {
                excludedFranchiseIdObj[franchiseId] = [];
                if (transactions.length) {
                    transactions.forEach((t) => {
                        excludedFranchiseIdObj[franchiseId].push(t.id);
                    });
                }
                alterStateWith = {
                    entities: { ...state.entities, ...transactionObj },
                    excludedTransactionIdsForFranchise: {
                        ...state.excludedTransactionIdsForFranchise,
                        ...excludedFranchiseIdObj
                    },
                    excludedTransactionTotalCount: { ...state.excludedTransactionTotalCount, ...countObj }
                };
            } else {
                franchiseIdObj[franchiseId] = [];
                if (transactions.length) {
                    transactions.forEach((t) => {
                        franchiseIdObj[franchiseId].push(t.id);
                    });
                }

                alterStateWith = {
                    entities: { ...state.entities, ...transactionObj },
                    transactionIdsForFranchise: {
                        ...state.transactionIdsForFranchise,
                        ...franchiseIdObj
                    },
                    transactionTotalCount: { ...state.transactionTotalCount, ...countObj }
                };
            }

            return { ...state, ...alterStateWith, loading: ActionStatus.Complete };
        }

        case transactionActions.REMOVE: {
            const franchiseId = action.payload.franchiseId;
            const transactionIds = action.payload.transactionIds;
            const isExcluded = action.payload.isExcluded;
            const franchiseIdObj = {};
            const alterStateWith = {};
            if (isExcluded) {
                if (state.excludedTransactionIdsForFranchise[franchiseId]) {
                    franchiseIdObj[franchiseId] = state.excludedTransactionIdsForFranchise[franchiseId].slice();
                    franchiseIdObj[franchiseId] = franchiseIdObj[franchiseId].filter(t => !transactionIds.includes(t));

                    alterStateWith['excludedTransactionIdsForFranchise'] = {
                        ...franchiseIdObj
                    };
                    return { ...state, ...alterStateWith };
                }
            } else {
                if (state.transactionIdsForFranchise[franchiseId]) {
                    franchiseIdObj[franchiseId] = state.transactionIdsForFranchise[franchiseId].slice();
                    franchiseIdObj[franchiseId] = franchiseIdObj[franchiseId].filter(t => !transactionIds.includes(t));

                    alterStateWith['transactionIdsForFranchise'] = {
                        ...franchiseIdObj
                    };
                    return { ...state, ...alterStateWith };
                }
            }

            return state;
        }

        case transactionActions.SELECT: {
            let newState = state;
            if (action.payload !== state.selectedTransactionId) {
                newState = {
                    ...state,
                    selectedTransactionId: action.payload
                };
            }

            return newState;
        }

        case transactionActions.SELECT_TO_MATCH: {
            return { ...state, selectedTransactionIdsToMatch: action.payload };
        }

        case transactionActions.UPDATE_MULTIPLE_COMPLETE: {
            const transactions = action.payload;
            const franchiseIdObj = { ...state.transactionIdsForFranchise };
            const excludedFranchiseIdObj = { ...state.excludedTransactionIdsForFranchise };
            const newEntities = {
                ...state.entities
            };

            if (transactions.length) {
                const franchiseId = transactions[0].franchise_id;

                if (!excludedFranchiseIdObj[franchiseId]) {
                    excludedFranchiseIdObj[franchiseId] = [];
                }

                transactions.forEach((t) => {
                    const oldTransaction = { ...newEntities[t.id] };
                    newEntities[t.id] = t;

                    if (t.excluded && !oldTransaction.excluded) {
                        const oldTransactionIndex = franchiseIdObj[franchiseId].findIndex((v) => v === t.id);
                        franchiseIdObj[franchiseId] = copyWithoutIndex(franchiseIdObj[franchiseId], oldTransactionIndex);
                        excludedFranchiseIdObj[franchiseId] = [...excludedFranchiseIdObj[franchiseId], t.id];
                    } else if (!t.excluded && oldTransaction.excluded) {
                        const oldTransactionIndex = excludedFranchiseIdObj[franchiseId].findIndex((v) => v === t.id);
                        excludedFranchiseIdObj[franchiseId] = copyWithoutIndex(excludedFranchiseIdObj[franchiseId], oldTransactionIndex);
                        franchiseIdObj[franchiseId] = [...franchiseIdObj[franchiseId], t.id];
                    }
                });
            }

            const alterStateWith = {
                entities: newEntities,
                transactionIdsForFranchise: franchiseIdObj,
                excludedTransactionIdsForFranchise: excludedFranchiseIdObj,
                saving: ActionStatus.Complete
            };

            return { ...state, ...alterStateWith };
        }

        case transactionActions.SELECT_TRANSACTIONS_SHOWN: {
            return { ...state, indexTransactionsShown: action.payload };
        }

        case transactionActions.SEND_RECONCILE_EMAILS: {
            return { ...state, emailSending: ActionStatus.Loading };
        }

        case transactionActions.SEND_RECONCILE_EMAILS_COMPLETE: {
            return { ...state, emailSending: ActionStatus.Complete };
        }

        case transactionActions.SEND_RECONCILE_EMAILS_FAILED: {
            return { ...state, emailSending: ActionStatus.Failed };
        }

        case transactionActions.UPDATE_MULTIPLE: {
            return { ...state, saving: ActionStatus.Loading };
        }

        case transactionActions.LIST: {
            return { ...state, loading: ActionStatus.Loading };
        }

        case transactionActions.UPDATE_MULTIPLE_FAILED: {
            return { ...state, saving: ActionStatus.Failed };
        }

        case transactionActions.LIST_FAILED: {
            return { ...state, loading: ActionStatus.Failed };
        }

        default: {
            return state;
        }
    }
}

export const getSelectedTransactionId = (state: State) => state.selectedTransactionId;
export const getSelectedTransactionIdsToMatch = (state: State) => state.selectedTransactionIdsToMatch;
export const getEntities = (state: State) => state.entities;
export const getTransactionIdsForFranchise = (state: State) => state.transactionIdsForFranchise;
export const getExcludedTransactionIdsForFranchise = (state: State) => state.excludedTransactionIdsForFranchise;
export const getSelectedTransaction = createSelector(getSelectedTransactionId, getEntities, (selectedId, entities) => {
    if (selectedId && entities[selectedId]) {
        return entities[selectedId];
    }
    return null;
});
export const getEmailSending = (state: State) => state.emailSending;
export const getSelectedTransactionsToMatch = createSelector(getSelectedTransactionIdsToMatch, getEntities, (selectedIds, entities) => {
    if (selectedIds && selectedIds.length) {
        const selectedTransactions = [];
        selectedIds.forEach(id => entities[id] ? selectedTransactions.push(entities[id]) : null);
        return selectedTransactions && selectedTransactions.length ? selectedTransactions : null;
    }
    return null;
});
export const getSaving = (state: State) => state.saving;
export const getLoading = (state: State) => state.loading;
export const getTransactionTotalCount = (state: State) => state.transactionTotalCount;
export const getTransactionExcludedTotalCount = (state: State) => state.excludedTransactionTotalCount;
export const getTransactionsShown = (state: State) => state.indexTransactionsShown;
